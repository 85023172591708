import { Icon } from '../../icon/Icon';
import './med-prac-button.scss';



interface Props {
    children: any,
    onClick?: Function,
}

export const MedPracButton = ({ 
    onClick,
    children,
}: Props) => {
    return (
        <button 
            className="med-prac-button"
            onClick={(e) => {
                if (onClick) {
                    onClick(e)
                }
            }}
        >
          {children}
        </button>
    );
};


export const MedPracButtonCreditCard = () => {
    return (
        <button className="med-prac-button">
            <div className="custom-icon-container">
                <i className="custom-icon">
                    <Icon name='creditcard' />
                </i>
            </div>
        </button>
    )
}
