interface Props {
    title?: boolean; // all caps text
    path?: boolean;
    children?: any;
}

/**
 * Use this component to wrap conent that should only be visible when the page is printed.
 */
const PrintOnlyContent = ({ title=false, path=true, children=null }: Props) => {
    const regularStyles = {}
    const titleStyles = {
        textTransform: 'uppercase',
        fontWeight: 800,
        color: 'rgba(65, 118, 187',
        textDecoration: 'underline',
        backgroundColor: '#f00',
    }

    return (
        <div className='print-only'>
            <div style={title ? titleStyles : regularStyles}>
                {children}
            </div>
            {path ? (
                <span style={{fontWeight: 'bold', opacity: `${title ? .6 : 1}`}}>
                    Printable text-and-image summary for {window.location.href}
                    <br/><br/><br/>
                </span>
            ): null}
        </div>
    )
}

export default PrintOnlyContent