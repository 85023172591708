import React, { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';

import { formatAWsCognitoErrorMessage } from '../../../../helpers/messageParserAws'
import parseErrorObject from '../../../../helpers/parseErrorObject';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';
import { qsToDict, buildPath } from '../../../../helpers/urls';

import { ACCOUNT_TYPES_TO_REG_URL } from '../../../../constants/accountType';

import './login.scss';



export const Login = () => {
    const API_DB_URL = process.env.REACT_APP_API_DB_URL
    
    // Auth/session functions
    const { authenticate, checkLogin } = useContext(AccountContext)
    
    // State vars
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')

    const [qp, _] = useState(qsToDict(window.location.search))
    const [referrerMessage, setReferrerMessage] = useState('')

    // Get query params and take appropriate action.
    useEffect(() => {
        // If user was referred here by step 1 of a registration process, show toaster.
        if (qp.referrer?.includes('registration')) {
            toast.success('Changes have been saved')
        }

        // If there was an error message from a bad login (specifically for bad credentials in DB
        // as opposed to cognito), then the user will be shown an error for a split second before
        // being redirected to this same page by Account.tsx[authenticate()]. So this mechanism
        // allows the user to still see that error message.
        if (qp.errorMessage) {
            setLoginError(qp.errorMessage)
        }

        // If they were redirected here because unauthenticated.
        if (qp.reason === 'unverified') {
            // User redirected here because tried to access a page that requires login.
            setReferrerMessage('You need to be logged in to view that page.')
        } else if (qp.reason === '#16778') {
            // Registration edge case #16778.
            setReferrerMessage('You already have an account. Please login or click Forgot '
                                + 'Password, then complete your registration.')
        } else if (qp.referrer?.includes('registration')) {
            // Normal referal from Step 1 of registratoin.
            setReferrerMessage('Please login to continue your registration.')
        }

        // If they just completed Rep ("consumer") registration and indicated that they are 
        // part of an existing Rep Organization.
        if (qp.repRequestEmailSent === 'true') {
            toast.success("Confirmation email sent to Rep Organization to approve membership.")
        }
    }, [qp])

    // Bump out users who ARE already logged in.
    useEffect(() => {
        if (!checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (isLoggedIn) {
                const qpOut = {reason: 'isloggedin'}
                window.location.href = buildPath('/account/', qpOut)
            }
        })
    }, [checkLogin])

    const convertToCapitalCase = (str: string) => {
        return str.slice(0,1).toUpperCase() + str.slice(1,).toLowerCase() // use slice instead of [0].
    }


    // Send login info to AWS Cognito the authenticate user.
    const onSubmitLoginForm = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        authenticate(email, password)
            .then(async (data: any) => {
                /* Login success */
                const user_id = data.idToken.payload['custom:u_id']

                // Check 4 endpoints, for supplier/vendor/provider/consumer user types, to see if user 
                // has any uncompleted registration steps. If so, redirect to the page of the
                // current reg step. (Consumer user type, aka "general" user, is just a placeholder
                // function right now - it always returns succcess).)
                let userRegStepSupplier
                let userRegStepVendor
                let userRegStepProvider
                let userRegStepConsumer
                let nextStep
                let nextStepType
                try {
                    userRegStepSupplier = axios.get(`${API_DB_URL}/get-reg-step-supplier/${user_id}`)
                    userRegStepVendor = axios.get(`${API_DB_URL}/get-reg-step-vendor/${user_id}`)
                    userRegStepProvider = axios.get(`${API_DB_URL}/get-reg-step-provider/${user_id}`)
                    userRegStepConsumer = axios.get(`${API_DB_URL}/get-reg-step-consumer/${user_id}`)
                } catch (err) {
                    // No need to display this error prominently to user. Just log it and return.
                    // TODO: check if this actually logs the error or just "[Object object]".
                    console.error('error #11843: ', String(err))
                    return
                }

                userRegStepSupplier = await userRegStepSupplier
                userRegStepVendor = await userRegStepVendor
                userRegStepProvider = await userRegStepProvider
                userRegStepConsumer = await userRegStepConsumer

                if (userRegStepSupplier?.data) { // (will be `null` if no incomplete reg steps remaining)
                    nextStep = userRegStepSupplier.data.regStep || 1
                    nextStepType = convertToCapitalCase(userRegStepSupplier.data.type || '')
                } else if (userRegStepVendor?.data) {
                    nextStep = userRegStepVendor.data.regStep || 1
                    nextStepType = convertToCapitalCase(userRegStepVendor.data.type || '')
                } else if (userRegStepProvider?.data) {
                    nextStep = userRegStepProvider.data.regStep || 1
                    nextStepType = convertToCapitalCase(userRegStepProvider.data.type || '')
                } else if (userRegStepConsumer?.data) {
                    nextStep = userRegStepConsumer.data.regStep || 1
                    nextStepType = convertToCapitalCase(userRegStepConsumer.data.type || '')
                }
                
                // If there is a `redirectTo` query param, or all registration steps are complete,
                // then redirect to account page after login.
                if (qp.redirectTo || !nextStepType) {
                    const basePath = qp.redirectTo || '/account/'
                    const qpOut = {referrer: qp.referrer, via: '/auth/login'}
                    window.location.href = buildPath(basePath, qpOut)
                } else {
                    // If user still needs to complete registration steps, redirect to the 
                    // appropriate registration page and step.
                    const regBasePath = `${ACCOUNT_TYPES_TO_REG_URL[nextStepType]}`
                    const qpOut = {referrer: '/auth/login', reason: 'reg-incomplete'}
                    window.location.href = buildPath(`/${regBasePath}/${nextStep}`, qpOut)
                }
            }).catch((err: Error) => {
                /* Login error */
                // TODO: better error message
                setLoginError(formatAWsCognitoErrorMessage(err) + '\r\n' + parseErrorObject(err))
            })
    }, [qp, email, password])


    return (
        <div className='body-container'>
            <PrintOnlyContent title={true}>Medngine login page</PrintOnlyContent>
            <div className='login-form-wrapper'>
                <div className='title' data-testid='login-page-title'>
                    Sign In
                </div>

                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                <form className='form' onSubmit={onSubmitLoginForm}>
                    <InputField 
                        name='email' 
                        placeholder='Email' 
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />
                    <InputField
                        name='password'
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    />

                    {loginError ? (
                        <div className='error-message'>
                            {loginError}
                        </div>
                    ): null }

                    {!qp.referrer?.includes('registration') ? (
                        <div className='links-wrapper'>
                            <Link to={'/auth/forgot-password/1'}>
                                Forgot password?
                            </Link>
                        </div>
                    ) : null }
                    
                    <BlockButton type='submit' buttonStyle="purple" style={{ width: '100%', }}>
                        Continue
                    </BlockButton>
                </form>

                {!qp.referrer?.includes('registration') ? (
                    <>
                    <div className='title subtitle'>
                        New to Medngine?
                    </div>
                    <div className='form'>
                        <Link to={`/vendor-registration/1`}>
                            <BlockButton buttonStyle="purple" style={{ width: '100%', }}>
                                Create Account
                            </BlockButton>
                        </Link>
                    </div>
                    </>
                ) : null }

                <ToastContainer 
                    hideProgressBar={true}
                    position='bottom-center'
                    style={{textAlign: 'center', cursor: 'default'}}
                />
            </div>
        </div>
    )
}