import React, { useEffect, useState, useContext, useRef } from "react";
import { ToastContainer, toast, Flip } from 'react-toastify'

import { AccountContext } from "../../../auth/Account";

import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import { TextAreaField } from "../../../shared/form/text-area/TextAreaField";

// Helpers that are only used for this checkout.
import { Address, GetAddressIndexFromId, PaymentAccountDetails, getCartAndProductDetails } from "../CartShared";
import calculateCartPrices from "./_getCartPrices";
import getUserAddresses from "../_fetchAddress";
import handleAddressChange from "../_handleAddressChange";
import OrdersTable from "./_OrdersTable";
import PaymentForm from "./_paymentForm";
import PaymentForm_Braintree from "./_paymentForm_Braintree";
import PricesTable from "./_PricesTable";
import updateCoupon from "./_handleCouponChange";

import { CookieNames, makeOrUpdateCookie } from "../../../../cache/Cookie_General";
import { MAX_CART_NOTE_LENGTH } from "../../../../../config";
import PrintOnlyContent from "../../../shared/print/PrintOnlyContent";
import { ProductsById } from "../../patient/product-detail/PatientProductPage";
import { SessionPacket } from "../../../../types/session";


import "./check-out.scss";



const API_DB_URL = process.env.REACT_APP_API_DB_URL


enum NOTES_STATUS {
	NO_RESPONSE = 0,
	RESPONDED = 1,
	READY = 2,
}

// Maximum number of requests that can be made to backend for testing a coupon (per page load)
// Keep them from messing around too much. High number for slow typers and confused people.
// - TODO: keep this in centeralized configs somewhere.
const ATTEMPT_COUNT_LIMIT = 30 // 30


/**
 * Parent component for the checkout / shopping cart / payment page. 
 * Has a lot of child components and helper functions in other files.
 * 
 * TODO: cart should be stored on server because user will have other devices.
 */
export const CheckOut = () => {

	// Account context functions and values - these manage the shopping cart.
	const { getUserId, getUsernameAndIdToken, shoppingCart, shoppingCartSize, getShoppingCart,
			getCartNote, saveCartNote, 
			getAddressId, saveAddressId } = useContext(AccountContext)
	
	/** State variables. */
	// From the API - dictionary of products and their details:
	const [productDetails, setProductDetails] = useState({} as ProductsById)
	// User's addresses list and how they're displayed.
	const [userAddressesList, setUserAddressesList] = useState([] as Address[])
	const [userAddressesDropdownOptions, setUserAddressesDropdownOptions] = useState([] as SelectOption[])
    const [selectedAddressOptionIndex, setSelectedAddressOptionIndex] = useState(0)
	const [formattedAddress, setFormattedAddress] = useState('')
	const [triggerAddressChange, setTriggerAddressChange] = useState(false) // for first time ever on this checkout page.
	// Rates for sales tax and shipping costs, based on user's address.
    const [userStateTaxRate, setUserStateTaxRate] = useState(0)
	const [userShippingPrice, setUserShippingPrice] = useState(0)
    // Coupons, their discount values, and associated success/error messages.
	const [coupon, setCoupon] = useState('')
	const [couponDiscountPercent, setCouponDiscountPercent] = useState(0)
	const [couponDiscountDollars, setCouponDiscountDollars] = useState(0)
	const [couponSuccessMsg, setCouponSuccessMsg] = useState('')
	const [couponErrorMsg, setCouponErrorMsg] = useState('')
	// COUPON RATE LIMITERS, etc.
	const COUPON_VERIFY_DEBOUNCER = useRef<number>(0)
	const COUPON_VERIFY_DEBOUNCE_TIME = 700; // ms // Long timeout OK bc lots of mechanisms to make sure the whole coupon is checked.
	const couponAttemptCount = useRef(0) // to prevent not-logged in popup on mount.
	const [couponCheckAltTrigger, setCouponCheckAltTrigger] = useState(true) // Just flip it to trigger a backend check of coupon code.
	const [couponCheckEventTrigger, setCouponCheckEventTrigger] = useState(true) // Only caused by click/blur/Enter events.
	const [couponShowSpinner, setCouponShowSpinner] = useState(false)
	const couponCheckCountsTowardMax = useRef(false)
	const couponLastValChecked = useRef('') // Don't check backend twice for same code
	// All the different price figures at the right side of the page.
	const [cartBaseProductsPrice, setCartBaseProductsPrice] = useState(0)
	const [cartBasePrice, setCartBasePrice] = useState(0)
	const [cartTotalDiscounted, setCartTotalDiscounted] = useState(0) // This is the final price w/taxes & discounts.
	const [cartBulkDiscountAmount, setCartBulkDiscountAmount] = useState(0)
	const [cartCouponDiscountAmount, setCartCouponDiscountAmount] = useState(0)
	// Notes box
    const [notes, setNotes] = useState('')
	const notesReady = useRef(0) // To indicate that it's ok to update the note in Redis now.
	const notesTyped = useRef(false)
	const NOTE_DEBOUNCER = useRef<number>(0)
	const NOTE_DEBOUNCE_TIME = 1000; // ms
	const [showNoteWarning, setShowNoteWarning] = useState(true)
	const [tallerNotesInput, setTallerNotesInput] = useState(false)

	// Payment methods (such as 'bank'/'credit'/etc) and user's saved payment accounts.
	const [paymentAccountsDropdownOptions, setPaymentAccountsDropdownOptions] = useState([] as SelectOption[])
	const [paymentAccounts, setPaymentAccounts] = useState([] as PaymentAccountDetails[])
	const [selectedPaymentAccount, setSelectedPaymentAccount] = useState({} as PaymentAccountDetails)
	// Session
	const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
	const [sessionJson, setSessionJson] = useState('')
	const [loggedInUserId, setLoggedInUserId] = useState<number|null>(null) // User ID, only if they're logged in.
	
	// Cart
	const skuIds = useRef<number[]>([])




	// Get session obj, just after mount, once the AccountContext func is created.
	const getSessionObj = async () => {
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session || null)
		setSessionJson(session ? JSON.stringify(session) : '')
		if (session.error) { 
            console.error(session.error)
            alert(`Session error: ${session.error}`)
        }
	}
	// ^^
	useEffect(() => {
		getSessionObj()
	}, [getUsernameAndIdToken])

	// After getting the sessionObj:
	//  - Get list of user's addresses (which subsequently triggers whichever address the user
	//    chose the last time they were at this CheckOut page).)
	//  - Get dictionary of product details.
	//  - Get note that was prevously added to cart
	//  - Get the user's cart from memorydb/redis & get all of that cart's product/sku details.
	// IMPORTANT: If this block is changed, you'll prob also need to change the matching block
	// 	          in CheckOutConfirmation.tsx.
	useEffect(() => {
		if (sessionJson && getShoppingCart) {
			// Get the UserID from [mr_user] (or mb from Cognito).
			getUserId(sessionJson).then((uid: number|null) => {
				setLoggedInUserId(uid)
			})
			// Get cart from Redis and product details from psql.
			getCartAndProductDetails(sessionJson, skuIds, getShoppingCart, setProductDetails)
			// Get the list of addresses (psql) for this user's business or clinic that we can ship to.
			getUserAddresses(sessionJson, setUserAddressesList, setUserAddressesDropdownOptions)
			// Get any note text the user might have added to their cart (redis).
			getCartNote(sessionJson).then((text) => {
				notesReady.current = NOTES_STATUS.RESPONDED
				notesTyped.current = false // just in case
				// `text` will be null if key doesn't exist in Redis, OR if there's an error. 
				// Don't do anything if it's not string, else we'd clear their note whenever there's any error.
				if (typeof text === 'string') { 
					setNotes(text)
					// If the note is long, or has more than 2 new-line chars, expand the input box.
					if (text.length > 200 || text.split('\n').length > 3) {
						setTallerNotesInput(true)
					}
				}
			}).catch((err: Error) => {
				notesReady.current = NOTES_STATUS.NO_RESPONSE
				notesTyped.current = false // just in case
				toast.error('Failed to get note - please refresh and login again if necessary.', { autoClose: 1 })
			})
		} else if (!getShoppingCart) {
			// I think this can can happen, but haven't seen it in local testing...
			alert("The function to get ShooppingCart didn't load, please contact an admin.")
		}
	}, [sessionJson])

	// After loading the user's address list on mount...
	// - Get the user's selected addressId (row ID of mr_address table) from memdb/redis, then
	//   set the state variable to select the right dropdown option in the address selector.
	// - If user hasn't selected an address ID before (eg. this is their first time using the cart),
	//   then just grab the first ID from the list.
	useEffect(() => {
		if (!userAddressesList.length) return;

		getAddressId(sessionJson).then((_addressId: number|null) => {
			let addressIndex = 0
			let addressId = _addressId
			if (addressId === null) {
				// User has never selected an addressId from the list before
				addressId = userAddressesList[0].id
				setTriggerAddressChange(true) // (`selectedAddressOptionIndex` is already 0)
				return
			}
			
			const index: number = GetAddressIndexFromId(userAddressesList, Number(addressId))
			if (!isNaN(index) && index !== -1) {
				addressIndex = index
			}
			setSelectedAddressOptionIndex(addressIndex)

		})
	}, [userAddressesList])

	// For setting notes state, and saving then call the `saveNote` (debounced) function.
	useEffect(() => {
		if (!sessionJson) return;

		if (notesReady.current === NOTES_STATUS.RESPONDED) {
			// This will happen when the notes are first loaded from backend.
			notesReady.current = NOTES_STATUS.READY
		} else {
			// This is for all other cases, including normal writing and if the server/Redis failed to respond.
			if (typeof notes === 'string' && notes.length > MAX_CART_NOTE_LENGTH) {
				// If the note is too long, truncate it. (`saveNote` will run once that state update goes thru).
				toast.warn(`Maximum note length of ${MAX_CART_NOTE_LENGTH} exceeded!`, { autoClose: 1 })
				setNotes(notes.slice(0, MAX_CART_NOTE_LENGTH))
			} else {
				saveNote(sessionJson, notes)
			}
		}
	}, [sessionJson, notes])

	// Debounced function to write/store notes, from user action. 
	// - This guards against cases like: there is an error so we overwrite a real note with an empty string.
	// - If user hasn't typed anything since the note was loaded from Redis, don't try to save it.
	const saveNote = (sessionJson: string, noteText: string) => {
		clearTimeout(NOTE_DEBOUNCER.current)
        NOTE_DEBOUNCER.current = window.setTimeout(() => {
			if (typeof noteText !== 'string') return
			if (notesReady.current === NOTES_STATUS.READY && notesTyped.current) { // Normal scenario.
				saveCartNote(sessionJson, noteText)
			} else if (notesTyped.current) { // Server hasn't loaded the note yet.
				// If they don't want to change anything, refresh the page for them. Else save.
				const msg = "Your previous note hasn't loaded yet. Are you sure you want to overwrite it?"
				const confirmed = window.confirm(msg)
				if (!confirmed) {
					window.location.reload()
				} else {
					saveCartNote(sessionJson, noteText)
				}
			} 
			// (If user hasn't typed anything, there's no reason to save).
		}, NOTE_DEBOUNCE_TIME)
	}



	// Display selected user address and calculate shipping/sales-tax rates based on address.
	// This function runs when:
	//  - Component mounts and addresses are fetched (from `getUserAddresses()`).
	//  - When user changes address in the dropdown.
	useEffect(() => {
		if (sessionJson && !isNaN(Number(selectedAddressOptionIndex)) && userAddressesList.length) {
			handleAddressChange(
				sessionJson,
				setFormattedAddress, setUserStateTaxRate, setUserShippingPrice,
				userAddressesList, selectedAddressOptionIndex, saveAddressId,
			)
		}
	}, [sessionJson, selectedAddressOptionIndex, userAddressesList, triggerAddressChange])

	// Calculate total cost of all items in cart, then update display with proper values.
	// This function runs when:
	//  - Cart size (and value) changes.
	//  - User selects a different address (which changes state tax rate and shipping costs).
	//  - User adds or changes a coupon.
	useEffect(() => {
		// Set results of calculations to the provided state variables.
		if (shoppingCart && shoppingCartSize && productDetails) {
			const _shoppingCart = (typeof shoppingCart === 'string') ? JSON.parse(shoppingCart) : shoppingCart
			calculateCartPrices(
				setCartBaseProductsPrice, setCartBasePrice, setCartTotalDiscounted, setCartBulkDiscountAmount,
				setCartCouponDiscountAmount, _shoppingCart, productDetails,
				userStateTaxRate, userShippingPrice, couponDiscountPercent, couponDiscountDollars
			)
		}
	}, [shoppingCart, productDetails, userStateTaxRate, userShippingPrice, couponDiscountPercent, couponDiscountDollars])


	// Use a helper for this since it's used in two places.
	const exceededMaxCouponAttempts = () => {
		return (couponAttemptCount.current > ATTEMPT_COUNT_LIMIT)
	}

	// Verfiy coupon code and cart size/value, then compute discount/price in `calculateCartPrices()`.
	// This function runs when:
	//  - User enters or changes a coupon code.
	//  - Shopping cart changes in size or value, as that may affect whether the user is permitted
	//    to apply the discounts from coupon (ex: too few items in cart or too little cost).
	// - DEBOUNCED.
	useEffect(() => {
		// If they're checking the same coupon code as last time, don't run anything (or clear any messages).
		if (coupon === couponLastValChecked.current) {
			return;
		}
		// If empty, clear the success/error messages and return.
		if (!coupon) {
			setCouponSuccessMsg('')
			setCouponErrorMsg('')
			return;
		}
		// User must be logged in to test coupons.
		if (!sessionJson) {
			alert("You must be logged in to enter a coupon. Please refresh and try again.")
		}
		// If they're trying it too much, just tell them they can't anymore.
		// - Clear the success/error messages and don't check the backend.
		if (exceededMaxCouponAttempts()) {
			alert("Maximum attempts exceeded!")
			setCouponErrorMsg('Maximum attempts exceeded')
			return // don't even check
		}

		// Dobouncer before checking the coupon with the backend.
		clearTimeout(COUPON_VERIFY_DEBOUNCER.current)
        COUPON_VERIFY_DEBOUNCER.current = window.setTimeout(() => {
			// Mark that we're checking this particular coupon code.
			couponLastValChecked.current = coupon
			// Increment here bc it should only be incremented when backend is checked AND this 
			// backend check was triggered by a change in the coupon code, as opposed to clicking,
			// pressing Enter, or bluring the coupon input field.
			if (couponCheckCountsTowardMax.current) {
				couponAttemptCount.current += 1
			}
			// Show the spinner only when the backend request is being made, so they know.
			setCouponShowSpinner(true)
			// Check the backend
			updateCoupon(
				sessionJson,
				setCouponShowSpinner,
				setCouponDiscountPercent, setCouponDiscountDollars, setCouponSuccessMsg, setCouponErrorMsg,
				coupon, shoppingCartSize, cartBaseProductsPrice,
			)
		}, COUPON_VERIFY_DEBOUNCE_TIME)
	}, [sessionJson, coupon, shoppingCartSize, cartBaseProductsPrice, couponCheckAltTrigger])

	/**
	 * Above function needs to be called through one of these two "wrapper"s.
	 */
	// ... Wrapper 1: for normal typing events.
	// - Just a direct line to the other useEffect hook.
	useEffect(() => {
		couponCheckCountsTowardMax.current = true
		setCouponCheckAltTrigger(prev => !prev)
	}, [coupon])
	// ... Wrapper 2: for click/blur/Enter-key events
	// - Don't count these events toward their limit, bc it's just the same coupon-code value.
	// - Also, if they exceeded their limit we don't want to show the popup again when they 
	//   click out of the input field or just click it.
	// - (but if they try to type something again it will still show the alert again).
	useEffect(() => {
		if (!exceededMaxCouponAttempts()) {
			couponCheckCountsTowardMax.current = false
			setCouponCheckAltTrigger(prev => !prev)
		}
	}, [couponCheckEventTrigger])


	// Whenever cart price changes, store in cookie for use on the checkout confirmation page.
	// TODO: definitely use a safer method before going live.
	useEffect(() => {
		makeOrUpdateCookie(CookieNames.carttotalprice, String(cartTotalDiscounted))
	}, [cartTotalDiscounted])

	return (
		<>
		<PrintOnlyContent title={true}>Medngine shopping cart page</PrintOnlyContent>
		<HeaderBanner
			imageName="doctorpen"
			imageText="CHECK OUT"
			imageSpacerColor='BLUE'
			dontChangeTitleOpacity={true}
      	/>
		<div className='body-container cart-check-out-page'>
			<div className="address-selection">
				<div className="section-header first">
					Ship to:
				</div>
				<div className="select-address">
					{userAddressesDropdownOptions ? (
						<SelectField
							containerClassName="transparent-bg bold-text"
							name="shipping-address"
							options={userAddressesDropdownOptions}
							selectedIndex={selectedAddressOptionIndex}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								setSelectedAddressOptionIndex(Number(e.target.value))
							}}
						/>
					): null}
					<div className='address-header'>
						Selected address:
					</div>
					<address style={{whiteSpace: 'pre'}}>
						{userAddressesDropdownOptions.length ? (
							(formattedAddress || '')
						) : (
							'No address on record'
						)}
					</address>
				</div>
			</div>

			<div className="order-summary-section">
				<div className="section-header second">
					Order:
				</div>
				<OrdersTable  
					modifiable={true}
					sessionObj={sessionObj}
					productDetails={productDetails}
				/>
				{(shoppingCartSize ? (
					<div className="notes">
						<div className="section-subheader">
							Notes
						</div>
						<div className="textarea-wrapper">
							<TextAreaField 
								name="order-notes" 
								className={`order-notes ${tallerNotesInput ? 'taller' : ''}`}
								value={notes}
								onChange={e => {
									notesTyped.current = true
									setNotes(e.target.value)
								}}
							/>
						</div>
						{(notes && showNoteWarning) ? (
							<div className='note-warning-container'>
								<div className='note-warning'>
									Do not enter any senstive information into this field
									<button 
										className='hide-note-warning'
										onClick={() => setShowNoteWarning(false)}
									>
										OK
									</button>
								</div>
							</div>
						) : null}
					</div>
				) : ( null ))}
				<div className="cart-cost">
					<PricesTable 
						setCoupon={setCoupon}
						setCouponCheckEventTrigger={setCouponCheckEventTrigger}
						couponShowSpinner={couponShowSpinner}
						couponSuccessMsg={couponSuccessMsg}
						couponErrorMsg={couponErrorMsg}
						cartBaseProductsPrice={cartBaseProductsPrice}
						cartBasePrice={cartBasePrice}
						userStateTaxRate={userStateTaxRate}
						userShippingPrice={userShippingPrice}
						cartBulkDiscountAmount={cartBulkDiscountAmount}
						cartCouponDiscountAmount={cartCouponDiscountAmount}
						cartTotalDiscounted={cartTotalDiscounted}
					/>
				</div>
			</div>

			{/* Only show the payment options if there is something in the shopping cart */}
			{(shoppingCartSize) ? (
				<div className="payment-section">
					<div className="section-header">
						Payment:
					</div>
					<div className="payment-option-selection">
						<PaymentForm_Braintree
							loggedInUserId={loggedInUserId}
							sessionJson={sessionJson}
							paymentAccounts={paymentAccounts}
							setPaymentAccounts={setPaymentAccounts}
							selectedPaymentAccount={selectedPaymentAccount}
							setSelectedPaymentAccount={setSelectedPaymentAccount}
							paymentAccountsDropdownOptions={paymentAccountsDropdownOptions}
							setPaymentAccountsDropdownOptions={setPaymentAccountsDropdownOptions}
						/>
					</div>
				</div>
			) : null}
		</div>
		<ToastContainer 
			hideProgressBar={true}
			closeOnClick={true}
			position='bottom-center'
			style={{textAlign: 'center', cursor: 'default', width: '500px', maxWidth: '80vw', whiteSpace: 'pre-wrap'}}
			transition={Flip}
		/>
		</>
	)
}



/**
 * Old Liu stuff - keep for reference, for now.
 */


// const injectStoreToProps = (store: StoreItems) => {
// 	const { selectedPaymentMethod, cart, stateTax, taxRates } = store;
// 	return { selectedPaymentMethod, cart, stateTax, taxRates };
// }


// const conn = connect(injectStoreToProps, { changeSectionHeader, updatePaymentMethod, getTaxRateByState });
// export const CheckOut = conn(CheckOutPage);
