import { Link } from "react-router-dom";
import { PillButton } from '../../../shared/buttons/pill-button/PillButton';
import { ProductCard } from "../../../shared/product-card/ProductCard";
import './products-list.scss';


interface PillButtonGeneratorProps {
  floating: boolean;
  text: string;
  linkTo: string;
}

interface ProductsListProps {
  condensed: boolean;
}


const PillButtonLink = ({ floating, text, linkTo }: PillButtonGeneratorProps) => {
  return (
    <div className='noprint'> {/* This has `.noprint` because it breaks print on home page  */}
      {(floating) ? (
        <Link to={linkTo}>
          <PillButton 
            buttonStyle="white gradient floating-button"
            style={{float: 'right', marginLeft: '16px'}}
          >
            {text}
          </PillButton>
        </Link>
      ) : (
        <Link to={linkTo}>
          <PillButton buttonStyle="white gradient non-floating-button">
            {text}
          </PillButton>
        </Link>
      )}
    </div>
  )
}


export const ProductsList = ({ condensed }: ProductsListProps) => {
  return (
    <div className='product-list-wrapper'>
      <div className={`header ${condensed ? 'hide' : ''}`}>
        AmnioText™
      </div>
      <div className={`product-category-list ${condensed ? 'hide' : ''}`}>
        <div className={`product-category-description`}>
          <PillButtonLink floating={true} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
          AmnioText™ Patch is a sterile, dehydrated human amniotic membrane
          allograft, intended for homologous use to cover and protect the recipient’s
          tissues. AmnioText™ is minimally manipulated and processed to preserve the
          original relevant characteristics relating to its utility as a barrier.
        </div>
        <PillButtonLink floating={false} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
      </div>
      <div className={`product-display-grid ${condensed ? 'condense' : ''}`}>
        <Link to={"/public/products/1"} className='product-card-link'>
          <ProductCard
            product="AMNIOTEXT™ 1cm x 1cm"
            brand="Regenative Labs"
            vendor="APF2 – 11"
            description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
            productImage="amniotextbox"
          />
        </Link>
        <Link to={"/public/products/1"} className='product-card-link'>
          <ProductCard
            product="AMNIOTEXT™ 2cm x 2cm"
            brand="Regenative Labs"
            vendor="APF2 – 22"
            description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
            productImage="amniotextbox"
          />
        </Link>
      </div>

      <div className={`header ${condensed ? 'hide' : ''}`}>
        ProText™
      </div>
      <div className={`product-category-list ${condensed ? 'hide' : ''}`}>
        <div className={`product-category-description`}>
          <PillButtonLink floating={true} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
          ProText™ is our most concentrated Wharton’s jelly allograft. This connective tissue
          supplement provides the scaffolding necessary to replace missing or damaged tissue
          with an abundance of structural proteins, cytokines, and growth factors.
        </div>
        <PillButtonLink floating={false} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
      </div>
      <div className={`product-display-grid ${condensed ? 'condense' : ''}`}>
        <Link to={"/public/products/4"} className='product-card-link'>
          <ProductCard
            product="PROTEXT™ 1 mL"
            brand="Regenative Labs"
            vendor="PT100-25"
            description="ProText™ is a structural connective tissue allograft intended for 
                                    homologous use to replace or supplement missing or damaged tissue 
                                    directly at the site of a structural defect."
            productImage="protextvial"
          />
        </Link>
        <Link to={"/public/products/4"} className='product-card-link'>
          <ProductCard
            product="PROTEXT™ 2 mL"
            brand="Regenative Labs"
            vendor="PT200-25"
            description="ProText™ is a structural connective tissue allograft intended for 
                                    homologous use to replace or supplement missing or damaged tissue 
                                    directly at the site of a structural defect."
            productImage="protextvial"
          />
        </Link>
      </div>

      <div className={`header ${condensed ? 'hide' : ''}`}>
        CryoText™
      </div>
      <div className={`product-category-list ${condensed ? 'hide' : ''}`}>
        <div className={`product-category-description`}>
          <PillButtonLink floating={true} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
          CryoText™ is our most concentrated, non-coded Wharton’s jelly product. This connective
          tissue supplement is intended to replace missing or damaged connective tissue.
        </div>
        <PillButtonLink floating={false} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
      </div>
      <div className={`product-display-grid ${condensed ? 'condense' : ''}`}>
        <Link to={"/public/products/2"} className='product-card-link'>
          <ProductCard
            product="CRYOTEXT™ Plus 1 mL/35mg"
            brand="Regenative Labs"
            vendor="CY+100-25"
            description="This is a connective tissue supplement intended for homologous use 
                                  only, as described in 21CFR 1271.10(a). Structural connective tissue 
                                  that is missing or damaged severely may require new or additional 
                                  tissue to create conditions that encourage the stability of anatomic 
                                  infrastructure."
            productImage="cryotextvial"
          />
        </Link>
        <Link to={"/public/products/3"} className='product-card-link'>
          <ProductCard
            product="CRYOTEXT™ Pro 1 mL/50mg"
            brand="Regenative Labs"
            vendor="CYP100-25"
            description="This is a connective tissue supplement intended for homologous use 
                                    only, as described in 21CFR 1271.10(a). Structural connective tissue 
                                    that is missing or damaged severely may require new or additional 
                                    tissue to create conditions that encourage the stability of anatomic 
                                    infrastructure."
            productImage="secretextvial"
          />
        </Link>
      </div>

      <div className={`header ${condensed ? 'hide' : ''}`}>
        SecreText™
      </div>
      <div className={`product-category-list ${condensed ? 'hide' : ''}`}>
        <div className={`product-category-description`}>
          <PillButtonLink floating={true} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
          SecreText™ is Regenative Labs’ new dose form of Wharton’s jelly. This small tissue size
          is ideal for smaller defects. Containing essential molecules rich in cytokines and
          growth factors, this less concentrated tissue product is suited for aesthetic
          applications where volume is needed to cushion or support the affected area (ie.
          facial lines, wrinkles, etc.).
        </div>
        <PillButtonLink floating={false} text='VIEW ALL PRODUCTS' linkTo='/public/vendor-profile' />
      </div>
      <div className={`product-display-grid ${condensed ? 'condense' : ''}`}>
        <Link to={"/public/products/5"} className='product-card-link'>
          <ProductCard
            product="SECRETEXT™ Plus 1 mL/15mg"
            brand="Regenative Labs"
            vendor="ST100-32"
            description="This is a connective tissue supplement intended for homologous use 
                                  only, as described in 21CFR 1271.10(a). SecreText™ is derived from 
                                  Wharton’s jelly, which is processed to preserve the structural 
                                  integrity and original characteristics of the donor."
            productImage="cryotextvial"
          />
        </Link>
        <Link to={"/public/products/6"} className='product-card-link'>
          <ProductCard
            product="SECRETEXT™ Pro"
            brand="Regenative Labs"
            vendor="STP100-32"
            description="This is a connective tissue supplement intended for homologous use 
                                    only, as described in 21CFR 1271.10(a). SecreText™ is derived from 
                                    Wharton’s jelly, which is processed to preserve the structural 
                                    integrity and original characteristics of the donor."
            productImage="secretextvial"
          />
        </Link>
      </div>
    </div>
  )
}