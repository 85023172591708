import axios from 'axios'

import parseErrorObject from '../../../../helpers/parseErrorObject';



const API_DB_URL = process.env.REACT_APP_API_DB_URL



// Represents a data obj returned from DB, for table [mr_regen_global_coupons].
export interface CouponDetails {
    description: string;
    min_cart_value: number;
    min_cart_value_error: string,
    min_cart_items: number,
    min_cart_items_error: string,
    discount_dollars: number,
    discount_percent: number,
    db_msg_coupon_expired?: string,
}


/**
 * This is a massive function so moved it to its own file.
 * 
 * Get discounts after applying valid coupon code.
 *  - See notes about how coupons work in _getCartPrices.tsx.
 * 
 * Whenever the coupon code is changed, this function:
 *  - checks whether the entered coupon code is valid,
 *  - checks whether the user fulfills the requirements to use the coupon.
 *      - current checcks are for minimum cart size and minimum cart value in dollars.
 *  - sets state vars for cart-prices calculations to re-run (in _getCartPrices.tsx).
 *  - sets state vars to display messaging to user under the coupon-code input field.
 * 
 * See notes about how coupons work in _getCartPrices.tsx.
 * 
 * The backend here just returns the coupon object if it is all of:
 *      - approved
 *      - active
 *      - not expired
 *      - coupon code matches
 *  ... And DOESN'T consider whether there are enough items/dollars in the cart.
 *      - The fronentend, here, will handle that part.
 *  ... And both at the confirmation page and during final checkout, all of the coupons details 
 *      will be tested again for security reasons.
 * 
 * This function is DEBOUNCED, by its caller, then there's also a TIMEOUT on the backend,
 * to prevent brute forcing a coupon.
 */

const _handleCouponChange = (
    sessionJson: string,
    setCouponShowSpinner: React.Dispatch<React.SetStateAction<boolean>>,
    setCouponDiscountPercent: React.Dispatch<React.SetStateAction<number>>,
    setCouponDiscountDollars: React.Dispatch<React.SetStateAction<number>>,
    setCouponSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
    setCouponErrorMsg: React.Dispatch<React.SetStateAction<string>>,
    coupon: string,
    shoppingCartSize: number,
    cartBaseProductsPrice: number,
) => {
    setCouponDiscountPercent(0)
    setCouponDiscountDollars(0)

    if (!coupon) {
        setCouponSuccessMsg('')
        setCouponErrorMsg('')
        return
    }

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData()
    formData.append('session', sessionJson)
    formData.append('couponCode', coupon)


    axios.post(`${API_DB_URL}/cart/coupon-code/`,
        Object.fromEntries(formData), 
        config
    ).then(res => {
        setCouponShowSpinner(false)

        const couponObj = res.data || null

        // If no coupon obj found, or is expired, show a message.
        if (!couponObj) {
            setCouponSuccessMsg('')
            setCouponErrorMsg('Coupon code invalid')
        } else if (couponObj.hasOwnProperty('db_msg_coupon_expired')) {
            // The db says this coupon is expired, so just show the db message.
            setCouponSuccessMsg('')
            setCouponErrorMsg(couponObj.db_msg_coupon_expired || 'This coupon is expired')
        } else {
            // Coupon was found.

            // Make sure coupon code still has value
            const couponHasPercentValue = (!isNaN(couponObj.discount_percent) && couponObj.discount_percent > 0)
            const couponHasDollarValue = (!isNaN(couponObj.discount_dollars) && couponObj.discount_dollars > 0)
            if (!couponHasPercentValue && !couponHasDollarValue) {
                setCouponSuccessMsg('')
                setCouponErrorMsg("This coupon no longer has any value.")
                return
            }

            // TODO: need to handle when discount dollars (or even perccent) is greater than price.
            // TODO: need to update coupon messaging when cart size is change


            // Make sure user passes the requirements for using this coupon.
            const cartSizeReqPassed = !couponObj.min_cart_items || shoppingCartSize >= couponObj.min_cart_items
            const cartValueReqPassed = !couponObj.min_cart_value || cartBaseProductsPrice >= couponObj.min_cart_value
            const couponReqsPassed = cartSizeReqPassed && cartValueReqPassed

            // A couple of different success messages/statuses.
            // - And maybe an error message too if they don't have stuff enough in their cart.
            if (couponReqsPassed) {
                setCouponSuccessMsg('Coupon applied!\n' + couponObj.description)
                setCouponErrorMsg('')
                if (couponObj.discount_percent) {
                    setCouponDiscountPercent(couponObj.discount_percent)
                } else if (couponObj.discount_dollars) {
                    setCouponDiscountDollars(couponObj.discount_dollars)
                }
            } else {
                setCouponSuccessMsg('Coupon code valid')
                if (couponObj.min_cart_value) {
                    setCouponErrorMsg(couponObj.min_cart_value_error)
                } else {
                    setCouponErrorMsg(couponObj.min_cart_items_error)
                }
            }
        }
    }).catch(err => {
        setCouponShowSpinner(false)
        // Show an alert for errors
        const eMsg = parseErrorObject(err)
        alert(eMsg)
    })
}

export default _handleCouponChange