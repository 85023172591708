import { Icon } from '../../icon/Icon';
import './pill-button.scss';


interface Props {
    children: any,
    onClick?: Function,
    buttonStyle?: string,
    disabled?: boolean,
    style?: any,
    buttonType?: string,
    iconType?: string,
    reverse?: boolean,
    hideArrow?: boolean,
}


export const PillButton = ({ children, buttonStyle, disabled, style, iconType, 
                             buttonType, onClick, reverse, hideArrow }: Props) => {

    const selectIcon = () => {     
        if (iconType === 'add') {
            return (
                <div className='cross-box'>
                    <div className='add' />
                </div>
            )
        } else {
            return (
                
                <div className='custom-icon'>
                    <Icon name='arrow' />
                </div>
            )
        }
    }

    return (
        <button 
            style={style} 
            disabled={disabled} 
            className={buttonStyle + ' pill-button' + (buttonType ?? '')}
            onClick={(e) => (onClick && !disabled) ? onClick(e) : null}
        >
            {(reverse) ? (
                <>
                {selectIcon()}{children}
                </>
            ) : ((hideArrow) ? (
                <>
                {children}
                </>
            ) : (
                <>
                {children}{selectIcon()}
                </>
            ))}
        </button>
    );
    }