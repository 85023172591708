

export enum HeaderAction {
    CHANGE_SECTION_HEADER = 'CHANGE_SECTION_HEADER',
    NO_SECTION_HEADER = 'NO_SECTION_HEADER',
};




export type SectionHeaderPayload = {
    image: 'doctor-signing',
    sectionTitle: string
};


export const changeSectionHeader = (newSectionHeader: SectionHeaderPayload) => {
    return {
        type: HeaderAction.CHANGE_SECTION_HEADER,
        payload: newSectionHeader,
    }
};


export const noSectionHeader = () => {
    return {
        type: HeaderAction.NO_SECTION_HEADER,
        payload: { image: '', sectionTitle: '' },
    }
};