import { PillButton } from "../../buttons/pill-button/PillButton";
import { DoctorCard } from "./DoctorCard";
import './manual-carousel.scss';


interface Props {
  name?: string,
  practice?: string,
  description?: string,
};


export const ManualCarousel = ({ }: Props) => {

  return (

    <div className="carousel-body">
      <div className="carousel-container">
        <div className="carousel-place-card">
          <input type="radio" name="slider" id="item-1" checked />
          <input type="radio" name="slider" id="item-2" checked />
          <input type="radio" name="slider" id="item-3" checked />
          <div className="cards">
            <label className="card" htmlFor="item-1" id="display-1">
              <DoctorCard imgSrc='' name="NAME SURNAME" practice="General Practice" description=" test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test " />
              <div className="button-placement">
                <PillButton
                  style={{}}
                  buttonStyle="white gradient"
                >
                  VIEW PROFILE
                </PillButton>
              </div>
            </label>
            <label className="card" htmlFor="item-2" id="display-2">
              <DoctorCard imgSrc='' name="NAME SURNAME" practice="General Practice" description="test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test " />
              <div className="button-placement">
                <PillButton
                  style={{}}
                  buttonStyle="white gradient"
                >
                  VIEW PROFILE
                </PillButton>
              </div>
            </label>
            <label className="card" htmlFor="item-3" id="display-3">
              <DoctorCard imgSrc='' name="NAME SURNAME" practice="General Practice" description="test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test " />
              <div className="button-placement">
                <PillButton
                  style={{}}
                  buttonStyle="white gradient"
                >
                  VIEW PROFILE
                </PillButton>
              </div>
            </label>
          </div>
          <div className="slider">
          </div>
        </div>
      </div >
    </div >

  );
}