import React from "react";
import "./progress-bar.scss"


interface Props {
    whichStep: number;
    numberOfSteps: number;
}

export const ProgressBar = ({ whichStep, numberOfSteps }: Props) => {

    // Make an array that is as long as `numberOfSteps`
    const list = Array.from(Array(numberOfSteps))

    return (
        <div className="div-contain-progress-bar">
            {list.map((_, index) =>
                (index + 1 <= whichStep) ? (
                    (index + 1 < whichStep) ? (
                        // Completed steps
                        <React.Fragment key={index}>
                            <div className="progress-circle">
                                <div className="progress-circle checkmark" />
                            </div>
                            <div className="progress-line" />
                        </React.Fragment>
                    ) : (
                        // Current step
                        <React.Fragment key={index}>
                            <div key={index} className="progress-circle white-circle">
                                <div className="progress-circle checkmark blue-check" />
                            </div>
                        </React.Fragment>
                    )
                ) : (
                    // Upcoming steps
                    <React.Fragment key={index}>
                        <div className="progress-line disabled" />
                        <div className="progress-circle disabled" />
                    </React.Fragment>
                )
            )}
        </div>
    )
}