import React, { useEffect, useState } from 'react'

import { BlockButton } from "../buttons/block-button/BlockButton";
import { Modal } from "./Modal"

import { MyAccountInfo } from '../../../../config';

import LoadingCircle from '../../../static/gif/loading-circle.gif'

import './tag-tree-modal.scss'



type Props = {
    show: boolean;
    closeModal: Function;
    accountInfo: MyAccountInfo;
    flatData: any[];
    tagTreeData: any; // Tag-tree multi-level dictionary from backend.
    tagTreeUserCount: number|null;
    tagTreeIsLoading: boolean;
    tagTreeErrorMessage: string;
}




export const TagTreeModal = ({ 
    show, closeModal,
    accountInfo,
    flatData,
    tagTreeData,
    tagTreeUserCount,
    tagTreeIsLoading,
    tagTreeErrorMessage,
}: Props) => {
    const [JSXarr, setJSXarr] = useState<any[]>([])

    const name = (userRow: any) => {
        return [userRow.first_name, userRow.last_name].join(' ').replace(/\s+/g, ' ').trim()
    }

    // Recursively create list of JSX elements to display the tag-hierarchy tree.
    const recursiveBuild = (data: any, depth = 0, key_add = 0) => {
        if (!data) {
            return ([<div>.</div>])
        }

        const datas: any[] = depth === 0 ? Object.values(data) : [data]
        const results = []

        let iter = 0
        for (let data of datas) {
            iter++;

            if (!data || !data.obj || !data.children) {
                return []
            }

            const { obj, children } = data

            if (obj) {
                const el = (
                    <div className='indent' key={`${depth}-${key_add}-${iter}`}>
                        <div className='rep-name-email'>
                            {/* User's name */}
                            <div className='no-hover-show'>
                                {name(obj)}
                            </div>
                            <div className='on-hover-show'>
                                {/* User ID */}
                                <div className='user-id-slot' title='User ID'>
                                    {obj.id}
                                </div>
                                {/* Email address */}
                                <div className='email-slot'>
                                    {obj.email.email}
                                </div>
                            </div>

                        </div>
                        <br/>
                        {children.map((childObj: any, index: number) => (
                            <React.Fragment key={`i-${index}-${index}`}>
                                {recursiveBuild(childObj, depth + 1, index + 1)?.map(childJsx => (
                                    (childJsx)
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                )
                results.push(el)
            }
        }

        return results
    }
    
    
    // When the tag-tree data comes in, create the JSX elements for it to show in UI.
    useEffect(() => {
        const res = recursiveBuild(tagTreeData, 0, -1)
        setJSXarr(res)
    }, [tagTreeData])


    
    return (
        <div className='tag-tree-modal'>
            <Modal
                modalType={2}
                show={show}
                closeModal={closeModal}
            >
                <div className="modal-contents">
                    <h2 className="title">
                        Tag Hierarchy
                    </h2>
                    {(tagTreeIsLoading) ? (
                        <div className="sub-title">
                            Loading
                        </div>
                    ) : null}

                    {/* 
                      * Make nested display up to 8 levels deep, and if there's more, show a message.
                      * - TODO: make this cleaner. Could make an array of JSX
                      */}
                    <div className='tag-tree-container'>
                        {tagTreeIsLoading ? (
                            <img src={LoadingCircle} className='preload-spinner' style={{opacity: 1}} />
                        ) : (tagTreeErrorMessage) ? (
                            // Error message if a circular reference was identified, or if 
                            // the tag tree depth > 8 (8 is defined on backend as the max_depth).
                            <div className='error-message'>
                                {tagTreeErrorMessage}
                            </div>
                        ) : (
                            // The main tag-tree UI display.
                            (JSXarr) 
                        )}
                    </div>

                    {/* 
                      * There is always some risk that someone will create a circular reference.
                      * That would be bad but it's going to take forever to test and make it 
                      * bullet-proof, so just accept that it's going to happens sometimes.
                      * - When it does happen, they won't see the correct number of users in their
                      *   tag tree. 
                      * - So when the count of users is wrong, show a message that they might
                      *   have a circular reference and how to fix it.
                      * 
                      * In the below conditional, minus 1 is bc the flatData list also includes the
                      * rep-lead (ie. the user who is lokoking at this tree), but the tree data
                      * does not.
                      */}
                    {(
                        tagTreeData !== null 
                        && tagTreeUserCount !== flatData.filter(row => row.active).length - 1
                    ) ? (
                        <div className='has-missing-items-message'>
                            It looks like some of your reps are missing from this tree. This can happen if:<br/>
                            <ul style={{listStyle: 'unset', padding: 'unset', marginLeft: '30px'}}>
                            <li>there is a circular tag reference between one or more of your reps,</li>
                            <li>if one of your active reps isn't assigned to anyone,</li>
                            <li>or if the tags hierarchy has changed since you last refreshed the page.</li>
                            </ul>
                            <br/>
                            If after refreshing the page you continue to see this message, reset them
                            by reassigning the reps to yourself.
                        </div>
                    ) : null}
                    

                    <div className='buttons-row'>
                        <BlockButton
                            type='button'
                            style={{ marginTop: '40px' }} 
                            buttonStyle="ghost-purple"
                            onClick={closeModal}
                        >
                            Close
                        </BlockButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
};