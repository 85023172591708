import { useEffect } from 'react';
import { useLocation } from 'react-router';

/** 
 * Automatically scrolls browser to top on every page load .
 */
const ScrollToTop = () => {
	const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null
}

export default ScrollToTop;