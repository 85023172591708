import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account'
import { formatAWsCognitoErrorMessage, convertAwsEmailToUsername } from '../../../../helpers/messageParserAws'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton'
import { InputField } from '../../../shared/form/input-field/InputField'
import { SelectField } from '../../../shared/form/select-field/SelectField'
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner"
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage'
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar"
import { VerificationCodeModal } from '../../../shared/modal/VerificationCodeModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject'

import { ACCOUNT_TYPES_DROPDOWN_OPTIONS, ACCOUNT_TYPES_FLAT, ACCOUNT_TYPES_TO_REG_URL } from '../../../../constants/accountType'

import LoadingCircle from '../../../../static/gif/loading-circle.gif'

import './consumer-registration.scss'
import 'react-toastify/dist/ReactToastify.css'




const API_DB_URL = process.env.REACT_APP_API_DB_URL



/**
 * First (and only) step of Consumer registration.
 * Only this step is visible to non-logged-in users.
 * Upon successful completion of form and Cognito email code verification, redirect to login page,
 *      which will subsequently redirect to Home page after login.
 */
export const ConsumerRegistration1 = () => {
    const STEP = 1
    const THIS_ENTITY_TYPE = 'rep' // TODO: this needs a check/test so it doesn't get changed.

    // Auth/session functions
    const { register, resendVerification, getUsernameAndIdToken } = useContext(AccountContext)

    // Message based on query parameters
    const [referrerMessage, setReferrerMessage] = useState('')

    // Pre-load spinner
    const [showPreloadSpinner, setShowPreloadSpinner] = useState(true)

    // Session data
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean|null>(null)
    const [hasDbData, setHasDbData] = useState<boolean|null>(null)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // For input fields
    const [userType, setUserType] = useState('')
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [npi, setNpi] = useState('')
    const [isRepLead, setIsRepLead] = useState<string|null>(null) // Must be sent to backend as EMPTY string for "false"
    const [isRepMember, setIsRepMember] = useState<string|null>(null) // Must be sent to backend as EMPTY string for "false"
    const [isIndy, setIsIndy] = useState<string|null>(null) // Must be sent to backend as EMPTY string for "false"
    const [orgName, setOrgName] = useState('')
    const [streetAddress1, setStreetAddress1] = useState('')
    const [streetAddress2, setStreetAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [phone, setPhone] = useState('')
    const [website, setWebsite] = useState('')

    // For Rep-Org search
    const [searchOrgName, setSearchOrgName] = useState('')
    const [backendRepOrgs, setBackendRepOrgs] = useState<any[]>([]) // Search against these.
    const [searchResultOrgName, setSearchResultOrgName] = useState('') // Selected org
    const [searchSelectedOrgId, setSearchSelectedOrgId] = useState(0) // Selected org
    const SEARCH_DEBOUNCER = useRef<number>(0)
    const SEARCH_DEBOUNCE_TIME = 500; // ms
    const [showSearchSpinner, setShowSearchSpinner] = useState(false)
    const [searchResultRows, setSearchResultRows] = useState<any[]>([])

    const [emailError, setEmailError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [awsUsername, setAwsUsername] = useState('')
    const [showResendVerificationCode, setShowResendVerificationCode] = useState(false)
    const [hideAllErrors, setHideAllErrors] = useState(false)
    // For error messages sent back from AWS Cognito service after failed attempt to register.
    const [cognitoErrorMessage, setCognitoErrorMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // Modal to show loading status and for entering verification code.
    const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()
    // Users who just need to enter their validation code.
    const [justVerifyEmail, setJustVerifyEmail] = useState<boolean|null>(null)

    // On mount
    useEffect(() => {
        // Get query parameters from url.
        const qp = qsToDict(window.location.search)

        // Show message if user referred here after login. Normally this won't happen on step 1, 
        // unless the user was manually created through Cognito GUI.
        if (qp.reason === 'reg-incomplete') {
            setReferrerMessage('Please complete your registration')
        }

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Try to prefill user info. This will return empty object if user isn't logged in.
        getPrefilledData()

        // For redirects for users who just need to enter their email verification code.
        if (qp.mode === 'verify-email-redirect' && qp.email && qp.userTypeNum) {
            setJustVerifyEmail(true)
            const _userType = qp.userTypeNum // string
            const _userEmail = decodeURIComponent(qp.email)
            setUserType(_userType)
            setEmail(_userEmail)
            const _awsUsername = convertAwsEmailToUsername(_userEmail)
            setAwsUsername(_awsUsername)
            setShowVerificationCodeModal(true)
        } else {
            setJustVerifyEmail(false)
        }
    }, [])

    /**
     * Make sure these fields are valid before sending data to AWS Cognito.
     * 
     * These 3 fields will be sent to AWS Cognito for registering a new user:
     *  -  Email, password, and user type (account/entity type)
     *  -  Assume that entity type is auto-filled and valid.
     * Fail silently - backend will handle data errors, too.
     * 
     * TODO: it would be better to check POST values instead of just the input text contents.
     */
    const frontendValidationErrors = () => {
        let errorCount = 0

        try {
            // Always check for email.
            if (!email || email.indexOf('@') === -1 || email.indexOf('.') === -1) {
                setEmailError('Please enter a valid email address')
                errorCount += 1
            } else {
                setEmailError('')
            }

            // Only check for password if this is a new user (i.e. not updating).
            if (!hasDbData && (!password || !passwordConfirm || password !== passwordConfirm)) {
                setPasswordError('Passwords must match')
                errorCount += 1
            } else {
                setPasswordError('')
            }

            return errorCount
        } catch (e) {
            return 0
        }
    }

    /** 
     * Redirect to appropriate registration page if user type is changed.
     */
    useEffect(() => {
        if (justVerifyEmail !== false) return
        if (!userType || isNaN(Number(userType)) || Number(userType) < 0) return
        let userTypeName = ACCOUNT_TYPES_FLAT[Number(userType)]
        userTypeName = userTypeName[0].toUpperCase() + userTypeName.slice(1,).toLowerCase() // capital-case
        const currentPath = window.location.pathname
        let targetPath = `${ACCOUNT_TYPES_TO_REG_URL[userTypeName]}/1`
        if (isPreviewMode) {
            targetPath += '?mode=preview'
        }
        if (currentPath !== targetPath) {
            window.location.href = targetPath
        }
    }, [userType, isPreviewMode])


    /**
     * On mount, get list of rep-orgs for search field.
     */
    useEffect(() => {
        axios.post(`${API_DB_URL}/new-user-init-consumer/1/get-rep-org-list`, 
            {}, // (no post data needed) 
        ).then(async (res) => {
            const data = res?.data
            if (!data) {
                // Note: this error only affects users who need to search for a REP-ORG.
                setErrorMessage('System error: failed to retrieve some data')
                return
            }
            setBackendRepOrgs(data)
        }).catch(async (err) => {
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8802aGc'))
            }, 350)
        })
    }, [])

    /**
     * Pre-fill information if user completed this step already.
     */
    const getPrefilledData = async () => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            // This probably means user isn't logged in.
            setIsLoggedIn(false)
            setHasDbData(false)
            setShowPreloadSpinner(false)
            return 
        }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData() 
        formData.append('session', sessionJson)
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/get-consumer-data/step/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            setEmail(data.email)
            // Getting this data means user is logged in, unless the data obj above is empty
            setIsLoggedIn(!!data.email)
            setHasDbData(!!data.email)
        }).catch(async (err) => {
            setIsLoggedIn(false)
            setHasDbData(false)
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8802aGc'))
            }, 350)
        }).finally(() => {
            setShowPreloadSpinner(false)
        })
    }

    /** 
     * Submit regisration Step 1 data to backend & Cognito to create a new user.
     */
    const onSubmitRegistrationForm = async (
        e: React.FormEvent<HTMLFormElement>, 
        isRepLead: string|null,
        isRepMember: string|null,
        isIndy: string|null,
        selectedOrgId: number,
    ) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        // Make sure only one type is selected.
        if (
            (!!isRepLead && (!!isRepMember || !!isIndy)) 
            || (!!isRepMember && (!!isRepLead || !!isIndy)) 
            || (!!isIndy && (!!isRepMember || !!isRepLead)) 
        ) {
            setErrorMessage('Multiple account types are selected. Please refersh and try again')
            return
        }

        // Make sure a user type is selected.
        if (!isRepLead && !isRepMember && !isIndy) {
            setErrorMessage('No user type is selected. Please refresh and try again.')
            return
        }

        setShowSpinner(true)

        // Check the 3 inputs that will be sent to AWS Cognito before registering.
        // (React will confirm that the other required fields at least have some value entered)
        if (frontendValidationErrors()) { 
            setShowSpinner(false)
            return 
        }

        /** Return Cognito user info after validating */
        // User is expected to NOT be logged in here so expect `session.error` to have a message.
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        // Only show error if error code doesn't include #88847 - that one is the not-logged-in error.
        if (session.error && !session.error.includes('#88847')) { 
            setHideAllErrors(false);
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)

        /** Config for API call. */
        const config = {'headers': {'content-type': 'multipart/form-data'}}

        /** Form data */
        const formData = new FormData(e.target as HTMLFormElement)  
        formData.append('session', sessionJson)
        // Convert email to username. See notes on why this is needed in this function's definition.
        const _awsUsername = convertAwsEmailToUsername(email)
        setAwsUsername(_awsUsername)
        formData.append('username', _awsUsername)
        // We don't need these fields to be sent to backend, so delete.
        formData.delete('password')
        formData.delete('passwordConfirm')
        formData.delete('entityTypeIndex')
        // Clean up these fields so that only truthy values aren't empty.
        const _isRepLead = String(isRepLead || '')
        const _isRepMember = String(isRepMember || '')
        const _isIndy = String(isIndy || '')

        if (_isRepLead !== 'true') { formData.set('isRepLead', '') }
        if (_isRepMember !== 'true') { formData.set('isRepMember', '') }
        if (_isIndy !== 'true') { formData.set('isIndy', '') }
        formData.append('selectedOrgId', String(selectedOrgId || '0'))



        /**
         * If data should be UPDATED in DB as opposed to created.
         */
        if (hasDbData) {
            axios.post(`${API_DB_URL}/update-consumer-data/${STEP}`, 
                Object.fromEntries(formData), 
                config,
            ).then(async () => {
                const qpOut = {
                    reason: 'updated',
                }
                if (isEdit) {
                    window.location.href = buildPath(`/account`, qpOut)
                } else {
                    window.location.href = buildPath(`/consumer-registration/${STEP+1}`, qpOut)
                }
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802aGUc'))
                }, 350)
            })
        }

        /**
         * Create new user:
         *  - Write record in db, then post to Cognito, then update db w/cognito info.
         *      - If registration successful, redirect to url for step 2.
         *      - If the user already exists in db, then tell them to login.
         */
        if (!hasDbData) {
            axios.post(`${API_DB_URL}/new-user-init-consumer/${STEP}/a`, 
                // Step 1: Create the user object in DB, and throw error if user already exists.
                Object.fromEntries(formData), 
                config
            ).then(async (res) => {
                // Step 2: Create the user in Cognito.
                const [user, userTypeId, userTypeName, is_new_user, codeForECs] = res.data
                let cognitoSuccess = true
                const cognitoData = await register(
                    _awsUsername, email, password, user.id, userTypeId, userTypeName
                ).catch((err: Error) => {
                    // See possible exception types here:
                    //  - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
                    if (err.name === 'UsernameExistsException') {
                        if (user.is_reregister) {
                            resendVerification(_awsUsername)
                            return null
                        } else if (is_new_user) {
                            // This block is for edge case #16778 
                            cognitoSuccess = false
                            return null
                        } else {
                            // NOT an edge case (though it a "potential" EC #16789 on the backendi)
                            throw new Error (`You already have an account, please login.`)
                        }
                    } else {
                        // For normal errors, show the error message and stop.
                        setHideAllErrors(false)
                        setCognitoErrorMessage(err.name + ': ' + err.message)
                        throw new Error('') // to stop exectuion while diplsay above error in correct div.
                    }
                })
                setHideAllErrors(true)
                return [cognitoData, user.id, cognitoSuccess, is_new_user, user.is_reregister, codeForECs]
            }).then(async ([cognitoData, userId, cognitoSuccess, is_new_user, is_reregister, codeForECs]) => {
                // Save cognito data to db to complete registration.
                // Note that backend will handle when `cognitoData` is null for edge case #16778.
                // if (!is_reregister) {
                    await axios.post(`${API_DB_URL}/new-user-init-consumer/${STEP}/b`, 
                        { session: sessionJson, userId, cognitoData, is_new_user, codeForECs }, 
                        config
                    ).catch(err => {
                        throw err
                    })
                // }
                return [cognitoSuccess, is_reregister]
            }).then(async ([cognitoSuccess, is_reregister]) => {
                if (is_reregister) {
                    // If this user registered before but never verified email, then basically 
                    // treat them as new user.
                    // - (don't do anything here)
                } else if (!cognitoSuccess) {
                    // If Edge Case #16778 was hit, just go straight to login page without verification code.
                    const qpOut = {
                        referrer: `/consumer-registration/${STEP}`,
                        redirectTo: `/consumer-registration/${STEP + 1}`, 
                        reason: '#16778',
                    }
                    window.location.href = buildPath('/auth/login/', qpOut)
                }
                // All steps successful
                // (Redirect to login page is handled by `ShowVerificationCodeModal`)
                setHideAllErrors(true)
                setShowResendVerificationCode(true)
                setShowVerificationCodeModal(true)
                setShowSpinner(false)
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802Gc'))
                }, 350)
            })
        }
    }


    /**
     * Run search for organizations for REP-MEMBER types
     * ================================================================================
     */
    // Gets a max of 5 results to display. Then add to `searchResultRows`.
    const runOrgSearch = (_searchStr: string, _searchResultRows: any[]) => {
        clearTimeout(SEARCH_DEBOUNCER.current)
        SEARCH_DEBOUNCER.current = window.setTimeout(() => {
            // Always clear their selection when they are searching.
            setSearchResultOrgName('')
            setSearchSelectedOrgId(0)

            // If no string, clear the search results.
            if (!_searchStr || !_searchStr.trim()) {
                setSearchResultRows([])
                return
            }
            const results = []
            // Use no-space lowercase strings for search, and remove all punctuation.
            const searchStr = (_searchStr || '').toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, "")

            for (let row of _searchResultRows) {
                // Format the searched fields
                const rName = (row.name || '').toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, "")
                const rNameDisplay = (row.name_display || '').toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, "")
                if (rName.includes(searchStr) || rNameDisplay.includes(searchStr)) {
                    const searchResultObj = {
                        id: row.id,
                        name: row.name_display || row.name,
                    }
                    results.push(searchResultObj)
                }
            }
            setSearchResultRows(results)

        }, SEARCH_DEBOUNCE_TIME)
    }


    // Trigger search when typing in search box.
    useEffect(() => {
        runOrgSearch(searchOrgName, backendRepOrgs)
    }, [searchOrgName, backendRepOrgs])

    // Clear search results whenever user selects or deselects the `isRepMember` option.
    useEffect(() => {
        setSearchOrgName('')
        setSearchSelectedOrgId(0)
        setSearchResultRows([])
        // Also clear the other user types.
        if (isRepLead !== null) { setIsRepLead('') }
        if (isIndy !== null) { setIsIndy('') }
    }, [isRepMember])

    // For changing user types
    useEffect(() => {
        if (isRepMember !== null) { setIsRepMember('') }
        if (isIndy !== null) { setIsIndy('') }
    }, [setIsRepLead])

    useEffect(() => {
        if (isRepLead !== null) { setIsRepLead('') }
        if (isRepMember !== null) { setIsRepMember('') }
    }, [isIndy])





    return (
        <>
            {!justVerifyEmail ? (
                <HeaderBanner
                    imageName="doctorpen"
                    imageText={isEdit ? 'EDIT ACCOUNT' : 'CREATE ACCOUNT'}
                    imageSpacerColor='BLUE'
                />
            ) : null }
            {/* Shows form to input emailed verification code, and redirects to login page on success */}
            <VerificationCodeModal
                awsUsername={awsUsername}
                referrer={`/consumer-registration/${STEP}`}
                redirectTo={`/consumer-registration/${STEP+1}` + (!!isRepMember ? '?repRequestEmailSent=true' : '')}
                show={showVerificationCodeModal}
                setShow={setShowVerificationCodeModal}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
                spinnerText={spinnerText}
                setSpinnerText={setSpinnerText}
                justVerifyEmail={!!justVerifyEmail}
            />

            <PreviewModeMessage show={!!isPreviewMode} link={'/consumer-registration/1'} />

            <div 
                className="consumer-register-page-layout"
                // For users who just need to verify email code, use invisible so it doesn't collapse.
                style={{ visibility: justVerifyEmail ? 'hidden' : 'visible' }}
            >
                {!isEdit ? (
                    <p className="body-container top-paragraph">
                        This is the Rep registration form. 
                        <br/><br/>
                        Fill out this form if you intend to sell products for commission.
                        <br/><br/>
                        {!isPreviewMode ? (
                            <>
                                To see a preview of what information you will need when registering,&nbsp;
                                <Link to={'?mode=preview'} className='preview-link'>
                                    click here
                                </Link>.
                            </>
                        ): null}
                    </p>
                ) : null}
                
                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                {!isEdit ? (
                    <div className="progress-bar-placement">
                        <ProgressBar whichStep={1} numberOfSteps={2} />
                    </div>
                ) : null}

                {/* Only show the form after checking backend for pre-fill data. */}
                <div 
                    className="body-container form-layout"
                    style={{ display: (showPreloadSpinner ? 'none' : 'block') }}
                >
                    <form onSubmit={(e) => onSubmitRegistrationForm(e, isRepLead, isRepMember, isIndy, searchSelectedOrgId)} aria-autocomplete='none'>
                        <div className="header">
                            ACCOUNT TYPE
                        </div>
                        <div className="field-group-wrapper">
                            <SelectField
                                required
                                id="consumer-registration-accounttype"
                                name='entityTypeIndex'
                                options={ACCOUNT_TYPES_DROPDOWN_OPTIONS}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    const usrType = Number(event.target.value) - 1
                                    if (!isNaN(usrType)) {
                                        setUserType(String(usrType))
                                    }
                                }}
                                altDisplay={true}
                                selectedIndex={ACCOUNT_TYPES_FLAT.findIndex(type => {
                                    return type.toLowerCase() === THIS_ENTITY_TYPE.toLowerCase()
                                })}
                                firstOptionIsNull={true}
                                style={{width: 'calc(50% - 12.5px)'}}
                                disabled={justVerifyEmail ? true : isLoggedIn === false ? false : true} // `isLoggedIn` can be null
                            />
                        </div>
                        <div className="header">
                            PERSONAL INFORMATION
                        </div>
                        <div className="field-group-wrapper">
                            <div className='field-row'>
                                <InputField
                                    required
                                    id="rep-registration-firstname"
                                    name='firstName'
                                    placeholder='First Name'
                                    altDisplay={true}
                                    value={firstName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFirstName(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                                <InputField
                                    required
                                    id="rep-registration-lastname"
                                    name='lastName'
                                    placeholder='Last Name'
                                    altDisplay={true}
                                    value={lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setLastName(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            <div className="field-row">
                                <InputField
                                    required
                                    id="rep-registration-npi"
                                    name='npi'
                                    placeholder='NPI'
                                    altDisplay={true}
                                    value={npi}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setNpi(event.target.value)
                                    }}
                                    style={{width: 'calc(50% - 12.5px)'}}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                        </div>
                        <div className="header">
                            ORGANIZATION INFORMATION
                        </div>
                        <div className={`field-group-wrapper ${isRepLead ? '' : 'hide-slow'}`}>
                            <div className='radio-row'>
                                <div className='options'>
                                    <input type="radio" id="ua0" name="UA" checked={!!isRepLead} onChange={() => setIsRepLead('true')} /><label htmlFor="ua0">True</label>
                                    <input type="radio" id="ua1" name="UA" checked={isRepLead === ''} onChange={() => setIsRepLead('')} /><label htmlFor="ua1" >False</label>
                                </div>
                                <div className='radio-title'>
                                    I manage an organization of multiple Reps
                                </div>
                            </div>

                            {/* The only only shows if the above radio is selected "False" */}
                            <div className={`radio-row ${isRepLead !== '' ? 'hide-slow' : ''}`}>
                                <div className='options'>
                                    <input type="radio" id="ub0" name="UB" checked={!!isRepMember} onChange={() => setIsRepMember('true')} /><label htmlFor="ub0">True</label>
                                    <input type="radio" id="ub1" name="UB" checked={isRepMember === ''} onChange={() => setIsRepMember('')} /><label htmlFor="ub1" >False</label>
                                </div>
                                <div className='radio-title'>
                                    I work for an organization of Reps
                                </div>
                            </div>

                            {/* The only only shows if both of the above radios are selected "False". This one cannot be selected "False". */}
                            <div className={`radio-row ${(isRepLead !== '' || isRepMember !== '') ? 'hide-slow' : ''}`}>
                                <div className='options'>
                                    <input type="radio" id="uc0" name="UC" checked={!!isIndy} onChange={() => setIsIndy('true')} /><label htmlFor="uc0">True</label>
                                    <input type="radio" id="uc1" name="UC" checked={false} disabled/><label htmlFor="uc1" >False</label>
                                </div>
                                <div className='radio-title'>
                                    I am an independent Rep (you can still be invited to an org later)
                                </div>
                            </div>

                            {/* 
                              * REP-LEADER SECTION ==================================
                              */}
                            
                            {/* <div className={`field-row ${isRepLead ? '' : 'hide-slow'}`}>
                                <div className='directions-text'>
                                    Enter you rorganization info below.
                                </div>
                            </div> */}
                            <div className={`field-row ${isRepLead ? '' : 'hide-slow'}`}>
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-orgname"
                                    name='orgName'
                                    placeholder='Organization Name'
                                    altDisplay={true}
                                    value={orgName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setOrgName(event.target.value)
                                    }}
                                    style={{width: 'calc(50% - 12.5px)'}}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            <div className={`field-row ${isRepLead ? '' : 'hide-slow'}`}>
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-streetAddress1"
                                    name='streetAddress1'
                                    placeholder='Street Address'
                                    altDisplay={true}
                                    value={streetAddress1}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setStreetAddress1(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-streetAddress2"
                                    name='streetAddress2'
                                    placeholder='Unit/Suite'
                                    altDisplay={true}
                                    value={streetAddress2}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setStreetAddress2(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            <div className={`field-row ${isRepLead ? '' : 'hide-slow'}`}>
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-city"
                                    name='city'
                                    placeholder='City'
                                    altDisplay={true}
                                    value={city}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setCity(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-state"
                                    name='state'
                                    placeholder='State'
                                    altDisplay={true}
                                    value={state}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setState(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                    maxLength={2}
                                />
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-zip"
                                    name='zip'
                                    placeholder='Zip'
                                    altDisplay={true}
                                    value={zip}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setZip(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            <div className={`field-row ${isRepLead ? '' : 'hide-slow'}`}>
                                <InputField
                                    required={!!isRepLead}
                                    id="rep-registration-phone"
                                    name='phone'
                                    placeholder='Phone'
                                    altDisplay={true}
                                    value={phone}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPhone(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                                <InputField
                                    required={false}
                                    id="rep-registration-website"
                                    name='website'
                                    placeholder='Website'
                                    altDisplay={true}
                                    value={website}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWebsite(event.target.value)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                            </div>

                  

                            {/* 
                              * SEARCH FIELD (REP ORGANIZATIONS) ==================================
                              */}
                            <div 
                                className={`field-row ${(isRepLead === '' && isRepMember === 'true') ? '' : 'hide-slow'}`}
                                style={{
                                    maxHeight: `${(isRepLead === '' && isRepMember === 'true' && !searchSelectedOrgId) ? '100px' : '0px'}`,
                                    // gap: `${(searchResultRows.length && !searchSelectedOrgId) ? '25px' : '0px'}`,
                                }}
                            >
                                <InputField
                                    required={!!isRepMember}
                                    id="rep-registration-org-name-search"
                                    name='org-name-search'
                                    placeholder='Search for your organization'
                                    altDisplay={true}
                                    value={searchOrgName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSearchOrgName(event.target.value)
                                    }}
                                    style={{display: isRepMember === 'true' ? 'block' : 'none'}}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            {/* 
                              * SEARCH RESULTS LIST ==================================
                              * ALSO SELECTED SEARCH RESULT ==================================
                              */}
                            <div 
                                className='directions-text'
                                style={{maxHeight: `${(isRepMember && searchResultRows.length) ? '30px' : '0px'}`, marginTop: '-4px'}}
                            >
                                {searchSelectedOrgId ? (
                                    'Selected organization:'
                                ) : (
                                    <span style={{color: '#f34'}}>
                                        Select one of the following results
                                    </span>
                                )}
                            </div>
                            <div className='search-result-rows' style={{maxHeight: `${isRepMember && searchResultRows.length ? '500px' : '0px'}`}}>
                                {searchResultRows.map((row, index) => (
                                    // If there's a selected search result, show only that one, and color it.
                                    <div 
                                    key={`${index}-${row.id}`}
                                        className='search-result-row-container'
                                    >
                                        <div 
                                            className={
                                                `search-result-row ` +
                                                `${(searchSelectedOrgId && row.id !== searchSelectedOrgId) ? 'collpase' : 
                                                (searchSelectedOrgId && row.id === searchSelectedOrgId) ? 'highlight' : 
                                                ''
                                                }`}
                                            onClick={() => {
                                                setSearchResultOrgName(row.name)
                                                setSearchSelectedOrgId(row.id)
                                            }}
                                        >
                                            {row.name}
                                        </div>
                                        {searchSelectedOrgId && row.id === searchSelectedOrgId ? (
                                            <button 
                                                className="x-button"
                                                onClick={() => {
                                                    setSearchResultOrgName('')
                                                    setSearchSelectedOrgId(0)
                                                }}
                                            >
                                                &#10006;
                                            </button>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <div className={`field-row ${(isRepLead !== '' || isRepMember !== '' || isIndy !== 'true') ? 'hide-slow' : ''}`}>
                                <div className='directions-text'>
                                    You can skip this section
                                </div>
                            </div>
                            <div className={`field-row ${searchSelectedOrgId ? '' : 'hide-slow'}`}>
                                <div className='directions-text'>
                                    A verification email will be sent to the owner of this organization's 
                                    account to to confirm your membership.
                                </div>
                            </div>
                            
                            {/* 
                              * REP HIDDEN FIELDS ==================================
                              */}

                            {/* Hidden fields to make sure they select the radio buttons for Organization 
                              * Information, and so backend knows what type of Rep they're signing up as.
                              */}
                            <div style={{height: 0, width: 0, overflow: 'hidden', opacity: 0}}>
                                {/* At least one of the radio buttons needs to be true */}
                                <InputField
                                    required={true}
                                    id="rep-radio-needs-selection"
                                    name='rep-radio-needs-selection'
                                    value={(!!isRepLead || !!isRepMember || !!isIndy) ? 'ok' : ''}
                                />
                                <InputField
                                    required={false}
                                    id="rep-radio-isRepLead"
                                    name='isRepLead'
                                    value={!!isRepLead}
                                />
                                <InputField
                                    required={false}
                                    id="rep-radio-isRepMember"
                                    name='isRepMember'
                                    value={!!isRepMember}
                                />
                                <InputField
                                    required={false}
                                    id="rep-radio-isIndy"
                                    name='isIndy'
                                    value={!!isIndy}
                                />

                            </div>
                            


                        </div>
                        <div className="header">
                            LOGIN INFORMATION
                        </div>
                        <div className="field-group-wrapper">
                            <InputField
                                required
                                id="consumer-registration-email"
                                name='email' // Don't change this
                                type='email'
                                placeholder='Email Address'
                                altDisplay={true}
                                value={email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(event.target.value)
                                }}
                                errorMessage={emailError}
                                preventAutofill={true}
                                style={{width: 'calc(50% - 12.5px)'}}
                                // If user is logged in, don't let them change their email.
                                disabled={isPreviewMode ? true : isLoggedIn === false ? false : true} // `isLoggedIn` can be null
                            />
                            {/* Don't show password fields if user is logged in already - password
                            * change should happen on a different page.
                            */}
                            {isLoggedIn === false ? (
                                <div className="field-row">
                                    <InputField
                                        required
                                        id="consumer-registration-password"
                                        name='password'
                                        type='password'
                                        placeholder='Password'
                                        altDisplay={true}
                                        value={password}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPassword(event.target.value)
                                        }}
                                        errorMessage={passwordError}
                                        preventAutofill={true}
                                        disabled={!!isPreviewMode}
                                    />
                                    <InputField
                                        required
                                        id="consumer-registration-passwordconfirmation"
                                        name='passwordConfirm'
                                        type='password'
                                        placeholder='Password Confirmation'
                                        altDisplay={true}
                                        value={passwordConfirm}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPasswordConfirm(event.target.value)
                                        }}
                                        preventAutofill={true}
                                        disabled={!!isPreviewMode}
                                    />
                                </div>
                            ) : null}
                        </div>

                        
                            
                        

                        {/* Error messages - from DB API */}
                        {(!hideAllErrors && errorMessage) ? (
                            <div className='error-message' data-type='db-api'>
                                {errorMessage}
                            </div>
                        ): null }

                        {/* Error messages - from AWS Cognito (must be a separate error var) */}
                        {(!hideAllErrors && cognitoErrorMessage) ? (
                            <div className='error-message' data-type='cognito'>
                                [_Cognito -- {cognitoErrorMessage}]
                            </div>
                        ): null }

                        {/* Test resend-verification-code link */}
                        {(showResendVerificationCode) ? (
                            <div className='resend-verification-code-link-container'>
                                If you don't see the email within 5 minutes, check your spam folder.
                                {/* <br/>
                                To send another verifcation link,&nbsp;
                                <a 
                                    href='#' 
                                    onClick={() => resendVerification(awsUsername)} 
                                    className='resend-link'
                                >
                                    click here
                                </a>. */}
                            </div>    
                        ) : ( null )}

                        <div className="buttons-container" style={{justifyContent: 'space-around'}}>
                            {isEdit ? (
                                <BlockButton
                                    type='button'
                                    style={{ width: '250px' }}
                                    buttonStyle="ghost-purple"
                                    onClick={() => window.location.href = '/account'}
                                >
                                    CANCEL
                                </BlockButton>
                            ) : null}

                            {(!isPreviewMode && !isEdit) ? (
                                <BlockButton
                                    type='submit'
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                >
                                    CONTINUE
                                </BlockButton>
                            ) : (isEdit ? (
                                <BlockButton
                                    type='submit'
                                    style={{ width: '250px' }} 
                                    buttonStyle="purple"
                                >
                                    SAVE
                                </BlockButton>
                            ) : ( // Preview mode
                                <BlockButton
                                    type='button' // IMPORTANT
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                    onClick={() => {
                                        window.location.href=`/consumer-registration/${STEP + 1}?mode=preview`
                                    }}
                                    disabled={true}
                                >
                                    FINALIZE
                                </BlockButton>
                            ))}
                        </div>

                        <ToastContainer 
                            hideProgressBar={true}
                            closeOnClick={true}
                            position='bottom-center'
                            style={{textAlign: 'center', cursor: 'default'}}
                        />
                    </form>
                </div>

                {/* If form is not ready to show yet, show the spinner */}
                {showPreloadSpinner ? (
                    <img src={LoadingCircle} className='preload-spinner' />
                ) : null}
            </div>
        </>
    )
}