import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Product } from '../../../../models/Product';
import { ProductGroup } from '../../../../models/ProductGroup';
import { getAllProducts } from '../../../../store/actions/product-actions';
import { getUser } from '../../../../store/actions/user-actions';
import { StoreItems } from '../../../../store/store';
import './physician-profile.scss';


type Props = {

    // props from store
    user: any,
    products: ProductGroup[],
    getAllProducts: Function,
};

type State = {};




class PhysicianProfilePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        props.getAllProducts();
    }


    private _formatProducts() {
        if (!this.props?.products) return null;

        return this.props.products.map((product, i) => (
            // Adding `i` to key just in case not all products have IDs yet
            <li key={String(product.id) + 'x' + i + 'x'} className='product-list-item'>
                <Link to={`/product/details/${product.id}`}>
                    <div className='product-listing clickable'>
                        <div className='img-wrapper' style={{
                            background: 'url(' + product.displayProduct.image + ') no-repeat center center',
                            backgroundSize: 'contain',
                        }} />

                        <div className='product-summary'>
                            <h4>{product.displayProduct.name}</h4>
                            <p className='brand'>{product.displayProduct.brand}</p>
                            <p className='vendor'>{product.displayProduct.vendor}</p>
                            <p className='description'>
                                {product.truncateText(product.displayProduct.description)}
                            </p>
                        </div>
                    </div>
                </Link>

            </li>
        ))
    }

    render() {
        return (
            <>
                <div className="header-spacer" />
                <div className='body-container physician-profile'>
                    <div className='section products'>
                        <h2>Featured Product</h2>
                        <ul>
                            {this._formatProducts()}
                        </ul>
                    </div>

                    <div className='section events'>
                        <h2>Events</h2>

                        <div className='events-gallery'>
                            <div className='event clickable main-event'>
                                <div className='bg' />
                                <p>webinar / Event</p>
                            </div>
                            <div className='sub-events'>
                                <div className='event clickable'>
                                    <div className='bg' />
                                    <p>webinar / Event</p>
                                </div>
                                <div className='event clickable'>
                                    <div className='bg' />
                                    <p>webinar / Event</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}




const injectStoreToProps = (store: StoreItems) => {
    const { user, products } = store;
    return { user, products };
}

const conn = connect(injectStoreToProps, { getUser, getAllProducts });
export const PhysicianProfile = conn(PhysicianProfilePage);