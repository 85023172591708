import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { ADMIN_NAV_HIERARCY, Hierarchy, Page } from '../../admin/_PATHS'

import './breadcrumb-trail.scss'

interface Location {
  [key: string]: string; // key is the page name and val is the url path.
}
interface BreadCrumb {
  path: Location[]; // The breadrumb trail 
  children: Location[]; // The children only for the last item of the breadcrumb trail
  siblings?: Location[];
}


interface Props {
    pageName: string; // The name of the page that you need to breadcrumb trail for.
    hierarchyObj: Hierarchy;
    // Styles
    style?: React.CSSProperties;
    classes?: string;
}


export const BreadCrumbTrail = ({
    pageName,
    hierarchyObj,
    style = {},
    classes = '',
}: Props) => {
    // State vars
    const [trail, setTrail] = useState<BreadCrumb|null>({} as BreadCrumb)
    const [trailLength, setTrailLength] = useState(0)
    const [selectedChildPathIndex, setSelectedChildPathIndex] = useState(0)
    const [selectedSiblingPathIndex, setSelectedSiblingPathIndex] = useState(0)
    const [childBoxText, setChildBoxText] = useState('')
    const [siblingBoxText, setSiblingBoxText] = useState('')

    // Use this to navigate between admin pages so there is no reload time.
    const navigate = useNavigate()

    // Reset state variables after using `useNavigate` (`navigate()`) to go to a different admin page.
    useEffect(() => {
        return () => {
            setTrail({} as BreadCrumb)
            setTrailLength(0)
            setSelectedChildPathIndex(0)
            setSelectedSiblingPathIndex(0)
            setChildBoxText('')
            setSiblingBoxText('')
        }
      }, [navigate]);


    /**
     * Construct data obj for making a breadcrumb trail in the component.
     * - Recursive function
     **/ 
    const getPath = (
        _targetPageName: string, // The name of the page that we want to find.
        _currentPageName: string, // The current page name being inspected,
        _currentPage: Page, // The parent object in `adminNavHierarchy`
    ): BreadCrumb|null => {
        // Exit case.
        // - When the page is found return the pageName and path, along with a list of the pageName/paths
        //   of immediate children.
        if (_targetPageName === _currentPageName) {
            const currentLocation: Location = { [_currentPageName]: _currentPage.path } 
            const childrenLocations: Location[] = Object.entries(_currentPage.children || []).map(([pageName, pageDetails]) => 
                ({ [pageName]: pageDetails.path })
            )
            const breadCrumb: BreadCrumb = {
                path: [currentLocation],
                children: childrenLocations,
                // `siblings` key needs to be `undefined` until the direct parent proceses it.
            }
            return breadCrumb
        } else if (!_currentPage.children) {
            return null
        }

        // Current location
        const currentLocation: Location = { [_currentPageName]: _currentPage.path } 

        // If a breadcrumb obj is returned from any child, store it to be returned at end of this func.
        let crumb: BreadCrumb|null = null
        for (let [childPageName, childPage] of Object.entries(_currentPage.children)) {
            const res: BreadCrumb|null = getPath(_targetPageName, childPageName, childPage)
            if (res) {
                crumb = res
                // If the target was found on the direct children, the `siblings` key will be 
                // `undefined`, so if that's the case add them in. 
                // - This level's children are the siblings.
                if (crumb !== null && typeof crumb.siblings === 'undefined') {
                    const siblingLocations: Location[] = (
                        Object.entries(_currentPage.children || []).map(([pageName, pageDetails]) => 
                            ({ [pageName]: pageDetails.path })
                        )
                    )
                    crumb.siblings = siblingLocations
                }
                break
            }
        }

        // Matching page not found (at least in this sub-tree.)
        if (!crumb) return null

        // If breadcrumb obj exists, that means there is a match somewhere in descendents. So prepend
        // the current Location then return.
        crumb.path.unshift(currentLocation)
        return crumb
    }

    // On mount (and on change), populate the data for the breadcrumb trail.
    useEffect(() => {
        // Key the top level key/val pair from the Hierarchy obj (there should only be one).
        const [_topLevelPageName, _topLevelPageDetails] = Object.entries(hierarchyObj)[0]
        const breadcrumbTrail = getPath(pageName, _topLevelPageName, _topLevelPageDetails)
        setTrail(breadcrumbTrail)
        setTrailLength(breadcrumbTrail?.path?.length || 0)
    }, [pageName, hierarchyObj])

    
    // When dropdown option changes (for CHILDREN dropdown), get the page path and navigate to it.
    useEffect(() => {
        if (!trail || !trail.children) return
        const indexInChildList = selectedChildPathIndex - 1
        if (indexInChildList < 0) return // User selected the 'null' option in dropdown.
        // Get the path to the page.
        const targetLocation = trail.children[indexInChildList]
        const targetPath = Object.values(targetLocation)[0]
        // Navigate to the page.
        navigate(targetPath)
    }, [trail, selectedChildPathIndex])

    // When dropdown option changes (for SIBLING dropdown), get the page path and navigate to it.
    useEffect(() => {
        if (!trail || !trail.siblings) return
        const indexInSiblingsList = selectedSiblingPathIndex - 1
        if (indexInSiblingsList < 0) return // User selected the 'null' option in dropdown.
        // Get the path to the page.
        const targetLocation = trail.siblings[indexInSiblingsList]
        const targetPath = Object.values(targetLocation)[0]
        // Navigate to the page.
        navigate(targetPath)
    }, [trail, selectedSiblingPathIndex])





    return (
        <div className={`breadcrumb-trail-container ${classes}`} style={style}>
            {(trail?.path || [] as Location[]).map((item: Location, index_outer: number) => (
                // FYI: this loop always runs only once.
                (Object.entries(item)).map(([pageName, pagePath], index_inner) => (
                    <React.Fragment key={index_inner + pagePath}>
                    
                    {(index_outer === trailLength - 1) ? (
                        // If it IS the last item, show siblings drpdown.
                        (trail?.siblings?.length ? (
                            // HAS SIBLINGS
                            <div className='children-box siblings'>
                                <div className='children-box-text'>
                                <div className={`node ${(index_outer < trailLength - 1) ? 'parent' : 'target'}`}>
                                    <Link to={pagePath}> 
                                        {siblingBoxText || pageName}
                                    </Link>
                                </div> 
                                </div>
                                <div className='children-box-inner'>
                                    <div className='children-dropdown'>
                                        {(trail?.siblings || []).map((location: Location, index_sibling: number) => (
                                            <div 
                                                key={index_sibling} 
                                                className='children-row'
                                                onMouseEnter={() => {
                                                    setSiblingBoxText(Object.keys(location)[0])
                                                }}
                                                onMouseLeave={() => {
                                                    setSiblingBoxText('')
                                                }}
                                                onClick={() => {
                                                    setSelectedSiblingPathIndex(index_sibling + 1)
                                                }}
                                            >
                                                {Object.keys(location)[0]} {/* There's always only 1 */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // DOES NOT HAVE SIBLINGS
                            <div className={`node ${(index_outer < trailLength - 1) ? 'parent' : 'target'}`}>
                                <Link to={pagePath}> 
                                    {pageName}
                                </Link>
                            </div> 
                        ))
                    ) : (
                        // Normal nodes
                        <div className={`node ${(index_outer < trailLength - 1) ? 'parent' : 'target'}`}>
                            <Link to={pagePath}> 
                                {pageName}
                            </Link>
                        </div> 
                    )}

                    {(index_outer < trailLength - 1 || trail?.children?.length) ? (
                        // {/* If it's NOT the last item, add a ">" between items */}
                        <div className='descendant-arrow'>
                            &gt;
                        </div>
                    ) : null}
                    </React.Fragment>
                ))
            ))}

            
            {/* After the last item, if there are any descendents show a dropdown */}
            {trail?.children?.length ? (
                <div className='children-box'>
                    <div className='children-box-text'>
                        {childBoxText}
                    </div>
                    <div className='children-box-inner'>
                        <div className='children-dropdown'>
                            {(trail?.children || []).map((location: Location, index_children: number) => (
                                <div 
                                    key={index_children} 
                                    className='children-row'
                                    onMouseEnter={() => {
                                        setChildBoxText(Object.keys(location)[0])
                                    }}
                                    onMouseLeave={() => {
                                        setChildBoxText('')
                                    }}
                                    onClick={() => {
                                        setSelectedChildPathIndex(index_children + 1)
                                    }}
                                >
                                    {Object.keys(location)[0]} {/* There's always only 1 */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )

}