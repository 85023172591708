import React from 'react';
import { ProductsList } from '../../public/products-list/ProductsList';
import { Greyback } from '../../../shared/form/greyback/Greyback';
import { Link } from "react-router-dom";
import LocationIcon from "../../../../static/icons/location_icon.png"
import ApplyTermsIcon from "../../../../static/icons/apply_account_icon.png"
import './new-physician-dashboard.scss';


TODO:  //  fix greyback sections.  add icons to greybacksections.

type Props = {};

type State = {};


export class NewPhysicianDashboard extends React.Component<Props, State> {


  render() {
    return (
      <>
        <div className="header-spacer" />
        <div className="body-container-no-vert-pad new-physician-dashboard-layout">
          <div className="multicolor-headers">
            <div className="header blue">WELCOME</div>
            <div className="header">DR. WHO</div>
          </div>
          <div className="greyback-wrapper">
            <Greyback
              greybackStyle="base-content"
              style={{ height: '230px' }}
              closable={true}
            >
              <div className="greyback-content">
                <img src={ApplyTermsIcon} />
                <div className="greyback-text">
                  <text>APPLY FOR A TERMS ACCOUNT</text>
                  <p>
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                  </p>
                </div>
              </div>
            </Greyback>

            <Greyback
              greybackStyle="base-content"
              style={{ height: '230px' }}
              closable={true}
            >
              <div className="greyback-content">
                <img src={LocationIcon} />
                <div className="greyback-text">
                  <text>HOW DO I JOIN ANOTHER PRACTICE LOCATION?</text>
                  <p>
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                    test text test text test text test text test text
                  </p>
                </div>
              </div>
            </Greyback>
          </div>

          <div className="header">NEW PRODUCTS</div>
          <ProductsList condensed={true} />

          <div className="header">EVENTS</div>
          <div className='events-wrapper'>
            <div className='events-gallery'>
              <div className='event clickable main-event'>
                <div className='bg'></div>
                webinar / Event
              </div>
              <div className='sub-events'>
                <div className='event clickable'>
                  <div className='bg'></div>
                  webinar / Event
                </div>
                <div className='event clickable'>
                  <div className='bg'></div>
                  webinar / Event
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}