import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import "./user-guide.scss";




export const UserGuide = () => {


  return (
    <div className="user-guide-layout">
      <div className="body-container ">
        <div className="multicolor-headers">
          <div className="header small blue">RESOURCES</div>
          <div className="header small">/ USER GUIDE</div>
        </div>
        <span className="bold-text">This guide is provided as a collection of Frequently Asked Questions (FAQS) grouped by role of users.</span>
        <div className="teal-display-container">
          <div className="teal-box">
            <span className="display-text">DISTRIBUTOR ADMINS AND SALES REPS</span>
          </div>
          <div className="teal-box">
            <span className="display-text">DISTRIBUTOR ADMINS</span>
          </div>
        </div>

        <span className="bold-text">I. Role differences between distributor admins and reps</span>
        <div className="information-container">
          <div className="information-column">


            <div className="information-box">
              <span className="information-text">Q. Can distributor admins tag doctors themselves?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Yes. You can tag doctors as reps do.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Can I see all doctors of my distributor/organization?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Distributor admin can see doctors tagged by all reps.  Reps can only see his/her own volumes.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Whos sales volume can I see?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Distributor admin can see all reps' volumes.  Reps can only see his/her own volumes.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Can reps add other reps</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>No. Only distributor admins can add reps.  Rep as a team member can not build the team.</span>
              </div>
            </div>

            <span className="bold-text spaced">II. Tagging doctoradmins and reps</span>

            <div className="information-box">
              <span className="information-text">Q. Why can I not tag more doctors?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>A representative (Rep) can tag up to 60 doctors, then he can NOT tag new doctors (even if he has tagged less than 60 doctors).</span>
              </div>
            </div>

            <div className="information-box">
              <div className="text-required-wrap purple">
                <span className="information-text">Q. What information do I need to tag a doctor?</span><div className="purple-button">TAG DOCTOR</div>
              </div>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Doctor/Clinic NPIs, doctor full name, email, mobile/clinic phone.  It is not required but recommended to provide clinic administrator's name.  The shipping address should be provided to the best of your knowledge.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. How do I set up multiple locations for one clinic/doctor account?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>When you tag a doctor, you provide one single initial address (office location).  Once the doctor is tagged, you can add multiple locations from the doctor's detail page (start from <div className="purple-button sized">DOCTORS</div> ).  But this is only allowed before doctor placing his/her first order.  (doctor's addresses are doctor's personal information).  Please add extra locations as soon as possible, or contact us for help.</span>
              </div>
            </div>
          </div>
          <div className="information-column">

            <div className="information-box">
              <span className="information-text">Q. Can distributor admins tag doctors themselves?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Yes. You can tag doctors as reps do.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Can I see all doctors of my distributor/organization?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Distributor admin can see doctors tagged by all reps.  Reps can only see his/her own doctors.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Whose sales volumes can I see?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>Distributor admin can see all reps' volumes. Reps can see only his/her own volumes.</span>
              </div>
            </div>

            <div className="information-box">
              <span className="information-text">Q. Can reps add other reps?</span>
              <div className="text-required-wrap">
                <span className="information-text">A.</span>
                <span>No.  Only distributor admins can add reps.  Rep as a team member can not build the team.</span>
              </div>
            </div>



          </div>


        </div>
      </div>
    </div>

  )
}