import React, { useCallback, useContext, useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast, Flip } from 'react-toastify'

import { AccountContext } from '../auth/Account'
import { SessionPacket } from '../../types/session'

import { BlockButton } from '../shared/buttons/block-button/BlockButton'
import { Icon } from '../shared/icon/Icon'
import { InfoTextModal } from '../shared/modal/InfoTextModal'
import { InputField } from '../shared/form/input-field/InputField'
import { RowDetailsModal } from '../shared/modal/RowDetailsModal'
import { SelectField } from '../shared/form/select-field/SelectField'

import parseErrorObject from '../../helpers/parseErrorObject'

import LoadingCircle from '../../static/gif/loading-circle.gif'

import './admin.scss'
import 'react-toastify/dist/ReactToastify.css'
import { TextAreaField } from '../shared/form/text-area/TextAreaField'
import { ChangeLogModal } from '../shared/modal/ChangeLogModal'





interface TableDetails {
    [tableName: string]: { // Key is the "table name"
        modifiable: {[fieldName:string]: string|any[]},
        notes: {[fieldName:string]: string}, // Show info icon 
        link: {[fieldName:string]: string}, // Fields that should display their (array) contents as links.
        expandable: {[fieldName: string]: string}, // Fields that can be opened in a modal (foreign keys, basically). Val is the table name.
        hide: string[], // Fields to hide bc they're useless or redundant.
        hideEmpty: string[], // Fields that should be hidden only if they're empty (or empty array).
    }
}
interface TableDetailsGeneric {
    modifiable: {[fieldName:string]: string|any[]},
    notes: {[fieldName:string]: string}, // Show info icon 
    link: {[fieldName:string]: string}, // Fields that should display their (array) contents as links.
    expandable: {[fieldName: string]: string}, // Fields that can be opened in a modal (foreign keys, basically). Val is the table name.
    hide: string[], // Fields to hide bc they're useless or redundant.
    hideEmpty: string[], // Fields that should be hidden only if they're empty (or empty array).
}


// Add this string to notes to indicate that they are important. 
// - Their info icons will be colored red.
// - The text from this var will be removed in the final note.
// - Acutally might not use red, maybe black.
const RED_STR = '(!)'

/**
 * =================================================================================================
 * [TABLES AND FIELDS]
 * - THIS IS THE MAIN CONFIG FOR WHAT IS SHOWN IN THE DATA MODAL, AND WHAT IS MODIFIABBLE, AND NOTES, ETC.
 * - Keys are the table names (slightly modified from backend names, for use on frontend only). 
 * - If a key isn't in here then that table can't be viewed/expanded in the admin.
 * - There is also another table below called `TAFG` (generic) 
 * - For notes, if the text has an exclamation point (!) the icon will show as red (#f00).
 * =================================================================================================
 */
const TAF: TableDetails = {
    user: {
        modifiable: {
            // active: [true, false], // Deactivating also requires changing clinic, business, products, skus, redis search, 
                                      // cognito, and tag relationships. Also enabling re-registration and recovering old info.
                                      // re-registration/re-activation structure.
            block_user: [null, true],  lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            first_name: 'STRING', middle_name: 'STRING', last_name: 'STRING', title: 'STRING',
            npi: 'INTEGER',
        },
        notes: {
            id: "This user's unique ID. Use this for reference purposes when you contact support.",
            active: "Default value is null/empty. Gets set to true when user completes step 1 "
                    + "of registration, confirms their email, then logs in.\r\n\r\n"
                    + "Deactivation involves changing many different tables and services, "
                    + "and can only be done by a sys-admin.",
                    // + "If changing this to true, you will also need to activate the user in Cognito "
                    // + "before they can use their account.\r\n\r\n"
                    // + "Setting to false will prevent user from accessing their account but may not block the user "
                    // + "from registering again with the same email - see `block_user` for that.\r\n\r\n"
                    // + "Set this to false to enable the user to register again with the same email "
                    // + "but with a different account/user type.\r\n\r\n"
                    // + "If setting to false to deactivate a user, ensure that you also deactivate "
                    // + "their associated business, clinic, and all products and skus.",
            block_user: "Default value is null/emtpy.\r\n\r\nSet to true to block user from their "
                    + "account and from registering again with the same email.\r\n\r\n"
                    + RED_STR,
            role: "This number is the row ID in the roles table - modify this table to change a user's roles.\r\n\r\n"
                    + "This user's roles are listed to the right.",
            u_type: "This corresponds to what type the user chose in the first step of registration.\r\n\r\n"
                    + "User type cannot be changed. If user wishes to change their account type, they must "
                    + "either register with a new email, or you can mark the user as "
                    + "inactive and ask them to register again with the same email.",
            lock: "True means that this user cannot be modified by anyone through this admin interface. "
                    + "Normal users are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            title: "Blank by default, except those who register as Clinic get \"Dr.\" by default",
            email_id: "Email address cannot be changed due to security restrictions.\r\n\r\n"
                    + "Alternative: deactivate user (set active=false) then ask them to register again. Or "
                    + "ask them to register with another email.",
            is_deleted: "True indicates that the user deleted/deactivated their own account. This field "
                    + "can only be changed by the end user.",
            created_at: "This is in UTC time zone, aka London time. Local time is the second row to the right.",
            updated_at: "This is in UTC time zone, aka London time. Local time is the second row to the right.",
        },
        expandable: { // {<fieldname>: <tableName>}
            role: 'role',
            u_type: 'entity_type',
            address_id: 'address',
            email_id: 'email',
            phone_id: 'phone',
        },
        hide: ['user_role', 'entity_type', 'address', 'phone', 'email'],
        hideEmpty: ['rep_org', 'business_list', 'clinic_list'],
        link: { // {<fieldname>: <tableName>}
            rep_org: 'rep_org', 
            business_list: 'business', 
            clinic_list: 'clinic',
            cognito: 'user_cognito',
        },
    },
    user_cognito: { // Nothing should be editable in this table.
        modifiable: {},
        notes: {
            verified: "Default value is false. Changes to true when user enteres the verification "
                + "code that was sent to their email, then logs in for the first time.",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    role: {
        modifiable: {
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            sysadmin: [true, null], admin: [true, null], manager: [true, null], conductor: [true, null],
        },
        notes: {
            id: "The unique ID for this row, not necessarily the same as the user ID.",
            user_id: "The ID of the user that this role describes.",
            lock: "True means that this user's role cannot be modified by anyone through this admin interface. "
                    + "Normal roles are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            is_public: "This value is set during registration and cannot be changed.",
            is_regen: "This value is set based on the user's email address and cannot be changed "
                    + "except by an administrator.",
            vendor: "This value is set during registration and cannot be changed.",
            supplier: "This value is set during registration and cannot be changed.",
            clinic: "This value is set during registration and cannot be changed.",
            rep: "This value is set during registration and cannot be changed.",
            rep_member: "True means this user is part of a Rep Organization.",
            rep_lead: "True means this user is the head of a Rep Organization.",
            general: "This value is set during registration and cannot be changed.",
            sysadmin: "For Regentive Labs users only. Sysadmins and admins can update fields on this admin page",
            admin: "For Regentive Labs users only. Sysadmins and admins can update fields on this admin page",
            manager: "For Regentive Labs users only. Managers and conductors cannot update data on this admin page",
            conductor: "For Regentive Labs users only. Managers and conductors cannot update data on this admin page",
        },
        expandable: { // {<fieldname>: <tableName>}
            user_id: 'user',
        },
        hide: ['user'],
        hideEmpty: [],
        link: {},
    },
    entity_type: { // Nothing should be editable in this table.
        modifiable: {},
        notes: {},
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    rep_org: {
        modifiable: {
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            name: 'STRING',
            name_display: 'STRING',
            npi: 'STRING',
        },
        notes: {
            id: "This Rep organization's unique ID. Use this for reference purposes when you contact support.",
            active: "Default is true. "
                    + "Deactivation requires changing several different tables.", 
            user_id: "The user who registered this Rep organization.",
            lock: "True means that this Rep organization cannot be modified by anyone through this admin interface. "
                    + "Normal Rep organizations are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            npi: "This field isn't currently used. See the linked User for their NPI.",
            reg_complete: "Whether this Rep organization has completed all registration steps.",
            reg_step: "If registration is complete, this field will be null/empty.",
        },
        expandable: {
            user_id: 'user',
            type_id: 'entity_type',
            address_id: 'address',
            email_id: 'email',
            phone_id: 'phone',
            website_id: 'website',
        },
        hide: ['user', 'type', 'address', 'phone', 'email', 'website'],
        hideEmpty: ['business_list', 'clinic_list'],
        link: {
            owner: 'user',
            member_list: 'rep_member', 
        },
    },
    rep_member: {
        modifiable: {
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
        },
        notes: {
            id: "Unique ID for this row.",
            active: "Default is null. Becomes true when membership/tag is approved. Becomes false "
                    + "invitation was declined, or tag was deactivated by either party.",
            user_id: "The user who who is a member of this Rep Organization.",
            lock: "True means that this membership status cannot be modified by anyone through this admin interface. "
                    + "Normal rows are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            rep_org_id: "The ID of the Rep Organization",
        },
        expandable: {
            user_id: 'user',
            rep_org_id: 'rep_org',
        },
        hide: ['user', 'rep_org'],
        hideEmpty: [],
        link: {
            owner: 'user',
            rep_org: 'rep_org', 
        },
    },
    business: {
        modifiable: {
            // active: [false], // Deactivating also requires changing products, skus, redis search, 
                // and tag tag relationships. And re-registration/re-activation structure.
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            name: 'STRING',
            name_display: 'STRING',
        },
        notes: {
            id: "This business's unique ID. Use this for reference purposes when you contact support.",
            user_id: "The user who registered this business.",
            active: "Default value is null/empty, and set to true when the business completes "
                    + "all registration steps and adds at least one product and sku. "
                    + "Deactivation requires changing several different tables.",
                    // + "Only set this to false"
                    // + "active, so this value can only be set to false.\r\n\r\n",
            lock: "True means that this business cannot be modified by anyone through this admin interface. "
                    + "Normal businesses are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            reg_complete: "Whether this business has completed all registration steps, including"
                    + "adding at least 1 product and sku.",
            reg_step: "If registration is complete, this field will be null/empty.",
        },
        expandable: { // {<fieldname>: <tableName>}
            user_id: 'user',
            type_id: 'entity_type',
            copy_id: 'business_copy',
            address_id: 'address',
            email_id: 'email',
            phone_id: 'phone',
            website_id: 'website',
        },
        hide: ['user', 'entity_type', 'address', 'phone', 'email', 'website'],
        hideEmpty: ['rep_org', 'clinic_list'],
        link: {
            owner: 'user',
            product_list: 'product',
        },
    },
    clinic: {
        modifiable: {
            // active: [false], // Deactivating also requires changing redis search, and tag relationships.
                // And re-registration/re-activation structure.
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            name: 'STRING',
            name_display: 'STRING',
            npi: 'STRING',
        },
        notes: {
            id: "This clinic's unique ID. Use this for reference purposes when you contact support.",
            user_id: "The user who registered this clinic.",
            active: "Default value is null/empty, and set to true when the clinic completes "
                    + "all registration steps. "
                    + "Deactivation requires changing several different tables.", 
            lock: "True means that this clinic cannot be modified by anyone through this admin interface. "
                    + "Normal clinics are null/empty (as opposed to false).\r\n\r\n"
                    + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                    + RED_STR,
            npi: "This is the clinic's NPI, which is usually different from the user's NPI.",
            reg_complete: "Whether this clinic has completed all registration steps.",
            reg_step: "If registration is complete, this field will be null/empty.",
        },
        expandable: {
            user_id: 'user',
            type_id: 'entity_type',
            copy_id: 'clinic_copy',
            address_id: 'address',
            email_id: 'email',
            phone_id: 'phone',
            website_id: 'website',
        },
        hide: ['user', 'type', 'address', 'phone', 'email', 'website'],
        hideEmpty: ['rep_org', 'business_list'],
        link: {
            owner: 'user',
        },
    },
    business_copy: {
        modifiable: {
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            headline_1: 'STRING', headline_2: 'STRING', headline_3: 'STRING', headline_4: 'STRING', headline_5: 'STRING',
            sub_headline_1: 'STRING', sub_headline_2: 'STRING', sub_headline_3: 'STRING', sub_headline_4: 'STRING', sub_headline_5: 'STRING',
            description_1: 'TEXT', description_2: 'TEXT', description_3: 'TEXT', description_4: 'TEXT', description_5: 'TEXT',
            img_1: 'STRING', img_2: 'STRING', img_3: 'STRING', img_4: 'STRING', img_5: 'STRING',
            link_1: 'STRING', link_2: 'STRING', link_3: 'STRING', link_4: 'STRING', link_5: 'STRING',
        },
        notes: {
            id: 'Unique ID for business_copy.',
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    clinic_copy: {
        modifiable: {
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            headline_1: 'STRING', headline_2: 'STRING', headline_3: 'STRING', headline_4: 'STRING', headline_5: 'STRING',
            sub_headline_1: 'STRING', sub_headline_2: 'STRING', sub_headline_3: 'STRING', sub_headline_4: 'STRING', sub_headline_5: 'STRING',
            description_1: 'TEXT', description_2: 'TEXT', description_3: 'TEXT', description_4: 'TEXT', description_5: 'TEXT',
            img_1: 'STRING', img_2: 'STRING', img_3: 'STRING', img_4: 'STRING', img_5: 'STRING',
            link_1: 'STRING', link_2: 'STRING', link_3: 'STRING', link_4: 'STRING', link_5: 'STRING',
        },
        notes: {
            id: 'Unique ID for clinic_copy.',
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    address: {
        modifiable: {
            // label: 'STRING', description: 'STRING', priority: 'INTEGER',
            name: 'STRING', street1: 'STRING', street2: 'STRING', city: 'STRING', state: 'STRING', zip: 'STRING',
        },
        notes: {
            id: 'Unique ID for address',
            active: "Default value is true.",
            verified: "Default value is false.",
            state: "State must be entered as a capitalized 2-letter abbreviation.",
            label: "To be set by the user.",
            description: "To be set by the user.",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    email: {
        modifiable: { // No fields should be modifiable right now
            // label: 'STRING', description: 'STRING', priority: 'INTEGER', // email: 'STRING', // <-- DO NOT MODIFY EMAIL FIELD.
        },
        notes: {
            id: 'Unique ID for email',
            active: "Default is null/empty. Gets set to true when user verifies email address. "
                + "Deactivation requires changing several different tables.",
                // + "Only gets set to false when deactivated by a sys admin.", // (confirmed)
            verified: "Default is false. Gets set to true when user verifies email address.",
            email: "Changing email address requires updating various services. Currently not supported.",
            label: "To be set by the user.",
            description: "To be set by the user.",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    phone: {
        modifiable: {
            // label: 'STRING', description: 'STRING', priority: 'INTEGER', 
            phone: 'STRING',
        },
        notes: {
            id: 'Unique ID for phone',
            active: "Default value is true.",
            verified: "Default value is false.",
            label: "To be set by the user.",
            description: "To be set by the user.",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    website: {
        modifiable: {
            // label: 'STRING', description: 'STRING', priority: 'INTEGER', 
            website: 'STRING',
        },
        notes: {
            id: 'Unique ID for website',
            active: "Default value is true.",
            verified: "Default value is false.",
            label: "To be set by the user.",
            description: "To be set by the user.",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {},
    },
    product: {
        modifiable: {
            // active: [true, false], // Deactivating requires updating skus, maybe the business (if no 
                // more active products), redis search, and tag relationships. And 
                // re-registration/re-activation structure.
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            name: 'STRING', name_display: 'STRING', brand: 'STRING', // discounts: 'STRING', // not yet
            // headline_1: 'STRING', headline_2: 'STRING', headline_3: 'STRING',
            product_id: 'STRING',
            sub_headline_1: 'STRING', sub_headline_2: 'STRING', sub_headline_3: 'STRING',
            description_1: 'TEXT', description_2: 'TEXT', description_3: 'TEXT',
            // img_1: 'STRING', img_2: 'STRING', img_3: 'STRING',
            // link_1: 'STRING', link_2: 'STRING', link_3: 'STRING',
        },
        notes: {
            id: "Unique ID for this product",
            active: "Default value is true. "
                    + "Deactivation requires changing several different tables.",
            product_id: "A USER-GENERATED product ID for internal tracking purposes.\r\n\r\n"
                    + "This ID does not relate to any of the unique IDs in any of our tables\r\n\r\n"
                    + "When a list of products are displayed on the website, they will be "
                    + "ordered alphabetically by their \"product_id\"."
                    + RED_STR,
            // business_id: "The unique ID of the business that added this product.",
            discounts: "This field isn't currently being used",
            headline_1: "This field isn't currently being used",
            headline_2: "This field isn't currently being used",
            headline_3: "This field isn't currently being used",
            img_1: "This field isn't currently being used",
            img_2: "This field isn't currently being used",
            img_3: "This field isn't currently being used",
            link_1: "This field isn't currently being used",
            link_2: "This field isn't currently being used",
            link_3: "This field isn't currently being used",
        },
        expandable: {},
        hide: ['business_id'],
        hideEmpty: [],
        link: {
            business: 'business',
            sku_list: 'sku',
        },
    },
    sku: {
        modifiable: {
            // active: [true, false], // active: [true, false], // Deactivating requires maybe the product 
                // or business (if no more active products), redis search, and tag relationships. And 
                // re-registration/re-activation structure.
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            sku_id: 'STRING', name: 'STRING', name_display: 'STRING',
            dose: 'STRING', size: 'STRING', price: 'DECMINAL',  // discounts: 'STRING', // not yet
            // headline: 'STRING', sub_headline: 'STRING', description: 'TEXT', 
            // img_1: 'STRING', img_2: 'STRING', img_3: 'STRING', img_4: 'STRING', 
            // link: 'STRING',
        },
        notes: {
            id: 'Unique ID for sku',
            active: "Default value is true. Deactivation requires changing several different tables.",
            sku_id: "A USER-GENERATED sku ID for internal tracking purposes.\r\n\r\n"
                    + "This ID does not relate to any of the unique IDs in any of our tables\r\n\r\n"
                    + "When a list of skus are displayed on the website, they will be "
                    + "ordered alphabetically by their \"sku_id\"."
                    + RED_STR,
            dose: "Only one of `dose` OR `size` should have a value, as one will overwrite the other.",
            size: "Only one of `dose` OR `size` should have a value, as one will overwrite the other.",
            discounts: "This field isn't currently being used",
            headline: "This field isn't currently being used",
            sub_headline: "This field isn't currently being used",
            description: "This field isn't currently being used",
            img_1: "This field isn't currently being used",
            img_2: "This field isn't currently being used",
            img_3: "This field isn't currently being used",
            img_4: "This field isn't currently being used",
            link: "This field isn't currently being used",
        },
        expandable: {},
        hide: [],
        hideEmpty: [],
        link: {
            product: 'product',
        },
    },
    tag: {
        modifiable: {
            active: [true, false], 
            lock: [true, null], // Only a sys-admin can undo this (by setting it to null).
            from_user: 'INTEGER', from_business: 'INTEGER', from_clinic: 'INTEGER', 
            to_user: 'INTEGER', to_business: 'INTEGER', to_clinic: 'INTEGER', 
        },
        notes: {
            id: 'Unique ID for tags',
            active: 'Whether this tag relationship is active.\r\n\r\nDefault is null, which means '
                + "the invitation is still outstanding. False means that either the invitation was "
                + "declined, or the tag relationship was deactivated.",
            deactivated_by: "If this tag is inactive, this will indicate which user_id (either "
                + "the `from_user` id or the `to_user` id) who deactivated it.",
            from_user: 'The user who sent the tag invitation. This field should never be empty.',
            from_business: "The business associated with `from_user`",
            from_clinic: "The clinic associated with `from_user`",
            to_user: 'The user who received the tag invitation. This field should never be empty.',
            to_business: "The business associated with `to_user`",
            to_clinic: "The clinic associated with `to_user`",
        },
        expandable: {
            from_user: 'user',
            from_business: 'business',
            from_clinic: 'clinic',
            to_user: 'user',
            to_business: 'business',
            to_clinic: 'clinic',
        },
        hide: ['user_from', 'business_from', 'clinic_from', 'user_to', 'business_to', 'clinic_to'],
        hideEmpty: [],
        link: {
            from_user: 'user',
            from_business: 'business',
            from_clinic: 'clinic',
            to_user: 'user',
            to_business: 'business',
            to_clinic: 'clinic',
        },
    },
}

/**
 * =================================================================================================
 * GENERIC ITEMS FOR `TAF` config.
 * - Anything in here will be applied to ALL tables (or fields).
 * =================================================================================================
 */
const TAFG: TableDetailsGeneric = {
    modifiable: {},
    notes: {
        name_display: "If this field has a value it will usually take precedence over the `name` "
                + "field when it's displayed on the website.",
        lock: "True means that none of the data cannot be modified by anyone through this admin interface. "
                + "If there is no lock, then this value will be null/empty (as opposed to false).\r\n\r\n"
                + "WARNING: Once lock is set to true it can only be undone by a system administrator."
                + RED_STR,
    },
    expandable: {},
    // 'table' is the table name, and 'time' is the time of data query. Both are tacked on by backedn.
    hide: ['table', 'time', 'type', 'copy', 'createdAt', 'updatedAt'],
    hideEmpty: [],
    link: {},
}

// Time fields
const TAFT = new Set(['updated_at', 'created_at'])


/**
 * =================================================================================================
 * QUERY-ABLE TABLES (for running searches)
 * =================================================================================================
 */

// Tables that you can run queries on.
const SEARCHABLE_TABLES = new Set([
    'user', 'rep_org', 'business', 'clinic', 'product',
])


/**
 * =================================================================================================
 * QUERY FIELDS (for running searches)
 * =================================================================================================
 */
interface QF {
    [tableName: string]: {
        idFields: string[]; // searchable by numbers only
        textFields: string[]; // regular searchable column names
        concatFiels: string[]; // just the frontend names - backend will have to handle the logic.
        roles: string[]; // radio buttons for roles to limit search to.
        types: string[]; // radio buttons for t/f columns on the table to limit search to.
        orders: string[]; // columns sortable
    }
}

// // NOT IMPLEMENTED - save this for later.
//
// // What types of fields are available to query, by table name.
// const QF: QF = {
//     user: {
//         id: true,
//         user_id: false,
//         name: true,
//         npi: false,
//         text: false;
//         roles: ['', 'is_public', 'is_regen', 'vendor', 'supplier', 'clinic', 'rep', 'general', 'conductor', 'manager', 'admin', 'sysadmin'],
//         types: ['', 'active', 'lock', 'block_user'],
//         orders: ['', 'first_name', 'last_name', 'created_at', 'updated_at'],
//     },
//     user_cognito: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: true,
//         text: false;
//         roles: [],
//         types: ['', 'verified', 'user_confirmed'],
//         orders: ['', 'username', 'created_at', 'updated_at'],
//     },
//     role: {
//         id: false,
//         user_id: true,
//         name: true,
//         npi: false,
//         text: false,
//         roles: ['', 'is_public', 'is_regen', 'vendor', 'supplier', 'clinic', 'rep', 'general', 'conductor', 'manager', 'admin', 'sysadmin'],
//         types: ['', 'lock'],
//         orders: ['', 'first_name', 'last_name', 'created_at', 'updated_at'],
//     },
//     entity_type: {
//         id: false,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: false,
//         roles: [],
//         types: [],
//         orders: [],
//     },
//     business: {
//         id: true,
//         user_id: true,
//         name: true,
//         npi: false,
//         text: false,
//         roles: [],
//         types: ['', 'active', 'lock', 'reg_complete'],
//         orders: ['', 'reg_step', 'created_at', 'updated_at'],
//     },
//     clinic: {
//         id: true,
//         user_id: true,
//         name: true,
//         npi: true,
//         text: false,
//         roles: [],
//         types: ['', 'active', 'lock', 'reg_complete'],
//         orders: ['', 'reg_step', 'created_at', 'updated_at'],
//     },
//     business_copy: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: true,
//         roles: [],
//         types: ['', 'active', 'lock', 'reg_complete'],
//         orders: ['', 'reg_step', 'created_at', 'updated_at'],
//     },
//     clinic_copy: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: true,
//         roles: [],
//         types: ['', 'active', 'lock', 'reg_complete'],
//         orders: ['', 'reg_step', 'created_at', 'updated_at'],
//     },
//     address: {
//         id: true,
//         user_id: false,
//         name: true,
//         npi: false,
//         text: true,
//         roles: [],
//         types: [],
//         orders: ['', 'city', 'state', 'zip', 'created_at', 'updated_at'],
//     },
//     phone: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: true,
//         roles: [],
//         types: [],
//         orders: [],
//     },
//     email: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: true,
//         roles: [],
//         types: [],
//         orders: [],
//     },
//     website: {
//         id: true,
//         user_id: false,
//         name: false,
//         npi: false,
//         text: true,
//         roles: [],
//         types: [],
//         orders: [],
//     },
//     product: {
//         id: true,
//         user_id: false,
//         name: true,
//         npi: false,
//         text: false,
//         roles: [],
//         types: ['', 'active', 'lock', 'reg_complete'],
//         orders: ['', 'reg_step', 'created_at', 'updated_at'],
//     },
// }











// API endpoints
const API_DB_URL = process.env.REACT_APP_API_DB_URL

// Query row limits
const DEFAULT_ROW_COUNT = 10
const MAX_ROW_COUNT = 100

// The amount of time that cached items are good for, in milliseconds.
// - This is only applied to cached OBJECTS, not the data-list or queries.
const CACHE_DURATION = 300000 // 5 minutes

// String to use in URL to indicate that back button was pressed.
const BACK_BUTTON_URL_STR = 'true'










export const AdminLists = () => {
    // Bottom of search-table-list box, will scroll there whether or not query was successful.
    const beforeTablResultsBox = useRef<HTMLDivElement>(null)
    const errorMessageBox = useRef<HTMLDivElement>(null)
    // Ref for the visible part of the data-modal.
    const dataModalRef = useRef<HTMLDivElement>(null)

    // Current table name for LISTS query (for the main page table).
    const [currentTableList, setCurrentTableList] = useState('')
    // Current table name for SINGLE OBJ query (for the data-modal).
    const [currentTableObj, setCurrentTableObj] = useState('')

    // Force certain components to update.
    const [forceUpdate, setForceUpdate] = useState(Date.now())
    // For input/select fields to unset their "changed" status, which makes them have red bg color.
    const [unmarkChanged, setUnmarkChanged] = useState(false)

    // Url parms, which may or may not be provided. IFF provided, look up the user then open 
    // the modal for that user automatically.
    // const { userId } = useParams()
    const { urlTable, urlObjId, urlBackButton } = useParams()
    // Previous table/id indicated by the url, and whether the "Back" button was pressed to get here.
    const urlTablePrev = useRef('')
    const urlObjIdPrev = useRef('')
    const urlBackButtonPrev = useRef('')

    // Cache the most recent db query filters and the query's results, so that user can be where 
    // they left off whenever they close the data modal (because their url might be different 
    // now from hopping around the different 'expand' buttons and 'links'.
    // - This holds one (query + results) at most, and is cleared whenever it is used (which is 
    //   when data modal is closed).)
    const cachedQuery = useRef<any>({})
    // Cache all of the `dbResult` objs for any data modal that was populated. 
    // - Individual cache items are cleared when an update is made, or when the data is older
    //   than (something I still need to define).
    // - All cache items are cleared when data modal is closed, or when refresh button is hit.
    const cachedDataObjs = useRef<any>({})

    // Change browser url without navigating to a different page.
    const navigate = useNavigate() // Same as useHistory in newer versions of this package.
    
    // User's session info.
    const { checkLogin, checkLoginNow, getUsernameAndIdToken } = useContext(AccountContext)
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)

    // Display states.
    const [showSpinner, setShowSpinner] = useState(false)
    const [showModalSpinner, setShowModalSpinner] = useState(false)
    const [showButtonSpinner, setShowButtonSpinner] = useState('') // uses table name.
    const [showDataModal, setShowDataModal] = useState<boolean|null>(null)
    const [showInfoTextModal, setShowInfoTextModal] = useState(false)
    const [showChangeLogModal, setShowChangeLogModal] = useState(false)
    const [infoText, setInfoText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [modalErrorMessage, setModalErrorMessage] = useState('')
    const [showModalInitialLoadSpinner, setShowModalInitialLoadSpinner] = useState(false)
    const [showRefreshSpinner, setShowRefreshSpinner] = useState(false)
    const [showChangeLogSpinner, setShowChangeLogSpinner] = useState(false)
    // Trigger reload of data inside of the modal for a single object.
    const [triggerReloadDataObj, setTriggerReloadDataObj] = useState(false)

    // Trigger openeing of anothe modal to show admin change history for the currently opened obj.
    const [showEditHistory, setShowEditHistory] = useState(false)

    // States for radio button filters.
    const [filterId, setFilterId] = useState(0) // For "ID" field, fall back to 0.
    const [filterName, setFilterName] = useState('') // For "name" fields (`ilike` sql query; special for 'user' table)
    const [filterType, setFilterType] = useState('') // For "type" filters (eg. roles - active/inactive/reg_complete/etc)
    const [filterRole, setFilterRole] = useState('') // For "role" filters (eg. roles - vendor/rep/general/admin/sysadmin/etc)
    const [filterNpi, setFilterNpi] = useState('') // For "npi" filters
    const [sortField, setSortField] = useState('')
    const [rowCount, setRowCount] = useState(DEFAULT_ROW_COUNT) // The number of rows to return
    const [rowSkip, setRowSkip] = useState(0) // The number of results to skip

    const [resultsSummary, setResultsSummary] = useState('')

    // Results from db
    const [dbResults, setDbResults] = useState<any[]>([]) // array of all objs from query
    const [dbResult, setDbResult] = useState<any>({}) // ONE obj - to show in data-modal.
    const [dbResultsTime, setDbResultsTime] = useState('') // array of all objs from query
    const prevDbResults = useRef<any[]>([]) // Chronological data objs that were viewed in data-modal.

    // Change-log data
    const [changeLogResults, setChangeLogResults] = useState<any[]>([])
    // The row ID of the row that is clicked (and will be shown in data-modal).
    const [activeRowId, setActiveRowId] = useState(0)

    // For editing - Keep track of what fields have been changed.
    const [changedFields, setChangedFields] = useState<string[]>([])




    /**
     * =============================================================================================
     * NAVIGATION HELPERS
     * =============================================================================================
     */

    // THE MAIN MECHANISM FOR GETTING DATA FOR THE DATA MODAL.
    //   - (INCLUDING when row is clicked in db-results list/table).
    //   -  Use url params changes to trigger loading of data modal. (And possibly also for just 
    //      showing the query/filters to search a different table, maybe). This is NOT for when a db-row
    //      result is clicked (bc that doesn't actually need to pull new data from the API bc that 
    //      data is already in `dbResults`). Rather, it's for when a link or the expand button/icon is 
    //      clicked within the data modal, and the modal needs to show data for a different object
    //      that it doesn't have data for yet. The url change will trigger the `getDataObj()` function to 
    //      run and pull up the db-modal with the result data.
    //          - Going to this url will also trigger update of the state variables `currentTableList`
    //            and/or `currentTableObj`.
    //  - If `currentTableList` will be changing, the clear the data-list/table (`dbResults`).
    const goToLinkForData = useCallback((
        rowId: number, fieldName?: string, tableNameOverride?: string,
        usedBackButton?: boolean, _changedFields?: string[],
    ) => {
        // (because typescript)
        if (!fieldName && !tableNameOverride) {
            console.error('Bad args for func goToLinkFor', rowId, fieldName, tableNameOverride, 'x');
            return;
        }
        // Most of the time the data modal will already be opened, except when user clicks on main table row.
        setShowDataModal(true)
        
        // Build the url to navigate to.
        let url = ''
        if (rowId && !isNaN(Number(rowId))) {
            url = `/admin/lists/${fieldName || tableNameOverride || ''}/${rowId}`
        } else {
            url = `/admin/lists/${fieldName || tableNameOverride || ''}`
        }
        if (usedBackButton) {
            url += `/${BACK_BUTTON_URL_STR}` // (any string will do)
        }

        // FYI: If user pressed the back button to get to the func then they will have alrady 
        //      confirmed, so they will have passed in an empty arr for `_changedFields`.
        const confirmed = confirmNavigate(_changedFields || [])

        if (confirmed) {
            // If table name is changing, clear the data-list and mark the data-list (table) as not-ready.
            if (tableNameOverride && tableNameOverride !== currentTableList) {
                setDbResults([])
                setDbResultsTime('')
            }
            navigate(url)
        }

        
    }, [currentTableList])
    

    // Runs when the url changes.
    //  - If both table name and object ID were provided in the URL params, then set those to our
    //    state vars, and ALSO automatically look up that object and open the modal for that user.
    //  - When currentTableList changes, also need to clear the the db-list/table results,
    //    so that the table won't try (and fail) to read fields that don't exist.
    useEffect(() => {
        // called xx  user undefined undefined {} false
        const backButtonPressed = !!urlBackButton

        if (urlTable === urlTablePrev.current && urlObjId === urlObjIdPrev.current) {
            // If the back button was just pressed,
            // If only the backButton changed (or if NO parts of the url changed), don't do anything.
            // - If the "/back" part of url changed w/o anything else changing, that means "/back"
            //   was removed from url.
            // - On acutal "back" actions, the objId and probably urlTable will both change too.
            // - If this `useEffect` was called w/o "/back" changed, then it was just another 
            //   dep update that we need the data for, but don't need to do anything with.
            // DON'T return here, continue to below...
        } else if (
            urlTable && TAF[urlTable] && urlObjId && !isNaN(Number(urlObjId))
            // && (urlTable !== urlTablePrev.current || urlObjId === urlObjIdPrev.current)
        
        ) { // (doesn't need `TAFG`)
            // At this point we know that the urlTable or urlObjId defnitely changed.
            setCurrentTableList(urlTable) // `currentTableObj` will set when data is retrieved.
            // Only clear the data-list (table) results (`dbResults`) if table has actually changed.
            if (urlTable !== urlTablePrev.current) {
                setDbResults([])
                setDbResultsTime('')
            }
            // setShowDataModal(true) // Just keep it open for UI smoothness.
            // Use override arg (3rd arg) bc states won't update the function fast enough.
            // - This function will handle remove of the 'backButton' url query param.
            // - Pass null in place of first arg (for `dataObj`) bc that was only going to be used
            //   to push to the "history" (for the back button to use later).
            getDataObj(dbResult, showDataModal, Number(urlObjId), '', urlTable, backButtonPressed, false)
        } else if (
            urlTable && TAF[urlTable] && !urlObjId
            && (urlTable !== urlTablePrev.current || urlObjId === urlObjIdPrev.current) // (yes, both)
        ) { // (doesn't need `TAFG`)
            // At this point we know either the urlTable changed or the urlObjId was removed.
            // Redircted to a main-table page (though, fyi, it may not be a valid main table page). 
            // - Make sure it's a "searchable" (valid) page. If it is, then we can stay where
            //   we are in the browser. If not, then try to redirect to the 
            //   page with the cached-query, else fall back on the user-search page.
            if (!SEARCHABLE_TABLES.has(urlTable)) {
                if (cachedQuery.current.table) {
                    // After navigating here, it will call the below block and will populate the table.
                    navigate(`/admin/lists/${cachedQuery.current.table}`)
                    return
                } else {
                    // Or just go to the Search-users page.
                    navigate(`/admin/lists/user`)
                    return
                }
            }
            // This will change what table filters are shown on the main page (ie. not the data modal).)
            setCurrentTableList(urlTable)
            // Only clear the data-list (table) results (`dbResults`) if table has actually changed.
            if (urlTable !== urlTablePrev.current) {
                setDbResults([])
                setDbResultsTime('')
            }
            // If there are cachedQuery results, and only a tableName (and no objId), this means
            // that the modal was just closed and then redirected, so auto-populate their
            // original query data and db results in the table.
            // - This one does NOT use a cache timeout.
            if (urlTable === cachedQuery.current.table) {
                setCurrentTableList(cachedQuery.current?.table || '')
                setFilterId(cachedQuery.current.query?.filterId || 0) // fall back to 0
                setFilterName(cachedQuery.current.query?.filterName || '')
                setFilterType(cachedQuery.current.query?.filterType || '')
                setFilterRole(cachedQuery.current.query?.filterRole || '')
                setFilterNpi(cachedQuery.current.query?.filterNpi || '')
                setSortField(cachedQuery.current.query?.sortField || '')
                setRowCount(cachedQuery.current.query?.rowCount || '')
                setRowSkip(cachedQuery.current.query?.rowSkip || '')
                setDbResults([...cachedQuery.current.results] || [])
                if (!dbResult || Object.keys(dbResult).length > 0) { // <-- prevent infinite loop
                    setDbResult({})
                }
                setDbResultsTime(getFriendlyLocalTime(cachedQuery.current.time))
                // Then clear it so it doesn't get used again.
                // - NVM don't; we can just overwrite it.
                // cachedQuery.current = {}   
            }
        } else if (backButtonPressed) {
            // If the back button pressed, then remove it from url through a redirect.
            // - (this won't trigger any reloading of data)
            const newUrl = window.location.pathname.split(`/${BACK_BUTTON_URL_STR}`).join('')
            navigate(newUrl)
        } else if (!SEARCHABLE_TABLES.has(urlTable || '')) {
            // This is a non-usable page - or we have no tables by that name.
            navigate(`/admin/lists/user`)
        }
        // Update vars
        urlTablePrev.current = String(urlTable)
        urlObjIdPrev.current = String(urlObjId)
        urlBackButtonPrev.current = String(urlBackButton)
        
    }, [urlTable, urlObjId, urlBackButton, dbResult, showDataModal])


    // When "Back" button is clicked in the modal, get the previous `dbResult` and populate 
    // corresopnding state vars.
    const onBackButtonClick = (_changedFields: string[]) => {
        if (!prevDbResults.current.length) return; // (this should never happen).
        // First confirm that they want to discard changes (if any)
        // - After confirming, below we'll pass an empty array as the "changed-fields" arg,
        //   cus we already checked.
        const confirmed  = confirmNavigate(_changedFields)
        if (confirmed) {
            // Deduce the url from the 'prev' obj.
            const prevDbResult = prevDbResults.current.pop()
            // `id` and `user_id` are the only two possible pk's we have right now.
            // - IMPORTANT - DON'T send changed-fields because then user when get the same confirm popup twice.
            goToLinkForData(prevDbResult.id || prevDbResult.user_id, '', prevDbResult.table, true, [])
        }
    }




    
    /**
     * =============================================================================================
     * API/BACKEND REQUESTS
     * =============================================================================================
     */


    // ======================================================
    // Get ONE ROW of data from any table with an ID.
    // ======================================================
    // - This is only called when a link/button within the data-modal is clicked.
    // - Sends updated data directly to the data-modal (`dbResult`), NOT the list (`dbResults`).
    // - Invalid args will be handled by backend, and error message or toast will show on frontend.
    // - Don't clear the db-modal's data (`dbResult`) until we know backend has data for this id.
    // - Show error message if no data obj is returned.
    // Args:
    // - `tableName` is optional and will override whatever the current state variable say. 
    //      - If it's not provided then it would be inferred from the field name that the user
    //        clicked (via the data-modal "expand" button/icon).
    // - Only one of `fieldName` OR `tableNameOverride` is necessary.
    const getDataObj = async (
        dbResult: any, showDataModal: boolean|null, 
        rowId: number, fieldName?: string,  tableNameOverride?: string,
        usedBackButton?: boolean, isRefresh?: boolean,
    ) => {
        // Guard (bc of typescript) (this shouldn't happen unless coding errors).
        if (!fieldName && !tableNameOverride) {
            console.error('Missing args for `getDataObj` func.')
            return
        } else if (!rowId) { // NaN or 0
            console.error(`Got ${rowId} for rowId in "getDataObj" func.`)
            return
        }

        // Show the full-page spinner only when modal isn't already oepned (including on first page load).
        if (!showDataModal) {
            setShowModalInitialLoadSpinner(true)
        }
        
        setModalErrorMessage('')
        setShowButtonSpinner(fieldName || tableNameOverride || '') // This can handle either the fieldName or the table name
        toast.dismiss()
        
        // ADD TO HISTORY
        // Only "remember" the current data if user didn't press the back button (or refresh).
        // - FYI: The first time the modal is opened `dbResul` will be an empty obj.
        if (!usedBackButton && !isRefresh && (dbResult && Object.keys(dbResult).length)) {
            prevDbResults.current.push(dbResult)
        }

        // Convert the fieldName into a tableName.
        const tableName = tableNameOverride || TAF[currentTableObj].expandable[fieldName || ''] 
                                            || TAFG.expandable[fieldName || ''] || ''
        if (!tableName) {
            console.error('Bad args for `getDataObj` func.')
            return
        }

        // Check for a cached data obj that was recently created (< 5 mins ago). If it exists,
        // then use that instead and return.
        const cachedDataObj = cachedDataObjs.current[tableName] ? 
                                cachedDataObjs.current[tableName][String(rowId)] :
                                null
        if (!isRefresh && cachedDataObj && (cachedDataObj.time > Date.now() - CACHE_DURATION)) {
            setDbResult({...cachedDataObj})
            setCurrentTableObj(tableName)
            setShowButtonSpinner('')
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            console.error(session.error)
            setErrorMessage(`Error: ${session.error}`)
            return 
        }
        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData() 
        formData.append('session', sessionJson)
        formData.append('table', tableName)
        formData.append('idToGet', String(rowId)) // always send as "id", backend will handle "user_id".
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/admin/get-general-obj`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            if (!data) { 
                toast.error(`Object not found!\r\n\r\nTry to refresh or sign in again.`)
            } else { 
                console.log(data)
                // Add timestamp to the data
                data.time = Date.now()
                setDbResult({...data})
                // Set the new current-table name. (don't change currentTableList, obvs).
                setCurrentTableObj(tableName)
                // Don't need it when hopping objects inside the data modal, but DO need on first page load run.
                setShowDataModal(true)
                // Cache the item with current timestamp (from above).
                // - (make sure the keys exist first)
                cachedDataObjs.current[tableName] = cachedDataObjs.current[tableName] || {}
                cachedDataObjs.current[tableName][String(rowId)] = cachedDataObjs.current[tableName][String(rowId)] || {}
                cachedDataObjs.current[tableName][String(rowId)] = {...data}
                // If this was a refresh (or even if it wasn't), clear the changed-fields and scroll to top.
                setChangedFields([])
                setUnmarkChanged(true)
                dataModalRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        }).catch(async (err) => {
            setTimeout(() => toast(parseErrorObject(err, '#1445a')), 350)
        }).finally(() => {
            setShowButtonSpinner('')
            setShowModalInitialLoadSpinner(false)
            setShowRefreshSpinner(false)
            // If back button was pressed, remove that segment from the url.
            // - (this won't trigger any reoloading of data)
            if (usedBackButton) {
                const newUrl = window.location.pathname.split(`/${BACK_BUTTON_URL_STR}`).join('')
                navigate(newUrl)
            }
        })
    }



    // ======================================================
    // Get LIST OF ROWS of data for any table.
    // ======================================================
    const getDataList = async (
        currentTableList: string, filterId: number,
        filterName: string, filterType: string, filterRole: string, 
        filterNpi: string, sortField: string, rowCount: number, rowSkip: number,
        // idToGet?: number
    ) => {
        setShowSpinner(true)
        setResultsSummary('')
        setErrorMessage('')
        toast.dismiss()
        prevDbResults.current = []

        // There's special behavior if `idToGet` is provided.
        // - This is for when a link is clicked from another modal to explore this object.
        // const getSpecificObjId = idToGet && !isNaN(Number(idToGet))

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            setShowSpinner(false)
            setErrorMessage(`Error: ${session.error}`)
            return 
        }

        // Clear db results from previous query. 
        setDbResults([])
        setDbResult({}) // Always clear this, else get funcky issues when clicking btw db modal links.
        setDbResultsTime('')
        // cachedQuery.current = {}

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData() 
        formData.append('session', sessionJson)
        formData.append('table', currentTableList)
        formData.append('filterId', String(filterId || '')) // Now fall back on '', not 0.
        formData.append('filterName', filterName)
        formData.append('filterType', filterType)
        formData.append('filterRole', filterRole)
        formData.append('filterNpi', filterNpi)
        formData.append('sortField', sortField)
        formData.append('rowCount', String(rowCount))
        formData.append('rowSkip', String(rowSkip))
        // IFF obj ID is provided, then only lookup that obj id.
        // formData.append('idToGet', String(idToGet || ''))
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/admin/get-general-list`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            if (!data || !data.length) {
                // If no data, just show error message.
                if (rowSkip) {
                    setErrorMessage('No more results found. Try reducing the start row.')
                } else {
                    setErrorMessage('No results found')
                }
                // Scroll down to erro message upon success.
                errorMessageBox.current?.scrollIntoView({ behavior: "smooth", block: "start" })
            } else {
                // Cache the query and the results.
                const now = Date.now()
                cachedQuery.current = {
                    time: now,
                    table: currentTableList,
                    query: {
                        filterId: filterId || 0, // fall back on 0
                        filterName,
                        filterType,
                        filterRole,
                        filterNpi,
                        sortField,
                        rowCount,
                        rowSkip,
                    },
                    results: data,
                }
                // TODO: is this timeout necessary? 
                // await new Promise((resolve) => setTimeout(() => {
                    setDbResults(data)
                    setDbResultsTime(getFriendlyLocalTime(now))
                    setResultsSummary(`Showing results ${Number(rowSkip) + 1} to ${Number(rowSkip) + data.length}`)
                    // resolve(true)
                // }, 500))
                // Scroll down totable upon success.
                beforeTablResultsBox.current?.scrollIntoView({ behavior: "smooth", block: "start" })
            }
        }).catch(async (err) => {
            setErrorMessage('')
            setTimeout(() => {
                setErrorMessage(parseErrorObject(err, '#1413a'))
            }, 350)
        }).finally(() => {
            setShowSpinner(false)
        })
    }



    // ======================================================
    // UPDATE any data obj.
    // ======================================================
    const updateDataObj = async (
        e: React.FormEvent<HTMLFormElement>, 
        currentTableObj: string, dbResult: any, _changedFields: string[],
        showDataModal: boolean,
    ) => {
        e.preventDefault()
        setShowModalSpinner(true)
        setModalErrorMessage('')
        toast.dismiss()

        if (!dbResult || typeof dbResult !== 'object' || !Object.keys(dbResult).length) {
            toast.error('No object to modify. Please contact an admin.')
            return
        }

        // Confirm with user the changes they are about to make.
        if (!_changedFields.length) {
            alert("You haven't made any changes")
            setShowModalSpinner(false)
            return
        } else {
            const confirmed = confirmChangedDetails(dbResult, _changedFields)
            if (!confirmed) {
                setShowModalSpinner(false)
                return
            }
        }

        // This sessionObj should be set when they first ran a query.
        if (!sessionObj) {
            setModalErrorMessage('Please refresh the page or log out and login again.')
            setShowModalSpinner(false)
            return
        }
        // Assume sessionObj is valid if it exists.
        const sessionJson = JSON.stringify(sessionObj)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData(e.target as HTMLFormElement)

        // Only submit fields that have been changed.
        const fieldsToRemove: string[] = []
        formData.forEach((value, key) => { // `key` is same as `fieldName`/`field`, since <form name=`${field}`>.
            if (!_changedFields.includes(String(key))) {
                fieldsToRemove.push(String(key))
            }
        })
        for (let fieldName of fieldsToRemove) {
            if (fieldsToRemove.includes(fieldName)) {
                formData.delete(fieldName)
            }
        }

        // All fields that are select/dropdowns just return indexes for the selection, so convert 
        // those back to the right values, and update in `FormData` obj.
        //  - Edge case (handled): If field is marked as changed, and it was a dropdown list
        //    that had a value that was not in the `modifiable` list (aka the list of options
        //    available for the user to select), and therefore an extra row was added to the options
        //    for display purposes), then effectively that data won't get sent to the backedn.
        //      - This is because the original `modifiable` list was never changed, so the value
        //        that this obj returns for that selected index will be `undefined`.
        /// - Besides, the backend will prevent users from setting that field anyway.
        const modifiableFields = {...TAF[currentTableObj].modifiable, ...TAFG.modifiable}
        for (let [fieldName, options] of Object.entries(modifiableFields)) {
            // Make sure that this is both: a dropdown/selcct field, and a changed field.
            if (!Array.isArray(options)) continue;
            if (!_changedFields.includes(fieldName)) continue;
            // The data obj that the user selected.
            const index = Number(formData.get(fieldName)) 
            // For vals that were "appended" to to the select-dropdown's options, `actualValue` 
            // will be undefined,  which means that the value was changed by the user then 
            // changed back, so we'll remove those types of fields/vals from FormData.
            const actualValue = modifiableFields[fieldName][index]
            if (actualValue === undefined) {
                formData.delete(fieldName)
            } else {
                // Convert all other vals to String.
                formData.set(fieldName, String(actualValue))
            }
        }

        // Add session data for POST request.
        formData.append('session', sessionJson)
        // And the currently active object ID and current object's table name.
        const pk = dbResult.id || dbResult.user_id // (these are the only possibilities rn)
        formData.append('idToUpdate', String(pk))
        formData.append('table', currentTableObj)

        // Post the update data to the backend.
        axios.post(`${API_DB_URL}/admin/set-general-obj`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const nonBlockingErrorMessage = res.data
            // There will be a message here if some fields didn't update due to permissions.
            // - fields/vals that were permitted to be updated will have been updated, but also
            //   the backend will have skipped updaing fields/vals that weren't permitted,
            //   and will have the corresponding error note here.
            // - If the response string is empty, then nothing went wrong.
            // - Timeout needed for toaster to keep showing.
            setTimeout(() => {
                if (typeof nonBlockingErrorMessage === 'string' && !!nonBlockingErrorMessage) {
                    toast.error(nonBlockingErrorMessage, { autoClose: false })
                } else {
                    toast.success(`Row updated`, { autoClose: 1000 })
                }
            }, 500)

            // setTriggerReloadDataObj(true) // needed? - don't think so...

            // After the row is updated, clear the changed-fields list and trigger a "REFRESH" of the data.
            setChangedFields([])
            getDataObj(dbResult, showDataModal, pk, '', currentTableObj, false, true)
        }).catch(async (err) => {
            const errMsg = `Failed to save data:\r\n\r\n${parseErrorObject(err) || 'Row failed to update for unknown reason (#2)'}`
            toast.error(errMsg, { autoClose: false })
        }).finally(() => {
            setShowModalSpinner(false)
        })
    }


    // ======================================================
    // GET CHANGE LOG
    // ======================================================
    /**
     * Get list of recent changes made to a particular object (by table name & row Id).
     * Errors for this one use toast alerts.
     */
    const getChangeLog = async (
        tableName: string, rowId: number,
    ) => {
        if (!tableName || !rowId) {
            toast.error('Something went wrong with the change log (#1a). Please contact an admin.')
            return
        }
        // Show the button spinner for the change-log button inside of the data-modal.
        setShowChangeLogSpinner(true)
        
        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            console.error(session.error)
            setErrorMessage(`Error: ${session.error}`)
            return 
        }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData() 
        formData.append('session', sessionJson)
        formData.append('table', tableName)
        formData.append('rowId', String(rowId)) // always send as "id", backend will handle "user_id".
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/admin/get-change-log`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            if (!data) { 
                toast.error('Something went wrong with the change log (#2a). Please contact an admin.')
            } else { 
                setChangeLogResults([...data])
                setShowChangeLogModal(true)
                // Why not - it will make it less confusing when that modal is closed.
                dataModalRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }).catch(async (err) => {
            setTimeout(() => toast(parseErrorObject(err, '#1746a')), 350)
        }).finally(() => {
            setShowChangeLogSpinner(false)
        })
    }


    /**
     * Trigger to get the change-log data from backend.
     * =================================================
     */
    useEffect(() => {
        if (showEditHistory) {
            setShowEditHistory(false)
            getChangeLog(dbResult.table, dbResult.id || dbResult.user_id)
        }
    }, [showEditHistory, dbResult])

    /**
     * Helper that both parses a JSON dict and returns it's keys/vals in sorted order of keys.
     * =================================================
     */
    const sortedEntries = (dataStr: string) => {
        const obj = JSON.parse(dataStr)
        const entries = Object.entries(obj)
        const entriesSorted = entries.sort((a, b) => {
            if (a[0] < b[0]) { return -1 }
            else if (a[0] > b[0]) { return 1 } 
            return 0
        })
        return entriesSorted
    }
    // Zip the old_val and new_val enries together.
    const sortedEntries_4k = (old_dataStr: string, new_dataStr: string) => {
        const old_obj = JSON.parse(old_dataStr)
        const new_obj = JSON.parse(new_dataStr)
        const old_entries = Object.entries(old_obj)
        const new_entries = Object.entries(new_obj)
        const old_entriesSorted = old_entries.sort((a, b) => {
            if (a[0] < b[0]) { return -1 }
            else if (a[0] > b[0]) { return 1 } 
            return 0
        })
        const new_entriesSorted = new_entries.sort((a, b) => {
            if (a[0] < b[0]) { return -1 }
            else if (a[0] > b[0]) { return 1 } 
            return 0
        })
        // Format [[<old_k>, <old_v>, <new_k>, <new_v>], ...]
        const result = []
        for (let x=0; x < new_entriesSorted.length; x++) {
            const okovnknv = old_entriesSorted[x].concat(new_entriesSorted[x])
            result.push(okovnknv)
        }
        return result
    }



    /**
     * =============================================================================================
     * API/BACKEND REQUESTS HELPERS
     * =============================================================================================
     */

    // REFRESH BUTTON
    //  - When the refresh button is clicked inside of the data modal.
    //  - Clear ALL cached data objs first (but not cached data-list (for query and table results)).
    //  - Reload just the data in the modal without changing or refreshing the page itself.
    useEffect(() => {
        if (triggerReloadDataObj) {
            setTriggerReloadDataObj(false)
            cachedDataObjs.current = {}
            const confirmed = confirmRefresh(changedFields)
            if (confirmed) {
                setShowRefreshSpinner(true)
                const pk = dbResult.id || dbResult.user_id // (these are the only possibilities rn)
                getDataObj(dbResult, showDataModal, pk, '', dbResult.table, false, true)
                setForceUpdate(Date.now())
            }
        }
    }, [triggerReloadDataObj, dbResult, changedFields, showDataModal])





    /**
     * =============================================================================================
     * TABLE HELPERS (data-list)
     * =============================================================================================
     */

    // Enforce max row count
    useEffect(() => {
        setRowCount(Math.min(Math.max(rowCount, 0), MAX_ROW_COUNT))
    }, [rowCount])

    // Functions to get user-friendly datetime strings for psql date strings.
    const getUtcTimeString = (psqlTime: string) => {
        const pgTime = new Date(psqlTime);
        const fullUtcTime = pgTime.toLocaleString('en-US', { 
            weekday: 'short', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', 
            second: 'numeric', year: 'numeric', timeZone: 'UTC',
        })
        return fullUtcTime
    }
    const getLocalTimeString = (psqlTime: string) => {
        const pgTime = new Date(psqlTime);
        const fullLocalTime = pgTime.toLocaleString('en-US', { 
            weekday: 'short', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', 
            second: 'numeric', year: 'numeric',
        })
        return fullLocalTime
    }
    const getLocalTimeZone = () => {
        return new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(new Date()).split(', ')[1]
    }
    const getLocalTimeString_alt = (psqlTime: string) => {
        const pgTime = new Date(psqlTime);
        const fullLocalTime = pgTime.toLocaleString('en-US', { 
            month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', 
            second: 'numeric', year: 'numeric',
        })
        return fullLocalTime
    }
    // Time that looks like: 4/1 5:01 pm. Return 'N/A' if invalid 
    const getFriendlyLocalTime = (timestamp: number) => {
        try {
            const jsTime = new Date(timestamp)
            const dateString = `${jsTime.toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "numeric",
                    day: "numeric",
                })}, ${jsTime.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                })}`
            return dateString
        } catch (e) {
            return 'n/a'
        }
    }
    // Time that looks like: 4/1/2023 5:01 pm. Return 'N/A' if invalid 
    const getFriendlyLocalTime_wYear = (psqlTime: string) => {
        try {
            const pgTime = new Date(psqlTime);
            const dateString = `${pgTime.toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "numeric",
                    year: "numeric",
                    day: "numeric",
                })}, ${pgTime.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                })}`
            return dateString.replace(/,\s*/g, '\r\n')
        } catch (e) {
            return 'n/a'
        }
    }


    

    /**
     * =============================================================================================
     * DATA-MODAL  HELPERS (data-obj)
     * =============================================================================================
     */

    // Whenver is modal is opened:
    //  - Reset the tracking vars.
    // Whenever modal is closed:
    //  - Restore the url to where they were before opening the modal.
    //  - After they navigate to the url, if there is a `cachedQuery`, then it will be restored.
    //  - But all 'cachedDataObjs` will be removed.
    useEffect(() => {
        if (showDataModal) { 
            // (When `showData` goes from false/null -> true).
            setChangedFields([])
            setModalErrorMessage('')
        } else if (showDataModal === false) { 
            // (Ignore null, which is on first page load).
            // If there is a cached query object, go to the path indicated by that obj.
            // - Otherwise just chop the id off of the url.
            // - Also clear ALL cached query objects.
            if (cachedQuery.current.table) {
                navigate(`/admin/lists/${cachedQuery.current.table}`)
            } else {
                let url = window.location.pathname
                url = url.split('?')[0].split('#')[0]
                url = url.slice(-1) === '/' ? url.slice(0, -1) : url
                if (!isNaN(Number(url.split('/').slice(-1)[0]))) {
                    url = url.split('/').slice(0, -1).join('/')
                    navigate(url)
                }
            }
            // And clear the "back"/"history" for the modal.
            prevDbResults.current = []
            // And clear the cached data-objects (but not the data-list)
            cachedDataObjs.current = {}
        }
    }, [showDataModal])

    
    // Whenver new data populates the modal, scroll to top.
    useEffect(() => {
        dataModalRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [dbResult])

    
    // Remove duplicates from changedFields list.
    useEffect(() => {
        const originalLength = changedFields.length
        const newList = Array.from(new Set(changedFields))

        if (newList.length != originalLength) {
            setChangedFields(newList)
        }
    }, [changedFields])

    // Show info text in a separate small modal.
    const onClickInfoIcon = (text: string) => {
        setInfoText(text)
        setShowInfoTextModal(true)
    }

    // Clean `dbResult` object - keep only rows that aren't marked `hide`.
    const cleanFieldsList = useCallback((oneRowData: any) => {
        if (!currentTableObj) return;
        const newData = {...oneRowData}
        for (let fieldToRemove of TAF[currentTableObj].hide.concat(TAFG.hide)) {
            delete newData[fieldToRemove]
        }
        return newData
    }, [currentTableObj])

    // For select-dropdowns, if row has a value that isn't in `modifiableFields`, add it 
    // to the options list so that it displays anyway.
    //  - This is needed because, for example if the `lock` field only allows it to be set to
    //    true, but the value is, say, null, then any time the user updates any other field
    //    then it will also lock the data obj from editing.
    const addDropdownValIfNecessary = useCallback((fieldName: string, val: any) => {
        const thisFieldsModifiableVals = (TAF[currentTableObj].modifiable[fieldName] || TAFG.modifiable[fieldName])
        if (!Array.isArray(thisFieldsModifiableVals)) {
            // (for typescript) - this shouldn't happen if coded correctly.
            console.error(`Non-array for 'thisFieldsModifiableVals' for field ${fieldName} and val ${val}`)
            return []
        } else if (thisFieldsModifiableVals.includes(val)) {
            return thisFieldsModifiableVals
        } else {
            // IMPORTANT: add field to the END of the list, because this `modifiable[fieldName]`
            // dict will be referenced for select-dropdowns that have been changed before
            // POST'ing the update data to the backend, because our <SelectField> component
            // only returns the indexes as the value.
            //  - Note that using `concat` here returns a copy of the array and DOES NOT
            //    actually modify the `thisFieldsModifiableVals` object itself.
            //  - DON'T convert `val` to string here - it will cause problems. Besides it's handled
            //    by <SelectField> component.
            return thisFieldsModifiableVals.concat(val)
        }
    }, [currentTableObj])


    // The curent table name, with first character capitalized. And replace underscores.
    const capitalizedTableObjName = useMemo((): string => {
        let tName = currentTableObj
        tName = tName.charAt(0).toUpperCase() + tName.slice(1);
        tName = tName.replace('_', ' ')
        return tName
    }, [currentTableObj, dbResult])
    const capitalizedTableListName = useMemo((): string => {
        let tName = currentTableList
        tName = tName.charAt(0).toUpperCase() + tName.slice(1);
        tName = tName.replace('_', ' ')
        return tName
    }, [currentTableList, dbResult])

    
    


    /**
     * ==========================================================================================
     * DATA VALUE COMVERTERES: THE NOTES TO SHOW IN THE RIGHT COLUMN FOR A GIVEN FIELD IN DATA MODAL
     * ==========================================================================================
     */
    // Definitions (functions) for how data values should be converted in the UI.
    // - These UI vals do NOT affect values sent to the backend, which will get correct data(/type).
    // - Most of these are pretty generic and can be applied to all tables with that field name,
    //   but there are a few that will have conditionals with the table name.
    //
    // - Return types don't need to be formatted bc `makeFieldValNotes()` will convert them all to string.
    // - Only add fields here that need to be modified, you can leave the other fields off.
    // - If there shouldn't be any text, just `return` or don't return at all.
    // Args:
    //      - key => the field name (same as db column name and same as <input>'s `name`)
    // Return:
    //      - Return an array of `[<string>, <style_obj>]`, second arg is optional.
    //      - If ANY non-nullish style dict is provided (including `{}`), then the text will be 
    //          automatically styled to be red & bold, UNLESS a `color` and `fontWeight` are 
    //          specified in the provided style dict.
    //      - When no style obj is provided, it will show up in default black(ish) text.
    //      - A return value isn't necessary. No return val will ultimately be sent to UI as empty str.
    const OUTPUT_CONVERTER: {[key: string]: Function} = {
        // id: (field: string, value: any, data: any) => {
        //     if (data.table === 'role') return ['Role ID is always the same as User ID', null]
        // },
        user_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            const U = data.user
            if (U) return [[U.first_name, U.last_name].join(' ').replace(/\s+/, ' '), {}]
        },
        active: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (value === null && data.table === 'user') {
                return ["null/empty means user hasn't logged in for the first time yet.", null]
            } else if (value === false && data.table === 'user') {
                return ["This user has been deactivated. They will not be able to use their accont, "
                        + "but they can still register again with "
                        + "the same email and with a different account type. To prevent this, "
                        + "set `block_user` to true.", null]
            } else if (value === false && data.table === 'product') {
                return ["This product has been deactivated.", null]
            } else if (value === false && data.table === 'sku') {
                return ["This sku has been deactivated.", null]
            } else if (value === false && (['rep_org', 'business', 'clinic'].includes(data.table))) {
                if (data.reg_complete === null) return [`This ${data.table} has been deactivated.`, null]
                if (data.reg_complete !== null) return [`This ${data.table} was never activated.`, null]
            } else if (value === false && (['address', 'phone', 'website'].includes(data.table))) {
                if (data.reg_complete) return [`This ${data.table} has been deactivated.`, null]
            } else if (value === true && (['address', 'phone', 'website'].includes(data.table))) {
                if (data.reg_complete) return [`${data.table} is marked active by default.`, null]
            } else if (value === false && data.table === 'tag') {
                return ["INACTIVE", null]
            } else if (value === false) {
                return [`This ${data.table} isn't active.`, null]
            }
        },
        block_user: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (value === true) return ["User is blocked from logging in and from re-registering "
                + "with the same email address.", {}]
        },
        role: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!data.user_role) return;
            const IGNORE_FIELDS = ['lock', 'is_public', 'is_regen']
            const roleNames = Object.entries(data.user_role).map(([key, val]) => (
                (val === true && !IGNORE_FIELDS.includes(key)) ? key : ''
            )).filter(val => !!val)
            const publicUser = `${data.user_role.is_public ? 'Public user' : ''}`
            const regenUser = `${data.user_role.is_regen ? 'Regenative Labs user' : ''}`
            let publicOrRegenUser = (publicUser && regenUser) ? [publicUser, regenUser].join(' AND ') : publicUser + regenUser
            publicOrRegenUser = publicOrRegenUser ? publicOrRegenUser + '\r\n' : ''
            return [`${publicOrRegenUser}Roles: [${roleNames.join(', ')}]`, {fontWeight: '400', color: '#334', lineHeight: 1.4}]
        },
        u_type: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.entity_type) return;
            return [`${data.entity_type.name.toUpperCase()}`, {fontWeight: '400', color: '#334'}]
        },
        type: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.entity_type) return;
            return [`${data.entity_type.name.toUpperCase()}`, {fontWeight: '400', color: '#334'}]
        },
        lock: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (value === true) return ["LOCKED", {color: 'red'}]
            return [value, null]
        },
        cognito_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.cognito) return [`Empty means that registration may have failed on step 1, or this is a test user.`, {color: 'red'}]
            const C = data.cognito
            return [`active: ${C.active}\r\nverified: ${C.verified})`, null]
        },
        address_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.address) return;
            const A = data.address
            const line1 = A.name ? A.name + '\r\n' : ''
            const line2 = `${[(A.street1 || ''), (A.street2 || '')].join(', ')}\r\n`
            const line3 = `${[(A.city ? A.city + ',' : ''), (A.state || ''), (A.zip || '')].join(' ')}`
            return [`${line1}${line2}${line3}`, null]
        },
        phone_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.phone) return;
            return [`${data.phone.phone}`, {}]
        },
        email_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.email) return;
            return [`${data.email.email}`, {}]
        },
        website_id: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !data.website) return;
            return [`${data.website.website}`, null]
        },
        product_list: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !value.length) return;
            const pluralized = value.length === 1 ? 'product' : 'products'
            return [`${value.length} ${pluralized}`, null]
        },
        sku_list: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !value.length) return;
            const pluralized = value.length === 1 ? 'sku' : 'skus'
            return [`${value.length} ${pluralized}`, null]
        },
        users: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => { // (this field doesn't exist yet, AFAIK)
            if (!value || !value.length) return;
            const pluralized = value.length === 1 ? 'user' : 'users'
            return [`${value.length} ${pluralized}`, null]
        },
        business: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !Object.keys(value).length) return;
            if (data.table === 'product') return [`The business that listed this product.`, null]
        },
        product: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value || !Object.keys(value).length) return;
            if (data.table === 'sku') return [`The product that this sku belongs to.`, null]
        },
        created_at: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value) return;
            const fullUtcTime = getUtcTimeString(value)
            const fullLocalTime = getLocalTimeString(value)
            const localTimeZoneName = getLocalTimeZone()
            // return [`${fullUtcTime} UTC\r\n${fullLocalTime} ${localTimeZoneName} (local time)`, null]
            if (flag1) return [`${fullUtcTime} (UTC)`, null]
            return [`${fullLocalTime} ${localTimeZoneName}`, null]
        },
        updated_at: (field: string, value: any, data: any, flag1: boolean, flag2: boolean) => {
            if (!value) return;
            const fullUtcTime = getUtcTimeString(value)
            const fullLocalTime = getLocalTimeString(value)
            const localTimeZoneName = getLocalTimeZone()
            if (flag1) return [`${fullUtcTime} (UTC)`, null]
            return [`${fullLocalTime} ${localTimeZoneName}`, null]
        },
    }

    // Run field/values through here. Return values are ALL converted to string type.
    // - `flag` vars are just arbitrary flags to change behavior in funciton logic.
    // - `returnStringOnly` applies to this outer function.
    const makeFieldValNotes = useCallback((
        field: string, value: any, 
        returnStringOnly?: boolean,
        flag1?: boolean, flag2?: boolean, 
    ) => {
        const func = OUTPUT_CONVERTER[field]
        if (!func) return '';
        const res = func(field, value, dbResult, flag1, flag2)
        const formattedVal = (res && res[0]) ? res[0] : ''
        const style = (res && res[1]) ? res[1] : null
        // Make all added styles BOLD otherwise indicated by the provided style obj.
        if (style && !style.fontWeight) style.fontWeight = '400'
        if (returnStringOnly) {
            return String(formattedVal)
        }
        return (
            <span style={style || {}}>{String(formattedVal)}</span>
        )
    }, [dbResult])

    
    // Convert field names (the left column) in data-modal.
    const formatFieldName = (_field: string) => {
        let field = _field.toLowerCase().replace(/_/g, ' ').replace(/id$/, 'ID')
        field = field.slice(0, 1).toUpperCase() + field.slice(1,)
        return field
    }
    
    // ^^ ===================================================================================== ^^

    // Make table based on the table name
    const makeModalTitle = (data: any) => {
        if (!data || typeof data !== 'object') return ''
        let titleName = (
            data.name_display
            || data.name
            || [data.first_name || '', data.last_name || ''].join(' ').replace(/\s+/g, ' ').trim()
            || data.label
            || data.headline
            || data.headline_1
            || data.sub_headline
            || data.sub_headline_1
            || data.street1
            || (typeof data.phone === 'string' ? data.phone : '')
            || (typeof data.email === 'string' ? data.email : '')
            || (typeof data.website === 'string' ? data.website : '')
            || (data.table || '').replaceAll('_', ' ') + ` id=${data.id || data.user_id}`
        )
        titleName = (titleName || '').slice(0, 50)
        return titleName
    }

    // ^^ ===================================================================================== ^^



    /**
     * Misc template helpers
     */
    const isDict = (obj: any) => {
        return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    }
    interface Dict {
        [key: string]: any;
    }
    // For simplifying template logic.
    const _isHideEmpty = useCallback((field: string): boolean => {
        return TAF[currentTableObj].hideEmpty.includes(field) || TAFG.hideEmpty.includes(field)
    }, [currentTableObj])
    const _isEmpty = useCallback((val: any): boolean => { // Return `true` if nullish, (including 0/false/''), or is an empty array or dict.
        return !val || (Array.isArray(val) && !val.length) || (typeof val === 'object' && !Object.keys(val).length)
    }, [currentTableObj])
    const _hasNotes = useCallback((field: string): boolean => {
        return !!TAF[currentTableObj].notes[field] || !!TAFG.notes[field]
    }, [currentTableObj])
    const _isSimpleDataType = useCallback((val: any): boolean => {
        return (['string', 'number', 'boolean'].includes(typeof val) ||  !val)
    }, []) 
    const _isNotModifiable = useCallback((field: string): boolean => {
        return dbResult.lock || (!TAF[currentTableObj].modifiable[field] && !TAFG.modifiable[field] && !TAFT.has(field))
    }, [currentTableObj, dbResult])
    const _isTimestampField = useCallback((field: string): boolean => {
        return TAFT.has(field)
    }, [])
    const _isTextField = useCallback((field: string): boolean => {
        return TAF[currentTableObj].modifiable[field] === 'TEXT' || TAFG.modifiable[field] ==='TEXT'
    }, [currentTableObj])
    const _isNotModifiableArray = useCallback((field: string): boolean => {
        return !Array.isArray(TAF[currentTableObj].modifiable[field]) && !Array.isArray(TAFG.modifiable[field])
    }, [currentTableObj])
    const _isModifiableArray = useCallback((field: string): boolean => {
        return Array.isArray(TAF[currentTableObj].modifiable[field]) || Array.isArray(TAFG.modifiable[field])
    }, [currentTableObj])
    const _isLinkable = useCallback((field: string, val: any): boolean => {
        return (!!TAF[currentTableObj].link[field] || !!TAFG.link[field]) && (Array.isArray(val) || isDict(val))
    }, [currentTableObj])
    const _getLinkTableName = useCallback((field: string): string => {
        return TAF[currentTableObj].link[field] || TAFG.link[field] || ''
    }, [currentTableObj]) 
    const _getArrOrDictAsArr = useCallback((val: any): any[] => {
        return isDict(val) ? [val as Dict] : Array.isArray(val) ? val : []
    }, [])
    const _getNameForAnyObj = useCallback((val: any): string => {
        return makeModalTitle(val)
        // return (val.name_display || val.name || ([val.first_name, val.last_name].join(' '))).replace(/\s+/g, ' ').trim()
    }, [])
    const _isExpandable = useCallback((field: string, val: any): boolean => {
        return (TAF[currentTableObj].expandable[field] || TAFG.expandable[field]) && (val ?? false)
    }, [currentTableObj])
    const _getExpandableTableName = useCallback((field: string): string => {
        return TAF[currentTableObj].expandable[field] || TAFG.expandable[field]
    }, [currentTableObj])
    const _shouldShowButtonSpinner = useCallback((field: string): boolean => {
        return [field, TAF[currentTableObj].expandable[field], TAFG.expandable[field]].includes(showButtonSpinner)
    }, [currentTableObj, showButtonSpinner])
    // Returns [<should_use_red_icon>, <formatted_note>]
    const _redNote = useCallback((field: string): [boolean, string] => {
        const noteText = TAF[currentTableObj].notes[field] || TAFG.notes[field]
        if (Array.isArray(noteText)) return [false, '']
        if (noteText.includes(RED_STR)) return [true, noteText.replaceAll(RED_STR, '')]
        return [false, noteText]
    }, [currentTableObj])

    


    /**
     * ==========================================================================================
     * Alerts for changed fields before moving away from a specific data obj (in data-modal).
     * ==========================================================================================
     */
    // Confirm save changes.
    const confirmChangedDetails = (dbResult: any, _changedFields: string[]): boolean => {
        const message = `Updating table [${dbResult.table}] [id=${dbResult.id || dbResult.user_id}]\r\n\r\n`
            + `The following fields have been updated , are you sure you want to continue?\r\n\r\n`
            + `- ${_changedFields.join('\r\n - ')}`
        const confirmed = window.confirm(message)
        return confirmed
    }

    // Confirm close modal
    const closeModalConfirmChanges = (_changedFields: string[]) => {
        if (_changedFields.length) {
            const confirmed = window.confirm("Discard changes?")
            if (confirmed) {
                setShowDataModal(false)
            }
        } else {
            setShowDataModal(false)
        }
    }

    // Confirm navigate
    const confirmNavigate = (_changedFields: string[])  => {
        if (_changedFields.length) {
            const confirmed = window.confirm("Discard changes?")
            return confirmed
        } else {
            return true
        }
    }

    // Confirm refresh data (if there are changes)
    const confirmRefresh = (_changedFields: string[]) => {
        if (_changedFields.length) {
            const confirmed = window.confirm("Discard changes?")
            return confirmed
        } else {
            return true
        }
    }


    /**
     * ==========================================================================================
     * Select matching text in change-log.
     * ==========================================================================================
     */
    // For selecting on the left and auto-highlighting the right.
    const [activeChangeRow_LR, setActiveChangeRow_LR] = useState('')
    const [selectStart_LR, setSelectStart_LR] = useState(0)
    const [selectEnd_LR, setSelectEnd_LR] = useState(0)
    // For selecting on the right and auto-highlighting the left.
    const [activeChangeRow_RL, setActiveChangeRow_RL] = useState('')
    const [selectStart_RL, setSelectStart_RL] = useState(0)
    const [selectEnd_RL, setSelectEnd_RL] = useState(0)

    // Use same debounce for both.
    const SELECT_DEBOUNCE_TIMEOUT = useRef<number>(0)
    const SELECT_DEBOUNCE_TIME = 200 // ms
  
    // For Left to Right
    const handleSelection_LR = (e: React.MouseEvent<HTMLDivElement>) => {
        clearTimeout(SELECT_DEBOUNCE_TIMEOUT.current)
        SELECT_DEBOUNCE_TIMEOUT.current = window.setTimeout(() => {
            const selection = window.getSelection()
            // If no selection, clear to the highglights.
            if (!selection || selection.isCollapsed || selection.anchorOffset === selection.focusOffset) {
                setActiveChangeRow_LR('')
                return
            }
            const element = e.target as HTMLDivElement
            const rowIndex = element.getAttribute('data-row-index')
            // If the row is different (on EITHER side), clear the text selection. (but don't return).
            if ((activeChangeRow_LR && rowIndex !== activeChangeRow_LR) || (activeChangeRow_RL && rowIndex !== activeChangeRow_RL)) {
                selection?.empty()
                setActiveChangeRow_LR('')
                setActiveChangeRow_RL('')
            }
            // setActiveChangeRow_RL('') // (clear THIS side's highlights)
            setActiveChangeRow_LR(rowIndex || '')
            setSelectStart_LR(Math.min(selection.anchorOffset, selection.focusOffset))
            setSelectEnd_LR(Math.max(selection.anchorOffset, selection.focusOffset))
        }, SELECT_DEBOUNCE_TIME)
    }

    // For Right to Left
    const handleSelection_RL = (e: React.MouseEvent<HTMLDivElement>) => {
        clearTimeout(SELECT_DEBOUNCE_TIMEOUT.current)
        SELECT_DEBOUNCE_TIMEOUT.current = window.setTimeout(() => {
            const selection = window.getSelection()
            // If no selection, clear to the highglights.
            if (!selection || selection.isCollapsed || selection.anchorOffset === selection.focusOffset) {
                setActiveChangeRow_RL('')
                return
            }
            const element = e.target as HTMLDivElement
            const rowIndex = element.getAttribute('data-row-index')
            // If the row is different (on EITHER side), clear the text selection. (but don't return).
            if ((activeChangeRow_LR && rowIndex !== activeChangeRow_LR) || (activeChangeRow_RL && rowIndex !== activeChangeRow_RL)) {
                selection?.empty()
                setActiveChangeRow_LR('')
                setActiveChangeRow_RL('')
            }
            // setActiveChangeRow_LR('') // (clear THIS side's highlights)
            setActiveChangeRow_RL(rowIndex || '')
            setSelectStart_RL(Math.min(selection.anchorOffset, selection.focusOffset))
            setSelectEnd_RL(Math.max(selection.anchorOffset, selection.focusOffset))
        }, SELECT_DEBOUNCE_TIME)
    }


    return (
        <>
        <div className='body-container-admin'>
            {/* 
              * ====================================================================================
              * MODAL FOR INFO TEXT
              * ====================================================================================
              */}

            {/* Show info about a particular field in the data row when "dataModal" is opened. */}
            {showInfoTextModal ? (
                <InfoTextModal
                    show={showInfoTextModal}
                    closeModal={() => setShowInfoTextModal(false)}
                    text={infoText}
                />
            ) : null}


            {/* 
              * ====================================================================================
              * MODAL FOR CHANGE LOG
              * ====================================================================================
              */}

            {/* Show info about a particular field in the data row when "dataModal" is opened. */}
            {showChangeLogModal ? (
                <ChangeLogModal
                    show={showChangeLogModal}
                    closeModal={() => setShowChangeLogModal(false)}
                    overTitle={`Admin change log for table [${dbResult.table.replace(/_/g, ' ')}] [id=${dbResult.id || dbResult.user_id}]`}
                >
                    {!(changeLogResults && changeLogResults.length) ? (
                        <div style={{textAlign: 'center'}}>
                            No changes
                        </div>
                    ) : (
                    <div className="modal-rows-container change-log">
                        <div className='modal-row change-log title-row'>
                            <div className='left change-log'>
                                <div className='mock-link-wrapper change-log' style={{marginTop: '12px'}}>
                                    Changed by
                                </div>
                            </div>
                            <div className='middle-split'>
                                <div className='middle-split-inner-row'>
                                    <div className='split-left'>
                                        <div style={{transform: 'translateX(-32px)'}}>
                                            Old values
                                        </div>
                                    </div>
                                    <div className='split-right'>
                                    <div style={{transform: 'translateX(-8px)'}}>
                                            New values
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {changeLogResults.map((row, outer_index) => (
                            <div 
                                key={`o-${row.id}`}
                                className='modal-row change-log'
                            >
                                <div className='left change-log'>
                                    <div className='mock-link-wrapper change-log'>
                                        <div 
                                            className='mock-link' 
                                            onClick={() => {
                                                goToLinkForData(row.by_user.id, '', 'user', false, []) 
                                                setShowChangeLogModal(false)
                                            }}
                                            title={`User ID = ${row.by_user.id}`}
                                        >
                                            {_getNameForAnyObj(row.by_user)}
                                        </div>
                                    </div>
                                    {getFriendlyLocalTime_wYear(row.created_at)}
                                </div>

                                {/* Change-log select left and highlight right and vice versa. */}
                                <div className='middle-split'>
                                    {sortedEntries_4k(String(row.old_vals), String(row.new_vals)).map(([old_k, old_v, new_k, new_v], inner_index) => (
                                        <div className='middle-split-inner-row' key={`${old_k}-${inner_index}-2`}>
                                            <div className='split-left'>
                                                <div className='old-key'>
                                                    {String(old_k)}
                                                </div>
                                                <div className='old-val'>
                                                    <div 
                                                        className='envelop'
                                                        data-row-index={`${outer_index}-${inner_index}-A`}
                                                        onMouseMove={e => handleSelection_LR(e)}
                                                        // onMouseDown={() => setActiveChangeRow_RL('')} // Clear selection when switching sides.
                                                    >
                                                        {old_v !== '' ? (
                                                            ((
                                                                activeChangeRow_RL 
                                                                && activeChangeRow_RL.split('-')[0] === String(outer_index) 
                                                                && activeChangeRow_RL.split('-')[1] === String(inner_index) 
                                                                && activeChangeRow_RL.split('-')[2] === 'B'
                                                            ) ? (
                                                                <React.Fragment key={activeChangeRow_RL + selectStart_RL + selectEnd_RL}>
                                                                    {String(old_v).slice(0, selectStart_RL)}
                                                                    <span className='blue-highlight'>
                                                                        {String(old_v).slice(selectStart_RL, selectEnd_RL)}
                                                                    </span>
                                                                    {String(old_v).slice(selectEnd_RL,)}
                                                                </React.Fragment>
                                                            ) : (
                                                                String(old_v) 
                                                            ))
                                                        ): '\u00A0'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='split-right'>
                                                <div className='new-key'>
                                                    {String(new_k)}
                                                </div>
                                                <div className='new-val'>
                                                    <div 
                                                        className='envelop'
                                                        data-row-index={`${outer_index}-${inner_index}-B`}
                                                        onMouseMove={e => handleSelection_RL(e)}
                                                        // onMouseDown={() => setActiveChangeRow_LR('')} // Clear selection when switching sides.
                                                    >
                                                        {new_v !== '' ? (
                                                            ((
                                                                activeChangeRow_LR 
                                                                && activeChangeRow_LR.split('-')[0] === String(outer_index) 
                                                                && activeChangeRow_LR.split('-')[1] === String(inner_index) 
                                                                && activeChangeRow_LR.split('-')[2] === 'A'
                                                            ) ? (
                                                                <React.Fragment key={activeChangeRow_LR + selectStart_LR + selectEnd_LR}>
                                                                    {String(new_v).slice(0, selectStart_LR)}
                                                                    <span className='blue-highlight'>
                                                                        {String(new_v).slice(selectStart_LR, selectEnd_LR)}
                                                                    </span>
                                                                    {String(new_v).slice(selectEnd_LR,)}
                                                                </React.Fragment>
                                                            ) : (
                                                                String(new_v) 
                                                            ))
                                                        ): '\u00A0'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                        ))}
                    </div>
                    )}
                </ChangeLogModal>
            ) : null}


            {/* 
              * ====================================================================================
              * MODAL FOR DATA EXOLORER (data-obj)
              * ====================================================================================
              */}

            {/* Show the db row's data and allow user to modify (if user has 'admin' role.) */}
            {showDataModal ? (
                // IMPORTANT: display is controlled by both the above `showDataModal` as well as by
                // this modal's `show` prop. This ensures that modal state is always reset after closing.
                <RowDetailsModal
                    show={!!(showDataModal && Object.keys(dbResult).length)}
                    closeModal={() => closeModalConfirmChanges(changedFields) }
                    overTitle={capitalizedTableObjName}
                    topLeftButton={
                        (prevDbResults.current.length ? (
                            <BlockButton
                                type='button'
                                style={{ margin: '0px 0' }} 
                                buttonStyle="ghost-mid little"
                                onClick={() => onBackButtonClick(changedFields)}
                            >
                                {/* Below symbol is a big arrow, in the browser */}
                                {'⇦'} 
                            </BlockButton>
                        ) : null)
                    }
                    title={makeModalTitle(dbResult)}
                    isLockedAlert={(dbResult.lock) ? 'LOCKED' : ''}
                    isLockedAlertStyle={dbResult.lock ? {color: 'red', fontWeight: 'bold', fontStyle: 'normal'} : {}}
                    lastRefreshedTime={getFriendlyLocalTime(dbResult.time)}
                    urlToClipBaord={window.location.href}
                    toastFunction={toast}
                    setTriggerReloadDataObj={setTriggerReloadDataObj}
                    modalRef={dataModalRef}
                    showRefreshSpinner={showRefreshSpinner}
                    setShowEditHistory={setShowEditHistory}
                    showChangeLogSpinner={showChangeLogSpinner}
                >
                    <form onSubmit={(e) => updateDataObj(e, currentTableObj, dbResult, changedFields, showDataModal)}>
                        <div className='modal-rows-container'>
                            {/* Title row */}
                            <div key={'-1'} className='modal-row title-row'>
                                <div className='left'>
                                    Field
                                </div>
                                <div className='middle'>
                                    Database value
                                </div>
                                <div className='right'>
                                    Notes
                                </div>
                            </div>
                            {/* Main rows for user's details */}
                            {Object.entries(cleanFieldsList(dbResult) || {}).map(([field, val], index) => (
                                ((_isHideEmpty(field) && _isEmpty(val)) ? (
                                    /** Ignore rows that are both marked 'hideEmpty' and actually ARE empty. */
                                    null
                                ) : (
                                    /** Regular rows that should be displayed. */
                                    // (the `currentTableObj` in the key prevents the "controlled-
                                    //  -vs-uncontrolled input" error when changing between
                                    //  different `dbResult` objs/data).
                                    <div 
                                        key={index+field+currentTableObj} 
                                        className='modal-row'
                                    >
                                        <div className='left'>
                                            {formatFieldName(field)}:  

                                            {(_hasNotes(field)) ? (
                                                <div className={`icon-wrapper ${_redNote(field)[0] ? 'red' : ''}`}
                                                    onClick={() => {
                                                        onClickInfoIcon(_redNote(field)[1])
                                                    }}
                                                >
                                                    <Icon name='info' />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='middle'>
                                            {(_isSimpleDataType(val)) ? (
                                                <>
                                                {(_isNotModifiable(field)) ? (
                                                    // NON-MODIFIABLE fields (EXCEPT timestamp fields).
                                                    // - If the db user has `lock=true`, all fields are unmodifiable.
                                                    // - Also if the table isn't "primary", don't let it be changed.
                                                    <InputField
                                                        // This `key` prevents the "controlled-vs-uncontrolled input" error. 
                                                        // - And also allows fields to refresh even after user has typed in them.
                                                        key={forceUpdate + 1}
                                                        id={`noedit-${currentTableObj}`} // All non-modifiable have same ids.
                                                        name={``}
                                                        type='text'
                                                        inputStyle='edit-string'
                                                        value={String(val ?? '')} // not modifiable
                                                        style={{ 
                                                            padding: '2px 12px',
                                                            fontSize: '16px',
                                                            backgroundColor: 'rgba(0,4,12,.15)', 
                                                            color: val === null ? 'rgba(0, 0, 0, .2)' : '',
                                                        }}
                                                        onChange={() => {
                                                            setChangedFields(prev => [...prev, field])
                                                        }}
                                                        disabled={true}
                                                        preventAutofill={true}
                                                    />
                                                ) : (_isTimestampField(field)) ? (
                                                    // TIMESTAMP fields (NON-MODIFIABLE) get the modified value inside the input area, 
                                                    // as UTC (right col shows local timezone).
                                                    // - All of these are non-modifiable
                                                    <InputField
                                                        // This `key` prevents the "controlled-vs-uncontrolled input" error. 
                                                        // - And also allows fields to refresh even after user has typed in them.
                                                        key={forceUpdate + 2}
                                                        id={`noedit-${currentTableObj}`} // All non-modifiable have same ids.
                                                        name={``}
                                                        type='text'
                                                        inputStyle='edit-string'
                                                        value={val ? makeFieldValNotes(field, val, true, true, false) : ''} // not modifiable
                                                        style={{ 
                                                            padding: '2px 12px',
                                                            fontSize: '16px',
                                                            backgroundColor: 'rgba(0,4,12,.15)', 
                                                            color: val === null ? 'rgba(0, 0, 0, .2)' : '',
                                                        }}
                                                        onChange={() => {
                                                            setChangedFields(prev => [...prev, field])
                                                        }}
                                                        disabled={true}
                                                        preventAutofill={true}
                                                    />
                                                ) : (_isTextField(field)) ? (
                                                    // TEXTAREA modifiable fields (or number/null field which 
                                                    // are converted to text) fields.
                                                    <TextAreaField
                                                        // This `key` prevents the "controlled-vs-uncontrolled input" error. 
                                                        // - And also allows fields to refresh even after user has typed in them.
                                                        key={forceUpdate + 3}
                                                        id={`edit-${currentTableObj || 'x'}-${field}`}
                                                        name={`${field}`}
                                                        defaultValue={String(val ?? '')} 
                                                        style={{ 
                                                            paddingBottom: '64px',
                                                            fontSize: '16px',
                                                            backgroundColor: 'rgba(222, 238, 255, .5)', 
                                                        }}
                                                        onChange={() => {
                                                            setChangedFields(prev => [...prev, field])
                                                        }}
                                                        onChangeRedBackground={true}
                                                        unmarkChanged={unmarkChanged}
                                                        setUnmarkChanged={setUnmarkChanged}
                                                        preventAutofill={true}
                                                    />
                                                ) : (_isNotModifiableArray(field)) ? (
                                                    // TEXT modifiable fields (or number/null field which 
                                                    // are converted to text) fields.
                                                    <InputField
                                                        // This `key` prevents the "controlled-vs-uncontrolled input" error. 
                                                        // - And also allows fields to refresh even after user has typed in them.
                                                        key={forceUpdate + 4}
                                                        id={`edit-${currentTableObj || 'x'}-${field}`}
                                                        name={`${field}`}
                                                        type='text'
                                                        defaultValue={String(val ?? '')} 
                                                        inputStyle='edit-string'
                                                        style={{ 
                                                            padding: '2px 12px',
                                                            fontSize: '16px',
                                                            backgroundColor: 'rgba(222, 238, 255, .5)', 
                                                            color: val === null ? 'rgba(0, 0, 0, .2)' : '', 
                                                        }}
                                                        onChange={() => {
                                                            setChangedFields(prev => [...prev, field])
                                                        }}
                                                        onChangeRedBackground={true}
                                                        unmarkChanged={unmarkChanged}
                                                        setUnmarkChanged={setUnmarkChanged}
                                                        preventAutofill={true}
                                                    />
                                                ) : (_isModifiableArray(field)) ? (
                                                    // SELECT FIELDS
                                                    <SelectField
                                                        // This `key` prevents the "controlled-vs-uncontrolled input" error. 
                                                        // - And also allows fields to refresh even after user has typed in them.
                                                        key={forceUpdate + 5}
                                                        id={`edit-${currentTableObj || 'x'}-${field}`}
                                                        name={`${field}`}
                                                        options={addDropdownValIfNecessary(field, val)}
                                                        altDisplay={true}
                                                        defaultValue={String(addDropdownValIfNecessary(field, val).indexOf(val))}
                                                        firstOptionIsNull={false} // IMPORTANT: NEVER SET THIS TO TRUE IN THE 
                                                                    // ADMIN PAGE, bc index is used to graph real value from 
                                                                    // `MODIFIABLE FIELDS` before POST'ing to backend.
                                                        containerStyle={{
                                                            width: '100%',
                                                        }}
                                                        style={{ 
                                                            height: 'unset',
                                                            padding: '8px 12px',
                                                            backgroundColor: 'rgba(222, 238, 255, .75)', 
                                                            color: val === null ? 'rgba(0, 0, 0, .2)' : 'rgba(0, 4, 12, .75)',
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                        }}
                                                        onChange={() => {
                                                            setChangedFields(prev => [...prev, field])
                                                        }}
                                                        onChangeRedBackground={true}
                                                        unmarkChanged={unmarkChanged}
                                                        setUnmarkChanged={setUnmarkChanged}
                                                    />
                                                ) : (
                                                    null // this should never happen.
                                                )}
                                                </>
                                            ) : (
                                                (!val) ? (
                                                    // EMPTY FIELDS // (this should never happen).
                                                    ''
                                                ) : ((_isLinkable(field, val))  ? (
                                                    // LINKS FIELDS
                                                    <>
                                                    {/* ARRAYS OF LINKS (OR JUST ONE LINK) - to go to a diff data obj within the  the data modal. 
                                                      * Similar to 'expand' below.  If the obj isn't an array, it will be put into one on the next line.
                                                      */}
                                                    <div className='mock-link-wrapper'>
                                                        {(_getArrOrDictAsArr(val)).map(v => (
                                                            <div 
                                                                key={`${index}-${_getLinkTableName(field)}-${v.id}-${currentTableObj}`}
                                                                className='mock-link' 
                                                                onClick={() => goToLinkForData(v.id, '', _getLinkTableName(field), false, changedFields) }
                                                            >
                                                                {_getNameForAnyObj(v)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    </>
                                                ) : (
                                                    // MISC FIELDS (fall back on JSON) - this shouldn't happen in prod.
                                                    <>
                                                        {JSON.stringify(val)}
                                                    </>
                                                ))
                                            )}

                                            {/* Button to "expand" the field and open the data for 
                                                it within the data modal.
                                              */}
                                            {(_isExpandable(field, val)) ? (
                                                <button 
                                                    type='button' 
                                                    className='expand-item-button' 
                                                    onClick={() => goToLinkForData(Number(val), _getExpandableTableName(field), '', false, changedFields) }
                                                    title='Expand'
                                                >
                                                    {(_shouldShowButtonSpinner(field)) ? (
                                                        <img 
                                                            src={LoadingCircle} 
                                                            className='preload-spinner-in-button' 
                                                            style={{opacity: 1, transform: 'translateX(-8px)'}} 
                                                        />
                                                    ) : (
                                                        <Icon name='expand' />
                                                    )}
                                                </button>
                                            ) : null}
                                        </div>
                                        <div className='right'>
                                            <div className='data-details'>
                                                {makeFieldValNotes(field, val)}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ))}
                        </div>

                        {modalErrorMessage ? (
                            <div className='error-message' style={{textAlign: 'center', fontSize: '2.2rem'}}>
                                {modalErrorMessage}
                            </div>
                        ) : null}

                        <div className='modal-bottom-buttons-row'>
                            <div>
                                {prevDbResults.current.length ? (
                                    <BlockButton
                                        type='button'
                                        style={{ margin: '20px 0' }} 
                                        buttonStyle="ghost-mid less-pad "
                                        onClick={() => onBackButtonClick(changedFields)}
                                    >
                                        {/* Below symbol is a big arrow, in the browser */}
                                        {'⇦'}
                                    </BlockButton>
                                ) : null}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', columnGap: '60px'}}>
                                <BlockButton
                                    type='button'
                                    style={{ margin: '20px 0' }} 
                                    buttonStyle="ghost-purple"
                                    onClick={() => closeModalConfirmChanges(changedFields) }
                                >
                                    Cancel
                                </BlockButton>
                                <BlockButton
                                    type='submit'
                                    style={{ margin: '20px 0' }} 
                                    buttonStyle="purple"
                                    disabled={dbResult.lock}
                                >
                                    {showModalSpinner ? (
                                        <>
                                            <span style={{opacity: .5}}>Update</span>
                                            <img src={LoadingCircle} className='preload-spinner-in-button' style={{opacity: 1}} />
                                        </>
                                    ) : (
                                        <>
                                            <span style={{opacity: 1}}>Update</span>
                                            <img src={LoadingCircle} className='preload-spinner-in-button' style={{opacity: 0}} />
                                        </>
                                    )}
                                </BlockButton>
                            </div>
                        </div>
                    </form>
                </RowDetailsModal>
            ): null}



            {/* 
              * ====================================================================================
              * QUERIES/FILTERS FOR DATA TABLE (data-list)
              * ====================================================================================
              */}


            {(!((showDataModal && dbResult && Object.keys(dbResult).length) && SEARCHABLE_TABLES.has(dbResult.table)) && SEARCHABLE_TABLES.has(currentTableList)) ? (
            <>
            <div className='page-title'>
                {capitalizedTableListName}
            </div>
            <div className='section'>
                <div className='title'>
                    Search the {capitalizedTableListName} table
                </div>

                {(currentTableList === 'user') ? (
                    <>
                    <div className='subsection'>
                        <div className='title'>General</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="nm" name="NM" placeholder='User&apos;s name'  value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="fnpi" name="FNPI" placeholder='NPI'  value={filterNpi} onChange={(e) => setFilterNpi(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                            <input type="text" id="rid" name="RID" placeholder='ID'  value={String(filterId || '')} onChange={(e) => setFilterId(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    
                    <div className='subsection'>
                        <div className='title'>Filters</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Status
                            </div>
                            <div className='options'>
                                <input type="radio" id="ut0" name="UT" checked={filterType===''} onChange={() => setFilterType('')} className='default' /><label htmlFor="ut0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ut1" name="UT" checked={filterType==='active'} onChange={() => setFilterType('active')} /><label htmlFor="ut1">active</label>
                                <input type="radio" id="ut2" name="UT" checked={filterType==='!active'} onChange={() => setFilterType('!active')} /><label htmlFor="ut2">inactive</label>
                                <input type="radio" id="ut3" name="UT" checked={filterType==='lock'} onChange={() => setFilterType('lock')} /><label htmlFor="ut3">lock</label>
                                <input type="radio" id="ut4" name="UT" checked={filterType==='!lock'} onChange={() => setFilterType('!lock')} /><label htmlFor="ut4">no lock</label>
                                <input type="radio" id="ut5" name="UT" checked={filterType==='block_user'} onChange={() => setFilterType('block_user')} /><label htmlFor="ut5">blocked</label>
                                <input type="radio" id="ut6" name="UT" checked={filterType==='!block_user'} onChange={() => setFilterType('!block_user')} /><label htmlFor="ut6">not blocked</label>
                            </div>
                            
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Role
                            </div>
                            <div className='options'>
                                <input type="radio" id="ur0" name="UR" checked={filterRole===''} onChange={() => setFilterRole('')} className='default' /><label htmlFor="ur0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ur1" name="UR" checked={filterRole==='is_public'} onChange={() => setFilterRole('is_public')} /><label htmlFor="ur1">public user</label>
                                <input type="radio" id="ur2" name="UR" checked={filterRole==='is_regen'} onChange={() => setFilterRole('is_regen')} /><label htmlFor="ur2">regen user</label>
                                <input type="radio" id="ur3" name="UR" checked={filterRole==='vendor'} onChange={() => setFilterRole('vendor')} /><label htmlFor="ur3">vendor</label>
                                <input type="radio" id="ur4" name="UR" checked={filterRole==='supplier'} onChange={() => setFilterRole('supplier')} /><label htmlFor="ur4">supplier</label>
                                <input type="radio" id="ur5" name="UR" checked={filterRole==='clinic'} onChange={() => setFilterRole('clinic')} /><label htmlFor="ur5">clinic</label>
                                <input type="radio" id="ur6" name="UR" checked={filterRole==='rep'} onChange={() => setFilterRole('rep')} /><label htmlFor="ur6">rep</label>
                                <input type="radio" id="ur7" name="UR" checked={filterRole==='rep_lead'} onChange={() => setFilterRole('rep_lead')} /><label htmlFor="ur7">rep lead</label>
                                <input type="radio" id="ur8" name="UR" checked={filterRole==='general'} onChange={() => setFilterRole('general')} /><label htmlFor="ur8">general</label>
                                <input type="radio" id="ur9" name="UR" checked={filterRole==='conductor'} onChange={() => setFilterRole('conductor')} /><label htmlFor="ur9">conductor</label>
                                <input type="radio" id="ur10" name="UR" checked={filterRole==='manager'} onChange={() => setFilterRole('manager')} /><label htmlFor="ur10">manager</label>
                                <input type="radio" id="ur11" name="UR" checked={filterRole==='admin'} onChange={() => setFilterRole('admin')} /><label htmlFor="ur11">admin</label>
                                <input type="radio" id="ur12" name="UR" checked={filterRole==='sysadmin'} onChange={() => setFilterRole('sysadmin')} /><label htmlFor="ur12">sysadmin</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Sort by</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="radio" id="uo0" name="UO" checked={sortField===''} onChange={() => setSortField('')} className='default' /><label htmlFor="uo0" title='no sort'>{'\uFE61'}</label>
                                <input type="radio" id="uo1" name="UO" checked={sortField==='created_at'} onChange={() => setSortField('created_at')} /><label htmlFor="uo1" title='oldest first'>&darr; created</label>
                                <input type="radio" id="uo2" name="UO" checked={sortField==='-created_at'} onChange={() => setSortField('-created_at')} /><label htmlFor="uo2" title='newest first'>&uarr; created</label>
                                <input type="radio" id="uo3" name="UO" checked={sortField==='updated_at'} onChange={() => setSortField('updated_at')} /><label htmlFor="uo3" title='oldest first'>&darr; updated</label>
                                <input type="radio" id="uo4" name="UO" checked={sortField==='-updated_at'} onChange={() => setSortField('-updated_at')} /><label htmlFor="uo4" title='newest first'>&darr; updated</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Rows</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}} placeholder={String(DEFAULT_ROW_COUNT)}>
                                # of results
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short default-10' id="rt1" name="RT1" title='# of rows to return' value={rowCount ? String(rowCount) : ''} onChange={(e) => setRowCount(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}}>
                                Start after row #
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short' id="rt2" name="RT2" placeholder={'0'} title='Result # to start at' value={rowSkip ? String(rowSkip) : ''} onChange={(e) => setRowSkip(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    </>
                ) : ((currentTableList === 'rep_org') ? (
                    <>
                    <div className='subsection'>
                        <div className='title'>General</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="nm" name="NM" placeholder='Rep Organization name' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                            <input type="text" id="rid" name="RID" placeholder='ID' value={String(filterId || '')} onChange={(e) => setFilterId(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Filters</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Status
                            </div>
                            <div className='options'>
                                <input type="radio" id="ut0" name="UT" checked={filterType===''} onChange={() => setFilterType('')} className='default' /><label htmlFor="ut0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ut1" name="UT" checked={filterType==='active'} onChange={() => setFilterType('active')} /><label htmlFor="ut1">active</label>
                                <input type="radio" id="ut2" name="UT" checked={filterType==='!active'} onChange={() => setFilterType('!active')} /><label htmlFor="ut2">inactive</label>
                                <input type="radio" id="ut3" name="UT" checked={filterType==='reg_complete'} onChange={() => setFilterType('reg_complete')} /><label htmlFor="ut3">reg complete</label>
                                <input type="radio" id="ut4" name="UT" checked={filterType==='!reg_complete'} onChange={() => setFilterType('!reg_complete')} /><label htmlFor="ut4">reg incomplete</label>
                                <input type="radio" id="ut5" name="UT" checked={filterType==='lock'} onChange={() => setFilterType('lock')} /><label htmlFor="ut5">lock</label>
                                <input type="radio" id="ut6" name="UT" checked={filterType==='!lock'} onChange={() => setFilterType('!lock')} /><label htmlFor="ut6">no lock</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Sort by</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="radio" id="uo0" name="UO" checked={sortField===''} onChange={() => setSortField('')} className='default' /><label htmlFor="uo0" title='no sort'>{'\uFE61'}</label>
                                <input type="radio" id="uo1" name="UO" checked={sortField==='name'} onChange={() => setSortField('name')} /><label htmlFor="uo1">&uarr; name</label>
                                <input type="radio" id="uo2" name="UO" checked={sortField==='-name'} onChange={() => setSortField('-name')} /><label htmlFor="uo2">&darr; name</label>
                                <input type="radio" id="uo3" name="UO" checked={sortField==='created_at'} onChange={() => setSortField('created_at')} /><label htmlFor="uo3" title='oldest first'>&darr; created</label>
                                <input type="radio" id="uo4" name="UO" checked={sortField==='-created_at'} onChange={() => setSortField('-created_at')} /><label htmlFor="uo4" title='newest first'>&uarr; created</label>
                                <input type="radio" id="uo5" name="UO" checked={sortField==='updated_at'} onChange={() => setSortField('updated_at')} /><label htmlFor="uo5" title='oldest first'>&darr; updated</label>
                                <input type="radio" id="uo6" name="UO" checked={sortField==='-updated_at'} onChange={() => setSortField('-updated_at')} /><label htmlFor="uo6" title='newest first'>&darr; updated</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Rows</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}} placeholder={String(DEFAULT_ROW_COUNT)}>
                                # of results
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short default-10' id="rt1" name="RT1" title='# of rows to return' value={rowCount ? String(rowCount) : ''} onChange={(e) => setRowCount(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}}>
                                Start after row #
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short' id="rt2" name="RT2" placeholder={'0'} title='Result # to start at' value={rowSkip ? String(rowSkip) : ''} onChange={(e) => setRowSkip(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    </>
                ) : ((currentTableList === 'business') ? (
                    <>
                    <div className='subsection'>
                        <div className='title'>General</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="nm" name="NM" placeholder='Business name' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                            <input type="text" id="rid" name="RID" placeholder='ID' value={String(filterId || '')} onChange={(e) => setFilterId(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Filters</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Status
                            </div>
                            <div className='options'>
                                <input type="radio" id="ut0" name="UT" checked={filterType===''} onChange={() => setFilterType('')} className='default' /><label htmlFor="ut0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ut1" name="UT" checked={filterType==='active'} onChange={() => setFilterType('active')} /><label htmlFor="ut1">active</label>
                                <input type="radio" id="ut2" name="UT" checked={filterType==='!active'} onChange={() => setFilterType('!active')} /><label htmlFor="ut2">inactive</label>
                                <input type="radio" id="ut3" name="UT" checked={filterType==='reg_complete'} onChange={() => setFilterType('reg_complete')} /><label htmlFor="ut3">reg complete</label>
                                <input type="radio" id="ut4" name="UT" checked={filterType==='!reg_complete'} onChange={() => setFilterType('!reg_complete')} /><label htmlFor="ut4">reg incomplete</label>
                                <input type="radio" id="ut5" name="UT" checked={filterType==='lock'} onChange={() => setFilterType('lock')} /><label htmlFor="ut5">lock</label>
                                <input type="radio" id="ut6" name="UT" checked={filterType==='!lock'} onChange={() => setFilterType('!lock')} /><label htmlFor="ut6">no lock</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Sort by</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="radio" id="uo0" name="UO" checked={sortField===''} onChange={() => setSortField('')} className='default' /><label htmlFor="uo0" title='no sort'>{'\uFE61'}</label>
                                <input type="radio" id="uo1" name="UO" checked={sortField==='name'} onChange={() => setSortField('name')} /><label htmlFor="uo1">&uarr; name</label>
                                <input type="radio" id="uo2" name="UO" checked={sortField==='-name'} onChange={() => setSortField('-name')} /><label htmlFor="uo2">&darr; name</label>
                                <input type="radio" id="uo3" name="UO" checked={sortField==='created_at'} onChange={() => setSortField('created_at')} /><label htmlFor="uo3" title='oldest first'>&darr; created</label>
                                <input type="radio" id="uo4" name="UO" checked={sortField==='-created_at'} onChange={() => setSortField('-created_at')} /><label htmlFor="uo4" title='newest first'>&uarr; created</label>
                                <input type="radio" id="uo5" name="UO" checked={sortField==='updated_at'} onChange={() => setSortField('updated_at')} /><label htmlFor="uo5" title='oldest first'>&darr; updated</label>
                                <input type="radio" id="uo6" name="UO" checked={sortField==='-updated_at'} onChange={() => setSortField('-updated_at')} /><label htmlFor="uo6" title='newest first'>&darr; updated</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Rows</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}} placeholder={String(DEFAULT_ROW_COUNT)}>
                                # of results
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short default-10' id="rt1" name="RT1" title='# of rows to return' value={rowCount ? String(rowCount) : ''} onChange={(e) => setRowCount(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}}>
                                Start after row #
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short' id="rt2" name="RT2" placeholder={'0'} title='Result # to start at' value={rowSkip ? String(rowSkip) : ''} onChange={(e) => setRowSkip(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    </>
                ) : ((currentTableList === 'clinic') ? (
                    <>
                    <div className='subsection'>
                        <div className='title'>General</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="nm" name="NM" placeholder='Clinic name' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="fnpi" name="FNPI" placeholder='NPI' value={filterNpi} onChange={(e) => setFilterNpi(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                            <input type="text" id="rid" name="RID" placeholder='ID' value={String(filterId || '')} onChange={(e) => setFilterId(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Filters</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Status
                            </div>
                            <div className='options'>
                                <input type="radio" id="ut0" name="UT" checked={filterType===''} onChange={() => setFilterType('')} className='default' /><label htmlFor="ut0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ut1" name="UT" checked={filterType==='active'} onChange={() => setFilterType('active')} /><label htmlFor="ut1">active</label>
                                <input type="radio" id="ut2" name="UT" checked={filterType==='!active'} onChange={() => setFilterType('!active')} /><label htmlFor="ut2">inactive</label>
                                <input type="radio" id="ut3" name="UT" checked={filterType==='reg_complete'} onChange={() => setFilterType('reg_complete')} /><label htmlFor="ut3">reg complete</label>
                                <input type="radio" id="ut4" name="UT" checked={filterType==='!reg_complete'} onChange={() => setFilterType('!reg_complete')} /><label htmlFor="ut4">reg incomplete</label>
                                <input type="radio" id="ut5" name="UT" checked={filterType==='lock'} onChange={() => setFilterType('lock')} /><label htmlFor="ut5">lock</label>
                                <input type="radio" id="ut6" name="UT" checked={filterType==='!lock'} onChange={() => setFilterType('!lock')} /><label htmlFor="ut6">no lock</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Sort by</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="radio" id="uo0" name="UO" checked={sortField===''} onChange={() => setSortField('')} className='default' /><label htmlFor="uo0" title='no sort'>{'\uFE61'}</label>
                                <input type="radio" id="uo1" name="UO" checked={sortField==='name'} onChange={() => setSortField('name')} /><label htmlFor="uo1">&uarr; name</label>
                                <input type="radio" id="uo2" name="UO" checked={sortField==='-name'} onChange={() => setSortField('-name')} /><label htmlFor="uo2">&darr; name</label>
                                <input type="radio" id="uo3" name="UO" checked={sortField==='created_at'} onChange={() => setSortField('created_at')} /><label htmlFor="uo3" title='oldest first'>&darr; created</label>
                                <input type="radio" id="uo4" name="UO" checked={sortField==='-created_at'} onChange={() => setSortField('-created_at')} /><label htmlFor="uo4" title='newest first'>&uarr; created</label>
                                <input type="radio" id="uo5" name="UO" checked={sortField==='updated_at'} onChange={() => setSortField('updated_at')} /><label htmlFor="uo5" title='oldest first'>&darr; updated</label>
                                <input type="radio" id="uo6" name="UO" checked={sortField==='-updated_at'} onChange={() => setSortField('-updated_at')} /><label htmlFor="uo6" title='newest first'>&darr; updated</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Rows</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}} placeholder={String(DEFAULT_ROW_COUNT)}>
                                # of results
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short default-10' id="rt1" name="RT1" title='# of rows to return' value={rowCount ? String(rowCount) : ''} onChange={(e) => setRowCount(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}}>
                                Start after row #
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short' id="rt2" name="RT2" placeholder={'0'} title='Result # to start at' value={rowSkip ? String(rowSkip) : ''} onChange={(e) => setRowSkip(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    </>
                ) : ((currentTableList === 'product') ? (
                    <>
                    <div className='subsection'>
                        <div className='title'>General</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" id="nm" name="NM" placeholder='Product name' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                            <input type="text" id="rid" name="RID" placeholder='ID' value={String(filterId || '')} onChange={(e) => setFilterId(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Filters</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '6px'}}>
                                Status
                            </div>
                            <div className='options'>
                                <input type="radio" id="ut0" name="UT" checked={filterType===''} onChange={() => setFilterType('')} className='default' /><label htmlFor="ut0" title='no filter'>{'\uFE61'}</label>
                                <input type="radio" id="ut1" name="UT" checked={filterType==='active'} onChange={() => setFilterType('active')} /><label htmlFor="ut1">active</label>
                                <input type="radio" id="ut2" name="UT" checked={filterType==='!active'} onChange={() => setFilterType('!active')} /><label htmlFor="ut2">inactive</label>
                                <input type="radio" id="ut3" name="UT" checked={filterType==='lock'} onChange={() => setFilterType('lock')} /><label htmlFor="ut3">lock</label>
                                <input type="radio" id="ut4" name="UT" checked={filterType==='!lock'} onChange={() => setFilterType('!lock')} /><label htmlFor="ut4">no lock</label> {/* Yes, this need to be "inlock" for backend */}
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Sort by</div>
                        <div className='radio-row'>
                            <div className='title short'></div> {/* for alignment */}
                            <div className='options'>
                                <input type="radio" id="uo0" name="UO" checked={sortField===''} onChange={() => setSortField('')} className='default' /><label htmlFor="uo0" title='no sort'>{'\uFE61'}</label>
                                <input type="radio" id="uo1" name="UO" checked={sortField==='name'} onChange={() => setSortField('name')} /><label htmlFor="uo1">&uarr; name</label>
                                <input type="radio" id="uo2" name="UO" checked={sortField==='-name'} onChange={() => setSortField('-name')} /><label htmlFor="uo2">&darr; name</label>
                                <input type="radio" id="uo3" name="UO" checked={sortField==='created_at'} onChange={() => setSortField('created_at')} /><label htmlFor="uo3" title='oldest first'>&darr; created</label>
                                <input type="radio" id="uo4" name="UO" checked={sortField==='-created_at'} onChange={() => setSortField('-created_at')} /><label htmlFor="uo4" title='newest first'>&uarr; created</label>
                                <input type="radio" id="uo5" name="UO" checked={sortField==='updated_at'} onChange={() => setSortField('updated_at')} /><label htmlFor="uo5" title='oldest first'>&darr; updated</label>
                                <input type="radio" id="uo6" name="UO" checked={sortField==='-updated_at'} onChange={() => setSortField('-updated_at')} /><label htmlFor="uo6" title='newest first'>&darr; updated</label>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <div className='title'>Rows</div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}} placeholder={String(DEFAULT_ROW_COUNT)}>
                                # of results
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short default-10' id="rt1" name="RT1" title='# of rows to return' value={rowCount ? String(rowCount) : ''} onChange={(e) => setRowCount(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                        <div className='radio-row'>
                            <div className='title short' style={{paddingTop: '12px'}}>
                                Start after row #
                            </div> {/* for alignment */}
                            <div className='options'>
                                <input type="text" className='short' id="rt2" name="RT2" placeholder={'0'} title='Result # to start at' value={rowSkip ? String(rowSkip) : ''} onChange={(e) => setRowSkip(Number(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                    </>
                ) : (
                    // This should never happen
                    // <div className='subsection'>
                    //     Invalid table name
                    // </div>
                    null
                )))))}

                <BlockButton
                    type='button'
                    style={{ margin: '20px 0' }} 
                    buttonStyle="purple"
                    onClick={() => getDataList(currentTableList, filterId, filterName, filterType, filterRole, filterNpi, sortField, rowCount, rowSkip)}
                >
                    {showSpinner ? (
                        <>
                            <span style={{opacity: .5}}>Run</span>
                            <img src={LoadingCircle} className='preload-spinner-in-button' style={{opacity: 1}} />
                        </>
                    ) : (
                        <>
                            <span style={{opacity: 1}}>Run</span>
                            <img src={LoadingCircle} className='preload-spinner-in-button' style={{opacity: 0}} />
                        </>
                    )}
                </BlockButton>

                {/* Will scroll to here when there is an error message */}
                <div 
                    ref={errorMessageBox}
                    className={`error-message ${errorMessage ? '' : 'collapse'}`}
                >
                    {errorMessage}
                </div>

                {dbResultsTime ? (
                    <div className='data-fetched-at-note bottom'>
                        Data fetched at: {dbResultsTime}
                    </div>
                ) : null}
            </div>
            </>
            ) : (
                null
            )}




            
            {/* Will scroll to here when there is successful table-list result */}
            <div ref={beforeTablResultsBox}></div>





            {(showModalInitialLoadSpinner) ? (
                <>
                {/* BIG LOADING SPINNER - add the class logic for transition */}
                <div className={`big-spinner-overlay ${showModalInitialLoadSpinner ? 'show' : ''}`}>
                    <img src={LoadingCircle} className='preload-spinner-big' style={{opacity: 1}} />
                </div>
                </>
            ) : null}

            

            {/* 
              * ====================================================================================
              * MAIN TABLE DATA ROWS (data-list)
              * ====================================================================================
              */}

            {(dbResults?.length) ? (
                <div className='section table-section'>
                    <div className='title' >
                        Results
                    </div>
                    <div className='subtitle' >
                        {resultsSummary}
                    </div>

                    {((currentTableList === 'user') ? (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            <div className='table-row title scrollable-cells nowrap-cells'>
                                <div className='table-cell b'>id</div>
                                <div className='table-cell b'>active</div>
                                <div className='table-cell c'>type</div>
                                <div className='table-cell c'>name</div>
                                <div className='table-cell d'>email</div>
                                <div className='table-cell c'>npi</div>
                            </div>
                            {dbResults?.map(row => (
                                <div 
                                    key={row.id} 
                                    className='table-row scrollable-cells nowrap-cells'
                                    onClick={() => {
                                        setShowModalInitialLoadSpinner(true)
                                        goToLinkForData(row.id, '', currentTableList) 
                                    }}
                                >
                                    <div className='table-cell b'>{row.id}</div>
                                    <div className='table-cell b'>{String(row.active)}</div>
                                    <div className='table-cell c'>{row.entity_type.name}</div>
                                    <div className='table-cell c'>{[row.first_name, row.last_name].join(' ').replace(/\s+/g, ' ').trim()}</div>
                                    <div className='table-cell d'>{row.email.email}</div>
                                    <div className='table-cell c'>{row.npi}</div>
                                </div>
                            ))}
                        </div>
                    ) : ((currentTableList === 'rep_org') ? (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            <div className='table-row title scrollable-cells nowrap-cells'>
                                <div className='table-cell b'>id</div>
                                <div className='table-cell b'>active</div>
                                <div className='table-cell b'>lock</div>
                                <div className='table-cell c'>reg complete</div>
                                <div className='table-cell c'>type</div>
                                <div className='table-cell d'>name</div>
                                <div className='table-cell d'>created</div>
                                <div className='table-cell d'>updated</div>
                            </div>
                            {dbResults?.map(row => (
                                <div 
                                    key={row.id} 
                                    className='table-row scrollable-cells nowrap-cells'
                                    onClick={() => {
                                        setShowModalInitialLoadSpinner(true)
                                        goToLinkForData(row.id, '', currentTableList)
                                    }}
                                >
                                    <div className='table-cell b'>{row.id}</div>
                                    <div className='table-cell b'>{String(row.active)}</div>
                                    <div className='table-cell b'>{String(row.lock)}</div>
                                    <div className='table-cell c'>{String(row.reg_complete)}</div>
                                    <div className='table-cell c'>{row.type.name}</div> 
                                    <div className='table-cell d'>{row.name_display || row.name}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.created_at)} ${getLocalTimeZone()}`}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.updated_at)} ${getLocalTimeZone()}`}</div>
                                </div>
                            ))}
                        </div>
                    ) : ((currentTableList === 'business') ? (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            <div className='table-row title scrollable-cells nowrap-cells'>
                                <div className='table-cell b'>id</div>
                                <div className='table-cell b'>active</div>
                                <div className='table-cell b'>lock</div>
                                <div className='table-cell c'>reg complete</div>
                                <div className='table-cell c'>type</div>
                                <div className='table-cell d'>name</div>
                                <div className='table-cell d'>created</div>
                                <div className='table-cell d'>updated</div>
                            </div>
                            {dbResults?.map(row => (
                                <div 
                                    key={row.id} 
                                    className='table-row scrollable-cells nowrap-cells'
                                    onClick={() => {
                                        setShowModalInitialLoadSpinner(true)
                                        goToLinkForData(row.id, '', currentTableList)
                                    }}
                                >
                                    <div className='table-cell b'>{row.id}</div>
                                    <div className='table-cell b'>{String(row.active)}</div>
                                    <div className='table-cell b'>{String(row.lock)}</div>
                                    <div className='table-cell c'>{String(row.reg_complete)}</div>
                                    <div className='table-cell c'>{row.type.name}</div> 
                                    <div className='table-cell d'>{row.name_display || row.name}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.created_at)} ${getLocalTimeZone()}`}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.updated_at)} ${getLocalTimeZone()}`}</div>
                                </div>
                            ))}
                        </div>
                    ) : ((currentTableList === 'clinic') ? (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            <div className='table-row title scrollable-cells nowrap-cells'>
                                <div className='table-cell b'>id</div>
                                <div className='table-cell b'>active</div>
                                <div className='table-cell b'>lock</div>
                                <div className='table-cell c'>reg complete</div>
                                <div className='table-cell c'>type</div>
                                <div className='table-cell d'>name</div>
                                <div className='table-cell c'>npi</div>
                                <div className='table-cell d'>created</div>
                                <div className='table-cell d'>updated</div>
                            </div>
                            {dbResults?.map(row => (
                                <div 
                                    key={row.id} 
                                    className='table-row scrollable-cells nowrap-cells'
                                    onClick={() => {
                                        setShowModalInitialLoadSpinner(true)
                                        goToLinkForData(row.id, '', currentTableList)
                                    }}
                                >
                                    <div className='table-cell b'>{row.id}</div>
                                    <div className='table-cell b'>{String(row.active)}</div>
                                    <div className='table-cell b'>{String(row.lock)}</div>
                                    <div className='table-cell c'>{String(row.reg_complete)}</div>
                                    <div className='table-cell c'>{row.type.name}</div> 
                                    <div className='table-cell d'>{row.name_display || row.name}</div>
                                    <div className='table-cell c'>{row.npi}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.created_at)} ${getLocalTimeZone()}`}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.updated_at)} ${getLocalTimeZone()}`}</div>
                                </div>
                            ))}
                        </div>
                    ) : ((currentTableList === 'product') ? (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            <div className='table-row title scrollable-cells nowrap-cells'>
                                <div className='table-cell b'>id</div>
                                <div className='table-cell b'>active</div>
                                <div className='table-cell b'>lock</div>
                                <div className='table-cell d'>name</div>
                                <div className='table-cell d'>brand</div>
                                <div className='table-cell c'>product_id</div>
                                <div className='table-cell c'>business_id</div>
                                <div className='table-cell d'>created</div>
                                <div className='table-cell d'>updated</div>
                            </div>
                            {dbResults?.map(row => (
                                <div 
                                    key={row.id} 
                                    className='table-row scrollable-cells nowrap-cells'
                                    onClick={() => {
                                        setShowModalInitialLoadSpinner(true)
                                        goToLinkForData(row.id, '', currentTableList)
                                    }}
                                >
                                    <div className='table-cell b'>{row.id}</div>
                                    <div className='table-cell b'>{String(row.active)}</div>
                                    <div className='table-cell b'>{String(row.lock)}</div>
                                    <div className='table-cell d'>{row.name_display || row.name}</div>
                                    <div className='table-cell d'>{row.brand}</div>
                                    <div className='table-cell c'>{row.product_id}</div>
                                    <div className='table-cell c'>{row.business_id}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.created_at)} ${getLocalTimeZone()}`}</div>
                                    <div className='table-cell d'>{`${getLocalTimeString_alt(row.updated_at)} ${getLocalTimeZone()}`}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='table-container' style={{marginTop: '20px'}}>
                            This table hasn't been set up to query.
                        </div>
                    ))))))}
                </div>
            ) : null}

            <ToastContainer 
                hideProgressBar={true}
                closeOnClick={true}
                position='bottom-center'
                style={{textAlign: 'center', cursor: 'default', width: '500px', maxWidth: '80vw', whiteSpace: 'pre-wrap'}}
                transition={Flip}
            />
        </div>
        </>
    )
}