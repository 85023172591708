import { BlockButton } from '../buttons/block-button/BlockButton';
import DoctorProfileImage from './doctor-profile-card.png';
import "./doctor-profile-banner.scss"


interface Props { };

export const DoctorProfileBanner = ({ }: Props) => {
  return (
    <div className="image-container">
      <div className="banner-span">
        <div className="card">
          <div className="card-content">
            <img src={DoctorProfileImage} className="profile-image" />
            <div className="card-list">
              <div className="header">DR. VICTORIA FRANKEN</div>
              <div className="career">Orthopetic Surgeon</div>
              <div className="stars">
                <div className="star1" />
                <div className="star1" />
                <div className="star1" />
                <div className="star1" />
                <div className="star1" />
              </div>
              <div className="button-group">
                <BlockButton
                  style={{ width: '120px' }}
                  buttonStyle="desktop-header-buttons ghost-purple"
                >
                  WEBSITE
                </BlockButton>
                <BlockButton
                  style={{ width: '120px' }}
                  buttonStyle="desktop-header-buttons ghost-purple"
                >
                  EMAIL
                </BlockButton>
              </div>
              <div className="description">
                text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>


  )
}