import { useEffect, useRef } from 'react'

import { useNavigate } from "react-router-dom"

import { Icon } from "../icon/Icon";

import LoadingCircle from '../../../static/gif/loading-circle.gif'

import './row-details-modal.scss';



type Props = {
    show: boolean;
    closeModal: Function;
    overTitle?: string;
    title?: string;
    topLeftButton?: JSX.Element|null;
    // "subtitle" section
    isLockedAlert?: string;
    isLockedAlertStyle?: React.CSSProperties;
    lastRefreshedTime?: string;
    // State variable from parent to trigger reloading this object's data.
    setTriggerReloadDataObj?: React.Dispatch<React.SetStateAction<boolean>>;
    // Text to copy to clipboard when icon is clicked.
    urlToClipBaord?: string;
    // State to put spinner in place of refresh icon.
    showRefreshSpinner?: boolean;
    // State var to trigger loading of change history for the data obj being displayed.
    // - (change history only shows for what admins changed, not changes from user or db... maybe)
    setShowEditHistory?: React.Dispatch<React.SetStateAction<boolean>>;
    showChangeLogSpinner?: boolean;
    // The function to pop toaster message, because having a Toaster component both in here
    // and in the parent will cause them to popup twice.
    toastFunction?: any;
    // Ref to attach to visible part of modal component.
    modalRef?: React.RefObject<HTMLDivElement>;
    children?: JSX.Element;
    
}




export const RowDetailsModal = ({ 
    show, closeModal,
    overTitle, title, topLeftButton,
    isLockedAlert, isLockedAlertStyle = {}, lastRefreshedTime, urlToClipBaord, 
    setTriggerReloadDataObj, showRefreshSpinner,
    setShowEditHistory, showChangeLogSpinner,
    toastFunction,
    modalRef,
    children,
}: Props) => {
    const showHideClassName = show ? "display-block" : "display-none";

    // Timer to distinguish between clicks & double clicks.
    const clickTimeout = useRef<any>(null)

    // Change browser url without navigating to a different page.
    const navigate = useNavigate(); // Same as useHistory in newer versions of this package.

    // If `urlToClipBaord` provided, copy the value to clipboard on click.
    const onClickSubtitleLink = (linkText: string) => {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = setTimeout(() => {
            navigator.clipboard.writeText(linkText)
                .then(() => {
                    if (toastFunction) {
                        toastFunction.success(`Link copied to clipboard:\r\n${linkText}`, { autoClose: 1000 })
                    }
                }).catch((error) => {
                    if (toastFunction) {
                        toastFunction.error(`Failed to copy link to clipboard:\r\n(url: ${linkText})`, { autoClose: 5000 })
                    }
                })
        }, 300)
    }

    // const onDoubleClickSubtitleLink = async (linkText: string) => {
    //     clearTimeout(clickTimeout.current) // So onClick event handler doesn't execute.
    //     const relativeLink = linkText.replace(window.location.origin, '')
    //     const shouldShowToast = window.location.pathname !== relativeLink
    //     navigate(relativeLink)
    //     if (shouldShowToast) {
    //         setTimeout(() => {
    //             toastFunction.success(`Updated url:\r\n${linkText}`, { autoClose: 1000 })
    //         }, 200)
    //     }
    // }

    const onClickRefreshLink = (linkText: string) => {
        if (setTriggerReloadDataObj) { // prefer doing it this way instead of `.reload()`/`navigate()`.
            setTriggerReloadDataObj(true)
        } else if (linkText === window.location.href) {
            window.location.reload()
        } else {
            const relativeLink = linkText.replace(window.location.origin, '')
            navigate(relativeLink)
        }
    }

    return (
        <div className={`row-details-modal generic-modal noprint base-modal ${showHideClassName}`} >

            {/* Background overlay */}
            <div onClick={() => {
                if (closeModal) {
                    closeModal();
                }
                }} className='bg-shade'
            />

            <div className="modal-main" ref={modalRef}>
                {(overTitle) ? (
                    <div className='over-title'>
                        {overTitle}
                    </div>
                ) : null}
                {(title) ? (
                    <div className='title'>
                        {title}
                    </div>
                ) : null}

                {(topLeftButton) ? (
                    <div className='top-left-text'>
                        {topLeftButton}
                    </div>
                ) : null}
                
                {(isLockedAlert) ? (
                    <div className='subtitle' style={isLockedAlertStyle}>
                        {isLockedAlert}
                    </div>
                ) : null}

                {(urlToClipBaord || !!setShowEditHistory) ? (
                    <div className='subtitle-links'>
                        {setShowEditHistory ? (
                            <button 
                                className='button-link'
                                onClick={() => setShowEditHistory(true)}
                                title='Show edit history'
                            >
                                {showChangeLogSpinner ? (
                                    <img 
                                        src={LoadingCircle} 
                                        className='preload-spinner-in-button' 
                                        style={{opacity: 1, transform: 'translate(-16px, -2px)'}} 
                                    />
                                ) : (
                                    <Icon name='history' />
                                )}
                            </button>
                        ) : null}
                        {urlToClipBaord ? (
                            <>
                            <button 
                                className='button-link'
                                onClick={() => onClickSubtitleLink(urlToClipBaord)}
                                // onDoubleClick={() => onDoubleClickSubtitleLink(urlToClipBaord)}
                                title='Copy link to clipboard'
                            >
                                <Icon name='link' />
                            </button>
                            <button 
                                className='button-link'
                                onClick={() => onClickRefreshLink(urlToClipBaord)} // Yes, same url.
                                title='Refresh data'
                            >
                                {showRefreshSpinner ? (
                                    <img 
                                        src={LoadingCircle} 
                                        className='preload-spinner-in-button' 
                                        style={{opacity: 1, transform: 'translate(-16px, -2px)'}} 
                                    />
                                ) : (
                                    <Icon name='refresh' />
                                )}
                            </button>
                            </>
                        ) : null}
                    </div>
                ) : null}

                {(lastRefreshedTime) ? (
                    <div 
                        className='subtitle2' 
                        title='Time that this data was loaded'
                    >
                        {(!showRefreshSpinner) ? (
                            `${lastRefreshedTime}`
                        ) : (
                            'refreshing'
                        )}
                    </div>
                ) : null}
                
                {/* Close button */}
                <div className="close-button"
                    onClick={() => closeModal()}
                >
                    &#10006;
                </div>

                {/* Children */}
                {children}
            </div>
        </div>
    )
}