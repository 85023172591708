/**
 * Pass in a query string then return a dict.
 * 
 * Args: Leading '?' optional.
 * Return: Dict of qs keys & vals. If no qs then return `{}`.
 */
export const qsToDict = (_qs: string) => {
    const qs = _qs[0] === '?' ? _qs.slice(1,) : _qs
    const pairs = qs.split('&')
    const d: {[key: string]: string} = {}
    for (let pair of pairs) {
        const [key, val] = pair.split('=')
        d[key] = decodeURIComponent(val)
    }
    return d
}

/**
 * Pass in a dict to get a query string.
 * If dict is empty return "".
 * Ignores dict keys that have empty values.
 */
export const dictToQs = (d: {[key: string]: string}) => {
    let qs = '?'
    for (let [key, val] of Object.entries(d)) {
        if (val) {
            qs += key + '=' + encodeURIComponent(val) + '&'
        }
    }
    return qs.slice(0, -1)
}

/**
 * Get value for a specific key in query string.
 * If not found return "".
 */
export const getQsVal = (key: string, qs: string) => {
    const d = qsToDict(qs)
    return d[key] ? decodeURIComponent(d[key]) : ''
}

/**
 * Build relative url path with base path and query dict.
 */
export const buildPath = (_basePath: string, _qs?: {[key: string]: string}) => {
    let qs = _qs ? dictToQs(_qs) : ''
    let basePath = _basePath
    if (basePath.includes('?')) {
        qs = '&' + qs.slice(1,)
        basePath = ('/' + _basePath + qs).replace(/\/+/g, '/')
    } else {
        basePath = ('/' + _basePath + '/').replace(/\/+/g, '/')
    }
    return basePath + qs
}