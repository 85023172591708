import { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { NavDropdown } from './nav-account-dropdown/NavDropdown'
import { supplierRegistrationPaths, vendorRegistrationPaths, 
         doctorRegistrationPaths, consumerRegistrationPaths } from '../../../constants/paths'
import { publicFacingPaths } from '../../../constants/paths'

import './navbar.scss'


//   TODO  -  set navbar opacity = universal, instead of vendor registration only


interface Props {
    navType: 'default';
}

export const Navbar = ({ navType }: Props) => {
    // TODO: don't hardcode either of these values. 
    // TODO: Find a way to link to scss variables, and urls to their paths.
    const NAV_OPACITY_SCROLL_POS = 260 - 60; // ($banner-image-height - $navbar-height)
    // Which nav buttons to show based on `navType` arg.
    const NAV_LINKS = {
        'default': ['home', 'products', 'aboutus', 'contact'],
    }
    // If there is match for paths that need to use a translucent nav on scroll.
    const pathMatch = useRef(true)

    /**
     * Dropdown components to show for each nav menu item.
     * - Components at the left of screen should "openLeft" & vice versa for right side of screen.
     */
    
    const NAV_DROPDOWN_COMPONENTS: { [key: string]: JSX.Element } = {
        'home': (
            <NavDropdown
                key='HOME'
                navName="HOME"
                navLinkSingle='HOME'
            />
        ),
        'products': (
            <NavDropdown
                key='PRODUCTS'
                navName="PRODUCTS"
                navLinkSingle='PRODUCTS'
                navClickableParent={false}
                // TOOD: make these into dictionary
                navLinks={['AmnioText', 'CryoText Plus', 'CryoText Pro', 'ProText', 'SecreText', 'SecreText Pro']}
                navLinksPaths={['/public/products/1', '/public/products/2', '/public/products/3',
                    '/public/products/4', '/public/products/5', '/public/products/6']}
                openLeft={true}
            />
        ),
        'aboutus': (
            <NavDropdown
                key='ABOUT'
                navName="ABOUT US"
                navLinkSingle='ABOUT'
            />
        ),
        'contact': (
            <NavDropdown
                key='CONTACT'
                navName="CONTACT"
                navLinkSingle='CONTACT'
                navLinks={['1-844-422-8607', 'info@regenativelabs.com']}
                navLinksPaths={['/public/contact', '/public/contact']}
                openLeft={false}
            />
        ),
    }

    /**
     * Control navbar opacity based on url and scroll position.
     */

    // Variables to control navbar opacity.
    const validPathsForTranslucentNav = publicFacingPaths.concat(
                                                vendorRegistrationPaths, supplierRegistrationPaths, 
                                                doctorRegistrationPaths, consumerRegistrationPaths)
    const location = useLocation()
    const [navTranslucent, setNavTranslucent] = useState(false)

    // Any time the url path changes, check if we should use a (dynamically) translucent navbar.
    useEffect(() => {
        if (location.pathname) {
            pathMatch.current = validPathsForTranslucentNav.indexOf(location.pathname) > -1
            setNavTranslucent(pathMatch.current)
        }
    }, [location, location.pathname])

    // Function that is called whenever user scrolls the page.
    const handleScroll = () => {
        const useTranslucentNav = pathMatch.current && window.pageYOffset < NAV_OPACITY_SCROLL_POS
        setNavTranslucent(useTranslucentNav)
    }

    // Initialize scroll event handler on mount.
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [])



    return (
        <div className='navbar-wrapper' >
            <div className={`nav-background ${(!navTranslucent && 'opaque')}`}></div>
            <div className="body-container-no-pad navbar-buttons-container">
                {NAV_LINKS[navType].map((type: string) => (
                    NAV_DROPDOWN_COMPONENTS[type]
                ))}
            </div>
        </div>
    )
}