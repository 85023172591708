import { BlockButton } from "../buttons/block-button/BlockButton";

import './info-text-modal.scss';



type Props = {
    show: boolean;
    closeModal: Function;
    text: string;
    children?: JSX.Element;
}




export const InfoTextModal = ({ 
    show, closeModal,
    text,
    children,
}: Props) => {
    const showHideClassName = show ? "display-block" : "display-none";

    return (
        <div className={`info-text-modal generic-modal noprint base-modal ${showHideClassName}`} >

            {/* Background overlay */}
            <div onClick={() => {
                if (closeModal) {
                    closeModal();
                }
                }} className='bg-shade'
            />

            <div className="modal-main">
                <div className='body-text'>
                    {text}
                </div>
                
                {/* Close button */}
                <div className="close-button"
                    onClick={() => closeModal()}
                >
                    &#10006;
                </div>

                {/* Children */}
                {children}

                <BlockButton
                    type='button'
                    style={{ display: 'block', margin: '40px 0 0 50%', transform: 'translateX(-50%)' }} 
                    buttonStyle="ghost-dark no-hover"
                    onClick={() => closeModal()}
                >
                    OK
                </BlockButton>
            </div>
        </div>
    )
}