import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Icon } from '../../shared/icon/Icon'

import textOnlyLogo from '../../../static/img/medngine-footer-logo.png'

import './desktop-footer.scss'





interface Props { };

export const DesktopFooter = ({ }: Props) => {
    /**
     * 
     * Will need to make API call to get contact info
     */

    const COPYRIGHT_YEAR: number = new Date().getFullYear()

    // Hide and change parts of header when on interneal admin pages.
    const location = useLocation()
    const [isAdminClass, setIsAdminClass] = useState('')
    useEffect(() => {
        if (location.pathname.indexOf('/admin/') === 0) {
            setIsAdminClass('is-admin')
        } else {
            setIsAdminClass('')
        }
    }, [location])

    return (
        <footer className={`desktop-footer ${isAdminClass}`}>
            <div className='body-container-no-vert-pad categories'>
                <div className='wrap-block'>
                    <div className='column'>
                        <div className='column-header'>Category 1</div>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                    </div>
                    <div className='column'>
                        <div className='column-header'>Category 2</div>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                    </div>
                </div>
                <div className='wrap-block'>
                    <div className='column'>
                        <div className='column-header'>Category 3</div>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                    </div>
                    <div className='column'>
                        <div className='column-header'>Category 4</div>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                        <Link to="" className='category-link'>Sub Category</Link>
                    </div>
                </div>
            </div>

            <div className='footer-nav-wrapper'>
                <div className='body-container-no-vert-pad footer-nav'>
                    <div className='wrap-block'>
                        <div className="column contact">
                            <div className='column-header'>Contact Us</div>
                            <a href='tel:1-844-422-8607' className='footer-link contact-link'>
                                1-844-422-8607
                            </a>
                            <a href='mailto:info@regenativelabs.com' className='footer-link contact-link'>
                                info@regenativelabs.com
                            </a>
                        </div>
                        <div className="column">
                            <div className='column-header'>Customer Service</div>
                            <Link to='/public/contact' className='footer-link'>Contact Us</Link>
                            <Link to='' className='footer-link'>Ordering &amp; Payments</Link>
                            <Link to='' className='footer-link'>Shipping</Link>
                            <Link to='' className='footer-link'>FAQ</Link>
                        </div>
                    </div>
                    <div className='wrap-block'>
                        <div className="column">
                            <div className='column-header'>Information</div>
                            <Link to='/public/about' className='footer-link'>About Medngine</Link>
                            <Link to='' className='footer-link'>Work With Us</Link>
                            <Link to='' className='footer-link'>Privacy Policy</Link>
                            <Link to='' className='footer-link'>Terms &amp; Conditions</Link>
                            <Link to='' className='footer-link'>Press Enquiries</Link>
                        </div>
                        <div className="column subscribe">
                            {/* <div className='subscribe-contents'> */}
                            <div className='subscribe-contents'>
                                <div className='column-header'>Subscribe to Medngine</div>
                                <div className='subscribe-text'>
                                    News &amp; updates, delivered straight to your inbox.
                                </div>
                                <div className='subscribe-wrapper'>
                                    <input type="email" name='email-subscription' placeholder='Email Address' />
                                    <button className='clickable'>
                                        <Icon name='mail-check' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='copyright-outer'>
                    <div className='body-container-no-vert-pad copyright'>
                        <img className='logo-for-copyright'
                            src={textOnlyLogo}
                            alt="Medngine logo text"
                        />
                        <div className='copyright-text'>
                            &copy; {COPYRIGHT_YEAR} Medngine, All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};