import React from "react";

import { InputField } from "../../../shared/form/input-field/InputField";

import dollarFormat from '../../../../helpers/numberFormatter'

import LoadingCircle from '../../../../static/gif/loading-circle.gif'




interface Props {
    setCoupon: React.Dispatch<React.SetStateAction<string>>;
    setCouponCheckEventTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    couponShowSpinner: boolean;
    couponSuccessMsg: string;
    couponErrorMsg: string;
    cartBaseProductsPrice: number;
    cartBasePrice: number;
    userStateTaxRate: number;
    userShippingPrice: number;
    cartBulkDiscountAmount: number;
    cartCouponDiscountAmount: number;
    cartTotalDiscounted: number;
}



/**
 * Component that shows the price breakdowns on the shopping cart page (CheckOut.tsx).
 */
const _PricesTable = ({
    setCoupon, 
    setCouponCheckEventTrigger,
    couponShowSpinner,
    couponSuccessMsg,
    couponErrorMsg,
    cartBaseProductsPrice,
    cartBasePrice,
    userStateTaxRate,
    userShippingPrice,
    cartBulkDiscountAmount,
    cartCouponDiscountAmount,
    cartTotalDiscounted,

}: Props) => {
    return (
        <div className="cost-grid">
            <div className="coupon-label">
                <label htmlFor="checkout-coupon-code">Coupon Code</label>
            </div>
            
            <div className="cost-summary">
                <div className="coupon-input">
                    <InputField 
                        small={true}
                        id="checkout-coupon-code"
                        name="coupon-code"
                        altStyle="medium-border" 
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCoupon(e.target.value)
                        }}
                        successMessage={couponSuccessMsg}
                        errorMessage={couponErrorMsg}
                        // Need to use a few different types of events (for desktop & mobile) bc
                        // of backend brute-force/rate-limiter prevention mechnism could cause the
                        // last char(s) entered not to run an actual check. It would be a somewhat 
                        // common edge case or rare depending on the duration of the frontend debounce
                        // timeout. If after entering a proper coupon, but the backend check isn't run,
                        // and the user is confused and does 
                        // anything like click in the box or somewhere else (or tap on mobile),
                        // presses Enter, etc, then enough time will have passed for the rate limiter
                        // mechanism to allow the (coupon-code-check function) to run again.
                        onClick={() => {
                            setCouponCheckEventTrigger(prev => !prev)
                        }}
                        onKeyUp={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                setCouponCheckEventTrigger(prev => !prev)
                            }
                        }}
                        onBlur={() => {
                            setCouponCheckEventTrigger(prev => !prev)
                        }}
                    />
                    {(couponShowSpinner) ? (
                        <img 
                            src={LoadingCircle} 
                            className='checking-coupon-spinner-in-input' 
                            style={{opacity: 1}} 
                        />
                    ) : null}
                </div>

                <div className='price-row'>
                    <div className='price-row-title'>
                        Base price:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartBaseProductsPrice)}
                    </div>
                </div>
                <div className='price-row'>
                    {/* Taxes for the original base price before any discounts were applied */}
                    <div className='price-row-title'>
                        Tax ({(userStateTaxRate * 100).toFixed(3)}%):
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartBaseProductsPrice * userStateTaxRate)}
                    </div>
                </div>
                <div className='price-row'>
                    <div className='price-row-title'>
                        Shipping:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartBasePrice ? userShippingPrice : 0)}
                    </div>
                </div>

                <div className='price-row-separator'></div>

                <div className='price-row'>
                    <div className='price-row-title'>
                        Original total:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartBasePrice)}
                    </div>
                </div>
                <div className='price-row'>
                    <div className='price-row-title'>
                        Bulk discounts:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartBulkDiscountAmount)}
                    </div>
                </div>
                <div className='price-row'>
                    <div className='price-row-title'>
                        Coupon discounts:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartCouponDiscountAmount)}
                    </div>
                </div>

                <div className='price-row-separator'></div>

                <div className='price-row bold'>
                    <div className='price-row-title'>
                        Total price:
                    </div>
                    <div className='price-row-value'>
                        {dollarFormat(cartTotalDiscounted)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default _PricesTable