import { useEffect, useState } from 'react'

import { DoctorCard } from '../manual-carousel/DoctorCard';
import { PillButton } from '../../buttons/pill-button/PillButton';

import './auto-carousel.scss';


// Frequncy of card rotation.
const ROTATION_TIMER = 6000
// This val should be the same as `transition-duration` in the css.
const ANIMATION_DURATION = 500

// Mock data - for testing
const cardContents = [
    {text: 'one', styles: {backgroundColor: '#f23'}},
    {text: 'two', styles: {backgroundColor: '#fa0'}},
    {text: 'three', styles: {backgroundColor: '#fe0'}},
    {text: 'four', styles: {backgroundColor: '#8f3'}},
    {text: 'five', styles: {backgroundColor: '#0ff'}},
    {text: 'six', styles: {backgroundColor: '#08f'}},
    {text: 'seven', styles: {backgroundColor: '#a3f'}},
]


/**
 * Props for data
 */
interface CardData {
    name: string;
    practice: string;
    description: string;
    imgSrc: string;
    styles?: React.CSSProperties; // For testing purposes only (with `cardContents`)
    text?: string; // For testing purposes only (with `cardContents`)
}

/**
 * Props for components
 */
interface PropsCard {
    whichCard: number;
    // "mid" is the card displayed in the center, and "left2" & "right2" are
    // the cards underneath the left-most and right-most cards, respectively.
    whichPos: "left2"|"left"|"mid"|"right"|"right2";   
    pillButton: JSX.Element;
    cardData: CardData[];
}
interface PropsAutoCarousel {
    pillButton: JSX.Element;
    cardData: CardData[];
}




/** 
     * Card component 
     */
 const Card = ({ whichCard, whichPos, pillButton, cardData } : PropsCard) => {
    return (
        <div 
            className={`card ${whichPos} print-clear-styles`} 
            style={cardData[whichCard].styles  || {}}
        >
            {cardData[whichCard].text ? (
                // Test content (using `cardData` above)
                cardData[whichCard].text
            ) : (
                // Actual content
                <DoctorCard 
                    name={cardData[whichCard].name}
                    practice={cardData[whichCard].practice}
                    description={cardData[whichCard].description} 
                    imgSrc={cardData[whichCard].imgSrc}
                    containerStyle={{width: '100%'}}
                    pillButton={pillButton}
                />
            )}
        </div>
    )
}



/** 
 * Coordinate rotation of cards. 
 */
export const AutoCarousel = ({ pillButton, cardData } : PropsAutoCarousel) => {
    // Initialize this to the index of what should initialally display as the left-most card.
    const [leftCardIndex, setLeftCardIndex] = useState(cardData.length - 1)
    // For triggering css transform animations to rotate cards.
    const [isMoving, setIsMoving] = useState(false)
    // For forcing re-render of cards after they have rotated to their correct positions.
    const [keyForRefresh, setKeyForRefresh] = useState(Date.now())

    // Timers to trigger rotation of cards.
    useEffect(() => {
        let interval: NodeJS.Timer;
        let timer: NodeJS.Timeout;

        interval = setInterval(() => {
            setIsMoving(true)

            timer = setTimeout(() => {
                setLeftCardIndex(prev => prev + 1)
                setIsMoving(false)
                setKeyForRefresh(Date.now())
            }, ANIMATION_DURATION)
        }, ROTATION_TIMER)

        return () => {
            clearInterval(interval)
            clearTimeout(timer)
        }
    }, [])

    return (
        <div className='carousel-container'>
            <div className='carousel-container-inner'>
                {/* Card underneath right-most card */}
                <Card
                    key={keyForRefresh + 3}
                    whichCard={(leftCardIndex + 3) % cardData.length}
                    whichPos={isMoving ? 'right' : 'right2'}
                    pillButton={pillButton}
                    cardData={cardData}
                />

                {/* Right-most card */}
                <Card
                    key={keyForRefresh + 2}
                    whichCard={(leftCardIndex + 2) % cardData.length}
                    whichPos={isMoving ? 'mid' : 'right'}
                    pillButton={pillButton}
                    cardData={cardData}
                />

                {/* Left-most card */}
                <Card
                    key={keyForRefresh + 0}
                    whichCard={(leftCardIndex + 0) % cardData.length}
                    whichPos={isMoving ? 'left2' : 'left'}
                    pillButton={pillButton}
                    cardData={cardData}
                />
                
                {/* Main/Center card, which needs to be displayed on top */}
                <Card
                    key={keyForRefresh + 1}
                    whichCard={(leftCardIndex + 1) % cardData.length}
                    whichPos={isMoving ? 'left' : 'mid'}
                    pillButton={pillButton}
                    cardData={cardData}
                />
            </div>
        </div>
    )
}
