import { useState } from 'react'
import { Link } from "react-router-dom";

import { BlockButton } from "../../shared/buttons/block-button/BlockButton";
import { CreditApplicationStep1 } from './CreditApplicationStep1'
import { CreditApplicationStep2 } from './CreditApplicationStep2'
import { CreditApplicationStep3 } from './CreditApplicationStep3'
import { CreditApplicationStep4 } from './CreditApplicationStep4'
import { CreditApplicationStep5 } from './CreditApplicationStep5'
import { ProgressBar } from "../../shared/progress-bar/ProgressBar"

import './credit-application.scss'
import PrintOnlyContent from '../../shared/print/PrintOnlyContent';



export const CreditApplication = () => {
    const NUMBER_OF_STEPS: number = 4
    const [step, setStep] = useState<number>(1)

    // The form component that should be visible on any given step.
    //  - This structure makes inactive forms invisible instead of 
    //    removing them from the page, so that form contents are 
    //    preserved while navigating between steps.
    let componentsByStep: {[key: number]: JSX.Element} = {
        1: <CreditApplicationStep1 key={'1'} activeStep={step} />,
        2: <CreditApplicationStep2 key={'2'} activeStep={step} />,
        3: <CreditApplicationStep3 key={'3'} activeStep={step} />,
        4: <CreditApplicationStep4 key={'4'} activeStep={step} />,
        5: <CreditApplicationStep5 key={'5'} activeStep={step} />,
    }

    return (
        <>
        <PrintOnlyContent title={true}>Medngine credit application page</PrintOnlyContent>
        <div className='body-container credit-application-parent'>
            {/* Page title & progress bar */}
            {(step <= NUMBER_OF_STEPS) && (
                <>
                <div className='page-title'>
                    Credit Application
                </div>
                <div className='progress-bar-wrapper'>
                    <ProgressBar whichStep={step} numberOfSteps={NUMBER_OF_STEPS} />
                </div>
                </>
            )}

            {/* Show the correct form for the current step. */}
            {Object.keys(componentsByStep).map((key) => {
                return componentsByStep[Number(key)]
            })}

            {/* Navigation buttons */}
            <div className={
                `next-and-prev-buttons-container` +
                `${(step === 1) ? ' right' : ''}` +
                `${(step > NUMBER_OF_STEPS) ? ' center' : ''}`
            }>
                {/* Button to go to previous step */}
                {(step > 1 && step <= NUMBER_OF_STEPS) && (
                    <BlockButton 
                        buttonStyle="ghost-purple" 
                        style={{width: '240px'}}
                        onClick={() => setStep(prev => prev - 1)}
                    >
                        BACK
                    </BlockButton>
                )}

                {/* Button to go to next step */}
                {(step < NUMBER_OF_STEPS) && (
                    <BlockButton
                        buttonStyle="purple"
                        style={{width: '240px'}}
                        onClick={() => setStep(prev => prev + 1)}
                    >
                        NEXT
                    </BlockButton>
                )}

                {/* Button to submit the whole form */}
                {(step === NUMBER_OF_STEPS) && (
                    <BlockButton
                        buttonStyle="purple"
                        style={{width: '240px'}}
                        onClick={() => setStep(prev => prev + 1)}
                    >
                        Submit
                    </BlockButton>
                )}

                {/* Button to go to home page after the form is complete (on the "success" page)*/}
                {(step > NUMBER_OF_STEPS) && (
                    <Link to={"/"}>
                        <BlockButton buttonStyle="ghost-purple" disabled={false}>HOME</BlockButton>
                    </Link>
                )}
            </div>
        </div>
        </>
    )
}