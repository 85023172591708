import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import { AccountContext } from '../../../auth/Account';
import { MAX_ORDER_QUANTITY } from '../../../../constants/cart';

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';
import { SetToggleButtonGroup } from '../../../shared/buttons/set-toggle-button-group/SetToggleButtonGroup';
import { TextCard } from "../../../shared/product-card/TextCard";

import pdfIcon from './pdf-icon.png';
import webIcon from './website-icon.png';

import hash from '../../../../helpers/hashCode';
import parseErrorObject from '../../../../helpers/parseErrorObject';
import { ProductInfo, SkuInfo, DiscountsByQuantity } from '../../../../../config';

import { SessionPacket } from '../../../../types/session';

import './product-page.scss';





const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL

// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)



/**
 * Component to display product details and choose dose/size/quantity and add the product
 * to shopping cart.
 */
export const ProductPage = () => {
    // Get shopping cart handler from Account Context.
    const { getUsernameAndIdToken, addToShoppingCart } = useContext(AccountContext)

    // Get url parameters.
    const params = useParams()

    // State vars
    const [productId, setProductId] = useState(Number(params.productId))
    const [productDetails, setProductDetails] = useState<ProductInfo>({} as ProductInfo)
    const [selectedSkuIndex, setSelectedSkuIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    // Flag to hide or show the modal for product details (such as dose sizes etc)
    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
    // Session
	const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)


    // Get session obj
	const getSessionObj = async () => {
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session || null)
	}
	useEffect(() => {
		getSessionObj()
	}, [getUsernameAndIdToken])


    // Parse the discount data from `productDetails`, and also make a list of keys.
    const getDiscountRangeTextAndMultipliers = (
        skuObj: SkuInfo,
    ): [string[], string[], number[]] => {
        const keysList = []
        const resultText = []
        const resultMultipliers = []
        let prevNum = 1

        if (skuObj.discountsByQuantity?.length) {
            // If there are defined discounts for this sku that are based on QUANTITY.
            for (let discountObj of skuObj.discountsByQuantity) {
                const [upToQuantity, priceMultiplier] = discountObj as DiscountsByQuantity

                keysList.push(`${skuObj.id}-${skuObj.sku_id}`)

                if (upToQuantity === null) {
                    resultText.push(String(prevNum) + '+')
                    resultMultipliers.push(priceMultiplier)
                    break
                } else {
                    resultText.push(String(prevNum) + ' - ' + upToQuantity)
                    resultMultipliers.push(priceMultiplier)
                    prevNum = upToQuantity + 1
                }
            }
        } else {
            // If there are no discounts set up for this sku.
            keysList.push(`${skuObj.id}-${skuObj.sku_id}`)
            resultText.push('')
            resultMultipliers.push(1)
        }

        return [keysList, resultText, resultMultipliers]
    }



    // Get the Product ID from the url and get product data from backend.
    useEffect(() => {
        const product_id = Number(params.productId) || 0
        setProductId(product_id)

        axios.get(
            `${API_DB_URL}/product-info/${product_id}`
        ).then(res => {
            setProductDetails(res.data)
            setErrorMessage('')
        }).catch(err => {
            const eMsg = parseErrorObject(err)
            setErrorMessage(eMsg)
          })
    }, [params.productId])


    
    // Data lists related to this product's details - pulled from the database.
    // (Currently uses mock data from the mock API)
    // const [MOCKproductDetails, setMOCKproductDetails] = useState({} as ProductsById)
    const [discountRangeKeys, setDiscountRangeKeys] = useState<string[]>([])
    const [discountRangeText, setDiscountRangeText] = useState<string[]>([])
    const [discountRangeValues, setDiscountRangeValues] = useState<number[]>([])

    // Simulate API call to fetch data from db.
    // Once the data arrives, break it down into a few different state variables above.
    useEffect(() => {
        if (!productDetails?.skus?.length || isNaN(selectedSkuIndex)) return

        const skuObj = productDetails.skus[selectedSkuIndex]
        const [keysList, discountText, discountMultipliers] = 
                        getDiscountRangeTextAndMultipliers(skuObj)
        setDiscountRangeKeys(keysList)
        setDiscountRangeText(discountText)
        setDiscountRangeValues(discountMultipliers)
    }, [productDetails, selectedSkuIndex])

    // Get and store data about whatever Sku is currently selected.
    const [quantity, setQuantity] = useState(1)
    
    const [currentSkuPrice, setCurrentSkuPrice] = useState(0)

    // This var indicates the index of the box in the "Price Per Unit" section that
    // should be programmatically selected/higlighted, based on the quantity of product
    // the user typed in (to add to shopping cart).
    const [priceRangeBoxToHighlight, setPriceRangeBoxToHighlight] = useState(0)

    // When the selected Sku changes, update the prices listed on the page.
    useEffect(() => {
        if (productDetails?.skus?.length && !isNaN(Number(selectedSkuIndex)) && productDetails.skus[selectedSkuIndex]) {
            const thisSkuPrice = productDetails.skus[selectedSkuIndex].price
            setCurrentSkuPrice(Number(thisSkuPrice || 0))
        }
    }, [selectedSkuIndex, productDetails?.skus])
    
    // Whenver user changes the quantity, see what discount range this fits under, in order to
    // programmatically highlight/select the correct box in the "Price Per Unit" section.
    // - Will assume that discountsByQuantity array is sorted HIGHEST-quantity first.
    // - (This is a placeholder function for now until if/when discounts functionality is added)
    useEffect(() => {
        if (productDetails?.skus?.length && !isNaN(Number(selectedSkuIndex)) && productDetails.skus[selectedSkuIndex]) {
            const skuObj = productDetails.skus[selectedSkuIndex]
            for (let x=0; x < (skuObj?.discountsByQuantity?.length || 0); x++) {
                const [upToQuantity, priceMultiplier] = skuObj.discountsByQuantity[x] as DiscountsByQuantity
                if (quantity < (upToQuantity ?? Infinity)) {
                    setPriceRangeBoxToHighlight(x)
                    break
                }
            }
        }
    }, [quantity, productDetails])


    return (
        <>
            <PrintOnlyContent title={true}>Medngine product page</PrintOnlyContent>

            <div className="header-spacer"></div>
            <div className="product-detail-page-layout">
                {/* This error message will appear when user enters invalid product ID, or DB is down. */}
                {(errorMessage) ? (
                <div className='error-message'>
                    {errorMessage}
                </div>
                ) : null}

                <div className="body-container formwrapper-top">
                    <div className="category-2-wrapper">
                        <div className="multicolor-headers">
                            {(productDetails.id) ? (
                                <>
                                <div className="header small">
                                    <Link to={`/public/vendor-profile/${productDetails.business_id}`}>
                                        {productDetails.business_name_display || productDetails.business_name}
                                    </Link>
                                </div>
                                <span className='header small'>
                                    {'/'} 
                                </span>
                                <div className="header small blue" style={{cursor: 'default'}}>
                                    {productDetails.name_display || productDetails.name}
                                </div>
                                </>
                            ) : (null)}
                        </div>
                    </div>
                    
                    {(productDetails.id) ? (
                        <TextCard
                            productId={productId}
                            name={productDetails.name}
                            nameDisplay={productDetails.name_display}
                            imageSrc1={`${CLOUDFRONT_BASE_URL}/uploads/product/${productId}/PRODUCT_1`}
                            imageSrc2={`${CLOUDFRONT_BASE_URL}/uploads/product/${productId}/PRODUCT_2`}
                            imageSrc3={`${CLOUDFRONT_BASE_URL}/uploads/product/${productId}/PRODUCT_3`}
                            imageSrc4={`${CLOUDFRONT_BASE_URL}/uploads/product/${productId}/PRODUCT_4`}
                            style={{}}
                            vendor={productDetails.business_name_display || productDetails.business_name }
                            brand={productDetails.brand}
                            description={productDetails.product_headline + '\n\n' + productDetails.product_description_1}
                            // productUses={productDetails.productUses}
                            // productResearch={productDetails.productResearch}
                            skus={productDetails.skus}
                            showProductDetailsModal={showProductDetailsModal}
                            setShowProductDetailsModal={setShowProductDetailsModal}
                        />
                    ) : null }
                </div>
                <div className='body-container-no-vert-pad selection-container'>
                    <div className='selection-row noprint'>
                        <div className='selection-section dose-section'>
                            <div className='selection-title'>
                                DOSE / SIZE
                            </div>
                            {productDetails?.skus && productDetails.skus.length ? (
                                <div className='selection-subtitle'>
                                    Selected: {productDetails.skus[selectedSkuIndex].name_display || productDetails.skus[selectedSkuIndex].name}
                                </div>
                            ) : null}
                            <SetToggleButtonGroup 
                                type='white-small-plus-text'
                                loopKeys={discountRangeKeys}
                                mainValues={!productDetails.skus?.length ? [] : productDetails.skus.map(skuObj => (skuObj.size || skuObj.dose))}
                                // Added upperValues per George recommendation but it doesn't look good. Save for posterity.
                                // upperValues={!productDetails.skus?.length ? [] : productDetails.skus.map(skuObj => (skuObj.name_display || skuObj.name))}
                                containerStyle={{display: 'flex', flexWrap: 'wrap', width: '100%'}}
                                buttonStyle={{
                                    // Some Products/Skus will have specific minimum widths 
                                    // for these buttons because they have more text.
                                    // minWidth: MOCKproductDetails[productId]?.displayMods.productPageDoseButtonMinWidth
                                }}
                                selectedItemIndex={selectedSkuIndex}
                                setSelectedItemIndex={setSelectedSkuIndex}
                            />
                        </div>
                        <div className='selection-section'>
                            <div className='selection-title'>
                                QUANTITY
                            </div>
                            <div style={{display: 'flex', columnGap: '12px', height: 'auto'}}>
                                <input
                                    className='quantity-input'
                                    name='quantity'
                                    type='number'
                                    min={1}
                                    max={MAX_ORDER_QUANTITY}
                                    value={quantity}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setQuantity(
                                            Math.min(Math.max(Number(event.target.value), 0), MAX_ORDER_QUANTITY)
                                        )
                                    }}
                                />
                                <BlockButton 
                                    buttonStyle='purple'
                                    style={{
                                        // Make height equal to input
                                        height: 'unset',
                                        padding: '0 16px',
                                    }}
                                    onClick={() => {
                                        const sessionJson = sessionObj ? JSON.stringify(sessionObj) : ''
                                        if (sessionJson) {
                                            addToShoppingCart(productId, productDetails?.skus[selectedSkuIndex].id, quantity, sessionJson)
                                        }
                                    }}
                                >
                                    Add to Cart
                                </BlockButton>
                            </div>
                        </div>
                    </div>

                    {/* For web */}
                    <div className='selection-row noprint'>
                        <div className='selection-section'>
                            <div className='selection-title'>
                                PRICE PER UNIT
                            </div>
                            <SetToggleButtonGroup 
                                type='white'
                                loopKeys={discountRangeKeys}
                                upperValues={discountRangeText}
                                mainValues={
                                    (currentSkuPrice) ? (
                                        discountRangeValues.map(multiplier => (
                                            '$' + (currentSkuPrice * multiplier).toFixed(2)
                                        ))
                                    ) : (
                                        []
                                    )
                                }
                                selectByClicking={false}
                                // This component will highlight the box at whatever this 
                                // provided state variable indicates.
                                selectedItemIndex={priceRangeBoxToHighlight}
                                setSelectedItemIndex={setPriceRangeBoxToHighlight}
                            />
                        </div>
                    </div>
                    
                    {/* For print only */}
                    <PrintOnlyContent>
                        <br/>
                        <span style={{fontWeight: 'bold'}}>
                            Price by dose / size:
                        </span>
                        <br/>
                        {(productDetails?.skus || []).map((skuObj, x) => (
                            <div key={`${skuObj.id}-${skuObj.sku_id}`}>
                                {skuObj.size || skuObj.dose}:<br/>
                                {discountRangeText.map((range, x) => (
                                    <React.Fragment key={`${hash(range)}-${x}`}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {range} units:&nbsp;
                                        ${(currentSkuPrice * discountRangeValues[x]).toFixed(2)} / each
                                        <br/>
                                    </React.Fragment>
                                ))}
                            </div>
                        ))}
                        <br/>
                    </PrintOnlyContent>
                </div>
                <div className="formwrapper-mid">
                    <div className="body-container category-3-wrapper">
                        <div className="header blue">
                            USES
                        </div>
                        {(productDetails?.id) && (
                            <p>{productDetails.product_description_2}</p>
                        )}
                    </div>
                </div>
                <div className="body-container formwrapper-bottom">
                    <div className="category-4-wrapper">
                        <div className="header blue">
                            RESEARCH
                        </div>
                        {(productDetails?.id) && (
                            <p>{productDetails.product_description_3}</p>
                        )}
                    </div>

                    <div className="pdf-web-wrapper">
                        <ul>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}