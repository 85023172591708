import { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { PillButton } from '../../../shared/buttons/pill-button/PillButton';
import { ProductsList2 } from '../products-list/ProductsList2';

import parseErrorObject from '../../../../helpers/parseErrorObject';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';
import { ProductData } from '../../../shared/modal/AddProductModal';

import './vendor-profile-page.scss';



const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL

// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)


interface Props { };



export const VendorProfilePage = ({ }: Props) => {
  let params = useParams()

  const [businessId, setBusinessId] = useState(0) // 0 will show default images on CloudFront (in the /0/ folder)
  const [businessName, setBusinessName] = useState('')
  const [businessNameDisplay, setBusinessNameDisplay] = useState('')
  const [aboutText, setAboutText] = useState('')
  const [buttonName, setButtonName] = useState('')
  const [buttonUrl, setButtonUrl] = useState('')
  const [specialties, setSpecialties] = useState<string[]>([])
  const [certifications, setCertifications] = useState<string[]>([])
  const [productsInfo, setProductsInfo] = useState<ProductData[]>([])
  const [errorMessage, setErrorMessage] = useState('')

  // Get businessId from the url params.
  useEffect(() => {
    if (!isNaN(Number(params?.businessId)) && Number(params.businessId)) {
      setBusinessId(Number(params.businessId))
    }
  }, [params?.businessId])

  // Ween business Id is set/changed, fetch info about the business.
  useEffect(() => {
    if (!businessId) return

    axios.get(
      `${API_DB_URL}/business-info/${businessId}`
    ).then(res => {
      const bizId = res.data?.id || ''
      setBusinessId(bizId)
      setBusinessName(res.data?.name || '')
      setBusinessNameDisplay(res.data?.name_display || '')
      setAboutText(res.data?.about || '')
      setButtonName(res.data?.button_1_text || '')
      setButtonUrl(res.data?.button_1_link || '')
      setSpecialties(convertToBulletsList(res.data?.specialties || ''))
      setCertifications(convertToBulletsList(res.data?.certifications || ''))
      setErrorMessage('')
      return bizId
    }).catch(err => {
      const eMsg = parseErrorObject(err)
      setErrorMessage(eMsg)
    }).then(bizId => {
      if (!bizId || isNaN(bizId)) return
      
      axios.get(
        `${API_DB_URL}/get-business-products/${businessId}`
      ).then(res => {
        const _productsInfo = res.data
        if (!_productsInfo || !Array.isArray(_productsInfo) || !_productsInfo.length) {
          setErrorMessage('Error #4563: No products found.')
          return
        } else {
          setProductsInfo(_productsInfo)
        }
      }).catch(err => {
        const eMsg = parseErrorObject(err)
        setErrorMessage(eMsg)
      })
    })
  }, [businessId])

  // Helper to parse db plain-text data for certifications and specialties so that they can be
  // displayed as bullets.
  const convertToBulletsList = (text: string) => {
    return text.split('\\n').filter(line => !!line).map(line => line.trim())
  }


  return (
    <>
      <PrintOnlyContent title={true}>Medngine vendor profile page</PrintOnlyContent>
      
      <HeaderBanner
        bannerType={3}
        imageName="CUSTOM"
        imageSrcUrl={`${CLOUDFRONT_BASE_URL}/uploads/business/${businessId || 0}/BANNER_1`}
        imageTextVendorBanner={(businessNameDisplay || businessName || '').toUpperCase()}
        vendorImageLogo="CUSTOM"
        vendorImageLogoSrcUrl={`${CLOUDFRONT_BASE_URL}/uploads/business/${businessId || 0}/LOGO_1`}
        secondaryImgSrcUrl={`${CLOUDFRONT_BASE_URL}/uploads/business/${businessId || 0}/LOGOALT_1`}
        imageSpacerColor='LIGHTGRAY'
        dontChangeTitleOpacity={true}
      />

      <div className="vendor-profile-page-layout">
        {/* This error message will appear when user enters invalid business ID, or DB is down. */}
        {(errorMessage) ? (
          <div className='error-message'>
            {errorMessage}
          </div>
        ) : null}

        <div className="formwrapper-mid">
          <div className="body-container-no-vert-pad category-3-wrapper">
            <div className="multicolor-headers">
              <div className="header blue">ABOUT</div>
              <div className="header">{(businessNameDisplay || businessName || '').toUpperCase()}</div>
            </div>
            <p>
              {aboutText}
            </p>
            {(buttonUrl) ? (
              <a href={buttonUrl}
                target='_blank'
                style={{width: 'fit-content'}}
              >
                <BlockButton buttonStyle='desktop-header-buttons purple'>
                  {buttonName || 'LINK'}
                </BlockButton>
              </a>
            ) : null}
          </div>
        </div>

        <div className="body-container-no-vert-pad formwrapper-products">
          <div className="header">
            PRODUCTS - (TODO: delete this section?)
          </div>
          <div className="products-text">
            <p>
              AmnioText™ Patch is a sterile, dehydrated human amniotic membrane
              allograft, intended for homologous use to cover and protect the recipient’s
              tissues. AmnioText™ is minimally manipulated and processed to preserve the
              original relevant characteristics relating to its utility as a barrier.
              <br /><br />
            </p>
            <p>
              Click below to view our CMS Decision Summaries.
              <br /><br />
            </p>
            <a href='https://regenativelabs.com/wp-content/uploads/2021/08/CMS_Decision_Summaries.pdf'
              target='_blank'
            >
              <BlockButton buttonStyle='desktop-header-buttons purple'>
                DOWNLOAD
              </BlockButton>
            </a>
          </div>

          <ProductsList2 condensed={true} productsInfo={productsInfo} />

        </div>
        <Link to={`/public/vendor-profile/${businessId || 0}`}>
          <PillButton
            buttonStyle="white gradient disabled"
            style={{ width: 'fit-content' }}
          >
            VIEW ALL PRODUCTS
          </PillButton>
        </Link>
        <div className="formwrapper-bottom">
          <div className="body-container-no-vert-pad category-5-wrapper">
            <div className="cards">
              <div className="card">
                <div className="header small blue">
                  Specialties &amp; Features
                </div>
                <ul>
                  {specialties.map((spec, i) => (
                    <li key={i}>
                      <div className="dot" />
                      <div className='li-text'>
                        {spec}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card">
                <div className="header small blue">Certifications</div>
                <ul>
                  {certifications.map((cert, i) => (
                    <li key={i}>
                      <div className="dot" />
                      <div className='li-text'>
                        {cert}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}