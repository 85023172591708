/**
 * Helper to parse error objects from both backend and frontend.
 * 
 * Use identifiers for `eName`/`eMessage` so it's easy to debug which part of the error handling
 * needs to be fixed.
 */

// If the error message has a section wrapped in "<<<" and ">>>", then only that section is 
// meant to be displayed, without any other error ID stuff.
const EXACT_START = '<<<'
const EXACT_END = '>>>'

const parseErrorObject = ( errorObj: any, errorCode?: string|number): string => {
    const e = errorObj
    let eType = ''
    let eName = ''
    let eMessage = ''
    let eString = ''

    // Handling for different types of errors.
    if (typeof e?.response?.data === 'string' && e?.response?.data.length) {
        // This type of error obj comes from:
        //   - `throw new Error` from Express
        eName = ''
        eMessage = e.response.data
        eType = '[ee=1]'
    } else if (typeof e?.response?.data?.message === 'string' && e?.response?.data?.message.length) {
        // This type of error obj comes from:
        //   - (Maybe): an unhandled error directly from Sequelize or Express.
        eName = e.response.data.name || ''
        eMessage = e.response.data.message
        eType = '[ee=2]'
    } else if (e?.name || e?.message) {
        // This type of error obj comes from:
        //   - (Maybe): when `throw new Error` from frontend.
        eName = e.name || ''
        eMessage = e.message || ''
        eType = '[ee=3]'
    } else if (e?.data) {
        // This type of error obj comes from:
        //   - (Unknown)
        if (typeof e.data === 'string') {
            eName = ''
            eMessage = e.data
            eType = '[ee=4]'
        } else {
            try {
                eName = ''
                eMessage = JSON.stringify(e.data)
                eType = '[ee=5]'
            } catch (_) {
                eName = 'Error'
                eMessage = String(e.data)
                eType = '[ee=6]'
            }
        }
    } else if (e) {
        // This type of error obj comes from:
        //   - (Unknown)
        if (typeof e === 'string') {
            eName = ''
            eMessage = e
            eType = '[ee=7]'
        } else {
            try {
                eName = ''
                eMessage = JSON.stringify(e)
                eType = '[ee=8]'
            } catch (_) {
                try {
                    eName = 'Error'
                    eMessage = e.toString()
                    eType = '[ee=9x]'
                } catch (_) {
                    eName = 'Error'
                    eMessage = String(e)
                    eType = '[ee=9y]'
                }
                
            }
        }
    } else {
        // No `e`
        eString = '[Empty error #00001y]'
    }

    // Clean up response
    eName = (eName || '').trim()
    eMessage = (eMessage || '').trim()

    /* If the error is meant to be displayed only literally, do that, and return here. */
    const _exact_start_idx = eMessage.indexOf(EXACT_START)
    const _exact_end_idx = eMessage.indexOf(EXACT_END)
    console.log('idxs', _exact_start_idx, _exact_end_idx, eName, '--', eMessage, 'y')
    if (_exact_start_idx > -1 && _exact_end_idx > _exact_start_idx) {
        eMessage = eMessage.slice(_exact_start_idx + EXACT_START.length, _exact_end_idx)
        return eMessage
    }


    if (eName) {
        eString = `${eName}: ${eMessage} ${eType}`
    } else {
        eString = `${eMessage} ${eType}`
    }

    // OVERRIDE THESE ERROR MESSAGES
    if (eString.includes('SequelizeConnectionAcquireTimeoutError')) {
        eString = 'Database timeout error. Please wait a few minutes and try loading the page again.'
    }

    // Edge case
    if (eString.replace(/\s/g, '').length === 0) {
        eString = '[Empty error #00001z]'
    }

    // If the string starts with "Error: " but that word redundat to the message, drop the first word.
    if ((eString.match(/error/ig) || []).length > 1) {
        eString = eString.split(/ error/i).join(' ').replace(/\s+/g, ' ')
    }

    // Add on the supplied error code arg (could be from frontend or backend, depending on the caller).
    // - but don't add it if the errorCode is already in the message
    if (errorCode) {
        if (!eString.includes(String(errorCode))) {
            eString += `[eee=${errorCode}]`
        }
    }
    
    return eString
}

export default parseErrorObject