import './my-account-dropdown.scss';

export const MyAccountDropdown = () => {

  // displayDropdown = () => {  } 

  // <div className="my-account" onClick={() => this.diplayDropdown( )}>My Account</div>

  return (
    <div>


      <div className="my-account-navbutton" onClick={() => (null)}>MY ACCOUNT</div>

      <select id="myaccount-dropdown" hidden>
        <option value="profile">My Profile</option>
        <option value="recent-orders">Recent Orders</option>
        <option value="tissue-inventory">Tissue Inventory</option>
        <option value="registration-forms">Registration Forms</option>
      </select>




      <ul className="my-account-dropdown">
        <li className="my-account-items">My Profile</li>
        <li className="my-account-items">Recent Orders</li>
        <li className="my-account-items">Tissue Inventory</li>
        <li className="my-account-items">Registration Forms</li>
      </ul>
    </div>

  );
};