import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrintOnlyContent from '../../shared/print/PrintOnlyContent';
import { ProductDetailsTemp } from './product-details/ProductDetailsTemp';
import './product.scss';

type Props = {};
type State = {};

export class Product extends React.Component<Props, State> {


    render() {
        return (
            <div className='product-page'>
                <PrintOnlyContent title={true}>Medngine product pages</PrintOnlyContent>
                <Routes>
                    <Route path='details/:productId' element={<ProductDetailsTemp />} />
                </Routes>
            </div>
        );
    }
}


