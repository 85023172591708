import './set-toggle-button.scss';


export const SetTogglePriceDark = ({ children }: Props) => {
    return <button className="price-toggle"><div className="price-toggle darktoggletext">1 - 10</div>{children}</button>;
}

export const SetTogglePriceWhite = ({ children }: Props) => {
    return <button className="price-toggle white1"><div className="price-toggle white1 whitetoggletext">11 - 24</div>{children}</button>;
}

export const SetToggleMlWhite = ({ children }: Props) => {
    return <button className="mltoggle ml-white1">{children}</button>;
}

export const SetToggleMlDark = ({ children }: Props) => {
    return <button className="mltoggle">{children}</button>;
}


/**
 * ====== Testing refactor
 */
interface Props {
    children?: any;
    buttonStyle?: string;
    selected?: boolean;
    price?: string;
    count?: string;
    countColor?: string;
};

export const SetToggleButton = ({ buttonStyle, selected, price, count, countColor }: Props) => {
    return (
        <button
            className={buttonStyle + ' price-toggle ' + ((selected) ? ' selected ' : '')}>
            <div className={countColor + ' price-toggle ' + ((selected) ? ' selected ' : '')}>{count}</div>
            {price}
        </button>
    );
}