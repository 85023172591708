/**
 * For doctor/clinic/provider users. Shows reps who tagged them.
 */

import { useEffect, useState } from 'react';

import { Icon } from '../../shared/icon/Icon';
import { TagAcceptModal } from '../../shared/modal/TagAcceptModal';

import { MyAccountInfo, TaggedReps } from '../../../../config'



interface Props {
    getTagsListError: string;
    tagsData_RtoC: TaggedReps[];
    untagRep: Function;
    userId: number;
    userType: 'doctor';
    accountInfo: MyAccountInfo;
}

export const TaggedByReps_Doc = ({
    getTagsListError,
    tagsData_RtoC,
    untagRep,
    userId, userType, accountInfo,
}: Props) => {
    const [showAcceptTagModal, setShowAcceptTagModal] = useState(false)
    const [fromName, setFromName] = useState('')
    const [fromEmail, setFromEmail] = useState('')
    const [fromOrgName, setFromOrgName] = useState('') // rep-org name
    const [uuid, setUuid] = useState('')

    // When accept-tag modal is closed, clear the vars.
    useEffect(() => {
        if (!showAcceptTagModal) {
            setFromName('')
            setFromEmail('')
            setFromOrgName('')
            setUuid('')
        }
    }, [showAcceptTagModal])

    // Condition to prevent display of this modal.
    if (!tagsData_RtoC.length) {
        return null
    }

    return (
        <div className='section'>
            {/* Modal to accept tag invite (but not decline, which is handled by MyAccount.tsx) */}
            {(showAcceptTagModal && fromEmail && uuid) ? (
                <TagAcceptModal
                    show={showAcceptTagModal}
                    closeModal={() => setShowAcceptTagModal(false)}
                    title='Accept Invitation from:'
                    subtitle={[fromOrgName, fromName, fromEmail].filter(v => !!v).join('\r\n')}
                    message="Confirm that this user is a sales rep for your medical practice."
                    uuid={uuid}
                />
            ) : null}

            <div className='title-row'>
                <div className='section-title'>
                My Sales Reps
                </div>
            </div>

            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            <table className='info-table'>
                <thead>
                    <tr>
                        <td>Sales Rep</td>
                        <td>Status</td>
                        <td>Invited</td>
                        <td>Accepted</td>
                    </tr>
                </thead>
                <tbody>
                    {tagsData_RtoC.map((row, index) => (
                        <tr 
                            key={index + '--' + row.to_uid}
                            className='tag-row'
                            style={{ cursor: 'default' }}
                            title={
                                `Invited by:\r\n`
                                + `    ${row.from_name ? `${row.from_name}\r\n` : ''}`
                                + `    ${row.from_email ? `${row.from_email}\r\n` : ''}`
                                + `Invite sent to:\r\n`
                                + `${row.to_name ? `    ${row.to_name}\r\n` : ''}`
                                + `    ${row.to_email}`
                        }
                        >
                            <td className='title medium product-cell'>
                                {row.from_name}
                            </td>
                            <td className='smaller product-cell'>
                                {(row.active) ? (
                                    'Active'
                                ) : (row.active === false) ? (
                                    'Deactivated' // This should never show bc it's filtered out on backend.
                                ) : ( // row.active === null
                                    <span style={{color: '#f34'}}>
                                        Pending
                                    </span>
                                )}
                            </td>
                            <td className='small product-cell'>
                                {row.invited_at}
                            </td>
                            <td className='small product-cell'>
                                {(row.active) ? (
                                    row.accepted_at
                                ) : (row.active === false) ? (
                                    'Declined' // (this should never show bc these rows are filtered out.)
                                ) : ( // row.active === null
                                    // Button to show the accept-invite modal.
                                    <button 
                                        type='button' 
                                        className='accept-invite-button'
                                        onClick={() => {
                                            setShowAcceptTagModal(true)
                                            setFromOrgName(row.from_reporg_name)
                                            setFromName(row.from_name)
                                            setFromEmail(row.from_email)
                                            setUuid(row.uuid)
                                        }}
                                    >
                                        Accept
                                    </button>
                                )}
                            </td>
                            <td className='smallest untag-cell'>
                                <button 
                                    type='button'
                                    className='untag-button'
                                    onClick={() => {
                                        untagRep(
                                            'deactivate', null, false,
                                            userId, userType, accountInfo, false,
                                            row.from_roid, row.to_roid,
                                            row.from_reporg_name, row.to_reporg_name,
                                            row.from_bid, row.from_biz_name, 
                                            row.from_uid, row.to_uid, row.from_name, '', // ('' intentional)
                                            row.to_cid, row.to_clinic_name, 
                                            row.active)
                                    }}
                                >
                                    <Icon name='circle-x' />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}