import "./auth.scss";
import { Route, Routes } from "react-router-dom";
import { Login } from "./login/Login";
import { ForgotPassword } from './forgot-password/ForgotPassword'
import { VerificationCode } from './verification-code/VerificationCode'



export const Auth = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password/*' element={<ForgotPassword />} />
      <Route path='verification-code' element={<VerificationCode />} />
    </Routes>
  )
}
