
import { Route, Routes, useLocation } from "react-router-dom";

import { AdminHome } from "./AdminHome";
import { SysAdmin } from "./SysAdmin";

import { BreadCrumbTrail } from "../shared/nav/BreadcrumbTrail";

import { ADMIN_NAV_HIERARCY, BASE_PAGE_NAME, Hierarchy, Page } from './_PATHS'
import { AdminUsers } from "./AdminUsers";
import { AdminLists } from "./AdminLists";
import { AdminUsersTag } from "./AdminUsersTag";
import { DesktopHeader } from "../layout/desktop-header/DesktopHeader";

import './admin.scss'

export const Admin = () => {

    const location = useLocation()

    return (
        <>
        <DesktopHeader
            isAdminPage={true}
            style={{ zIndex: 60 }} // (see .admin-page-overlay class)
        />
        <BreadCrumbTrail
            pageName={location.pathname.split('/').slice(-1)[0].replace(/\//g, '').trim() || BASE_PAGE_NAME}
            hierarchyObj={ADMIN_NAV_HIERARCY}
            classes='admin-pages'
            style={{ zIndex: 60 }} // (see .admin-page-overlay class)
        />
        <div className='admin-page-header-underlay' />

        {/**
         * Any update to these routes should also be updated in ./_PATHS.tsx.
         */}
        <div className='admin-page-overlay'>
            <Routes>
                <Route path='/' element={<AdminHome />} />
                <Route path='sys' element={<SysAdmin />} />
                <Route path='lists/:urlTable' element={<AdminLists />} />
                <Route path='lists/:urlTable/:urlObjId' element={<AdminLists />} />
                <Route path='lists/:urlTable/:urlObjId/:urlBackButton' element={<AdminLists />} />
                <Route path='tags' element={<AdminUsersTag />} />
            </Routes>
        </div>
        </>
    )
}
