import React from "react";
import { Route, Routes } from "react-router-dom";
import { PatientProductPage } from "./product-detail/PatientProductPage";
import { PatientVerifyEmail } from "./verify-email/PatientVerifyEmail";
import PrintOnlyContent from "../../shared/print/PrintOnlyContent";

export const Patient = () => {

	return (
		<>
			<PrintOnlyContent title={true}>Medngine patient pages</PrintOnlyContent>
			<Routes>
				<Route path='/products/:productId' element={<PatientProductPage />} />
				<Route path='/verify-email' element={<PatientVerifyEmail />} />
			</Routes>
		</>
	);
}