import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getUser } from '../../../store/actions/user-actions';
import { StoreItems } from '../../../store/store';
import { PhysicianProfile } from './physician-profile/PhysicianProfile';
import { PhysicianDashboard } from './dashboard/PhysicianDashboard';
import { NewPhysicianDashboard } from './dashboard-new/NewPhysicianDashboard';
import { RegisteredProcedures } from './procedures/RegisteredProcedures';
import { TissueInventory } from './tissue-inventory/TissueInventory';
import { ProductInventory } from './product-inventory/ProductInventory';
import { StudyDashboard } from './study-dashboard/StudyDashboard';
//  import { ProductDetails } from '../product/product-details/ProductDetails';   PRODUCT DETAIL PAGE.
import './physician.scss';
import PrintOnlyContent from '../../shared/print/PrintOnlyContent';


// TODO:  FIX PRODUCT DETAILS PAGE EXPORT FOR COMPATIBILITY WITH MODERN REACT


type Props = {
    user?: any,
    getUser: any,
};



const PhysicianPage = (props: Props) => {

    props.getUser();

    console.log(props.user);

    if (!props.user) <Navigate to="/auth/login" />

    return (
        <>
        <PrintOnlyContent title={true}>Medngine physician pages</PrintOnlyContent>
        <Routes>
            <Route path='profile' element={<PhysicianProfile />} />
            {/*  <Route path='product-detail' element={<ProductDetails />} /> */}
            <Route path='physician-dashboard' element={<PhysicianDashboard />} />
            <Route path='new-physician-dashboard' element={<NewPhysicianDashboard />} />
            <Route path='study-dashboard' element={<StudyDashboard />} />
            <Route path='registered-procedures' element={<RegisteredProcedures />} />
            <Route path='tissue-inventory' element={<TissueInventory />} />
            <Route path='product-inventory' element={<ProductInventory />} />
        </Routes>
        </>
    );
}


const injectStoreToProps = (store: StoreItems) => {
    const { user } = store;
    return { user };
}

const conn = connect(injectStoreToProps, { getUser });
export const Physician = conn(PhysicianPage);