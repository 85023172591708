import { Route, Routes } from 'react-router-dom'
import { SupplierRegistration1 } from './SupplierRegistration1'
import { SupplierRegistration2 } from './SupplierRegistration2'
import { SupplierRegistration3 } from './SupplierRegistration3'
import { SupplierRegistration4 } from './SupplierRegistration4'
import { SupplierRegistration5 } from './SupplierRegistration5'
import { SupplierRegistration6 } from './SupplierRegistration6'
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent'


export const SupplierRegistration = () => {

    return (
        <>
            <PrintOnlyContent title={true}>Medngine supplier registration pages</PrintOnlyContent>
            <Routes>
                <Route path='1' element={<SupplierRegistration1 />} />
                <Route path='2' element={<SupplierRegistration2 />} />
                <Route path='3' element={<SupplierRegistration3 />} />
                <Route path='4' element={<SupplierRegistration4 />} />
                <Route path='5' element={<SupplierRegistration5 />} />
                <Route path='6' element={<SupplierRegistration6 />} />
            </Routes>
        </>
    )
}