import React from 'react';
import { Link } from "react-router-dom";
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { Greyback } from '../../../shared/form/greyback/Greyback';
import { InputField } from '../../../shared/form/input-field/InputField';
import { TextAreaField } from '../../../shared/form/text-area/TextAreaField';
import { DoctorProfileBanner } from '../../../shared/image-spans/DoctorProfileBanner';
import PrivacyIcon from "./privacy-icon.png";
import './doctor-profile-page.scss';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';

type Props = {};

type State = {};


export class DoctorProfilePage extends React.Component<Props, State> {



  render() {
    return (
      <>
        <PrintOnlyContent title={true}>Medngine doctor profile page</PrintOnlyContent>
        <DoctorProfileBanner />
        <div className="doctorpage-layout">
          <div className="body-container formwrapper-top">
            <div className="about-practice-container">
              <div className="about-practice">
                <div className="multicolor-headers">
                  <div className="header blue">ABOUT</div>
                  <div className="header">PRACTICE</div>
                </div>
                <div className="about-practice-text">
                  text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test test text <br /><br />  test text test text test text test text test text test text test text test text test text test text test text test text test text test text
                </div>
              </div>
              <div className="greyback-container"><Greyback
                greybackStyle="base-content"
                style={{ width: '100%', height: '90%', borderRadius: '3px' }}
                closable={false}
              >VIDEO OR IMAGE <br /><br /> PROMOTIONAL SPACE </Greyback>
              </div>
            </div>
          </div>
          <div className="formwrapper-mid">
            <div className="body-container-no-vert-pad about-physician">
              <div className="inner">
                <div className="multicolor-headers">
                  <div className="header blue">ABOUT</div>
                  <div className="header">PHYSICIAN</div>
                </div>
                <div className="about-physician-text">
                  test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test test text <br /><br /> text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text
                </div>
              </div>
              <div className="cards">
                <div className="card">
                  <div className="header small blue">
                    Specialties
                  </div>
                  <ul>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here Text here  Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here Text here  Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <ul>
                    <div className="header small blue">Certifications</div>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here Text here  Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here Text here  Text here
                      </div>
                    </li>
                    <li>
                      <div className="dot" />
                      <div className='li-text'>
                        Text here
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="body-container-no-vert-pad formwrapper-bottom">
            <div className="doctor-contact-form">
              <div className="header blue">CONTACT</div>
              <div className="category-1-wrapper">
                <InputField
                  required
                  id="doctor-contact-firstname"
                  name='firstname'
                  placeholder='First Name'
                  style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                />
                <InputField
                  required
                  id="doctor-contact-lastname"
                  name='lastname'
                  placeholder='Last Name'
                  style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                />
              </div>
              <div className="category-2-wrapper">
                <InputField
                  required
                  id="doctor-contact-email"
                  type="email"
                  name='emailaddress'
                  placeholder='Email Address'
                  style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                />
                <InputField
                  id="doctor-contact-phone"
                  name='phone'
                  type="number"
                  placeholder='Phone Number'
                  style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                />
              </div>
              <div className="category-3-wrapper">
                <TextAreaField
                  required
                  id="doctor-contact-message"
                  name="message"
                  placeholder='Message'
                  style={{ minHeight: '200px', height: 'calc(100% - 2rem)', resize: 'none' }}
                />
                <BlockButton
                  style={{ width: '240px', marginTop: '35px' }}
                  buttonStyle='desktop-header-buttons purple'
                >
                  SEND EMAIL
                </BlockButton>
              </div>
            </div>
            <div className="privacy-container">
              <div className="privacy-image"><img src={PrivacyIcon} /></div>
              <div className="header-small">YOUR PRIVACY</div>
              <div className="privacy-text">test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}