import React from 'react';
import { InputField } from '../../../shared/form/input-field/InputField';
import { Table } from "../../../shared/tables/Table";
import { Link } from "react-router-dom";
import './product-inventory.scss';


interface Props { };

export const ProductInventory = ({ }: Props) => {

  const mockDataTable = [
    { orderNumber: "1003045001", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045002", date: "Patch", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045003", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045004", date: "Patch", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045005", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045006", date: "Patch", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045007", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045008", date: "Patch", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045009", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045011", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045022", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045033", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045044", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045055", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045066", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045077", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045088", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045099", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045200", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
    { orderNumber: "1003045301", date: "Vial", amount: "99", status: "$200", orderLink: 'VIEW ORDER' },
  ]

  return (
    <>
      <div className="body-container product-inventory-layout">
        <div className="header">PRODUCT INVENTORY</div>
        <InputField
          required
          style={{ width: '700px', marginTop: '15px' }}
          name='vendorfirst'
          placeholder='Search by SKU, Price, etc.'
        />
        <Table
          headerStyle="vendordash-header-cols"
          gridType="vendordash-container"
          tableStyle="vendordash-items-fill"
          style={{ marginTop: '45px', paddingBottom: '150px' }}
          visibleRows={4}
          data={mockDataTable}
          columnNames={["ITEM SKU", "DESCRIPTION", "QTY", "PRICE", "VIEW ORDER"]}
          pagingOption="paging-arrow"
        />
      </div>
    </>
  )
}