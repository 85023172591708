import { useContext, useEffect, useState } from 'react'

import { AccountContext } from "../../../auth/Account";

import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';

import { SessionPacket } from '../../../../types/session';

import './check-out-success.scss'

/**
 * Component to show after checkout is complete.
 * Get an Order ID and clear the shopping cart.
 */
export const CheckOutSuccess = () => {
    const { getUsernameAndIdToken, getOrderId, emptyShoppingCart } = useContext(AccountContext)

    const [orderId, setOrderId] = useState('')
	const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [sessionJson, setSessionJson] = useState('')

    // Get the user's session.
    useEffect(() => {
        getSession()
    }, [])

    // After getting the sessionObj:
    // - First get the order ID, to display on the page.
    useEffect(() => {
        if (sessionJson) { showOrderId() }
    }, [sessionJson])

    // ^^ TODO: changed this on 7/2 - didn't test the changes.

    // After getting then sessionObj and showing the order ID, clear the shopping cart, bc
    // user already paid for all of it.
    // - TODO: it would be much better to do this from the backend from the same function
    //   that completes checkout and payment.
    useEffect(() => {
        if (sessionJson && orderId) { 
            const isCleared = emptyShoppingCart(sessionJson)
            if (!isCleared) {
                alert('Failed to empty shopping cart (#63381)')
            }
        }
    }, [sessionJson, orderId])

    // ^^ TODO: changed this on 7/2 - didn't test the changes.

    /*
     * Helper functions.
     */ 
    const getSession = async () => {
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session || null)
        setSessionJson(session ? JSON.stringify(session) : '')
    }
    const showOrderId = async () => {
        const oid = await getOrderId(sessionJson)
        if (oid) { setOrderId(oid) }
    }

    // ^^ TODO: changed this on 7/2 - didn't test the changes.



    return (
        <>
            <PrintOnlyContent title={true}>Medngine checkout success page</PrintOnlyContent>
            <HeaderBanner
                imageName="doctorpen"
                imageText="PAYMENT SUCCESSFUL"
                imageSpacerColor='BLUE'
                dontChangeTitleOpacity={true}
            />
            <div className='body-container checkout-success-page'>
                <div className='title-order-id'>
                    Order ID: {orderId}
                </div>
                <div className="order-success-message">
                    <div className='message-callout'>
                        Thank you for your order! 
                    </div>
                    An email confirmation will be sent to you shortly.
                    Your email will contain the list of products in your 
                    order, shipment address, and order ID.
                    <br/><br/>
                    Please save the above order ID in case you need to contact
                    us about your order.
                    If you have any issues, contact us by phone or email:
                    <br/><br/>
                    1-844-422-8607<br/>
                    info@regenativelabs.com<br/>
                </div>
            </div>
        </>
    )
}
