import React from 'react';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { Greyback } from '../../../shared/form/greyback/Greyback';
import { Link } from "react-router-dom";
import './study-dashboard.scss';


interface Props { };

export const StudyDashboard = ({ }: Props) => {

  return (
    <>
      <div className="body-container study-dashboard-layout">
        <div className="header">STUDY DASHBOARD PROTEXT</div>
        <div className="directions-area">
          <p>- Initiate a new procedure registration form each time a ProText vial is applied</p>
          <p>- Complete second visit and any additional floow up visits when evaluating parients who do not receive additional ProText applications.</p>
          <p>- Record each new procedure site as a new patient.  This includes bilateral application of ProText.</p>
          <p>- Example: Patient receives ProText in right shoulder defect and left knee defect, surveys should be complete, and data submitted as if they were a seperate patient.</p>
        </div>
        <div className="form-area">
          <div className="uploaded-forms">
            <div className="form1">
              <div className="circlecheck"><div className="checkmark" /></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck"><div className="checkmark" /></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck"><div className="checkmark" /></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck"><div className="checkmark" /></div>
              Lorem Ipsum
            </div>
            <div className="grey-divider" />
            <div className="form1">
              <div className="circlecheck"><div className="checkmark" /></div>
              Lorem Ipsum
            </div>
            <div className="form1 current">
              <div className="circlecheck null current"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="grey-divider" />
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="grey-divider" />
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>
            <div className="form1">
              <div className="circlecheck null"></div>
              Lorem Ipsum
            </div>



          </div>
          <div className="form-vis">
            <Greyback
              greybackStyle="base-content"
              style={{ width: '100%', height: '850px', borderRadius: '3px' }}
              closable={false}
            >
              FORM PLACEHOLDER
            </Greyback>
          </div>

        </div>
        <div className="button">
          <BlockButton
            buttonStyle='desktop-header-buttons purple'
            style={{ width: '130px' }}
          >
            SUBMIT
          </BlockButton>
        </div>
      </div>
    </>
  )
}