import { useEffect, useRef, useState } from "react";
import axios from "axios";


import { Icon } from '../../../shared/icon/Icon';
import HospitalIcon from '../../../../static/img/hospital-icon.png'
import { Link } from "react-router-dom";

import parseErrorObject from "../../../../helpers/parseErrorObject";

import './header-search-input-field.scss';



const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL


// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)


interface Props {
    placeholder: string;
    maxLength?: number;
    minLength?: number;
}

// How long to wait after a pause in typing to start running a search.
const TYPING_PAUSE_SEARCH_TIMEOUT = 500 // ms


/**
 * Search bar in header, with a pseudo search enabled.
 */
export const HeaderSearchInputField = ({placeholder, maxLength, minLength}: Props) => {

    const [searchText, setSearchText] = useState('')
    const [resultsCount, setResultsCount] = useState(0)
    const [resultsBusiness, setResultsBusiness] = useState([]) // list of dicts
    const [resultsClinic, setResultsClinic] = useState([]) // list of dicts
    const [resultsProduct, setResultsProduct] = useState([]) // list of dicts

    const timeout = useRef<any>(null)
    
    // TODO: use real search 
    const runSearch = (_text: string) => {
        if (!_text || !_text.trim()) {
            return
        }
        const text = _text.replace(/\s+/, ' ').trim().toLowerCase()

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('searchWords', text)

        // (fallback on requesting from SIT env when on local dev).
        axios.post(`${API_DB_URL}/admin/memorydb/search`, 
            Object.fromEntries(formData),
            config
        ).then((res) => {
            let searchResultsData = res.data
            console.log('SeaerchResults', searchResultsData)
            setResultsBusiness(res.data?.BUSINESS)
            setResultsClinic(res.data?.CLINIC)
            setResultsProduct(res.data?.PRODUCT)
            setResultsCount(Number(res.data?.BUSINESS?.length + res.data?.CLINIC?.length + res.data?.PRODUCT?.length))
        }).catch(err => {
            console.log('SearchError #5156', parseErrorObject(err))
        })
    }

    // Helper to reset all search resets to empty.
    const clearSearchResults = (timeout?: number) => {
        setTimeout(() => {
            setResultsBusiness([])
            setResultsClinic([])
            setResultsProduct([])
        }, timeout ?? 300)
    }


    // Show search results after pressing Enter.
    const handleKeyPress = (key: string) => {
        if (key === 'Enter') {
            runSearch(searchText)
        } else if (key === 'Escape') {
            clearSearchResults(0)
        }
    }

    // Show search results after a pause in typing.
    const handleChangedInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timeout.current)
        const text = event.target.value
        setSearchText(text)
        
        if (text) {
            timeout.current = setTimeout(() => {
                runSearch(text)
            }, TYPING_PAUSE_SEARCH_TIMEOUT)
        } else {
            // If input is empty, clear the search results and hide the box immediately.
            clearSearchResults(0)
        }
    }

    // When focused, IF there is still search text, run the search.
    const handleOnFocus = () => {
        if (!searchText) {
            clearSearchResults(0)
            return
        }
        runSearch(searchText)
    }

    // When blurred (focus-out), clear the search results.
    const handleOnBlur = () => {
        clearSearchResults(400)
    }


    return (
        <>
        <div className='search-input-field-wrapper'>
            {/* Search input field */}
            <input
                id='search-text-field'
                className='input-field'
                name='search-text'
                placeholder={placeholder}
                type='text'
                maxLength={maxLength}
                minLength={minLength}
                value={searchText}
                onKeyDown={(e) => handleKeyPress(e.key)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangedInput(event)
                }}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
            />

            {/* Search button - magnifying glass icon */}
            <button 
                className='icon-submit dynamic-icon' 
                title="Search"
                onClick={() => runSearch(searchText)}
            >
                <Icon name="magnifying-glass" />
            </button>

            {/* Search results popout */}
            <div className={
                `search-input-results-box ` + 
                `${(resultsBusiness?.length || resultsClinic?.length || resultsProduct?.length) ? 'show' : ''}`
            }>
                
                {/* Search-result meta info */}
                <div className='search-results-box-title-bar'>
                    <div className="search-results-box-title">
                        {(resultsCount > 0) ? (
                            (resultsCount === 1) ? `${resultsCount} result` :  `${resultsCount} results`
                        ) : null}
                    </div>
                    {/* Close button*/}
                    <button 
                        className='search-results-box-close-button'
                        onClick={() => clearSearchResults(0)}
                    >
                        {'\u2716'}
                    </button>
                </div>

                {/* Results that are businesses */}
                {resultsBusiness?.length ? (
                    <>
                    <div className='search-result-section-title'>
                        BUSINESSES
                    </div>
                    {resultsBusiness.map((item, x) => (
                        <Link to={`/public/vendor-profile/${item['{i}:b_id']}`}>

                            <div key={`1-${x}-${item['{i}:b_displayname']}`}
                                className='search-result-row' 
                            >
                                <div className="thumbnail-container">
                                    <img 
                                        className='thumbnail-img' 
                                        src={`${CLOUDFRONT_BASE_URL}/uploads/business/${item['{i}:b_id']}/LOGO_1`} 
                                        title={`${item['{i}:b_displayname'] || item['{i}:b_name']}`}
                                        onError={(event) => {
                                            (event.target as HTMLImageElement).style.display = 'none'
                                        }}
                                    />
                                </div>
                                <div className="business-details">
                                    <div className='business-title'>
                                        {item['{i}:b_displayname'] || item['{i}:b_name']}
                                    </div>
                                    <div className='business-body'>
                                        {item['{i}:b_street']}, {item['{i}:b_city']}, {item['{i}:b_state']} {item['{i}:b_zip']}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                    </>
                ) : null}

                {/* Results that are clinics/doctors */}
                {resultsClinic?.length ? (
                    <>
                    <div className='search-result-section-title'>
                        CLINICS/DOCTORS
                    </div>
                    {resultsClinic.map((item, x) => (

                        // TODO: There is no clinic/doctor page that's been designed.

                        <div key={`2-${x}-${item['{i}:c_displayname'] || item['{i}:c_name']}`}
                            className='search-result-row'
                        >
                            <div className="thumbnail-container">
                                <img 
                                    className='thumbnail-img' 
                                    src={HospitalIcon}
                                    title={`${item['{i}:c_displayname'] || item['{i}:c_name']}}`}
                                    alt='Hospital Image'
                                />
                            </div>
                            <div className="clinic-details">
                                <div className='clinic-title'>
                                    {item['{i}:c_displayname'] || item['{i}:c_name']}
                                </div>
                                <div className='clinic-subtitle'>
                                    {item['{i}:u_title']} {item['{i}:u_firstname']} {item['{i}:u_middlename']} {item['{i}:u_lastname']}
                                </div>
                                <div className='clinic-body'>
                                    {item['{i}:c_street1']} {item['{i}:c_street2']} / {item['{i}:c_city']}, {item['{i}:c_state']} {item['{i}:c_zip']}
                                </div>

                            </div>
                        </div>
                    ))}
                    </>
                ) : null}

                {/* Results that are products */}
                {resultsProduct?.length ? (
                    <>
                    <div className='search-result-section-title'>
                        PRODUCTS
                    </div>
                    {resultsProduct.map((item, x) => (
                        <Link to={`/public/products/${item['{i}:p_id']}`}>
                            <div key={`1-${x}-${item['{i}:p_displayname']}`} 
                                className='search-result-row'
                            >
                                <div className="thumbnail-container">
                                    <img 
                                        className='thumbnail-img' 
                                        src={`${CLOUDFRONT_BASE_URL}/uploads/product/${item['{i}:p_id']}/PRODUCT_1`} 
                                        title={`${item['{i}:p_displayname'] || item['{i}:p_name']}} (${item['{i}:p_brand']})`}
                                        alt='Product Image'
                                        onError={(event) => {
                                            (event.target as HTMLImageElement).style.display = 'none'
                                        }}
                                    />
                                </div>
                                <div className="product-details">
                                    <div className='product-title'>
                                        {item['{i}:p_displayname'] || item['{i}:p_name']}
                                    </div>
                                    <div className='product-body'>

                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                    </>
                ) : null}
                

            </div>
        </div>
        </>
    );
};