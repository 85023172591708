import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { InputField } from "../../../shared/form/input-field/InputField";
import { TextAreaField } from "../../../shared/form/text-area/TextAreaField";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import { STATES } from "../../../../constants/states";
import "./tag-doctor.scss";




export const TagDoctor = () => {

  const statesAbbreviationsList: SelectOption[] = [
    { option: ' ', value: null },
    ...STATES.map(state => ({ option: state.abbreviation, value: state.abbreviation }))
  ]


  return (
    <div className="tag-doctor-layout">
      <div className="body-container ">
        <div className="multicolor-headers">
          <div className="header small blue">BUILD</div>
          <div className="header small">/ TAG DOCTOR</div>
        </div>
        <div className="teal-display-container">
          <div className="teal-box">
            <span className="display-text">Number of Doctors Tagged Successfully</span>
            <span className="display-number">1 OF 60</span>
          </div>
          <div className="teal-box">
            <span className="display-text">Total Number of Doctor Tags</span>
            <span className="display-number">3</span>
          </div>
          <div className="teal-box">
            <span className="display-text">Number of Doctor Tags with No Orders</span>
            <span className="display-number">3</span>
          </div>
        </div>


        <div className="information-container">
          <div className="information-box">
            <div className="header blue">DOCTOR INFORMATION</div>
            <div className="text-required-wrap">
              <span className="information-text">Doctor NPI </span>
              <span> (required)</span>
            </div>
            <InputField required name='doctor-npi' />
            <span className="span-padding">The doctor's personal NPI (should be different from Clinic NPI).</span>


            <div className="text-required-wrap">
              <span className="information-text">Doctor First Name</span>
              <span> (required)</span>
            </div>
            <InputField required name='doctor-firstname' />


            <span className="information-text">Doctor Middle Name</span>
            <InputField name='doctor-middlename' />


            <div className="text-required-wrap">
              <span className="information-text">Doctor Last Name</span>
              <span> (required)</span>
            </div>
            <InputField required name='doctor-lastname' />


            <span className="information-text">Specialty</span>
            <InputField name='doctor-specialty' />


            <span className="information-text">Mobile Phone</span>
            <InputField name='doctor-mobilephone' />


            <span className="information-text">Clinic Phone</span>
            <InputField name='doctor-clinicphone' />

            <div className="button-container">
              <BlockButton buttonStyle='desktop-header-buttons white'>
                <div className="button-inject">
                  <div className="circle">
                    <div className="plus" />
                  </div>
                  ADD DOCTOR
                </div>
              </BlockButton>
            </div>

          </div>

          <div className="information-box">
            <div className="header blue">CLINIC INFORMATION</div>
            <div className="text-required-wrap">
              <span className="information-text">Clinic NPI </span>
              <span> (required)</span>
            </div>
            <InputField required name='clinic-npi' />
            <span className="span-padding">The clinic NPI must be unique to each account</span>


            <div className="text-required-wrap">
              <span className="information-text">Clinic Name</span>
              <span> (required)</span>
            </div>
            <InputField required name='clinic-name' />


            <span className="information-text">DEA Number</span>
            <InputField name='clinic-dea' />


            <span className="information-text">Clinic Admin Middle Name</span>
            <InputField required name='clinic-adminmiddlename' />


            <span className="information-text">Clinic Admin Last Name</span>
            <InputField name='clinic-adminlastname' />


            <span className="information-text">Bill/Receipt Email</span>
            <InputField name='clinic-billemail' />
            <span className="span-padding">Doctor's email will be used if not provided</span>


            <div className="text-required-wrap">
              <span className="information-text">Clinic Note</span>
              <span> (Public)</span>
            </div>
            <TextAreaField name="clinic-notepublic" style={{ minHeight: '80px', maxHeight: '150px', height: 'calc(80% - 2rem)', resize: 'none' }} />


            <div className="text-required-wrap">
              <span className="information-text">Clinic Note</span>
              <span> (Private)</span>
            </div>
            <TextAreaField name="clinic-noteprivate" style={{ minHeight: '80px', maxHeight: '150px', height: 'calc(80% - 2rem)', resize: 'none' }} />

          </div>

          <div className="information-box">
            <div className="header blue">SHIPPING ADDRESS</div>
            <div className="text-required-wrap">
              <span className="information-text">Company</span>
              <span> (optional)</span>
            </div>
            <InputField required name='doctor-npi' />
            <span className="white-space">This is for temporary white space padding</span>


            <span className="information-text">Address Line 1</span>
            <InputField required name='shipping-address1' />


            <span className="information-text">Address Line 2</span>
            <InputField name='shipping-address2' />


            <span className="information-text">City</span>
            <InputField required name='shipping-city' />


            <div className="state-zip-wrapper">
              <div className="state-wrapper">
                <span className="information-text">State</span>
                <SelectField
                  name='shipping-state'
                  options={statesAbbreviationsList}
                />
              </div>


              <div className="zip-wrapper">
                <span className="information-text">Zip Code</span>
                <InputField name='shipping-zip' />
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
  )
}