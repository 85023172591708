import { Route, Routes } from 'react-router-dom'
import { DoctorRegistration1 } from './DoctorRegistration1'
import { DoctorRegistration2 } from './DoctorRegistration2'
import { DoctorRegistration3 } from './DoctorRegistration3'
import { DoctorInvitation } from './DoctorInvitation'
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent'


export const DoctorRegistration = () => {

    return (
        <>
            <PrintOnlyContent title={true}>Medngine doctor registration pages</PrintOnlyContent>
            <Routes>
                <Route path='1' element={<DoctorRegistration1 />} />
                <Route path='2' element={<DoctorRegistration2 />} />
                <Route path='3' element={<DoctorRegistration3 />} />
                {/* <Route path='7' element={<DoctorInvitation />} /> */}
            </Routes>
        </>
    )
}