import { Route, Routes } from 'react-router-dom'
import { ConsumerRegistration1 } from './ConsumerRegistration1'
import { ConsumerRegistration2 } from './ConsumerRegistration2'



export const ConsumerRegistration = () => {

  return (
    <>
      <Routes>
        <Route path='1' element={<ConsumerRegistration1 />} />
        <Route path='2' element={<ConsumerRegistration2 />} />
      </Routes>
    </>
  )
}