
import { ProductCategory, ProductCategoryInfo } from "./ProductCategory";
import { ProductPrice, ProductPriceInfo } from "./ProductPrice";



interface IProduct {
    get id(): number;
    get prices(): ProductPrice[];
    get categories(): ProductCategory[];
    get productType(): string;
    get createdDate(): Date;
    get deactivationDate(): Date|null;
    get dose(): number;
    get weight(): number;
    get vendor(): string;
    get brand(): string;
    get description(): string;
    get image(): string;
    get name(): string;
    get sku(): string;
};


export type ProductInfo = {
    brand: string,
    description: string,
    image: string,
    vendor: string,
    name: string,
    sku: string,
    id: number,
    Prices: ProductPriceInfo[],
    categories: ProductCategoryInfo[],
    product_type: {id: number, type: string},
    created_date: string,
    deactivation_date: string,
    dose: number,
    weight: number,
};


export class Product implements IProduct {
    private _vendor: string;
    private _brand: string;
    private _description: string;
    private _image: string;
    private _name: string;
    private _sku: string;
    private _id: number;
    private _prices: ProductPrice[];
    private _categories: ProductCategory[];
    private _productType: string;
    private _createdDate: Date;
    private _deactivationDate: Date|null;
    private _dose: number;
    private _weight: number;

    constructor(product: ProductInfo) {        
        this._brand = product.brand;
        this._description = product.description;
        this._image = product.image;
        this._vendor = product.vendor;
        this._name = product.name;
        this._sku = product.sku;
        this._id = product.id;
        this._prices = product.Prices.map(price => new ProductPrice(price));
        this._categories = product.categories.map(category => new ProductCategory(category));
        this._productType = product.product_type.type;
        this._createdDate = new Date(product.created_date);
        this._deactivationDate = (product.deactivation_date) ? new Date(product.deactivation_date) : null;
        this._dose = product.dose;
        this._weight = product.weight;
    }


    get id(): number {
        return this._id;
    }
    get prices(): ProductPrice[] {
        return this._prices;
    }
    get categories(): ProductCategory[] {
        return this._categories;
    }
    get productType(): string {
        return this._productType;
    }
    get createdDate(): Date {
        return this._createdDate;
    }
    get deactivationDate(): Date|null {
        return this._deactivationDate;
    }
    get dose(): number {
        return this._dose;
    }
    get weight(): number {
        return this._weight;
    }
    get vendor() {
        return this._vendor;
    }
    get brand() {
        return this._brand;
    }
    get description() {
        return this._description;
    }
    get image() {
        return this._image;
    }
    get name() {
        return this._name;
    }
    get sku(): string {
        return this._sku
    }
}