import React from "react";
import { Route, Routes } from "react-router-dom";
import { AboutPage } from './about/About';
import { CategoryPage } from './category/Category';
import { ContactPage } from './contact/Contact';
import { DbTest } from './DbTest';
import { DoctorProfilePage } from './doctor-profile/DoctorProfile';
import { HomePage } from './home/Home';
import { VendorProfilePage } from './vendor-profile/VendorProfile';
import { ProductPage } from "./product-detail/ProductPage";
import { SearchResultsPage } from "./search-results/SearchResults";


export const Public = () => {

	return (
		<>
			<Routes>
				<Route path='/about' element={<AboutPage />} />
				<Route path='/category' element={<CategoryPage />} />
				<Route path='/contact' element={<ContactPage />} />
				<Route path='/doctor-profile' element={<DoctorProfilePage />} />
				<Route path='/home' element={<HomePage />} />
				<Route path='/vendor-profile/:businessId' element={<VendorProfilePage />} />
				<Route path='/search-results' element={<SearchResultsPage />} />
				<Route path='/products/:productId' element={<ProductPage />} />
				{/* Test table for db/RDS */}
				<Route path='/dbtest' element={<DbTest />} />
			</Routes>
		</>
	);
}