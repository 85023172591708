/**
 * Image-upload grey box that will show a preview of the image that was uploaded.
 * 
 * If user has already uploaded an image for a given field, it will be displayed.
 * 
 * Existing images are downloaded from Cloudflare, but new images are pushed to and
 * downloaded from S3 (for speed).
 */

import React, { createRef, MutableRefObject, useEffect, useState, useRef } from 'react';
import axios from 'axios'

import VendorUploadImg from "../../../../static/img/upload-vendor-icon.png"
import LoadingCircle from '../../../../static/gif/loading-circle.gif'

import { SessionPacket } from '../../../../types/session';
import { VALID_IMG_NAMES, VALID_UPLOAD_TYPES } from '../../../../../config'

import './input-image-field.scss'



interface Props {
    uploadType: VALID_UPLOAD_TYPES; // required!
    imageName: VALID_IMG_NAMES; // required!
    businessId: number; // required!
    productId?: number; // required when `uploadType` is for product images.
    imageStyle: React.CSSProperties; // required!
    name: string;
    setImageErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    sessionObj: SessionPacket|null;
    setSessionObj: React.Dispatch<React.SetStateAction<SessionPacket|null>>;
    setHideAllErrors?: React.Dispatch<React.SetStateAction<boolean>>;
    getUsernameAndIdToken: Function;
    title?: string;
    desc?: string;
    required?: boolean;
    wrapperStyle?: React.CSSProperties;
    onChange?: Function;
    onImgLoadSuccess?: Function;
    onImgLoadError?: Function;
    // `expectImage` is a config option used for VendorReg Step 5 (AddProductModal)
    // - If `true`, show loading spinner until error/success. On error show default background 
    //   that says "Upload...(etc)".
    // - `true` value gives it an alternate logic flow for showing loading spinner, bg img, etc.
    expectImage?: boolean;
    // The primary image of card is the `PRODUCT_1` photo for the product, this state variable tells
    // the parent (VendorRegistration5) when to cache-break img src to use for the card image.
    setPrimaryImgChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    // Display stock images if user is in registration "preview mode".
    isPreviewMode?: boolean|null;
}


// Environment vars
const _ENV = process.env.REACT_APP__ENV
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL

// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)

// TODO: centralize this
const S3_BASE_URL = (
    _ENV?.includes('local') ? 'https://mr-s3-public-local.s3.amazonaws.com' :
    _ENV === 'sit' ? 'https://mr-s3-public-sit.s3.amazonaws.com' :
    _ENV === 'dev' ? 'https://mr-s3-public-dev.s3.amazonaws.com' :
    _ENV === 'prod' ? 'https://mr-s3-public-prod.s3.amazonaws.com' :
    '' // no fallback
)





export const InputImageField = ({
    uploadType, imageName,
    businessId, productId,
    name, title, desc, required, wrapperStyle, imageStyle, 
    sessionObj, setSessionObj,
    setHideAllErrors,
    setImageErrorMessage, 
    getUsernameAndIdToken,
    onChange,
    onImgLoadSuccess,
    onImgLoadError,
    expectImage,
    setPrimaryImgChanged,
    isPreviewMode,
}: Props) => {
    // Get CloudFront and S3 urls specific to this image.
    const CLOUDFRONT_IMG_URL = (
        (isPreviewMode && uploadType === VALID_UPLOAD_TYPES.IMG_BUSINESS) ? 
            `${CLOUDFRONT_BASE_URL}/uploads/business/0/${imageName}` :
        (isPreviewMode && uploadType === VALID_UPLOAD_TYPES.IMG_PRODUCT) ? 
            `${CLOUDFRONT_BASE_URL}/uploads/product/0/${imageName}` :
        uploadType === VALID_UPLOAD_TYPES.IMG_BUSINESS ? 
            `${CLOUDFRONT_BASE_URL}/uploads/business/${businessId}/${imageName}` :
        uploadType === VALID_UPLOAD_TYPES.IMG_PRODUCT ? 
            `${CLOUDFRONT_BASE_URL}/uploads/product/${productId}/${imageName}` :
        'invalidImgType'
    )
    const S3_IMG_URL = (
        (isPreviewMode && uploadType === VALID_UPLOAD_TYPES.IMG_BUSINESS) ? 
            `${S3_BASE_URL}/uploads/business/0/${imageName}` :
        (isPreviewMode && uploadType === VALID_UPLOAD_TYPES.IMG_PRODUCT) ? 
            `${S3_BASE_URL}/uploads/product/0/${imageName}` :
        uploadType === VALID_UPLOAD_TYPES.IMG_BUSINESS ? 
            `${S3_BASE_URL}/uploads/business/${businessId}/${imageName}` :
        uploadType === VALID_UPLOAD_TYPES.IMG_PRODUCT ? 
            `${S3_BASE_URL}/uploads/product/${productId}/${imageName}` :
        'invalidImgType'
    )

    // If this is first img, loads faster via Cloudfornt. Uploaded images load faster with S3.
    const [isFirstImage, setIsFirstImage] = useState(true)
    // Display flag that activates on successful img load.
    const [displayImg, setDisplayImg] = useState(false)
    // Image lifecycle. When uploading a new image, all of these are turned on in sequence.
    const [imageChosen, setImageChosen] = useState(false) // Step 1
    const [imageUploaded, setImageUploaded] = useState(false) // Step 2
    const [imageDownloading, setImageDownloading] = useState(true) // Step 3
    const [imageLoaded, setImageLoaded] = useState(false) // Step 4
    // Image status & error messaging
    const [imageError, setImageError] = useState(true)
    const [greyBoxMessageOverride, setGreyBoxMessageOverride] = useState('')
    // Whether the most recent image has loaded into the <img> element successfully.
    const [imageLoadSuccess, setImageLoadSuccess] = useState<boolean|null>(null)
    
    // The image input field and the <img> object, respectively.
    const inputRef = createRef<HTMLInputElement>()
    const imgRef: MutableRefObject<null|HTMLImageElement> = useRef(null)


    // Apparently this is the only way to add the "cache-control" attribute to an <img> tag in React.
    useEffect(() => {
        imgRef.current?.setAttribute("cache-control", "no-cache");
    }, [])

    // Reset some vars whenever productId changes
    useEffect(() => {
        // This is for image loading spinner to work correctly when `expectImage=true`.
        setImageLoadSuccess(null)
    }, [productId])

    // Upon clicking the transparent button overlay, trigger a click on the actual <input> element
    // so user can select an image to upload.
    const onUploadImageButtonClick = () => {
        if (isPreviewMode !== false) return
        if (inputRef.current) { // There might be a bug with the optional chaining (".?") notation.
            inputRef.current.click()
        }
    }

    // Run chain of events when user selects new image.
    const onSelectNewImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isPreviewMode !== false) return
        
        // Initialize triggers
        setImageChosen(true)
        setImageUploaded(false)
        setImageDownloading(false)
        setImageLoaded(false)
        setImageError(false)
        setGreyBoxMessageOverride('Checking session...')

        // Make a form with only the image in it.
        const formData = new FormData();
        if (event.target.files) {
            formData.append('upl-image', event.target.files[0]);
        }
        
        // Get user's session info and add to formData, to validate user/biz before any uploading.
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        const sessionJson = JSON.stringify(session)
        
        const config = {'headers': {
            'content-type': 'multipart/form-data',
            'x-mr-auth': sessionJson,
            'x-mr-img-name': event.target.getAttribute('data-imagename') || '',
            'x-mr-upload-type': uploadType,
            'x-mr-product-id': String(productId), // this will only have a value if upload type if for product-image.
        }}

        setGreyBoxMessageOverride('Uploading...')

        // Upload BUSINESS image to S3 (not product images)
        axios.post(`${API_IMG_URL}/upload/img/business`, 
            formData,
            config
        ).then((res) => {
            console.log('[img uploaded]')
            setImageErrorMessage('')
            setImageChosen(false)
            setImageUploaded(true)
            setImageDownloading(true)
            setGreyBoxMessageOverride('Generating preview...')
        }).catch(e => {
            console.log('[img upload failed]')
            setImageChosen(false)
            if (setHideAllErrors) setHideAllErrors(false)
            setGreyBoxMessageOverride('Error')
            console.log('e#3561b -->')
            console.log(e)
            let eName = String(e.response?.data?.name || e.response?.name || e.name || 'Error')
            let eMessage = String(e.response?.data?.message || e.response?.message || e.message || e.data || '#6713b')
            // Make 413 error more user friendly.
            if (eMessage.includes('Request failed with status code 413')) {
                // TODO: use a centralized constant for this!
                eMessage = 'File size must be 10 MB or less'
            }
            setTimeout(() => {
                setImageErrorMessage(`${eName}: ${eMessage} (#8204b)`)
            }, 350)
        })
    } 

    // Use a cache breaker because <img>'s `src` will never change, even if a new image is added.
    const imgCacheBreaker = () => {
        if (imgRef.current) {
            imgRef.current.src = imgRef.current.src.split('?')[0] + '?time=' + String(Date.now())
            setImageUploaded(false)
            setImageDownloading(true)
            setImageChosen(false) // unset
        }
    }

    // Triggered once we get success response from S3 that image has uploaded.
    useEffect(() => {
        if (imageUploaded) {
            // setImageLoaded(false)
            setImageChosen(false)
            setIsFirstImage(false)
            // Needs a short delay before running cache breaker.
            setTimeout(() => {
                imgCacheBreaker()
            }, 0)
            // Let the parent know that the image has changed, so parent can run its own 
            // cache-breaker for the product-card display of this product's primary image (for
            // example for Vendor Registration Step 5 - AddProductModal).
            if (setPrimaryImgChanged) {
                setPrimaryImgChanged(true)
            }
        }
    }, [imageUploaded])

    



    return (
        <>
        <div className='img-input-wrapper' style={wrapperStyle}>
            {/* The default backround when no image is present */}
            {(
                (!expectImage && !imageChosen && !imageDownloading && !imageLoaded) 
                || (expectImage && imageLoadSuccess === false)
            ) ? (
                <>
                <div className='upper'>
                    <img src={VendorUploadImg} className="background-image" /> 
                </div>
                <div className='lower'>
                    <div className='title'>
                        {title || 'Upload'}
                    </div>
                    <div className='desc'>
                        {desc || 'Image'}
                    </div>
                </div>
                </>
            ): null}

            {/* The actual user's image - will be prepopulated with an image if user already
                has an uploaded image on S3/CF. */}
            {/* Without this guard, this will try get img for businessId=0 and fail on mount. */}
            {(isPreviewMode || businessId) ? (
                <div className='real-image-container'>
                    <img 
                        ref={imgRef}
                        className='s3image-img'
                        src={(isFirstImage ?  CLOUDFRONT_IMG_URL : S3_IMG_URL + `?time=${Date.now()}`)}
                        style={{...imageStyle, display: (displayImg && !imageError) ? 'block' : 'none'}}
                        onLoad={() => {
                            setImageLoadSuccess(true)
                            if (imageDownloading) {
                                setGreyBoxMessageOverride('')
                                setImageDownloading(false)
                            }
                            setImageLoaded(true)
                            setImageError(false)
                            setDisplayImg(true)
                            if (onImgLoadSuccess) {
                                onImgLoadSuccess()
                            }
                        }}
                        onError={(e) => {
                            setImageLoadSuccess(false)
                            if (!isFirstImage) {
                                setImageChosen(false)
                            }    
                            setImageUploaded(false)
                            setImageLoaded(false)
                            setDisplayImg(false)
                            setImageDownloading(false)
                            setGreyBoxMessageOverride('')
                            if (onImgLoadError) {
                                onImgLoadError(e)
                            }
                        }}
                    />

                    {/* Loading circle */}
                    {(  (isPreviewMode === false) && (
                            (!expectImage && (imageChosen || imageDownloading) && !imageError) 
                            || (expectImage && imageLoadSuccess === null)
                        )
                    ) ? (
                        <img src={LoadingCircle} className='img-loading-circle' />
                    ) : null}
                </div>
            ) : null}

            {/* Hidden image/file input */}
            <input
                data-imagename={imageName}
                ref={inputRef}
                id={name}
                name={name}
                type='file'
                // TODO: some possible options to test:
                //      .gif, .bmp, .webp, .tiff, .svg
                //  accept='image/*' will also allow for phone photos 
                // Tested & working on Chrome:
                //  - png, jpg, jpeg, bmp, webp, avif, gif
                //  - NOT WORKING: tiff, svg, heic (iphone format)
                accept='.png,.jpg,.jpeg,.gif,.avif,.bmp,.webp'
                required={required ?? false}
                className='img-input'
                onChange={(e) => {
                    onSelectNewImage(e)
                    if (onChange) {
                        onChange()
                    }
                }}
            />
            
            {/* Clickable transparent button overlay that will trigger the <input> click. */}
            {isPreviewMode === false ? (
                <button
                    type='button'
                    className='input-button'
                    onClick={onUploadImageButtonClick}
                />
            ) : null}

            {/* Bottom message & loading spinner */}
            {(
                (!expectImage && (imageChosen || imageDownloading || imageLoaded))
                || (expectImage && imageLoadSuccess !== false)
            ) ? (
                (isPreviewMode && uploadType?.toLowerCase().includes('product') ? (
                    // Don't show this for products modal in "preview mode" cus it's buggy.
                    // (But DO show it on Vendor/Supplier registration step 4).)
                    null
                ): (
                    <div className={`bottom-note-overlay`}>
                        <>
                        {(
                            (!expectImage && (imageChosen || imageDownloading))
                            || (expectImage && imageLoadSuccess === null)
                        ) ? (
                            greyBoxMessageOverride || 'Loading...'
                        ) : ((imageLoaded) ? (
                            greyBoxMessageOverride || (
                                imageName.includes('BANNER_') ? 
                                    'Preview: Banner images are set to 100% width and aligned to the top' :
                                imageName.includes('LOGO_') ? 
                                    'Logo preview' : 
                                    'Preview'
                        )) : (
                            greyBoxMessageOverride || ''
                        ))}
                        </>
                    </div>
                ))
            ) : '' }
        </div>
        </>
    )
}