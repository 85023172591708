import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton'
import { InputField } from '../../../shared/form/input-field/InputField'
import { SelectField, SelectOption } from '../../../shared/form/select-field/SelectField'
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner"
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage'
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar"
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject'

import LoadingCircle from '../../../../static/gif/loading-circle.gif'

import { STATES } from '../../../../constants/states'

import './vendor-registration.scss'



export const VendorRegistration2 = () => {
    const STEP = 2
    let STATE_DROPDOWN_OPTIONS: SelectOption[] = STATES.map(state => (
        { option: state.abbreviation, value: state.abbreviation }
    ))
    STATE_DROPDOWN_OPTIONS.unshift({ option: 'State', value: 'State' })

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Input fields
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [addrStateIndex, setAddrStateIndex] = useState(0)
    const [phone, setPhone] = useState('')
    const [website, setWebsite] = useState('')
    // Modal to show loading status.
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Pre-load spinner
    const [showPreloadSpinner, setShowPreloadSpinner] = useState(true)

    // Session data
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [hasDbData, setHasDbData] = useState<boolean|null>(null)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()

    // Auth/session functions
    const { checkLogin, checkLoginNow, getUsernameAndIdToken } = useContext(AccountContext)

    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Message based on referrer
        setReferrerMessage(
            qp.reason === 'reg-incomplete' ? 'Please complete your registration' :
            qp.referrer?.includes('registration') ? 'Continue your registration below' :
            ''
        )

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Try to prefill user info. This will return empty object if user isn't logged in.
        getPrefilledData()
    }, [])

    // If in preview mode.
    useEffect(() => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
        }
    }, [isPreviewMode])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (isPreviewMode !== false || !checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/vendor-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin, isPreviewMode])

    /**
     * Pre-fill information if user completed this step already.
     */
    const getPrefilledData = async () => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { return }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL
        const formData = new FormData() 
        formData.append('session', sessionJson)
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/get-vendor-data/step/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            console.log(data)
            setStreet1(data.street1)
            setStreet2(data.street2)
            setCity(data.city)
            // Address-state needs to be the index in the dropdown list.
            setAddrStateIndex(STATES.findIndex(obj => obj.abbreviation === data.state) + 1)
            setZip(data.zip)
            setPhone(data.phone)
            setWebsite(data.website)
            // Mark that data was retrieved
            setHasDbData(!!data.street1)
        }).catch(async (err) => {
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8802bG'))
            }, 350)
        }).finally(() => {
            setShowPreloadSpinner(false)
        })
    }

    // Validate inputs (Placeholder for basic frontend validation before submitting to backend).
    // TODO: ^ (but not super important since backend already validates it, and react form
    //       has SOME auto validators built in)
    const frontendValidationErrors = () => {
        let errorCount = 0
        return errorCount
    }

    /** 
     * Submit regisration Step 2 data db to continue user creation.
     */
    const onSubmitRegistrationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setShowSpinner(true)
        setSpinnerText('Validating')

        // Run frontend validation.
        if (frontendValidationErrors()) { 
            setShowSpinner(false)
            return 
        }

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            setHideAllErrors(false);
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        /** Config for API call. */
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL

        /** Form data */
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)
        // formData.append('username', session.username)
        // formData.append('idToken', session.idToken)
        // Replace state index with actual state abbreviation.
        const stateAbbr = STATE_DROPDOWN_OPTIONS[Number(formData.get('state'))].option.toUpperCase()
        formData.set('state', stateAbbr)
        
        /**
         * If data should be UPDATED in DB as opposed to created.
         */
        if (hasDbData) {
            axios.post(`${API_DB_URL}/update-vendor-data/${STEP}`, 
                Object.fromEntries(formData), 
                config,
            ).then(async () => {
                const qpOut = {
                    reason: 'updated',
                }
                if (isEdit) {
                    window.location.href = buildPath(`/account`, qpOut)
                } else {
                    window.location.href = buildPath(`/vendor-registration/${STEP+1}`, qpOut)
                }
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802aGU'))
                }, 350)
            })
        }

        /** Submit to db api */
        if (!hasDbData) {
            axios.post(`${API_DB_URL}/new-user-init-vendor/${STEP}`, 
                Object.fromEntries(formData), config
            ).then(async (res) => {
                // Success
                setHideAllErrors(true)
                setSpinnerText('Confirmed!')
                setTimeout(() => {
                    setShowSpinner(false)
                    window.location.href = `/vendor-registration/${STEP+1}`
                }, 800)
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8803aGU'))
                }, 350)
            })
        }
    }



    return (
        <div>
            <HeaderBanner
                imageName="doctorpen"
                imageText={isEdit ? 'EDIT BUSINESS DETAILS' : "VENDOR APPLICATION"}
                imageSpacerColor='BLUE'
            />
            <RegistrationLoadingModal
                show={showSpinner}
                setShow={setShowSpinner}
                titleText={spinnerText}
            />

            <PreviewModeMessage show={!!isPreviewMode} link={'/vendor-registration/1'} />

            <div className="vendor-register-page-layout">
                {!isEdit ? (
                    <p className="body-container top-paragraph">
                        test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 
                        test test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test test text 1 
                        test text 1 test text 1 test text 1 test text 1 test text 1 test test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 
                    </p>
                ) : null}

                {/* Show different messages based on how the user got here */}
                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                {!isEdit ? (
                    <div className="progress-bar-placement">
                        <ProgressBar whichStep={2} numberOfSteps={5} />
                    </div>
                ) : null}

                {/* Only show the form after checking backend for pre-fill data. */}
                <div 
                    className="body-container form-layout"
                    style={{ display: (showPreloadSpinner ? 'none' : 'block') }}
                >
                    <form onSubmit={onSubmitRegistrationForm}>
                        <div className="header2 p2">
                            BUSINESS INFORMATION
                        </div>
                        <div className='form-wrapper-top p2'>
                            <InputField
                                required
                                id="vendor-registration-streetaddress"
                                name='streetAddress1'
                                placeholder='Street Address'
                                value={street1}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setStreet1(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                id="vendor-registration-streetaddressdetails"
                                name='streetAddress2'
                                placeholder='Suite. Bldg, etc'
                                value={street2}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setStreet2(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className='form-wrapper-uppermid p2'>
                            <InputField
                                required
                                id="vendor-registration-city"
                                name='city'
                                placeholder='City'
                                value={city}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCity(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className='form-wrapper-mid p2'>
                            <SelectField
                                style={{ width: '160px' }}
                                required
                                id="vendor-registration-state"
                                name='state'
                                options={STATE_DROPDOWN_OPTIONS}
                                selectedIndex={addrStateIndex}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setAddrStateIndex(Number(event.target.value))
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                style={{ width: '200px' }}
                                required
                                id="vendor-registration-zip"
                                name='zip'
                                placeholder='Zip Code'
                                value={zip}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setZip(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className='form-wrapper-bottom p2'>
                            <InputField
                                required
                                id="vendor-registration-phone"
                                name='phone'
                                type='tel'
                                placeholder='Phone Number'
                                value={phone}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPhone(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                id="vendor-registration-webaddress"
                                name='website'
                                placeholder='Website Address'
                                value={website}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setWebsite(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            {/* TODO: this was on design doc but doesn't seem to make sense here.
                              *   - Check this later if we want to add it back in.
                              */}
                            {/* <TextAreaField
                                required
                                id="vendor-registration-productofferings"
                                name="productOffering"
                                placeholder='What are your core product offerings?'
                                style={{ minHeight: '170px', height: 'calc(100% - 2rem)', resize: 'none' }}
                            /> */}
                        </div>

                        {/* Error messages */}
                        {(!hideAllErrors && errorMessage) ? (
                            <div className='error-message'>
                                {errorMessage}
                            </div>
                        ): null }

                        <div className='buttons-container'>
                            {isEdit ? (
                                <BlockButton
                                    type='button'
                                    style={{ width: '250px' }}
                                    buttonStyle="ghost-purple"
                                    onClick={() => window.location.href = '/account'}
                                >
                                    CANCEL
                                </BlockButton>
                            ) : (
                                <Link to={`/vendor-registration/${STEP - 1}${isPreviewMode ? '?mode=preview' : ''}`}>
                                    <BlockButton style={{ width: '250px' }} buttonStyle="ghost-purple">
                                        BACK
                                    </BlockButton>
                                </Link>
                            )}

                            {(!isPreviewMode && !isEdit) ? (
                                <BlockButton 
                                    type='submit'
                                    style={{ width: '250px' }} 
                                    buttonStyle="purple"
                                >
                                    CONTINUE
                                </BlockButton>
                            ) : (isEdit ? (
                                <BlockButton
                                    type='submit'
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                >
                                    SAVE
                                </BlockButton>
                            ) : (
                                <BlockButton
                                    type='button'
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                    onClick={() => {
                                        window.location.href=`/vendor-registration/${STEP + 1}?mode=preview`
                                    }}
                                >
                                    NEXT
                                </BlockButton>
                            ))}
                        </div>

                        <ToastContainer 
                            hideProgressBar={true}
                            position='bottom-center'
                            style={{textAlign: 'center', cursor: 'default'}}
                        />
                    </form>
                </div>

                {/* If form is not ready to show yet, show the spinner */}
                {showPreloadSpinner ? (
                    <img src={LoadingCircle} className='preload-spinner' />
                ) : null}
            </div>
        </div>
    )
}