import { UserAction } from "../actions/user-actions"
import { ReducerAction } from "./_types"


export const user = (userInfo = null, action: ReducerAction): any => {
    switch (action.type) {
        case UserAction.GET_USER:
            return userInfo ? userInfo : action.payload
        default:
            return userInfo
    }
}