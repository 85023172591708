import React from 'react';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { Table } from "../../../shared/tables/Table";
import { ProductsList } from '../../public/products-list/ProductsList';
import { Link } from "react-router-dom";
import CircleChart from "./circle-chart.png";
import './physician-dashboard.scss';


//    TODO:   add Search Bar on top of Recent Orders Grid, fix pagination
//    TODO:   make working circlechart
//    TODO:   fix dropdown position


type Props = {};

type State = {};


export class PhysicianDashboard extends React.Component<Props, State> {


  mockDataTable = [
    { orderNumber: "151435411", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435412", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435413", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435414", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435415", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435416", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435417", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435418", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435419", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435421", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435422", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435423", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435424", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435425", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435426", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435427", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435428", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435429", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435430", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435431", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
  ]

  render() {
    return (
      <div className="physician-dashboard-layout">
        <div className="expansion-container">
          <details>
            <summary className="body-container-no-vert-pad">
              MEN'S HEALTH MEDICINE SPECIALIST
              <span className="arrow" />
            </summary>
            <div className="expansion-box">
              <div className="body-container salesnumbers-container">
                <div className='section'>
                  <div className='data'>$89,021.00</div>
                  <div className='title'>CURRENT BALANCE</div>
                  <div className="button"><BlockButton
                    buttonStyle='desktop-header-buttons purple'
                    style={{ width: '270px' }}
                  >
                    PAY BALANCE
                  </BlockButton>
                  </div>
                </div>
                <div className='section'>
                  <div className='data'>$11,000.00</div>
                  <div className='title'>AVAILABLE CREDIT</div>
                  <div className="button"><BlockButton
                    buttonStyle='desktop-header-buttons purple'
                    style={{ width: '270px' }}
                  >
                    REQUEST CREDIT INCREASE
                  </BlockButton>
                  </div>
                </div>
                <div className='section chart'>
                  <img src={CircleChart} />
                </div>
              </div>
            </div>
          </details>
        </div>

        <div className='body-container'>
          <div className="recent-orders-wrapper">
            <div className="header orders">RECENT ORDERS</div>
            <InputField
              required
              style={{ width: '700px', marginTop: '30px' }}
              name='vendorfirst'
              placeholder='Search by Order Number, Product, etc.'
            />
            <Table
              headerStyle="vendordash-header-cols"
              gridType="vendordash-container"
              tableStyle="vendordash-items-fill"
              style={{ marginTop: '27px' }}
              visibleRows={4}
              data={this.mockDataTable}
              columnNames={["Order Number", "Date", "ORDERED BY", "AMOUNT", "Order Link"]}
              pagingOption="paging-arrow"
            />
          </div>
          <div className="products-wrapper">
            <div className="header products">NEW PRODUCTS</div>
            <div className="body-container-no-vert-padding">
              <ProductsList condensed={true} />
            </div>
          </div>
          <div className="header">EVENTS</div>
          <div className='events-wrapper'>
            <div className='events-gallery'>
              <div className='event clickable main-event'>
                <div className='bg'></div>
                webinar / Event
              </div>
              <div className='sub-events'>
                <div className='event clickable'>
                  <div className='bg'></div>
                  webinar / Event
                </div>
                <div className='event clickable'>
                  <div className='bg'></div>
                  webinar / Event
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}