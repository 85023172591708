/**
 * Hook to get user's business ID.
 * 
 * Will try to get it from an existing `sessionObj` if it already exists, otherwise will get 
 * session data from Account Context.
 */

import React, { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../components/auth/Account'
import axios, { AxiosRequestConfig } from 'axios'

import parseErrorObject from '../helpers/parseErrorObject'

import { SessionPacket } from '../types/session'



export const useUserBusinessId = (
    sessionObj: SessionPacket|null, 
    setSessionObj: React.Dispatch<React.SetStateAction<SessionPacket|null>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
) => {
    const API_IMG_URL = process.env.REACT_APP_API_IMG_URL

    // States
    const [userBizId, setUserBizId] = useState(0)

    // Account Context
    const { getUsernameAndIdToken } = useContext(AccountContext)

    // Get user session if it wasn't already passed in.
    useEffect(() => {
        new Promise(resolve => {
            resolve(sessionObj || getUsernameAndIdToken())
        }).then((_session) => {
            const session = _session as SessionPacket
            const sessionJson = JSON.stringify(session)
            setSessionObj(session)
            return sessionJson
        }).then(sessionJson => {
            // Create form object and headers
            const config = {'headers': {
                'content-type': 'multipart/form-data',
                'x-mr-auth': sessionJson,
            }}
            const formData = new FormData()
            return [config, formData]
        }).then(([config, formData]) => {
            // Get business Id from backend.
            axios.post(`${API_IMG_URL}/get-bid`, 
                formData,
                config as AxiosRequestConfig
            ).then((res) => {
                setErrorMessage('')
                const bizId = res.data?.bid
                if (bizId && !isNaN(bizId) && Number(bizId)) {
                    setUserBizId(Number(bizId))
                }
            })
        }).catch(e => {
            setUserBizId(0)
            setErrorMessage('')
            const errorCode = `#4888`
            const errorMessagePre = 'Validation failed, please log out and login again.'
            const errorMessage = `${errorMessagePre} \n${parseErrorObject(e, errorCode)}`
            setTimeout(() => {
                setErrorMessage(errorMessage)
            }, 350)
            return 0
        })
    }, [sessionObj])

    

    return { userBizId, setUserBizId }
}