/**
 * Connection details for UserPool that is set up in AWS Cognito.
 */

import { CognitoUserPool } from 'amazon-cognito-identity-js'


// "endpoint": "https://cognito-idp.us-east-1.amazonaws.com/",

// Note: it's okay for these to be public.
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID

// TODO: move this to .env and create dev/prod/etc envs.
const UserPool = new CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID || 'xyzbacd', // weird str for debugging purposes.
    ClientId: COGNITO_CLIENT_ID || 'xyzbacde', // weird str for debugging purposes.
})

export default UserPool