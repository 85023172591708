//import './image-carousel.scss'
import React, { useEffect, useRef, useState } from 'react';

import ProductImage from "../../product-card/images/productimage1.png"
import ProductImage2 from "../../product-card/images/product2-placeholder.png"
import ProductImageAmnioTextBox from "../../product-card/images/Amniotext-Box-1.png"
import ProductImageProTextVial from "../../product-card/images/Protext-Vial.png"
import ProductImageCryoTextVial from "../../product-card/images/Cryotext-Vial.png"
import ProductImageSecreTextVial from "../../product-card/images/Secretext-Vial.png"

import './image-carousel2.scss';


interface Props {
    // All four of these args are required or else the function will break. 
    productImageSrc1: string;
    productImageSrc2: string;
    productImageSrc3: string;
    productImageSrc4: string;
}

// This represents that 
const IMAGE_COUNT = 4;



export const ImageCarousel2 = ({ 
    productImageSrc1, productImageSrc2, productImageSrc3, productImageSrc4,
}: Props) => {

    /** Configurable details for this component. */
    // How long to display each image before sliding to the next
    const IMAGE_DISPLAY_TIME = 3000 // ms 
    const IMAGE_TRANSITION_DURATION = 500 // ms
    const IMAGE_SETUP_TIMEOUT = 1000 // (must be less than `IMAGE_DISPLAY_TIME`, but greater than `IMAGE_TRANSITON_DURATION`)

    const [validImages, setValidImages] = useState<string[]>([])
    const [prevValidImagesLength, setPrevValidImagesLength] = useState(-1)

    const [prevShowImageIndex, setPrevShowImageIndex] = useState<number>(-1)
    const [showImageIndex, setShowImageIndex] = useState(0)

    // For animations 
    const rotateImages = useRef(true)
    const [transitionDuration, setTransitionDuration] = useState(0) // Initialize to 0ms until images have loaded.
    const imageDisplayTime = useRef(IMAGE_DISPLAY_TIME)
    const hasShownFirstImage = useRef(false)

    // To know when images are "ready" i.e. have loaded or errored.
    const [successImgs, setSuccessImgs] = useState(0)
    const [errorImgs, setErrorImgs] = useState(0)
    const [isReady, setIsReady] = useState(false)



    // Filter out image src paths that weren't provided as arguments.
    useEffect(() => {
        const srcs = [productImageSrc1, productImageSrc2, productImageSrc3, productImageSrc4].filter(src => !!src)
        setValidImages(srcs as string[])
        // If there's only one image (or 0 images), don't cycle.
        if (srcs.length <= 1) {
            rotateImages.current = false
        }
    }, [productImageSrc1, productImageSrc2, productImageSrc3, productImageSrc4])


    // When an image-src doesn't return an image (i.e. has img-load error), remove it from the image list.
    const removeFromImageList = (index: number) => {
        if (index < 0 || index > validImages.length) return
        const srcs = validImages.slice(0, index).concat(validImages.slice(index + 1))
        setValidImages(srcs)
        // If there's only one image (or 0 images), don't cycle.
        if (srcs.length <= 1) {
            rotateImages.current = false
        }
    }

    
    // Run the cycle-images animation.
    useEffect(() => {
        if (!isReady || !rotateImages.current) return

        let timeoutId1: NodeJS.Timeout;
        let timeoutId2: NodeJS.Timeout;

        if (prevValidImagesLength !== validImages.length || prevShowImageIndex !== showImageIndex) {
            // To properly detect only meaningful changes.
            setPrevShowImageIndex(showImageIndex)
            setPrevValidImagesLength(prevValidImagesLength)

            // Get the index of the image that should show next.
            const nextVal = (showImageIndex + 1) % ((validImages?.length || 1) + 1)

            // The first and last imgs are same, so do no transition between them.
            // First and last imgs are the same, so show each of them for half of their 
            // default length of time and do no transition between those two. So from user's 
            // perspective the carousel is continuous and wraps around.
            // - This timeout runs at 1000ms so it sets teh transition time and image-display 
            //   duration prior to the next image show.
            timeoutId1 = setTimeout(() => {
                if (nextVal === validImages.length) {
                    setTransitionDuration(IMAGE_TRANSITION_DURATION)
                    imageDisplayTime.current = IMAGE_DISPLAY_TIME / 2
                } else if (nextVal === 0 && hasShownFirstImage.current) {
                    setTransitionDuration(0)
                    imageDisplayTime.current = IMAGE_DISPLAY_TIME / 2
                } else {
                    setTransitionDuration(IMAGE_TRANSITION_DURATION)
                    imageDisplayTime.current = IMAGE_DISPLAY_TIME
                }
            }, IMAGE_SETUP_TIMEOUT)

            // Change the image index after specified timeout.
            timeoutId2 = setTimeout(() => {
                hasShownFirstImage.current = true
                if (rotateImages.current) {
                    setShowImageIndex(nextVal)
                }
            }, imageDisplayTime.current)
        }

        // Need to cleanup the timeouts in case component is unmounted while timeout is running.
        return () => {
            clearTimeout(timeoutId1)
            clearTimeout(timeoutId2)
        }
    }, [prevShowImageIndex, showImageIndex, validImages, isReady])


    // When a slide-button is clicked, stop the carousel cycle/rotation and display the image.
    const selectImageAndStopCarousel = (showImageIndex: number) => {
        rotateImages.current = false
        setShowImageIndex(showImageIndex)
    }


    const imageName = (productImageSrc: string, imgIndex: number) => {
        switch (productImageSrc) {
            case 'amniotextbox':
                return <img className="slide-img" src={ProductImageAmnioTextBox} />
            case 'protextvial':
                return <img className="slide-img" src={ProductImageProTextVial} />
            case 'cryotextvial':
                return <img className="slide-img" src={ProductImageCryoTextVial} />
            case 'secretextvial':
                return <img className="slide-img" src={ProductImageSecreTextVial} />
            case 'productimage1':
                return <img className="slide-img" src={ProductImage} />
            case 'productimage2':
                return <img className="slide-img" src={ProductImage2} />
            default:
                return (
                    <img 
                        className="slide-img" 
                        src={productImageSrc}
                        onLoad={() => {
                            setSuccessImgs(prev => prev + 1)
                        }}
                        onError={(e) => {
                            e.preventDefault()
                            removeFromImageList(imgIndex)
                            setErrorImgs(prev => prev + 1)
                        }}
                    />
                )
        }
    }

    // Four images sources will be provided, and there's an extra one added to the end,
    // so wait for 5 total success+error indicators before starting animation.
    
    const timeout = useRef<any>(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        if (successImgs + errorImgs >= IMAGE_COUNT + 1) { // +1 for extra img at end.
            timeout.current = setTimeout(() => {
                setIsReady(true)
            }, 1000)
        }
    }, [successImgs, errorImgs])


    return (
        <div className="carousel-camera-roll">
            {/* Container for carousel images. */}
            <div 
                className='slideshow-wrapper'
                style={{
                    width: `${((validImages.length || 0) + 1) * 100}%`,
                    marginLeft: `${(!isReady || validImages.length <= 1) ? 0 : (showImageIndex * -100)}%`, 
                    transitionDuration: `${transitionDuration}ms`,
                }}
            >
                {/* The main images that will be cycled. */}
                {validImages.map((src, index) => (
                    <div key={src + index} className="slide">
                        {imageName(src, index)}
                    </div>
                ))}

                {/* Repeat the first image in the list so that we can make illusion of 
                    carousel that wraps around completely.. */}
                {validImages.length ? (
                    <div key={validImages[0] + validImages.length} className="slide">
                        {imageName(validImages[0], validImages.length)}
                    </div>
                ): (
                    <div></div>
                )}
            </div>

            {/* Buttons that show which image/slide is currently being displayed. */}
            <div className='slide-btns-container'>
                {isReady ? (
                    (validImages.map((src, index) => (
                        <button 
                            key={src + index} 
                            className={`
                                slide-btn 
                                ${(showImageIndex === index || (showImageIndex === validImages.length && index === 0)) ? 'active' : ''}
                            `} 
                            onClick={() => selectImageAndStopCarousel(index)}
                        >
                            <div className='slide-btn-inner'></div>
                        </button>
                    )))
                ): null}
            </div>
        </div>
    )
}
