import { PillButton } from '../buttons/pill-button/PillButton';
import './skelhome.scss';


interface Props { };

export const SkelHome = ({ }: Props) => {

  return (
    <div className="image-container">
      <div className="skel-span">
        <div className='body-container-no-pad content-platform'>
          <div className='content-platform-inner'>
            <div className='small-title'>Nullam ipsum nibh.</div>
            <div className='big-title'>FUSCE BIBENDUM ID VELIT ID MOLLIS.</div>
            <PillButton buttonStyle="white">
              JOIN TODAY
            </PillButton>
          </div>
        </div>
      </div>
    </div>


  );
}