import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { AccountContext } from '../../../auth/Account';
import { PaymentAccountDetails, PaymentMethod, getObjByIdFromList } from '../CartShared';
import { PAYMENT_METHODS } from '../../../../../config';

import getUserPaymentAccounts from './_fetchUserPaymentAccounts';

import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { Icon } from '../../../shared/icon/Icon';
import { SelectField, SelectOption, SELECT_OPTION_NULL } from "../../../shared/form/select-field/SelectField";
import { SetToggleButtonGroup } from "../../../shared/buttons/set-toggle-button-group/SetToggleButtonGroup";

// Braintree
import dropin from "braintree-web-drop-in"
import axios from "axios";
import braintreeWeb, { Client, DataCollector, HostedFields } from 'braintree-web'
import { BrantreeForm_CC } from '../braintree-hosted-fields/BraintreeForm_CC';



const API_DB_URL = process.env.REACT_APP_API_DB_URL


// Icons for payment options. Key is the PaymentMethod's ID. (So 2 will always bank, etc).
const PAYMENT_METHOD_ICONS: {[key: number]: JSX.Element} = {
	1: <Icon name='creditcard3' />,
	2: <Icon name='bank' />,
	3: <Icon name='contract' />
}


interface TxnDataInner {
    amount: number,
    paymentInstrumentType: string,
    status: string,
    id: number,
}
interface TxnData {
    success: boolean,
    transaction: TxnDataInner,

}


interface Props {
    loggedInUserId: number|null; // Only has a val if user is logged in.
    sessionJson: string;
    paymentAccounts: PaymentAccountDetails[];
    setPaymentAccounts: React.Dispatch<React.SetStateAction<PaymentAccountDetails[]>>;
    selectedPaymentAccount: PaymentAccountDetails;
    setSelectedPaymentAccount: React.Dispatch<React.SetStateAction<PaymentAccountDetails>>;
    paymentAccountsDropdownOptions: SelectOption[];
    setPaymentAccountsDropdownOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>;
}

/**
 * Component for user to select a saved payment account (for a given payment method) on CheckOut
 *      page, or add a new payment account.
 *  - The selected payment method gets passed to the parent via `setSelectedPaymentAccount` (state).
 */
const _paymentForm = ({
    loggedInUserId,
    sessionJson,
    paymentAccounts,
    setPaymentAccounts,
    selectedPaymentAccount,
    setSelectedPaymentAccount,
    paymentAccountsDropdownOptions,
    setPaymentAccountsDropdownOptions,
}: Props) => {
    // Account context
    const { ACTCTX_READY, getPaymentMethodIds, savePaymentMethodIds, 
            deletePaymentAccountId } = useContext(AccountContext)

    /** 
     * Selected payment methods.
     */
    // For the payment method selected by the user.
    const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] = useState(0) // 0 is valid (it's for toggle buttons).
    const [selectedPaymentAccountIndex, setSelectedPaymentAccountIndex] = useState(0) // 0 is the nullish option in dropdown.
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({} as PaymentMethod)
    const [selectedPaymentAccountId, setSelectedPaymentAccountId] = useState(0) // A real ID is > 0.

    /**
     * Braintree
     */
    const [hostedFieldsReady, setHostedFieldsReady] = useState(false)
    


    // Set flags on mount for ready state
    const [x1, x2, x3] = [useRef(false), useRef(false), useRef(false)]
    useEffect(() => {
        x1.current = true
    }, [])

    // Run on mount
    //  - (Tehnically it runs one cycle after mount, so that AccountContext etc are ready)
    //  - This only runs ONCE.
    //  - Try to get user's previously selected payment method (and index), else use the 0th index.
    useEffect(() => {
        if (ACTCTX_READY && x1.current && !x2.current) {
            getPaymentMethodIds().then((paymentInfo) => {
                if (paymentInfo && Array.isArray(paymentInfo) && paymentInfo.length === 2) {
                    const [selectedPmtMethodId, selectedPmtAccountId] = paymentInfo
                    setSelectedPaymentAccountId(Number(selectedPmtAccountId))
                    // Find the payment method obj & index.
                    // Remember that unfound index will return as -1.
                    const [pmtMethod, pmtMethodIdx] = getObjByIdFromList(PAYMENT_METHODS, 'id', selectedPmtMethodId)
                    // If matching payment method is found, then store payment method and 
                    // trigger its selection in buttons.
                    if (pmtMethod) {
                        setSelectedPaymentMethodIndex(pmtMethodIdx)
                        setSelectedPaymentMethod(pmtMethod)
                        // Get user's saved accounts for this payment method (and populate dropdown menu)
                        getUserPaymentAccounts(pmtMethod).then(([pmtAccts, drpdwnOpts]) => {
                            setPaymentAccounts(pmtAccts)
                            setPaymentAccountsDropdownOptions(drpdwnOpts)
                            x2.current = true
                        }).catch(() => {
                            x2.current = true
                        })
                    } else {
                        // Default case
                        setSelectedPaymentMethodIndex(0)
                        x2.current = true
                    }
                } else {
                    x2.current = true
                }
            })
        }
    }, [ACTCTX_READY, getPaymentMethodIds])

    // Fetch all of the user's stored payment ACCOUNTs
    // This runs when:
    //  - Right after the 1st useEffect above, which happens right after** mount,
    //  - This only runs ONCE. This runs 2nd.
    //  - Runs when `paymentAccounts` is loaded, which is always after the above useEffect.
    useEffect(() => {
        if (x2.current && !x3.current && paymentAccounts.length) {
            const [_, pmtAccountIdx] = getObjByIdFromList(paymentAccounts, 'id', selectedPaymentAccountId)
            // (If `selectedPaymentAccountId` not found, then `pmtAccountIdx` will be -1).
            setSelectedPaymentAccountIndex(pmtAccountIdx+1) // 0 is nullish option is dropdown menu.
            x3.current = true
        }
    }, [x2.current, x3.current, paymentAccounts, selectedPaymentAccountId])

    // Store the payment METHOD and clear out the saved payment ACCOUNT ID.
    // This runs when:
    //  - User MANUALLY changes a payment METHOD (NOT ACCOUNT),
    //  - NEVER runs on mount.
    //  - Only runs when user changes the payment METHOD button.
    useEffect(() => {
        if (x2.current) {
            setSelectedPaymentAccountIndex(0)
            // Remember that <Select>'s 0th index is a nullish value. 
            // And that 0 is not a real payment account ID (must be at least 1).
            const selectedPmtMethod = PAYMENT_METHODS[selectedPaymentMethodIndex]
            // Delete saved payment account (done by setting the account-id value to 0)
            deletePaymentAccountId(selectedPmtMethod.id)
            // Update UI for this payment method.
            setSelectedPaymentMethod(selectedPmtMethod)
            // Get user's saved accounts for this payment method (and populate dropdown menu)
            getUserPaymentAccounts(selectedPmtMethod).then(([pmtAccts, drpdwnOpts]) => {
                setPaymentAccounts(pmtAccts)
                setPaymentAccountsDropdownOptions(drpdwnOpts)
                x3.current = true
            }).catch(() => {
                x3.current = true
            })
        }
    }, [selectedPaymentMethodIndex, selectedPaymentAccountId])

    // Store both the payment method ID and payment account ID.
    // This runs when:
    //  - User MANUALLY changes a payment ACCOUNT.
    //  - Won't run on mount unless there is a selected account saved from previous session.
    //      - If there's a saved account, then it will run 3rd after the other two useEffects at the top.
    //  - AFTER payment METHOD is changed (which is set to automatically "delete" saved ACCOUNT).
    useEffect(() => {
        if (x2.current && selectedPaymentMethod.id) { // Remember: valid IDs are > 0
            if (selectedPaymentAccountIndex > 0) { // 0 is nullish option.
                const selectedPmtAccount = paymentAccounts[selectedPaymentAccountIndex-1] 
                setSelectedPaymentAccount(selectedPmtAccount)
                savePaymentMethodIds(selectedPaymentMethod.id, selectedPmtAccount.id)
            } else {
                setSelectedPaymentAccount({} as PaymentAccountDetails)
                deletePaymentAccountId(selectedPaymentMethod.id)
            }
        }
    }, [selectedPaymentMethod.id, selectedPaymentAccountIndex])

    // Redirect to checkout confirmation page.
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = '/cart/check-out-confirmation'; 
        navigate(path);
    }


    return (
        <>
        {/* For selecting payment method */}
        <SetToggleButtonGroup 
            type='white'
            loopKeys={PAYMENT_METHODS.map(opt => String(opt.id))}
            mainValues={PAYMENT_METHODS.map(opt => PAYMENT_METHOD_ICONS[opt.id])}
            upperValues={PAYMENT_METHODS.map(opt => (
                opt.display.slice(0,1).toUpperCase() + opt.display.slice(1)
            ))}
            containerStyle={{ display: 'flex', flexWrap: 'wrap', 
                                justifyContent: 'space-between', width: '100%' }}
            buttonStyle={{ padding: '0 16px', width: '28%', minWidth: '100px', 
                            maxWidth: '300px', height: '120px',}}
            mainTextStyle={{ height: '40px', width: '40px', margin: '0 auto', 
                                position: 'relative', display: 'flex', 
                                justifyContent: 'center', alignItems: 'center', 
                                textAlign: 'center' }}
            upperTextStyle={{ marginTop: '8px', marginBottom: '16px',
                                textTransform: 'uppercase' }}
            selectedItemIndex={selectedPaymentMethodIndex}
            setSelectedItemIndex={setSelectedPaymentMethodIndex}
        />      

        {/* For selecting payment account */}
        <div className="saved-payment-options">
            <div className="title">
                Pay with {selectedPaymentMethod.display?.toLowerCase()}
            </div>
            <div className="subtitle">
                {(paymentAccountsDropdownOptions.length) ? (
                    `Select a saved ${selectedPaymentMethod.display?.toLowerCase()} or add a new account`
                ) : (
                    `You don't have any stored ${selectedPaymentMethod.display}s yet`
                )}
            </div>
            {(paymentAccountsDropdownOptions.length) ? (
                <SelectField 
                    containerStyle={{}}
                    style={{
                        padding: '0 20px', height: '40px', width: '100%', minWidth: '360px',
                        maxWidth: '450px', backgroundColor: 'rgba(255,255,255,.9)', fontWeight: 600, 
                        fontSize: '1.6rem', lineHeight: 1.2, textAlign: 'center',
                    }}
                    name="saved-payment-selection" 
                    options={[SELECT_OPTION_NULL].concat(paymentAccountsDropdownOptions)}
                    selectedIndex={selectedPaymentAccountIndex}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedPaymentAccountIndex(Number(e.target.value))
                    }}
                />
            ) : ( null )}
            {(selectedPaymentAccount) ? (
                <div className='payment-account-details'>
                    {selectedPaymentAccount.accountNumber}
                </div>
            ): ( null )}
            <BlockButton 
                buttonStyle="ghost-purple less-pad"
                style={{marginTop: '50px', fontSize: '1.6rem', height: '40px'}}
            >
                Add {paymentAccountsDropdownOptions.length ? 'another' : 'a'} {selectedPaymentMethod.display}
            </BlockButton>
        </div>

        <div className="submit-section">
            <div className="submit-button-wrapper">
                <BlockButton 
                    buttonStyle="purple"
                    className='submit-button'
                    disabled={!selectedPaymentAccount.id}
                    onClick={routeChange}
                >
                    Continue
                </BlockButton>
            </div>
        </div>

        <BrantreeForm_CC
            loggedInUserId={loggedInUserId}
            sessionJson={sessionJson}
            setHostedFieldsReady={setHostedFieldsReady}
        />
        </>
    )
}

export default _paymentForm