import { Route, Routes } from 'react-router-dom'
import { ForgotPassword1 } from './ForgotPassword1'

export const ForgotPassword = () => {

    return (
        <>
            <Routes>
                <Route path='1' element={<ForgotPassword1 />} />
            </Routes>
        </>
    )
}