import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';
import './text-area-field.scss';


interface Props {
    name: string;
    placeholder?: string;
    id?: string;
    required?: boolean;
    style?: any;
    className?: string;
    defaultValue?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    disabled?: boolean;
    // Color the background red if the value has changed.
    onChangeRedBackground?: boolean;
    unmarkChanged?: boolean;
    setUnmarkChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    // Chrome sometimes mis-guesses that an input field should be completed with an email/pw,
    // and auto-fills the user's email address, when it's really a different type of field.
    // Use this flag for those types of fields.
    preventAutofill?: boolean;
};

type State = {};


// Styles to display after value has been changed by user (if `onChangeRedBackground` is true):
const CHANGED_STYLE = {
    backgroundColor: 'rgba(255, 0, 0, .15)',
    color: 'rgba(0, 4, 12, .75)',
}


export const TextAreaField = ({
    name, placeholder, id, required, style, className='', 
    defaultValue, value, 
    onChange, onFocus, onBlur,
    disabled,
    onChangeRedBackground, unmarkChanged, setUnmarkChanged,
    preventAutofill,
}: Props) => {

    const [changeColor, setChangeColor] = useState(false)

    // If `onChangeRedBrackground=true`, then this will unset the red background)
    // - (this runs whenever component is updated w/new vars/vals)
    useEffect(() => {
        if (unmarkChanged === true && setUnmarkChanged) {
            setUnmarkChanged(false)
            setChangeColor(false)
        }
    }, [unmarkChanged])

    return (
        <textarea 
            id={id ?? name}
            className={`textarea-field ${className}`}
            name={name}
            placeholder={placeholder}
            style={{...(style || {}), ...(changeColor ? CHANGED_STYLE : {})}}
            defaultValue={defaultValue}
            value={value || undefined}
            onChange={(e) => {
                if (onChange) {
                    onChange(e)
                }
                if (onChangeRedBackground) {
                    setChangeColor(true)
                }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={!!disabled}
            // This is the only way that works
            // https://stackoverflow.com/questions/50347574/how-to-disable-chrome-autocomplete-feature
            autoComplete={preventAutofill ? 'new-password' : undefined}
        />
    )
}