import { useState } from 'react';
import { Link } from "react-router-dom";
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { ProductCard } from '../../../shared/product-card/ProductCard';
import { InputField } from '../../../shared/form/input-field/InputField';
import { CheckBox } from '../../../shared/buttons/check-box/CheckBox';
import { PageNumbers } from '../../../shared/buttons/page-numbers/PageNumbers';
import './search-results.scss';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';



interface Props { };

export const SearchResultsPage = ({ }: Props) => {

  const [showRedirectModal, setShowRedirectModal] = useState(false)

  const [page, setPage] = useState(2)


  return (
    <>
      <PrintOnlyContent title={true}>Medngine search results page</PrintOnlyContent>
      <HeaderBanner
        imageName="opbedmid"
        imageText="SEARCH"
        imageSpacerColor='BLUE'
      />
      <div className="body-container-no-vert-pad searchresults-layout">
        <div className="header">1 - 16 of 400 results "Lorem Ipsum"</div>
        <div className="formwrapper-top">
          <div className="left-panel">
            <div className="filter-keyword">
              <InputField
                id="search-filter"
                name='filter'
                placeholder='Filter by keyword'
                style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
              />
            </div>
            <div className="check-filters">
              <div className="closable-circles">
                <div className="circle-x"><div className="x-itself" /></div>
                <div className="circle-x"><div className="x-itself" /></div>
                <div className="circle-x"><div className="x-itself" /></div>
              </div>

              <div className="filter-boxes">
                <div className="filter">Lorem</div>
                <div className="filter">Ipsum</div>
                <div className="filter">Dolor</div>
              </div>
            </div>


            <div className="expansion-container">
              <details>
                <summary>
                  BRANDS
                  <span className="arrow">V</span>
                </summary>
                <div className="expansion-box">
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                </div>
              </details>
            </div>

            <div className="expansion-container">
              <details>
                <summary>
                  CATEGORIES
                  <span className="arrow">V</span>
                </summary>
                <div className="expansion-box">
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                  <div className="checkone"><CheckBox><div className="text">LOREM IPSUM DOLOR</div></CheckBox></div>
                </div>
              </details>
            </div>

          </div>
          <div className="right-results">
            <div className="products">
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
              <Link to={"/public/products/amniotext"} className='product-card-link'>
                <ProductCard
                  product="AMNIOTEXT™ 1cm x 1cm"
                  brand="Regenative Labs"
                  vendor="APF2 – 11"
                  description="The donor tissue is recovered and processed aseptically in full 
                                    accordance with all industry standards. "
                  productImage="amniotextbox"
                />
              </Link>
            </div>
          </div>

        </div>
        <div className="page-numbers">
          <PageNumbers
            style={{ marginLeft: '340px', marginTop: '20px' }}
            currentPage={page}
            totalPages={7}
            setPage={setPage}
          />
        </div>
      </div>
    </>
  )
}