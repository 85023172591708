import React, { useEffect, useState } from 'react';
import './select-field.scss';



export type SelectOption = {
    option: string,
    value: any,
}

interface Props {
    name: string;
    options: SelectOption[]|any[];
    label?: string;
    required?: boolean;
    id?: string;
    containerClassName?: string;
    containerStyle?: { [key: string]: string | number };
    style?: { [key: string]: string | number };
    selectStyle?: string,
    // This is how to select an dropdown option programmatically
    selectedIndex?: string | number;
    onChange?: (e: React.ChangeEvent<any>) => any;
    onChangeRedBackground?: boolean;
    unmarkChanged?: boolean;
    setUnmarkChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    defaultValue?: string;
    // Error message to be displayed for this input field.
    errorMessage?: string;
    // Modify display for error/success message display.
    altDisplay?: boolean;
    // If the first option is '-', dropdown title, or some other nullish field that isn't a valid selection.
    firstOptionIsNull?: boolean;
    // Disabled select
    disabled?: boolean;
};


// Use this for the first item in dropdown box
export const SELECT_OPTION_NULL: SelectOption = { option: '-', value: '-' }


// Styles to display after value has been changed by user (if `onChangeRedBackground` is true):
const CHANGED_STYLE = {
    backgroundColor: 'rgba(255, 0, 0, .15)',
    color: 'rgba(0, 4, 12, .75)',
}


export const SelectField = ({
    name, label, options, style, selectStyle, required, id,
    containerStyle, containerClassName, selectedIndex, onChange, onChangeRedBackground, 
    unmarkChanged, setUnmarkChanged,
    defaultValue, 
    errorMessage, altDisplay = false,
    firstOptionIsNull = false,
    disabled, 
}: Props) => {

    const [changeColor, setChangeColor] = useState(false)

    const isDictionary = (obj: any) => {
        return typeof obj === "object" && obj !== null && !Array.isArray(obj) && Object.keys(obj).every(key => typeof key === "string")
    }

    // If `onChangeRedBrackground=true`, then this will unset the red background)
    // - (this runs whenever component is updated w/new vars/vals)
    useEffect(() => {
        if (unmarkChanged === true && setUnmarkChanged) {
            setUnmarkChanged(false)
            setChangeColor(false)
        }
    }, [unmarkChanged])

    return (
        <div
            className={
                'select-component-wrapper '
                + (containerClassName ?? '') + ' '
                + (altDisplay ? 'alt' : '') + ' '
            }
            style={containerStyle}
        >
            {(label ? (
                <label className="label" htmlFor={id ?? name}>{label}</label>
            ) : (
                null
            ))}

            <div
                className={selectStyle + ' select-component-wrapper-inner'}>
                <select
                    style={{...(style || {}), ...(changeColor ? CHANGED_STYLE : {})}}
                    name={name}
                    id={id ?? name}
                    required={required ?? false}
                    value={selectedIndex !== undefined ? Number(selectedIndex) + Number(firstOptionIsNull) : undefined}
                    onChange={(e) => {
                        if (onChange) { onChange(e) }
                        if (onChangeRedBackground) {
                            setChangeColor(true)
                        }
                    }}
                    disabled={!!disabled}
                    defaultValue={defaultValue !== undefined ? Number(defaultValue) + Number(firstOptionIsNull) : undefined}
                >
                    {options.map((option, i) => (
                        (isDictionary(option) ? (
                            <option key={`${i}-${option.option}`} value={i}>
                                {/* this is what is deisplayed to the user  */}
                                {option.value}
                            </option>
                        ) : (
                            <option key={`${i}-${String(option)}`} value={i}>
                                {String(option)}
                            </option>
                        ))
                    ))}
                </select>

                {(errorMessage ? (
                    <div className='error-message'>
                        {errorMessage}
                    </div>
                ) : (
                    null
                ))}
            </div>
        </div>
    )
}