import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton'
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner"
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage'
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar"
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject'

import './vendor-registration.scss'



export const VendorRegistration3 = () => {
    const STEP = 3

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Modal to show loading status.
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()

    // Auth/session functions
    const { checkLogin, checkLoginNow, getUsernameAndIdToken } = useContext(AccountContext)

    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Message based on referrer
        setReferrerMessage(qp.reason === 'reg-incomplete' ? 'Please complete your registration' : '')

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }
    }, [])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (isPreviewMode !== false || !checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/vendor-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin, isPreviewMode])

    // Validate inputs (Placeholder for basic frontend validation before submitting to backend).
    const frontendValidationErrors = () => {
        let errorCount = 0
        return errorCount
    }
    
    // Submit regisration data to AWS Cognito to create a new user.
    const onSubmitRegistrationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setShowSpinner(true)
        setSpinnerText('Validating')

        // Run frontend validation.
        if (frontendValidationErrors()) {
            setShowSpinner(false)
            return
        }
        
        /** Return Cognito user info after validating */
        const session: SessionPacket = await getUsernameAndIdToken()
        if (session.error) { 
            setHideAllErrors(false);
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        // Config for API call.
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL

        // Add username and idToken to form submission
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)
        
        // Submit to db
        axios.post(`${API_DB_URL}/new-user-init-vendor/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            // Success
            setHideAllErrors(true)
            setSpinnerText('Confirmed!')
            setTimeout(() => {
                setShowSpinner(false)
                window.location.href = `/vendor-registration/${STEP+1}`
            }, 800)
        }).catch(async (err) => {
            // Failure
            setShowSpinner(false)
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8803'))
            }, 350)
        })
    }
    

    return (
        <>
        <HeaderBanner
            imageName="doctorpen"
            imageText="VENDOR APPLICATION"
            imageSpacerColor='BLUE'
        />
        <RegistrationLoadingModal
            show={showSpinner}
            setShow={setShowSpinner}
            titleText={spinnerText}
        />
        
        <PreviewModeMessage show={!!isPreviewMode} link={'/vendor-registration/1'} />

        <div className="vendor-register-page-layout">
            <div className="progress-bar-placement p3">
                <ProgressBar whichStep={3} numberOfSteps={5} />
            </div>
            <div className="body-container">
                <div className="header3">
                    READ BEFORE CONTINUING
                </div>
                <p className="bottom-paragraph">
                    test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                    test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                    test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                    text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 
                    1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                    text 1 test text 1 test text 1 test text 1 test text 1 
                </p>

                {/* Show different messages based on how the user got here */}
                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                <form onSubmit={onSubmitRegistrationForm}>
                    {/* Error messages */}
                    {(!hideAllErrors && errorMessage) ? (
                        <div className='error-message'>
                            {errorMessage}
                        </div>
                    ): null }

                    <div className="buttons-container">
                        <Link to={`/vendor-registration/${STEP - 1}${isPreviewMode ? '?mode=preview' : ''}`}>
                            <BlockButton style={{ width: '250px' }} buttonStyle="ghost-purple">
                                BACK
                            </BlockButton>
                        </Link>

                        {!isPreviewMode ? (
                            <BlockButton 
                                type='submit'
                                style={{ width: '250px' }} 
                                buttonStyle="purple"
                            >
                                CONTINUE
                            </BlockButton>
                        ) : (
                            <BlockButton
                                type='button'
                                style={{ width: '250px' }}
                                buttonStyle="purple"
                                onClick={() => {
                                    window.location.href=`/vendor-registration/${STEP + 1}?mode=preview`
                                }}
                            >
                                NEXT
                            </BlockButton>
                        )}
                    </div>

                    <ToastContainer 
                        hideProgressBar={true}
                        position='bottom-center'
                        style={{textAlign: 'center', cursor: 'default'}}
                    />
                </form>
            </div>
        </div>
        </>
    )
}