import { CartItem } from "../../models/CartItem"
import { CartActions } from "../actions/cart-actions"
import { CheckOutActionsEnum } from "../actions/check-out-actions"
import { GlobalActions } from "../actions/_global-actions"
import { ProductAction } from "../actions/product-actions"
import { ReducerAction } from "./_types"


export type CartMap = Map<string, CartItem>
export const cart = (orders:CartMap = new Map(), action: ReducerAction) => {
    switch (action.type) {
        case CartActions.ADD_PRODUCT:
            const newItem = new CartItem(action.payload)
            const key = newItem.uniqueKey
            if(!orders.has(key)) {
                orders.set(key, newItem)
            }
            return orders
        case GlobalActions.HIPPA_RESET:
            return new Map()
        default:
            return orders
    }
}


export const selectedPaymentMethod = (paymentMethod = null, action: ReducerAction): any => {
    switch (action.type) {
        case CheckOutActionsEnum.UPDATE_PAYMENT_METHOD:
            return action.payload
        default:
            return paymentMethod
    }
}


export const products = (products = [], action: ReducerAction) => {
    switch (action.type) {
        case ProductAction.FETCH_ALL:
            return action.payload
        case GlobalActions.HIPPA_RESET:
            return []
        default:
            return products
    }
}


export const currentProduct = (product = {}, action: ReducerAction) => {
    switch (action.type) {
        case ProductAction.GET_SINGLE_PRODUCT:
            return action.payload
        case GlobalActions.HIPPA_RESET:
            return {}
        default:
            return product
    }
}


export type TaxRateMap = Map<string, any>
export const taxRates = (taxRates: TaxRateMap = new Map(), action: ReducerAction) => {
    switch (action.type) {
        case CheckOutActionsEnum.GET_TAX_RATES:            
            return action.payload
        default:
            return taxRates
    }
}


export const stateTax = (stateTaxRates = null, action: ReducerAction) => {
    switch (action.type) {
        case CheckOutActionsEnum.GET_TAX_BY_STATE:
            return action.payload
        default:
            return stateTaxRates
    }
}
