import { InputField } from "../../shared/form/input-field/InputField"

import "./credit-application.scss";



interface Props {
	activeStep: number;
}
/**
 * Credit Application page - step 2.
 * 
 * This is where user enters info about their billing company.
 */
export const CreditApplicationStep2 = ({ activeStep }: Props) => {
	const STEP: number = 2

    return (
        <div style={{display: `${(activeStep === STEP) ? 'block' : 'none'}`}}>
			<div className="form-border">
				<div className='section-title'>
					Billing Company Information
				</div>

				<div className="form-row">
					<div className='form-field' style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-billing-company">
							Billing Company
						</label>
						<InputField
							required
							id="credit-application-form-billing-company"
							name="billing-company"
							altStyle='credit-app'
						/>
					</div>
				</div>

				<div className="form-row">
					<div className='form-field' style={{width: '40%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-billing-contact-name">
							Contact Name
						</label>
						<InputField
							id="credit-application-form-billing-contact-name"
							name="billing-contact-name"
							altStyle='credit-app'
						/>
					</div>

					<div className="form-field" style={{width: '25%'}}>
						<label htmlFor="credit-application-billing-contact-phone">
							Phone
						</label>
						<InputField
							required
							id="credit-application-form-billing-contact-phone"
							type='tel'
							name="billing-contact-phone"
							altStyle='credit-app'
						/>
					</div>

					<div className="form-field" style={{width: '35%'}}>
						<label htmlFor="credit-application-form-billing-contact-email">
							Email Address
						</label>
						<InputField
							id="credit-application-form-billing-contact-email"
							type='email'
							name="billing-contact-email"
							altStyle='credit-app'
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="form-field" style={{width: '40%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-billing-requested-limit">
							Requested Limit ($)
						</label>
						{/* TODO: add commas to dollar figures */}
						<InputField
							id="credit-application-form-billing-requested-limit"
							type='number'
							maxLength={9}
							name="billing-requested-limit"
							altStyle='credit-app'
						/>
					</div>
				</div>
			</div>


			<div className="form-border">
				<div className='section-title'>
					Primary Bank Reference
				</div>

				<div className="form-row">
					<div className="form-field no-right-padding" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-bank-name">
							Bank Name
						</label>
						<InputField
							required
							id="credit-application-form-bank-name"
							name="bank-name"
						/>
					</div>

					<div className="form-field no-right-padding" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-account-number">
							Account Number
						</label>
						<InputField
							required
							id="credit-application-form-account-number"
							type='number'
							maxLength={17}
							name="account-number"
						/> {/* maxlength: https://www.askbanking.com/usa-bank-account-number-digits/ */}
					</div>
				</div>

				<div className="form-row">
					<div className="form-field no-right-padding" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-billing-bank-contact-name">
							Contact Name
						</label>
						<InputField
							required
							id="credit-application-form-billing-bank-contact-name"
							name="billing-bank-contact-name"
						/>
					</div>

					<div className="form-field no-right-padding" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-billing-phone">
							Phone Number
						</label>
						<InputField
							required
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							type='tel'
							id="credit-application-form-billing-phone"
							name="billing-phone"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
