import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { SessionPacket } from '../../../types/session';

import { BlockButton } from "../buttons/block-button/BlockButton";
import { Icon } from '../icon/Icon';
import { InputImageField } from '../form/input-image-field/InputImageField';
import { InputField } from '../form/input-field/InputField';
import { TextAreaField } from '../form/text-area/TextAreaField';

import parseErrorObject from '../../../helpers/parseErrorObject';

import { VALID_IMG_NAMES, VALID_UPLOAD_TYPES } from '../../../../config';

import LoadingCircle from '../../../static/gif/loading-circle.gif'
import './add-product-modal.scss';


// Data structure for products (NOT for component)
export interface ProductData {
    id: number;
    product_id: string;
    name: string;
    name_display: string;
    brand: string;
    headline_1: string;
    description_1: string;
    description_2?: string;
    description_3?: string;
    discounts: any; // TODO: use correct type when we start using discounts.
    business_name: string;
    business_name_display: string;
}

// Default object for productData
export const DEFAULT_PRODUCT_DATA: ProductData = {
    id: -1,
    product_id: '',
    name: '',
    name_display: '',
    brand: '',
    headline_1: '',
    description_1: '',
    description_2: '',
    description_3: '',
    discounts: '',
    business_name: '',
    business_name_display: '',
}

// Data structure for SKU data
export interface ProductSkuData {
    id: number,
    sku_id: string,
    name: string,
    name_display: string,
    dose: string,
    size: string,
    price: string, // This must be a string else problems with entering decimals.
}

// Default object for productSkuData
export const DEFAULT_PRODUCT_SKU_DATA: ProductSkuData = {
    id: -1,
    sku_id: '',
    name: '',
    name_display: '',
    dose: '',
    size: '',
    price: '',
}

// Component props
interface Props {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    sessionObj: SessionPacket|null;
    setSessionObj: React.Dispatch<React.SetStateAction<SessionPacket|null>>;
    getUsernameAndIdToken: Function;
    userBizId: number;
    productId: number;
    setProductAdded: React.Dispatch<React.SetStateAction<boolean>>;
    productData: ProductData;
    setProductData: React.Dispatch<React.SetStateAction<ProductData>>;
    setPrimaryImgChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    businessType: 'vendor' | 'supplier';
    isPreviewMode?: boolean|null;
};

// Env vars
const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL

// Placeholder text for <textarea> since they're weird.
// TODO: centralize
const TEXTAREA_PLACEHOLDER_DESC = 'Description'
const TEXTAREA_PLACEHOLDER_USES = 'Uses'
const TEXTAREA_PLACEHOLDER_RESEARCH = 'Research'



export const AddProductModal = ({ 
    show, setShow,
    sessionObj, setSessionObj,
    getUsernameAndIdToken,
    userBizId,
    productId,
    setProductAdded,
    productData = DEFAULT_PRODUCT_DATA,
    setProductData,
    setPrimaryImgChanged,
    businessType,
    isPreviewMode,
}: Props) => {
    const showHideClassName = show ? "display-block" : "display-none";

    // State variables for component behavior.
    const [changesMade, setChangesMade] = useState(false)
    const [showSpinnerOverlay, setShowSpinnerOverlay] = useState(false)
    const [showSkusLoadingSpinner, setShowSkusLoadingSpinner] = useState(true)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    // State variables for input fields.
    const [inputName, setInputName] = useState(productData['name'] || '')
    const [inputProductId, setInputProductId] = useState(productData['product_id'] || '')
    const [inputBrand, setInputBrand] = useState(productData['brand'] || '')
    const [inputHeadline1, setInputHeadline1] = useState(productData['headline_1'] || '')
    const [inputDescription1, setInputDescription1] = useState(productData['description_1'] || TEXTAREA_PLACEHOLDER_DESC)
    const [inputDescription2, setInputDescription2] = useState(productData['description_2'] || TEXTAREA_PLACEHOLDER_USES)
    const [inputDescription3, setInputDescription3] = useState(productData['description_3'] || TEXTAREA_PLACEHOLDER_RESEARCH)

    // Skus data for this product Id. Initialize with empty row.
    const [skusList, setSkusList] = useState<ProductSkuData[]>([{...DEFAULT_PRODUCT_SKU_DATA}])
    // Updated/Deleted sku IDs
    const [updatedSkuIds, setUpdatedSkuIds] = useState<number[]>([])
    const [deletedSkuIds, setDeletedSkuIds] = useState<number[]>([])

    // Require at least one image to be added (the first image, specifically)
    const [img1Success, setImg1Success] = useState(false)

    // The container object, to scroll to top any time a new one is opened.
    const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>


    // On mount & whenever `productData` changes, set all the input fields.
    useEffect(() => {   
        if (isPreviewMode !== false) return

        // Reset display
        modalRef.current.scroll(0, 0)
        setShowSkusLoadingSpinner(true)
        setSuccessMessage('')
        setErrorMessage('')
        // Empty out the SKUs list data, and changed/deleted SKU ids.
        setSkusList([{...DEFAULT_PRODUCT_SKU_DATA}])
        setUpdatedSkuIds([])
        setDeletedSkuIds([])
        // Replace product-level input fields with supplied data or empty.
        setInputName(productData.name || '')
        setInputProductId(productData.product_id || '')
        setInputBrand(productData.brand || '')
        setInputHeadline1(productData.headline_1 || '')
        setInputDescription1(productData.description_1 || TEXTAREA_PLACEHOLDER_DESC)
        setInputDescription2(productData.description_2 || TEXTAREA_PLACEHOLDER_USES)
        setInputDescription3(productData.description_3 || TEXTAREA_PLACEHOLDER_RESEARCH)
        // Also get the skus data from backend.
        axios.get(`${API_DB_URL}/get-product-skus/${productId}`)
            .then(res => {
                if (res.data && Array.isArray(res.data) && res.data.length) {
                    setSkusList(res.data)
                } else {
                    setSkusList([{...DEFAULT_PRODUCT_SKU_DATA}])
                }
            }).catch(e => {
                const errMsg = parseErrorObject(e, '#05a')
                setErrorMessage(`System error #4953: Skus failed to load. Please refresh and try again. ${errMsg}`)
            }).finally(() => {
                setShowSkusLoadingSpinner(false)
            })
    }, [productId, productData])

    // Always hide the loading spinner if we're in "preview mode".
    useEffect(() => {
        if (isPreviewMode) {
            setShowSkusLoadingSpinner(false)
        }
    }, [showSkusLoadingSpinner, isPreviewMode])

    // On unmount - clear product data so they don't show in the next modal.
    useEffect(() => {
        return () => {
            setProductData(DEFAULT_PRODUCT_DATA)
            setInputDescription1(TEXTAREA_PLACEHOLDER_DESC)
            setInputDescription2(TEXTAREA_PLACEHOLDER_USES)
            setInputDescription3(TEXTAREA_PLACEHOLDER_RESEARCH)
        }
    }, [])

    // Cancel changes without saving and close modal.
    const cancel = useCallback(async () => {
        if (isPreviewMode !== false) {
            setShow(false)
            return
        }

        let confirmedCancel = false
        if (changesMade) {
            confirmedCancel = window.confirm('Are you sure you want to discard your changes?')
        }
        if (!changesMade || confirmedCancel) {
            // Reset state variables for the next time this modal is opened.
            setSkusList([{...DEFAULT_PRODUCT_SKU_DATA}])
            setUpdatedSkuIds([])
            setDeletedSkuIds([])
            setSuccessMessage('')
            setErrorMessage('')
            setInputName('')
            setInputProductId('')
            setInputBrand('')
            setInputHeadline1('')
            setInputDescription1(TEXTAREA_PLACEHOLDER_DESC)
            setInputDescription2(TEXTAREA_PLACEHOLDER_USES)
            setInputDescription3(TEXTAREA_PLACEHOLDER_RESEARCH)
            setShow(false)
            setProductData(DEFAULT_PRODUCT_DATA)

            // Get info to post to backend api. (fail silently)
            const formData = new FormData()
            formData.append('productId', String(productId))

            // Delete the product row from DB IFF it is empty.
            // - Fail silently
            // - This step must run first (and must be await'd), bc the next step that deletes 
            //   images will first confirm that the product row DOESN'T exist in the db.
            const wasDeleted = await axios.delete(`${API_DB_URL}/delete-product-if-empty/${productId}`)
                                          .then((res) => res.data)
                                          .catch(e => false) 
            // If it WASN'T deleted, it could have been due to an error, but assume it was because
            // the mr_product row wasn't empty. So only delete all of this product's images
            // if the row was deleted successfully.
            // - No need to await
            if (wasDeleted === true) {
                axios.delete(`${API_IMG_URL}/delete-all-images-for-product/${productId}`)
                     .catch(e => {}) // Fail silently
            }
        }
    }, [changesMade, productId])

    // Add another row to the end of the list.
    const addSkuRow = useCallback(() => {
        setSkusList([...skusList, {...DEFAULT_PRODUCT_SKU_DATA}])
    }, [skusList])

    // "Remove" a row from display using the row index number.
    // TODO: on form submit, these removed rows should be erased from the DB, too.
    const removeSkuRow = useCallback((rowIndex: number) => {
        // Don't let the user remove the last row.
        if (skusList.length === 1) return
        // Remove the sku from the list and add the deleted SKU ids to an input field.
        const removedSku = skusList.splice(rowIndex, 1)
        setSkusList([...skusList])
        const _deletedSkuIds = Array.from(new Set([...deletedSkuIds, removedSku[0].id]))
        setDeletedSkuIds(_deletedSkuIds)
    }, [skusList])

    // Update state variables for a row when the input values are changed
    const updateRow = useCallback((
        rowIndex: number, fieldName: keyof ProductSkuData, value: string|number, skuId: number,
    ) => {
        // Update list of updated SKU Ids
        const updateSkuIdsList = Array.from(new Set([...updatedSkuIds, skuId]))
        setUpdatedSkuIds(updateSkuIdsList)
        // Update list of SKU data.
        const newSkusList: ProductSkuData[] = [...skusList] // deep copy
        if (fieldName === 'id') {
            newSkusList[rowIndex].id = Number(value)
        } else if (fieldName === 'sku_id') {
            newSkusList[rowIndex].sku_id = String(value).trim()
        } else if (fieldName === 'name' || fieldName === 'name_display') {
            newSkusList[rowIndex].name = String(value)
            newSkusList[rowIndex].name_display = String(value)
        } else if (fieldName === 'dose' || fieldName === 'size') {
            newSkusList[rowIndex].dose = String(value)
            newSkusList[rowIndex].size = String(value)
        } else if (fieldName === 'price') {
            // IMPORTANT: don't convert this to number yet because it messes up decimals.
            // Also remove the leading '$'
            newSkusList[rowIndex].price = String(value).replace('$', '').replace(' ', '')
        }
        setSkusList(newSkusList)
    }, [skusList])

    // Validate inputs (Placeholder for basic frontend validation before submitting to backend).
    // Check for:
    // - redundant skuId's and sku names
    // - empty fields
    // - invalid numbers for price
    const frontendValidationErrors = () => {
        let errorCount = 0
        
        const skuIdPks = new Set()
        const skuIds = new Set() // sku IDs for user, not pk in db.
        const skuNames = new Set()
        const skuNamesDisplay = new Set()

        for (let sku of skusList) {
            // This would be a coding bug.
            if (!String(sku.id).trim()) throw new Error('Error #6613. Please refresh and try again.')
            // All of these fields must have a value.
            if (!sku.sku_id.trim()) throw new Error('Sku ID must not be empty.')
            if (!sku.name.trim()) throw new Error('Sku name must not be empty.')
            if (!sku.name_display.trim()) throw new Error('Sku display name must not be empty.')
            if (!sku.price.trim()) throw new Error('Sku price must not be empty.')
            // Validate skus' DB primary key IDs. Only consider it if the sku ID isn't -1 or 0,
            // which could be the default values for a new sku row, which don't have db pk id's yet.
            if (isNaN(sku.id) || (Number(sku.id) > 0 && skuIdPks.has(sku.id))) {
                throw new Error('System Error #4958. Unknown error. Please refresh and try again.')
            }
            skuIdPks.add(sku.id)
            // User-defined SKU IDs must be unique.
            if (skuIds.has(sku.sku_id)) {
                throw new Error('All SKU IDs must be unique.')
            }
            skuIds.add(sku.sku_id)
            // Sku names must be unique.
            if (skuNames.has(sku.name)) {
                throw new Error('All SKU names must be unique.')
            }
            skuNames.add(sku.name)
            // Sku display names must be unique
            if (skuNamesDisplay.has(sku.name_display)) {
                throw new Error('All SKU display names must be unique.')
            }
            skuNamesDisplay.add(sku.name_display)
            // All prices must be a number
            const skuPrice = Number(sku.price.replace('$', '').replace(' ', ''))
            if (isNaN(skuPrice) || skuPrice <= 0) {
                throw new Error('All SKU prices must be a positive decimal number.')
            }
        }

        

        return errorCount
    }
    
    // Submit registration data to AWS Cognito to create a new user.
    const onSubmitProductDetails = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setSuccessMessage('')
        setErrorMessage('')
        setShowSpinnerOverlay(true)

        // Make sure there is an image in the first position.
        if (!img1Success) {
            setErrorMessage('Primary image must not be empty')
            setShowSpinnerOverlay(false)
            return
        }

        // Run frontend validation for text input fields.
        try {
            if (frontendValidationErrors()) return
        } catch (e: any) {
            setErrorMessage(`${e.message}`)
            setShowSpinnerOverlay(false)
            return
        }

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        if (session.error) { 
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        // Config for API call.
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL

        // Add username and idToken to form submission
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)
        formData.append('businessId', String(userBizId))
        formData.append('productId', String(productId))
        
        // Submit to db
        axios.post(`${API_DB_URL}/new-user-init-${businessType}/5b`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            // Success
            setSuccessMessage('Product saved!')
            setProductAdded(true)
            setTimeout(() => {
                setShowSpinnerOverlay(false)
                setShow(false)
            }, 800)
            setTimeout(() => {
                setSuccessMessage('')
            }, 10000)
        }).catch(async (e) => {
            // Failure
            setShowSpinnerOverlay(false)
            setErrorMessage('')
            const errorCode = 'e#3564'
            const errorMessage = parseErrorObject(e, errorCode)
            setTimeout(() => {
                setErrorMessage(errorMessage)
            }, 350)
        })
    }



    return (
        <div className={`add-product-modal noprint ${showHideClassName}`}>

            {/* Background overlay */}
            <div 
                className='bg-shade' 
                onClick={cancel}
            />

            <div 
                ref={modalRef}
                className="modal-main"
            >
                <div className="modal-main-inner">
                    <div className='title'>
                        Add a product
                    </div>
                    
                    <div className='subtitle'>
                        Enter product details and up to four images<br/>
                        Images should be square
                    </div>
                    
                    <button
                        className="close-button"
                        onClick={cancel}
                    >
                        &#10006;
                    </button>

                    <div className='image-upload-container-outer'>
                        <div className='image-upload-container'>
                            <InputImageField
                                uploadType={VALID_UPLOAD_TYPES.IMG_PRODUCT}
                                imageName={VALID_IMG_NAMES.PRODUCT_1}
                                name='product-image'
                                wrapperStyle={{ width: '250px', minWidth: '250px', height: '250px' }}
                                imageStyle={{ width: '100%', height: '100%' }}
                                title='Upload'
                                desc='Primary image'
                                businessId={userBizId}
                                productId={productId}
                                setImageErrorMessage={setErrorMessage}
                                sessionObj={sessionObj}
                                setSessionObj={setSessionObj}
                                getUsernameAndIdToken={getUsernameAndIdToken}
                                onChange={() => {
                                    setChangesMade(true)
                                }}
                                expectImage={true}
                                isPreviewMode={!!isPreviewMode}
                                // Only for this first image.
                                onImgLoadSuccess={() => setImg1Success(true)}
                                onImgLoadError={() => setImg1Success(false)}
                                setPrimaryImgChanged={setPrimaryImgChanged}
                            />
                            <InputImageField
                                uploadType={VALID_UPLOAD_TYPES.IMG_PRODUCT}
                                imageName={VALID_IMG_NAMES.PRODUCT_2}
                                name='product-image'
                                wrapperStyle={{ width: '250px', minWidth: '250px', height: '250px' }}
                                imageStyle={{ width: '100%', height: '100%' }}
                                title='Upload'
                                desc='Image 2'
                                businessId={userBizId}
                                productId={productId}
                                setImageErrorMessage={setErrorMessage}
                                sessionObj={sessionObj}
                                setSessionObj={setSessionObj}
                                getUsernameAndIdToken={getUsernameAndIdToken}
                                onChange={() => {
                                    setChangesMade(true)
                                }}
                                expectImage={true}
                                isPreviewMode={!!isPreviewMode}
                            />
                            <InputImageField
                                uploadType={VALID_UPLOAD_TYPES.IMG_PRODUCT}
                                imageName={VALID_IMG_NAMES.PRODUCT_3}
                                name='product-image'
                                wrapperStyle={{ width: '250px', minWidth: '250px', height: '250px' }}
                                imageStyle={{ width: '100%', height: '100%' }}
                                title='Upload'
                                desc='Image 3'
                                businessId={userBizId}
                                productId={productId}
                                setImageErrorMessage={setErrorMessage}
                                sessionObj={sessionObj}
                                setSessionObj={setSessionObj}
                                getUsernameAndIdToken={getUsernameAndIdToken}
                                onChange={() => {
                                    setChangesMade(true)
                                }}
                                expectImage={true}
                                isPreviewMode={!!isPreviewMode}
                            />
                            <InputImageField
                                uploadType={VALID_UPLOAD_TYPES.IMG_PRODUCT}
                                imageName={VALID_IMG_NAMES.PRODUCT_4}
                                name='product-image'
                                wrapperStyle={{ width: '250px', minWidth: '250px', height: '250px' }}
                                imageStyle={{ width: '100%', height: '100%' }}
                                title='Upload'
                                desc='Image 4'
                                businessId={userBizId}
                                productId={productId}
                                setImageErrorMessage={setErrorMessage}
                                sessionObj={sessionObj}
                                setSessionObj={setSessionObj}
                                getUsernameAndIdToken={getUsernameAndIdToken}
                                onChange={() => {
                                    setChangesMade(true)
                                }}
                                expectImage={true}
                                isPreviewMode={!!isPreviewMode}
                            />
                        </div>
                    </div>

                    <form onSubmit={onSubmitProductDetails}>
                        <div className='header'>
                            PRODUCT DETAILS
                            <div className='subheader'>
                                Products will be sorted alphabetically by the Product IDs you assign to them.
                            </div>
                        </div>
                        <div className='input-row'>
                            <InputField
                                required
                                id={`${businessType}-registration-product-name`}
                                name="productName"
                                placeholder='Product name'
                                wrapperStyle={{ width: '30%' }}
                                value={inputName || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setInputName(e.target.value)
                                    setChangesMade(true)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                required
                                id={`${businessType}-registration-product-id`}
                                name="inputProductId"
                                placeholder='Product ID'
                                wrapperStyle={{ width: '30%' }}
                                value={inputProductId || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setInputProductId(e.target.value)
                                    setChangesMade(true)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                required
                                id={`${businessType}-registration-product-brand`}
                                name="productBrand"
                                placeholder='Brand'
                                wrapperStyle={{ width: '30%' }}
                                value={inputBrand || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setInputBrand(e.target.value)
                                    setChangesMade(true)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className='input-row'>
                            <InputField
                                required
                                id={`${businessType}-registration-product-headline`}
                                name="productHeadline"
                                placeholder='Headline'
                                wrapperStyle={{ width: '100%' }}
                                maxLength={140}
                                value={inputHeadline1 || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setInputHeadline1(e.target.value)
                                    setChangesMade(true)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className='input-row'>
                            <TextAreaField
                                required
                                id={`${businessType}-registration-product-description`}
                                name="productDescription"
                                style={{ minHeight: '170px', height: 'calc(100% - 2rem)', resize: 'none' }}
                                value={inputDescription1 || ''}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setInputDescription1(e.target.value)
                                    setChangesMade(true)
                                }}
                                // Special handling for <textarea> placeholder text.
                                className={(inputDescription1 === TEXTAREA_PLACEHOLDER_DESC) ? 'placeholder' : ''}
                                onFocus={() => {
                                    if (inputDescription1 === TEXTAREA_PLACEHOLDER_DESC) setInputDescription1(' ')
                                }}
                                onBlur={() => {
                                    if (inputDescription1.trim() === '') setInputDescription1(TEXTAREA_PLACEHOLDER_DESC)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>

                        <div className='input-row'>
                            <TextAreaField
                                required
                                id={`${businessType}-registration-product-uses`}
                                name="productUses"
                                style={{ minHeight: '170px', height: 'calc(100% - 2rem)', resize: 'none' }}
                                value={inputDescription2 || ''}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setInputDescription2(e.target.value)
                                    setChangesMade(true)
                                }}
                                // Special handling for <textarea> placeholder text.
                                className={(inputDescription2 === TEXTAREA_PLACEHOLDER_USES) ? 'placeholder' : ''}
                                onFocus={() => {
                                    if (inputDescription2 === TEXTAREA_PLACEHOLDER_USES) setInputDescription2(' ')
                                }}
                                onBlur={() => {
                                    if (inputDescription2.trim() === '') setInputDescription2(TEXTAREA_PLACEHOLDER_USES)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>

                        <div className='input-row'>
                            <TextAreaField
                                required
                                id={`${businessType}-registration-product-research`}
                                name="productResearch"
                                style={{ minHeight: '170px', height: 'calc(100% - 2rem)', resize: 'none' }}
                                value={inputDescription3 || ''}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setInputDescription3(e.target.value)
                                    setChangesMade(true)
                                }}
                                // Special handling for <textarea> placeholder text.
                                className={(inputDescription3 === TEXTAREA_PLACEHOLDER_RESEARCH) ? 'placeholder' : ''}
                                onFocus={() => {
                                    if (inputDescription3 === TEXTAREA_PLACEHOLDER_RESEARCH) setInputDescription3(' ')
                                }}
                                onBlur={() => {
                                    if (inputDescription3.trim() === '') setInputDescription3(TEXTAREA_PLACEHOLDER_RESEARCH)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>

                        <div className='header'>
                            SKUs
                            <div className='subheader'>
                                List the different doses/sizes for this product and their 
                                respective prices.
                                <br/><br/>
                                Skus will be sorted alphabetically by Sku ID.
                            </div>
                        </div>

                        {/* Loading spinner for SKUs rows */}
                        <img 
                            src={LoadingCircle} 
                            className='spinner skus' 
                            style={{ display: showSkusLoadingSpinner ? 'block' : 'none' }}
                        />

                        {/* 
                        *  Rows of SKU inputs 
                        *  Use +1 for all index numbers in forms for easier file naming on backend.
                        */}
                        <>
                            {skusList.map((sku, i) => (
                                <div
                                    // Key shouldn't be the same as index in this case.
                                    // Ensure unique keys when switching cards by using both 
                                    // SkuID (defaults to -1 for new product and is a real number 
                                    // for existing products), and the index.
                                    key={`${i}-${sku.id}`}
                                    className='input-row sku-row'
                                    style={{ display: showSkusLoadingSpinner ? 'none' : 'flex' }}
                                >
                                    {/* Hidden field for this SKU's ID (i.e. the pk in db taable)
                                        (if user is editing an existing SKU rather than creating a new one). */}
                                    <InputField
                                        id={`${businessType}-registration-sku-idpk-${i + 1}`}
                                        name={`sku-idpk-${i + 1}`}
                                        wrapperStyle={{ display: 'none', width: 0, overflow: 'hidden' }}
                                        value={skusList[i].id > 0 ? skusList[i].id : ''}
                                        disabled={!!isPreviewMode}
                                    />

                                    {/* Row input fields */}
                                    <div className='row-number'>
                                        {i + 1}
                                    </div>
                                    {/* SKU Name */}
                                    <InputField
                                        required
                                        id={`${businessType}-registration-product-sku-name-${i + 1}`}
                                        name={`sku-name-${i + 1}`}
                                        placeholder='Name'
                                        wrapperStyle={{ width: '25%' }}
                                        maxLength={140}
                                        value={skusList[i].name ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            updateRow(i, 'name', e.target.value, sku.id)
                                            setChangesMade(true)
                                        }}
                                        disabled={!!isPreviewMode}
                                    />
                                    {/* The user-defined SKU ID, NOT the pk in db */}
                                    <InputField
                                        required
                                        id={`${businessType}-registration-product-sku-id-${i + 1}`}
                                        name={`sku-id-${i + 1}`}
                                        placeholder='Sku ID'
                                        wrapperStyle={{ width: '20%' }}
                                        maxLength={140}
                                        value={skusList[i].sku_id ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            updateRow(i, 'sku_id', e.target.value, sku.id)
                                            setChangesMade(true)
                                        }}
                                        disabled={!!isPreviewMode}
                                    />
                                    {/* SKU dose/size */}
                                    <InputField
                                        required
                                        id={`${businessType}-registration-product-sku-dosesize-${i + 1}`}
                                        name={`sku-dosesize-${i + 1}`}
                                        placeholder='Dose or Size'
                                        wrapperStyle={{ width: '25%' }}
                                        maxLength={140}
                                        value={skusList[i].dose ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            updateRow(i, 'dose', e.target.value, sku.id)
                                            setChangesMade(true)
                                        }}
                                        disabled={!!isPreviewMode}
                                    />
                                    {/* SKU price */}
                                    <InputField
                                        required
                                        id={`${businessType}-registration-product-sku-price-${i + 1}`}
                                        name={`sku-price-${i + 1}`}
                                        placeholder='Price ($)'
                                        wrapperStyle={{ width: '20%' }}
                                        maxLength={140}
                                        value={skusList[i].price ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            updateRow(i, 'price', e.target.value, sku.id)
                                            setChangesMade(true)
                                        }}
                                        // Set type to "currency", and provide mock state variable setter.
                                        isCurrency={true}
                                        setValue={(val:string) => {
                                            updateRow(i, 'price', val, sku.id)
                                        }}
                                        disabled={!!isPreviewMode}
                                    />
                                    {/* Button to delete a row. Don't show if there's only one row */}
                                    <button
                                        type='button'
                                        className={`delete-row-button ${(skusList.length <= 1) ? 'noclick' : ''}`}
                                        onClick={() => {
                                            if (skusList.length > 1) {
                                                removeSkuRow(i)
                                                setChangesMade(true)
                                            }
                                        }}
                                    >
                                        &#10006;
                                    </button>
                                </div>
                            ))}
                        </>

                        {/* Hidden fields for updated/deleted SKU IDs (for removed rows) */}
                        <InputField
                            id={`${businessType}-registration-updated-sku-ids`}
                            name='skuIdsToUpdate'
                            value={updatedSkuIds.join(',')}
                            wrapperStyle={{ display: 'none' }}
                        />
                        <InputField
                            id={`${businessType}-registration-deleted-sku-ids`}
                            name='skuIdsToDelete'
                            value={deletedSkuIds.join(',')}
                            wrapperStyle={{ display: 'none' }}
                        />

                        {/* Button to add a row */}
                        <div 
                            className="input-row" 
                            style={{
                                marginTop: '40px', 
                                justifyContent: 'center',
                                display: showSkusLoadingSpinner ? 'none' : 'flex',
                            }}
                        >
                            <button
                                type='button'
                                className={`add-row-button`}
                                onClick={addSkuRow}
                            >
                                <Icon name='plus-circle' />
                            </button>
                        </div>

                        {successMessage ? (
                            <div className='success-message'>
                                {successMessage}
                            </div>
                        ): null}
                        
                        {errorMessage ? (
                            <div className='error-message'>
                                {errorMessage}
                            </div>
                        ): null}

                        <div className='buttons-container'>
                            <BlockButton 
                                style={{ width: '250px' }} 
                                buttonStyle="ghost-purple"
                                onClick={cancel}
                            >
                                Cancel
                            </BlockButton>
                            <BlockButton 
                                type='submit'
                                style={{ width: '250px' }} 
                                buttonStyle="purple"
                            >
                                Save
                            </BlockButton>
                        </div>
                    </form>
                    
                    {/* Foreground overlay that shows loading spinner and prevents extra clicking */}
                    <div className={`foreground-overlay ${showSpinnerOverlay ? 'show' : ''}`}>
                        <img src={LoadingCircle} className='spinner' />
                    </div>
                </div>
            </div>
        </div>
    )
}