import React, { useState } from "react";
import { PageNumbers } from "../buttons/page-numbers/PageNumbers";
import { RepTagPaging } from "../buttons/rep-tag-paging/RepTagPaging";

import './table.scss';

//    TODO:   add Search Bar on top, fix pagination

//    TODO:   add Search Bar on top, fix pagination


interface Data {
    orderNumber: string;
    date: string;
    amount: string;
    status: string;
    orderLink: string;
    slot6?: string;
    slot7?: string;
    slot8?: string;
    slot9?: string;
    slot10?: string;
    slot11?: string;
}

interface Props {
    data: Data[];
    columnNames: string[];
    visibleRows: number;
    tableStyle?: string;
    gridType?: string;
    headerStyle?: string;
    style?: any;
    pagingOption: 'paging-arrow' | 'paging-block' | 'null';
}

export const Table = ({ data, visibleRows, columnNames, style, tableStyle, gridType, headerStyle, pagingOption }: Props) => {


    const [page, setPage] = useState(2)
    //index 0 - 4


    const PagingOptions: { [key: string]: JSX.Element } = {
        "paging-arrow": <div className="paging-arrow"><PageNumbers style={{ marginLeft: '340px', marginTop: '20px' }} currentPage={page} totalPages={7} setPage={setPage} /></div>,
        "paging-block": <div className="paging-block"><RepTagPaging currentPage={page} totalPages={7} setPage={setPage} /></div>,
        "null": <></>
    }

    return (
        <>
            <div className={'grid-container ' + (gridType ?? '')} style={style}>

                {/* title row */}
                {columnNames.map((colName, x) => (
                    <div key={colName} className={(headerStyle)}>
                        {colName}
                    </div>
                ))}


                {data.slice((page - 1) * 5, ((page - 1) * 5) + 4).map((obj, index) => (
                    (index + 1 <= visibleRows) ? (

                        // Display Rows
                        <React.Fragment key={String(obj.orderNumber)}>
                            <div className={(tableStyle)}>{obj.orderNumber}</div>
                            <div className={(tableStyle)}>{obj.date}</div>
                            <div className={(tableStyle)}>{obj.amount}</div>
                            <div className={(tableStyle)}>{obj.status}</div>
                            <div className={(tableStyle)}>{obj.orderLink}</div>
                        </React.Fragment>
                    ) : (
                        // Additional Rows
                        <React.Fragment key={String(obj.slot6)}>
                            <div className={(tableStyle)}>{obj.slot6}</div>
                            <div className={(tableStyle)}>{obj.slot7}</div>
                            <div className={(tableStyle)}>{obj.slot8}</div>
                            <div className={(tableStyle)}>{obj.slot9}</div>
                            <div className={(tableStyle)}>{obj.slot10}</div>
                            <div className={(tableStyle)}>{obj.slot11}</div>
                        </React.Fragment>
                    )
                ))}

            </div>

            <div className='paging-style'>
                {PagingOptions[pagingOption]}
            </div>

        </>
    )
}