import './rep-tag-paging.scss';

interface Props {
  currentPage: number,
  totalPages: number,
  setPage: Function,
  style?: any,
}

export const RepTagPaging = ({ currentPage, totalPages, setPage, style }: Props) => {
    let start = currentPage - 1
    const pagesToShow = [start, start + 1, start + 2, start + 3, start + 4]

    return (
        <div className="paging-layout">
            <div className="left-text">
                <div className="text">
                    Showing 1 to 5 of 5 entries
                </div>
            </div>
            <div className="paging" style={style}>
                <div className="wrap radius-left" 
                     onClick={() => setPage((prev: number) => prev - 1)}
                >
                    PREVIOUS
                </div>

                {pagesToShow.map((page, x) => (
                    <div key={String(page)} 
                         className={`wrap middle-purple ${page === currentPage ? "active" : ''}`} 
                         onClick={() => setPage(page)}
                    >
                        {page}
                    </div>
                ))}

                <div className="wrap radius-right" 
                     onClick={() => setPage((prev: number) => prev + 1)}>
                    NEXT
                </div>
            </div>
        </div>
    )
}