import { PaymentAccounts, PaymentType } from "../components/pages/cart/CartShared";






export interface CarouselCardData {
    name: string;
    practice: string;
    description: string;
    imgSrc: string;
}


// TODO: This will be replaced with API call in future.
export const MOCK_carouselCardsData: CarouselCardData[] = [
    {
        name: 'TYLER BARRETT',
        practice: "CHIEF EXECUTIVE OFFICER",
        description: "With extensive experience as an executive in industries ranging from "
            + "software to healthcare, Tyler brings a clear vision to the company and a vivacious " 
            + "entrepreneur spirit that continues to guide the company forward. As a persistent "
            + "advocate for employee education, Tyler has gained more than a dozen Certified "
            + "Tissue Bank Auditors in the past year alone.",
        imgSrc: 'https://regenativelabs.com/wp-content/uploads/2021/07/Tyler-Barrett.jpg',
    },
    {
        name: 'ROGER LIU',
        practice: "CHIEF FINANCIAL OFFICER",
        description: "Mr. Liu has extensive work experience gained over 20 years in senior roles "
            + "with several leading financial institutions covering the securities, banking, insurance, "
            + "precious metal-gold and asset management businesses across Asia and North America.",
        imgSrc: 'https://regenativelabs.com/wp-content/uploads/2021/09/Roger_Headshot-Website-395x395-1.jpg',
    },
    {
        name: 'DR. JOHN SHOU',
        practice: "CHIEF SCIENTIFIC OFFICER",
        description: "After receiving a MS in Pharmacology and Ph.D. in Radiation Medicine and "
            + "Toxicology, John was a post-doctoral fellow at both The University of Texas MD Anderson "
            + "Cancer Center and Baylor College of Medicine. He then went to Maker Cell Technology, "
            + "where he became an Assistant Professor and Principal Investigator before moving on to "
            + "Chief Medical Officer and Head of Research and Development.",
        imgSrc: 'https://regenativelabs.com/wp-content/uploads/2021/07/Dr-John-Shou.jpg',
    },
    {
        name: 'DR. MICHAEL BAUER',
        practice: "MEDICAL DIRECTOR",
        description: "Board-certified in clinical pathology and transfusion medicine, Michael "
            + "served as a blood bank physician all across the country and led the stem cell programs "
            + "at two major institutions. A CLIA-certified laboratory director, Michael is nationally "
            + "recognized as a medical expert in eye and tissue banking, donor infectious disease "
            + "consulting, and laboratory testing.",
        imgSrc: 'https://regenativelabs.com/wp-content/uploads/2021/07/RGL-Headshot-Dr-Bauer.jpg',
    },
]







export interface StateTaxRates {
    [key: string]: number;
}

// State tax rate as percentage.
export const MOCK_stateTaxRates: StateTaxRates = {
    'AL': 4.00,
    'AK': 0.00,
    'AZ': 5.60,
    'AR': 6.50,
    'CA': 7.25,
    'CO': 2.90,
    'CT': 6.35,
    'DE': 0.00,
    'DC': 6.00,
    'FL': 6.00,
    'GA': 4.00,
    'HA': 4.00,
    'ID': 6.00,
    'IL': 6.25,
    'IN': 7.00,
    'IO': 6.00,
    'KS': 6.50,
    'KY': 6.00,
    'LA': 4.45,
    'ME': 5.50,
    'MD': 6.00,
    'MA': 6.25,
    'MI': 6.00,
    'MN': 6.875,
    'MS': 7.00,
    'MO': 4.225,
    'MT': 0.00,
    'NE': 5.50,
    'NV': 6.85,
    'NH': 0.00,
    'NJ': 6.625,
    'NM': 5.125,
    'NY': 4.00,
    'NC': 4.75,
    'ND': 5.00,
    'OH': 5.75,
    'OK': 4.50,
    'OR': 0.00,
    'PA': 6.00,
    'RI': 7.00,
    'SC': 6.00,
    'SD': 4.50,
    'TN': 7.00,
    'TX': 6.25,
    'UT': 6.10,
    'VT': 6.00,
    'VA': 5.30,
    'WA': 6.50,
    'WV': 6.00,
    'WI': 5.00,
    'WY': 4.00,
}









export interface StateShippingPrices {
    [key: string]: number;
}

// State tax rate as percentage.
export const MOCK_shippingPriceByState: StateShippingPrices = {
    'AL': 14.99,
    'AK': 10.99,
    'AZ': 15.99,
    'AR': 16.99,
    'CA': 17.99,
    'CO': 12.99,
    'CT': 16.99,
    'DE': 10.99,
    'DC': 16.99,
    'FL': 16.99,
    'GA': 14.99,
    'HA': 14.99,
    'ID': 16.99,
    'IL': 16.99,
    'IN': 17.99,
    'IO': 16.99,
    'KS': 16.99,
    'KY': 16.99,
    'LA': 14.99,
    'ME': 15.99,
    'MD': 16.99,
    'MA': 16.99,
    'MI': 16.99,
    'MN': 16.99,
    'MS': 17.99,
    'MO': 14.99,
    'MT': 10.99,
    'NE': 15.99,
    'NV': 16.99,
    'NH': 10.99,
    'NJ': 16.99,
    'NM': 15.99,
    'NY': 14.99,
    'NC': 14.99,
    'ND': 15.99,
    'OH': 15.99,
    'OK': 14.99,
    'OR': 10.99,
    'PA': 16.99,
    'RI': 17.99,
    'SC': 16.99,
    'SD': 14.99,
    'TN': 17.99,
    'TX': 16.99,
    'UT': 16.99,
    'VT': 16.99,
    'VA': 15.99,
    'WA': 16.99,
    'WV': 16.99,
    'WI': 15.99,
    'WY': 14.99,
}








/**
 * Data types for payments, and mock data for payment methods and payment accounts.
 *  - "Payment method" means bank, credit card, terms account contract, etc.
 *  - "Payment accont" mans a user's stored credit card number on file, bank account number on file, etc.
 */


export const MOCK_paymentAccounts: PaymentAccounts = {
    credit: [
        {
            id: 1,
            type: PaymentType.credit,
            name: 'AMEX Black Card',
            company: 'AMEX',
            routingNumber: '',
            accountNumber: '**** **** **** 7746',
        },
        {
            id: 2,
            type: PaymentType.credit,
            name: 'Chase Platinum Business',
            company: 'Visa',
            routingNumber: '',
            accountNumber: '**** **** **** 0154',
        },
    ],
    bank: [
        {
            id: 3,
            type: PaymentType.bank,
            name: 'BoA checking',
            company: 'Bank of America',
            routingNumber: '145734581',
            accountNumber: '********5848',
        },
        {
            id: 4,
            type: PaymentType.bank,
            name: 'Wells Fargo checking',
            company: 'Wells Fargo',
            routingNumber: '323341567',
            accountNumber: '********9697',
        },
    ],
    contract: [
        // Leave empty for testing purposes
        // {
        //     id: 5,
        //     type: PaymentType.contract,
        //     name: 'Contract 1',
        //     company: 'Regenative Labs',
        //     routingNumber: '',
        //     accountNumber: '20221015',
        // },
        // {
        //     id: 6,
        //     type: PaymentType.contract,
        //     name: 'Contract2',
        //     company: 'JP Morgan',
        //     routingNumber: '',
        //     accountNumber: '20221222',
        // },
    ]
}