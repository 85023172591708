import React from 'react'
import PrintOnlyContent from '../../shared/print/PrintOnlyContent';
// import axios from 'axios'

import './business-associate-agreement.scss';



type Props = {};

type State = {};



export class BusinessAssociateAgreement extends React.Component<Props, State> {

    state = {
        baadate: null,
        baaassociatename: null,
    }





    // submitClick = (e) => {
    //     const data =


    //     {
    //         "drid": window.props.user_id,
    //         "provider_name": this.state.baaassociatename,
    //         "date": this.state.baadate,
    //     }
    //     axios.post('https://api.medngine.com/regenlabs/baa/', data,
            // {
                // headers: {
                //     'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
                //     'content-type': 'application/json',

                // }
    //         }).then(response => {

    //             window.location.reload(true)

    //         }, []);


    // }


    handletextChange(e: any) {
        this.setState({ [e.target.name]: e.target.value }, () => { console.log(this.state) });
    }

    handlebaadateChange(e: any) {
        this.setState({ baadate: e.target.value }, () => { console.log(this.state.baadate) });
    }

    handleSubmit(e: any) {
        alert("Business Associate Agreement Request Submitted");
    }




    render() {
        return (
                    <div className="body-container baa-page">
                        <PrintOnlyContent title={true}>Medngine BAA page</PrintOnlyContent>
                        <div className="main-container">
                        <div className="header">
                            B A A
                        </div>
                        <div className="wrapper">
                        <br/><br/>
                            This Business Associate Agreement (the Agreement) is entered into as
                            of, by and between ATXWARE, LLC and ROW1, Inc. d/b/a Regenative Labs (the Covered Entity(s)) 
                            and (Business Associate).
                            <br/><br/>
                            For and in consideration of the mutual covenants contained in this
                            Agreement and intending to be legally bound hereby, the parties agree as follows:
                            <br/><br/><br/><br/>
                            <div className="header sections">
                            Section 1.
                            Definitions
                            </div>
                            <br/><br/><br/><br/>
                            &nbsp;&nbsp;&nbsp;The following terms used in this Agreement shall have the same meaning as those terms
                            in the HIPAA Rules: Breach, Data Aggregation, Designated Record Set, Disclosure, Health Care Operations,
                            Individual, Minimum Necessary, Notice of Privacy Practices, Protected Health Information, Required By Law,
                            Secretary, Security Incident, Subcontractor, Unsecured Protected Health Information, and Use.
                            <br/><br/>
                            Business Associate shall include the person or entity identified in this Agreement and shall generally have 
                            the same meaning as the term "Business Associate" at 45 C.F.R.  160.103.
                            <br/><br/>
                            "Covered Entity(s)" shall include the person or entity identified in this Agreement and shall generally have 
                            the same meaning as the term "Covered Entity(s)" at 45 C.F.R. 160.103.
                            <br/><br/>
                            HIPAA Rules shall mean the Privacy, Security, Breach Notification, and Enforcement Rules at 
                            45 CFR Part 160 and Part164.
                            <br/><br/>
                            HITECHAct shall mean the Health Information Technology for Economic and Clinical Health Act, 
                            Title XIII of the American Recovery and Reinvestment Act, Pub. L. No. 111-5.
                            <br/><br/>
                            Privacy Regulations shall mean the Standards for Privacy of Individually Identifiable Health Information 
                            at 45 C.F.R. Part 160 and Part 164, Subparts A and E.
                            <br/><br/>
                            Security Regulations shall mean the Security Standards for the Protection of Electronic 
                            Protected Health Information at 45 C.F.R. Part 160 and 164, Subparts A and C.
                            <br/><br/>
                            Terms used, but not otherwise defined, in this Agreement shall have the same meaning as those terms 
                            in 45 C.F.R  160.103, 164.304 and 164.501
                        </div>
                        <div className="wrapper">
                        <div className="header sections">
                            Section 2. Obligations and Activities of Business Associate
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            <ul>Business Associate agrees that it will:
                            <br/><br/><br/><li>
                            (a)  Not use or further disclose Protected Health Information other than as permitted or required by 
                            this Agreement or as required by law;
                            </li>
                            <br/><br/><li>
                            (b)  Use appropriate safeguards, and comply with Subpart C of 45 C.F.R. Part 164 with respect to electronic 
                            protected health information, to prevent use or disclosure of protected health information other than as 
                            provided for by the Agreement, which includes implementing requirements of the HIPAA Rules with
                            regard to electronicPHI;
                            </li>
                            <br/><br/><li>
                            (c)  Mitigate, subject to the liability cap, to the extent practicable, any harmful effect that is known to 
                            Business Associate of a use or disclosure of Protected Health Information by Business Associate in violation 
                            of the requirements of this Agreement;
                            </li>
                            <br/><br/><li>
                            (d)  Report to Covered Entity(s) within thirty (30) days, any use or disclosure of the Protected Health Information 
                            not provided for by this Agreement of which it becomes aware, with respect to breaches of 
                            Unsecured Protected Health Information, such report shall include at least the following information:
                            </li>
                            <br/><br/><li><li>
                            (i)
                            the identity of the individual whose information was accessed, acquired or disclosed during the breach;
                            </li></li>
                            <br/><br/><li><li>
                            (ii)
                            a brief description of what happened;
                            </li></li>
                            <br/><br/><li><li>
                            (iii)
                            the date of discovery of the breach;
                            </li></li>
                            <br/><li><li>
                            (iv)
                            the nature of the Unsecured Protected Health Information that was involved
                            <br/>
                            (e.g.
                            social security numbers, date of birth,etc.);
                            </li></li>
                            <br/><br/><li><li>
                            (v)
                            any steps individuals should take to protect themselves from potential harm resulting from the breach; and
                            </li></li>
                            <br/><br/><li><li>
                            (vi)
                            a brief description of what the Business Associate is doing to investigate the breach, to mitigate harm to 
                            individuals subject to the liability cap, and to protect against any further breaches.
                            </li></li>
                            <br/><br/><li>
                            (e)  Ensure that any agent, including a subcontractor, to whom it provides 
                            Protected Health Information received from, or created or received by, Business Associate on behalf of 
                            Covered Entity(s) agrees to the same restrictions and conditions that apply through this Agreement to 
                            Business Associate with respect to such information;
                            </li>
                            <br/><br/><li>
                            (f)  Provide access to Protected Health Information in a designated record set to Covered Entity(s) or, 
                            as directed by Covered Entity(s), to an Individual in order to meet the requirements under 45
                            C.F.R.164.524;
                            </li>
                            <br/><br/><li>
                            (g)  Make any amendment(s) to Protected Health Information in a designated record set that the 
                            Covered Entity(s) directs or agrees to pursuant to 45 C.F.R 164.526 at the request of Covered Entity(s) 
                            or an Individual, and in the time and manner reasonably designated by Covered Entity(s);
                            </li>
                            <br/><br/><li>
                            (h)  Make its internal practices, books, and records, including policies and procedures and 
                            Protected Health Information, relating to the use and disclosure of Protected Health Information 
                            received from, or created or received by Business Associate on behalf of, Covered Entity(s) available 
                            to the Secretary for purposes of the Secretary determining Covered Entity(s) compliance with the 
                            Privacy Regulations upon request by the Secretary;
                            </li>
                            <br/><br/><li>
                            (i)  Document such disclosures of Protected Health Information and information related to such disclosures 
                            as would be required for Covered Entity(s) of Business Associate to respond to a request by a Individual 
                            for an accounting of disclosures of Protected Health Information in accordance with 45 C.F.R. 164.528;
                            </li>
                            <br/><br/><li>
                            (j)  Provide to Covered Entity(s) or an Individual information collected in accordance with Section 2(i) of 
                            this Agreement, to satisfy the requirements for an accounting of disclosures of Protected Health Information 
                            in accordance with 45 C.F.R. 164.528 or Section 13405(c)(3) of the HITECH Act;
                            </li>
                            <br/><br/><li>
                            (k)  Implement administrative, physical and technical safeguards that reasonably and appropriately protect 
                            the confidentiality, integrity, and availability of any electronic Protected Health Information that it 
                            creates, receives, maintains, or transmits on behalf of Covered Entity(s), and effective February 17, 2010, 
                            to comply with the provisions of the Security Rule identified in Section 3(a)(1)(B) of this Agreement;
                            </li>
                            <br/><br/><li>
                            (l)  Ensure that any agent, including a subcontractor, to whom it provides electronic 
                            Protected Health Information agrees to implement reasonable and appropriate safeguards to protect it;
                            </li>
                            <br/><br/><li>
                            (m)  Report to Covered Entity(s) any material attempted or successful unauthorized access, use, disclosure, 
                            modification, or destruction of information or interference with system operations in an information system; 
                            and
                            </li>
                            <br/><br/><li>
                            (n)  To the extent the Business Associate is to carry out one or more of Covered Entity(s) obligation(s) 
                            under Subpart E of 45 CFR Part 164, comply with the requirements of Subpart E that apply to the 
                            Covered Entity(s) in the performance of such obligation(s).
                            </li></ul>
                            <br/><br/>
                            [The parties agree that the total maximum liability (the "Liability Cap") of Business Associate for all costs, 
                            expenses, mitigation, credit monitoring, indemnification, hold harmless responsibility, damages, penalties, 
                            fines, losses, attorneys fees, judgments, claims, costs associated with a Breach that occurs as a result 
                            of Business Associates handling of PHI, data, records covered hereunder, completion of the notification 
                            process of each patient as defined by the HIPAA Rules regulation at Business Associates expense, 
                            financial responsibility for any fines, settlements or legal action that may occur as a result of a 
                            Business Associates Breach or claims hereunder shall be limited to a maximum of the amount of the service fees 
                            received by Business Associate during the 6-month period prior to the breach.]
                        </div>
                        <div className="wrapper">
                        <div className="header sections">
                            Section 3. Permitted Uses and Disclosures by Business Associate
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            <h2>Statutory Duties</h2>
                            <br/><br/>
                            <ul>Business Associate acknowledges that is has a statutory duty under the HIPAA Rules and HITECH Act to, 
                            among other duties:
                            <br/><br/><br/><li>
                            (a)  Use and disclose Protected Health Information only in compliance with 45 C.F.R. 164.504(e) 
                            (the provisions of which have been incorporated into this Agreement); and
                            </li>
                            <br/><br/><li>
                            (b)  Comply with 45 C.F.R. 164.308 (Administrative Safeguards), 164.310 (Physical Safeguards). 164.312 (Technical
                            Safeguards), and 164.316 (Policies and Procedures and Documentation Requirements). In complying with 45 C.F.R
                            164.312 (Technical Safeguards), Business Associate shall consider guidance issued by the Secretary pursuant
                            to Section 13401(c) of the HITECH Act and, if a decision is made to not follow such guidance, document the
                            rationale for that decision. Business Associate acknowledges that its failure to comply with these or any other
                            statuary duties could result in civil and/or criminal penalties under 42 U.S.C. 1320d-5 and 1320d-6.
                            </li></ul>
                            <br/><br/><br/><br/>
                            <h2>General Use and Disclosure Provisions</h2>
                            <br/><br/>
                            Except as otherwise limited in this Agreement, Business Associate may use or disclose Protected Health Information 
                            to perform functions, activities, or services for, or on behalf of, Covered Entity(s) pursuant to the underlying 
                            service agreement between the parties, provided that use or disclosure would not violate the Privacy Regulations 
                            if done by Covered Entity(s). Business Associate agrees to make uses and disclosures and requests for protected 
                            health information consistent with Covered Entity(s) minimum necessary policies and procedures.
                            <br/><br/><br/><br/>
                            <h2>Specific Use and Disclosure Provisions</h2>
                            <br/><br/>
                            Except as otherwise limited in this Agreement, Business Associate may use Protected Health Information for 
                            the proper management and administration of the Business Associate or to carry out the legal responsibilities 
                            of the Business Associate.
                            <br/><br/>
                            Except as otherwise limited in this Agreement, Business Associate may disclose Protected Health Information 
                            for the proper management and administration of the Business Associate, provided that disclosures are required 
                            by law or Business Associate obtains reasonable assurances from the person to whom the information is disclosed 
                            that it will remain confidential and be used or further disclosed only as required by law or for the purpose for 
                            which it was disclosed to the person, and the person notifies the Business Associate of any instances of which 
                            it is aware in which the confidentiality of the information has been breached.
                            <br/><br/>
                            Except as otherwise limited in this Agreement, Business Associate may use Protected Health Information to provide 
                            Data Aggregation services to Covered Entity(s) as permitted by 42C.F.R.164.504(e)(2)(i)(B).
                            <br/><br/>
                            Business Associate may use Protected Health Information to report violations of law to appropriate 
                            Federal and State authorities, consistent with 45C.F.R 164.502(j)(1).
                            <br/><br/>
                            Business Associate may not use or disclose protected health information in a manner that would violate 
                            Subpart E of 45 CFR Part 164 if done by Covered Entity(s).
                        </div>
                        <div className="wrapper">
                        <div className="header sections">
                            Section 4. Obligations of Covered Entity(s)
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            <ul>Covered Entity(s) agree that they will:
                            <br/><br/><br/><li>
                            (a)  Notify Business Associate of any limitation(s) in its Notice of Privacy Practices in accordance with 
                            45 C.F.R. 164.520, to the extent that such limitation may affect Business Associates use or disclosure of 
                            Protected Health Information;
                            </li>
                            <br/><br/><li>
                            (b)  Notify Business Associate of any changes in, or revocation of, permission by Individual to use or disclose 
                            Protected Health Information, to the extent that such changes may affect Business Associates use or disclosure 
                            of Protected Health Information;
                            </li>
                            <br/><br/><li>
                            (c)  Notify Business Associate of any restriction to the use or disclosure of Protected Health Information 
                            that Covered Entity(s) has agreed to in accordance with 45 C.F.R. 164.522, to the extent that such restriction 
                            may affect Business Associates use or disclosure of Protected Health Information.
                            </li></ul>
                            </div>
                            <div className="wrapper">
                            <div className="header sections">
                            Section 5. Permissible Requests by Covered Entity(s)
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            Covered Entity(s) shall not request Business Associate to use or disclose Protected HealthInformation in 
                            any manner that would not be permissible under the Privacy Regulations if done by Covered Entity(s) or 
                            in contravention of this Agreement.
                        </div>
                        <div className="wrapper">
                        <div className="header sections">
                            Section 6. Term and Termination
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            <h2>Term.</h2>
                            <br/><br/><br/><br/><ul>
                            &nbsp;The Term of this Agreement shall be effective
                            immediately upon execution and shall terminate when all of the Protected Health Information provided by Covered
                            Entity(s) to Business Associate, or created or received by Business Associate on behalf of Covered Entity(s),
                            is destroyed or returned to Covered Entity(s) or, if it is not feasible to return or destroy the Protected Health
                            Information, protections are extended to such information, in accordance with the termination provisions in this
                            Section. Termination for Cause. Immediately upon Covered Entity(s) knowledge of a material breach by Business
                            Associate, Covered Entity(s) may either:
                            <br/><br/><br/><li>
                            (a)  Providean opportunity for Business Associate to cure the breach or end the violation and terminate this 
                            Agreement if Business Associate does not cure the breach or end the violation within the time reasonably specified 
                            by Covered Entity(s);
                            </li>
                            <br/><br/><li>
                            (b)  Immediately terminate this Agreement if Business Associate has breached a material term of this Agreement and 
                            cure is not possible; or
                            </li>
                            <br/><br/><li>
                            (c)  If neither termination nor cure is feasible, report the violation to the Secretary.
                            </li></ul>
                            <br/><br/><br/><br/>
                            <ul>
                            <h2>Effect of Termination.</h2>
                            <br/><br/><br/><br/><li>
                            (a)  Except as provided in paragraph (b) of this section, upon termination of this Agreement for any reason, 
                            Business Associate shall return or destroy all Protected Health Information received from Covered Entity(s), 
                            or created or received by Business Associate on behalf of Covered Entity(s). This provision shall apply to 
                            Protected Health Information that is in the possession of subcontractors or agents of Business Associate. 
                            Business Associate shall retain no copies of the Protected Health Information.
                            </li>
                            <br/><br/><li>
                            (b)  In the event that Business Associate determines that returning or destroying the Protected Health Information 
                            is not feasible, Business Associate shall provide to Covered Entity(s) notification of the conditions that make
                            return or destruction infeasible. Upon mutual agreement of the parties that return or destruction of Protected
                            Health Information is not feasible, Business Associate shall extend the protections of this Agreement to such
                            Protected Health Information and limit further uses and disclosures of such Protected Health Information to
                            those purposes that make the return or destruction infeasible, for so long as Business Associate maintains such
                            Protected Health Information.
                            </li></ul>
                        </div>
                        <div className="wrapper">
                        <div className="header sections">
                            Section 7. Miscellaneous
                            </div>
                            <br/><br/><br/><br/><br/><br/>
                            <h2>Regulatory References.</h2>
                            <br/>
                            A reference in this Agreement to a section in the Privacy Regulations or Security Regulations means the section 
                            then in effect, or as amended.
                            <br/><br/><br/><br/>
                            <h2>Amendment.</h2>
                            <br/>
                            The parties agree to take such action as is necessary to amend this Agreement from time to time as is necessary 
                            for Covered Entity(s) to comply with the requirements of the Privacy Regulations, the Security Regulations, 
                            and the Health Insurance Portability and Accountability Act, Public Law104-191.
                            <br/><br/><br/><br/>
                            <h2>Interpretation.</h2>
                            <br/>
                            Any ambiguity in this Agreement shall be resolved to permit Covered Entity(s) to comply with the 
                            Privacy Regulations and Security Regulations. This Agreement voids and supersedes any previous
                            Business Associate Agreement between the parties.
                            <br/><br/><br/><br/>
                            <h2>Governing Law.</h2>
                            <br/>
                            This Agreement has been executed and delivered in and shall be interpreted, construed, and enforced pursuant to 
                            and in accordance with laws of the State of Florida. The venue for any action, special proceeding, or other 
                            proceeding that may be brought or arise out of, in connection with, or by reason of this Agreement 
                            shall be in Florida.
                            <br/><br/><br/><br/>
                            <h2>Severability.</h2>
                            <br/>
                            In the event that any one or more of the provisions of this Agreement shall for any reason be held to be invalid,
                            illegal, or unenforceable, the invalidity, illegality, or unenforceability shall not affect any other provision,
                            and the Agreement shall be construed as though it had not contained the invalid, illegal, or unenforceable
                            provision.
                            <br/><br/><br/><br/>
                            <h2>Waiver.</h2>
                            <br/>
                            Any failure by either party at any time to enforce or require the strict performance of any of the terms or 
                            conditions hereof shall not constitute a waiver of its rights and shall not affect or impair either partys 
                            right to avail itself of the remedies available for subsequent breach of such terms or conditions.
                            <br/><br/><br/><br/>
                            <h2>Section Headings.</h2>
                            <br/>
                            Section headings are added solely to aid in the review of this Agreement and are not to be construed to affect 
                            the interpretation of this Agreement.
                            <br/><br/><br/><br/>
                            <h2>Binding Agreement.</h2>
                            <br/>
                            This Agreement shall be binding upon and inure to the benefit of Company, its successors and assigns, and 
                            shall be binding upon and inure to the benefit of Institution, his successors and permitted assigns.
                            <br/><br/><br/><br/>
                            <h2>Notice.</h2>
                            <br/>
                            Any notice required to be given under this Agreement shall be effective on the date of delivery if personally
                            delivered, on the next business day if delivered by Federal Express or other equivalent overnight courier, or on
                            the third business day after mailing first-class.
                            <br/><br/><br/><br/>
                            <h2>Compliance with Laws.</h2>
                            <br/>
                            Business Associate covenants to comply with all local, state and federal laws, rules and regulations in his 
                            performance of his duties and obligations hereunder, including but not limited to obtaining and maintaining 
                            appropriate licenses, and maintaining the privacy, confidentiality and security of protected health information 
                            as required under federal and state laws and regulations.
                            <br/><br/><br/><br/>
                            <h2>Intent.</h2>
                            <br/>
                            Notwithstanding any unanticipated effect of any of the provisions herein, neither party intends to 
                            violate the Medicare and Medicaid fraud and abuse or anti-kickback provisions 
                            (including 42 U.S.C. 1395nn and 1396 and 42 U.S.C.  1320a-7b)
                            <br/><br/><br/><br/>
                            <h2>Amendments and Counterparts.</h2>
                            <br/>
                            This Agreement and any amendments hereto shall be in writing and executed in multiple copies on 
                            behalf of Company and Institution. Each multiple copy shall be deemed an original, but all 
                            multiple copies together shall constitute one and the same instrument.
                            <br/><br/><br/><br/>
                            <h2>Mediation and Arbitration.</h2>
                            <br/>
                            Both parties agree, in good faith, to attempt to resolve any dispute which may arise under this 
                            Agreement by submitting such dispute for nonbinding mediation. This Section shall not prevent either 
                            party from electing to terminate this Agreement in accordance with its termination provisions. 
                            If mediation is unsuccessful, the parties agree to submit all disputes to binding arbitration 
                            according to the commercial rules of arbitration of the American Arbitration Association. 
                            All disputes will be arbitrated by three independent arbitrators, to be chosen as follows: 
                            each party shall choose one independent arbitrator. The two arbitrators chosen by the parties shall 
                            choose a third arbitrator. If the two arbitrators chosen by the parties cannot agree on an independent 
                            third arbitrator, the AAA shall appoint an independent third arbitrator. All arbitration shall be 
                            conducted in Pensacola, Florida.
                        </div>
                        {/* <div onClick={e => this.submitClick(e)} className="uicomponentsubmitframe-C61RwL">
                        </div>
                        <div onClick={e => this.submitClick(e)} className="submit-C61RwL">
                            IAGREE
                        </div> */}
                        <div className="function-area">
                        <div className="header bottom">
                            BUSINESS ASSOCIATE AGREEMENT
                        </div>
                        <input onChange={e => this.handletextChange(e)} className="baaassociatename-C61RwL" name="baaassociatename" placeholder="Associate Name" required type="text" />
                        <input onChange={e => this.handlebaadateChange(e)} className="baadate-C61RwL" name="baadate" placeholder="Todays date" required type="date" />
                    </div>
                    </div>
                    </div>
        );
    }
}