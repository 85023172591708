import { T_saveAddressId } from '../../auth/Account'
import { Address } from './CartShared'

import { MOCK_API_get_state_tax_rate, MOCK_API_get_shipping_price } from '../../../api/mockApi'

/**
 * This function run user selects an address.
 * It makes API calls to get the new sales tax rates and shipping price for the address,
 * then updates state variables (which will then trigger other functions to run, such as
 * _getCartPrices.tsx).
 */
const _updateAddress = (
    sessionJson: string,
    setFormattedAddress: React.Dispatch<React.SetStateAction<string>>,
    setUserStateTaxRate: React.Dispatch<React.SetStateAction<number>>,
    setUserShippingPrice: React.Dispatch<React.SetStateAction<number>>,
    userAddresses: Address[],
    selectedAddressOptionIndex: number,
    saveAddressId: T_saveAddressId,
) => {
    if (userAddresses.length) {
        // Display user's selected address.
        const addr = userAddresses[selectedAddressOptionIndex]
        const addrLine2 = addr.street2 ? `${addr.street2}\n` : ``
        const addressText = 
            `${addr.name}\n${addr.street1}\n${addrLine2}${addr.city}, ${addr.state}, ${addr.zip}`
        setFormattedAddress(addressText)
        // Get user's tax rate based on the US State of their shipping address.
        MOCK_API_get_state_tax_rate(addr.state).then((taxRate) => {
            setUserStateTaxRate(taxRate)
        })
        // Get shipping price to the user.
        MOCK_API_get_shipping_price(addr.state).then((shipPrice) => {
            setUserShippingPrice(shipPrice)
        })
        // Save address ID to Redis and Account Context
        saveAddressId(sessionJson, addr.id)
    }
}

export default _updateAddress