import React from 'react';
import { Link } from "react-router-dom";

import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar";

import './consumer-registration.scss';



export const ConsumerRegistration2 = () => {

  return (
    <>
      <HeaderBanner
        imageName="doctorpen"
        imageText="EMAIL CONFIRMED"
        imageSpacerColor='BLUE'
      />
      <div className="body-container-no-vert-pad consumer-register-page-layout">
        <div className="top-paragraph">
          test text 1 test text 1 test text 1 test text 1 test text 1 test text 1
          test test text 1 test text 1 test text 1 test text 1 test text 1 test
          text 1 test text 1 test text 1 test text 1 test text 1 test test text 1
          test text 1 test text 1 test text 1 test text 1 test text 1 test test
          text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test
          text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test
          text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test
          text 1 test text 1 test text 1 test text 1 test text 1 test text 1
        </div>
        <div className="progress-bar-placement">
          <ProgressBar whichStep={2} numberOfSteps={2} />
        </div>
        <div className="header3">
          REGISTRATION COMPLETE!
        </div>
        <div className="center-text">
          <Link to={`/account`}>
            <BlockButton
              buttonStyle='desktop-header-buttons purple'
              style={{ paddingLeft: '40px', paddingRight: '40px',  }}
            >
              MY ACCOUNT
            </BlockButton>

          </Link>
        </div>
      </div>
    </>
  );
}
