import React from 'react';
import { InputField } from '../../../shared/form/input-field/InputField';
import { Table } from "../../../shared/tables/Table";
import { Link } from "react-router-dom";
import './registered-procedures.scss';


interface Props { };

export const RegisteredProcedures = ({ }: Props) => {

  const mockDataTable = [
    { orderNumber: "151435411", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435412", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435413", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435414", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435415", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435416", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435417", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435418", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435419", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435421", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435422", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435423", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435424", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435425", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435426", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435427", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435428", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435429", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435430", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    { orderNumber: "151435431", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
  ]

  return (
    <>
      <div className="body-container registered-procedures-layout">
        <div className="header">REGISTERED PROCEDURES</div>
        <div className="header small">BY DR.FRANKENSTEIN</div>
        <InputField
          required
          style={{ width: '700px', marginTop: '15px' }}
          name='vendorfirst'
          placeholder='Search by Order Number, Product, etc.'
        />
        <Table
          headerStyle="procedures-header-cols"
          gridType="procedures-container"
          tableStyle="procedures-items-fill"
          style={{ marginTop: '45px' }}
          visibleRows={4}
          data={mockDataTable}
          columnNames={["Order Number", "Date", "PAYMENT TYPE", "AMOUNT", "Order Link"]}
          pagingOption="paging-arrow"
        />
      </div>
    </>
  )
}