import { useState } from 'react'
import './greyback.scss'


interface Props {
    children: any,
    onClick?: Function,
    style?: any,
    greybackStyle?: string,
    closable: boolean,
    // If it's visible when the page is first mounted.
    initiallyVisible?: boolean,
    // Initial height, used for close animation.
    initalHeight?: string,
}


export const Greyback = ({ 
    children, style, closable, onClick, greybackStyle, initiallyVisible = true,
}: Props) => {
    // This should be equal to or longer than the transition-duration property in CSS.
    const HIDE_EL_TIMEOUT = 600 // ms

    // For triggering the fade-out animation.
    const [visible, setVisible] = useState(initiallyVisible)
    // For actually removing this element from the DOM.
    const [exists, setExists] = useState(true)

    const closeModal = () => {
        setVisible(false)
        setTimeout(() => {
            setExists(false)
        }, HIDE_EL_TIMEOUT)
    }

    const isClosable = () => {
        if (closable) {
            return (
                <button 
                    className="x-button"
                    onClick={closeModal}
                >
                    &#10006;
                </button>
            )
        } else {
            return (
                <div className="space-maintain"></div>
            )
        }
    }

    return (
        (exists ? (
            <div
                className={`greyback ${greybackStyle ?? ''} ${!visible ? 'hide' : ''}`} 
                style={style}
            >
                {isClosable()}
                <div className="text-position">{children}</div>
            </div>
        ) : null)
    );
}