import "./credit-application.scss"



interface Props {
	activeStep: number;
}
/**
 * Success page after Credit Application form is completed.
 * 
 * The props are only here for parallel structure purposes.
 */
export const CreditApplicationStep5 = ({ activeStep }: Props) => {
    const STEP: number = 5
    
    return (
        <div style={{display: `${(activeStep === STEP) ? 'block' : 'none'}`}}>
            <div className='section-title page-title'>
                Credit Application complete
            </div>

            <div className='success-message'>
                You will notified by email of your application results.<br/><br/>

                Vestbulum fermentum turois orci fermentum nulla, non finibus liqula enim at diam. 
                Sed volutpat efficitur blandit. Pellentesque vel quam interdum, pulvi.
                nar nunc condimentum. ultricies lacus. Sed trinallla lectus vel nIlvinar nosuere. 
                Proin ornare metus auis urna daninus. alrhoncus mauris vulputate. 
                Integer a arcu quis nisi sagittis euismod eu nec lectus. 
                Donec nec tempor velit, at tincidunt felis.
            </div>
        </div>
    )
}















