/**
 * Admin page - for internal employess only.
 */

import React, { useState } from 'react'
import axios from 'axios'

import { InputField } from '../shared/form/input-field/InputField'
import { BlockButton } from '../shared/buttons/block-button/BlockButton'
import { CONVERT_AT_SIGN_TO } from '../../../config'

import parseErrorObject from '../../helpers/parseErrorObject'

import './admin.scss'


// Environment vars
const _ENV = process.env.REACT_APP__ENV
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL
const API_DB_URL = process.env.REACT_APP_API_DB_URL



// TODO: (!!!) Make this page only accessible for authenticated admin user.

export const SysAdmin = () => {
    const [email, setEmail] = useState('')
    const [cfItemPath, setCfItemPath] = useState('')
    const [logRowsCount, setLogRowsCount] = useState('')
    const [logResults, setLogResults] = useState<any>()
    const [memDb_GET_key, setMemDb_GET_key] = useState('')
    const [memDb_SET_key, setMemDb_SET_key] = useState('')
    const [memDb_SET_val, setMemDb_SET_val] = useState('')

    const [memDb_GET_key2, setMemDb_GET_key2] = useState('')
    const [memDb_SET_key2, setMemDb_SET_key2] = useState('')
    const [memDb_SET_val2, setMemDb_SET_val2] = useState('')
    const [memDb_SCAN_cursor, setMemDb_SCAN_cursor] = useState('')
    const [memDb_SCAN_count, setMemDb_SCAN_count] = useState('')
    const [memDb_SCAN_match, setMemDb_SCAN_match] = useState('')
    const [memDb_SCAN_type, setMemDb_SCAN_type] = useState('')
    const [memDb_searchStr, setMemDb_searchStr] = useState('')

    const [userId, setUserId] = useState('')
    const [clinicId, setClinicId] = useState('')
    const [productId, setProductId] = useState('')
    const [businessId, setBusinessId] = useState('')
    
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [userTypeId, setUserTypeId] = useState('')
    const [userTypeName, setUserTypeName] = useState('')

    const [rolesToAdd, setRolesToAdd] = useState('')
    const [rolesToRemove, setRolesToRemove] = useState('')

    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    // Success message handling.
    const _success = (res: any) => {
        let message = (typeof res === 'string') ? res 
                        : (typeof res.data === 'string') ? res.data 
                        : res.data ? JSON.stringify(res.data) 
                        : JSON.stringify(res)
        setErrorMessage('')
        setSuccessMessage('')
        setTimeout(() => {
            setIsLoading(false)
            setSuccessMessage(message)
        }, 400)
    }
    // Error message handling.
    const _error = (e: any) => {
        let message = parseErrorObject(e)
        setSuccessMessage('')
        setErrorMessage('')
        setTimeout(() => {
            setIsLoading(false)
            setErrorMessage(message)
        }, 400)
    }
    // Close button for success/error dialog boxes at bottom of screen.
    const _close_dialog = (isSuccessDialog: boolean, isErrorDialog: boolean) => {
        if (isSuccessDialog) setSuccessMessage('')
        if (isErrorDialog) setErrorMessage('')
    }

    
    const deleteUserByEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('email', email)

        axios.post(`${API_DB_URL}/admin/delete-user`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const createUserByEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('email', email)
        formData.set('username', username)
        formData.set('password', password)
        formData.set('userId', userId)
        formData.set('userTypeId', userTypeId)
        formData.set('userTypeName', userTypeName)

        axios.post(`${API_DB_URL}/admin/create-user`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const changeUserRolesByEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('email', email)
        formData.set('rolesToAdd', rolesToAdd)
        formData.set('rolesToRemove', rolesToRemove)

        axios.post(`${API_DB_URL}/admin/set-user-roles`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const invalidateCloudFrontCacheByPath = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const qp = encodeURIComponent(`${cfItemPath}`)
        
        axios.get(`${API_IMG_URL}/admin/cache-invalidate-for/?path=${qp}`)
        .then((res) => _success(res))
        .catch((e) => _error(parseErrorObject(e)))
    }

    const memoryDb_GET = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('key', memDb_GET_key)

        axios.post(`${API_DB_URL}/admin/memorydb/get`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const memoryDb_SET = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('key', memDb_SET_key)
        formData.set('val', memDb_SET_val)

        axios.post(`${API_DB_URL}/admin/memorydb/set`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }












    const memoryDb_GET2 = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('key', memDb_GET_key2)

        axios.post(`${API_DB_URL}/admin/memorydb/get2`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const memoryDb_SET2 = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('key', memDb_SET_key2)
        formData.set('val', memDb_SET_val2)

        axios.post(`${API_DB_URL}/admin/memorydb/set2`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }

    const memoryDb_SCAN2 = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('cursor', memDb_SCAN_cursor)
        formData.set('count', memDb_SCAN_count)
        formData.set('match', memDb_SCAN_match)
        formData.set('type', memDb_SCAN_type)

        axios.post(`${API_DB_URL}/admin/memorydb/scan2`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }






    const makeSearchableDoctorAndClinic = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('user_id', userId)
        formData.set('clinic_id', clinicId)

        axios.post(`${API_DB_URL}/admin/memorydb/add-doctor-clinic-to-search`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }
    const makeSearchableProductAndSkus = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('product_id', productId)

        axios.post(`${API_DB_URL}/admin/memorydb/add-product-and-skus-to-search`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }
    const makeSearchableBusiness = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('business_id', businessId)

        axios.post(`${API_DB_URL}/admin/memorydb/add-business-to-search`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }




    const emptyUsersCart = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('user_id', userId)

        axios.post(`${API_DB_URL}/admin/memorydb/empty-users-cart`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }
    const getUsersCart = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('user_id', userId)

        axios.post(`${API_DB_URL}/admin/memorydb/get-users-cart`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }


    
    


    
    const runSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('searchWords', memDb_searchStr)

        axios.post(`${API_DB_URL}/admin/memorydb/search`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => _success(res))
        .catch(e => _error(parseErrorObject(e)))
    }









    const getLogRows = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.set('logRowsCount', logRowsCount)

        axios.post(`${API_DB_URL}/admin/get-logs`, 
            Object.fromEntries(formData),
            config
        )
        .then((res) => {
            setLogResults(res?.data || null)
            _success(res) // Don't show success message for logs.
        })
        .catch(e => _error(parseErrorObject(e)))
    }

    const _stringify = (obj: any) => {
        if (!obj) return 'n/a'
        try {
            return JSON.stringify(obj)
        } catch (e) {
            try {
                return obj.toString()
            } catch (e2) {
                return String(obj)
            }
        }
    }

    
    
    return (
        <div className='body-container-admin'>
            <div className='page-title'>
                SYS-ADMIN PAGE
            </div>

            {/* Create a user IN AWS COGNITO ONLY. */}
            <div className='section'>
                <form onSubmit={createUserByEmail}>
                    <div className='title'>
                        Create user in Cognito 
                    </div>
                    <div className='description'>
                        Warning: this is only for users who already exist in the db. Like users 
                        created in Sequelize seeders.
                        <br/><br/>
                        Only creates a user in Cognito, and nothing else.
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-create-cognito-user-email'
                            name='admin-create-cognito-user-email'
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value.trim())
                            }}
                            placeholder='xxx@xxx.com'
                        />
                        <InputField
                            id='admin-create-cognito-user-username'
                            name='admin-create-cognito-user-username'
                            value={email.replace('@', CONVERT_AT_SIGN_TO)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value.trim())
                            }}
                            placeholder='cognito username (auto)'
                            disabled={true}
                        />
                        <InputField
                            id='admin-create-cognito-user-pw'
                            name='admin-create-cognito-user-pw'
                            type='password'
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value.trim())
                            }}
                            placeholder='password'
                        />
                        <InputField
                            id='admin-create-cognito-user-userId'
                            name='admin-create-cognito-user-userId'
                            value={userId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserId(event.target.value.trim())
                            }}
                            placeholder='user ID in db'
                        />
                        <InputField
                            id='admin-create-cognito-user-userTypeId'
                            name='admin-create-cognito-user-userTypeId'
                            value={userTypeId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserTypeId(event.target.value.trim())
                            }}
                            placeholder='user type ID (1-4)'
                        />
                        <InputField
                            id='admin-create-cognito-user-userTypeName'
                            name='admin-create-cognito-user-userTypeName'
                            value={userTypeName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserTypeName(event.target.value.trim().toLowerCase())
                            }}
                            placeholder='user type Name (ex: vendor)'
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            CREATE USER
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* "Delete" (deactivate) user from AWS Cognito UserPool, mr_user, and mr_email. */}
            <div className='section'>
                <form onSubmit={deleteUserByEmail}>
                    <div className='title'>
                        Delete user by email
                    </div>
                    <div className='description'>
                        Warning: this will delete (deactivate) any user who has ever used this email.
                        <br/><br/>
                        Deletes: 
                        <br/>
                        [AWS cognito user (from AWS UserPool), mr_user db row, mr_email db row]
                        <br/><br/>
                        Does NOT delete: 
                        <br/>
                        business/products/skus/clinics/addresses/phone/etc that are associated w/ the user
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-delete-cognito-user'
                            name='admin-delete-cognito-user'
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value)
                            }}
                            placeholder='xxx@xxx.com'
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            DELETE USER
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Add or remove roles from user, by email. */}
            <div className='section'>
                <form onSubmit={changeUserRolesByEmail}>
                    <div className='title'>
                        Change user roles by email
                    </div>
                    <div className='description'>
                        <>
                        {`
                        Possible values: [
                            'is_public', 'is_regen', 'vendor', 'supplier', 'clinic', 'rep', 'general', 
                            'conductor', 'manager', 'admin', 'sysadmin',
                        ]
                        `}
                        </>
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-change-roles-email'
                            name='admin-change-roles-email'
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value)
                            }}
                            placeholder='xxx@xxx.com'
                        />
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-roles-to-add'
                            name='admin-roles-to-add'
                            value={rolesToAdd}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setRolesToAdd(event.target.value)
                            }}
                            placeholder='Comma-separated list of role(s) to ADD'
                        />
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-roles-to-remove'
                            name='admin-roles-to-remove'
                            value={rolesToRemove}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setRolesToRemove(event.target.value)
                            }}
                            placeholder='Comma-separated list of role(s) to REMOVE'
                        />
                    </div>
                    <div className='row'>
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            SET ROLES
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Invalidate CloudFront cache */}
            <div className='section'>
                <form onSubmit={invalidateCloudFrontCacheByPath}>
                    <div className='title'>
                        Invalidate cloudfront cache by item
                    </div>
                    <div className='description'>
                        Doesn't delete anything - just clears the cache for new image to show.
                        <br/><br/>
                        Enter in the s3 path of the item(s)
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-invalidate-cf-cache'
                            name='admin-invalidate-cf-cache'
                            value={cfItemPath}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCfItemPath(event.target.value)
                            }}
                            placeholder='ex: uploads/business/<businessId>/BANNER_<1-4> || uploads/products/<productId>/PRODUCT_<1-4>'
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            INVALIDATE CACHE
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* GET/SET MemoryDB (redis) key/vals */}
            <div className='section'>
                <div className='title'>
                    MemoryDB/Redis - basic actions
                </div>
                {/* <div className='description'>    
                    (This part is the OLD way, using `node-redis`)
                </div> */}
                {/* <form onSubmit={memoryDb_GET}>
                    <div className='row'>
                        <InputField
                            id='admin-memdb-get-key'
                            name='admin-memdb-get-key'
                            value={memDb_GET_key}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_GET_key(event.target.value)
                            }}
                            placeholder='GET: key'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <div style={{ width: '150px' }}>
                        </div>
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            GET
                        </BlockButton>
                    </div>
                </form>
                <form onSubmit={memoryDb_SET}>
                    <div className='row'>
                        <InputField
                            id='admin-memdb-set-key'
                            name='admin-memdb-set-key'
                            value={memDb_SET_key}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SET_key(event.target.value)
                            }}
                            placeholder='SET: key'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-memdb-set-val'
                            name='admin-memdb-set-val'
                            value={memDb_SET_val}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SET_val(event.target.value)
                            }}
                            placeholder='SET: val'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            SET
                        </BlockButton>
                    </div>
                </form> */}
                {/* <div className='description'>    
                    (This part is the NEW way, using `ioredis`)
                </div> */}
                <form onSubmit={memoryDb_GET2}>
                    <div className='row'>
                        <InputField
                            id='admin-memdb-get-key2'
                            name='admin-memdb-get-key2'
                            value={memDb_GET_key2}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_GET_key2(event.target.value)
                            }}
                            placeholder='GET: key'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <div style={{ width: '150px' }}>
                            {/* just for alignment */}
                        </div>
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            GET
                        </BlockButton>
                    </div>
                </form>
                <form onSubmit={memoryDb_SET2}>
                    <div className='row'>
                        <InputField
                            id='admin-memdb-set-key2'
                            name='admin-memdb-set-key2'
                            value={memDb_SET_key2}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SET_key2(event.target.value)
                            }}
                            placeholder='SET: key'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-memdb-set-val2'
                            name='admin-memdb-set-val2'
                            value={memDb_SET_val2}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SET_val2(event.target.value)
                            }}
                            placeholder='SET: val'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            SET
                        </BlockButton>
                    </div>
                </form>
                <form onSubmit={memoryDb_SCAN2}>
                    <div className='row'>
                        <InputField
                            id='admin-memdb-scan-cursor'
                            name='admin-memdb-scan-cursor'
                            value={memDb_SCAN_cursor}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SCAN_cursor(event.target.value)
                            }}
                            placeholder='SCAN: cursor'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-memdb-scan-match'
                            name='admin-memdb-scan-match'
                            value={memDb_SCAN_match}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SCAN_match(event.target.value)
                            }}
                            placeholder='SCAN: match'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-memdb-scan-count'
                            name='admin-memdb-scan-count'
                            value={memDb_SCAN_count}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SCAN_count(event.target.value)
                            }}
                            placeholder='SCAN: count'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-memdb-scan-type'
                            name='admin-memdb-scan-type'
                            value={memDb_SCAN_type}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_SCAN_type(event.target.value)
                            }}
                            placeholder='SCAN: type'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            SCAN
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Update MemoryDB/Redis with search-keys for (DOCTOR + CLINIC) */}
            <div className='section'>
                <div className='title'>
                    Add doctor/clinic to search
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>THIS WON'T WORK ON LOCAL</span>
                </div>
                <div className='description'>
                    Both user ID and clinic ID are required.
                </div>
                <form onSubmit={makeSearchableDoctorAndClinic}>
                    <div className='row'>
                        <InputField
                            id='admin-searchable-provider-user-id'
                            name='admin-searchable-provider-user-id'
                            value={userId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserId(event.target.value)
                            }}
                            placeholder='User ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <InputField
                            id='admin-searchable-provider-clinic-id'
                            name='admin-searchable-provider-clinic-id'
                            value={clinicId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setClinicId(event.target.value)
                            }}
                            placeholder='Clinic ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            Make searchable Clinic + Doctor
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Update MemoryDB/Redis with search-keys for (PRODUCT + SKUS) */}
            <div className='section'>
                <div className='title'>
                    Add product+skus to search
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>THIS WON'T WORK ON LOCAL</span>
                </div>
                <div className='description'>
                    Enter the product ID (not sku ID)
                </div>
                <form onSubmit={makeSearchableProductAndSkus}>
                    <div className='row'>
                        <InputField
                            id='admin-searchable-product-id'
                            name='admin-searchable-product-id'
                            value={productId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setProductId(event.target.value)
                            }}
                            placeholder='Product ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            Make searchable Product + SKUs
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Update MemoryDB/Redis with search-keys for (BUSINESS) */}
            <div className='section'>
                <div className='title'>
                    Add business to search
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>THIS WON'T WORK ON LOCAL</span>
                </div>
                <div className='description'>
                    Enter the business ID
                </div>
                <form onSubmit={makeSearchableBusiness}>
                    <div className='row'>
                        <InputField
                            id='admin-searchable-business-id'
                            name='admin-searchable-business-id'
                            value={businessId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setBusinessId(event.target.value)
                            }}
                            placeholder='business ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            Make searchable Business
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* SEARCH MemoryDB/Redis */}
            <div className='section'>
                <div className='title'>
                    Search
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>LOCAL SEARCH CALLS SIT ENV</span>
                </div>
                <div className='description'>
                    This uses the same mechanism as the search bar in the nav header. 
                    Good for debugging.
                    <br/><br/>
                    Remember that on local envs this makes requests to SIT env, since 
                    connections can't be made to MemoryDb from outside of VPC.
                </div>
                <form onSubmit={runSearch}>
                    <div className='row'>
                        <InputField
                            id='search-str'
                            name='search-str'
                            value={memDb_searchStr}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMemDb_searchStr(event.target.value)
                            }}
                            placeholder='search string'
                            wrapperStyle={{ width: '250px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            Search (for business)
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Get user's cart from MemoryDB/Redis (unformatted) */}
            <div className='section'>
                <div className='title'>
                    View cart by userId
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>THIS WON'T WORK ON LOCAL</span>
                </div>
                <div className='description'>
                    Enter the user's ID. Cart format is:<br/>
                    &lcub;&lt;product_id&rt;: &lcub;&lt;sku_id&gt;: &lt;count&gt;&rcub;&rcub;
                </div>
                <form onSubmit={getUsersCart}>
                    <div className='row'>
                        <InputField
                            id='admin-cart-get-by-user-id'
                            name='admin-cart-get-by-user-id'
                            value={userId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserId(event.target.value)
                            }}
                            placeholder='user ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            View cart
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Empty/clear a user's cart from MemoryDB/Redis (unformatted) */}
            <div className='section'>
                <div className='title'>
                    Empty/clear cart by userId
                    <br/>
                    <span style={{ color: 'red', fontSize: '70%', }}>THIS WON'T WORK ON LOCAL</span>
                </div>
                <div className='description'>
                    This will remove everything from the user's cart.
                </div>
                <form onSubmit={emptyUsersCart}>
                    <div className='row'>
                        <InputField
                            id='admin-cart-empty-by-user-id'
                            name='admin-cart-empty-by-user-id'
                            value={userId}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserId(event.target.value)
                            }}
                            placeholder='user ID'
                            wrapperStyle={{ width: '150px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            Empty cart
                        </BlockButton>
                    </div>
                </form>
            </div>

            {/* Show the most recent 100 rows of logs */}
            <div className='section'>
                <form onSubmit={getLogRows}>
                    <div className='title'>
                        Show recent log rows
                    </div>
                    <div className='description'>
                    </div>
                    <div className='row'>
                        <InputField
                            id='admin-logs-rows'
                            name='logRowsCount'
                            value={logRowsCount}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setLogRowsCount(event.target.value)
                            }}
                            placeholder='# of rows to return'
                            wrapperStyle={{ width: '250px' }}
                        />
                        <BlockButton
                            type='submit'
                            style={{ marginTop: '20px' }} 
                            buttonStyle="purple"
                        >
                            GET LOGS
                        </BlockButton>
                    </div>
                    <div className='row'>
                        {(typeof logResults === 'object') ? (
                            <div className='table-container'>
                                <div className='table-row title'>
                                    <div className='table-cell b'>id</div>
                                    <div className='table-cell c'>name</div>
                                    <div className='table-cell c'>desc</div>
                                    <div className='table-cell b'>status</div>
                                    <div className='table-cell b'>alert</div>
                                    <div className='table-cell b'>user_id</div>
                                    <div className='table-cell b'>email_id</div>
                                    <div className='table-cell d'>inums 1-2, nums 1-4</div>
                                    <div className='table-cell e'>strs 1-4</div>
                                    <div className='table-cell d'>created_at (UTC)</div>
                                </div>
                                {logResults.map((r: any, index: number) => (
                                    <div className='table-row' key={index}>
                                        <div className='table-cell b'>{r.id}</div>
                                        <div className='table-cell c'>{r.name}</div>
                                        <div className='table-cell c'>{r.desc}</div>
                                        <div className='table-cell b'>{r.status}</div>
                                        <div className='table-cell b'>{r.alert}</div>
                                        <div className='table-cell b'>{r.user_id}</div>
                                        <div className='table-cell b'>{r.email_id}</div>
                                        <div className='table-cell d'>{r.inum1}|{r.inum2}|{r.num1}|{r.num2}|{r.num4}|{r.num4}</div>
                                        <div className='table-cell e'>{r.str1}|{r.str2}|{r.str4}|{r.str4}</div>
                                        <div className='table-cell d'>{r.created_at}</div>
                                    </div>
                                ))}
                            </div>
                        ) : ((typeof logResults === 'string') ? (
                            <>
                            {logResults}
                            </>
                        ) : (
                            <>
                            {_stringify(logResults)}
                            </>
                        ))}
                    </div>
                </form>
            </div>

            {/* SUCCESS/ERROR messages */}
            <div className={`success-box ${successMessage ? 'show' : ''}`}>
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                    SUCCESS
                </span>
                <br/><br/>
                <pre>
                    {successMessage}
                </pre>
                <button
                    style={{ position: 'absolute', top: '6px', right: '6px', padding: '6px', border: '1px solid #667' }} 
                    onClick={() => _close_dialog(true, false)}
                >
                    close
                </button>
            </div>
            <div className={`error-box ${errorMessage ? 'show' : ''}`}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                    ERROR
                </span>
                <br/><br/>
                <pre>
                    {errorMessage}
                </pre>
                <button
                    style={{ position: 'absolute', top: '6px', right: '6px', padding: '6px', border: '1px solid #667' }} 
                    onClick={() => _close_dialog(false, true)}
                >
                    close
                </button>
            </div>

            {/* LOADING message */}
            <div className={`loading-box ${isLoading ? 'show' : ''}`}>
                <span style={{ color: 'blue', fontWeight: 'bold' }}>
                    LOADING...
                </span>
            </div>
        </div>
    )
}