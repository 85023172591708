/**
 * Function to generate a hash from a string (or potentially any object, read below).
 * The hash is a number but returned as a string.
 * 
 * Useful for template loops where using the index isn't appropriate (for ex
 * if the order of items can change - see: https://reactjs.org/docs/lists-and-keys.html).
 * 
 * IMPORTANT:
 *  - If you're hashing anything other than a string or a number, you need to first ensure 
 *    that the object's `.toString()` method will return a unique value when compared against
 *    the other objects in the array that is being looped by the react tmeplate.
 *  - Consider using `<Object>.prototype...` to set a .toString() method for these objects.
 * 
 */

const hash = (input: any): string => {
    const text = input.toString()
    let hash = 0

    for (let i = 0; i < text.length; i++) {
        const chr = text.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32-bit integer
    }

    return hash.toString()
}

export default hash