import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { Product } from '../../../../models/Product';
import { ProductGroup } from '../../../../models/ProductGroup';
import { getAllProducts } from '../../../../store/actions/product-actions';
import { StoreItems } from '../../../../store/store';
import { ProductDetails } from './ProductDetails';


type Props = {
    // store injections
    getAllProducts(): any,
    products: ProductGroup[],
};


const ProductDetailsTempPage = (props: Props) => {
    let product: ProductGroup | undefined = undefined;
    if (!props.products || props.products.length === 0) props.getAllProducts();

    const { productId } = useParams();
    if (productId) {
        product = props.products.find((product: ProductGroup) => product.id === parseInt(productId));
    }
    // no product found, redirect to somewhere else
    if ((props.products.length !== 0) && !product) return <Navigate to="/" state={{product}} />

    // pass product to component with state
    return <ProductDetails productId={parseInt(productId as string)} product={product as ProductGroup} />
}



const injectStoreToProps = (store: StoreItems) => {
    const { products } = store;    
    return { products };
}

const conn = connect(injectStoreToProps, { getAllProducts });
export const ProductDetailsTemp = conn(ProductDetailsTempPage);