/**
 * Page for users who closed the verification code modal on the normal registration process to 
 * enter it here. 
 * 
 * This will redirect them to the page where they can enter the code.
 */

import React, { useCallback, useContext, useEffect, useState } from 'react'

import { AccountContext } from '../../../auth/Account';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { SelectField } from '../../../shared/form/select-field/SelectField';

import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';
import { buildPath } from '../../../../helpers/urls';

import { ACCOUNT_TYPES_DROPDOWN_OPTIONS, ACCOUNT_TYPES_FLAT, ACCOUNT_TYPES_TO_REG_URL } from '../../../../constants/accountType';

import './verification-code.scss';



export const VerificationCode = () => {
    // Auth/session functions
    const { authenticate, checkLogin } = useContext(AccountContext)
    
    // State vars
    const [email, setEmail] = useState('')
    const [userType, setUserType] = useState('')
    const [redirectPath, setRedirectPath] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    // Bump out users who ARE already logged in.
    useEffect(() => {
        if (!checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (isLoggedIn) {
                const qpOut = {reason: 'isloggedin'}
                window.location.href = buildPath('/', qpOut)
            }
        })
    }, [checkLogin])

    // On mount
    useEffect(() => {
        // Reset the email and account type to prevent certain bugs.
        setEmail('')
        setUserType('-1')
    }, [])

    // Redirect user to the proper page for validating their email address.
    const onSubmitRedirectForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setErrorMessage('')

        let err_msg = ''
        if (!email) { err_msg = 'Email is required' }
        if (userType === '' || Number(userType) < 0) { err_msg = 'Account type is required' }

        if (err_msg) {
            setTimeout(() => {
                setErrorMessage(err_msg)
            }, 400)
            return
        }

        window.location.href = redirectPath
    }, [redirectPath, userType, email])


    useEffect(() => {
        if (!userType || isNaN(Number(userType)) || Number(userType) < 0) return

        let userTypeName = ACCOUNT_TYPES_FLAT[Number(userType)]
        userTypeName = userTypeName[0].toUpperCase() + userTypeName.slice(1,).toLowerCase() // capital-case
        const targetPath = `${ACCOUNT_TYPES_TO_REG_URL[userTypeName]}/1`
        const qpOut = {
            mode: 'verify-email-redirect',
            email: email || 'hi',
            userTypeNum: userType
        }
        const _redirectPath = buildPath(targetPath, qpOut)
        setRedirectPath(_redirectPath)
    }, [userType, email])

    return (
        <div className='body-container'>
            <PrintOnlyContent title={true}>Medngine verification code entry page</PrintOnlyContent>
            <div className='verification-code-container'>
                <div className='title'>
                    Email verification
                </div>
                <div className='description'>
                    Use this form to verify your email address if you haven't done so already.<br/><br/>
                    Enter the same values below that you used for registration then you will be 
                    taken to the code-confirmation screen.
                </div>

                <form 
                    className='form'
                    onSubmit={onSubmitRedirectForm}
                >
                    <div className="header">
                        Registration email
                    </div>
                    <InputField 
                        required
                        name='email' 
                        placeholder='Email' 
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />

                    <div className="header">
                        Registration account type
                    </div>
                    <SelectField
                        required
                        id="verification-accounttype"
                        name='entityTypeIndex'
                        options={ACCOUNT_TYPES_DROPDOWN_OPTIONS}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            const usrType = Number(event.target.value) - 1
                            if (!isNaN(usrType)) {
                                setUserType(String(usrType))
                            }
                        }}
                        altDisplay={true}
                        selectedIndex={Number(userType)}
                        firstOptionIsNull={true}
                        style={{ color: 'rgba(0, 4, 12, .75)' }}
                    />

                    {errorMessage ? (
                        <div className='error-message'>
                            {errorMessage}
                        </div>
                    ) : null}

                    <BlockButton 
                        type='submit'
                        buttonStyle="purple"
                        style={{ marginTop: '60px', width: '100%', }}
                    >
                        Enter Verification Code
                    </BlockButton>
                </form>
            </div>
        </div>
    )
}