
import { CookieNames } from "./app/cache/Cookie_General";
import { PaymentMethod, PaymentType } from "./app/components/pages/cart/CartShared";




/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Use app version to trigger clearing of browser cache when major changes take place. 
 *  - For example if local storage or cookie structure changes, the updated app 
 *    could cause errors for end user who still has old versions of cookies on their browser.
 *  - See VersionManager.tsx
 */
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const APP_VERSION = '0.0.1'
export const APP_VERSION_COOKIE_NAME = CookieNames.medappversion
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>







// Config vars from dotenv.
const _ENV = process.env._ENV






/**
 * For environment-based config.
 * 
 * TODO: consider putting all other consts on this page in here.
 */
export const config = Object.freeze({
    // Legacy - leave this here until scripts that use this are removed.
    // TODO: ^^
    apiBase: (_ENV !== 'prod') ? 'https://dev.medngine.com/regenlabs' : 'https://api.medngine.com/regenlabs',
});


/**
 * AWS Cognito username-email converters.
 * 
 * AWS Cognito has bug that won't allow username to be same as email - so set username to
 * the email address without the '@' (and add "--" for easy find/replace if needed
 * for, say, data analysis or joining tables or w/e). 
 * Users can still login with their full original email addresses.
 * (search names: asperand, amperstat, at sign, at symbol, at-sign, at-symbol)
 * 
 *      - IMPORTANT - 
 *        Never change this or all user logins will break!
 */
// TODO: put this in one location - it also lives in DB API (for vedor-registration) right now.
export const CONVERT_AT_SIGN_TO = '--'


/**
 * Payment method options.
 */
export const PAYMENT_METHODS: PaymentMethod[]= [
	{
        id: 1,
        type: PaymentType.credit,
        display: 'credit card'.toLowerCase(),
    },
	{
        id: 2,
        type: PaymentType.bank,
        display: 'bank account'.toLowerCase(),
    },
	{
        id: 3,
        type: PaymentType.contract,
        display: 'terms account'.toLowerCase(),
    },
]



/** 
 * Types of valid uploads.
 * TODO: centralize these
 */
export enum VALID_UPLOAD_TYPES {
    IMG_BUSINESS = 'IMG_BUSINESS',
    IMG_PRODUCT = 'IMG_PRODUCT',
}

/** 
 * Valid file names - img api won't accept img uploads that aren't one of these names.
 */
// TODO: centralize these - share with node. Right now there's another copy in aws-s3-uploader.js.
// TODO: run tests to check for these.
export enum VALID_IMG_NAMES {
    LOGO_1 = 'LOGO_1',
    LOGO_2 = 'LOGO_2',
    LOGOALT_1 = 'LOGOALT_1',
    LOGOALT_2 = 'LOGOALT_2',
    BANNER_1 = 'BANNER_1',
    BANNER_2 = 'BANNER_2',
    PRODUCT_1 = 'PRODUCT_1',
    PRODUCT_2 = 'PRODUCT_2',
    PRODUCT_3 = 'PRODUCT_3',
    PRODUCT_4 = 'PRODUCT_4',
}


// export interface DiscountsByQuantity {
//     upToQuantity: number|null; // Use null for infinity
//     priceMultiplier: number;
// }
export type DiscountsByQuantity = [number, number] // [upToQuantity, priceMultiplier]

// For product details pulled from backend.
export interface SkuInfo {
    id: string;
    name: string;
    name_display: string;
    sku_id: string;
    dose: string;
    size: string;
    price: string;
    // Placeholder for if/when discounts feature is added in.
    discountsByQuantity: DiscountsByQuantity[];
}
export interface ProductInfo {
    id: string;
    name: string;
    name_display: string;
    brand: string;
    product_headline: string;
    product_description_1: string;
    product_description_2: string;
    product_description_3: string;
    skus: SkuInfo[];
    business_id: string;
    business_name: string;
    business_name_display: string;
}

// Tags data from backend.
// - When sent from backend, not all of these fields will always have data. Seems to work fine.
export interface TaggedReps {
	active: boolean;
	invited_at: string;
	accepted_at: string;
    uuid: string;
	from_name: string;
	from_email: string;
	from_uid: number;
	from_roid: number; // from rep-org id
	from_reporg_name: string;
	from_bid: number;
	from_biz_name: string;
	to_uid: number;
	to_roid: number; // to rep-org id
	to_reporg_name: string;
	to_cid: number;
	to_clinic_name: string;
	to_name: string;
	to_email: string;
	on_reg: boolean; // whether this was an on-registration tag request to be part of the org. 
					 // ^ (REP-MEMBERS ONLY - most of them will have one row where this is true).
}

// General info about Products, for MyAccount Page.
interface SkuObj {
	id: number;
	name: string;
}

interface ProductObj {
	id: number;
	name: string;
	skus: SkuObj[];
}

interface UserTypes {
    is_vendor: boolean;
    is_supplier: boolean;
    is_clinic: boolean;
    is_rep: boolean;
    is_rep_member: boolean;
    is_rep_lead: boolean;
    is_general: boolean;
    is_conductor: boolean;
    is_manager: boolean;
    is_admin: boolean;
    is_sysadmin: boolean;
}

// General info about for MyAccount page.
export interface MyAccountInfo {
	// User info (all user types)
	u_id: number;
	u_name: string;
	u_email: string;
	u_reg_date: string;
	u_account_type: string[]; // All roles for this user, in a list. Words are capitalized and spaced. (like "Sales Rep Lead").
    u_types: UserTypes; // Same as above, but with standardized flags.
	// Official mr_entity_type name for public users only; Regen admins will have empty string here.
	u_entity_type: 'manufacturer'|'distributor'|'provider'|'general'; // String entity-type (not number)
	// Business info (Vendors & Suppliers)
	b_id: number;
	b_name: string;
	b_name_display: string;
	product_count: number;
	sku_count: number;
	products: ProductObj[];
	// Clinic info (Doctor)
	c_name: string;
	c_name_display: string;
	c_npi: string; // NPI for the clinic/business
	u_npi: string; // NPI for the user/doctor
}

export interface RepOrgAllReps {
	user_id: number;
	rep_org_name: string; // This is the same for all items in array, but it's easier this way.
    rep_org_id: number;
	active: boolean;
    on_reg: boolean|null;
	user_name: string;
    user_email: string;
	created_at: string;
	updated_at: string;
    tagged_by_ids: number[]|null;
    tagged_to_ids: number[]|null;
    tagged_by_strs: string[]|null;
    tagged_to_strs: string[]|null;
}



// For cart JSON obj that's stored in redis/memorydb.
export interface RedisCart {
	[key: string|number]: {
		[key: string|number]: number,
	}
}

// Cart objects will have keys added to them that don't fit the standard cart structure.
// These are they key names to ignore when, say, counting items in the cart, or extracting skuIds, etc.
// - Example cart obj:
//      {"1":{"1":9,"2":7,"10":90},"3":{"13":6},"orderId":"AXYE3BPBJ7E"}
// - IMPORTANT: SAVE ALL KEYS IN LOWERCASE!
export const CART_REFERENCE_KEYS = new Set([
    'orderid',
].map(x => x.toLowerCase().trim()))


// Chars for randomized strings, like Order IDs.
// - Uppercase chars (minus BOSZ) & some numbers, so no ambiguity with O0, 1Il, 2Z, 5S, 8B, etc.
export const CHARS_POOL = 'ACDEFGHJKMNPQRTUVWXY34679'

// Maximum length for notes in cart.
// - TODO: coordinate with the backend, which has its own constant for this.
export const MAX_CART_NOTE_LENGTH = 1000