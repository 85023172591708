import React, { useContext } from 'react'
import { Link } from "react-router-dom";

import { AccountContext } from '../../../auth/Account';

import { Icon } from '../../../shared/icon/Icon';

import getDiscountMultiplier from './_getDiscountMultiplier'

import { CART_REFERENCE_KEYS } from '../../../../../config';
import { SessionPacket } from '../../../../types/session';
import { MAX_ORDER_QUANTITY } from '../../../../constants/cart';
import { ProductsById } from '../../patient/product-detail/PatientProductPage';

import './_orders-table.scss'


interface Props {
    // productDetails: ProductsById;
    // `modifiable=false` is used for the checkout-confirmation screen.
    modifiable: boolean;
    containerStyle?: {[key: string]: string|number};
    // User's auth object
    sessionObj: SessionPacket|null;
    // Product details for shopping cart.
    productDetails: ProductsById;
}


/**
 * Component that shows the table of all items in the shopping cart, on the checkout page
 * (CheckOut.tsx)
 */
const _OrdersTable = ({ 
    modifiable, containerStyle,
    sessionObj, 
    productDetails,
}: Props) => {

    return (
        <table className={`order-table ${!modifiable ? 'smaller' : ''}`} style={containerStyle}>
            <thead>
                <tr>
                    <th><span>Product</span></th>
                    <th><span>Dose / Size</span></th>

                    {(!modifiable) ? (
                        <th><span>Product ID</span></th>
                    ) : ( null )}

                    {(modifiable) ? (
                        <th><span>Per unit</span></th>
                    ) : ( null )}

                    <th><span>QTY</span></th>

                    {(modifiable) ? (
                        <th><span>Total</span></th>
                    ) : ( null )}

                    {(modifiable) ? (
                        <th><span>Delivery Date</span></th>
                    ) : ( null )}
                </tr>
            </thead>
            <tbody>
                {/* {_formatOrderItems()} */}
                <_CheckoutCartItemsTable_Inner 
                    modifiable={modifiable}
                    sessionObj={sessionObj}
                    productDetails={productDetails}
                />
            </tbody>
        </table>
    )
}


const _CheckoutCartItemsTable_Inner = ({
    modifiable,
    sessionObj,
    productDetails,
}: Props) => {
    // Account Context functions
    const { shoppingCart, addToShoppingCart, removeFromShoppingCart } = useContext(AccountContext)

    const sessionJson = sessionObj ? JSON.stringify(sessionObj) : ''


    /**
     * TODO: since there is risk of badly-formatted cookies, run validation
     * checks to make sure all data points from cookeie cart match up with  
     * what's coming from the database. If not, enable them to remove the 
     * cart item (it may no longer be available). For other cases, ask them
     * to contact support.
     *  - OR -
     *  Just request the cart from the db/Redis.
     */
    return (
        <>
        {(shoppingCart && Object.keys(shoppingCart).length && productDetails) ? (
            <>
            {(Object.keys(shoppingCart).map((productId) => (
                (!productId.trim() || CART_REFERENCE_KEYS.has(productId.toLowerCase().trim())) ? (
                    null
                ) : (
                    <React.Fragment key={productId}>
                    {(Object.keys(shoppingCart[productId]).map((skuId, i) => (
                        // Assuming productId and skuId are both (unique) numbers, the "x" in these  
                        // keys ensure that all keys will be unique.
                        // TODO: confirm that this is the case when live data is wired up.
                        <React.Fragment key={skuId}>
                        {/* Show all rows if there's no `showOnlyDate`, otherwise only show 
                            rows that match that date. */}
                        {(shoppingCart[productId][skuId]) ? (
                            <tr className='data-rows'>

                                <td className='name-field'>
                                    {(modifiable) ? (
                                        <Link to={`/public/products/${productId}`} style={{width: '100%'}}>
                                            <span>
                                                {productDetails[productId]?.nameDisplay || productDetails[productId]?.name}
                                            </span>
                                        </Link>
                                    ) : (
                                        <span>
                                            {productDetails[productId]?.nameDisplay || productDetails[productId]?.name}
                                        </span>
                                    )}
                                </td>

                                <td className='name-field'>
                                    {(modifiable) ? (
                                        <Link to={`/public/products/${productId}`} style={{width: '100%'}}>
                                            <span>
                                                {productDetails[productId]?.skus[skuId]?.sizeAndDose}
                                            </span>
                                        </Link>
                                    ) : (
                                        <span>
                                            {productDetails[productId]?.skus[skuId]?.sizeAndDose}
                                        </span>
                                    )}
                                </td>

                                {(!modifiable) ? (
                                    <td>
                                        <span>
                                            <div style={{display: 'inline', opacity: .5}}>
                                                {skuId}
                                            </div>
                                        </span>
                                    </td>
                                ) : ( null )}

                                {(modifiable) ? (
                                    <td>
                                        <span>
                                            {(isNaN(Number(productDetails[productId]?.skus[skuId]?.price))) ? (
                                                <>
                                                    -
                                                </>
                                            ) : (
                                                <>
                                                ${(
                                                    Number(productDetails[productId]?.skus[skuId]?.price)
                                                    * getDiscountMultiplier(
                                                        productDetails, 
                                                        productId,
                                                        shoppingCart[productId][skuId]
                                                    )
                                                ).toFixed(2)}
                                                </>
                                            )}
                                        </span>
                                    </td>
                                ) : ( null )}

                                <td className='quantity-field'>
                                    <span style={{display:'flex', justifyContent:'space-around'}}>
                                        {/* Buttons to add/remove item in shopping cart */}
                                        {(modifiable) ? (
                                            <>
                                            {/* Delete-all button - don't show when only 1 item left. */}
                                            <button 
                                                className='cart-change-button trash'
                                                onClick={() => {
                                                    removeFromShoppingCart(productId, skuId, MAX_ORDER_QUANTITY, sessionJson)
                                                }}
                                            >
                                                <Icon name='circle-x' />
                                            </button>

                                            {/* Minus button - show differently when quantity is 1. */}
                                            {shoppingCart[productId][skuId] > 1 ? (
                                                <button 
                                                    className='cart-change-button minus'
                                                    onClick={() => {
                                                        removeFromShoppingCart(productId, skuId, 1, sessionJson)
                                                    }}
                                                >
                                                    
                                                    <Icon name='minus-thick' />
                                                </button>
                                            ) : (
                                                <button 
                                                    className='cart-change-button minus one-left'
                                                    onClick={() => {
                                                        removeFromShoppingCart(productId, skuId, 1, sessionJson)
                                                    }}
                                                >
                                                    
                                                    <Icon name='minus-thick' />
                                                </button>
                                            )}

                                            {/* Quantity/count of item */}
                                            {shoppingCart[productId][skuId]}

                                            {/* Plus button */}
                                            <button 
                                                className='cart-change-button plus'
                                                onClick={() => {
                                                    addToShoppingCart(productId, skuId, 1, sessionJson)
                                                }}
                                            >
                                                <Icon name='plus-thick' />
                                            </button>
                                            </>
                                        ) : (
                                            <>
                                            {shoppingCart[productId][skuId]}
                                            </>
                                        )}
                                    </span>
                                </td>

                                {(modifiable) ? (
                                    <td>
                                        <span>
                                            {(isNaN(Number(productDetails[productId]?.skus[skuId]?.price) )) ? (
                                                <>
                                                    -
                                                </>
                                            ) : (
                                                <>
                                                ${(
                                                    Number(productDetails[productId]?.skus[skuId]?.price) 
                                                    *
                                                    shoppingCart[productId][skuId]
                                                    *
                                                    getDiscountMultiplier(
                                                        productDetails,
                                                        productId,
                                                        shoppingCart[productId][skuId]
                                                    )
                                                ).toFixed(2)}
                                                </>
                                            )}
                                        </span>
                                    </td>
                                ) : ( null )}

                                {(modifiable) ? (
                                    <td>
                                        <span>
                                            {shoppingCart[productId][skuId]}
                                        </span>
                                    </td>
                                ) : ( null )}

                            </tr>
                        ) : (
                            null
                        )}
                        </React.Fragment>
                    )))}
                    </React.Fragment>
                )
            )))}
            </>
        ) : (
            <tr className='data-rows'>
                <td colSpan={6} className='empty-card-placeholder x'>cart is empty</td>
            </tr>
        )}
        </>
    )
}

export default _OrdersTable