import React from "react";
import "./cart.scss";
import { Route, Routes } from "react-router-dom";
import { CheckOut } from "./check-out/CheckOut";
import { CheckOutSuccess } from './check-out-success/CheckOutSuccess'
import { CheckOutConfirmation } from "./check-out-confirmation/CheckOutConfirmation";


type Props = {};

type State = {};


export class Cart extends React.Component<Props, State> {

	render() {
		return (
			<Routes>
				<Route path='check-out' element={<CheckOut />} />
				<Route path='check-out-confirmation' element={<CheckOutConfirmation />} />
				<Route path='check-out-success' element={<CheckOutSuccess />} />
			</Routes>
		);
	}
}
