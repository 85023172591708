import axios from "axios";
import { config } from "../../../config";
import { Product } from "../../models/Product";
import { ProductGroup } from "../../models/ProductGroup";
import { StoreItems } from "../store";



export enum ProductAction {
    FETCH_ALL = 'FETCH_ALL',
    GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT',
};





export const getAllProducts = () => {
    return async (dispatch: any, getState: any) => {
        const { products } = getState();

        if(products && (products.length > 0)) {
            dispatch({
                type: ProductAction.FETCH_ALL,
                payload: products
            });
            return;
        }

        try {            
            const response = await axios.get(config.apiBase + '/product_detail', {
                headers: {
                    'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
                    'content-type': 'application/json',
                }
            });

            let responseProducts = [];
            if (response.data) {
                responseProducts = response.data.map((product: any) => new ProductGroup(product));
            }

            dispatch({
                type: ProductAction.FETCH_ALL,
                payload: responseProducts
            });

        } catch (err) {
            console.log(err);
            dispatch({
                type: ProductAction.FETCH_ALL,
                payload: products
            });
        }
    }
};



export const getProductById = (productId: number) => {
    return async (dispatch: any, getState: any) => {
        const { products, currentProduct }: StoreItems = getState();

        if(products && (products.length > 0)) {
            dispatch({
                type: ProductAction.GET_SINGLE_PRODUCT,
                payload: products.find(product => product.id === productId),
            });
            return;
        }


        try {
            console.log('making first call');
            
            const response = await axios.get(config.apiBase + '/products', {
                headers: {
                    'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
                    'content-type': 'application/json',
                }
            });

            let responseProducts: Product[] = [];
            if (response.data) {
                responseProducts = response.data.map((product: any) => new Product(product));
            }

            dispatch({
                type: ProductAction.GET_SINGLE_PRODUCT,
                payload: responseProducts.find(product => product.id === productId),
            });

        } catch (err) {
            console.log(err);
            dispatch({
                type: ProductAction.GET_SINGLE_PRODUCT,
                payload: currentProduct
            });
        }
    }
};