import { ProductsById } from "../../patient/product-detail/PatientProductPage"



/**
 * Get per-product price after qunatity-based discounts (aka "bulk discounts") are applied.
 * As of now, discounts are based on productId, and not skuId.
 * 
 * This function returns a decimal value (0 < x <= 1) that should be multiplied by the
 * product's original price to get the discounted price.
 * 
 * Currently the only discount mechanism is a percentage reduction in price. There's nothing
 * for settings a fixed price for orders of at least "x" quantity, or a flat number-of-dollars
 * discount. Just percentages.
 * 
 * This has nothing to do with coupon discounts.
 */
const _getDiscountMultiplier = (
    productDetails: ProductsById,
    productId: string,
    quantity: number
) => {
    if (productDetails[productId]) {
        for (let [upToQuantity, multiplier] of (productDetails[productId].discountsByQuantity || [])) {
            if (quantity <= upToQuantity) {
                return multiplier
            }
        }
    }
    // If no discount is found, return a multiplier of `1`, which means full price.
    return 1
}

export default _getDiscountMultiplier