import { Route, Routes } from 'react-router-dom'
import { VendorRegistration1 } from './VendorRegistration1'
import { VendorRegistration2 } from './VendorRegistration2'
import { VendorRegistration3 } from './VendorRegistration3'
import { VendorRegistration4 } from './VendorRegistration4'
import { VendorRegistration5 } from './VendorRegistration5'
import { VendorRegistration6 } from './VendorRegistration6'
import { VendorInvitation } from './VendorInvitation'
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent'


export const VendorRegistration = () => {

    return (
        <>
            <PrintOnlyContent title={true}>Medngine vendor registration pages</PrintOnlyContent>
            <Routes>
                <Route path='1' element={<VendorRegistration1 />} />
                <Route path='2' element={<VendorRegistration2 />} />
                <Route path='3' element={<VendorRegistration3 />} />
                <Route path='4' element={<VendorRegistration4 />} />
                <Route path='5' element={<VendorRegistration5 />} />
                <Route path='6' element={<VendorRegistration6 />} />
            </Routes>
        </>
    )
}