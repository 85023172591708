import { SetStateAction, useState } from 'react';
import './set-toggle-button-group.scss';


interface Props {
    type: 'dark' | 'white' | 'white-small' | 'dark-small' | 'white-small-plus-text' | 'dark-small-plus-text';
    // Since keys are hard to generate from the JSX components, provide they keys here instead.
    // Keys must be unique and if the order of the supplied list changes, then these keys also must
    // change together with it. See https://reactjs.org/docs/lists-and-keys.html.
    loopKeys: string[];
    // These are the values that go into the center of the button/box.
    mainValues: string[]|JSX.Element[];
    // For "large" boxes only, these values are in small text at the top of the button/box.
    upperValues?: string[]|JSX.Element[];
    containerStyle?: {[key: string]: number|string};
    buttonStyle?: {[key: string]: number|string};
    mainTextStyle?: {[key: string]: number|string};
    upperTextStyle?: {[key: string]: number|string};
    selectByClicking?: boolean;
    // This can be a number, for example to show the 0th box highlighted on page load, or
    // it can be a numerical state variable that is dynamically changed by the parent.
    selectedItemIndex: number;
    // State variable for parent component to know the index of the selected button.
    setSelectedItemIndex: React.Dispatch<SetStateAction<number>>;
}


/**
 * Make a group of buttons that are all styled the same way, with only one that can be
 * selected at a time. Selection can happen programmatically or by user's click.
 */
export const SetToggleButtonGroup = ({ type, loopKeys,
                                       mainValues, upperValues,
                                       containerStyle = {},
                                       buttonStyle = {},
                                       upperTextStyle = {},
                                       mainTextStyle = {},
                                       selectByClicking = true,
                                       selectedItemIndex = 0,
                                       setSelectedItemIndex}: Props) => {

    // Set the correct structure and styling of the buttons based on the 4 possible `type`s.
    const outerClassName = (
        (type==='dark') ? 'large' : 
        (type==='white') ? 'large' : 
        (type==='dark-small') ? 'small' : 
        (type==='white-small') ? 'small' :
        (type==='dark-small-plus-text') ? 'small' : 
        (type==='white-small-plus-text') ? 'small' : ''
    )
    const buttonClassName = (
        (type==='dark') ? 'large' : 
        (type==='white') ? 'large white' : 
        (type==='dark-small') ? 'small' : 
        (type==='white-small') ? 'small white' :
        (type==='dark-small-plus-text') ? 'small' : 
        (type==='white-small-plus-text') ? 'small white' : ''
    )
    const buttonInnerClassName = (
        (type==='dark') ? 'upper-text' :
        (type==='white') ? 'upper-text white' :
        (type==='dark-small-plus-text') ? 'upper-text small' :
        (type==='white-small-plus-text') ? 'upper-text white small' : ''
    )

    // When button is clicked, highlight it, ONLY IF `selectByClicking` is true.
    const handleClick = (index: number) => {
        if (selectByClicking) {
            if (setSelectedItemIndex) {
                setSelectedItemIndex(index)
            }
        }
    }


    return (
        <div 
            className={`set-toggle-button-group ${outerClassName}`}
            style={containerStyle}
        >
            {Object.keys(mainValues).map((_, x) => (
                <button 
                    key={`${loopKeys[x]}-${x}`}
                    className={`
                        toggle-button 
                        ${buttonClassName} 
                        ${selectByClicking ? 'cursor-pointer' : ''}
                        ${selectedItemIndex === x ? 'selected' : ''}
                    `}
                    onClick={() => handleClick(x)}
                    style={buttonStyle}
                >

                    {/* "large" buttons have this extra div with small text in
                        it at the top of this button/box */}
                    {(buttonInnerClassName && upperValues) ? (
                        <div 
                            className={buttonInnerClassName}
                            style={upperTextStyle}
                        >
                            {upperValues[x]}
                        </div>
                    ): (null)}

                    {/* Main content of this button/box */}
                    <div
                        style={mainTextStyle}
                    >
                        {mainValues[x]}
                    </div>
                </button>
            ))}
        </div>
    );
}