/**
 * Functions that work with AWS Cognito to:
 *  - register/signup,
 *  - authenticate/login,
 *  - get session/login info,
 *  - logout,
 *  - get/manage shopping cart, and shopping-cart note.
 * Also functions to mananage shopping cart.
 * 
 * AWS JDK reference:
 *   https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html
 */

import { createContext, useEffect, useState } from 'react'
import axios from 'axios'

import { CognitoUser, CognitoUserAttribute, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js'
import UserPool, { COGNITO_CLIENT_ID } from './_AwsUserPool';
import jwtDecode from 'jwt-decode';

import { CartDict } from '../../cache/Cart_Types';
import { getCartDict as getRedisCart, 
         addItemToCart as addToRedisCart,
         emptyCart as emptyRedisCart,
         cartItemsCount as getCartItemsCount,
         getOrderId as getRedisOrderId,
         deleteOrderId as deleteRedisOrderId,
         storeCartNote as setRedisCartNote,
         getCartNote as getRedisCartNote,
         getSelectedAddressId as getShippingAddressId,
         storeSelectedAddressId as setShippingAddressId,
         storePaymentMethodIds as setCookiePaymentMethodIds,
         getPaymentMethodIds as getCookiePaymentMethodIds,
         getUserIdFromSessionJson as getUserIdFromJsonStr,
         deletePaymentAccountId as deleteCookiePaymentAccountId } from '../../cache/CartFuncs_Redis'

import { CONVERT_AT_SIGN_TO } from '../../../config';



// Re-export CartDict type from here - so other files can import everything from this one file.
export type CartDictType = CartDict

export interface DecodedJwt {
    'aud': string;
    'auth_time': number;
    'cognito:username': string;
    'custom:u_email': string;
    'custom:u_id': string;
    'custom:u_type_id': string;
    'custom:u_type_name': string;
    'email': string;
    'email_verified': boolean;
    'event_id': string;
    'exp': number;
    'iat': number;
    'iss': string;
    'jti': string;
    'origin_jti': string;
    'sub': string;
    'token_use': string;
}



/**
 * TYPES for Account Context
 */
export type T_register = (username: string, email: string, password: string, userId: number, userTypeId: number, userTypeName: string) => Promise<unknown>;
export type T_confirmVerificationCode = (username: string, code: string) => Promise<unknown>;
export type T_resendVerification = (username: string) => Promise<unknown>;
export type T_authenticate = (email: string, password: string) => Promise<unknown>;
export type T_getSession = () => Promise<CognitoUserSession|void|null>;
export type T_getUser = () => Promise<unknown>;
export type T_logout = (redirectTo?: string) => void;
export type T_checkLogin = () => Promise<boolean>;
export type T_checkLoginNow = () => Promise<boolean>;
export type T_decodeJwt = (token: string) => unknown;
export type T_getLocalIdToken = () => Promise<{username: string, idToken: string, error: string}>;
// ------------------
export type T_addToShoppingCart = (productId: number|string, skuId: number|string, count: number, sessionJson: string) => Promise<CartDict|null>;
export type T_changeCountInShoppingCart = (productId: number|string, skuId: number|string, count: number, sessionJson: string) => Promise<CartDict|null>;
export type T_confirmPassword = (username: string, verificationCode: string, newPassword: string) => Promise<unknown>;
export type T_emptyShoppingCart = (sessionJson: string) => Promise<CartDict|null>;
export type T_deletePaymentAccountId = (paymentMethodId: number|string) => void;
export type T_forgotPassword = (username: string) => Promise<unknown>;
export type T_getAddressId = (sessionJson: string) => Promise<number|null>;
export type T_getCartNote = (sessionJson: string) => Promise<string|null>;
export type T_getOrderId = (sessionJson: string) => Promise<string|null>;
export type T_getPaymentMethodIds = () => Promise<unknown>;
export type T_getShoppingCart = (sessionJson: string) => Promise<CartDict|null>;
export type T_getShoppingCartSize = (shoppingCart: CartDict|null) => number;
export type T_getUserId = (sessionJson: string) => Promise<number|null>;
export type T_removeFromShoppingCart = (productId: number|string, skuId: number|string, count: number, sessionJson: string) => Promise<CartDict|null>;
export type T_resetOrderId = (sessionJson: string) => Promise<boolean>;
export type T_saveAddressId = (sessionJson: string, addressId: number|string) => Promise<boolean>;
export type T_saveCartNote = (sessionJson: string, text: string) => Promise<void>;
export type T_savePaymentMethodIds = (paymentMethodId: number|string, paymentAccountId: number|string) => Promise<void>;
export type T_shoppingCart = CartDict|null;
export type T_shoppingCartSize = number;
export type T_ACTCTX_READY = boolean;

/**
 * Interface for Account Context
 */
export interface IAccountContext {
    register: T_register;
    authenticate: T_authenticate;
    confirmVerificationCode: T_confirmVerificationCode;
    resendVerification: T_resendVerification;
    getSession: T_getSession;
    getUser: T_getUser;
    logout: T_logout;
    checkLogin: T_checkLogin;
    checkLoginNow: T_checkLoginNow;
    decodeJwt: T_decodeJwt;
    getUsernameAndIdToken: T_getLocalIdToken;
    // ------------------
    addToShoppingCart: T_addToShoppingCart;
    changeCountInShoppingCart: T_changeCountInShoppingCart;
    confirmPassword: T_confirmPassword;
    deletePaymentAccountId: T_deletePaymentAccountId;
    emptyShoppingCart: T_emptyShoppingCart;
    forgotPassword: T_forgotPassword;
    getAddressId: T_getAddressId;
    getCartNote: T_getCartNote;
    getOrderId: T_getOrderId;
    getPaymentMethodIds: T_getPaymentMethodIds;
    getShoppingCart: T_getShoppingCart;
    getShoppingCartSize: T_getShoppingCartSize;
    getUserId: T_getUserId;
    removeFromShoppingCart: T_removeFromShoppingCart;
    resetOrderId: T_resetOrderId;
    saveAddressId: T_saveAddressId;
    saveCartNote: T_saveCartNote;
    savePaymentMethodIds: T_savePaymentMethodIds;
    shoppingCart: T_shoppingCart;
    shoppingCartSize: T_shoppingCartSize;
    // ------------------
    ACTCTX_READY: T_ACTCTX_READY; // notifies consumers when AccountContext is ready.
}

/**
 * Default function values for AccountContext.
 *  - None of these functions/vars really do anything. Their purpose is just to exist until
 *    AccountContext has finished loading, because many other components that use AccountContext
 *    will try to call these when right when they mount.
 *  - In the meanwhile, these default functions will reject as many promises as possible (for 
 *    example for `getSession()` and `getAddressId()`), and provide zero/empty values when 
 *    they need to. Out of all of the promises below only one has to resolve (`R()`), and 
 *    that's  `getOrderId()`. The rest are rejected (`r()`).
 */
const _AccountContext_Defaults: IAccountContext = {
    register: (username, email, password, userId, userTypeId, userTypeName ) => new Promise((R, r) => r()),
    confirmVerificationCode: (username, code) => new Promise((R, r) => r()),
    resendVerification: (username) => new Promise((R, r) => r()),
    authenticate: (email, password) => new Promise((R, r) => r()),
    logout: (redirectTo) => {},
    getSession: () => new Promise((R, r) => r()),
    getUser: () => new Promise((R, r) => r()),
    checkLogin: () => new Promise((R, r) => r(false)),
    checkLoginNow: () => new Promise((R, r) => r(false)),
    decodeJwt: () => null,
    getUsernameAndIdToken: () => new Promise((R, r) => r({username: '', idToken: '', error: 'Not initialized (#4466).'})),
    // ------------------
    addToShoppingCart: (productId, skuId, count, sessionJson) => new Promise((R, r) => r()),
    changeCountInShoppingCart: (productId, skuId, count, sessionJson) => new Promise((R, r) => r()),
    confirmPassword: (username, verificationCode, newPassword) => new Promise((R, r) => r()),
    deletePaymentAccountId: (paymentMethodId) => {},
    emptyShoppingCart: (sessionJson) => new Promise((R, r) => r(null)),
    forgotPassword: (username) => new Promise((R, r) => r()),
    getAddressId: (sessionJson) => new Promise((R, r) => r()),
    getCartNote: (sessionJson) => new Promise((R, r) => r(null)),
    getOrderId: (sessionJson) => new Promise((R, r) => R(null)),
    getPaymentMethodIds: () => new Promise((R, r) => r()),
    getShoppingCart: (sessionJson) => new Promise((R, r) => R(null)),
    getShoppingCartSize: (shoppingCart) => 0,
    getUserId: (sessionJson) => new Promise((R, r) => r()),
    removeFromShoppingCart: (productId, skuId, count, sessionJson) => new Promise((R, r) => r()),
    resetOrderId: (sessionJson) => new Promise((R, r) => r(false)),
    saveAddressId: (sessionJson, addressId) => new Promise((R, r) => r(false)),
    saveCartNote: (text) => new Promise((R, r) => r()),
    savePaymentMethodIds: (paymentMethodId, paymentAccountId) => new Promise((R, r) => r()),
    shoppingCart: ({} as CartDict),
    shoppingCartSize: 0,
    // ------------------
    ACTCTX_READY: false,
}







// Create a context object.
const AccountContext = createContext<IAccountContext>(_AccountContext_Defaults)

// Constants/config
// This is used for low-security auth-status verification.

// const AUTH_CACHE_TIMEOUT = 600000 // 10 minutes

// Testing, use 1 minute
const AUTH_CACHE_TIMEOUT = 60000 // 10 minutes


const API_DB_URL = process.env.REACT_APP_API_DB_URL


/**
 * Create the Account context provider.
 */
const Account = (props: any) => {
    // For notifying components when this AccountContext is ready to use.
    const [ACTCTX_READY, setACTCTX_READY] = useState(false)

    // For low-security checking of login status. 
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isLoggedInExpires, setIsLoggedInExpires] = useState(Date.now())

    // Shopping cart
    const [shoppingCart, setShoppingCart] = useState<CartDict|null>({})
    const [shoppingCartSize, setShoppingCartSize] = useState(0)


    // ====================================================================================
    // Core auth functions
    // ====================================================================================

    /** 
     * New user signup.
     * 
     * Example response:
     * {
     *       "user": {
     *           "username": "jordan--medngine.com",
     *           "pool": {
     *               "userPoolId": "us-east-1_sgIfRjI8C,
     *               "clientId": "3r6svrev16k3pr6n5c61t9cg6n",
     *               "client": {
     *                   "endpoint": "https://cognito-idp.us-east-1.amazonaws.com/",
     *                   "fetchOptions": {}
     *               },
     *               "advancedSecurityDataCollectionFlag": true,
     *               "storage": {}
     *           },
     *           "Session": null,
     *           "client": {
     *               "endpoint": "https://cognito-idp.us-east-1.amazonaws.com/",
     *               "fetchOptions": {}
     *           },
     *           "signInUserSession": null,
     *           "authenticationFlowType": "USER_SRP_AUTH",
     *           "storage": {},
     *           "keyPrefix": "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n",
     *           "userDataKey": "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.userData"
     *       },
     *       "userConfirmed": false,
     *       "userSub": "d45e2f0a-8b88-4d12-8644-7f2988e2f1a4",
     *       "codeDeliveryDetails": {
     *           "AttributeName": "email",
     *           "DeliveryMedium": "EMAIL",
     *           "Destination": "j***@m***"
     *       }
     * }
     *      
     */
    const register: T_register = async (
        username: string, email: string, password: string,
        userId: number, userTypeId: number, userTypeName: string, 
    ) => {
        return await new Promise((resolve, reject) => {
            UserPool.signUp(
                username, 
                password, 
                [
                    // FYI: If adding additional user attributes on Cognito, then you mmust also 
                    //      give them read/write permissions in AWS Cognito > App Integration > 
                    //      App client list > (your app) > Attribute read/write permissions.
                    new CognitoUserAttribute({'Name': 'custom:u_id', 'Value': String(userId)}),
                    new CognitoUserAttribute({'Name': 'custom:u_type_id', 'Value': String(userTypeId)}),
                    new CognitoUserAttribute({'Name': 'custom:u_type_name', 'Value': userTypeName}),
                    new CognitoUserAttribute({'Name': 'custom:u_email', 'Value': email}),
                    new CognitoUserAttribute({'Name': 'email', 'Value': email}),
                ], 
                [], 
                (err, data) => {
                    // See possible AWS Cognito error types for registration/signup:
                    //  - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
                    if (err) {
                        console.log('Registration failed (#c100):', err)
                        reject(err)
                    } else {
                        console.log('Registration successful!')
                        resolve(data)
                    }
            })
        })
    }

    /**
     * Confirm user registration with code that was sent to their email.
     */
    const confirmVerificationCode: T_confirmVerificationCode = async (
        username: string, code: string,
    ) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool })

        return await new Promise((resolve, reject) => {
            // See: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#confirmSignUp-property
            cognitoUser.confirmRegistration(code, false, (err, data) => {
                if (err) {
                    console.log(`Could not verify code (#c101): ${err.name}: ${err.message}`)
                    reject(err)
                } else {
                    console.log('Code verified!')
                    resolve(data)
                }
            })
        })
    }

    /**
     * Resend verification code/email.
     * 
     * Example response; 
     *      {CodeDeliveryDetails: 
     *          {AttributeName: 'email', DeliveryMedium: 'EMAIL', Destination: 'j***@m***'}
     *      }
     */
    const resendVerification: T_resendVerification = async (
        username: string,
    ) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool })

        return await new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode((err, data) => {
                if (err) {
                    console.log(`Could not get verification code. Are you sure you signed `
                                + `up? (#c102): ${err.name}: ${err.message}`)
                    reject(err)
                } else {
                    console.log('Verfication code sent')
                    resolve(data)
                }
            })
        })
    }

    
    // Forgot password:
    //  - Submit "username" (not email) to this endpoint for AWS Cognito to send an email 
    //    so that user can reset their password.
    //  - The email sent contains a verification code that user will enter on our website, not a link.
    //  - But email can probably be formatted in AWS Cognito GUI to send a link - we just 
    //    have to build handling for that.
    //  - Could also set this up to send to SMS text message, but that requires a connecting 
    //    a live phone number (since 2021).
    // See: https://stackoverflow.com/questions/38110615/how-to-allow-my-user-to-reset-their-password-on-cognito-user-pools
    const forgotPassword: T_forgotPassword = async (
        username: string
    ) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool })

        return await new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: function(result) {
                    resolve(result)
                },
                onFailure: function(err) {
                    console.log(`Forgot pw system fail (#c103): ${err.name}: ${err.message}`)
                    reject(err)
                },
            })
        })
    }

    // Confirm forgot-password verification code:
    //  - This function name is a misnomer
    //  - Use enters verification code to a form (to this funciton) that they got from 
    //    the forgot-password emai.
    // See: https://stackoverflow.com/questions/38110615/how-to-allow-my-user-to-reset-their-password-on-cognito-user-pools
    const confirmPassword: T_confirmPassword = (
        username: string, verificationCode: string, newPassword: string
    ) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool })

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess() {
                    resolve(true);
                },
                onFailure(err) {
                    console.log(`Confirm pw sys fail (#c104) for [${username}]: ${err.name}: ${err.message}`)
                    reject(err);
                },
            });
        });
    }


    /**
     * User login
     * 
     * Example response:
     *      {
     *           "idToken": {
     *               "jwtToken": "eyJraWQiOiJKZTltZDJFaFNFZVIzTWNWeDR1ZDhVWU1hTG9LaFltdjdMQmhuSWtJVlowPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOnVfZW1haWwiOiJqb3JkYW5AbWVkbmdpbmUuY29tIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfVjN3SFpZWk1iIiwiY29nbml0bzp1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIiwiY3VzdG9tOnVfaWQiOiIyIiwib3JpZ2luX2p0aSI6IjZhYzZhYjcyLTZiYmItNGQ3OC05MmU3LWQ0ZTZhOTRjMGMyZCIsImN1c3RvbTp1X3R5cGVfbmFtZSI6ImRpc3RyaWJ1dG9yIiwiYXVkIjoiMnRuaTB2dnA4YzEyc3MzbDR1dm1iM2k1cnUiLCJldmVudF9pZCI6ImM4MWE5MGVkLTgwMTUtNDljOC05ZmEwLTg2NWJmYTRkMjdmMyIsImN1c3RvbTp1X3R5cGVfaWQiOiIyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzAxMzYzMzIsImV4cCI6MTY3MDEzOTkzMiwiaWF0IjoxNjcwMTM2MzMyLCJqdGkiOiJjMDE2YTM1NC1hZDFjLTQ2ZmUtOTgxMS01N2EzNDY2ZjQwZjgiLCJlbWFpbCI6ImpvcmRhbkBtZWRuZ2luZS5jb20ifQ.a-ywnRQrJiF8Wgg9EhaiHf8TD1vKy9PuJ6O-vLmVLZjiinkiY9t84Xq5JHGdklRJla7TFs07yn4O5hHoDdDCeJjGYqXK9uFKwUpwtc23rW586SmUoSadlO9MwduQfc7IcodtMfMyfRXPgeateLO2Pmq-JGCi0dx3YsNCK0nZlEGE72k0Poc6nh9GIoImZq9zdu1tm_HLkaVAYJAc5pvo9XN3cD_TN13sRDoPaqCJWVeNS1YcZ1xZwr0hksFcRQk2MAcZUf1a9lyHGNxXukOm8GN6rAe8TovQbFBTzig8jeaY2CcmNedkcIXBCBLgOZLj58Ztas8oATsfRvDfd5xLyQ",
     *               "payload": {
     *                   "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *                   "email_verified": true,
     *                   "custom:u_email": "jordan@medngine.com",
     *                   "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *                   "cognito:username": "jordan--medngine.com",
     *                   "custom:u_id": "2",
     *                   "origin_jti": "6ac6ab72-6bbb-4d78-92e7-d4e6a94c0c2d",
     *                   "custom:u_type_name": "distributor",
     *                   "aud": "3r6svrev16k3pr6n5c61t9cg6n",
     *                   "event_id": "c81a90ed-8015-49c8-9fa0-865bfa4d27f3",
     *                   "custom:u_type_id": "2",
     *                   "token_use": "id",
     *                   "auth_time": 1670136332,
     *                   "exp": 1670139932,
     *                   "iat": 1670136332,
     *                   "jti": "c016a354-ad1c-46fe-9811-57a3466f40f8",
     *                   "email": "jordan@medngine.com"
     *               }
     *           },
     *           "refreshToken": {
     *               "token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.tbGXn5lSUDYN5bgMouwonymvXgApN1ArJ1fki6xGanAtHzJx2Jqz3CP4x1ZjF7_SCtiixRfeCmbVbjqy2spgwB0-cbw4L6RlI9iGn1uT0AtP7wbG-H6yKFY3a06-I8LAX-T6uGr8zbPopKP0wDAQaQ5-hZr4AVk22p18eG6uSu6IdSmnKs-l1Idxg94-T85nKwaaXlMLI38VO3lTslV1XrPnhpncQUJHevOXlMdySN_GQZ5ivjN0YBatBL6xhuk8E29QmEdl2K_CY_bRx4LVqGh7N0osK8WRce8teHgFM73C_ol2fiQ3JcEce7SlST-7CQf-kOusCC_jXZwSNqsKXQ.qHnLWw_Ne1BqWKwf.N0ae7gwvhxnX-dxj0I7Z9LIhdGdjeopnqITzs64v27TDXiaxdD8oMF2NQ8cnyuWHLXVHmUFu0ykhi-y3P734zypLdG7-pZOkXfSv8jn3jOAYFGLSUVVqkbJLt6ysKPNVFDYFsyhcf-wYGcNqZbityQ95wzM96cNiMF2RkPgCvIVlLafzeWhnkiEl8gWvOQEdsNHNIrFZ4pluBGoMZTaolMwLK9GvhUEsg2GAflzJzR7j0ghmv5iyFcfTGeeTmy9HFatFHXcP6N7R7I9qtiJIbSQ1hVOS_nskhJh8w5HKJxoqzoStoY_0vSBew5peonaBCkcM9NKrSP0gjlqb8S3-7yoOlTYqaR6g09-_x6GEvV9XWLG-pSX6vdwCYcExBaG1WVosg0JGYWxjQ9WNyvsylYw84gqXEyen-A5X_c6bsYB6ft0hpm5oIRK6_Zu9X1WHUEonKdTYkBR7bbS-lWPvsBDwSxNyV6raZjHefJwT2nP7xRrvHVN2nySDI9-7Ff0Qq57AvcPG3eavNH1734D88VcHoi61IBxWWX9rR4fLasxiu9gyxX8QLqf4K5laHP6eJIKZWY_ljE8o8jLHIhwCyFoLZkxP9QN4G91EOSeIZO65labdR-Dh5jZQPpdcLEAic_dC4Oic7PupT5ZOoR66rXwmC9RRHDfPr9Vn9Ax7y6as1eTuPX2ZMfk_46x3i-Em7jCqRnv9VvY-Ad5CTdOiKDa17vO6X-aGWojsEvzt3avi7l_sdqtMFSldZTYuv8VSTiOgNK1Y79Oo8TMl9Fzc5MJ5zSVZCdNDBDJCZd7uPvp5Qz-yvBOJZFv-p2lldW63hK-llyZvFz1ePtvMfwdGbVVU6zxojUpraFNHQDe_9hYc1QjaqST8AymK0z11r77m7sFSaha9t81kOFn_lVR2-SzcnZGg8Gz-A8l0nLiOyJrCPPtLOjxTDepFqOBzgMN3g5Z1oDZAKkXKKqwGC1pKdTskSnoT93SnCyCoiv-IOw4uZPtnI3vQjom_4C7qPxZJr2U-_bzMsLP5fD0Ys5MIKUxAxSAiyP2EKtfO26KQN9iMrT3mOlyGBGkM_lU9TIJy4RjbirFCJkRqM1oyzGpUwNx0RJ0SnW3i7ndrRv4vfY5aCrCAo-x1imlDf9MMoxlVT-rPSPLo1ml3fxuAWBngnh6V_2Z8Yg9vPf72AdFfby4jNPjj8YY3eibkbmtNFvwJ8W1Q3hm8TKAUYHvegYZEUKU-9L5KfnTNXckh-d_9jgFxyJqGlqRoDeByXHwUrWZnY-SMvh12lBxiKR6uRBDSk44CnI5Xq5M.N-wPpGKPl0r0buL_9fSuYw"
     *           },
     *           "accessToken": {
     *               "jwtToken": "eyJraWQiOiI1TXNhUkZveU1STTdzejFkMytPRHJtbmxqdFVNWVZiWm53Q1k2YVVMSlNjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9WM3dIWllaTWIiLCJjbGllbnRfaWQiOiIydG5pMHZ2cDhjMTJzczNsNHV2bWIzaTVydSIsIm9yaWdpbl9qdGkiOiI2YWM2YWI3Mi02YmJiLTRkNzgtOTJlNy1kNGU2YTk0YzBjMmQiLCJldmVudF9pZCI6ImM4MWE5MGVkLTgwMTUtNDljOC05ZmEwLTg2NWJmYTRkMjdmMyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzAxMzYzMzIsImV4cCI6MTY3MDEzOTkzMiwiaWF0IjoxNjcwMTM2MzMyLCJqdGkiOiJjYWNmYjg3YS1hYjk2LTQ3NmItOTc1NC1jMGZkZGJmYWY0MWMiLCJ1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIn0.xt0rWzwXWqMicEnodSl1QGIizfVYH_WOUkIPwB7Cn5jWX_K5KZR_Y6_I9YulOIJKRdX-VAMIKJs7O8xXBQGzlgOmRMKwXm_VlI2C1OkHVp3MUZ0T5Zw6RFYX00spjY4v0tGzf08oWZ4-GQGjB0_2O87SIAT7bbYF8rMki9d-XdvvttudgnE9pdy3lAPj8uBJSWNe9giNn3VmaKcySelNi85hHoV9N8UA35-RmSrwsw72lbr65F7mvYKEv1aBMKF7ZH2k_GyWdkufBZk01sX2uTW-Miykve1lAxBZKlsFSKN6pRCqjbwlgjffWicKKqtRjfhz1db7kPAKsux7Zmmpcg",
     *               "payload": {
     *                   "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *                   "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *                   "client_id": "3r6svrev16k3pr6n5c61t9cg6n",
     *                   "origin_jti": "6ac6ab72-6bbb-4d78-92e7-d4e6a94c0c2d",
     *                   "event_id": "c81a90ed-8015-49c8-9fa0-865bfa4d27f3",
     *                   "token_use": "access",
     *                   "scope": "aws.cognito.signin.user.admin",
     *                   "auth_time": 1670136332,
     *                   "exp": 1670139932,
     *                   "iat": 1670136332,
     *                   "jti": "cacfb87a-ab96-476b-9754-c0fddbfaf41c",
     *                   "username": "jordan--medngine.com"
     *               }
     *           },
     *           "clockDrift": 0
     *       }
     */
    const authenticate: T_authenticate = async (
        email: string, password: string
    ) => {
        return await new Promise((resolve, reject) => {
            const now = Date.now()
            const username = email.replace('@', CONVERT_AT_SIGN_TO)

            const user = new CognitoUser({ Username: username, Pool: UserPool })
            const authDetails = new AuthenticationDetails({ Username: username, Password: password })

            user.authenticateUser(authDetails, {
                onSuccess: async (data) => {
                    console.log('Login successful', data)
                    // Run backend validator on login.
                    // - This endpoint will throw an error if user has bad data, unauth'd, etc.
                    // TODO: execution of this func shouldn't rely on frontend.
                    // IMPORTANT: DO NOT catch any errors here (w/o rethrowing) because otherwise 
                    //      users with bad data in the db (e.g. info mimatch, unauthorized, etc), 
                    //      will still be able to login to their accounts).
                    await axios.post(`${API_DB_URL}/login`, { loginData: data }).catch((e) => {
                        // IMPORTANT: errors thrown from this endpoint are NOT visible in the
                        // error (`e`) object, so show user a general error message. 
                        // - The error details ARE visible in the logs, however.
                        const qpErrMsg = encodeURIComponent(
                            "There is a problem with your account. Please contact an adminsitrator. (e=6718)"
                        )
                        // Even if this backend fails, Cognito has already logged-in the user,
                        // so log them back out.
                        logout(`/auth/login?errorMessage=${qpErrMsg}`)
                        throw e // MUST RE-TRHOW!!
                    })
                    // TOOD: prob don't need these local storage items anymore ?
                    localStorage.setItem('mr_session', String(true))
                    localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                    resolve(data)
                },
                onFailure: (err: Error) => {
                    // See possible AWS Cognito error types for authenticate/login:
                    // - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
                    // NOTE: If the error name and message are "Error: Request failed with status code 422",
                    //   then that means there was an error from our own backend, like maybe the
                    //   mr_user record wasn't connected to a proper mr_user_cognito record, oslt.
                    console.log(`Login failed! (#c105): ${err.name}: ${err.message}`)
                    localStorage.setItem('mr_session', String(false))
                    localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    // This is for users who were added manually through Cognito GUI. 
                    //      (confirmed that this gets triggered correctly).
                    // TOOD: create a frontend redirect to reset-pw page for this.
                    console.log('Login - new password required')
                    // TODO: setting this to true because user seems to be logged in even if new pw req'd.
                    localStorage.setItem('mr_session', String(true))
                    localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                    resolve(data)
                },
            })
        })
    }

    /** 
     * Get the session details for currently logged-in user.
     * 
     * Example response:
     * {
     *       "idToken": {
     *           "jwtToken": "eyJraWQiOiJKZTltZDJFaFNFZVIzTWNWeDR1ZDhVWU1hTG9LaFltdjdMQmhuSWtJVlowPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOnVfZW1haWwiOiJqb3JkYW5AbWVkbmdpbmUuY29tIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfVjN3SFpZWk1iIiwiY29nbml0bzp1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIiwiY3VzdG9tOnVfaWQiOiIyIiwib3JpZ2luX2p0aSI6IjlkYmE1Yjc1LTg0YmMtNDlhNy1iNjk5LWJhZmQ0NDg1YjQxMCIsImN1c3RvbTp1X3R5cGVfbmFtZSI6ImRpc3RyaWJ1dG9yIiwiYXVkIjoiMnRuaTB2dnA4YzEyc3MzbDR1dm1iM2k1cnUiLCJldmVudF9pZCI6IjA0MThmYWJjLTRlZmMtNGQ0Yi1hMzczLWQxZTkxZmQ0Y2U3NiIsImN1c3RvbTp1X3R5cGVfaWQiOiIyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzAxNzQxNzEsImV4cCI6MTY3MDE3Nzc3MSwiaWF0IjoxNjcwMTc0MTcxLCJqdGkiOiI3YjczMWJlNy0yMWUyLTQ5Y2EtOWVlNi1jNTkzMmQ3NjcwMmYiLCJlbWFpbCI6ImpvcmRhbkBtZWRuZ2luZS5jb20ifQ.AeH_m1UovUqdAgcZSgDTed-aDc0IhVaFO4ZlxsxJL99MwqKm0UUTzQFDPuUIT5mE_hO_PmTxmJLhvXNOpfI0qkQxXij1qVheM_HLQHVe4IL71MnS6leR_cFLPoH3vO6bLDnbYL3QfToPp7ZLmnS3Hg9YSYwAX1duBpYDEdlcrseiJOjt5OQXW6dVwXxi204xz0Srk5zOjx0S1a9WAOo-0JWPUaPrCUyiobUDgp6eMp6TFcDNdiczMysLE50HGCqoiPAuGobxO3vHtGiw0Sqpy4h3wZp4-24_MdlSn3W_ukFH9B9Vu-gufFCSe1cPlgNW_E7PfpPsMSfNQdAyJloSRg",
     *           "payload": {
     *               "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *               "email_verified": true,
     *               "custom:u_email": "jordan@medngine.com",
     *               "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *               "cognito:username": "jordan--medngine.com",
     *               "custom:u_id": "2",
     *               "origin_jti": "9dba5b75-84bc-49a7-b699-bafd4485b410",
     *               "custom:u_type_name": "distributor",
     *               "aud": "3r6svrev16k3pr6n5c61t9cg6n",
     *               "event_id": "0418fabc-4efc-4d4b-a373-d1e91fd4ce76",
     *               "custom:u_type_id": "2",
     *               "token_use": "id",
     *               "auth_time": 1670174171,
     *               "exp": 1670177771,
     *               "iat": 1670174171,
     *               "jti": "7b731be7-21e2-49ca-9ee6-c5932d76702f",
     *               "email": "jordan@medngine.com"
     *           }
     *       },
     *       "refreshToken": {
     *           "token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.MT3m2tzALiiPBHTCqs4vnFx9gePCVBaloa8m8qWr14SF1hZOlE9lAxV59NY88zOz87CHDbnK9XHPTXwnDO3IYqZNi_hUTkiKfKDvjDukTtrjSGpjsgOnInQY3eXG_GRJiMaAEGDe0P_AuhYxQaIpgW6cpKK9QPKp08aa7cGoS3DlWbhRUIOyX9Hxmj9ztnreehgY2Pj9i2Uo5Z5LdnGWjMdKiAARZXAi21-SpGT7MiVjej0s3BOx-xQffaSzKRlvEi72OwAfRFl3sF8D3mnuEVgo68jJmb3Vrw08fhL8zQyyXbY52b_4tjoHcfr9oJGg-neLE5wD6BaYQ6nF3lzudg.rQyDt_DKJXol6VFk.J4aEQUnq30FOqLtsYOunTPr6ZH9CH6goDtj2kFDPe9n2kgqZvfmUY9x4WoD2QxFmv5H6Um30J5hxY3khaLD6--QDZRL1Y_myJNBUmh5TFwBS4M230Kr58u6MN-67yPVC0J7KWLR2PuN4BSuesdIYq9pBW-8fgudzQtZzok0mSeEvNb0NKcNnqyZF9sZBM4Y2poBlx6aJztX7X0mF6SOa_HPO7a08nRn1frDBLlEWp5sdagmiePt4VK155msn5TIv_IqSwLt6hcWf7C0d4IMG2SswX6n5v8AL6D7N8DKjVE7SKsl6HrkEe7nzTO9jlm4_amIEcCgsq8kqF2Pg5IIkW4v8DMIHuUcjRG0QKAQkpNkJZuFVIwD4ArbdLvr3SlL1lPCm_CSCGI8Wh7AMbmBThOY2YhvUuBdNrSEF8kf_SRGp3Hq5NbohKOefmjx4KHidZ4dizsSp6XzcHBIjxoOVlKe03ceBREJoQSNsrxSthpUrApLVd0qhA_vj3DKHLHElzFcL83p7e1VNrzi4mWPH8t4hT2t4MwrcOvUArAnF5r8Rg5kC1h0qa8KKiwITlU9JBWEdDJwuzr5TgBgUT4SXiaVqDEIVd5ZCJ49L1Lu-srNZ3_Kh-1cPtQfUIJRlwvhYY5aYyLYrnjU-iX-AxbDHGW3CtI8WTiNjLALTAvG7XQ-xqRRA9AKa69WLG75NavtTNcm7-LqXnx0BV9vJtalwlnk8GxclEc_fHEXmcyEf1ktjhtVxCWKNmjUzOcu3kfCfJM7E0IrtC3FwcNaalOCxIC3_Co6kJFqeu7fomi-gZ72puykbRXImWHM3aiYbWVUAzPEkeMAkfHQRojh6BJVUCncdIZfbfCQQdpAuFUYJNVVArsViZP5mp98TV6JrmPu1zpo1lRx5Zx2q5owS1zgMnKNbpnNFI3gm68CWX45zQeZT0azrrldKEFLhh_G6_Tt5frKE9O2xYiZec0DZLDDQyTbopE6U7LopaUM-lNFgPelRzlJmStDk_aWnF9t76yOJNF1yOQsWNQuTJ1gCYqq7b2BVW4f4FhCrBrdKYq5flLZ8kFlZxSQgq1bNL4JVX8l58o7PLeS00IvqXJF1ZtqTVz9tVDzar5E55iZ-XPvdzPtvCIQderjn9ti5yuEW9VYVmx7SCo3qSTwGZ6HmBFfAEmea0GJu67pO-k3LgGKQyqwLj8qD5-KsSBV3kUuU4_OUgQ3ci-RiruN-_IikIYyNuuKeRsMuWwQinm-Cw6MAtN-rbampQH4DFJVVBXooOfz2gYhb2etVMpUtziVu79hYDMYzt3mJ6v0.bTVaUg0kkmTeaqBCIVaWdg"
     *       },
     *       "accessToken": {
     *           "jwtToken": "eyJraWQiOiI1TXNhUkZveU1STTdzejFkMytPRHJtbmxqdFVNWVZiWm53Q1k2YVVMSlNjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9WM3dIWllaTWIiLCJjbGllbnRfaWQiOiIydG5pMHZ2cDhjMTJzczNsNHV2bWIzaTVydSIsIm9yaWdpbl9qdGkiOiI5ZGJhNWI3NS04NGJjLTQ5YTctYjY5OS1iYWZkNDQ4NWI0MTAiLCJldmVudF9pZCI6IjA0MThmYWJjLTRlZmMtNGQ0Yi1hMzczLWQxZTkxZmQ0Y2U3NiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzAxNzQxNzEsImV4cCI6MTY3MDE3Nzc3MSwiaWF0IjoxNjcwMTc0MTcxLCJqdGkiOiI2NDg5M2YxNy05MjY2LTQwMmUtYTVkYy1mMjFmOTI4MTMzZDUiLCJ1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIn0.CVHyzYdV0CoX4OWFVRPvKiTXvVKTa0sKUB0RqhJmaryT6jjbxWwHXLkf7t3xPLRLwzpd5p9Ly0ijFznDkV0XsnSGVzYnDZVVv7innk-W5c_QkTA9K8M6Ib4RtHa7n6LJDko6vMYv4w9felwLOKwNczR3a7WEw-vxNh2tG5zb0VIltrowGmnYLC1yByT3-NDyPBCG2Xeuas0mf3MshS4tyV_JYeZzDXidOq0HPYI-sctAhahvf2pqzGsccBl55l9gMnQsX40GPMe3Wir-Sz__1YUC3iPxIlcSLSHyC3ujuuubKiSJfp7BxqLJ160pRJXfA-sJ2hW-Z4pYM7Dg2q8fpw",
     *           "payload": {
     *               "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *               "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *               "client_id": "3r6svrev16k3pr6n5c61t9cg6n",
     *               "origin_jti": "9dba5b75-84bc-49a7-b699-bafd4485b410",
     *               "event_id": "0418fabc-4efc-4d4b-a373-d1e91fd4ce76",
     *               "token_use": "access",
     *               "scope": "aws.cognito.signin.user.admin",
     *               "auth_time": 1670174171,
     *               "exp": 1670177771,
     *               "iat": 1670174171,
     *               "jti": "64893f17-9266-402e-a5dc-f21f928133d5",
     *               "username": "jordan--medngine.com"
     *           }
     *       },
     *       "clockDrift": 0
     *   }
     */
    const getSession: T_getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser()

            if (user) {
                user.getSession((err: Error|null, session: CognitoUserSession|null) => {
                    if (err) {
                        console.log(`Get session failed! (#c106): ${err.name}: ${err.message}`)
                        reject()
                    } else {
                        resolve(session)
                    }
                })
            } else {
                reject()
            }
        })
    }

    /** 
     * Get info about the currently logged-in user.
     * If user isn't logged it this will throw an error ("Uncaught (in promise)")
     * 
     * Example response:
     *  {
     *       "username": "jordan--medngine.com",
     *       "pool": {
     *           "userPoolId": "us-east-1_sgIfRjI8C,
     *           "clientId": "3r6svrev16k3pr6n5c61t9cg6n",
     *           "client": {
     *               "endpoint": "https://cognito-idp.us-east-1.amazonaws.com/",
     *               "fetchOptions": {}
     *           },
     *           "advancedSecurityDataCollectionFlag": true,
     *           "storage": {
     *               "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.idToken": "eyJraWQiOiJKZTltZDJFaFNFZVIzTWNWeDR1ZDhVWU1hTG9LaFltdjdMQmhuSWtJVlowPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOnVfZW1haWwiOiJqb3JkYW5AbWVkbmdpbmUuY29tIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfVjN3SFpZWk1iIiwiY29nbml0bzp1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIiwiY3VzdG9tOnVfaWQiOiIyIiwib3JpZ2luX2p0aSI6Ijc3OTRkZGMyLTYwNDctNGJiYi05MTdlLTVlZWQ3M2RlNTM4MSIsImN1c3RvbTp1X3R5cGVfbmFtZSI6ImRpc3RyaWJ1dG9yIiwiYXVkIjoiMnRuaTB2dnA4YzEyc3MzbDR1dm1iM2k1cnUiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsImN1c3RvbTp1X3R5cGVfaWQiOiIyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiJhZWE1YTViZi1jMzZhLTQ2M2ItYjU4Yy00MzZhZTIyM2YwY2EiLCJlbWFpbCI6ImpvcmRhbkBtZWRuZ2luZS5jb20ifQ.A5TfP4LqFOVmYxaVv70HynJpMHGbOiRofYYazbRSEfGq76EZnJTKaD56qNKuZaNg66FI2_dzGoES6QlHXbZKBVIBAHuokPf1Do-rrR0pjb_DLUXvBXr9C9fxEquDJYs_nR22vuYpa8HzOWHaRYNTqoXz7eeAwH7lVu_1BUYTfD0bZlHegIERGCcbWGdAtuMZR06H2U5dMRNUKDLAeLdbSftA_XJDp4_kS7kiyzDtgzc4z8J3z3LAPaU-2NeO5m6ap6dcn7zy0m62z7vC03OclH3lqxKi2e_gxFEEQVQGkBdin6fy7q2SWLKb9XUZmArVH9L-x-61r5SMPV0DGNwAew",
     *               "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.IdRL0E7x3PhabLBNSPP0WA-pUaQGBgRdNGrVN1EETmJ6Eih5QLxbc73KUsRP7a1omnphN49ki30GniTPDhcn_2TGPvW_tngQn6ojkWBriDSJ0LId6Q7PXNBF7VwhINVUJWvxDXbdC8ZPkzgoQ75AM6rFjmrm3uijP_lZVYTHhmBnvjGOupTW3Ln-Ogi3B6M7aXs5GHd6p2ANYfj7TqMetCGkh9o6SbwuvEOPUNCoKgROG2EVjVUfthu7u4BBaUCl3aUVbNtnGl0xlHTmZdUFauyDMitx377G81oSWlxLN6NjAzBxlWrEplHCyPQPQL6G_u1lf_5nMFpwTZ71nqATyA.qGIskeFnhij0U5g2.e4n-bVM7IYxdxmZj0XJW13wQGSbkbmLxCwT541w9IAmnXQARle8GROW3gI-2Tsu1sIoxvg9KS7FV9JWna6Fw-yQG3e4lYzVuxcvZdq3xL05gpbD5PL1HiU4h08eXvAqmjJABepRpbRPiw6PhrrRF5vGRB8g-_C_PrlPO1m9SCyCgYHUYfURVnhO5wr_isZzBpI8z1A7thNtdDBGyuKuQRjCZHtG0E-iid2Q-oc3XtJtTENFw-3va32cL7omoRBf2TVO2wRZasdeR_R9Ee_Jd9DVeJwaiNQGEoMIlvhGsEDXJN-9XnP_LihQjBQkZNagya40oIYHd4s98HwqDDIY7_9qlbQcV5tTwMp7VvkfiOW8C_9tuO5Gmv2FbkNUaoyD1qv0dHOd6PiUq0Sjas2JHYI0wcSrRgtOv5YCh4TDmXUeXoKBwqRr06KmkLinS_PVssaiVcZsYK9yTI8KMa2xSlV9GniC-RzQWRmzZFpij_HhuWSadZKPODPXionkXK-kYioqhGN2_dfVRUhKRk3zHrJ0Fbxc_L3nS4KJc3qZITdzgo3th3fLBOVLYs5nd3lOHOhRM1bJCdiWTqKD-_YA6puxMVzWWvkKnjllcBewtq5dUNAS_ryWWypHdqQBsMhOd3KoGd4shCJQ3geUWDTcvg54-lzF-j7LcWY8SH5uEMnScUtAYANXSy-YaYLlWrTbrxWrSKizxyuvKtHtfpsTEVLYx-KGG1Au_xdJrmrNohwtcpvA2qOsobBG8gy07Fw0Q_Lfg0SUWl1w1Jwc2Oh3e8uJc-3JJU051schj6cKl6F__m761rULtfYIDqbdcEtsWLHxvt71JSBz_sCUqIiURrbNYyaW-ETQAOvS9BlSDpUd4QxxqbtlIvIVS45P2jYYgDx1XKjqUK0ysmtWwnt1ufJB8OTk81SDXIuGNBhKmfKVBpvC4JUNo11PVpE_wZVDe5VW75Xrf_OGRqjmsQSSayQ-4jkzmYq0cD6KJkTgz-vKYlawKYsM7LiGVgPa97vQWdTsrp9fE1ifJtYqUNTm9EHmZ6t7ltx2UNbIGRV-S02lCBlaSChLx0-N0Bt8jLjRADFQ44u8aT8UonP5ZtVVnJLu0K_yU25Xi__bOj3CBRLMIouQghjr6uPUmEJ9Z0egv8IIGQAoNHnJ8BOfD_iEIf0cZp8fk0xvOUTm_btfu_FB3J4T6Uu8eqSeAyq9k-YsbRl7AfFrusx4md5DQHvtGviV9ekpTPJ3aQUP4E_P3IACJPkr_E45EuguA3cy3vLN2SzlaNtwUoUn6jkSgfV_c8xc6fVDbQpE.ijYIeMsGKFZzgW5CkbWQNQ",
     *               "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.LastAuthUser": "jordan--medngine.com",
     *               "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.clockDrift": "0",
     *               "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.accessToken": "eyJraWQiOiI1TXNhUkZveU1STTdzejFkMytPRHJtbmxqdFVNWVZiWm53Q1k2YVVMSlNjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9WM3dIWllaTWIiLCJjbGllbnRfaWQiOiIydG5pMHZ2cDhjMTJzczNsNHV2bWIzaTVydSIsIm9yaWdpbl9qdGkiOiI3Nzk0ZGRjMi02MDQ3LTRiYmItOTE3ZS01ZWVkNzNkZTUzODEiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiIzMWVmMjRhNy03ZjNlLTRjYTMtYmM2Yy1lNWFkMDgzOGRhN2YiLCJ1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIn0.IooK2ieRyvZkDbLIKfgyOrvvSXE0HOVZRjo272CO8KcU88ML8d-B_-UaDQsJO-e1UNWhXmaGUijCUWzQE-FXhnLjmHYD4LlbnHvlVerL2xPhsCjI0J7K0mdwKo1cplC8S0oIBw1hAV1UEpbeBeTVm-SbfzgArgCFXXwM6ljRtc0qRkOfopqYnk5n_SCMOTu3ykQNE1e0zUHPpvLtdayy6ZlgbwppGETxUkssyeVkQs8e-EUS3If62saOMTHQHe0lW4I3Sf0MvZGMeARuDMGhjzyE_-91zAPDcBYM7goZNp4FexYsQ9RysyJ-IQ6u6hofVSeUkf8Thz7LOls0ddH7DQ"
     *           }
     *       },
     *       "Session": null,
     *       "client": {
     *           "endpoint": "https://cognito-idp.us-east-1.amazonaws.com/",
     *           "fetchOptions": {}
     *       },
     *       "signInUserSession": {
     *           "idToken": {
     *               "jwtToken": "eyJraWQiOiJKZTltZDJFaFNFZVIzTWNWeDR1ZDhVWU1hTG9LaFltdjdMQmhuSWtJVlowPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOnVfZW1haWwiOiJqb3JkYW5AbWVkbmdpbmUuY29tIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfVjN3SFpZWk1iIiwiY29nbml0bzp1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIiwiY3VzdG9tOnVfaWQiOiIyIiwib3JpZ2luX2p0aSI6Ijc3OTRkZGMyLTYwNDctNGJiYi05MTdlLTVlZWQ3M2RlNTM4MSIsImN1c3RvbTp1X3R5cGVfbmFtZSI6ImRpc3RyaWJ1dG9yIiwiYXVkIjoiMnRuaTB2dnA4YzEyc3MzbDR1dm1iM2k1cnUiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsImN1c3RvbTp1X3R5cGVfaWQiOiIyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiJhZWE1YTViZi1jMzZhLTQ2M2ItYjU4Yy00MzZhZTIyM2YwY2EiLCJlbWFpbCI6ImpvcmRhbkBtZWRuZ2luZS5jb20ifQ.A5TfP4LqFOVmYxaVv70HynJpMHGbOiRofYYazbRSEfGq76EZnJTKaD56qNKuZaNg66FI2_dzGoES6QlHXbZKBVIBAHuokPf1Do-rrR0pjb_DLUXvBXr9C9fxEquDJYs_nR22vuYpa8HzOWHaRYNTqoXz7eeAwH7lVu_1BUYTfD0bZlHegIERGCcbWGdAtuMZR06H2U5dMRNUKDLAeLdbSftA_XJDp4_kS7kiyzDtgzc4z8J3z3LAPaU-2NeO5m6ap6dcn7zy0m62z7vC03OclH3lqxKi2e_gxFEEQVQGkBdin6fy7q2SWLKb9XUZmArVH9L-x-61r5SMPV0DGNwAew",
     *               "payload": {
     *                   "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *                   "email_verified": true,
     *                   "custom:u_email": "jordan@medngine.com",
     *                   "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *                   "cognito:username": "jordan--medngine.com",
     *                   "custom:u_id": "2",
     *                   "origin_jti": "7794ddc2-6047-4bbb-917e-5eed73de5381",
     *                   "custom:u_type_name": "distributor",
     *                   "aud": "3r6svrev16k3pr6n5c61t9cg6n",
     *                   "event_id": "b5a6ade3-d289-428e-974e-2c10416d1343",
     *                   "custom:u_type_id": "2",
     *                   "token_use": "id",
     *                   "auth_time": 1670169403,
     *                   "exp": 1670173003,
     *                   "iat": 1670169403,
     *                   "jti": "aea5a5bf-c36a-463b-b58c-436ae223f0ca",
     *                   "email": "jordan@medngine.com"
     *               }
     *           },
     *           "refreshToken": {
     *               "token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.IdRL0E7x3PhabLBNSPP0WA-pUaQGBgRdNGrVN1EETmJ6Eih5QLxbc73KUsRP7a1omnphN49ki30GniTPDhcn_2TGPvW_tngQn6ojkWBriDSJ0LId6Q7PXNBF7VwhINVUJWvxDXbdC8ZPkzgoQ75AM6rFjmrm3uijP_lZVYTHhmBnvjGOupTW3Ln-Ogi3B6M7aXs5GHd6p2ANYfj7TqMetCGkh9o6SbwuvEOPUNCoKgROG2EVjVUfthu7u4BBaUCl3aUVbNtnGl0xlHTmZdUFauyDMitx377G81oSWlxLN6NjAzBxlWrEplHCyPQPQL6G_u1lf_5nMFpwTZ71nqATyA.qGIskeFnhij0U5g2.e4n-bVM7IYxdxmZj0XJW13wQGSbkbmLxCwT541w9IAmnXQARle8GROW3gI-2Tsu1sIoxvg9KS7FV9JWna6Fw-yQG3e4lYzVuxcvZdq3xL05gpbD5PL1HiU4h08eXvAqmjJABepRpbRPiw6PhrrRF5vGRB8g-_C_PrlPO1m9SCyCgYHUYfURVnhO5wr_isZzBpI8z1A7thNtdDBGyuKuQRjCZHtG0E-iid2Q-oc3XtJtTENFw-3va32cL7omoRBf2TVO2wRZasdeR_R9Ee_Jd9DVeJwaiNQGEoMIlvhGsEDXJN-9XnP_LihQjBQkZNagya40oIYHd4s98HwqDDIY7_9qlbQcV5tTwMp7VvkfiOW8C_9tuO5Gmv2FbkNUaoyD1qv0dHOd6PiUq0Sjas2JHYI0wcSrRgtOv5YCh4TDmXUeXoKBwqRr06KmkLinS_PVssaiVcZsYK9yTI8KMa2xSlV9GniC-RzQWRmzZFpij_HhuWSadZKPODPXionkXK-kYioqhGN2_dfVRUhKRk3zHrJ0Fbxc_L3nS4KJc3qZITdzgo3th3fLBOVLYs5nd3lOHOhRM1bJCdiWTqKD-_YA6puxMVzWWvkKnjllcBewtq5dUNAS_ryWWypHdqQBsMhOd3KoGd4shCJQ3geUWDTcvg54-lzF-j7LcWY8SH5uEMnScUtAYANXSy-YaYLlWrTbrxWrSKizxyuvKtHtfpsTEVLYx-KGG1Au_xdJrmrNohwtcpvA2qOsobBG8gy07Fw0Q_Lfg0SUWl1w1Jwc2Oh3e8uJc-3JJU051schj6cKl6F__m761rULtfYIDqbdcEtsWLHxvt71JSBz_sCUqIiURrbNYyaW-ETQAOvS9BlSDpUd4QxxqbtlIvIVS45P2jYYgDx1XKjqUK0ysmtWwnt1ufJB8OTk81SDXIuGNBhKmfKVBpvC4JUNo11PVpE_wZVDe5VW75Xrf_OGRqjmsQSSayQ-4jkzmYq0cD6KJkTgz-vKYlawKYsM7LiGVgPa97vQWdTsrp9fE1ifJtYqUNTm9EHmZ6t7ltx2UNbIGRV-S02lCBlaSChLx0-N0Bt8jLjRADFQ44u8aT8UonP5ZtVVnJLu0K_yU25Xi__bOj3CBRLMIouQghjr6uPUmEJ9Z0egv8IIGQAoNHnJ8BOfD_iEIf0cZp8fk0xvOUTm_btfu_FB3J4T6Uu8eqSeAyq9k-YsbRl7AfFrusx4md5DQHvtGviV9ekpTPJ3aQUP4E_P3IACJPkr_E45EuguA3cy3vLN2SzlaNtwUoUn6jkSgfV_c8xc6fVDbQpE.ijYIeMsGKFZzgW5CkbWQNQ"
     *           },
     *           "accessToken": {
     *               "jwtToken": "eyJraWQiOiI1TXNhUkZveU1STTdzejFkMytPRHJtbmxqdFVNWVZiWm53Q1k2YVVMSlNjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9WM3dIWllaTWIiLCJjbGllbnRfaWQiOiIydG5pMHZ2cDhjMTJzczNsNHV2bWIzaTVydSIsIm9yaWdpbl9qdGkiOiI3Nzk0ZGRjMi02MDQ3LTRiYmItOTE3ZS01ZWVkNzNkZTUzODEiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiIzMWVmMjRhNy03ZjNlLTRjYTMtYmM2Yy1lNWFkMDgzOGRhN2YiLCJ1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIn0.IooK2ieRyvZkDbLIKfgyOrvvSXE0HOVZRjo272CO8KcU88ML8d-B_-UaDQsJO-e1UNWhXmaGUijCUWzQE-FXhnLjmHYD4LlbnHvlVerL2xPhsCjI0J7K0mdwKo1cplC8S0oIBw1hAV1UEpbeBeTVm-SbfzgArgCFXXwM6ljRtc0qRkOfopqYnk5n_SCMOTu3ykQNE1e0zUHPpvLtdayy6ZlgbwppGETxUkssyeVkQs8e-EUS3If62saOMTHQHe0lW4I3Sf0MvZGMeARuDMGhjzyE_-91zAPDcBYM7goZNp4FexYsQ9RysyJ-IQ6u6hofVSeUkf8Thz7LOls0ddH7DQ",
     *               "payload": {
     *                   "sub": "67813bbf-baed-418f-9971-9ef2ecd09388",
     *                   "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sgIfRjI8C,
     *                   "client_id": "3r6svrev16k3pr6n5c61t9cg6n",
     *                   "origin_jti": "7794ddc2-6047-4bbb-917e-5eed73de5381",
     *                   "event_id": "b5a6ade3-d289-428e-974e-2c10416d1343",
     *                   "token_use": "access",
     *                   "scope": "aws.cognito.signin.user.admin",
     *                   "auth_time": 1670169403,
     *                   "exp": 1670173003,
     *                   "iat": 1670169403,
     *                   "jti": "31ef24a7-7f3e-4ca3-bc6c-e5ad0838da7f",
     *                   "username": "jordan--medngine.com"
     *               }
     *           },
     *           "clockDrift": 0
     *       },
     *       "authenticationFlowType": "USER_SRP_AUTH",
     *       "storage": {
     *           "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.idToken": "eyJraWQiOiJKZTltZDJFaFNFZVIzTWNWeDR1ZDhVWU1hTG9LaFltdjdMQmhuSWtJVlowPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOnVfZW1haWwiOiJqb3JkYW5AbWVkbmdpbmUuY29tIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfVjN3SFpZWk1iIiwiY29nbml0bzp1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIiwiY3VzdG9tOnVfaWQiOiIyIiwib3JpZ2luX2p0aSI6Ijc3OTRkZGMyLTYwNDctNGJiYi05MTdlLTVlZWQ3M2RlNTM4MSIsImN1c3RvbTp1X3R5cGVfbmFtZSI6ImRpc3RyaWJ1dG9yIiwiYXVkIjoiMnRuaTB2dnA4YzEyc3MzbDR1dm1iM2k1cnUiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsImN1c3RvbTp1X3R5cGVfaWQiOiIyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiJhZWE1YTViZi1jMzZhLTQ2M2ItYjU4Yy00MzZhZTIyM2YwY2EiLCJlbWFpbCI6ImpvcmRhbkBtZWRuZ2luZS5jb20ifQ.A5TfP4LqFOVmYxaVv70HynJpMHGbOiRofYYazbRSEfGq76EZnJTKaD56qNKuZaNg66FI2_dzGoES6QlHXbZKBVIBAHuokPf1Do-rrR0pjb_DLUXvBXr9C9fxEquDJYs_nR22vuYpa8HzOWHaRYNTqoXz7eeAwH7lVu_1BUYTfD0bZlHegIERGCcbWGdAtuMZR06H2U5dMRNUKDLAeLdbSftA_XJDp4_kS7kiyzDtgzc4z8J3z3LAPaU-2NeO5m6ap6dcn7zy0m62z7vC03OclH3lqxKi2e_gxFEEQVQGkBdin6fy7q2SWLKb9XUZmArVH9L-x-61r5SMPV0DGNwAew",
     *           "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.IdRL0E7x3PhabLBNSPP0WA-pUaQGBgRdNGrVN1EETmJ6Eih5QLxbc73KUsRP7a1omnphN49ki30GniTPDhcn_2TGPvW_tngQn6ojkWBriDSJ0LId6Q7PXNBF7VwhINVUJWvxDXbdC8ZPkzgoQ75AM6rFjmrm3uijP_lZVYTHhmBnvjGOupTW3Ln-Ogi3B6M7aXs5GHd6p2ANYfj7TqMetCGkh9o6SbwuvEOPUNCoKgROG2EVjVUfthu7u4BBaUCl3aUVbNtnGl0xlHTmZdUFauyDMitx377G81oSWlxLN6NjAzBxlWrEplHCyPQPQL6G_u1lf_5nMFpwTZ71nqATyA.qGIskeFnhij0U5g2.e4n-bVM7IYxdxmZj0XJW13wQGSbkbmLxCwT541w9IAmnXQARle8GROW3gI-2Tsu1sIoxvg9KS7FV9JWna6Fw-yQG3e4lYzVuxcvZdq3xL05gpbD5PL1HiU4h08eXvAqmjJABepRpbRPiw6PhrrRF5vGRB8g-_C_PrlPO1m9SCyCgYHUYfURVnhO5wr_isZzBpI8z1A7thNtdDBGyuKuQRjCZHtG0E-iid2Q-oc3XtJtTENFw-3va32cL7omoRBf2TVO2wRZasdeR_R9Ee_Jd9DVeJwaiNQGEoMIlvhGsEDXJN-9XnP_LihQjBQkZNagya40oIYHd4s98HwqDDIY7_9qlbQcV5tTwMp7VvkfiOW8C_9tuO5Gmv2FbkNUaoyD1qv0dHOd6PiUq0Sjas2JHYI0wcSrRgtOv5YCh4TDmXUeXoKBwqRr06KmkLinS_PVssaiVcZsYK9yTI8KMa2xSlV9GniC-RzQWRmzZFpij_HhuWSadZKPODPXionkXK-kYioqhGN2_dfVRUhKRk3zHrJ0Fbxc_L3nS4KJc3qZITdzgo3th3fLBOVLYs5nd3lOHOhRM1bJCdiWTqKD-_YA6puxMVzWWvkKnjllcBewtq5dUNAS_ryWWypHdqQBsMhOd3KoGd4shCJQ3geUWDTcvg54-lzF-j7LcWY8SH5uEMnScUtAYANXSy-YaYLlWrTbrxWrSKizxyuvKtHtfpsTEVLYx-KGG1Au_xdJrmrNohwtcpvA2qOsobBG8gy07Fw0Q_Lfg0SUWl1w1Jwc2Oh3e8uJc-3JJU051schj6cKl6F__m761rULtfYIDqbdcEtsWLHxvt71JSBz_sCUqIiURrbNYyaW-ETQAOvS9BlSDpUd4QxxqbtlIvIVS45P2jYYgDx1XKjqUK0ysmtWwnt1ufJB8OTk81SDXIuGNBhKmfKVBpvC4JUNo11PVpE_wZVDe5VW75Xrf_OGRqjmsQSSayQ-4jkzmYq0cD6KJkTgz-vKYlawKYsM7LiGVgPa97vQWdTsrp9fE1ifJtYqUNTm9EHmZ6t7ltx2UNbIGRV-S02lCBlaSChLx0-N0Bt8jLjRADFQ44u8aT8UonP5ZtVVnJLu0K_yU25Xi__bOj3CBRLMIouQghjr6uPUmEJ9Z0egv8IIGQAoNHnJ8BOfD_iEIf0cZp8fk0xvOUTm_btfu_FB3J4T6Uu8eqSeAyq9k-YsbRl7AfFrusx4md5DQHvtGviV9ekpTPJ3aQUP4E_P3IACJPkr_E45EuguA3cy3vLN2SzlaNtwUoUn6jkSgfV_c8xc6fVDbQpE.ijYIeMsGKFZzgW5CkbWQNQ",
     *           "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.LastAuthUser": "jordan--medngine.com",
     *           "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.clockDrift": "0",
     *           "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.accessToken": "eyJraWQiOiI1TXNhUkZveU1STTdzejFkMytPRHJtbmxqdFVNWVZiWm53Q1k2YVVMSlNjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2NzgxM2JiZi1iYWVkLTQxOGYtOTk3MS05ZWYyZWNkMDkzODgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9WM3dIWllaTWIiLCJjbGllbnRfaWQiOiIydG5pMHZ2cDhjMTJzczNsNHV2bWIzaTVydSIsIm9yaWdpbl9qdGkiOiI3Nzk0ZGRjMi02MDQ3LTRiYmItOTE3ZS01ZWVkNzNkZTUzODEiLCJldmVudF9pZCI6ImI1YTZhZGUzLWQyODktNDI4ZS05NzRlLTJjMTA0MTZkMTM0MyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzAxNjk0MDMsImV4cCI6MTY3MDE3MzAwMywiaWF0IjoxNjcwMTY5NDAzLCJqdGkiOiIzMWVmMjRhNy03ZjNlLTRjYTMtYmM2Yy1lNWFkMDgzOGRhN2YiLCJ1c2VybmFtZSI6ImpvcmRhbi0tbWVkbmdpbmUuY29tIn0.IooK2ieRyvZkDbLIKfgyOrvvSXE0HOVZRjo272CO8KcU88ML8d-B_-UaDQsJO-e1UNWhXmaGUijCUWzQE-FXhnLjmHYD4LlbnHvlVerL2xPhsCjI0J7K0mdwKo1cplC8S0oIBw1hAV1UEpbeBeTVm-SbfzgArgCFXXwM6ljRtc0qRkOfopqYnk5n_SCMOTu3ykQNE1e0zUHPpvLtdayy6ZlgbwppGETxUkssyeVkQs8e-EUS3If62saOMTHQHe0lW4I3Sf0MvZGMeARuDMGhjzyE_-91zAPDcBYM7goZNp4FexYsQ9RysyJ-IQ6u6hofVSeUkf8Thz7LOls0ddH7DQ"
     *       },
     *       "keyPrefix": "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n",
     *       "userDataKey": "CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.userData"
     *   }
     */
    const getUser: T_getUser = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser()

            if (user) {
                user.getSession((err: Error|null, session: CognitoUserSession|null) => {
                    if (err) {
                        console.log(`Get user failed! (#c107): ${err.name}: ${err.message}`)
                        reject()
                    } else {
                        resolve(user)
                    }
                })
            } else {
                reject()
            }
        })
    }

    /** 
     * Sign out then always redirect to home page.
     * 
     * This doesn't return anything.
     */
    const logout: T_logout = async (redirectTo?: string) => {
        const now = Date.now()

        const user = UserPool.getCurrentUser()

        if (user) {
            // Run backend validator on logout.
            // Fail silently.
            // TODO: execution of this func shouldn't rely on frontend.
            try {
                await axios.post(`${API_DB_URL}/logout`, { logoutUser: user })
            } catch (e) {}
            
            user.signOut(() => {
                localStorage.setItem('mr_session', String(false))
                localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                window.location.href = redirectTo || '/'
            })
        }
    }

    /**
     * Helper function to check login status.
     * 
     * Keeps cached status for 10 minutes.
     * Used for low-security auth checking.
     * Anything like writing to the backend db or getting sensitive user data like address
     *      SHOULD NOT use this method. Use `checkLoginNow` instead.
     * This method relies on user's local browser time.
     */
    const checkLogin: T_checkLogin = async () => {
        const now = Date.now()
        // Get from local stroage or fall back on defaults.
        let isLoggedIn = (localStorage.getItem('mr_session') === 'true') || false
        let isLoggedInExpires = Number(localStorage.getItem('mr_session_exp')) || 0
        // If the expiry time hasn't passed, just return the cached login status.
        if (isLoggedInExpires > now) {
            console.warn(`Returning from cache: ${isLoggedIn}, exp: ${isLoggedInExpires} (in ${(isLoggedInExpires - now)/1000}s)`)
            return isLoggedIn
        }
        // Otherwise get Cognito data obj - if it fails, then user is not logged in.
        // Also update the cache timeout.
        console.warn(`Getting new cache`)
        return await checkLoginNow()
    }

    /**
     * Helper function to return precise login status from AWS Cognito.
     * 
     * Doesn't use cache - calls AWS everytime so don't use it too frequently.
     *      However this function will update the cache.
     * Use this for more sensitive operations such as reading address or POSTing data
     *      to save in the db, etc.
     */
    const checkLoginNow: T_checkLoginNow = async () => {
        /* eslint-disable no-eval */
        console.warn('RUNNING CHECKLOGIN-NOW')
        const now = Date.now()

        const res = await getSession()
        .then((sesh) => {
            if (sesh && typeof sesh === 'object' && Object.keys(sesh)?.includes('accessToken')) {
                // If session object exists, they are logged in.
                console.warn(`Cache1 = logged in as of ${Date.now()}, expires ${isLoggedInExpires} `
                             + `(in ${(isLoggedInExpires - now) / 1000}s).`)
                localStorage.setItem('mr_session', String(true))
                localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                return true
            } else {
                console.warn(`Cache2 = logged OUT as of ${Date.now()}, expires ${isLoggedInExpires} `
                             + `(in ${(isLoggedInExpires - now) / 1000}s).`)
                localStorage.setItem('mr_session', String(false))
                localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
                return false
            }
        }).catch(err => {
            console.warn(`Cache3 = logged OUT as of ${Date.now()}, expires ${isLoggedInExpires} `
                             + `(in ${(isLoggedInExpires - now) / 1000}s).`)
            localStorage.setItem('mr_session', String(false))
            localStorage.setItem('mr_session_exp', String(now + AUTH_CACHE_TIMEOUT))
            return false
        })

        return res
    }

    /**
     * Decode JSON web tokens for verification purposes
     * 
     * The `idToken` and `accessToken` can be decoded, but the `refreshToken` cannot.
     * 
     * See example data structures at: https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html
     */
    const decodeJwt: T_decodeJwt = async (token: string): Promise<DecodedJwt|null> => {
        // const testtoken = localStorage.getItem('CognitoIdentityServiceProvider.3r6svrev16k3pr6n5c61t9cg6n.jordan--medngine.com.idToken')
        if (!token) return null
        try {
            const decoded = jwtDecode(token)
            return decoded as DecodedJwt
        } catch (e) {
            console.error('Token decode failure (#4419)', e)
            return null
        }
    }
    
    /**
     * Helper for getting Cognito's `idToken` credentials to pass to backend for API calls etc.
     *  - Check for differences between local storage token and returned token
     *  - This has its own error handling - will return a non-empty string in the 'error' key.
     */
    const getUsernameAndIdToken: T_getLocalIdToken = async () => {
        // Need to get username in order to get the key name.
        const usernameAndToken = await getSession().then(data => {
            if (!data || !Object.keys(data).length) {
                throw new Error('Please login and try again (#6868).')
            }
            const username = data.getIdToken().payload['cognito:username']
            const idToken = data.getIdToken().getJwtToken()
            return [username, idToken]
        }).then(([username, idToken]) => {
            // Success
            if (!username || !idToken) {
                throw new Error('System error #4545: Please contact support.')
            }
            const ID_TOKEN_NAME = `CognitoIdentityServiceProvider.${COGNITO_CLIENT_ID}.${username || ''}.idToken`
            const local_idToken = localStorage.getItem(ID_TOKEN_NAME) || null
            if (idToken !== local_idToken) {
                throw new Error("Session user doesn't match browser user. Please refresh your "
                                 + "page and try again (#8585)")
            }
            return {username, idToken, error: ''}
        }).catch(e => {
            // Failure
            console.warn('Error retrieving cognito auth idToken (#6650) -->')
            console.warn(e)
            console.warn('<--')
            return {username: '', idToken: '', error: `Not logged in #88847`} // don't change this number!!
        })
        return usernameAndToken
    }

    

    // ====================================================================================
    // Shopping cart functions
    // ====================================================================================


    // ==== Async functions ========= >>

    // Add 1 or more of a product to shopping cart.
    // - Can also "add" a negative amount if you want, or use the 
    const addToShoppingCart: T_addToShoppingCart = async (
        productId: number|string, skuId: number|string, count: number = 1,
        sessionJson: string,
    ): Promise<CartDict|null> => {
        let cartDict = await addToRedisCart(productId, skuId, count, sessionJson)
        if (typeof cartDict === 'string') {
            cartDict = JSON.parse(cartDict)
        }
        setShoppingCart(cartDict)
        getShoppingCartSize(cartDict)
        return cartDict // cart dict (not json), or null.
    }

    // Remove 1 or more of a product to shopping cart.
    // - This just calls the `addToShoppingCart` func above.
    const removeFromShoppingCart: T_removeFromShoppingCart = async (
        productId: number|string, skuId: number|string, count: number = 1,
        sessionJson: string,
    ): Promise<CartDict|null> => {
        return await addToShoppingCart(productId, skuId, count * -1, sessionJson)
    }

    // Set the quantity of a particular product/sku in the shopping cart.
    const changeCountInShoppingCart: T_changeCountInShoppingCart = async (
        productId: number|string, skuId: number|string, count: number,
        sessionJson: string,
    ): Promise<CartDict|null> => {
        let cartDict = await addToShoppingCart(productId, skuId, count, sessionJson)
        if (typeof cartDict === 'string') {
            cartDict = JSON.parse(cartDict)
        }
        setShoppingCart(cartDict)
        getShoppingCartSize(cartDict || {})
        return cartDict // cart dict (not json), or null.
    }

    // Get or generate an Order ID when user visits checkout-confirmation page.
    const getOrderId: T_getOrderId = async (sessionJson: string) => {
        const orderId = await getRedisOrderId(sessionJson)
        return orderId || null
    }

    // Delete the Order ID after the order is placed.
    const resetOrderId: T_resetOrderId = async (sessionJson: string) => {
        const result = await deleteRedisOrderId(sessionJson)
        return !!result
    }

    // ==== Synchronous functions ========= >>
    
    // Get shopping cart from Redis and transform to usable dict.
    // - Users who aren't logged in won't be able to use shopping cart.
    // Probably won't use this - it's better to use the `shoppingCart` state variable.
    const getShoppingCart: T_getShoppingCart = async (sessionJson: string): Promise<CartDict|null> => {
        let cartDict = await getRedisCart(sessionJson)
        if (typeof cartDict === 'string') {
            cartDict = JSON.parse(cartDict)
        }
        setShoppingCart(cartDict)
        getShoppingCartSize(cartDict)
        console.log('[Account.tsx] got cartDict', cartDict)
        return cartDict
    }

    // Get count of items in shopping cart
    const getShoppingCartSize: T_getShoppingCartSize = (shoppingCart: CartDict|null): number => {
        const numItemsInCart = getCartItemsCount(shoppingCart)
        setShoppingCartSize(numItemsInCart || 0)
        return numItemsInCart
    }

    // Empty out the shopping cart (like after user completes a purchase).
    const emptyShoppingCart: T_emptyShoppingCart = async (sessionJson: string): Promise<CartDict|null> => {
        let cartDict = await emptyRedisCart(sessionJson)
        if (typeof cartDict === 'string') {
            cartDict = JSON.parse(cartDict)
        }
        cartDict = cartDict || null
        setShoppingCart(cartDict)
        getShoppingCartSize(cartDict)
        return cartDict
    }



    // ====================================================================================
    // Functions to store note for shopping cart.
    // ====================================================================================
    
    // Get the note that the user previously entered into the checkout/shopping-cart page.
    const getCartNote: T_getCartNote = async (sessionJson: string): Promise<string|null> => {
        const note = await getRedisCartNote(sessionJson)
        return note || null
    }
    
    // Save the note that the user enters into the checkout/shopping-cart page (to Redis).
    const saveCartNote: T_saveCartNote = async (sessionJson: string, text: string): Promise<void> => {
        await setRedisCartNote(sessionJson, text)
    }


    // ====================================================================================
    // Function to get user ID from sessionJson
    // ====================================================================================

    const getUserId: T_getUserId = async (sessionJson: string): Promise<number|null> => {
        return await getUserIdFromJsonStr(sessionJson)
    }
    
    // ====================================================================================
    // Functions to get/save user-selected address
    // ====================================================================================

    // Get the address ID of the address that user selected in shopping cart.
    // - Backend will return `null` if not found - frontend will deal with that.
    const getAddressId: T_getAddressId = async (sessionJson: string): Promise<number|null> => {
        return await getShippingAddressId(sessionJson)
    }

    // Save the address ID of the address that user selected in shopping cart.
    const saveAddressId: T_saveAddressId = async (sessionJson: string, addressId: number|string): Promise<boolean> => {
        return await setShippingAddressId(sessionJson, addressId)
    }


    // ====================================================================================
    // Functions to remember the user-selected payment-method ID and payment-account ID.
    // ====================================================================================

    // Save the payment-method ID and payment-account ID of user's selected option in shopping cart.
    const getPaymentMethodIds: T_getPaymentMethodIds = async (): Promise<[number, number]> => {
        return await new Promise((resolve, reject) => {
            try {
                const [paymentMethodId, paymentAccountId] = getCookiePaymentMethodIds()
                resolve([Number(paymentMethodId), Number(paymentAccountId)])
            } catch (e) {
                reject()
            }
        })
    }

    // Get the payment-method ID and payment-account ID that the user selected in shopping cart.
    const savePaymentMethodIds: T_savePaymentMethodIds = async (
        paymentMethodId: number|string, paymentAccountId: number|string
    ): Promise<void> => {
        return await new Promise((resolve, reject) => {
            try {
                setCookiePaymentMethodIds(paymentMethodId, paymentAccountId)
                resolve()
            } catch (e) {
                reject()
            }
        })
    }

    // When user selects a nullish payment option, delete the payment info from storage.
    const deletePaymentAccountId: T_deletePaymentAccountId = async (
        paymentMethodId: number|string
    ): Promise<void> => {
        return await new Promise((resolve, reject) => {
            try {
                deleteCookiePaymentAccountId(paymentMethodId)
                resolve()
            } catch (e) {
                reject()
            }
        })
    }

    
    // ====================================================================================
    // ====================================================================================
    // ====================================================================================


    // Populate the `shoppingCart` on mount, and set the AccountContext to "ready"
    // - Nvm, don't get shopping cart on mount because sessinJson won't be ready yet.
    useEffect(() => {
        // getShoppingCart()
        setACTCTX_READY(true)
    }, [])


    return (
        <AccountContext.Provider 
            value={{ 
                getUserId,
                register, resendVerification, confirmVerificationCode,
                forgotPassword, confirmPassword, 
                authenticate, getSession, getUser, logout,
                checkLogin, checkLoginNow,
                decodeJwt, getUsernameAndIdToken,
                addToShoppingCart, removeFromShoppingCart, changeCountInShoppingCart, emptyShoppingCart,
                getShoppingCart, getShoppingCartSize, shoppingCart, shoppingCartSize,
                getOrderId, resetOrderId,
                saveCartNote, getCartNote,
                getAddressId, saveAddressId,
                getPaymentMethodIds, savePaymentMethodIds, deletePaymentAccountId,
                ACTCTX_READY,
             }}
        >
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext }