import getDiscountMultiplier from './_getDiscountMultiplier'

import { CartDict } from '../../../../cache/Cart_Types'
import { CART_REFERENCE_KEYS } from '../../../../../config'
import { ProductsById } from '../../patient/product-detail/PatientProductPage'

/**
 * This is a massive function so it was moved to a separate file.
 * Get total price of all items in the cart.
 * Compute both the original and discounted prices to display to user.
 * 
 * Types of discounts:
 *  - Bulk discounts, for buying more than "x" quantity of a product.
 *      - These discount amounts rates are determined at the product level, 
 *        as opposed to SKU level.
 *      - However, "x" quantity of a specific SKU (!) will have to be added to
 *        the cart for the discount to be applied to only that SKU.
 *      - So if at the product level there is a 10% discount for buying 10 or more,
 *        then the discount WON'T be applied if user buys 5 of each of 2 different types
 *        of SKUs within the same product umbrella.
 *      - This isn't a bug. Look at the product page to see how the discounts are
 *        displyed - the discount is applied for "x" quantity of the SAME SKU.
 *      - As of now all bulk discounts are a PERCENTAGE reduction for each product, not dollar.
 * - Coupon discounts
 *      - If a coupon code must be valid.
 *      - Coupons can have 2 types of restrictions:
 *          - Minimum number of items in cart.
 *          - Minimum pre-tax, pre-discount value of all items in cart.
 *      - If user's cart size doesn't fulfill these requirements, then they don't get the discount.
 *      - As of now, the coupon functions are NOT set up to:
 *          - Check for activation/exipation dates of the coupons.
 *          - Apply both a fixed dollar amount and percentage discount in the same coupon.
 *          - Use more than one coupon at a time.
 * 
 * Calculations notes:
 *  - Tax amount shown to user is computed based on the base total price of all
 *    products in the cart, before discounts are applied.
 *  - If there is both a bulk discount and a coupon discount, then coupon discount 
 *    is applied to the (already-reduced) price after the bulk discount is applied.
 *  - Discount savings, for both bulk discounts and coupon discounts, are computed based on
 *    the post-tax price for the undiscounted dollar amount, subtracted from the post-tax price 
 *    of the discounted dollar amount.
 *  - Shipping costs are added at the very end, and are effectively not taxed.
 */
const _getCartPrices = (
    setCartBaseProductsPrice:React.Dispatch<React.SetStateAction<number>>,
    setCartBasePrice: React.Dispatch<React.SetStateAction<number>>,
    setCartTotalDiscounted: React.Dispatch<React.SetStateAction<number>>,
    setCartBulkDiscountAmount: React.Dispatch<React.SetStateAction<number>>,
    setCartCouponDiscountAmount: React.Dispatch<React.SetStateAction<number>>,
    shoppingCart: CartDict|null,
    productDetails: ProductsById,
    userStateTaxRate: number,
    userShippingPrice: number,
    couponDiscountPercent: number,
    couponDiscountDollars: number,
) => {   
    // Base price for all products without bulk/coupon discounts, shipping, or taxes
    let price = 0
    // Same as `price`, but with bulk discount applied.
    let price_after_BulkDiscount = 0

    if (!shoppingCart || !Object.keys(shoppingCart).length || !productDetails || !Object.keys(productDetails).length) {
        setCartBaseProductsPrice(0)
        setCartBasePrice(0)
        setCartTotalDiscounted(0)
        setCartBulkDiscountAmount(0)
        setCartCouponDiscountAmount(0)
        return
    }

    // shoppingCart:  {"1":{"1":9,"2":7,"10":90},"3":{"13":6},"orderId":"AXYE3BPBJ7E"}
    
    for (let _productId of Object.keys(shoppingCart)) {
        const productId = _productId.trim()
        // (Non-first) calls to this func will have 'orderId' key in cartDict.
        if (!productId || CART_REFERENCE_KEYS.has(productId.toLowerCase())) continue;
        for (let skuId of Object.keys(shoppingCart[productId])) {
            const quantity = shoppingCart[productId][skuId]
            const baseProductPrice = productDetails[productId]?.skus[skuId]?.price
            const discountMultiplier = getDiscountMultiplier(productDetails, productId, quantity)
            price += (quantity * baseProductPrice)
            price_after_BulkDiscount += (quantity * baseProductPrice * discountMultiplier)
        }
    }

    setCartBaseProductsPrice(price)
    
    
    // Base prices for all products, before any discounts. (Includes tax & shipping)
    const price_after_Tax = (price + (price * userStateTaxRate))
    const price_after_Tax_Shipping = price_after_Tax + userShippingPrice
    setCartBasePrice(price_after_Tax_Shipping)
    
    // Discount prices at this point in the code only account for discounts for bulk purchases.
    const price_after_BulkDiscount_Tax = (price_after_BulkDiscount * (1 + userStateTaxRate))

    // Amount of bulk discount (calculate after taxes to make the amont look bigger)
    const bulkDiscountAmount = price_after_BulkDiscount_Tax - price_after_Tax
    setCartBulkDiscountAmount(bulkDiscountAmount)
    

    // Apply coupon discounts, if any.
    if (couponDiscountPercent || couponDiscountDollars) {
        let price_after_BulkDiscount_CouponDiscount = 0
        if (couponDiscountPercent) {
            price_after_BulkDiscount_CouponDiscount = price_after_BulkDiscount * (1 - couponDiscountPercent)
        } else {
            price_after_BulkDiscount_CouponDiscount = price_after_BulkDiscount - couponDiscountDollars
        }
        const price_after_BulkDiscount_CouponDiscount_Tax = price_after_BulkDiscount_CouponDiscount * (1 + userStateTaxRate)
        const price_after_BulkDiscount_CouponDiscount_Tax_Shipping = price_after_BulkDiscount_CouponDiscount_Tax + userShippingPrice
        const couponDiscountAmount = price_after_BulkDiscount_CouponDiscount_Tax - price_after_BulkDiscount_Tax
        setCartCouponDiscountAmount(couponDiscountAmount)
        setCartTotalDiscounted(price_after_BulkDiscount_CouponDiscount_Tax_Shipping)
    } else {
        const price_after_BulkDiscount_Tax_Shipping = price_after_BulkDiscount_Tax + userShippingPrice
        setCartCouponDiscountAmount(0)
        setCartTotalDiscounted(price_after_BulkDiscount_Tax_Shipping)
    }

}

export default _getCartPrices