import './check-box.scss';



interface Props {
  children?: any,
}

export const CheckBox = ({ children }: Props) => {
  return (

    <div className="div-contain-checkbox">
      <label className="checkbox-label">
        <input type="checkbox" name="checkbox" />
        {children}
      </label>
    </div>

  );
}