/**
 * Format number into dollars and cents.
 * Methods `toLocaleString()` and `.toFixed(2)` can't be used together, so use this function instead.
 */
const formattedDollarsAmount = (price: number) => {
    let dollars = price.toLocaleString()
    let dotIndex = dollars.indexOf('.')
    if (dotIndex !== -1) {
        dollars = dollars.slice(0, dotIndex + 3)
    }
    // Ensure there are still two decimal places
    dotIndex = dollars.indexOf('.')
    if (dotIndex === -1) {
        dollars += '.00'
    } else if (dotIndex === dollars.length - 1) {
        dollars += '00'
    } else if (dotIndex === dollars.length - 2) {
        dollars += '0'
    }
    // Add dollar sign.
    // If the number is negative, move the '-' before the dollar sign.
    if (dollars.indexOf('-') !== -1) {
        dollars = dollars.replace('-', '-$')
    } else {
        dollars = '$' + dollars
    }
    return dollars
}

export default formattedDollarsAmount