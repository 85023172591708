import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account';
import { useUserBusinessId } from '../../../../hooks/useAuthCredentials'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { InputImageField } from '../../../shared/form/input-image-field/InputImageField';
import { HeaderBanner } from '../../../layout/header-banner/HeaderBanner';
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage';
import { ProgressBar } from '../../../shared/progress-bar/ProgressBar';
import { TextAreaField } from '../../../shared/form/text-area/TextAreaField';
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject';

import LoadingCircle from '../../../../static/gif/loading-circle.gif'

import { VALID_IMG_NAMES, VALID_UPLOAD_TYPES } from '../../../../../config'

import './supplier-registration.scss';



export const SupplierRegistration4 = () => {
    const STEP = 4

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Input field values
    const [bio, setBio] = useState('')
    const [specialties, setSpecialties] = useState('')
    const [certifications, setCertifications] = useState('')
    // Modal to show loading status.
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')
    // Session object which will be needed every time an image is added.
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [hasDbData, setHasDbData] = useState<boolean|null>(null)
    // Error messages for fields
    const [imageErrorMessage, setImageErrorMessage] = useState('')

    // Auth/session functions
    const { checkLogin, getUsernameAndIdToken } = useContext(AccountContext)

    // Hook for getting user's business Id.
    const {userBizId, setUserBizId} = useUserBusinessId(sessionObj, setSessionObj, setErrorMessage)

    // Pre-load spinner
    const [showPreloadSpinner, setShowPreloadSpinner] = useState(true)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()

    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Message based on referrer
        setReferrerMessage(qp.reason === 'reg-incomplete' ? 'Please complete your registration' : '')

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Try to prefill user info. This will return empty object if user isn't logged in.
        getPrefilledData()
    }, [])

    // If in preview mode.
    useEffect(() => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
        }
    }, [isPreviewMode])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (isPreviewMode !== false || !checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/supplier-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin, isPreviewMode])

    /**
     * Pre-fill information if user completed this step already.
     */
     const getPrefilledData = async () => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        // IMPORTANT: don't even think about using `setSession` here (as done in other reg steps), 
        // which will cause infinite loop because changing that state var causes child components
        // to reload, and some of those child components also try to get the session.
        //      i.e. don't do this: `setSessionObj(session)`
        if (session.error) { return }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL
        const formData = new FormData() 
        formData.append('session', sessionJson)
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/get-supplier-data/step/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            setBio(data.bio)
            setSpecialties(data.specialties)
            setCertifications(data.certifications)
            // Mark that data was retrieved
            setHasDbData(!!data.bio)
        }).catch(async (err) => {
            setErrorMessage('')
            setTimeout(() => {
                setErrorMessage(err.response?.data || `${err.name}: ${err.message} (#8802dGs). Please logout and login again.`)
            }, 350)
        }).finally(() => {
            setShowPreloadSpinner(false)
        })
    }

    // Validate inputs (Placeholder for basic frontend validation before submitting to backend).
    // TODO: ^ (but not super important since backend already validates it, and react form
    //       has SOME auto validators built in)
    const frontendValidationErrors = () => {
        let errorCount = 0
        return errorCount
    }
    
    // Submit registration data to AWS Cognito to create a new user.
    const onSubmitRegistrationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setShowSpinner(true)
        setSpinnerText('Validating')

        // Run frontend validation.
        if (frontendValidationErrors()) {
            setShowSpinner(false)
            return
        }

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        // IMPORTANT: don't even think about using `setSession` here (as done in other reg steps), 
        // which will cause infinite loop because changing that state var causes child components
        // to reload, and some of those child components also try to get the session.
        //      i.e. don't do this: `setSessionObj(session)`
        if (session.error) { 
            setHideAllErrors(false)
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        // Config for API call.
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL

        // Add username and idToken to form submission
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)

        /**
         * If data should be UPDATED in DB as opposed to created.
         */
         if (hasDbData) {
            axios.post(`${API_DB_URL}/update-supplier-data/${STEP}`, 
                Object.fromEntries(formData), 
                config,
            ).then(async () => {
                const qpOut = {
                    reason: 'updated',
                }
                if (isEdit) {
                    window.location.href = buildPath(`/account`, qpOut)
                } else {
                    window.location.href = buildPath(`/supplier-registration/${STEP+1}`, qpOut)
                }
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802dst'))
                }, 350)
            })
        }
        
        // Submit to db
        if (!hasDbData) {
            axios.post(`${API_DB_URL}/new-user-init-supplier/${STEP}`, 
                Object.fromEntries(formData), 
                config
            ).then(async (res) => {
                // Success
                setHideAllErrors(true)
                setSpinnerText('Confirmed!')
                setTimeout(() => {
                    setShowSpinner(false)
                    window.location.href = `/supplier-registration/${STEP+1}`
                }, 800)
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#3564st'))
                }, 350)
            })
        }
    }



    return (
        <>
        <HeaderBanner
            imageName="doctorpen"
            imageText={isEdit ? 'EDIT BUSINESS PAGE' : 'SUPPLIER APPLICATION'}
            imageSpacerColor='BLUE'
        />

        {/* Modal to show loading spinner on registration pages. */}
        <RegistrationLoadingModal
            show={showSpinner}
            setShow={setShowSpinner}
            titleText={spinnerText}
        />

        <PreviewModeMessage show={!!isPreviewMode} link={'/supplier-registration/1'} />

        <div className="supplier-register-page-layout p4" style={{marginBottom: '150px'}}>
            {/* Show different messages based on how the user got here */}
            {referrerMessage ? (
                <div className='registration-message'>
                    {referrerMessage}
                </div>
            ) : null}

            {!isEdit ? (
                <div className="progress-bar-placement p3">
                    <ProgressBar whichStep={4} numberOfSteps={5} />
                </div>
            ) : null}

            <div className='body-container-no-vert-pad'>
                <form onSubmit={onSubmitRegistrationForm}>
                    <div className="header p4">
                        LOGO & BANNER IMAGE
                    </div>
                    <div className="img-upload-row">
                        <InputImageField
                            uploadType={VALID_UPLOAD_TYPES.IMG_BUSINESS}
                            imageName={VALID_IMG_NAMES.LOGO_1}
                            name='logo-image'
                            wrapperStyle={{ width: '250px', height: '250px' }}
                            imageStyle={{ width: '100%', height: '100%' }}
                            title='Upload'
                            desc='Logo'
                            businessId={userBizId}
                            setImageErrorMessage={setImageErrorMessage}
                            sessionObj={sessionObj}
                            setSessionObj={setSessionObj}
                            setHideAllErrors={setHideAllErrors}
                            getUsernameAndIdToken={getUsernameAndIdToken}
                            isPreviewMode={!!isPreviewMode}
                        />
                        <InputImageField
                            uploadType={VALID_UPLOAD_TYPES.IMG_BUSINESS}
                            imageName={VALID_IMG_NAMES.BANNER_1}
                            name='banner-image'
                            wrapperStyle={{ flex: 1, height: '250px' }}
                            imageStyle={{ width: '100%' }}
                            title='Upload'
                            desc='Lab Images'
                            businessId={userBizId}
                            setImageErrorMessage={setImageErrorMessage}
                            sessionObj={sessionObj}
                            setSessionObj={setSessionObj}
                            setHideAllErrors={setHideAllErrors}
                            getUsernameAndIdToken={getUsernameAndIdToken}
                            isPreviewMode={!!isPreviewMode}
                        />
                    </div>

                    {/* Image error messages */}
                    {(imageErrorMessage) ? (
                        <div className='error-message'>
                            {imageErrorMessage}
                        </div>
                    ): null }
                    
                    {/* Only show the form after checking backend for pre-fill data. */}
                    <div 
                        className="form-wrapper-uppermid p4"
                        style={{ display: (showPreloadSpinner ? 'none' : 'flex') }}
                    >
                        <div className="header2 p4" style={{marginTop: '40px'}}>
                            ABOUT YOUR COMPANY
                        </div>
                        <p className="paragraph-spacing"> 
                            Enter basic information about your company
                        </p>
                        <TextAreaField
                            required
                            id="supplier-registration-companybio"
                            name="companyBio"
                            placeholder='Company Bio'
                            style={{ minHeight: '170px', height: 'calc(100% - 2rem)', resize: 'none' }}
                            value={bio}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setBio(event.target.value)
                            }}
                            disabled={!!isPreviewMode}
                        />
                        <div className="header2 p4">
                            SPECIALTIES
                        </div>
                        <InputField
                            required
                            id="supplier-registration-specialties"
                            name="specialties"
                            value={specialties}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSpecialties(event.target.value)
                            }}
                            disabled={!!isPreviewMode}
                        />
                        <div className="header2 p4">
                            CERTIFICATIONS
                        </div>
                        <InputField
                            required
                            id="supplier-registration-certifications"
                            name='certifications'
                            value={certifications}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCertifications(event.target.value)
                            }}
                            disabled={!!isPreviewMode}
                        />
                    </div>

                    {/* If form is not ready to show yet, show the spinner */}
                    {showPreloadSpinner ? (
                        <img src={LoadingCircle} className='preload-spinner' />
                    ) : null}

                    {/* Form (non-image) error messages */}
                    {(!hideAllErrors && errorMessage) ? (
                        <div className='error-message'>
                            {errorMessage}
                        </div>
                    ): null }

                    <div className='buttons-container'>
                        {isEdit ? (
                            <BlockButton
                                type='button'
                                style={{ marginTop: '60px', width: '250px' }}
                                buttonStyle="ghost-purple"
                                onClick={() => window.location.href = '/account'}
                            >
                                CANCEL
                            </BlockButton>
                        ) : (
                            <Link to={`/supplier-registration/${STEP - 1}${isPreviewMode ? '?mode=preview' : ''}`}>
                                <BlockButton 
                                    style={{ marginTop: '60px', width: '250px' }} 
                                    buttonStyle="ghost-purple"
                                >
                                    BACK
                                </BlockButton>
                            </Link>
                        )}
                        
                        {(!isPreviewMode && !isEdit) ? (
                            <BlockButton 
                                type='submit'
                                style={{ marginTop: '60px', width: '250px' }} 
                                buttonStyle="purple"
                            >
                                CONTINUE
                            </BlockButton>
                        ) : (isEdit ? (
                            <BlockButton
                                type='submit'
                                style={{ marginTop: '60px', width: '250px' }}
                                buttonStyle="purple"
                            >
                                SAVE
                            </BlockButton>
                        ) : (
                            <BlockButton
                                type='button'
                                style={{ width: '250px' }}
                                buttonStyle="purple"
                                onClick={() => {
                                    window.location.href=`/supplier-registration/${STEP + 1}?mode=preview`
                                }}
                            >
                                NEXT
                            </BlockButton>
                        ))}
                    </div>

                    <ToastContainer 
                        hideProgressBar={true}
                        position='bottom-center'
                        style={{textAlign: 'center', cursor: 'default'}}
                    />
                </form>
            </div>
        </div>
        </>
    )
}