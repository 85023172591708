import { useNavigate } from 'react-router-dom';

import { BlockButton } from "../buttons/block-button/BlockButton";
import { Modal } from "./Modal"

import './tag-accept-modal.scss'



type Props = {
  show: boolean;
  closeModal: Function;
  title: string;
  subtitle:string;
  message: string;
  uuid: string;
  altPath?: string; // If it's not using the traditional url that uses `uuid`.
};



const API_DB_URL = process.env.REACT_APP_API_DB_URL



export const TagAcceptModal = ({ 
    show, closeModal,
    title, subtitle, message,
    uuid, altPath = '', 
}: Props) => {
    // On accept, will redirect to this path which tells the backend to mark the tag as active.
    // - Then redirects to MyAccount page with a query param that makes the toaster show success msg.
    // - DON'T use `navigate()`, bc it breaks on local.
    const acceptInvitation = (uuid: string, altPath: string) => {
        // This is the default way that is used for all invites except for the on-registration
        // invite that taps "up" to the rep-lead, when user registers as a rep-member of their org.
        if (!altPath) {
            window.location.href = `${API_DB_URL}/accept-tag/${uuid}`
        } else {
            window.location.href = `${API_DB_URL}/${altPath}`
        }
    }

    return (
        <div className='tag-accept-modal'>
            <Modal
                modalType={2}
                show={show}
                closeModal={closeModal}
            >
                <div className="modal-contents">
                        <h2 className="title">{title}</h2>
                        <div className="sub-title">{subtitle}</div>
                        <div className='regular-text'>{message}</div>
                        <div className='buttons-row'>
                            <BlockButton
                                type='button'
                                style={{ marginTop: '40px' }} 
                                buttonStyle="ghost-purple"
                                onClick={closeModal}
                            >
                                Cancel
                            </BlockButton>
                            <BlockButton
                                type='button'
                                style={{ marginTop: '40px' }} 
                                buttonStyle="purple"
                                onClick={() => acceptInvitation(uuid, altPath)}
                            >
                                Confirm
                            </BlockButton>
                        </div>
                </div>
            </Modal>
        </div>
    );
};