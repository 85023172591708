

/**
 * {
                "id": 3,
                "name": "Keenan Finkelstein",
                "description": "Back",
                "file_object": "https://medngine-media.s3.amazonaws.com/media/20210204_073719.jpg"
            }
 */

interface IProductResource {
    get id(): number;
    get name(): string;
    get description(): string;
    get fileObject(): string;
};

export type ProductResourceInfo = {
    id: number,
    name: string,
    description: string,
    fileObject: string,
};

export class ProductResource implements IProductResource {
    private _id: number;
    private _name: string;
    private _description: string;
    private _fileObject: string;

    constructor(resource: ProductResourceInfo) {
        this._id = resource.id;
        this._name = resource.name;
        this._description = resource.description;
        this._fileObject = resource.fileObject;
    }

    get description(): string {
        return this._description;
    }
    get fileObject(): string {
        return this._fileObject
    }
    get id(): number {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
}