import React, { Dispatch, useContext, useEffect, useState } from 'react';

import { AccountContext } from '../../auth/Account';

import { ImageCarousel2 } from '../carousels/image-carousel2/ImageCarousel2';
import { Modal } from '../modal/Modal';
import { PillButton } from '../../shared/buttons/pill-button/PillButton';

import { SkuInfo } from '../../../../config'
import { SessionPacket } from '../../../types/session';

import './text-card.scss';

interface Props {
    productId: number;
    name: string;
    nameDisplay: string;
    imageSrc1: string;
    imageSrc2: string;
    imageSrc3: string;
    imageSrc4: string;
    style: any;
    vendor: string;
    brand: string;
    description: string;
    productUses?: string;
    productResearch?: string;
    skus: SkuInfo[];
    showProductDetailsModal: boolean;
    setShowProductDetailsModal: Dispatch<boolean>;
}



export const TextCard = ({ productId, name, nameDisplay, 
                           imageSrc1, imageSrc2, imageSrc3, imageSrc4, 
                           style, 
                           vendor, brand, description, productUses, productResearch, skus,
                           showProductDetailsModal, setShowProductDetailsModal }: Props) => {

    const { getUsernameAndIdToken, addToShoppingCart } = useContext(AccountContext)

    // Session
	const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)

    // Get session obj
	const getSessionObj = async () => {
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session || null)
	}
	useEffect(() => {
		getSessionObj()
	}, [getUsernameAndIdToken])


    const truncateText = (text: string = '', charLimit: number = 340) => {
        if (text.length < charLimit) return text;
        return text.substring(0, charLimit) + '...';
    }




    return (
        <>

        {/* Carousel & text section */}
        <div className='text-card-container'>
            <Modal 
                modalType={1}
                show={showProductDetailsModal}
                closeModal={() => setShowProductDetailsModal(false)}
            >
                <div className='product-detail-modal-container'>
                    <div className='title'>
                        {nameDisplay}
                    </div>
                    
                    <div className='table'>
                        <div className='table-header'>SKU</div>
                        <div className='table-header'>Dose / Size</div>
                        <div className='table-header unit-header'>$/unit</div>
                        <div className='table-header add-to-cart'>Add to cart</div>

                        {skus.map((sku: SkuInfo) => (
                            <React.Fragment key={String(sku.id)}>
                                <div className='table-field'>
                                    {sku.name_display || sku.name}
                                </div>
                                <div className='table-field'>
                                    {sku.size || sku.dose}
                                </div>
                                <div className='table-field unit-field'>
                                    ${sku.price}
                                </div>
                                <div className='table-field add-to-cart-button-field'>
                                    <PillButton
                                        buttonStyle="white gradient"
                                        hideArrow={true}
                                        onClick={() => { 
                                            const sessionJson = sessionObj ? JSON.stringify(sessionObj) : ''
                                            if (sessionJson) {
                                                addToShoppingCart(productId, sku.id, 1, sessionJson) 
                                            }
                                        }}   
                                    >
                                        +
                                    </PillButton>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </Modal>

            <ImageCarousel2
                productImageSrc1={imageSrc1}
                productImageSrc2={imageSrc2}
                productImageSrc3={imageSrc3}
                productImageSrc4={imageSrc4}
            />

            <div className="text-card" style={(style)}>
                <div className="item-list">
                    <div className="item-li-group productname">{truncateText(nameDisplay) || truncateText(name)}</div>
                    <div className="item-li-group brandname">Brand: {truncateText(brand)}</div>
                    <div className="item-li-group vendorname">Vendor: {truncateText(vendor)}</div>
                    <div className="item-li-group productdescription">{truncateText(description)}</div>
                    <PillButton
                        buttonStyle="white gradient"
                        style={{ marginTop: '10px', maxWidth: '330px' }}
                        onClick={() => setShowProductDetailsModal(true)}
                    >
                        VIEW PRODUCT DETAILS
                    </PillButton>
                </div>
            </div>
        </div>
        </>
    )
  }