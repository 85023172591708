//import './image-carousel.scss'
import './image-carouselnew.scss';
import React from 'react';
import ImageOne from "./images/c-image1.png";
import ImageTwo from "./images/c-image2.jpg";
import ImageThree from "./images/c-image3.jpg";




/*

$(document).ready(function() {
    $('a').on("click", function(e) {
        e.preventDefault();
    });
});

*/




/*

const carouselSlider = document.querySelector("#carousel__slide1");

const outputBox = document.getElementById("output-box");

if (carouselSlider) {
  carouselSlider.addEventListener("click", function (event) {

    if (outputBox) {
      outputBox.innerHTML;
      event.preventDefault();
    }

  }, false);
}

*/


/*

const carouselSlider = document.getElementById("#carousel__slide1");

const Listener = addEventListener("click", function (event) {
  event.preventDefault()
});


*/





/*

private handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.props.searchUsers(this.state.text);
    this.setState({text: ''});
  }

  private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({text: e.currentTarget.value});
  }

*/


/*


const displayImg = (e) => {
  e.preventDefault();
}

*/


export class ImageCarouselNew extends React.Component {


  render() {

    return (
      <div className="carousel-2-main">

        <section className="carousel">
          <ol className="carousel__viewport">
            <li id="carousel__slide1"
              className="carousel__slide">
              <img src={ImageOne} className="imageone" />
              <div className="carousel__snapper" />
            </li>
            <li id="carousel__slide2"
              className="carousel__slide">
              <img src={ImageTwo} className="imagetwo" />
              <div className="carousel__snapper" />
            </li>
            <li id="carousel__slide3"
              className="carousel__slide">
              <img src={ImageThree} className="imagethree" />
              <div className="carousel__snapper" />
            </li>
          </ol>

          <aside className="carousel__navigation">
            <ol className="carousel__navigation-list">
              <li className="carousel__navigation-item">
                <a href="#carousel__slide1"
                  className="carousel__navigation-button" />
                <div className="dots-padding" />
              </li>
              <li className="carousel__navigation-item">
                <a href="#carousel__slide2"
                  className="carousel__navigation-button" />
                <div className="dots-padding" />
              </li>
              <li className="carousel__navigation-item">
                <a href="#carousel__slide3"
                  className="carousel__navigation-button" />
              </li>
            </ol>
          </aside>
        </section>
      </div>
    );
  }
}