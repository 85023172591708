import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Greyback } from '../../../shared/form/greyback/Greyback';
import { InputField } from '../../../shared/form/input-field/InputField';
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import './about-page.scss';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';

type Props = {};

type State = {};



export const AboutPage = () => {

    useEffect(() => {
      clog('Demo: working `clog()` command')
    }, [])

    return (
      <>
        <PrintOnlyContent title={true}>Medngine about page</PrintOnlyContent>
        <HeaderBanner
          bannerType={1}
          imageName="opbedlower"
          imageText="ABOUT"
          imageSpacerColor='BLUE'
          scrollToTransparentRange={[100, 130]}
        />
        <div className="body-container aboutpage-layout">
          <div className="formwrapper-main">
            <div className="category-1-wrapper">
              <div className="about-text-container">
                <p>
                  Regenative Labs produces regenerative medicine products to address the
                  root cause of a patient&#39;s conditions using Wharton’s Jelly innovations rather
                  than masking the pain with other treatments. Regenative Labs works
                  closely with scientists, physicians, hospitals, and surgery centers to
                  constantly monitor and improve patient progress and outcomes for new
                  product development. Formed by veteran industry professionals familiar
                  with daily challenges of innovations in healthcare, the company provides
                  effective, non-addictive, non-invasive options for patients. Regenative Labs
                  as a laser-focused, expert product research and development team which
                  follows FDA guidelines of minimal manipulation for homologous use. The
                  company adheres to AATB and FDA guidelines.
                </p>
                <p>
                  
                </p>
              </div>
              <div className="greyback-container"><Greyback
                greybackStyle="base-content"
                style={{ width: '100%', height: '380px', borderRadius: '3px' }}
                closable={false}
              >VIDEO OR IMAGE</Greyback>
              </div>
            </div>
            <div className="category-2-wrapper">
              <div className="header">
                PROVIDING OUR PARTNERS THE HIGHEST QUALITY TISSUE ALLOGRAFTS
              </div>
              <p>
                Regenative Lab’s mission is to facilitate predictable patient outcomes by
                providing the highest quality human tissue allografts available. We
                demonstrate our commitment to quality by collecting data from patient
                outcomes and analyzing the data for statistical significance, ensuring
                physicians make the most informed decision for the health of their patients.
                With the goal of addressing the root cause, rather than masking the pain,
                Regenative Labs birth tissue allografts provide an effective, non-addictive,
                non-invasive option for patients in debilitating situations.
              </p>
              <div className="header">
              OUR LAB
              </div>
              <p>
                Our first-class, state-of-the-art production facility is located in Pensacola,
                Florida. We spared no expense when it came to the technology that we all
                insisted was necessary to produce the quality regenerative products we
                expect for our partners. Tours of the lab are available by appointment.
                <br/><br/>
                Regenative Labs products are manufactured in state-of-the-art ISO Class 7
                cleanrooms. Our lab features Advanced Air Quality Systems, ISO Class 5
                Biological Safety Cabinets, Ultra-Low -80C Freezer Systems, and Advanced
                Quality Control Systems. All equipment is calibrated and validated to ensure
                valid and consistent results are produced.
              </p>
            </div>
          </div>
        </div>
      </>
    )
}