import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { Table } from "../../../shared/tables/Table";
import { InputField } from "../../../shared/form/input-field/InputField";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import "./sales-report.scss";


//  TODO:  FIX PAGING ON TABLE IMPORT

export const SalesReport = () => {

  const ShowTimePeriod: SelectOption[] = [
    { option: ' by day ', value: 99 },
    { option: ' by week ', value: 5 },
    { option: ' by month ', value: 10 },
    { option: ' by quarter ', value: 20 },
  ]

  const ShowEntries: SelectOption[] = [
    { option: ' All ', value: 99 },
    { option: ' 5 ', value: 5 },
    { option: ' 10 ', value: 10 },
    { option: ' 20 ', value: 20 },
    { option: ' 50 ', value: 50 },
  ]

  const mockDataTable = [
    { orderNumber: "Total", date: "0", amount: "8700.0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },
    { orderNumber: "APF2-11", date: "0", amount: "0", status: "0", orderLink: '0', slot6: '0', slot7: '0', slot8: '0', slot9: '0', slot10: '0', slot11: '0' },



  ]


  return (
    <div className="sales-report-layout">
      <div className="body-container ">
        <div className="multicolor-headers">
          <div className="header small blue">FINANCIALS</div>
          <div className="header small">/ SALES REPORT</div>
        </div>

        <div className="main-container">
          <div className="header">Sales Report of Representative REP DEMO</div>
          <div className="text-wrapper">
            <span>If you choose to show volume by "day", then please also choose the month.</span>
            <span>If you choose to show volume by "month", "quarter", or "week", then you do not have to choose month.</span>
          </div>
          <div className="select-container">
            <div className="column">
              <span>YEAR (default current year)</span>
              <InputField
                name='year'
                inputStyle="outlined"
              />
            </div>
            <div className="column date-range">
              <span>TIME PERIOD</span>
              <div className="dropdown">
                <SelectField
                  name='time-period'
                  options={ShowTimePeriod}
                  selectStyle="outlined"
                />
              </div>
            </div>
            <div className="column button">
              <div className="whitespace">
              </div>
              <BlockButton
                buttonStyle='desktop-header-buttons purple'
                style={{ width: '120px', marginTop: '3px' }}
              >
                GO!
              </BlockButton>
            </div>
            <div className="column button">
              <div className="whitespace">
              </div>
              <BlockButton
                buttonStyle='desktop-header-buttons dark'
                style={{ width: '120px', marginTop: '3px' }}
              >
                CSV
              </BlockButton>
            </div>
          </div>

          <div className="volumetext-container">
            <div className="dot" />
            <span>SALES VOLUME</span>
          </div>
          <div className="graph-chart">

            <div className="main-container">
              <div className="left-numbers">
                <span>$9,000</span>
                <span>$8,000</span>
                <span>$7,000</span>
                <span>$6,000</span>
                <span>$5,000</span>
                <span>$4,000</span>
                <span>$4,000</span>
                <span>$3,000</span>
                <span>$2,000</span>
                <span>$1,000</span>
                <span className="close-right">$0</span>
              </div>
              <div className="chart-body">
                <div className="chart-itself">

                </div>
                <div className="bottom-numbers">
                  <span>2021-04</span>
                  <span>2021-05</span>
                  <span>2021-06</span>
                  <span>2021-07</span>
                  <span>2021-08</span>
                  <span>2021-09</span>
                  <span>2021-10</span>
                  <span>2021-11</span>
                  <span>2021-12</span>
                  <span>2022-01</span>
                </div>
              </div>
            </div>
          </div>

          <div className="top-row">
            <div className="left-box">
              <span>Show</span>
              <div className="dropdown">
                <SelectField
                  name='entries-number'
                  options={ShowEntries}
                  selectStyle="outlined faded"
                />
              </div>
              <span>entries</span>
            </div>

            <div className="search-container">
              <span className="search-text">SEARCH</span>
              <InputField name='search' />
            </div>
          </div>
          <div className="table-container">
            <Table
              headerStyle="salesreport-header-cols"
              gridType="salesreport-container"
              tableStyle="tags-items-fill"
              style={{ marginTop: '30px', }}
              visibleRows={4}
              data={mockDataTable}
              columnNames={["NAME", "2021-04", "2021-05", "2021-06", "2021-07", "2021-08", "2021-09", "2021-10", "2021-11", "2021-12", "2022-01",]}
              pagingOption="paging-block"
            />
          </div>
        </div>
      </div>
    </div>
  )
}