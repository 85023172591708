import React from "react";
import { Link } from "react-router-dom";


interface Props {
  link: string;
  show: boolean;
}


export const PreviewModeMessage = ({ link, show }: Props) => {

    return (
        <>
        {show ? (
            <div style={{
                position: 'relative',
                padding: '30px 20px 20px 20px',
                width: '100%',
                backgroundColor: '#d03',
                textAlign: 'center',
                color: '#fff',
                fontSize: '2rem',
                fontWeight: 'bold',
                marginTop: '-20px', // bc prod bumps it down a little
            }}>
                <div className='body-container-no-vert-pad'>
                    You are viewing this page in preview mode.
                    <br/><br/>
                    To exit this preview and start 
                    your registration,&nbsp;
                    <Link to={link} className='preview-link-active'>
                        click here
                    </Link>.
                </div>
            </div>
        ): null}
        </>
    )
}
