/**
 * For businesses (vendors), show reps who the business has tagged.
 */

import React from 'react'

import { BlockButton } from '../../shared/buttons/block-button/BlockButton';
import { MyAccountInfo, TaggedReps } from '../../../../config'

import { Icon } from '../../shared/icon/Icon';


interface Props {
    getTagsListError: string;
    tagsData_VtoR: TaggedReps[];
    untagRep: Function;
    userId: number;
    userType: 'vendor'|'supplier';
    accountInfo: MyAccountInfo;
    setShowTagInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTagInviteType: React.Dispatch<React.SetStateAction<'clinic'|'rep'|''>>;
}

export const TaggedReps_Biz = ({
    getTagsListError,
    tagsData_VtoR,
    untagRep,
    userId, userType, accountInfo,
    setShowTagInviteModal, setTagInviteType,
}: Props) => {
    
    return (
        <div className='section'>
            <div className='title-row'>
                <div className='section-title'>
                    My Sales Reps
                </div>
            </div>

            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            <table className='info-table'>
                <thead>
                    <tr>
                        <td className='title medium'>Sales Rep</td>
                        {/* <td>Tagged by</td> */}
                        <td>Status</td>
                        <td>Invited</td>
                        <td>Accepted</td>
                    </tr>
                </thead>
                <tbody>
                    {tagsData_VtoR.map((row, index) => (
                        <tr 
                            key={index + '--' + row.to_uid}
                            className='tag-row'
                            style={{ cursor: 'default' }}
                            title={
                                `Invited by:\r\n`
                                + `    ${row.from_name ? `${row.from_name}\r\n` : ''}`
                                + `    ${row.from_email ? `${row.from_email}\r\n` : ''}`
                                + `    ${row.from_biz_name}\r\n`
                                + `Invite sent to:\r\n`
                                + `${row.to_name ? `    ${row.to_name}\r\n` : ''}`
                                + `    ${row.to_email}`
                            }
                        >
                            <td className='title medium product-cell'>
                                {row.to_name || row.to_email}
                            </td>
                            {/* <td className='medium product-cell'>
                                {row.from_name}
                            </td> */}
                            <td className='smaller product-cell'>
                                {row.active ? 'Active' : 
                                    row.active === false ? 'Deactivated' : 
                                    (row.active === null && !row.invited_at) ? 'Email failed to send' : 
                                    (row.active === null && !row.accepted_at) ? 'Invitation sent' : 
                                    'Re-invited' // Only happens if tag was de-activated then user was invited again later.
                                }
                            </td>
                            <td className='small product-cell'>
                                {row.invited_at}
                            </td>
                            <td className='small product-cell'>
                                {(row.active) ? (
                                    row.accepted_at
                                ) : (row.active === false) ? (
                                    'Deactivated' // This should never show bc it's filtered out on backend.
                                ) : (row.active === null && !row.invited_at) ? (
                                    <span style={{color: '#f34'}}>
                                        Email failed to send
                                    </span>
                                ) : (
                                    <span style={{color: '#f34'}}>
                                        Pending
                                    </span>
                                )}
                            </td>
                            <td className='smallest untag-cell'>
                                <button 
                                    type='button'
                                    className='untag-button'
                                    onClick={() => {
                                        untagRep(
                                            'deactivate', null, false,
                                            userId, userType, accountInfo, false,
                                            row.from_roid, row.to_roid,
                                            row.from_reporg_name, row.to_reporg_name,
                                            row.from_bid, row.from_biz_name, 
                                            row.from_uid, row.to_uid, row.from_name, row.to_name,
                                            row.to_cid, row.to_clinic_name, 
                                            row.active)
                                    }}
                                >
                                    <Icon name='circle-x' />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Button to invite a Rep to be tagged */}
            <BlockButton
                type='button'
                style={{ marginTop: '40px', width: 'fit-content' }} 
                buttonStyle="ghost-purple"
                className='less-pad mid-size' // 'edit-info main-action'
                onClick={() => {
                    setShowTagInviteModal(true)
                    setTagInviteType('')
                }}
            >
                Invite a rep
            </BlockButton>
        </div>
    )
}