import { useEffect, useState } from "react"

import DoctorPen from "../../../static/img/doctor-pen.png"
import Microscope from "../../../static/img/microscope.png"
import OpbedLower from "../../../static/img/opbed-lower.png"
import OpbedMid from "../../../static/img/opbed-mid.png"
import SkelPelvis from "../../../static/img/skelpelvis.png"
import OpbedTop from "../../../static/img/opbed-top.png"
import SkelProducts from "../../../static/img/home-skel-products-background.png"
import SkelProducts2 from "../../../static/img/home-skel-products-items-shadow.png"
import RegenVendor from "../../../static/img/vendor-banner-upload.png"
import VendorLogo from "../../../static/img/regen-vendor-logo.png"
import CompanyVendorUpload from "../../../static/img/company-vendor-upload.png"
import DoctorProfileBanner from "../../shared/image-spans/doctor-profile-banner.png"
import './header-banner.scss';

//  TODO - figure out how to pass multi style props for different banner text alignments

interface Props {
    bannerType?: number;
    imageText?: string;
    imageTextHomeLine?: string;
    imageTextHomeLine2?: string;
    imageTextVendorBanner?: string;
    
    companyVendorUpload?: string;
    style?: React.CSSProperties;
    styleText?: React.CSSProperties;
    // Background image: Use imageName="CUSTOM" for src's that are urls.
    imageName: 'CUSTOM' | 'microscope' | 'opbedlower' | 'opbedmid' | 'opbedtop' | 'skelpelvis' | 'doctorpen' | 'skelproducts' | 'regenvendor' | 'doctorprofile';
    imageSrcUrl?: string; // Provide an image src url IFF imageName=CUSTOM.
    // Logo image: Use vendorImageLogoSrcUrl="CUSTOM" for src's that are urls.
    vendorImageLogo?: string;
    vendorImageLogoSrcUrl?: string; // Provide an image src url IFF vendorImageLogo=CUSTOM.
    // Secondary image: Provide a src to use your own secondary img (on the right side).
    secondaryImgSrcUrl?: string;
    // To cover white background that shows behind translucent navbar when scrolling up fast on page.
    imageSpacerColor: 'BLUE' | 'LIGHTGRAY';
    // Set this to true to NOT change the title's opacity when scrolling behind the navbar.
    dontChangeTitleOpacity?: boolean;
    // Range of scroll distances within which the banner title fades out
    scrollToTransparentRange?: [number, number];
}

/**
 * Banner image with page title, used on some registration pages like Vedor Registration.
 */
export const HeaderBanner = ({ companyVendorUpload, imageText, imageTextVendorBanner,
    imageTextHomeLine, imageTextHomeLine2, imageSpacerColor,
    imageName, imageSrcUrl, vendorImageLogo, vendorImageLogoSrcUrl, secondaryImgSrcUrl,
    style = {}, styleText = {}, bannerType = 1,
    dontChangeTitleOpacity = false, scrollToTransparentRange = [60, 90] }: Props) => {
    // Min and max opacities for the banner title, which fades to near-invisible as you scroll down.
    const OPACITY_RANGE = [.05, 1]
    const SCROLL_RANGE = scrollToTransparentRange

    // Image elements.
    const headerImages: { [key: string]: JSX.Element } = {
        "microscope": <img className="banner-image" src={Microscope} alt="null" />,
        "opbedlower": <img className="banner-image" src={OpbedLower} alt="null" />,
        "opbedmid": <img className="banner-image" src={OpbedMid} alt="null" />,
        "opbedtop": <img className="banner-image" src={OpbedTop} alt="null" />,
        "skelpelvis": <img className="banner-image" src={SkelPelvis} alt="null" />,
        "doctorpen": <img className="banner-image" src={DoctorPen} alt="null" />,
        "skelproducts": <img className="banner-image" src={SkelProducts} alt="null" />,
        "regenvendor": <img className="banner-image" src={RegenVendor} alt="null" />,
        "doctorprofile": <img className="banner-image" src={DoctorProfileBanner} alt="null" />,
        "CUSTOM": <img className="banner-image" src={imageSrcUrl} alt="null" />,

    }

    const secondaryHeaderImages: { [key: string]: JSX.Element } = {
        "skelproducts2": <img className="banner-image-secondary" src={SkelProducts2} alt="null" />,
        "vendorlogo1": <img className="banner-image-secondary" src={VendorLogo} alt="null" />,
        "companyvendorupload": <img className="banner-image-secondary" src={CompanyVendorUpload} alt="null" />,
        "CUSTOM": <img className="banner-image-secondary" src={vendorImageLogoSrcUrl} alt="null" />,
    }


    // Make the banner title become more invisible as it is scrolled down.
    const [titleOpacity, setTitleOpacity] = useState(1)

    // Make navbar translucent on pages with a banner image, until the image is scrolled off screen.
    const changeTitleOpacityOnScroll = () => {
        if (dontChangeTitleOpacity) { return }

        const scrollPos: number = window.pageYOffset
        if (scrollPos < SCROLL_RANGE[0]) {
            setTitleOpacity(OPACITY_RANGE[1])
        } else if (scrollPos > SCROLL_RANGE[1]) {
            setTitleOpacity(OPACITY_RANGE[0])
        } else if (scrollPos >= SCROLL_RANGE[0] && scrollPos <= SCROLL_RANGE[1]) {
            const opacityVal = OPACITY_RANGE[1] -
                (((scrollPos - SCROLL_RANGE[0]) /
                    (SCROLL_RANGE[1] - SCROLL_RANGE[0])
                ) *
                    (OPACITY_RANGE[1] - OPACITY_RANGE[0])
                )
            setTitleOpacity(opacityVal)
        }
    }

    // Listen for scroll event.
    useEffect(() => {
        window.addEventListener("scroll", changeTitleOpacityOnScroll);
        return () => {
            window.removeEventListener("scroll", changeTitleOpacityOnScroll);
        }
    }, [])

    const BANNER_TYPE_NAMES: { [key: number]: string } = {
        1: 'default',
        2: 'home',
        3: 'vendorprofile',
    }

    const bannerTypeClass = 'btype-' + BANNER_TYPE_NAMES[bannerType]

    return (
        <div className='header-banner-container-outer'>
            {/* Spacer that's equal to height of the white header bar */}
            <div className='header-spacer'
                style={{
                    'backgroundColor': `${(imageSpacerColor === 'BLUE') ? 'rgb(64, 100, 164)' :
                        (imageSpacerColor === 'LIGHTGRAY') ? '#eee' : '#fff'
                        }`
                }}
            >

            </div>
            <div className={`banner-container ${bannerTypeClass}`}>
                {/* Banner image */}
                <div className={`banner-image-container-outer ${bannerTypeClass}`}>
                    <div className={`banner-image-container`}>
                        {headerImages[imageName]}
                    </div>
                </div>
                {/* Banner contents */}
                <div className={`body-container-no-vert-pad banner-right-container ${bannerTypeClass}`}>
                    {/* <div className={`banner-right-container-inner ${bannerTypeClass}`}> */}
                    {/* Text and images that go inside the banner */}
                    {(bannerType === 1) ? (
                        // Default bannerType
                        <>
                            <div className={`banner-title ${bannerTypeClass}`}
                                style={{ opacity: titleOpacity, ...styleText }}
                            >
                                <div className="image-text">{imageText}</div>
                            </div>
                        </>
                    ) : ((bannerType === 2) ? (
                        // For public/Home.tsx
                        <div className={`banner-title ${bannerTypeClass}`}
                            style={{ opacity: titleOpacity, ...styleText }}
                        >
                            <div className="home-text-wrapper">
                                <div className="home-line">{imageTextHomeLine}</div>
                                <div className="home-line-two">{imageTextHomeLine2}</div>
                            </div>
                        </div>
                    ) : ((bannerType === 3) ? (
                        // For VendorProfile.tsx
                        <div className={`banner-title ${bannerTypeClass}`}
                            style={{ opacity: titleOpacity, ...styleText }}
                        >
                            <div className="vendor-content-wrapper">
                                <div className="vendor-name">{imageTextVendorBanner}</div>
                                <div className="vendor-logo">
                                    {secondaryHeaderImages[vendorImageLogo || "vendorlogo1"]}
                                </div>
                            </div>
                        </div>
                    ) : ((bannerType === 4) ? (
                        // For VendorProfile.tsx
                        <div className={`banner-title ${bannerTypeClass}`}
                            style={{ opacity: titleOpacity, ...styleText }}
                        >
                            <div className="home-text-wrapper">
                                <div className="home-line">{imageTextHomeLine}</div>
                                <div className="home-line-two">{imageTextHomeLine2}</div>
                            </div>
                        </div>
                    ) :
                        null
                    )))}

                    {/* Secondary banner image */}
                    <div className='banner-image-secondary-container'>
                        {bannerType === 2 && (
                            secondaryHeaderImages.skelproducts2
                        )}

                        {bannerType === 3 && (
                            (secondaryImgSrcUrl) ? (
                                <img className="banner-image-secondary" src={secondaryImgSrcUrl} alt="null" />
                            ) : (
                                <>{secondaryHeaderImages.companyvendorupload}</>
                            )
                        )}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}