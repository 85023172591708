import './admin.scss'



export const AdminUsersTag = () => {
    return (
        <div className='body-container-admin'>
            Admins Users Tag page
        </div>
    )
}