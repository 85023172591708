import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { Table } from "../../../shared/tables/Table";
import { InputField } from "../../../shared/form/input-field/InputField";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import "./doctor-leaderboard.scss";


//  TODO:  FIX PAGING ON TABLE IMPORT

export const DoctorLeaderboard = () => {

  const ShowEntries: SelectOption[] = [
    { option: ' All ', value: 99 },
    { option: ' 5 ', value: 5 },
    { option: ' 10 ', value: 10 },
    { option: ' 20 ', value: 20 },
    { option: ' 50 ', value: 50 },
  ]

  const mockDataTable = [
    { orderNumber: "1", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "2", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "3", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "4", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "5", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "6", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "7", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "8", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "9", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR NAME' },
    { orderNumber: "10", date: "l-19-1999", amount: "2-19-1999", status: "BLANK", orderLink: 'DOCTOR' },
  ]


  return (
    <div className="doctor-leaderboard-layout">
      <div className="body-container ">
        <div className="multicolor-headers">
          <div className="header small blue">FINANCIALS</div>
          <div className="header small">/ DOCTOR LEADERBOARD</div>
        </div>

        <div className="main-container">
          <div className="header">I. Please Choose the Date Range and Limit Number of Doctors</div>
          <div className="select-container">
            <div className="column">
              <span>LIMIT</span>
              <InputField name='limit' />
            </div>
            <div className="column date-range">
              <span>DATE RANGE</span>
              <div className="dropdown">
                <SelectField
                  name='entries-number'
                  options={ShowEntries}
                />
              </div>
            </div>
            <div className="column button">
              <div className="whitespace">
              </div>
              <div className="button-holder">
                <BlockButton
                  buttonStyle='desktop-header-buttons purple'
                  style={{ width: '120px', marginTop: '3px' }}
                >
                  GO!
                </BlockButton>
              </div>
            </div>
          </div>
          <div className="header">II. Custom Leaderboard for Period (DATE RANGE HERE)</div>
          <div className="record-text">NO RECORD FOUND!</div>
          <div className="top-row">
            <div className="left-box">
              <span>Show</span>
              <div className="dropdown">
                <SelectField
                  name='entries-number'
                  options={ShowEntries}
                />
              </div>
              <span>entries</span>
            </div>

            <div className="search-container">
              <span className="search-text">SEARCH</span>
              <InputField name='search' />
            </div>
          </div>
          <div className="table-container">
            <Table
              headerStyle="tags-header-cols"
              gridType="tags-container"
              tableStyle="tags-items-fill"
              style={{ marginTop: '30px', }}
              visibleRows={4}
              data={mockDataTable}
              columnNames={["INDEX", "DOCTOR", "VOLUME", "PERCENTAGE CHANGE", "DELETE"]}
              pagingOption="paging-block"
            />
          </div>
        </div>
      </div>
    </div>
  )
}