import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { Icon } from '../../../shared/icon/Icon';
import { InputField } from '../../../shared/form/input-field/InputField';
import { MedPracButton } from '../../../shared/buttons/med-prac-button/MedPracButton';
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage'
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar";
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal';

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject'


import './doctor-registration.scss';
import 'react-toastify/dist/ReactToastify.css'



interface AddressItem {
    name: string;
    address: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    npi: string;
    country: string;
}

const EMPTY_ADDRESS_OBJ: AddressItem = {
    name: '',
    address: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    npi: '',
    country: '',
}

interface AddressItemHelper {
    street1_num: string;
    street1_street: string;
    street1_unit: string;
    zip_start: string;
    zip_end: string;
}

const EMPTY_ADDRESS_HELPER_OBJ: AddressItemHelper = {
    street1_num: '',
    street1_street: '',
    street1_unit: '',
    zip_start: '',
    zip_end: '',
}

type SearchOption = 'ADDRESS' | 'NPI' | ''

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY





export const DoctorRegistration2 = () => {
    const STEP: number = 2;

    // Time out for Google Maps address search debounce.
    const SEARCH_TIMEOUT = 400 // ms

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Http POST fields
    const [officeName, setOfficeName] = useState('')
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [city, setCity] = useState('')
    const [stateAbbr, setStateAbbr] = useState('')
    const [zip, setZip] = useState('')
    const [npi, setNpi] = useState('')

    // Modal to show loading status.
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Pre-load spinner
    const [showPreloadSpinner, setShowPreloadSpinner] = useState(true)

    // Whether user is searching for the clinic by "NPI" or "ADDRESS".
    const [option, setOption] = useState<SearchOption>('')
    // Search results after searching by address.
    const [addressList, setAddressList] = useState<AddressItem[]>([])
    // Chosen address from search results list.
    const [selectedAddressIndex, setSelectedAddressIndex] = useState<number|null>(null)
    const [selectedAddress, setSelectedAddress] = useState<AddressItem|null>(null)

    // Session data
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [hasDbData, setHasDbData] = useState<boolean|null>(null)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // Google Maps
    const [googleMapsAddress, setGoogleMapsAddress] = useState('')
    const mapRef = useRef<HTMLDivElement>(null)
    // Debounce for search
    const searchTimeoutRef = useRef<any>(null)

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()

    // Auth/session functions
    const { checkLogin, checkLoginNow, getUsernameAndIdToken } = useContext(AccountContext)

    // Backend endpoint
    const API_DB_URL = process.env.REACT_APP_API_DB_URL
    

    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Message based on referrer
        setReferrerMessage(
            qp.reason === 'reg-incomplete' ? 'Please complete your registration' :
            qp.referrer?.includes('registration') ? 'Continue your registration below' :
            ''
        )

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Try to prefill user info. This will return empty object if user isn't logged in.
        // (actually there's no data to pre-fill, but good for knowing if we are updating data
        //  or creating new data).
        getPrefilledData()
    }, [])

    // If in preview mode.
    useEffect(() => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
        }
    }, [isPreviewMode])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (isPreviewMode !== false || !checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/doctor-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin, isPreviewMode])

    /**
     * There's not actually anything to pre-fill for this step, but this is useful for knowing
     * if the POST'd data needs to create new rows or update existing rows.
     */
    const getPrefilledData = async () => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { return }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData() 
        formData.append('session', sessionJson)
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/get-provider-data/step/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            // Mark that data was retrieved
            const hasData = !!data.street1 && !!data.npi
            setHasDbData(hasData)
        }).catch(async (err) => {
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8802bgd'))
            }, 350)
        }).finally(() => {
            setShowPreloadSpinner(false)
        })
    }


    // Validate inputs (Do basic frontend validation before submitting to backend).
    const frontendValidationErrors = useCallback(() => {
        let errorCount = 0
        if (option === 'NPI') {
            if (!officeName || !street1 || !city || !stateAbbr || !zip || !npi) {
                errorCount += 1
            }
        } else if (option === 'ADDRESS') {
            if (!officeName || !street1 || !city || !stateAbbr || !zip) {
                errorCount += 1
            }
        } else {
            // Special case for invalid option
            return -1
        }
        
        return errorCount
    }, [option, officeName, street1, city, stateAbbr, zip, npi])

    /** 
     * Submit regisration Step 2 data db to continue user creation.
     */
      const onSubmitRegistrationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setShowSpinner(true)
        setSpinnerText('Validating')

        // Run frontend validation.
        const validationErrors = frontendValidationErrors()
        if (validationErrors === -1) {
            setShowSpinner(false)
            setErrorMessage('System error (#1646): please refresh the page and try again.')
            return 
        } else if (validationErrors) { 
            setShowSpinner(false)
            setErrorMessage('Please select an address')
            return 
        } else {
            setErrorMessage('')
        }

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            setHideAllErrors(false);
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        /** Config for API call. */
        const config = {'headers': {'content-type': 'multipart/form-data'}}

        /** Form data */
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)
        formData.set('officeName', officeName)
        formData.set('street1', street1)
        formData.set('street2', street2)
        formData.set('city', city)
        formData.set('state', stateAbbr)
        formData.set('zip', zip)
        formData.set('npi', npi)

        /**
         * If data should be UPDATED in DB as opposed to created.
         */
        if (hasDbData) {
            axios.post(`${API_DB_URL}/update-provider-data/${STEP}`, 
                Object.fromEntries(formData), 
                config,
            ).then(async () => {
                const qpOut = {
                    reason: 'updated',
                }
                if (isEdit) {
                    window.location.href = buildPath(`/account`, qpOut)
                } else {
                    window.location.href = buildPath(`/doctor-registration/${STEP+1}`, qpOut)
                }
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802bgd2'))
                }, 350)
            })
        }

        /** Submit to db api */
        if (!hasDbData) {
            axios.post(`${API_DB_URL}/new-user-init-provider/${STEP}`, 
                Object.fromEntries(formData), 
                config
            ).then(async (res) => {
                // Success
                setHideAllErrors(true)
                setSpinnerText('Confirmed!')
                setTimeout(() => {
                    setShowSpinner(false)
                    window.location.href = `/doctor-registration/${STEP+1}`
                }, 800)
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8803-bgd2'))
                }, 350)
            })
        }
    }


    // Selecting a search result
    const onClick_searchResult = (index: number, option: SearchOption) => {
        setSelectedAddressIndex(index)
        const _selectedAddress = {...addressList[index]}
        setSelectedAddress(_selectedAddress)
        if (option === 'NPI') {
            setGoogleMapsAddress(_selectedAddress.address)
        }
    }

    // Deselecting a previous selection and removing related data.
    const clearAddressSelection = () => {
        setSelectedAddress(null)
        setGoogleMapsAddress('')
        setSelectedAddressIndex(null)
        setOfficeName('')
        setStreet1('')
        setStreet2('')
        setCity('')
        setStateAbbr('')
        setZip('')
    }

    // Fetch data about a specific NPI #.
    // - This runs every time the info inside this input field changes.
    const getNpiData = async (_npi: string) => {
        // First clear these fields (regardless of whether _npi arg is valid).
        clearAddressSelection()

        // Remove all whitespace
        const npi = _npi.replace(/\s+/, '')

        // Make sure that it is a 10-digit string.
        if (!npi.match(/^\d{10}$/)) {
            setAddressList([])
            return
        }

        axios.get(
            `${API_DB_URL}/npi/search/${npi}`
        ).then(async (res) => {
            // Success
            const npiResponseData = res.data
            setErrorMessage('')
            processNpiData(npiResponseData)
            
        }).catch(async (err) => {
            // Failure
            setShowSpinner(false)
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8809-bgd2'))
            }, 350)
        })
    }

    // Helper to split a 9-digit zip code into its parts.
    const splitZip = (zip: string) => {
        if (!zip || zip.length <= 5) {
            return zip || ''
        }
        return zip.slice(0,5) + '-' + zip.slice(5)
    }

    // Helper to remove duplicate addresses from addressList.
    // - This just looks at the formatted address for display, which contains all 
    //   of this info that we are currently saving about the address as of now.
    const removeDuplicateAddresses = (addrList: any[]) => {
        // Remove any bad dicts from the list, just in case.
        const aList = addrList.filter(item => !!item.address)
        const remainingAddresses = new Set(aList.map(item => String(item.name) + String(item.address)))

        const cleanedAddressList = []

        for (let item of addrList) {
            const key = String(item.name) + String(item.address)
            if (remainingAddresses.has(key)) {
                remainingAddresses.delete(key)
                cleanedAddressList.push(item)
            }
        }
        return cleanedAddressList

    }

    // Take response from NPI API and display the list of addresses to select from.
    //
    // DON'T DELETE:
    //
    // General format of data:
    // {
    //     result_count: 1,
    //     results: [
    //         {
    //             created_epoch: '1161194420000',
    //             enumeration_type: 'NPI-1',
    //             last_updated_epoch: '1648229750000',
    //             number: '1306927074',
    //             addresses: [Array],
    //             practiceLocations: [],
    //             basic: [Object],
    //             taxonomies: [Array],
    //             identifiers: [],
    //             endpoints: [Array],
    //             other_names: []
    //         }
    //     ]
    // }
    const processNpiData = (data: any) => {
        const possibleAddresses_pre: any[] = []
        const possibleAddresses: AddressItem[] = []

        // Possible address types: [LOCATION, MAILING, PRIMARY, SECONDARY]
        // (see: https://npiregistry.cms.hhs.gov/demo-api)
        for (let x=0; x < data.result_count; x++) {
            const result = data.results[x]
            for (let address of result.addresses) {
                if (address.address_purpose.toUpperCase() !== 'MAILING') {
                    possibleAddresses_pre.push({...address, basic: result.basic})
                }
            }
            for (let practiceLocation of result.practiceLocations) {
                if (practiceLocation.address_purpose.toUpperCase() !== 'MAILING') {
                    possibleAddresses_pre.push({...practiceLocation, basic: result.basic})
                }
            }
        }

        // Format the address data for display in the component.
        for (let result of possibleAddresses_pre) {
            const obj = {...EMPTY_ADDRESS_OBJ}
            const _basic = result.basic
            const _names = [
                _basic?.organization_name || '',
                `${_basic?.name_prefix || ''} ${_basic?.first_name || ''} ${_basic?.middle_name || ''} ${_basic?.last_name || ''}`,
            ]
            obj.name = (_names[0] || _names[1]).replace(/\s+/, ' ')
            obj.street1 = result.address_1 || ''
            obj.street2 = result.address_2 || ''
            obj.city = result.city || ''
            obj.state = result.state || ''
            obj.zip = splitZip(result.postal_code || '')
            obj.country = result.country_code || ''
            // Make it into a one-liner.
            const _street = obj.street1 + (obj.street2 ? `, ${obj.street2}` : '')
            obj.address = `${_street}, ${obj.city}, ${obj.state}, ${obj.zip}`
            possibleAddresses.push(obj)
        }

        // Add to state variable
        setAddressList(removeDuplicateAddresses(possibleAddresses))
    }

    // Clear the address list and selected address whenever you switch options ('NPI' vs 'ADDRESS').
    useEffect(() => {
        setAddressList([])
        clearAddressSelection()
        setNpi('')
    }, [option])

    // Whenver select address is changed, save the parsed data to state variables so that they
    // are ready if/when the form gets submitted.
    useEffect(() => {
        if (addressList && !isNaN(Number(selectedAddressIndex))) {
            setErrorMessage('')
            const thisAddress = addressList[Number(selectedAddressIndex)]
            if (!thisAddress) return
            setOfficeName(thisAddress.name)
            setStreet1(thisAddress.street1)
            setStreet2(thisAddress.street2)
            setCity(thisAddress.city)
            setStateAbbr(thisAddress.state)
            setZip(thisAddress.zip)
        } else {
            clearAddressSelection()
        }
    }, [selectedAddressIndex])


    const getGoogleMapAddressResults = useCallback(() => {
        if (!GOOGLE_MAPS_API_KEY || !googleMapsAddress || !mapRef.current) return

        setAddressList([])

        const GoogleMapsLoader = new Loader({
          apiKey: GOOGLE_MAPS_API_KEY,
          version: 'weekly',
          libraries: ['places'], // needed for `google.maps.places`
        })
        
        GoogleMapsLoader.load().then( () => {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ address: googleMapsAddress },  (results, status) => {
                if (!results || status !== 'OK') return

                // Grab the first result and drop a pin on the map for it.
                let newMap = new google.maps.Map(mapRef.current!, {
                    center: results[0].geometry.location,
                    zoom: 12,
                })

                let obj = {...EMPTY_ADDRESS_OBJ}
                let helper_obj = {...EMPTY_ADDRESS_HELPER_OBJ}
                
                // Get the first match and format the data list with it.
                // - Add to list and drop a pin if the object doesn't have missing name or street address.
                makeAddressObj(results[0], obj, helper_obj)
                if (checkObjHasEnoughDetails(obj)) {
                    setAddressList([obj])
                    new google.maps.Marker({ map: newMap, position: results[0].geometry.location })
                }
    
                // If using the "register-by-NPI" method, then nothing else to do.
                if (option === 'NPI') return
                
                // Build the request for a radius/near-by search.
                const request = {
                    location: results[0].geometry.location,
                    radius: 50, // meters

                    


                    // TODO: confirm this works as excpected! 
                    // syntax guide seen says this should be an array. But here an aray throws error.
                    type: 'establishment,hospital',




                };
                const placesService = new google.maps.places.PlacesService(newMap);

                // Search businesses that are near the searched address.
                placesService.nearbySearch(request, (places, status) => {
                    if (!places || !places.length || status !== google.maps.places.PlacesServiceStatus.OK) return

                    // Transform data for each "place" into an object that we can use.
                    // - Drop a pin for each "place" onto the map.
                    for (let place of places) {
                        if (!place?.place_id) return
                        
                        placesService.getDetails({ placeId: place.place_id }, (placeDetails, status) => {
                            if (!placeDetails || status !== google.maps.places.PlacesServiceStatus.OK) return
                            
                            obj = {...EMPTY_ADDRESS_OBJ}
                            helper_obj = {...EMPTY_ADDRESS_HELPER_OBJ}
                            // Convert data into usable format.
                            makeAddressObj(placeDetails, obj, helper_obj)
                            // Need to set this in each loop iteration bc of funky async issues.
                            // TOOD: find a better way.
                            if (checkObjHasEnoughDetails(obj)) {
                                setAddressList(prev => [...prev, obj])
                                // Drop a pin on map for each nearby search result.
                            new google.maps.Marker({ map: newMap, position: place?.geometry?.location, title: place.name })
                            }
                        })
                    }
                })
            })
        })
    }, [googleMapsAddress, option])


    // Use debounce for Google Maps address search whenever text is changed.
    useEffect(() => {
        clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = setTimeout(() => {
            getGoogleMapAddressResults()
        }, SEARCH_TIMEOUT)
    }, [googleMapsAddress]);


    // Make place object returned by Google Maps API into a usable object.
    // - Modifies object in place.
    const makeAddressObj = (placeDetails: any, obj: AddressItem, helper_obj: AddressItemHelper) => {
        for (let prop of (placeDetails.address_components || [])) {
            if (prop.types.includes('street_number')) helper_obj.street1_num = prop.short_name
            if (prop.types.includes('route')) helper_obj.street1_street = prop.short_name
            if (prop.types.includes('subpremise')) helper_obj.street1_unit = prop.short_name
            if (prop.types.includes('locality')) obj.city = prop.short_name
            if (prop.types.includes('administrative_area_level_1')) obj.state = prop.short_name
            if (prop.types.includes('postal_code')) helper_obj.zip_start = prop.short_name
            if (prop.types.includes('postal_code_suffix')) helper_obj.zip_end = prop.short_name
            if (prop.types.includes('country')) obj.country = prop.short_name
        }
        // // Aggregate above data into fields.
        obj.street1 = `${helper_obj.street1_num} ${helper_obj.street1_street}`.replace(/\s+/, ' ').trim()
        obj.street2 = `${helper_obj.street1_unit}`.trim()
        obj.address = `${helper_obj.street1_num} ${helper_obj.street1_street} ${helper_obj.street1_unit}`.replace(/\s+/, ' ').trim()
        obj.zip = `${helper_obj.zip_start}${helper_obj.zip_end ? '-' + helper_obj.zip_end : ''}`.trim()
        // More data.
        obj.name = placeDetails.name || ''
    }

    
    // Check that object has enough details to list in results.
    const checkObjHasEnoughDetails = (obj: AddressItem) => {
        if (!obj.name || !obj.street1) return false
        return true
    }


    return (
        <>
            <HeaderBanner
                imageName="doctorpen"
                imageText={isEdit ? 'EDIT MEDICAL PRACTICE' : 'MEDICAL PRACTICE'}
                imageSpacerColor='BLUE'
            />
            <RegistrationLoadingModal
                show={showSpinner}
                setShow={setShowSpinner}
                titleText={spinnerText}
            />

            <PreviewModeMessage show={!!isPreviewMode} link={'/doctor-registration/1'} />

            <div className="doctor-register-page-layout">
                {!isEdit ? (
                    <p className="body-container top-paragraph">
                        test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 
                        test test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test test text 1 
                        test text 1 test text 1 test text 1 test text 1 test text 1 test test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test 
                        text 1 test text 1 test text 1 test text 1 test text 1 test text 1 
                    </p>
                ) : null}

                {/* Show different messages based on how the user got here */}
                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                {!isEdit ? (
                    <div className="progress-bar-placement">
                        <ProgressBar whichStep={2} numberOfSteps={2} />
                    </div>
                ) : null}

                <form onSubmit={onSubmitRegistrationForm}>
                    {!option ? ( 
                        <>
                        <div className={`body-container form-layout extend`}>
                            <div className="row-layout">
                                <MedPracButton onClick={() => setOption('ADDRESS')}>
                                    CLINIC ADDRESS
                                </MedPracButton>
                                <span>OR</span>
                                <MedPracButton onClick={() => setOption('NPI')}>
                                    CLINIC NPI
                                </MedPracButton>
                            </div>
                        </div>
                        <div className='body-container-no-vert-paid'>
                            <div 
                                className="buttons-container"
                                style={{ justifyContent: 'center' }}
                            >
                                <Link to={`/doctor-registration/${STEP - 1}${isPreviewMode ? '?mode=preview' : ''}`}>
                                    <BlockButton style={{ width: '250px' }} buttonStyle="ghost-purple">
                                        BACK
                                    </BlockButton>
                                </Link>
                            </div>
                        </div>
                        </>
                    ) : null}

                    {option === 'NPI' ? ( 
                        <>
                        <div className="body-container form-layout">
                            <div className="header">
                                PRIMARY CLINIC NPI
                            </div>
                            <div className="form-wrapper-mid shortened p1">
                                <InputField
                                    id="doctor-registration-npi"
                                    name='doctornpi'
                                    placeholder='Clinic NPI Number'
                                    value={npi}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const npi = (event.target.value || '').replace(/\s+/, '')
                                        setNpi(npi)
                                        getNpiData(npi)
                                    }}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                            <div className="address-search-results">
                                {addressList.length ? (
                                    <div className='selected-address-title'>
                                        Search results
                                    </div>
                                ) : null}
                                {addressList.map((addressRow, i) => (
                                    <div 
                                        className={`address-row ${i === selectedAddressIndex ? 'selected' : ''}`}
                                        onClick={() => onClick_searchResult(i, option)}
                                        key={`${addressRow.name}___+${i}`}
                                    >
                                        <div className="location-name">
                                            {addressRow.name}
                                        </div>
                                        <div className="location-address">
                                            <div className='address-text'>
                                                {addressRow.address}
                                                <br/>
                                                {addressRow.city}, {addressRow.state} {addressRow.zip}
                                            </div>
                                            <div className='radio-container'>
                                                <div className='fake-radio'>
                                                    {i === selectedAddressIndex ? (
                                                        <Icon name='check-bold'/>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="clinic-details">
                                {(selectedAddress && Object.keys(selectedAddress).length) ? (
                                    <>
                                    <div className='selected-address-title'>
                                        Selected address:
                                    </div>
                                    <span className="clinic-name">
                                        {selectedAddress.name}
                                    </span><br/>
                                    <span className="clinic-address">
                                        {selectedAddress.street1}{selectedAddress.street2 ? (
                                            (`,  ${selectedAddress.street2}`)
                                        ) : ''}
                                    </span><br/>
                                    <span className="clinic-city-state-zip">
                                        {selectedAddress.city}, {selectedAddress.state}, {selectedAddress.zip}
                                    </span>    
                                    </>
                                ): null}
                            </div>

                            {/* Google map that will point to selected address. */}
                            <div className="clinic-map-container">
                                <div className='clinic-map-background-text'>
                                    ENTER NPI & SELECT ADDRESS TO SHOW MAP
                                </div>
                                {/* The google map will go in here */}
                                <div ref={mapRef} className='google-map'></div>
                            </div>

                            {/* Error messages */}
                            {(!hideAllErrors && errorMessage) ? (
                                <div className='error-message'>
                                    {errorMessage}
                                </div>
                            ): null }
                        </div>
                        <div className='body-container-no-vert-pad'>
                            <div className="buttons-container">
                                <BlockButton 
                                    style={{ width: '250px' }} 
                                    buttonStyle="ghost-purple"
                                    onClick={() => setOption('')}
                                >
                                    BACK
                                </BlockButton>

                                {!isPreviewMode ? (
                                    <BlockButton 
                                        type='submit'
                                        style={{ width: '250px' }} 
                                        buttonStyle="purple"
                                    >
                                        CONTINUE
                                    </BlockButton>
                                ) : (
                                    <BlockButton 
                                        type='button' // IMPORTANT
                                        style={{ width: '250px' }} 
                                        buttonStyle="purple"
                                        disabled={true}
                                    >
                                        FINALIZE
                                    </BlockButton>
                                )}
                            </div>
                        </div>
                        </>
                    ) : null }

                    {option === 'ADDRESS' ? ( 
                        <>
                        <div className="body-container form-layout">
                            <div className="header">
                                PRIMARY CLINIC LOCATION
                            </div>
                            <div className="search-area">
                                <div className="search-contents">
                                    <InputField
                                        id="doctor-registration-search-by-address"
                                        name='doctorsearchbyaddress'
                                        placeholder='Search by address'
                                        value={googleMapsAddress}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setGoogleMapsAddress(event.target.value)
                                        }}
                                        style={{ height: '50px' }}
                                        disabled={!!isPreviewMode}
                                    />
                                    <BlockButton 
                                        style={{ width: '180px', height: '50px', marginTop: '-4px' }} 
                                        buttonStyle="desktop-header-buttons purple"
                                        onClick={() => {}} // TOOD: consider removing this button; not needed anymore.
                                    >
                                        SEARCH
                                    </BlockButton>
                                </div>
                            </div>

                            <div className="address-search-results">
                                {addressList.map((addressRow, i) => (
                                    <div 
                                        className={`address-row ${i === selectedAddressIndex ? 'selected' : ''}`}
                                        onClick={() => onClick_searchResult(i, option)}
                                        key={`${addressRow.name}___+${i}`}
                                    >
                                        <div className="location-name">
                                            {addressRow.name}
                                        </div>
                                        <div className="location-address">
                                            <div className='address-text'>
                                                {addressRow.address}
                                                <br/>
                                                {addressRow.city}, {addressRow.state} {addressRow.zip}
                                            </div>
                                            <div className='radio-container'>
                                                <div className='fake-radio'>
                                                    {i === selectedAddressIndex ? (
                                                        <Icon name='check-bold'/>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Google map that will point to selected address. */}
                            <div className="clinic-map-container">
                                <div className='clinic-map-background-text'>
                                    SELECT ADDRESS TO SHOW MAP
                                </div>
                                {/* The google map will go in here */}
                                <div ref={mapRef} className='google-map'></div>
                            </div>

                            {/* Error messages */}
                            {(!hideAllErrors && errorMessage) ? (
                                <div className='error-message'>
                                    {errorMessage}
                                </div>
                            ): null }
                        </div>

                        <div className='body-container-no-vert-pad'>
                            <div className="buttons-container">
                                <BlockButton 
                                    style={{ width: '250px' }} 
                                    buttonStyle="ghost-purple"
                                    onClick={() => setOption('')}
                                >
                                    BACK
                                </BlockButton>

                                {(!isPreviewMode && !isEdit) ? (
                                    <BlockButton 
                                        type='submit'
                                        style={{ width: '250px' }} 
                                        buttonStyle="purple"
                                    >
                                        CONTINUE
                                    </BlockButton>
                                ) : (isEdit ? (
                                    <BlockButton
                                        type='submit'
                                        style={{ width: '250px' }} 
                                        buttonStyle="purple"
                                    >
                                        SAVE
                                    </BlockButton>
                                ) : (
                                    <BlockButton 
                                        type='button' // IMPORTANT
                                        style={{ width: '250px' }} 
                                        buttonStyle="purple"
                                        disabled={true}
                                    >
                                        FINALIZE
                                    </BlockButton>
                                ))}
                            </div>
                        </div>
                        </>
                    ) : null }
                </form>

                <ToastContainer 
                    hideProgressBar={true}
                    position='bottom-center'
                    style={{textAlign: 'center', cursor: 'default'}}
                />
            </div>
        </>
    )
}
