/**
 * For Reps users. Shows doctors they have tagged.
 */

import { BlockButton } from '../../shared/buttons/block-button/BlockButton';
import { Icon } from '../../shared/icon/Icon';

import { MyAccountInfo, TaggedReps } from '../../../../config'



interface Props {
    getTagsListError: string;
    tagsData_RtoC: TaggedReps[];
    untagRep: Function;
    userId: number;
    userType: 'consumer';
    accountInfo: MyAccountInfo;
    setShowTagInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTagInviteType: React.Dispatch<React.SetStateAction<'clinic'|'rep'|''>>;
}

export const TaggedDocs_Rep = ({
    getTagsListError,
    tagsData_RtoC = [],
    untagRep,
    userId, userType, accountInfo,
    setShowTagInviteModal, setTagInviteType
}: Props) => {

    // Forgo this logic for nwo - they should see the invite button even if they aren't part of an org.
    // if (
    //     !tagsData_RtoR_sent.find(t => t.active && t.on_reg && t.from_uid === userId) // The on-reg request to join the rep-org.
    //     && !tagsData_RtoR_received.find(t => t.active && t.to_uid === userId) // Any "parent" in the rep-org that has tagged this user.
    // ) {
    //     return null
    // }

    return (
        <div className='section'>
            <div className='title-row'>
                <div className='section-title'>
                    My Medical Providers
                </div>
            </div>
            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            {(tagsData_RtoC.length) ? (
                <table className='info-table'>
                    <thead>
                        <tr>
                            <td>Medical Practice</td>
                            <td>Tagged</td>
                            <td>Status</td>
                            <td>Invited</td>
                            <td>Accepted</td>
                        </tr>
                    </thead>
                    <tbody>
                        {tagsData_RtoC.map((row, index) => (
                            <tr 
                                key={index + '--' + row.to_uid}
                                className='tag-row'
                                style={{ cursor: 'default' }}
                                title={
                                    `Invitation sent to:\r\n`
                                    + `    Email: ${row.to_email}\r\n`
                                    + `    ${row.to_name ? `Doctor: ${row.to_name}\r\n` : ''}`
                                    + `    ${row.to_clinic_name ? `Practice: ${row.to_clinic_name}` : ''}`
                                }
                            >
                                <td className='title medium product-cell'>
                                    {/* <span className='info-icon'>&#9432;</span> */}
                                    {row.to_clinic_name}
                                </td>
                                <td className='medium product-cell'>
                                    {row.to_name}
                                </td>
                                <td className='smaller product-cell'>
                                    {row.active ? 'Active' : 
                                        row.active === false ? 'Inactive' : 
                                        (row.active === null && !row.invited_at) ? 'Email failed to send' : 
                                        (row.active === null && !row.accepted_at) ? 'Invitation sent' : 
                                        'Invite sent' // this only happens if tag was de-activated then user was invited again later.
                                    }
                                </td>
                                <td className='small product-cell'>
                                    {row.invited_at}
                                </td>
                                <td className='small product-cell'>
                                    {(row.active) ? (
                                        row.accepted_at
                                    ) : (row.active === false) ? (
                                        'Deactivated' // This should never show bc it's filtered out on backend.
                                    ) : (row.active === null && !row.invited_at) ? (
                                        <span style={{color: '#f34'}}>
                                            Email failed to send
                                        </span>
                                    ) : (
                                        <span style={{color: '#f34'}}>
                                            Pending
                                        </span>
                                    )}
                                </td>
                                <td className='smallest untag-cell'>
                                    {/* untag */}
                                    <button 
                                        type='button'
                                        className='untag-button'
                                        onClick={() => {
                                            untagRep(
                                                'deactivate', null, false,
                                                userId, userType, accountInfo, false,
                                                row.from_roid, row.to_roid,
                                                row.from_reporg_name, row.to_reporg_name,
                                                row.from_bid, row.from_biz_name, 
                                                row.from_uid, row.to_uid, row.from_name, row.to_name,
                                                row.to_cid, row.to_clinic_name,
                                                row.active)
                                        }}
                                    >
                                            <Icon name='circle-x' />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}

            <BlockButton
                type='button'
                style={{ marginTop: '40px', width: 'fit-content' }} 
                buttonStyle="ghost-purple"
                className='less-pad mid-size' // 'edit-info main-action'
                onClick={() => {
                    setShowTagInviteModal(true)
                    setTagInviteType('clinic')
                }}
            >
                Invite a doctor
            </BlockButton>
        </div>
    )
}