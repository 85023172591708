import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getUser } from '../../../store/actions/user-actions';
import { TagDoctor } from './tag-doctor/TagDoctor';
import { UserGuide } from './user-guide/UserGuide';
import { TagHistory } from './tag-history/TagHistory';
import { DoctorLeaderboard } from './doctor-leaderboard/DoctorLeaderboard';
import { Doctors } from './doctors/Doctors';
import { RepDashboard } from './dashboard/RepDashboard';
import { SalesReport } from './sales-report/SalesReport';
import './rep.scss';
import PrintOnlyContent from '../../shared/print/PrintOnlyContent';


interface Props { };



export const Rep = (props: Props) => {

  return (
    <>
      <PrintOnlyContent title={true}>Medngine rep pages</PrintOnlyContent>
      <Routes>
        <Route path='tag-doctor' element={<TagDoctor />} />
        <Route path='user-guide' element={<UserGuide />} />
        <Route path='tag-history' element={<TagHistory />} />
        <Route path='doctor-leaderboard' element={<DoctorLeaderboard />} />
        <Route path='doctors' element={<Doctors />} />
        <Route path='dashboard' element={<RepDashboard />} />
        <Route path='sales-report' element={<SalesReport />} />
      </Routes>
    </>
  );
}