import React from 'react';
import { Link } from "react-router-dom";
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { TextAreaField } from '../../../shared/form/text-area/TextAreaField';
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import './contact-page.scss';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';



export const ContactPage = () => {

    return (
        <>
            <PrintOnlyContent title={true}>Medngine contact page</PrintOnlyContent>
            <HeaderBanner
                bannerType={1}
                imageName="microscope"
                imageText="CONTACT"
                imageSpacerColor='BLUE'
                scrollToTransparentRange={[100, 130]}
            />
            <div className="body-container contactpage-layout">
                <p>
                    At Regenative Labs, we have different teams dedicated to service your needs
                    and to answer questions you may have.<br/><br/>
                    Submit your contact information through our website and a representative will
                    reach out shortly.
                    <br/><br/>
                </p>
                <p>
                    If you want to visit the Medngine Portal,&nbsp;
                    <a href='https://www.medngine.com/' style={{textDecoration: 'underline', fontWeight: 'bold'}}>
                        click here
                    </a>.
                    <br/><br/><br/><br/>
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>
                        Phone:
                    </span>
                    <br/>
                    <a href='tel:1-800-891-3452'>
                        1-844-512-1257 {/* 1-800-891-3452 */}
                    </a>
                    <br/><br/>
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>
                        Email:
                    </span>
                    <br/>
                    <a href='mailto:info@regenativelabs.com'>
                        info@regenativelabs.com
                    </a>
                </p>
                <div className="formwrapper-main">
                    <span style={{fontWeight: 'bold'}}>
                        Or send us a message below
                    </span>
                    <div className="category-1-wrapper">
                        <InputField
                            required
                            id="public-contact-email"
                            type="email"
                            name='emailaddress'
                            placeholder='Email Address' 
                            style={{fontWeight: 'normal', fontSize: '1.8rem'}}
                        />
                        <InputField
                            id="public-contact-phone"
                            name='phone'
                            type="number"
                            placeholder='Phone Number'
                            style={{fontWeight: 'normal', fontSize: '1.8rem'}}
                        />
                    </div>
                    <div className="category-2-wrapper">
                        <TextAreaField
                            required
                            id="public-contact-message"
                            name="message"
                            placeholder='Message'
                            style={{ minHeight: '320px', height: 'calc(100% - 2rem)', resize: 'none' }}
                        />
                        <BlockButton
                            style={{ width: '120px', marginTop: '35px' }}
                            buttonStyle='desktop-header-buttons purple'
                        >
                            Submit
                        </BlockButton>
                    </div>
                </div>
            </div>
        </>
    )
}