import React from 'react';
import { Link } from "react-router-dom";
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import './verify-email.scss';



export const PatientVerifyEmail = () => {

  return (
    <>
      <HeaderBanner
        imageName="doctorpen"
        imageText="CONFIRM EMAIL ADDRESS"
        imageSpacerColor='BLUE'
      />
      <div className="body-container verify-email-page-layout">
        <div className="header3">ONE LAST STEP</div>
        <p>test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 test text 1 </p>
      </div>
    </>
  );
}
