import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account'
import { formatAWsCognitoErrorMessage, convertAwsEmailToUsername } from '../../../../helpers/messageParserAws'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton'
import { InputField } from '../../../shared/form/input-field/InputField'
import { SelectField } from '../../../shared/form/select-field/SelectField'
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner"
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage'
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar"
import { VerificationCodeModal } from '../../../shared/modal/VerificationCodeModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject'

import { ACCOUNT_TYPES_DROPDOWN_OPTIONS, ACCOUNT_TYPES_FLAT, ACCOUNT_TYPES_TO_REG_URL } from '../../../../constants/accountType'

import LoadingCircle from '../../../../static/gif/loading-circle.gif'

import './supplier-registration.scss'
import 'react-toastify/dist/ReactToastify.css'



/**
 * First step of supplier registration.
 * Only this step is visible to non-logged-in users.
 * Upon successful completion of form and Cognito email code verification, redirect to login page,
 *      which will subsequently redirect to step 2 of registration after login.
 */
export const SupplierRegistration1 = () => {
    const STEP = 1
    const THIS_ENTITY_TYPE = 'manufacturer' // TODO: this needs a check/test so it doesn't get changed.

    // Auth/session functions
    const { register, resendVerification, getUsernameAndIdToken } = useContext(AccountContext)

    // Message based on query parameters
    const [referrerMessage, setReferrerMessage] = useState('')

    // Pre-load spinner
    const [showPreloadSpinner, setShowPreloadSpinner] = useState(true)

    // Session data
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean|null>(null)
    const [hasDbData, setHasDbData] = useState<boolean|null>(null)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // For input fields
    const [userType, setUserType] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [awsUsername, setAwsUsername] = useState('')
    const [showResendVerificationCode, setShowResendVerificationCode] = useState(false)
    const [hideAllErrors, setHideAllErrors] = useState(false)
    // For error messages sent back from AWS Cognito service after failed attempt to register.
    const [cognitoErrorMessage, setCognitoErrorMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // Modal to show loading status and for entering verification code.
    const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()
    // Users who just need to enter their validation code.
    const [justVerifyEmail, setJustVerifyEmail] = useState<boolean|null>(null)

    // On mount
    useEffect(() => {
        // Get query parameters from url.
        const qp = qsToDict(window.location.search)

        // Show message if user referred here after login. Normally this won't happen on step 1, 
        // unless the user was manually created through Cognito GUI.
        if (qp.reason === 'reg-incomplete') {
            setReferrerMessage('Please complete your registration')
        }

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Try to prefill user info. This will return empty object if user isn't logged in.
        getPrefilledData()

        // For redirects for users who just need to enter their email verification code.
        if (qp.mode === 'verify-email-redirect' && qp.email && qp.userTypeNum) {
            setJustVerifyEmail(true)
            const _userType = qp.userTypeNum // string
            const _userEmail = decodeURIComponent(qp.email)
            setUserType(_userType)
            setEmail(_userEmail)
            const _awsUsername = convertAwsEmailToUsername(_userEmail)
            setAwsUsername(_awsUsername)
            setShowVerificationCodeModal(true)
        } else {
            setJustVerifyEmail(false)
        }
    }, [])

    // If in preview mode.
    useEffect(() => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
        }
    }, [isPreviewMode])

    /**
     * Make sure these fields are valid before sending data to AWS Cognito.
     * 
     * These 3 fields will be sent to AWS Cognito for registering a new user:
     *  -  Email, password, and user type (account/entity type)
     *  -  Assume that entity type is auto-filled and valid.
     * Fail silently - backend will handle data errors, too.
     * 
     * TODO: it would be better to check POST values instead of just the input text contents.
     */
    const frontendValidationErrors = () => {
        let errorCount = 0

        try {
            // Always check for email.
            if (!email || email.indexOf('@') === -1 || email.indexOf('.') === -1) {
                setEmailError('Please enter a valid email address')
                errorCount += 1
            } else {
                setEmailError('')
            }

            // Only check for password if this is a new user (i.e. not updating).
            if (!hasDbData && (!password || !passwordConfirm || password !== passwordConfirm)) {
                setPasswordError('Passwords must match')
                errorCount += 1
            } else {
                setPasswordError('')
            }

            return errorCount
        } catch (e) {
            return 0
        }
    }

    /** 
     * Redirect to appropriate registration page if user type is changed.
     */
    useEffect(() => {
        if (justVerifyEmail !== false) return
        if (!userType || isNaN(Number(userType)) || Number(userType) < 0) return
        let userTypeName = ACCOUNT_TYPES_FLAT[Number(userType)]
        userTypeName = userTypeName[0].toUpperCase() + userTypeName.slice(1,).toLowerCase() // capital-case
        const currentPath = window.location.pathname
        let targetPath = `${ACCOUNT_TYPES_TO_REG_URL[userTypeName]}/1`
        if (isPreviewMode) {
            targetPath += '?mode=preview'
        }
        if (currentPath !== targetPath) {
            window.location.href = targetPath
        }
    }, [userType, isPreviewMode])


    /**
     * Pre-fill information if user completed this step already.
     */
    const getPrefilledData = async () => {
        if (isPreviewMode) {
            setShowPreloadSpinner(false)
            return
        }

        // Get session info if possible, else fail silently
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) { 
            // This probably means user isn't logged in.
            setIsLoggedIn(false)
            setHasDbData(false)
            setShowPreloadSpinner(false)
            return 
        }

        // Prepare post request.
        const sessionJson = JSON.stringify(session)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL
        const formData = new FormData() 
        formData.append('session', sessionJson)
        
        // Get data from backend.
        axios.post(`${API_DB_URL}/get-supplier-data/step/${STEP}`, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setCompanyName(data.company)
            setCompanyTitle(data.title)
            setEmail(data.email)
            // Getting this data means user is logged in, unless the data obj above is empty
            setIsLoggedIn(!!data.email)
            setHasDbData(!!data.email)
        }).catch(async (err) => {
            setIsLoggedIn(false)
            setHasDbData(false)
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#8802aGs'))
            }, 350)
        }).finally(() => {
            setShowPreloadSpinner(false)
        })
    }

    /** 
     * Submit regisration Step 1 data to backend & Cognito to create a new user.
     */
    const onSubmitRegistrationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isPreviewMode !== false) return

        setShowSpinner(true)

        // Check the 3 inputs that will be sent to AWS Cognito before registering.
        // (React will confirm that the other required fields at least have some value entered)
        if (frontendValidationErrors()) { 
            setShowSpinner(false)
            return 
        }

        /** Return Cognito user info after validating */
        // User is expected to NOT be logged in here so expect `session.error` to have a message.
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        // Only show error if error code doesn't include #88847 - that one is the not-logged-in error.
        if (session.error && !session.error.includes('#88847')) { 
            setHideAllErrors(false);
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)

        /** Config for API call. */
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const API_DB_URL = process.env.REACT_APP_API_DB_URL

        /** Form data */
        const formData = new FormData(e.target as HTMLFormElement)  
        formData.append('session', sessionJson)
        // Convert email to username. See notes on why this is needed in this function's definition.
        const _awsUsername = convertAwsEmailToUsername(email)
        setAwsUsername(_awsUsername)
        formData.append('username', _awsUsername)
        // We don't need these fields to be sent to backend, so delete.
        formData.delete('password')
        formData.delete('passwordConfirm')
        formData.delete('entityTypeIndex')


        /**
         * If data should be UPDATED in DB as opposed to created.
         */
        if (hasDbData) {
            axios.post(`${API_DB_URL}/update-supplier-data/${STEP}`, 
                Object.fromEntries(formData), 
                config,
            ).then(async () => {
                const qpOut = {
                    reason: 'updated',
                }
                if (isEdit) {
                    window.location.href = buildPath(`/account`, qpOut)
                } else {
                    window.location.href = buildPath(`/supplier-registration/${STEP+1}`, qpOut)
                }
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802aGUs'))
                }, 350)
            })
        }

        /**
         * Create new user:
         *  - Write record in db, then post to Cognito, then update db w/cognito info.
         *      - If registration successful, redirect to url for step 2.
         *      - If the user already exists in db, then tell them to login.
         */
        if (!hasDbData) {
            axios.post(`${API_DB_URL}/new-user-init-supplier/${STEP}/a`, 
                // Step 1: Create the user object in DB, and throw error if user already exists.
                Object.fromEntries(formData), 
                config
            ).then(async (res) => {
                // Step 2: Create the user in Cognito.
                const [user, userTypeId, userTypeName, is_new_user, codeForECs] = res.data
                let cognitoSuccess = true
                const cognitoData = await register(
                    _awsUsername, email, password, user.id, userTypeId, userTypeName
                ).catch((err: Error) => {
                    // See possible exception types here:
                    //  - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
                    if (err.name === 'UsernameExistsException') {
                        if (user.is_reregister) {
                            resendVerification(_awsUsername)
                            return null
                        } else if (is_new_user) {
                            // This block is for edge case #16778 
                            cognitoSuccess = false
                            return null
                        } else {
                            // NOT an edge case (though it a "potential" EC #16789 on the backendi)
                            throw new Error (`You already have an account, please login.`)
                        }
                    } else {
                        // For normal errors, show the error message and stop.
                        setHideAllErrors(false)
                        setCognitoErrorMessage(parseErrorObject(err, '#4041'))
                        throw new Error('') // to stop exectuion while diplsay above error in correct div.
                    }
                })
                setHideAllErrors(true)
                return [cognitoData, user.id, cognitoSuccess, is_new_user, user.is_reregister, codeForECs]
            }).then(async ([cognitoData, userId, cognitoSuccess, is_new_user, is_reregister, codeForECs]) => {
                // Save cognito data to db to complete registration.
                // Note that backend will handle when `cognitoData` is null for edge case #16778.
                // - UPDATE (Apr '23): took out this concditional - I think it's bc I added 
                //   special handling for re-registrations, but I might have just been testing smth.
                //   Leaving it off for now bc the app seems to work fine.
                // if (!is_reregister) {
                    await axios.post(`${API_DB_URL}/new-user-init-supplier/${STEP}/b`, 
                        { session: sessionJson, userId, cognitoData, is_new_user, codeForECs }, 
                        config
                    ).catch(err => {
                        throw err
                    })
                // }
                return [cognitoSuccess, is_reregister]
            }).then(async ([cognitoSuccess, is_reregister]) => {
                if (is_reregister) {
                    // If this user registered before but never verified email, then basically 
                    // treat them as new user.
                    // - (don't do anything here)
                } else if (!cognitoSuccess) {
                    // If Edge Case #16778 was hit, just go straight to login page without verification code.
                    const qpOut = {
                        referrer: `/supplier-registration/${STEP}`,
                        redirectTo: `/supplier-registration/${STEP + 1}`, 
                        reason: '#16778',
                    }
                    window.location.href = buildPath('/auth/login/', qpOut)
                }
                // All steps successful
                // (Redirect to login page is handled by `ShowVerificationCodeModal`)
                setHideAllErrors(true)
                setShowResendVerificationCode(true)
                setShowVerificationCodeModal(true)
                setShowSpinner(false)
            }).catch(async (err) => {
                // Failure
                setShowSpinner(false)
                setErrorMessage('')
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#8802Gs'))
                }, 350)
            })
        }
    }




    return (
        <>
            {!justVerifyEmail ? (
                <HeaderBanner
                    imageName="doctorpen"
                    imageText={isEdit ? 'EDIT ACCOUNT' : 'CREATE ACCOUNT'}
                    imageSpacerColor='BLUE'
                />
            ) : null}
            {/* Shows form to input emailed verification code, and redirects to login page on success */}
            <VerificationCodeModal
                awsUsername={awsUsername}
                referrer={`/supplier-registration/${STEP}`}
                redirectTo={`/supplier-registration/${STEP+1}`}
                show={showVerificationCodeModal}
                setShow={setShowVerificationCodeModal}
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
                spinnerText={spinnerText}
                setSpinnerText={setSpinnerText}
                justVerifyEmail={!!justVerifyEmail}
            />

            <PreviewModeMessage show={!!isPreviewMode} link={'/supplier-registration/1'} />

            <div 
                className="supplier-register-page-layout"
                // For users who just need to verify email code, use invisible so it doesn't collapse.
                style={{ visibility: justVerifyEmail ? 'hidden' : 'visible' }}
            >
                {!isEdit ? (
                    <p className="body-container top-paragraph">
                        This is the Supplier registration form. 
                        <br/><br/>
                        Fill out this form if your business is in manufacturing or production
                        of medical products that you intend to sell here.
                        If you are someone who can legally prescribe medications and give medical advice,
                        then select the "Doctor" option in the Account Type 
                        dropdown below. If you
                        are signing up as a business that intends to resell or merchandise products 
                        on this website, choose the "Business" option. 
                        Finally, if you intend to buy products for yourself without
                        any intent to resell or redistribute, then select the "General" option.
                        <br/><br/>
                        {!isPreviewMode ? (
                            <>
                                To see a preview of what information you will need when registering,&nbsp;
                                <Link to={'?mode=preview'} className='preview-link'>
                                    click here
                                </Link>.
                            </>
                        ): null}
                    </p>
                ) : null}

                {referrerMessage ? (
                    <div className='registration-message'>
                        {referrerMessage}
                    </div>
                ) : null}

                {!isEdit ? (
                    <div className="progress-bar-placement">
                        <ProgressBar whichStep={1} numberOfSteps={5} />
                    </div>
                ) : null}

                {/* Only show the form after checking backend for pre-fill data. */}
                <div 
                    className="body-container form-layout"
                    style={{ display: (showPreloadSpinner ? 'none' : 'block') }}
                >
                    <form onSubmit={onSubmitRegistrationForm} aria-autocomplete='none'>
                        <div className="header">ACCOUNT TYPE</div>
                        <div className="supplier-select-position">
                            <SelectField
                                required
                                id="supplier-registration-accounttype"
                                name='entityTypeIndex'
                                options={ACCOUNT_TYPES_DROPDOWN_OPTIONS}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    const usrType = Number(event.target.value) - 1
                                    if (!isNaN(usrType)) {
                                        setUserType(String(usrType))
                                    }
                                }}
                                altDisplay={true}
                                selectedIndex={ACCOUNT_TYPES_FLAT.findIndex(type => {
                                    return type.toLowerCase() === THIS_ENTITY_TYPE.toLowerCase()
                                })}
                                firstOptionIsNull={true}
                                disabled={justVerifyEmail ? true : isLoggedIn === false ? false : true} // `isLoggedIn` can be null
                            />
                        </div>
                        <div className="header2">YOUR INFORMATION</div>
                        <div className='form-wrapper-uppermid'>
                            <InputField
                                required
                                id="supplier-registration-firstname"
                                name='firstName'
                                placeholder='First Name'
                                altDisplay={true}
                                value={firstName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setFirstName(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                required
                                id="supplier-registration-lastname"
                                name='lastName'
                                placeholder='Last Name'
                                altDisplay={true}
                                value={lastName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLastName(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                        </div>
                        <div className="form-wrapper-mid p1">
                            <InputField
                                required
                                id="supplier-registration-company"
                                name='companyName'
                                placeholder='Company'
                                altDisplay={true}
                                value={companyName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCompanyName(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                id="supplier-registration-title"
                                name='title'
                                placeholder='Title'
                                altDisplay={true}
                                value={companyTitle}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCompanyTitle(event.target.value)
                                }}
                                disabled={!!isPreviewMode}
                            />
                            <InputField
                                required
                                id="supplier-registration-email"
                                name='email' // Don't change this
                                type='email'
                                placeholder='Email Address'
                                altDisplay={true}
                                value={email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(event.target.value)
                                }}
                                errorMessage={emailError}
                                preventAutofill={true}
                                // If user is logged in, don't let them change their email.
                                disabled={isPreviewMode ? true : isLoggedIn === false ? false : true} // `isLoggedIn` can be null
                            />
                        </div>

                        {/* Don't show password fields if user is logged in already - password
                          * change should happen on a different page.
                          */}
                        {isLoggedIn === false ? (
                            <div className="form-wrapper-bottom p1">
                                <InputField
                                    required
                                    id="supplier-registration-password"
                                    name='password'
                                    type='password'
                                    placeholder='Password'
                                    altDisplay={true}
                                    value={password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPassword(event.target.value)
                                    }}
                                    errorMessage={passwordError}
                                    preventAutofill={true}
                                    disabled={!!isPreviewMode}
                                />
                                <InputField
                                    required
                                    id="supplier-registration-passwordconfirmation"
                                    name='passwordConfirm'
                                    type='password'
                                    placeholder='Password Confirmation'
                                    altDisplay={true}
                                    value={passwordConfirm}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPasswordConfirm(event.target.value)
                                    }}
                                    preventAutofill={true}
                                    disabled={!!isPreviewMode}
                                />
                            </div>
                        ) : null}

                        {/* Error messages - from DB API */}
                        {(!hideAllErrors && errorMessage) ? (
                            <div className='error-message' data-type='db-api'>
                                {errorMessage}
                            </div>
                        ): null }

                        {/* Error messages - from AWS Cognito (must be a separate error var) */}
                        {(!hideAllErrors && cognitoErrorMessage) ? (
                            <div className='error-message' data-type='cognito'>
                                [_Cognito -- {cognitoErrorMessage}]
                            </div>
                        ): null }

                        {/* Test resend-verification-code link */}
                        {(showResendVerificationCode) ? (
                            <div className='resend-verification-code-link-container'>
                                If you don't see the email within 5 minutes, check your spam folder.
                                {/* <br/>
                                To send another verifcation link,&nbsp;
                                <a 
                                    href='#' 
                                    onClick={() => resendVerification(awsUsername)} 
                                    className='resend-link'
                                >
                                    click here
                                </a>. */}
                            </div>    
                        ) : ( null )}

                        <div className="buttons-container" style={{justifyContent: 'space-around'}}>
                            {isEdit ? (
                                <BlockButton
                                    type='button'
                                    style={{ width: '250px' }}
                                    buttonStyle="ghost-purple"
                                    onClick={() => window.location.href = '/account'}
                                >
                                    CANCEL
                                </BlockButton>
                            ) : null}

                            {(!isPreviewMode && !isEdit) ? (
                                <BlockButton
                                    type='submit'
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                >
                                    CONTINUE
                                </BlockButton>
                            ) : (isEdit ? (
                                <BlockButton
                                    type='submit'
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                >
                                    SAVE
                                </BlockButton>
                            ) : (
                                <BlockButton
                                    type='button' // IMPORTANT
                                    style={{ width: '250px' }}
                                    buttonStyle="purple"
                                    onClick={() => {
                                        window.location.href=`/supplier-registration/${STEP + 1}?mode=preview`
                                    }}
                                >
                                    NEXT
                                </BlockButton>
                            ))}
                        </div>

                        <ToastContainer 
                            hideProgressBar={true}
                            closeOnClick={true}
                            position='bottom-center'
                            style={{textAlign: 'center', cursor: 'default'}}
                        />
                    </form>
                </div>

                {/* If form is not ready to show yet, show the spinner */}
                {showPreloadSpinner ? (
                    <img src={LoadingCircle} className='preload-spinner' />
                ) : null}
            </div>
        </>
    )
}