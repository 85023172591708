import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";

import { InputField } from '../../../shared/form/input-field/InputField';
import { SelectField, SelectOption } from '../../../shared/form/select-field/SelectField';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { TextCard } from "../../../shared/product-card/TextCard";
import { STATES } from '../../../../constants/states';
import webIcon from './website-icon.png';
import pdfIcon from './pdf-icon.png';
import PrivacyIcon from "./privacy-icon.png";

import { DiscountsByQuantity } from '../../../../../config';

import './patient-product-page.scss';



const statesAbbreviationsList: SelectOption[] = [
    { option: 'State', value: null },
    ...STATES.map(state => ({ option: state.abbreviation, value: state.abbreviation }))
]




export interface ProductSkuDetails {
    id: number;
    sku: string;
    sizeAndDose: string;
    price: number;
}
export interface ProductSku {
    [key: string]: ProductSkuDetails
}
export interface ProductDesc {
    id: number;
    name: string;
    nameDisplay: string;
    vendor: string;
    brand: string;
    description: string;
    productUses: string;
    productResearch: string;
    imageSrc1: string;
    imageSrc2: string;
    imageSrc3: string;
    style: React.CSSProperties;
    skus: ProductSku;
    displayMods: {[key: string]: any};
    discountsByQuantity: DiscountsByQuantity[]; // [[<top_quantity_for_this_discount>, <discount_multiplier>]]
}
export interface ProductsById {
    [key: string]: ProductDesc
}




export const PatientProductPage = () => {
    let params = useParams()
    let productId = Number(params.productId)

    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)

    // Simulate API call to fetch data from db.
    // const [MOCKproductDetails, setMOCKProductDetails] = useState({} as ProductsById)
    // MOCK_API_get_product_details().then((data: ProductsById) => {
    //     setMOCKProductDetails(data)
    // })

    return (
        <>
            <div className="header-spacer"></div>
            <div className="product-detail-page-layout">
                This page is not in use.
                {/* <div className="body-container formwrapper-top">
                    <div className="category-2-wrapper">
                        <div className="multicolor-headers">
                            <div className="header small">
                                <Link to='/public/vendor-profile'>
                                    {MOCKproductDetails[productId]?.vendor}
                                </Link>
                            </div>
                            <span className='header small'>
                                {'/'}
                            </span>
                            <div className="header small blue" style={{ cursor: 'default' }}>
                                {MOCKproductDetails[productId]?.nameDisplay}
                            </div>
                        </div>
                    </div> */}

                    {/* {(productId) && (
                        <TextCard
                            productId={productId}
                            name={MOCKproductDetails[productId]?.name}
                            nameDisplay={MOCKproductDetails[productId]?.nameDisplay}
                            imageSrc1={MOCKproductDetails[productId]?.imageSrc1}
                            imageSrc2={MOCKproductDetails[productId]?.imageSrc2}
                            imageSrc3={MOCKproductDetails[productId]?.imageSrc3}
                            style={MOCKproductDetails[productId]?.style}
                            vendor={MOCKproductDetails[productId]?.vendor}
                            brand={MOCKproductDetails[productId]?.brand}
                            description={MOCKproductDetails[productId]?.description}
                            productUses={MOCKproductDetails[productId]?.productUses}
                            productResearch={MOCKproductDetails[productId]?.productResearch}
                            skus={MOCKproductDetails[productId]?.skus}
                            showProductDetailsModal={showProductDetailsModal}
                            setShowProductDetailsModal={setShowProductDetailsModal}
                        />
                    )} */}
                {/* </div> */}
                {/* <div className="formwrapper-mid">
                    <div className="body-container category-3-wrapper">
                        <div className="header blue">USES</div>
                        {(productId) && (
                            <p> {MOCKproductDetails[productId]?.productUses} </p>
                        )}
                    </div>
                </div>
                <div className="body-container formwrapper-bottom">
                    <div className="category-4-wrapper">
                        <div className="header blue">RESEARCH</div>
                        {(productId) && (
                            <p> {MOCKproductDetails[productId]?.productResearch} </p>
                        )}
                    </div>

                    <div className="pdf-web-wrapper noprint">
                        <ul>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={pdfIcon} className="icons pdf-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis in scelerisque
                            </li>
                            <li>
                                <img src={webIcon} className="icons web-icon noprint" />
                                Proin nisi sem, venenatis
                            </li>
                        </ul>
                    </div>
                </div>


                
                <div className="body-container-no-vert-pad formwrapper-bottomform">
                    <div className="multicolor-headers">
                        <div className="header blue">PHYSICIAN</div>
                        <div className="header">REFERRAL</div>
                    </div>
                    <p>Test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text</p>
                    <div className="form-container">
                        <div className="physician-referral-form">
                            <div className="category-1-wrapper">
                                <InputField
                                    required
                                    id="patient-doctor-contact-firstname"
                                    name='firstname'
                                    placeholder='First Name'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                                <InputField
                                    required
                                    id="patient-doctor-contact-lastname"
                                    name='lastname'
                                    placeholder='Last Name'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                            </div>
                            <div className="category-2-wrapper">
                                <InputField
                                    required
                                    id="patient-doctor-contact-email"
                                    type="email"
                                    name='emailaddress'
                                    placeholder='Email Address'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                                <InputField
                                    id="patient-doctor-contact-phone"
                                    name='phone'
                                    type="number"
                                    placeholder='Phone Number'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                            </div>
                            <div className="category-3-wrapper">
                                <InputField
                                    required
                                    id="patient-doctor-contact-address"
                                    type="text"
                                    name='streetaddress'
                                    placeholder='Street Address'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                            </div>
                            <div className="category-4-wrapper">
                                <InputField
                                    id="patient-doctor-contact-city"
                                    name='city'
                                    type="text"
                                    placeholder='City'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />
                                <SelectField
                                    //   style={{ width: '300px' }}
                                    required
                                    id="patient-doctor-contact-state"
                                    name='state'
                                    //  placeholder='State'
                                    options={statesAbbreviationsList}

                                />
                                <InputField
                                    id="patient-doctor-contact-zip"
                                    name='zip'
                                    type="number"
                                    placeholder='Zip Code'
                                    style={{ fontWeight: 'normal', fontSize: '1.8rem' }}
                                />


                            </div>
                            <div className="block-button-holder">
                                <BlockButton
                                    style={{ width: '240px', marginTop: '35px' }}
                                    buttonStyle='desktop-header-buttons purple'
                                >
                                    SEND EMAIL
                                </BlockButton>
                            </div>
                        </div>

                        <div className="privacy-container">
                            <div className="privacy-image"><img src={PrivacyIcon} /></div>
                            <div className="header-small">YOUR PRIVACY</div>
                            <div className="privacy-text">test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text test text </div>

                        </div>
                    </div>
                </div> */}
            </div>

        </>
    )
}