import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { Table } from "../../../shared/tables/Table";
import { InputField } from "../../../shared/form/input-field/InputField";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import "./rep-dashboard.scss";


// TODO:  FIX DATE DROPDOWN SPACING AND STYLES IN SelectField FILE



export const RepDashboard = () => {

  const ShowEntries: SelectOption[] = [
    { option: ' March 21, 2022 - March 31, 2022 ', value: 3.5 },
    { option: ' April 1, 2022 -  April 20, 2022', value: 4 },
    { option: ' April 21, 2022 - April 31, 2022', value: 4.5 },
    { option: ' May 1, 2022 -  May 20, 2022 ', value: 5 },
    { option: ' May 21, 2022 -  May 31, 2022 ', value: 5.5 },
  ]


  return (
    <div className="rep-dashboard-layout">
      <div className="body-container">
        <div className="search-span">
          <SelectField
            name='entries-number'
            options={ShowEntries}
          />
        </div>
        <div className="top-container">
          <div className="top-row">

            <div className="columns-top">
              <div className="info-card">
                <div className="info-span">
                  <div className="header">ORDER TOTAL</div>
                  <div className="circle-icon">!</div>
                </div>
                <div className="info-span">
                  <div className="header blue">$0.00</div>
                  <div className="header small blue">0.0%</div>
                </div>
              </div>
            </div>

            <div className="columns-top">
              <div className="info-card">
                <div className="info-span">
                  <div className="header">ORDER NUMBERS</div>
                  <div className="circle-icon">!</div>
                </div>
                <div className="info-span">
                  <div className="header blue">$0.00</div>
                  <div className="header small blue">0.0%</div>
                </div>
              </div>
            </div>

            <div className="columns-top">
              <div className="info-card">
                <div className="info-span">
                  <div className="header">AVERAGE ORDER PRICE</div>
                  <div className="circle-icon">!</div>
                </div>
                <div className="info-span">
                  <div className="header blue">$0.00</div>
                  <div className="header small blue">0.0%</div>
                </div>
              </div>
            </div>

            <div className="columns-top">
              <div className="info-card">
                <div className="info-span">
                  <div className="header">COMISSION VOLUME</div>
                  <div className="circle-icon">!</div>
                </div>
                <div className="info-span">
                  <div className="header blue">$0.00</div>
                  <div className="header small blue">0.0%</div>
                </div>
              </div>
            </div>

            <div className="columns-top right-side">
              <div className="info-card right-side">
                <div className="info-span">
                  <div className="header">NEW ORDERS</div>
                </div>
                <div className="info-span">
                  <div className="header blue">0</div>
                </div>
              </div>

              <div className="info-card right-side">
                <div className="info-span">
                  <div className="header">REPEATED ORDERS</div>
                </div>
                <div className="info-span">
                  <div className="header blue">0</div>
                </div>
              </div>
            </div>

          </div>

          <div className="middle-row">

            <div className="container map">
              <div className="header">DOCTOR LOCATION TRENDS</div>
              <div className="placeholders heatmap">HEAT MAP</div>
            </div>
            <div className="container right-side">
              <div className="header">PAYMENT METHODS</div>
              <div className="centering-map">
                <div className="placeholders circle-chart">CIRCLE CHART</div>
              </div>
            </div>
            <div className="container right-side two">
              <div className="header">CUSTOMER LEADERBOARDS</div>
              <div className="leaderboards">
                <div className="box">1. Doctor Name</div>
                <div className="box">2. Doctor Name</div>
                <div className="box">3. Doctor Name</div>
                <div className="box">4. Doctor Name</div>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <div className="text-span">
              <div className="header">MOST POPULAR PRODUCTS</div>
              <span>Product (Hover to see completely)</span>
            </div>
            <div className="box">1. Popular Product Name</div>
            <div className="box">2. Popular Product Name</div>
            <div className="box">3. Popular Product Name</div>
            <div className="box">4. Popular Product Name</div>
            <div className="box">5. Popular Product Name</div>
          </div>
        </div>
      </div>
    </div>
  )
}