import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';

import { BlockButton } from "../buttons/block-button/BlockButton";
import { Modal } from "./Modal"

import './tag-reassign-modal.scss'



type Props = {
  show: boolean|null;
  closeModal: Function;
  title: string;
  subtitle:string;
  message: string;
  action: 'deactivate'|'reassign';
  setAction: React.Dispatch<React.SetStateAction<'deactivate'|'reassign'>>;
  reassignTo: string;
  setReassignTo: React.Dispatch<React.SetStateAction<string>>;
  onConfirm: Function;
}



export const TagReassignModal = ({ 
    show, closeModal,
    title, subtitle, message,
    action, setAction,
    reassignTo, setReassignTo,
    onConfirm,
}: Props) => {
    const [disableConfirmButton, setDisableConfirmButton] = useState(false)

    return (
        <div className='tag-reassign-modal'>
            <Modal
                modalType={2}
                show={!!show}
                closeModal={closeModal}
            >
                <div className="modal-contents">
                    <h2 className="title">{title}</h2>
                    <div className="sub-title">{subtitle}</div>
                    
                    <div className='radio-row'>
                        <input 
                            type="radio"
                            id="actionRD1"
                            name="actionRD" 
                            checked={action==='deactivate'}
                            onChange={() => setAction('deactivate')}
                            className='default'
                        />
                        <label htmlFor="actionRD1">Deactivate</label>
                        <input
                            type="radio"
                            id="actionRD2"
                            name="actionRD"
                            checked={action==='reassign'}
                            onChange={() => setAction('reassign')}
                        />
                        <label htmlFor="actionRD2">Reassign / Reactivate</label>
                    </div>


                    {(action === 'reassign') ? (
                        <div className='regular-text'>
                            Who should this rep be assigned to?
                        </div>
                    ) : (action === 'deactivate') ? (
                        <div className='regular-text'>
                            After deactivation, who should their currently tagged reps be re-assigned to?
                        </div>
                    ) : null}

                    <input
                        type='text'
                        className='reassign-to-input'
                        placeholder='Enter email address or user ID'
                        required={true}
                        value={reassignTo}
                        onChange={(e) => setReassignTo(e.target.value)}
                    />

                    <div className='buttons-row'>
                        <BlockButton
                            type='button'
                            style={{ marginTop: '40px' }} 
                            buttonStyle="ghost-purple"
                            onClick={closeModal}
                        >
                            Cancel
                        </BlockButton>
                        <BlockButton
                            type='button'
                            style={{ marginTop: '40px' }} 
                            buttonStyle="purple"
                            onClick={() => {
                                setDisableConfirmButton(true)
                                onConfirm()
                            }}
                            disabled={disableConfirmButton}
                        >
                            Confirm
                        </BlockButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
};