import { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../components/auth/Account'

export const useCurrentSession = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean|null>(null)
    const [currentUser, setCurrentUser] = useState(null)
    const { getSession, getUser } = useContext(AccountContext)

    useEffect(() => {
        getSession().then((session: any) => {
            // TODO: remove this for prod.
            // TODO: can probably make use of using a state var for session and returning it.
            console.log('session::', session)
            setIsLoggedIn(true)
            getUser().then((user: any) => {
                setCurrentUser(user)
            }).catch(() => {
                setCurrentUser(null)
            })
        }).catch(() => {
            setIsLoggedIn(false)
        })
    }, [])
    
    return {isLoggedIn, currentUser}
}


