import axios, { Axios } from 'axios';
import React from 'react';
import ImageOne from './images/doctorimage1.png';
import ImageTwo from './images/doctorimage2.png';
import ImageThree from './images/doctorimage3.png';
import DoctorProfileImage from './images/doctor-profile-card.png';
import './doctorcard.scss';
import PrintOnlyContent from '../../print/PrintOnlyContent';



interface Props {
    imgSrc: string;
    name: string;
    practice: string;
    description: string;
    pillButton?: JSX.Element;
    containerStyle?: React.CSSProperties;
};


export const DoctorCard = ({ imgSrc, name, practice, description, pillButton, containerStyle={} }: Props) => {

    //  public state: State = {};
    //  private _api: Axios;

    //  constructor(props: Props) {
    //      super(props);

    //      this._api = axios.create({
    //          baseURL: '',
    //          headers: {
    //              'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
    //              'content-type': 'application/json',
    //          },
    //      });
    //  }

    //  componentDidMount() {
    //      const endpoint = '';

    // this._api.get()

    // axios.get(endpoint, )
    // }

    const truncateText = (text: string = '', charLimit: number = 200) => {
        if (text.length < charLimit) return text;
        
        let shorterText = text
        shorterText = text.slice(0, charLimit)
        for (let x=shorterText.length-1; x>=0; x--) {
            if (shorterText[x] === ' ') {
                shorterText = shorterText.slice(0, x) + '...'
                break
            }
        }
        return shorterText + '...'
    }

    return (
        <>
        {/* Web only */}
        <div className="manualcarousel-content-container noprint" style={containerStyle}>
            <div className='image-container'>
                <img src={imgSrc} />
            </div>

            <div className="item-list">
                <div className='name-and-title'>
                    <div className="doctor-name">{name}</div>
                    <div className="career-field">{practice}</div>
                </div>
                <div className="description-container">
                    <div className="description">{truncateText(description, 200)}</div>
                    <div className="description-small">{truncateText(description, 150)}</div>
                    <div className="description-vsmall">{truncateText(description, 80)}</div>
                    {(pillButton) && (pillButton)}
                </div>
            </div>
        </div>
        {/* Print only - same content as above but without the formatting */}
        <PrintOnlyContent>
            <img src={imgSrc} />
            <div>{description}</div>
        </PrintOnlyContent>
        </>
    );
}
