import './radio-selector.scss';



interface Props {
  name: string;
  useLeftMargin?: boolean;
  id?: string,
  required?: boolean,
  children?: any;
}

export const RadioSelector = ({ name, useLeftMargin = true, children }: Props) => {
  return (

    <div className="div-contain-radio">
      <label className={"radio-label " + (useLeftMargin ? '' : 'no-left-margin')}> {/* TODO: no-left-margin is defined in caller's stylesheet */}
        <input type="radio" name={name} />
        {children}
      </label>
    </div>

  );
}
