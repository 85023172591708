import axios, { Axios } from 'axios';
import React from 'react';
import ProductImage from "./images/productimage1.png"
import ProductImage2 from "./images/product2-placeholder.png"
import ProductImageAmnioTextBox from "./images/Amniotext-Box-1.png"
import ProductImageProTextVial from "./images/Protext-Vial.png"
import ProductImageCryoTextVial from "./images/Cryotext-Vial.png"
import ProductImageSecreTextVial from "./images/Secretext-Vial.png"

import './product-card.scss';



const _ENV = process.env.REACT_APP__ENV

// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)


interface Props {
    vendor: string;
    brand: string;
    product: string;
    description: string;
    productImage: 'productimage1' | 'productimage2' | 'amniotextbox' | 'protextvial'
    | 'cryotextvial' | 'secretextvial' | 'PRODUCT_1';
    productId?: string|number;
};


export const ProductCard = ({ vendor, brand, product, description, productImage, productId }: Props) => {

    const imageName = (productImage: string) => {
        switch (productImage) {
            case 'amniotextbox':
                return <img src={ProductImageAmnioTextBox} />
            case 'protextvial':
                return <img src={ProductImageProTextVial} />
            case 'cryotextvial':
                return <img src={ProductImageCryoTextVial} />
            case 'secretextvial':
                return <img src={ProductImageSecreTextVial} />
            case 'productimage1':
                return <img src={ProductImage} />
            case 'productimage2':
                return <img src={ProductImage2} />
            case 'PRODUCT_1':
                return <img src={`${CLOUDFRONT_BASE_URL}/uploads/product/${productId || 0}/PRODUCT_1`} />
            default:
                return null
        }
    }

    // public state: State = {};
    // private _api: Axios;

    //  constructor(props: Props) {
    //      super(props);

    //      this._api = axios.create({
    //          baseURL: '',
    //         headers: {
    //             'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
    //             'content-type': 'application/json',
    //         },
    //     });
    //  }

    //  componentDidMount() {
    //      const endpoint = '';

    //      // this._api.get()

    // axios.get(endpoint, )
    //   }




    const truncateText = (_text: string, charLimit: number = 180) => {
        const text = _text || ''
        if (text.length < charLimit) return text;
        return text.substring(0, charLimit) + '...';
    }



    return (
        <div className="product-card">
            <div className="image">
                {imageName(productImage)}
            </div>
            <ul className="item-list">
                <li className="item-li-group productname">{truncateText(product)}</li>
                <li className="item-li-group brandname">{truncateText(brand)}</li>
                <li className="item-li-group vendorname">{truncateText(vendor)}</li>
                <li className="item-li-group productdescription">{truncateText(description)}</li>
            </ul>
        </div>
    );
}