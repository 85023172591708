/**
 * A library for the frontend to interact with the back which talks to Redis.
 * - Redis is used for: 
 *      - shopping cart
 *      - shopping cart note
 *      - search (in a different file)
 * - Note: most of these funcs used to be implemented w/cookies, see the legacy funcs in CookieFunctions.tsx.
 * 
 * TODO: these need tests, and validation checks to ensure formats etc are good.
 * 
 * FYI: As of Jul 2023, it's safe to assume users WILL be logged in when needing to do
 *      anything with Redis.
 * 
 * FYI: max size of a string assigned to a single key in MemoryDB Redis is 512MB.
 *  - IMPORTANT -
 *  This number will become even smaller as SKU ids and product IDs get longer.
 *  - TODO -
 *  After a real database is added, just call the db for the dates on the checkout screen.
 */
import axios from "axios";

import { CART_REFERENCE_KEYS } from "../../config";
import { CookieNames, getCookie } from "./Cookie_General";
import { CartDict } from "./Cart_Types";
import parseErrorObject from "../helpers/parseErrorObject";



// API endpoint
const API_DB_URL = process.env.REACT_APP_API_DB_URL







/**
 * General Redis functions.
 * ============================================================================
 */



// Get the number of items in the cart. 
// - This is just a logic function that takes a cart obj arg and counts the items within it;
//   there are no calls to external services.
// Args:
//      _cart: can be either a dict (first call), or a JSON string (every other call).
export const cartItemsCount = (_cart: any): number => {
    let cart = _cart
    if (typeof cart === 'string') {
        try {
            cart = JSON.parse(cart)
        } catch (e) {
            cart = {}
        }
    }
    cart = cart || {}

    let totalSkuCount = 0
    for (let _productId of Object.keys(cart)) {
        const productId = _productId.trim()
        // (Non-first) calls to this func will have 'orderId' key in cartDict.
        if (!productId || CART_REFERENCE_KEYS.has(productId.toLowerCase())) continue;
        for (let skuCount of Object.values(cart[productId])) {
            totalSkuCount += Number(skuCount) || 0
        }
    }
    return totalSkuCount
}



/**
 * Shopping Cart actions
 * ============================================================================
 *  `cartDict` format:
 *      {<product_id>: {<sku_id>, <sku_count>, ...}, ...}
 */

// Get info about the cart from Redis for this particular user.
// - If user not logged in, return `null` (no error popups).
// - But if there is a failure from Redis show a popup.
export const getCartDict = async (sessionJson: string): Promise<CartDict|null> => {
    if (!sessionJson) return null

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)

    const cartData = await axios.post(`${API_DB_URL}/cart/get`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => { 
        // Assume the shape of obj is correct `CartDict` shape, if it's not null.
        // - This will NOT be a JSON string.
        return res.data
    }).catch(err => {
        const baseMsg = "Could not get cart due to server error. Please try again in a few minutes.\n\n"
        const eMsg = parseErrorObject(err, '#14441')
        alert(baseMsg + eMsg)
        return null
    })
    return cartData
}

// Increment/increase the count of a particular product/sku in the cart.
// - To remove items, provide a negative `count` to remove items.
// - The backend will handle if the `count` val is 0 or less:
//      - < 1 skus means the sku_id key will be removed.
//      - < 1 products means the whole dict will be (removed or emptied - not sure).
// - User not logged in => show popup alert message.
export const addItemToCart = async (
    _productId: number|string, _skuId: number|string, count: number = 1,
    sessionJson: string,
): Promise<CartDict|null> => {
    if (!sessionJson) {
        alert("Please refresh or login to continue (#47810).")
        return null
    }
    const productId = String(_productId)
    const skuId = String(_skuId)

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    formData.append('pid', productId)
    formData.append('sid', skuId)
    formData.append('count', String(count))

    const cart = await axios.post(`${API_DB_URL}/cart/add`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        // Assume the shape of obj is correct `CartDict` shape, if it's not null.
        // - This will NOT be a JSON string.
        return res.data
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14442')
        alert(eMsg)
        return null
    })
    return cart
}

// Remove all items from cart. Like after they completed their order/payment.
export const emptyCart = async (sessionJson: string): Promise<null> => {
    if (!sessionJson) {
        alert("Please refresh or login to continue.")
        return null
    }
    // The old (cookie) way.
    // deleteCookie(CookieNames.cart)
    // ...
    // the new (memorydb/redis) way.
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)

    const res = await axios.post(`${API_DB_URL}/cart/clear`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return res.data // Backend will always return `res.data = null` (since cart is empty now).
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14443')
        alert(eMsg)
        return null
    })
    return res
}


// ============================================================================
// Shopping Cart - Order IDs
// - Generate an orderId on the backend when user first visits checkouut-confrimation page, then
//   delete the orderId after user successfully places their order.
//
// TODO: should probably do this from the backend after the payment is finalized.
// ============================================================================

/**
 * Get order ID from Redis, if possible.
 * - Calls backend endpoint which calls `memdb_GetOrderId`.
 * - If user isn't logged in, show popup alert message and return null.
 * - If user is logged in but doesn't have an order ID yet, generate one.
 */
export const getOrderId = async (sessionJson: string): Promise<string|null> => {
    if (!sessionJson) {
        alert("Please refresh or login to continue.")
        return null
    }
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    const res = await axios.post(`${API_DB_URL}/cart/get-oid`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return res.data || null
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14444')
        alert(eMsg)
        return null
    })
    return res
}

/**
 * Delete the Order ID from Redis, if possible.
 * - Calls backend endpoint which calls `memdb_GetOrderId`.
 * - If user isn't logged in, return null.
 * - If user is logged in but doesn't have an order ID yet, generate one.
 * 
 * TODO: this should be deleted from the backend as part of the checkout/payment completion function.
 */
export const deleteOrderId = async (sessionJson: string): Promise<null> => {
    if (!sessionJson) {
        return null // Don't show alert here.
    }
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    const res = await axios.post(`${API_DB_URL}/cart/delete-oid`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return res.data // Backend will always return `res.data = null` (since cart is empty now).
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14445')
        alert(eMsg)
        return null
    })
    return res
}


// ============================================================================
// Functions for managing notes added to shopping cart
// ============================================================================

// Function to getting the stored note text that was previously added to shopping cart.
// - When note cannot be retrieved it will return null
// - FYI: If the key doesn't exist (aka when this func returns null, the frontend won't do anything
//   about that (such as saving empty str to that Redis key).
export const getCartNote = async (sessionJson: string): Promise<string|null> => {
    if (!sessionJson) {
        return null 
    }
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    const res = await axios.post(`${API_DB_URL}/cart/get-note`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return res.data || null
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14446')
        alert(eMsg)
        return null
    })
    return res
}

// Function to store note text added to shopping cart.
// - FYI: cart notes will persist between different order IDs.
export const storeCartNote = async (sessionJson: string, noteText: string): Promise<boolean> => {
    if (!sessionJson) {
        alert('You must be logged in to modify your note.')
        return false
    }
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    formData.append('noteText', noteText)
    const res = await axios.post(`${API_DB_URL}/cart/set-note`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return !!res.data // success will be `true`
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14447')
        alert(eMsg)
        return false
    })
    return res
}


// ============================================================================
// Functions for getting user ID from sessionJson
// ============================================================================

export const getUserIdFromSessionJson = async (sessionJson: string): Promise<number|null> => {
    if (!sessionJson) return null;

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)

    const res = await axios.post(`${API_DB_URL}/get-uid-from-sessionjson`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        const userId = Number(res.data)
        return userId || null
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#16901')
        alert(eMsg)
        return null
    })
    return res
}

// ============================================================================
// Functions for storing user's selected shipping address.
// ============================================================================

// Get the ID of the shipping address that the user most recently selected.
// Function to getting the stored note text that was previously added to shopping cart.
// - On error, just return null, no alert (and if they're not logged in another func in this 
//   file will alert them)
// - Return addressId as a number (or null if not found), not string.
export const getSelectedAddressId = async (sessionJson: string): Promise<number|null> => {
    if (!sessionJson) return null;

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)

    const res = await axios.post(`${API_DB_URL}/cart/get-shipping-address-id`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        const addressId = Number(res.data)
        return addressId || null
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14448')
        alert(eMsg)
        return null
    })
    return res
}

// Save the ID of the shipping address that the user most recently selected.
// - Let the backend handle bad data types for input'd addressId.
// - On success: return true.
// - On error: show popup alert message and return false.
export const storeSelectedAddressId = async (sessionJson: string, addressId: number|string): Promise<boolean> => {
    if (!sessionJson) {
        alert('Please refresh or login again.')
        return false
    }
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    formData.append('addressId', String(addressId))
    const res = await axios.post(`${API_DB_URL}/cart/set-shipping-address-id`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        return !!res.data // success will be `true`
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14449')
        alert(eMsg)
        return false
    })
    return res
}



// ============================================================================
// Functions for storing user's selected payment method & account.
// - TODO: these still use Cookies ... use redis/db/api
// ============================================================================


// Save IDs of both the payment method and payment account that the user selected..
export const storePaymentMethodIds = (
    _paymentMethodId: number|string, _paymentAccountId: number|string, expireDays: number = 30
) => {
    // Reject if bad data types
    if (!['number', 'string'].includes(typeof _paymentMethodId) || !['number', 'string'].includes(typeof _paymentAccountId)) {
        return
    }

    const paymentMethodId = String(_paymentMethodId)
    const paymentAccountId = String(_paymentAccountId)

    // Make expiration date string
    const expireDate = new Date()
    expireDate.setDate(expireDate.getDate() + expireDays)

    // Make two cookies, one each for payment method ID and payment account IO.
    document.cookie = CookieNames.paymentmethodid + '=' + paymentMethodId + ';expires=' + expireDate.toUTCString() + ';path=/'
    document.cookie = CookieNames.paymentaccountid + '=' + paymentAccountId + ';expires=' + expireDate.toUTCString() + ';path=/'
}

// Return both the payment method ID and payment account ID in an array.
export const getPaymentMethodIds = () => {
    // July 2023: Added fallback values because the cookie mechanisms were gutted, so grab a default.
    // - both of below are integers.
    const paymentMethodIdCookie = getCookie(CookieNames.paymentmethodid) || 1
    const paymentAccountIdCookie = getCookie(CookieNames.paymentaccountid) || 0
    return [paymentMethodIdCookie, paymentAccountIdCookie]
}

// Delete the selected payment ACCOUNT (like when user selects nullish payment account option 
// from the dropdown menu). Don't delete the payment METHOD because there is always one selected in the UI.
// "Delete" means set the value to 0 (because it's an ID value which is always 1 or higher).
export const deletePaymentAccountId = (
    _paymentMethodId: number|string, expireDays: number = 30
) => {
    storePaymentMethodIds(_paymentMethodId, 0, expireDays)
}
