
export type ProductPriceInfo = {
    id: number;
    price: number;
    price_type: string;
    trigger: string;
}


interface IProductPrice {
    get id(): number;
    get price(): number;
    get priceType(): string;
    get trigger(): string;
}


export class ProductPrice implements IProductPrice {
    private _id: number;
    private _price: number;
    private _priceType: string;
    private _trigger: string;

    constructor(priceObj: ProductPriceInfo) {
        this._id = priceObj.id;
        this._price = priceObj.price;
        this._priceType = priceObj.price_type;
        this._trigger = priceObj.trigger;
    }

    get id(): number {
        return this._id;
    }
    get price(): number {
        return this._price;
    }
    get priceType(): string {
        return this._priceType;
    }
    get trigger(): string {
        return this._trigger;
    }
}
