import React, { useEffect, useState } from "react"
import axios from 'axios'

import { InputField } from "../../shared/form/input-field/InputField"

import './db-test.scss'

/**
 * For testing new database connection to RDS.
 * 
 * This component will be deleted later.
 * 
 * To use the db, go into node-postgres and run `$ npm install && node index.js`.
 */
export const DbTest = () => {
    // Get the db endpoint based on current environment.
    // Local app needs to fetch from the original port. Test/Prod live app can fetch with port redirect.
    const API_DB_URL = process.env.REACT_APP_API_DB_URL


    

    const [name, setName] = useState('')
    const [data, setData] = useState('')
    const [id, setId] = useState('')

    
    const [error, setError] = useState('')

    const [rowsResponse, setRowsResponse] = useState('')
    const [parsedRowsResponse, setParsedRowsResponse] = useState<string[]>([])
    const [deleteRowsResponse, setDeleteRowsResponse] = useState('')


    // Some errors sent back by psql don't go into the `catch` block, so handle those here.
    const _isError = (data: any) => {
        return (
            data.constructor === Object && 
            data.name?.toUpperCase === 'ERROR' && 
            data.severity?.toUpperCase() === 'ERROR'
        )
    }


    useEffect(() => {
        let parsed: string[] = []
        try {
            if (typeof rowsResponse === 'string') {
                parsed = JSON.parse(rowsResponse)
            }
        } catch (e) {
            // pass
        }
        try {
            if (Array.isArray(rowsResponse)) {
                parsed = rowsResponse
            } else if (rowsResponse.constructor === Object) {
                parsed = Object.keys(rowsResponse).map((key: any) => String(rowsResponse[key]))
            } else {
                parsed = [String(rowsResponse)]
            }
            setParsedRowsResponse(parsed)
        } catch (e: any) {
            setError((e.name + ': ' + e.message) || 'unknown error')
        }
    }, [rowsResponse])


    const getAllRows = () => {
        axios.get(`${API_DB_URL}/test/`)
            .then((res) => {
                setRowsResponse(JSON.stringify(res.data))
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }

    const getRowById = (id: string) => {
        axios.get(`${API_DB_URL}/test/${id}`)
            .then((res) => {
                setRowsResponse(JSON.stringify(res.data))
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }

    const addRow = (name: string, data: string) => {
        axios.post(`${API_DB_URL}/test/`, {
            name, data
        })
            .then(res => {
                setRowsResponse(JSON.stringify(res.data))
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }

    const updateRow = (id: string, name: string, data: string) => {
        axios.patch(`${API_DB_URL}/test/`, {
            id, name, data
        })
            .then(res => {
                setRowsResponse(JSON.stringify(res.data))
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }

    const deleteRowById = (id: string) => {
        axios.delete(`${API_DB_URL}/test/${id}`)
            .then(res => {
                setDeleteRowsResponse(`deleted row id=${JSON.stringify(res.data)}`)
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }

    const deleteAllRows = () => {
        axios.delete(`${API_DB_URL}/test/0`)
            .then(res => {
                setDeleteRowsResponse(`deleted all rows... ${JSON.stringify(res.data)}`)
            })
            .catch(err => {
                setError(err.name + ': ' + err.message)
            })
    }


    return (
        <div className='db-test-container'>
            <div className='body-container' />
            <div>
                Buttons to test database actions.<br/>
                Currently wired up to [rds=testdb]<br/>
                Using table [test] in database [postgres]<br/>
                Has 5 fields: [id(num), name(str), data(str), created(dt), updated(dt)]<br/>
                <br/><br/><br/>
            </div>

            {/* Get all rows */}
            <button onClick={() => getAllRows()}>
                Get all rows
            </button>

            {/* Get row by ID */}
            <InputField
                name='getrowid'
                placeholder={'Row id to get'}
                value={id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <button onClick={() => {
                getRowById(id)
            }}>
                Get row id={id}
            </button>


            {/* Add/create row */}
            <InputField
                name='name'
                placeholder={'name'}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <InputField
                name='data'
                placeholder={'data'}
                value={data}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <button onClick={() => {
                addRow(name, data)
            }}>
                Add row for {name}, {data}
            </button>

            <InputField
                name='updateid'
                placeholder={'id'}
                value={id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <InputField
                name='updatename'
                placeholder={'updated name'}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <InputField
                name='updatedata'
                placeholder={'updated data'}
                value={data}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <button onClick={() => {
                updateRow(id, name, data)
            }}>
                Update row for id={id}: ({name}, {data})
            </button>

            {/* Delete row by ID */}
            <InputField
                name='deleterowid'
                placeholder={'Row id to delete'}
                value={id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
                style={{width: '300px', display: 'inline-block'}}
            />
            <button onClick={() => {
                deleteRowById(id)
            }}>
                Delete row id={id}
            </button>
            
            {/* Delete all rows */}
            <button onClick={() => {
                deleteAllRows()
            }}>
                Delete ALL rows
            </button>




            {/* Error responses */}
            <div style={{color: 'red'}}>
                {error}
            </div>


            {/* get-row(s) response */}
            <div style={{color: 'blue'}}>
                <>
                {parsedRowsResponse.map((row: string, x: number) => (
                    <React.Fragment key={x}>
                        {row}<br/>
                    </React.Fragment>
                ))}
                </>
            </div>

            {/* delete-row(s) response */}
            <div style={{color: 'purple'}}>
                {deleteRowsResponse}
            </div>



        </div>
    )
}
