
export class HippaTimeout {
    private _startTime: number;
    constructor() {
        this._startTime = Date.now();
    }


    public elapsedMinutes() {
        const minutes = this._startTime / 60000;
        // const seconds = ((this._startTime % 60000) / 1000).toFixed(0);
        return minutes;
    }

    public static elapsedMinutes(ms: number) {
        const minutes = ms / 60000;
        return minutes;
    }

    public static getMinutesInMilliseconds(minutes: number) {
        const ms = minutes * 60000;
        return ms;
    }
}