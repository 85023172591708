import RedirectGif from '../../../static/img/redirect-spinner.gif'
import './temp-modal.scss'


type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    titleText?: string,
};


export const RegistrationLoadingModal = ({
    show, setShow, titleText,
}: Props) => {
    return (
        <div className='temp-redirect-modal' style={{display: show ? 'block' : 'none'}}>
            <div 
                className={`redirecting-message-container full-opacity`}
                style={{ display: 'block' }}
            >
                <div className={`redirecting-message full-opacity`}>
                    {titleText || 'Loading'}
                    <br/>
                    <img src={RedirectGif} />
                </div>
            </div>
        </div>
    )
}