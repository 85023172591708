/**
 *For Reps users. Shows other reps they have tagged.
 */

import React from 'react'

import { BlockButton } from '../../shared/buttons/block-button/BlockButton';
import { Icon } from '../../shared/icon/Icon';

import { TaggedReps, MyAccountInfo } from '../../../../config';



interface Props {
    getTagsListError: string;
    tagsData_RtoR_sent: TaggedReps[];
    tagsData_RtoR_received: TaggedReps[];
    untagRep: Function;
    userId: number;
    userType: 'consumer';
    accountInfo: MyAccountInfo;
    setShowTagInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTagInviteType: React.Dispatch<React.SetStateAction<'clinic'|'rep'|''>>;
}



export const TaggedReps_Rep = ({
    getTagsListError,
    tagsData_RtoR_sent,
    tagsData_RtoR_received,
    untagRep,
    userId, userType, accountInfo,
    setShowTagInviteModal, setTagInviteType,
}: Props) => {
    
    // Condition to prevent display of this modal.
    // - This makes sure they are either a part of a rep-org, OR they have reps tagged under them.
    // - ... unless they are a rep-lead.
    if (
        !tagsData_RtoR_sent.find(t => t.active) 
        && !tagsData_RtoR_received.find(t => t.active)
        && !accountInfo.u_types.is_rep_lead
    ) {
        return null
    }

    return (
        <div className='section'>
            <div className='title-row'>
                <div className='section-title'>
                    My Reps
                </div>
            </div>

            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            {/* For `sent` - filter out the tag row that was for this user's on-registration
              * request to become a member of their rep-org.
              */}
            {tagsData_RtoR_sent.filter(t => !t.on_reg).length ? (
                <table className='info-table'>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Status</td>
                            <td>Invited</td>
                            <td>Accepted</td>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Same filter as above, again. */}
                        {tagsData_RtoR_sent.filter(t => !t.on_reg).map((row, index) => (
                            <tr 
                                key={index + '--' + row.to_uid}
                                className='tag-row'
                                style={{ cursor: 'default' }}
                                title={
                                    `Invitation from:\r\n`
                                    + `    Email: ${row.to_email}\r\n`
                                    + `    ${row.to_name ? `Rep: ${row.to_name}\r\n` : ''}`
                                }
                            >
                                <td className='title medium product-cell'>
                                    {row.to_name}
                                </td>
                                <td className='smaller product-cell'>
                                    {row.active ? 'Active' : 
                                        row.active === false ? 'Inactive' : 
                                        (row.active === null && !row.accepted_at) ? 'Invitation sent' : 
                                        'Invite sent' // this only happens if tag was de-activated then user was invited again later. (??)
                                    }
                                </td>
                                <td className='small product-cell'>
                                    {row.invited_at}
                                </td>
                                <td className='small product-cell'>
                                    {(row.active) ? (
                                        row.accepted_at
                                    ) : (row.active === false) ? (
                                        'Deactivated' // This should never show bc it's filtered out on backend.
                                    ) : (row.active === null && !row.invited_at) ? (
                                        <span style={{color: '#f34'}}>
                                            Email failed to send
                                        </span>
                                    ) : (
                                        <span style={{color: '#f34'}}>
                                            Pending
                                        </span>
                                    )}
                                </td>
                                <td className='smallest untag-cell'>
                                    {/* untag */}
                                    <button 
                                        type='button'
                                        className='untag-button'
                                        onClick={() => {
                                            untagRep(
                                                'deactivate', null, false,
                                                userId, userType, accountInfo, false,
                                                row.from_roid, row.to_roid,
                                                row.from_reporg_name, row.to_reporg_name,
                                                row.from_bid, row.from_biz_name, 
                                                row.from_uid, row.to_uid, row.from_name, row.to_name, 
                                                row.to_cid, row.to_clinic_name,
                                                row.active)
                                        }}
                                    >
                                            <Icon name='circle-x' />
                                    </button>
                                </td>
                            </tr>
                        ))}

                        {/* IF THIS IS A REP LEAD, THEN ALSO SHOW ROWS WHERE USERS SIGNED UP
                          * AS REP MEMBER AND INVITED THEMSELVES TO BE A PART OF THIS ORG.
                          * - Remember that these "up" tags have inverted "from" and "to" fields.  
                          */}
                        {accountInfo.u_types.is_rep_lead ? (
                            <>
                            {tagsData_RtoR_received.filter(t => t.on_reg).map((row, index) => (
                                <tr 
                                    key={index + '-inv-' + row.from_uid }
                                    className='tag-row'
                                    style={{ cursor: 'default' }}
                                    title={
                                        `Membership request from:\r\n`
                                        + `    Email: ${row.from_email}\r\n`
                                        + `    ${row.from_name ? `Rep: ${row.from_name}\r\n` : ''}`
                                    }
                                >
                                    <td className='title medium product-cell'>
                                        {row.from_name}
                                    </td>
                                    <td className='smaller product-cell'>
                                        {row.active ? 'Active' : 
                                            row.active === false ? 'Inactive' : 
                                            (row.active === null && !row.accepted_at) ? 'Requested' : 
                                            'Request' // this only happens if tag was de-activated then user was invited again later. (??)
                                        }
                                    </td>
                                    <td className='small product-cell'>
                                        {row.invited_at}
                                    </td>
                                    <td className='small product-cell'>
                                        {(row.active) ? (
                                            row.accepted_at
                                        ) : (row.active === false) ? (
                                            'Deactivated' // This should never show bc it's filtered out on backend.
                                        ) : (row.active === null && !row.invited_at) ? (
                                            <span style={{color: '#f34'}}>
                                                Email failed to send
                                            </span>
                                        ) : (
                                            <span style={{color: '#f34'}}>
                                                Pending
                                            </span>
                                        )}
                                    </td>
                                    <td className='smallest untag-cell'>
                                        {/* untag */}
                                        <button 
                                            type='button'
                                            className='untag-button'
                                            onClick={() => {
                                                untagRep(
                                                    'deactivate', null, false,
                                                    userId, userType, accountInfo, false,
                                                    row.from_roid, row.to_roid,
                                                    row.from_reporg_name, row.to_reporg_name,
                                                    row.from_bid, row.from_biz_name, 
                                                    row.from_uid, row.to_uid, row.from_name, row.to_name, 
                                                    row.to_cid, row.to_clinic_name,
                                                    row.active)
                                            }}
                                        >
                                                <Icon name='circle-x' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </>
                        ) : null}
                    </tbody>
                </table>
            ) : null}
            

            {/* Only show this if they have have an active tag with their Rep-Org.
              * (Indy reps shouldn't be able to tag other reps, either).
              * - For `sent` - filter out the tag row that was for this user's on-registration
              *   request to become a member of their rep-org.
              */}
            {((
                tagsData_RtoR_sent.find(t => t.active) 
                || tagsData_RtoR_received.find(t => t.active)
                || accountInfo.u_types.is_rep_lead
            ) ? (
                <BlockButton
                    type='button'
                    style={{marginTop: '40px', width: 'fit-content'}} 
                    buttonStyle="ghost-purple"
                    className='less-pad mid-size' // 'edit-info main-action'
                    onClick={() => {
                        setShowTagInviteModal(true)
                        setTagInviteType('rep')
                    }}
                >
                    Invite a Rep
                </BlockButton>
            ) : ((
                accountInfo.u_types.is_rep
                && accountInfo.u_types.is_rep_member
                && !accountInfo.u_types.is_rep_lead
            ) ? (
                // For users who are signed up as "rep-members", but haven't been approved yet.
                <div style={{marginTop: '40px', fontStyle: 'italic'}}>
                    Pending membership approval
                </div>
            ) : null))}
        </div>
    )
}