import { ReactComponent as AdminIcon } from '../../../static/icons/icon-admin.svg'
import { ReactComponent as ArrowIcon } from '../../../static/icons/icon-circle-arrow.svg'
import { ReactComponent as BankIcon } from '../../../static/icons/icon-bank.svg'
import { ReactComponent as BankDraftIcon } from '../../../static/icons/icon-bank-draft.svg'
import { ReactComponent as BarChartVertIcon } from '../../../static/icons/icon-bar-chart-vert.svg'
import { ReactComponent as CartIcon } from '../../../static/icons/icon-cart.svg'
import { ReactComponent as Check } from '../../../static/icons/check.svg'
import { ReactComponent as CheckBold } from '../../../static/icons/check-bold.svg'
import { ReactComponent as CircleCheckmarkIcon } from '../../../static/icons/icon-circle-check-filled.svg'
import { ReactComponent as CircleMinusOutlineIcon } from '../../../static/icons/icon-circle-minus-outline.svg'
import { ReactComponent as CirclePlusOutlineIcon } from '../../../static/icons/icon-circle-plus-outline.svg'
import { ReactComponent as CircleXIcon } from '../../../static/icons/icon-circle-x.svg'
import { ReactComponent as ContractIcon } from '../../../static/icons/icon-contract.svg'
import { ReactComponent as CreditCardIcon } from '../../../static/icons/credit-card-icon.svg'
import { ReactComponent as CreditCardPersonIcon } from '../../../static/icons/icon-credit-card-person.svg'
import { ReactComponent as CreditCardIconSizable } from '../../../static/icons/icon-credit-card.svg'
import { ReactComponent as CycleIcon } from '../../../static/icons/icon-cycle.svg'
import { ReactComponent as DropArrowIcon } from '../../../static/icons/icon-drop-arrow.svg'
import { ReactComponent as EditIcon } from '../../../static/icons/icon-edit.svg'
import { ReactComponent as ExclamationIcon } from '../../../static/icons/icon-exclamation.svg'
import { ReactComponent as ExpandIcon } from '../../../static/icons/icon-expand.svg'
import { ReactComponent as HistoryIcon } from '../../../static/icons/icon-history.svg'
import { ReactComponent as InfoIcon } from '../../../static/icons/icon-info.svg'
import { ReactComponent as LinkIcon } from '../../../static/icons/icon-link.svg'
import { ReactComponent as LogoutIcon } from '../../../static/icons/logout-icon.svg'
import { ReactComponent as MagnifyingGlassIcon } from '../../../static/icons/icon-magnifying-glass.svg'
import { ReactComponent as MailCheckIcon } from '../../../static/icons/icon-mail-check.svg'
import { ReactComponent as MinusThickIcon } from '../../../static/icons/icon-minus-thick.svg'
import { ReactComponent as PlusCircleIcon } from '../../../static/icons/icon-plus-circle.svg'
import { ReactComponent as PlusThickIcon } from '../../../static/icons/icon-plus-thick.svg'
import { ReactComponent as ProfileIcon } from '../../../static/icons/icon-profile.svg'
import { ReactComponent as RefreshIcon } from '../../../static/icons/icon-refresh.svg'
import { ReactComponent as TrashOutlineIcon } from '../../../static/icons/icon-trash-outline.svg'
import { ReactComponent as StudyIcon } from '../../../static/icons/icon-study.svg'


type Props = {
    name: 'admin' | 'arrow' | 'bank' | 'bankdraft' | 'barchart-vert' | 'cart' | 
          'creditcard' | 'creditcard2' | 'creditcard3' | 
          'drop-arrow' | 'circle-checkmark' | 'circle-minus-outline' | 'circle-plus-outline' | 
          'circle-x' |'contract' | 'cycle' | 'edit' | 'exclamation-triangle' | 'expand' | 
          'history' | 'info' | 'link' | 'logout' | 'magnifying-glass' | 
          'mail-check' | 'minus-thick' | 'plus-circle' | 'plus-thick' | 
          'profile' | 'refresh' | 'trash-outline' | 'check' | 'check-bold' | 'study'; // 'circle'
}

export const Icon = ({ name }: Props) => {
    switch (name) {
        case 'admin':
            return <AdminIcon />
        case 'arrow':
            return <ArrowIcon />
        case 'bank':
            return <BankIcon />
        case 'bankdraft':
            return <BankDraftIcon />
        case 'barchart-vert':
            return <BarChartVertIcon />
        case 'cart':
            return <CartIcon />
        case 'check':
            return <Check />
        case 'check-bold':
            return <CheckBold />
        case 'circle-checkmark':
            return <CircleCheckmarkIcon />
        case 'circle-minus-outline':
            return <CircleMinusOutlineIcon />
        case 'circle-plus-outline':
            return <CirclePlusOutlineIcon />
        case 'circle-x':
            return <CircleXIcon />
        case 'contract':
            return <ContractIcon />
        case 'creditcard':
            return <CreditCardIcon />
        case 'creditcard2':
            return <CreditCardIconSizable />
        case 'creditcard3':
            return <CreditCardPersonIcon />
        case 'cycle':
            return <CycleIcon />
        case 'drop-arrow':
            return <DropArrowIcon />
        case 'edit':
            return <EditIcon />
        case 'exclamation-triangle':
            return <ExclamationIcon />
        case 'expand':
            return <ExpandIcon />
        case 'history':
            return <HistoryIcon />
        case 'info':
            return <InfoIcon />
        case 'link':
            return <LinkIcon />
        case 'logout':
            return <LogoutIcon />
        case 'magnifying-glass':
            return <MagnifyingGlassIcon />
        case 'mail-check':
            return <MailCheckIcon />
        case 'minus-thick':
            return <MinusThickIcon />
        case 'plus-circle':
            return <PlusCircleIcon />
        case 'plus-thick':
            return <PlusThickIcon />
        case 'profile':
            return <ProfileIcon />
        case 'refresh':
            return <RefreshIcon />
        case 'trash-outline':
            return <TrashOutlineIcon />
        case 'study':
            return <StudyIcon />
        default:
            return null
    }
}