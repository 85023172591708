import { useState } from "react";

import { BlockButton } from "../../shared/buttons/block-button/BlockButton";
import { Icon } from "../../shared/icon/Icon";
import { InputField } from "../../shared/form/input-field/InputField"

import { SelectField, SelectOption } from "../../shared/form/select-field/SelectField";
import { STATES } from "../../../constants/states";
import "./credit-application.scss";



interface Props {
	activeStep: number;
}
/* 
 * Credit Application page - step 3.
 *
 * This component initializes with one form, which can be duplicated by the user for
 * when the company has multiple owners.
 */
export const CreditApplicationStep3 = ({ activeStep }: Props) => {
    const STEP: number = 3

    /* Constants for dropdown lists. */
    const STATE_ABBREVIATIONS: SelectOption[] = [
		{ option: '-', value: null }, 
		...STATES.map(state => ({ option: state.abbreviation, value: state.abbreviation }))
	]
    const CREDIT_APPLICATION_TYPES:  SelectOption[] = [
        {option: '-', value: null},
        {option: 'Commercial', value: 'Commercial'},
    ]

    const [numberOfForms, setNumberOfForms] = useState<number>(1)

    /* 
     * Add additional forms for when the company has multiple owners.
     */
    const addOwner = () => setNumberOfForms(prev => prev + 1)

    return (
        <div style={{display: `${(activeStep === STEP) ? 'block' : 'none'}`}}>
            <div className="form-border">
                {Array.from(Array(numberOfForms)).map((_, index) => (
                    <MainForm 
                        key={index.toString()}
                        STATE_ABBREVIATIONS={STATE_ABBREVIATIONS} 
                        CREDIT_APPLICATION_TYPES={CREDIT_APPLICATION_TYPES}
                        index={index}
                        numberOfForms={numberOfForms}
                        addOwner={addOwner}
                    />
                ))}
            </div>
		</div>
	)
}




interface FormDetails {
    STATE_ABBREVIATIONS: SelectOption[];
    CREDIT_APPLICATION_TYPES: SelectOption[];
    index: number;
    numberOfForms: number;
    addOwner: () => void;
}
/*
 * The form section of the page that can be repeated multiple times.
 */
const MainForm = ({ STATE_ABBREVIATIONS, CREDIT_APPLICATION_TYPES, 
                    index, numberOfForms, addOwner}: FormDetails) => {
    return (
        <>
            {/* Show separator bar for every form except the first form. */}
            {(index > 0) && (
                <div className='separator-bar'></div>
            )}

            {/* Only show the form's title in the first form. */}
            {(index === 0) && (
                <div className='section-title'>
                    Legal Name of Owner(s)
                </div>
            )}

            {/* Form */}
            <form>
                <div className="form-row">
                    <div className='form-field' style={{width: '40%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-name`}>
                            Name
                        </label>
                        <InputField 
                            required 
                            id={`credit-application-form-owner-${index+1}-name`}
                            name={`owner-${index+1}-name`}
                            altStyle='credit-app'
                        />
                    </div>
                    <div className='form-field' style={{width: '30%'}}>
                        <label htmlFor={`credit-application-form-owner-${index+1}-title`}>
                            Title
                        </label>
                        <InputField 
                            required 
                            id={`credit-application-form-owner-${index+1}-title`} 
                            name={`owner-${index+1}-title`} 
                            altStyle='credit-app'
                        />
                    </div>
                    <div className='form-field' style={{width: '30%'}}>
                        <label htmlFor={`credit-application-form-owner-${index+1}-percent`}>
                            Percentage of Ownership
                        </label>
                        <InputField
                            required
                            id={`credit-application-form-owner-${index+1}-percent`}
                            type='number'
                            maxLength={3}
                            name={`owner-${index+1}-percent`}
                            altStyle='credit-app'
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className='form-field' style={{width: '40%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-email`}>
                            Email Address
                        </label>
                        <InputField 
                            id={`credit-application-form-owner-${index+1}-email`}
                            type='email' name={`owner-${index+1}-email`}
                            altStyle='credit-app' 
                        />
                    </div>
                    <div className="form-field" style={{width: '30%'}}>
                        <label htmlFor={`credit-application-owner-${index+1}-cell`}>
                            Cell Phone
                        </label>
                        <InputField
                            required
                            id={`credit-application-form-owner-${index+1}-cell`}
                            type='tel'
                            name={`owner-${index+1}-cell`}
                            altStyle='credit-app'
                        />
                    </div>
                    <div className="form-field" style={{width: '30%'}}>
                        <label htmlFor={`credit-application-form-owner-${index+1}-phone`}>
                            Office Phone
                        </label>
                        <InputField 
                            id={`credit-application-form-owner-${index+1}-phone`}
                            type='tel'
                            name={`owner-${index+1}-phone`}
                            altStyle='credit-app'
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-field" style={{width: '50%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-address-line1`}>
                            Street Address
                        </label>
                        <InputField
                            id={`credit-application-form-owner-${index+1}-address-line1`}
                            name={`owner-${index+1}-address-line1`}
                            altStyle='credit-app'
                        />
                    </div>
                    <div className="form-field" style={{width: '50%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-address-line2`}>
                            Street Address Line 2
                        </label>
                        <InputField
                            id={`credit-application-form-owner-${index+1}-address-line2`}
                            name={`owner-${index+1}-address-line2`}
                            altStyle='credit-app'
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-field" style={{width: '50%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-city`}>
                            City / State
                        </label>
                        <InputField
                            required
                            id={`credit-application-form-owner-${index+1}-city`}
                            name={`owner-${index+1}-city`}
                            altStyle='credit-app city' 
                        />
                        <SelectField
                            required
                            id={`credit-application-form-owner-${index+1}-state`}
                            name={`owner-${index+1}-state`}
                            options={STATE_ABBREVIATIONS}
                            containerClassName='credit-app-state light-grey-bg' 
                        />
                    </div>
                    <div className="form-field" style={{width: '25%'}}>
                        <label htmlFor={`credit-application-form-owner-${index+1}-zip`}>
                            Zip Code
                        </label>
                        <InputField
                            required
                            type='number'
                            maxLength={5}
                            id={`credit-application-form-owner-${index+1}-zip`}
                            name={`owner-${index+1}-zip`}
                            altStyle='credit-app'
                        />
                    </div>
                    <div className="form-field" style={{width: '25%'}}>
                        <label htmlFor={`credit-application-form-owner-${index+1}-phone`}>
                            Phone
                        </label>
                        <InputField
                            required
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            type="tel"
                            id={`credit-application-form-owner-${index+1}-phone`}
                            name={`owner-${index+1}-phone`}
                            altStyle='credit-app'
                        />
                    </div>
                </div>
                <div className="form-row" style={{justifyContent: 'flex-start'}}>
                    <div className="form-field" style={{width: '50%'}}>
                        <label className='width-fixed'
                               htmlFor={`credit-application-form-owner-${index+1}-credit-type`}>
                            Credit Application Type
                        </label>
                        <SelectField
                            required
                            id={`credit-application-form-owner-${index+1}-credit-type`}
                            name={`owner-${index+1}-credit-type`}
                            options={CREDIT_APPLICATION_TYPES}
                            containerClassName='credit-app-credit-type light-grey-bg'
                        />
                    </div>
                    <div className="form-field" style={{width: '25%', marginLeft: '-10px'}}>
                        {/* Not sure what this field is for but it's in the design doc. */}
                        <InputField
                            required id={`credit-application-form-owner-${index+1}-placeholder`}
                            name={`owner-${index+1}-placeholder`}
                            altStyle='credit-app'
                        />
                    </div>
                </div>
            </form>

            {/* Button to add another owner - show only on the last form on the page. */}
            {(index === numberOfForms - 1) && (
                <div className="form-row" style={{justifyContent: 'flex-start', marginTop: '12px'}}>
                    <div className='width-fixed'>
                        {/* This div is for alignment purposes only. */}
                    </div>
                    <div className="form-field">
                        <BlockButton buttonStyle="ghost-dark less-pad" onClick={() => addOwner()}>
                            <div className='icon-then-text'>
                                <Icon name='plus-circle' />
                                Add Another Owner
                            </div>
                        </BlockButton>
                    </div>
                </div>
            )}
        </>
    )
}
