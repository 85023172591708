import React from "react";
import "./jesse-testing.scss";
import axios, { Axios } from "axios";
import { BlockButton } from "../../shared/buttons/block-button/BlockButton";
import { PillButton } from "../../shared/buttons/pill-button/PillButton";
import { SetTogglePriceDark, SetTogglePriceWhite, SetToggleMlDark, SetToggleMlWhite, SetToggleButton } from "../../shared/buttons/set-toggle-button/SetToggleButton";
import { TestButton } from "../../shared/buttons/test-button/TestButton";
import { TestButton2, TestButton3 } from "../../shared/buttons/test-button2/TestButton2";
import { ProductCard } from "../../shared/product-card/ProductCard";
import { MyAccountDropdown } from "../../layout/navbar/my-account-dropdown/MyAccountDropDown";
import { ImageCarousel } from "../../shared/carousels/image-carousel/ImageCarousel";
import { ImageCarouselNew } from "../../shared/carousels/image-carousel/ImageCarouselNew";
import { CarouselDots } from "../../shared/carousels/image-carousel/CarouselDots";
import { ImageCarousel2 } from "../../shared/carousels/image-carousel2/ImageCarousel2";
import { ImageCarousel3 } from "../../shared/carousels/image-carousel-3/ImageCarousel3";
import { CarouselTestMain } from "../../shared/carousels/image-carousel2/CarouselTestMain";
import { DoctorSlider } from "../../shared/carousels/doctor-carousel/DoctorSlider";
import { DoctorSliderIdle } from "../../shared/carousels/doctor-carousel/DoctorSliderIdle";
import { MultiAnimTest } from "../../shared/carousels/doctor-carousel/MultiAnimTest";
import { RadioSelector } from "../../shared/buttons/radio-selector/RadioSelector";
import { CheckBox } from "../../shared/buttons/check-box/CheckBox";
import { PageNumbers } from "../../shared/buttons/page-numbers/PageNumbers";
import { NavDropdown } from "../../layout/navbar/nav-account-dropdown/NavDropdown";
import { MedPracButton, MedPracButtonCreditCard } from "../../shared/buttons/med-prac-button/MedPracButton";
import { ManualCarousel } from "../../shared/carousels/manual-carousel/ManualCarousel";
import { CheckoutGrid, ProceduresGrid, TagsGrid, LeaderboardGrid, } from "../../shared/tables/WebGrid";
import { Table } from "../../shared/tables/Table";
import { HeaderBanner } from "../../layout/header-banner/HeaderBanner";
import { Navbar } from "../../layout/navbar/Navbar";
import { ProgressBar } from "../../shared/progress-bar/ProgressBar";
import PrintOnlyContent from "../../shared/print/PrintOnlyContent";


type Props = {};
type State = {};


export class JesseTestingPage extends React.Component<Props, State> {



  mockDataTable = [
    { orderNumber: "bob1", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob2", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob3", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob4", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob5", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob6", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob7", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob8", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob9", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob10", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob11", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob12", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob13", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob14", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob15", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob16", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob17", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob18", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob19", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
    { orderNumber: "bob20", date: "01-01-1995", amount: "5", status: "pending", orderLink: 'some link' },
  ]




  render() {
    return (
      <div className="body-container jesse-testing-page">
        <PrintOnlyContent title={true}>What's up Jesse?</PrintOnlyContent>
        <p>JESEE TESTING</p>




        <ImageCarousel />   <br></br><br></br><br></br><br></br><br></br>

        <ImageCarouselNew />
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br>


        <CarouselDots /><br></br><br></br><br></br><br></br><br></br>




        <ImageCarousel2
          productImageSrc1='amniotextbox'
          productImageSrc2='amniotextbox'
          productImageSrc3='amniotextbox'
          productImageSrc4="amniotextbox" /><br></br><br></br><br></br><br></br><br></br>




        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

        <MyAccountDropdown />

        <NavDropdown
          navName="My Account"
          navLinks={["My Profile", "Recent Orders", "Tissue Inventory", "Registration Forms"]}
          openLeft={true}
        />

        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>



        <DoctorSliderIdle /> <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

        <ManualCarousel name="test name" />





        <TestButton>TEST 1</TestButton>                          <br></br><br></br>

        <TestButton3>Test 2</TestButton3>                            <br></br><br></br>

        <BlockButton buttonStyle="purple">JOIN</BlockButton>              <br></br><br></br>
        <BlockButton disabled buttonStyle="purple1">JOIN</BlockButton>      <br></br><br></br>
        <BlockButton buttonStyle="dark">LOGIN</BlockButton>                   <br></br><br></br>
        <BlockButton disabled buttonStyle="black1">LOGIN</BlockButton>          <br></br><br></br>
        <BlockButton buttonStyle="ghost-purple">WEBSITE</BlockButton>             <br></br><br></br>
        <BlockButton disabled buttonStyle="white1">WEBSITE</BlockButton>            <br></br><br></br>
        <PillButton buttonStyle="white">JOIN</PillButton>                             <br></br><br></br>
        <PillButton disabled buttonStyle="white">Disabled</PillButton>                 <br></br><br></br>
        <PillButton buttonStyle="white gradient">PROFILE</PillButton>                 <br></br><br></br>
        <MedPracButton>CLINIC ADDRESS</MedPracButton>                               <br></br><br></br>
        <MedPracButton>CLINIC NPI</MedPracButton>                                 <br></br><br></br>
        <MedPracButtonCreditCard />                                              <br></br><br></br>
        <MedPracButton>BANK TRANSFER</MedPracButton>                            <br></br><br></br>
        <MedPracButton>TERMS ACCOUNT</MedPracButton>                           <br></br><br></br>
        <SetToggleButton price="$300" count="25+" countColor="white1 whitetoggletext" buttonStyle=" white1" />    <br></br><br></br>
        <SetToggleButton price="$400" count="11 - 24" countColor="white1 whitetoggletext" buttonStyle=" white1" />    <br></br><br></br>
        <SetToggleButton price="$500" count="1 - 10" countColor="darktoggletext" buttonStyle="price-toggle" />    <br></br><br></br>
        <SetToggleMlWhite>1 ml</SetToggleMlWhite>                          <br></br><br></br>
        <SetToggleMlWhite>2 ml</SetToggleMlWhite>                         <br></br><br></br>
        <SetToggleMlDark>3 ml</SetToggleMlDark>                          <br></br><br></br>
        <SetToggleMlWhite>4 ml</SetToggleMlWhite>                       <br></br><br></br><br></br>
        <RadioSelector name='test'>Label</RadioSelector>                          <br></br><br></br>
        <RadioSelector name='test'>Label</RadioSelector>                        <br></br><br></br><br></br>
        <CheckBox>Label</CheckBox>                                <br></br><br></br>
        <CheckBox>Label</CheckBox>                              <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <ProgressBar whichStep={3} numberOfSteps={3} />        <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        {/* <ProgressBar whichStep={2} numberOfSteps={4} />       <br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}
        {/* <PageNumbers currentPage={7} totalPages={80} />                                      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}



        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

        <CheckoutGrid
          col1="Product"
          col2="Sku"
          col3="Qty"
          col4="Amount"
          col5="Delivery Date"
        />
        <br></br><br></br><br></br><br></br>

        <ProceduresGrid
          col1="Order Number"
          col2="Date"
          col3="Payment Type"
          col4="Amount"
          col5=""
        />
        <br></br><br></br><br></br><br></br>

        <TagsGrid
          col1="Index"
          col2="Start Date"
          col3="End Date"
          col4="Clinic Name"
          col5="Doctor"
        />
        <br></br><br></br><br></br><br></br>

        <LeaderboardGrid
          col1="Index"
          col2="Doctor"
          col3="Volume($)"
          col4="Percentage Change"
        />


        <br></br><br></br><br></br><br></br>


        <Table
          visibleRows={5}
          data={this.mockDataTable}
          columnNames={["Order Number", "Date", "Amount", "Status", "Order Link"]}
          pagingOption="paging-arrow"
        />

        <br></br><br></br><br></br><br></br>






        <ProductCard
          vendor="Vendor"
          brand="Brand"
          product="PRODUCT NAME"
          description="Maecenas ullamcorper a ligula in rhoncus.  Sed libero tellus, elementum vel felis ac, suscipit faucibus srat.  Nulla eu nisi ac elit semper dapibus interdum quis urna."
          productImage="productimage1"
        />
        <br></br><br></br><br></br><br></br><br></br><br></br>



        <Navbar navType='default' />

        <HeaderBanner
          imageName="microscope"
          imageText="CONTACT"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="opbedlower"
          imageText="ABOUT"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="opbedmid"
          imageText="SEARCH"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="skelpelvis"
          imageText="CATEGORY 1"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="CONFIRM EMAIL ADDRESS"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="CHECK OUT"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="ORDER PLACED"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="HELLO DOCTOR_NAME"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="opbedtop"
          imageText="VENDOR APPLICATION"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="CREATE ACCOUNT"
          imageSpacerColor='BLUE'
        />

        <Navbar navType='default' />
        <HeaderBanner
          imageName="doctorpen"
          imageText="MEDICAL PRACTICE"
          imageSpacerColor='BLUE'
        />


      </div>
    );
  }
}

