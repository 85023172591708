import { Link } from "react-router-dom";

import { ProductCard } from "../../../shared/product-card/ProductCard";

import { ProductData } from '../../../shared/modal/AddProductModal';

import './products-list.scss';
import React, { useEffect } from "react";



interface ProductsListProps {
  condensed: boolean;
  productsInfo: ProductData[];
}



export const ProductsList2 = ({ condensed, productsInfo }: ProductsListProps) => {

  // Truncate the text at charLimit, then keep going backward until it hits a space char,
  // then add ellipsis if the text got shorter.
  const truncateToSpace = (text: string, charLimit: number): string => {
    if (!text) return '';
    const originalText = text
    let newText = text.slice(0, charLimit)
    if (newText.length === originalText.length) return newText;
    while (true) {
      if (!newText.length) return ''; // weird edge case
      if (newText[newText.length - 1] === ' ') {
        newText = newText.slice(0, -1) + ' ...'
        return newText
      } else {
        newText = newText.slice(0, -1)
      }
    }
  }
  

  return (
    <div className='product-list-wrapper'>
      {Array.from(Array(productsInfo.length)).map((_, x) => (
        <React.Fragment key={x}>
          {(x % 2 === 0) ? (
            <div className='product-display-grid condense'>
              <Link to={`/public/products/${productsInfo[x].id}`} className='product-card-link'>
                <ProductCard
                  product={productsInfo[x].name_display || productsInfo[x].name}
                  brand={productsInfo[x].brand}
                  vendor={productsInfo[x].business_name_display || productsInfo[x].business_name}
                  description={truncateToSpace(productsInfo[x].description_1, 100)}
                  productImage="PRODUCT_1"
                  productId={productsInfo[x].id}
                />
              </Link>
              {(productsInfo.length > x + 1) ? (
                <Link to={`/public/products/${productsInfo[x+1].id}`} className='product-card-link'>
                  <ProductCard
                    product={productsInfo[x+1].name_display || productsInfo[x+1].name}
                    brand={productsInfo[x+1].brand}
                    vendor={productsInfo[x+1].business_name_display || productsInfo[x+1].business_name}
                    description={truncateToSpace(productsInfo[x+1].description_1, 100)}
                    productImage="PRODUCT_1"
                    productId={productsInfo[x+1].id}
                  />
                </Link>
              ) : null}
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  )
}