/** 
 * Mock data and mock API endpoints for local testing and temporary placeholders.
 * 
 * TOOD: Get rid of all of these.
 */

// TODO: setup and test failed API calls behavior and UI display/messaging.

import { PaymentAccountDetails, PaymentType } from '../components/pages/cart/CartShared'
import { MOCK_carouselCardsData, CarouselCardData,
         MOCK_stateTaxRates,
         MOCK_shippingPriceByState,
         MOCK_paymentAccounts,  
        } from './_mockApi_data'






/**
 * MOCK API functions
 */


export const MOCK_API_get_carousel_cards_data = (): Promise<CarouselCardData[]> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(MOCK_carouselCardsData), 0)
    })
}


// Get formatted date String, skipping weekends
// randomizeAmount is how much to vary +/- days randomly
export const MOCK_API_get_delivery_date = (
    businessDaysFromNow: number = 5, 
    randomizeAmount: number = 2 // this number needs to be non-zero!!
): Promise<string> => {
    const date = new Date()
    const randNum = (Math.random() * (randomizeAmount * 2.0)) - randomizeAmount
    
    // Add 5 days to today, plus 1 extra day if today is Sunday, and 2 extra days if today is Saturday.
    date.setDate(
        date.getDate()
        + businessDaysFromNow
        + (randomizeAmount ? Math.round(randNum) : 0)
        + Number((date.getDay() === 0 || date.getDay() === 6))
        + Number((date.getDay() === 6))
    )

    const dayOfWeek = date.toLocaleDateString('en-US', {
        weekday: 'short',
    })

    const dateStr = dayOfWeek + ', ' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()

    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(dateStr), 0)
    })
}


// Get DECIMAL (not percent) tax rate for a state
export const MOCK_API_get_state_tax_rate = (state_abbreviation: string): Promise<number> => {
    const state = state_abbreviation.toUpperCase().trim()
    const decimal_rate = MOCK_stateTaxRates[state] / 100
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(decimal_rate), 0)
    })
}


// Get shipping price in dollars/cents for a particular state.
export const MOCK_API_get_shipping_price = (state_abbreviation: string): Promise<number> => {
    const state = state_abbreviation.toUpperCase().trim()
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(MOCK_shippingPriceByState[state]), 0)
    })
}




// Get list of user's saved payment accounts
export const MOCK_API_get_saved_payment_accounts = (type: PaymentType): Promise<PaymentAccountDetails[]> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(MOCK_paymentAccounts[type]), 0)
    })
}