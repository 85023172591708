/**
 * General functions for cookies.
 */


import { CookieDict } from "./Cart_Types"


// ============================================================================
// Cookie names enum
// ============================================================================
export enum CookieNames {
    // APP VERSION
    medappversion = 'medappversion',
    // Contents of shopping cart.
    cart = 'cart',
    // Order ID that is generatd on the checkout completion/success page.
    orderid = 'orderid',
    // Note that user entered on his checkout/shopping-cart page.
    cartnote = 'cartnote,',
    // ID of the user's selected shipping address.
    addressid = 'addressid',
    // ID of the user's selected payment method.
    paymentmethodid = 'paymentmethodid',
    // ID of the user's selected payment account.
    paymentaccountid = 'paymentaccountid',
    // Total price of cart. 
    // TODO: this is def unsafe for going live.
    carttotalprice = 'carttotalprice'
}



// ============================================================================
// General cookie functions.
// ============================================================================

// Get all cookies in the form of dictionary.
export const getAllCookies = (): CookieDict => {
    const cookieList = document.cookie.split(';').map(cookie => cookie.trim())
    const cookieDict = Object.assign({}, ...cookieList.map((x) => {
        const kv = x.split('=')
        return {[kv[0]]: kv[1]}
    }))
    return cookieDict
}
// Get the value of a cookie.
export const getCookie = (name: string) => {
    const cookieDict = getAllCookies()
    return cookieDict[name]
}
// Make the cookie, or update its value if the cookie already exists.
export const makeOrUpdateCookie = (name: string, value: string, expireDays: number = 30) => {
    const expireDate = new Date()
    expireDate.setDate(expireDate.getDate() + expireDays)
    document.cookie = name + '=' + value + '; expires=' + expireDate.toUTCString() + ';path=/'
}
// Delete the cookie, if it exists.
export const deleteCookie = (name: string) => {
    makeOrUpdateCookie(name, '', -30) // Negative expiry date deletes it.
}



