import { useState } from 'react'
import { Icon } from '../../../shared/icon/Icon';
import './header-search-dropdown.scss';



interface Props {
    SEARCH_CATEGORIES: string[];
    searchCategory: string;
    setSearchCategory: React.Dispatch<React.SetStateAction<string>>;
}
/**
 * Shows/hides search bar's category-selection dropdown.
 */
export const HeaderSearchDropdown = ({ 
    SEARCH_CATEGORIES, searchCategory, setSearchCategory 
}: Props) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const toggleMenu = () => {
        setShowDropdown(prev => !prev)
    }

    return (
        <>
        <div className="search-dropdown">
            <div className='active-category'>
                {searchCategory}
                <Icon name='drop-arrow'/>
            </div>
            <div className={`dropdown-menu-container ${showDropdown ? 'show' : ''}`} 
                 onClick={() => toggleMenu()}
            >
                <div className='dropdown-rows'>
                    <div className='dropdown-spacer'></div>
                    <div className='dropdown-rows-inner'>
                        <div className='dropdown-row title'>
                            Category
                        </div>
                        {SEARCH_CATEGORIES.map((category: string) => (
                            <div
                                key={category}
                                className={
                                    `dropdown-row ${category === searchCategory ? 'current' : ''}`
                                }
                                onClick={() => setSearchCategory(category)}
                            >
                                {category}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

        {/* Overlay to hide the menu when moving mouse away from the dropdown menu. */}
        <div className={`full-screen-overlay ${showDropdown ? '' : 'hidden'}`} 
             onMouseEnter={(() => setShowDropdown(false))}
        ></div>
        </>
    )
}