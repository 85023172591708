/**
 * Step 6 of Supplier Registration - this is basically just the registration-completion
 * confirmation page.
 */

import { useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account';
import { SessionPacket } from '../../../../types/session'

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { Greyback } from '../../../shared/form/greyback/Greyback';
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'

import './supplier-registration.scss';



export const SupplierRegistration6 = () => {
    const STEP = 6

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Modal to show loading status.
    const [showSpinner, setShowSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')

    // Auth/session functions
    const { checkLogin, checkLoginNow, getUsernameAndIdToken } = useContext(AccountContext)

    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // Message based on referrer
        setReferrerMessage(qp.reason === 'reg-incomplete' ? 'Please complete your registration' : '')
    }, [])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (!checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/supplier-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin])



    return (
        <div className="body-container supplier-register-page-layout p6">
            <RegistrationLoadingModal
                show={showSpinner}
                setShow={setShowSpinner}
                titleText={spinnerText}
            />

            <div className="form-container-top p6">
                {/* Closable message at top of page */}
                <Greyback
                    closable={true}
                    style={{ 
                        display: 'flex', flexDirection: 'column', alignItems: 'center', 
                        textAlign: 'center', fontSize: '1.6rem', color: 'rgba(0,0,8,.8)',
                    }}
                >
                    <span style={{ fontSize: '1.9rem' }}>
                        REGISTRATION COMPLETE!
                    </span>
                    <br/><br/><br/>
                    <div style={{ lineHeight: 2, fontSize: '2rem', fontWeight: 400, textAlign: 'center' }}>
                        Your business and products are now searchable in the nav bar. You can modify 
                        your business and product details at any time by clicking over to your account
                        page. 
                    </div>
                    <br/><br/>
                    <Link to={`/account`}>
                        <BlockButton buttonStyle="purple">
                            MY ACCOUNT
                        </BlockButton>
                    </Link>
                </Greyback>

                {/* Some pictures of upcoming events */}
                <div className="header p6">
                    EVENTS
                </div>
                <div className='events-wrapper'>
                    <div className='events-gallery'>
                        <div className='event clickable main-event'>
                            <div className='bg' />
                            <p>WEBINAR / EVENT</p>
                        </div>
                        <div className='sub-events'>
                            <div className='event clickable'>
                                <div className='bg' />
                                <p>WEBINAR / EVENT</p>
                            </div>
                            <div className='event clickable'>
                                <div className='bg' />
                                <p>WEBINAR / EVENT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer 
                hideProgressBar={true}
                position='bottom-center'
                style={{textAlign: 'center', cursor: 'default'}}
            />
        </div>
    )
}





