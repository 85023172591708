import "./credit-application.scss"



interface Props {
	activeStep: number;
}
/**
 * Credit Application page - step 4.
 * 
 * This is where user signs the Docusign form.
 */
export const CreditApplicationStep4 = ({ activeStep }: Props) => {
    const STEP: number = 4

    return (
        <div style={{display: `${(activeStep === STEP) ? 'block' : 'none'}`}}>
            <div className="form-border">
                Placeholder for Docusign, PandaDoc, or similar.
            </div>
        </div>
    )
}















