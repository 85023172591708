import './doctor-slider-idle.scss';
import React from 'react';
import ImageOne from "./images/doctorimage1.png";
import ImageTwo from "./images/doctorimage2.png";
import ImageThree from "./images/doctorimage3.png";
import { PillButton } from '../../buttons/pill-button/PillButton';



type Props = {
  doctorname?: string,
  practice?: string,
  description?: string,
  //  productImage?: string,
};

type State = {};


export class DoctorSliderIdle extends React.Component<Props, State>  {


  truncateText(text: string = '', charLimit = 200) {
    if (text.length < charLimit) return text;
    return text.substring(0, charLimit) + '...';
  }

  render() {

    return (

      <div className="container-ds-idle">
        <div className="content-ds-idle">
          <div className="carousel-content-ds-idle">
          </div>
          <div className="doctorslideshow-idle">
            <div className="doctorslideshow-wrapper-idle">
              <div className="doctorslide-idle-background">
                <div className="doctorcard-idle-background">
                </div>
              </div>
              <div className="doctorslide-idle">
                <div className="doctorcard-idle">
                  <img src={ImageOne} className="slide-img-ds-idle" />
                  <ul>
                    <li className="item-li-group productname">{this.truncateText(this.props.doctorname)}</li>
                    <li className="item-li-group brandname">{this.truncateText(this.props.practice)}</li>
                    <li className="item-li-group productdescription">{this.truncateText(this.props.description)}</li>
                    <li><PillButton buttonStyle="white gradient" style={{ width: '270px' }}>VIEW PROFILE</PillButton></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}