import './page-numbers.scss';

interface Props {
  currentPage: number,
  totalPages: number,
  setPage: Function,
  style?: any,
}

export const PageNumbers = ({currentPage, totalPages, setPage, style}: Props) => {
    let start = currentPage - 2
    const pagesToShow = [start, start + 1, start + 2, start + 3, start + 4] 

    return (
        <div className="div-contain-page-numbers" style={style}>
            <div className="arrow-box" 
                 onClick={() => setPage((prev: number) => prev - 1)}
            >
                <div className="arrow-box arrow-prev" /></div>
                {pagesToShow.map((page, x) => (
                    <div key={String(page)} 
                         className={`numbers ${page === currentPage ? "active" : ''}`} 
                         onClick={() => setPage(page)}
                    >
                        {page}
                    </div>
                ))}
            <div className="arrow-box" 
                 onClick={() => setPage((prev: number) => prev + 1)}
            >
                <div className="arrow-box arrow-next"></div>
            </div>
        </div>
    )
}