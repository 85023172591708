import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from "react-router-dom";

import { MOCK_API_get_carousel_cards_data } from '../../../../api/mockApi'

import { Bodymap } from "../../../shared/body-map/Bodymap";
import { AutoCarousel } from "../../../shared/carousels/auto-carousel/AutoCarousel";
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { PillButton } from '../../../shared/buttons/pill-button/PillButton';
import { ProductsList } from "../products-list/ProductsList";
import { SkelHome } from '../../../shared/image-spans/SkelHome';

import parseErrorObject from '../../../../helpers/parseErrorObject';

import PrintOnlyContent from "../../../shared/print/PrintOnlyContent";

import './home-page.scss';


interface CarouselCardData {
    name: string;
    practice: string;
    description: string;
    imgSrc: string;
}

interface VendorDetails {
    id: number;
    active: boolean|null;
    sort_order: number|null;
    business_id: number;
    business_name: string;
    image_url: string;
    image_uploaded: boolean;
}


const BUSINESS_PAGE_BASE_URL = '/public/vendor-profile/'



const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL


// TODO: centralize this
const CLOUDFRONT_BASE_URL = (
    _ENV?.includes('local') ? 'https://d3ha0j1mj5tkmf.cloudfront.net' :
    _ENV === 'sit' ? 'https://dct02i3nq5zjx.cloudfront.net' :
    _ENV === 'dev' ? 'https://d2xfqhgrrcx6ir.cloudfront.net' :
    _ENV === 'prod' ? 'https://d2pn3lue5qzdfs.cloudfront.net' :
    '' // no fallback
)



export const HomePage = () => {

    // For body map
    const [showICD10Modal, setShowICD10Modal] = useState(false)
    // For "Our Vendors" section.
    const [featureVendorDetails, setFeatureVendorDetails] = useState([] as VendorDetails[])


    // Simulate API call to fetch data from db.
    const [MOCKcarouselCardsData, setMOCKcarouselCardsData] = useState([] as CarouselCardData[])
    MOCK_API_get_carousel_cards_data().then((data: CarouselCardData[]) => {
        setMOCKcarouselCardsData(data)
    })


    // Get data for "Our Vendors" section.
    useEffect(() => {
        axios.get(
            `${API_DB_URL}/public-web/home-vendors`
        ).then(async (res) => { 
            const vendorDeets = res.data || []
            setFeatureVendorDetails(res.data)
        }).catch(err => {
            const eMsg = parseErrorObject(err, '#12618')
            console.error(eMsg)
        })
    }, [])


    return (
        <div className='homepage-new'>
            <PrintOnlyContent title={true}>Medngine home page</PrintOnlyContent>
            <HeaderBanner
                bannerType={2}
                imageName="skelproducts"
                imageTextHomeLine="Wharton's Jelly Allograft"
                imageTextHomeLine2="Connective tissue supplements"
                imageSpacerColor='BLUE'
                scrollToTransparentRange={[140, 200]}
            />
            <div className="homepage-layout">
                <div className="body-container">
                    <ProductsList condensed={false} />
                </div>

                <div className="carousel-section-outer">
                    <div className="body-container-no-vert-pad carousel-section">
                        <div className="multicolor-headers">
                            <div className="header blue">MEDNGINE</div>
                            <div className="header">LEADERSHIP</div>
                        </div>
                        <div className="carousel-wrapper">
                            {(MOCKcarouselCardsData.length) ? (
                                <AutoCarousel
                                    cardData={MOCKcarouselCardsData}
                                    pillButton={
                                        <PillButton
                                            style={{ maxWidth: '240px' }}
                                            buttonStyle="white gradient"
                                        >
                                            VIEW PROFILE
                                        </PillButton>
                                    }
                                />
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>

                <SkelHome />

                <div className="body-container-no-vert-pad formwrapper-bottom">
                    <div className="multicolor-headers vendor-list">
                        <div className="header blue">OUR</div>
                        <div className="header">VENDORS</div>
                    </div>
                    <div className='subtitle'>
                        Regenative Labs products are manufactured in state-of-the-art ISO
                        Class 7 cleanrooms
                    </div>

                    <div className="vendors-list">
                        {featureVendorDetails.map((biz) => (
                            <Link key={String(biz.id)} to={BUSINESS_PAGE_BASE_URL + biz.business_id}>
                                <div className='vendor-box'>
                                    <div className='vendor-img-container noprint'>
                                        {/* Get the image from Cloudfront if it was uploaded to S3. */}
                                        <img 
                                            className='vendor-img' 
                                            src={(biz.image_uploaded ? CLOUDFRONT_BASE_URL : '') + biz.image_url} 
                                        />
                                    </div>
                                    <div className='vendor-name'>
                                        {biz.business_name}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
