import React from "react";
import "./testing.scss";
import axios, { Axios } from "axios";
import PrintOnlyContent from "../../shared/print/PrintOnlyContent";

type Props = {};
type State = {
  media: string[],
};


export class TestingPage extends React.Component<Props, State> {

  public state: State = {
    media: [],
  };
  private _api: Axios;

  constructor(props: Props) {
    super(props);
    this._api = axios.create({
      baseURL: 'https://api.medngine.com',
      headers: {
        'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
        'content-type': 'application/json',
      },
    });
  }


  componentDidMount() {
    /**
     * API page call:
     * /regenlabs/pagecomponent?slug=test
     * 
     * API content call by content item:
     * /regenlabs/contentitem/1
     * 
     */

    this._api.get('/regenlabs/pagecontent?slug=test').then(response => {
      this._getPageContent(response.data[0].page_content)
    })
      // failed api call
      .catch(err => {
        console.log(err);
      });
  }


  private _getPageContent(contentIds: number[]) {    
    const content = contentIds.map(contentId => {      
      return this._api.get('/regenlabs/contentitem/' + contentId);
    });

    Promise.all(content).then(contentItemResporses => {
      contentItemResporses.forEach(item => {        
        this.setState({
          media: [ ...this.state.media, item.data],
        });
      });
    })
    // contetn failed to be retreived
    .catch(err => {
      console.log('failed to get items');
      console.log(err);
    });
  }


  private _displayImages() {
    console.log('images loading');
    return this.state.media.map((mediaItem: any) => <img key={mediaItem.id} src={mediaItem.media} alt={mediaItem.name} />);
  }


  render() {
    return (
      <div className="body-container testing-page">
        <PrintOnlyContent title={true}>Medngine testing page</PrintOnlyContent>
        <div className="">

          <h1>API Testing Page</h1>


          { this._displayImages() }

        </div>
      </div>
    );
  }
}















