import './carousel-dots.scss';
import React from 'react';
import ImageOne from "./images/c-image1.png";
import ImageTwo from "./images/c-image2.jpg";
import ImageThree from "./images/c-image3.jpg";





export const CarouselDots = (props: any) => {
  return (

    <div className="slide-body">
      <div className="slider">
        <input type="radio" name="slider" title="slide1" className="slider__nav" />
        <input type="radio" name="slider" title="slide2" className="slider__nav" />
        <input type="radio" name="slider" title="slide3" className="slider__nav" />
        <div className="slider__inner">
          <div className="slider__contents">
            <img src={ImageOne} className="slider__imgsize" />
          </div>
          <div className="slider__contents">
            <img src={ImageTwo} className="slider__imgsize" />
          </div>
          <div className="slider__contents">
            <img src={ImageThree} className="slider__imgsize" />
          </div>
          <div className="slider__contents"><i></i>
          </div>
        </div>
      </div>
    </div>
  )
}