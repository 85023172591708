import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'

import { AccountContext } from '../../../auth/Account';
import { useUserBusinessId } from '../../../../hooks/useAuthCredentials'
import { SessionPacket } from '../../../../types/session'
import { useQueryParams } from '../../../../hooks/useQueryParams'

import { AddProductModal, ProductData, DEFAULT_PRODUCT_DATA } from '../../../shared/modal/AddProductModal';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { HeaderBanner } from '../../../layout/header-banner/HeaderBanner';
import { PreviewModeMessage } from '../../../shared/preview-mode-message/PreviewModeMessage';
import { ProductUploadGreyback } from '../../../shared/form/greyback/ProductUploadGreyback';
import { ProgressBar } from '../../../shared/progress-bar/ProgressBar';
import { RegistrationLoadingModal } from '../../../shared/modal/RegistrationLoadingModal'

import { buildPath, qsToDict } from '../../../../helpers/urls'
import parseErrorObject from '../../../../helpers/parseErrorObject';

import './supplier-registration.scss';



// Environment vars
const _ENV = process.env.REACT_APP__ENV
const API_DB_URL = process.env.REACT_APP_API_DB_URL
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL

// TODO: centralize this
const S3_BASE_URL = (
    _ENV?.includes('local') ? 'https://mr-s3-public-local.s3.amazonaws.com' :
    _ENV === 'sit' ? 'https://mr-s3-public-sit.s3.amazonaws.com' :
    _ENV === 'dev' ? 'https://mr-s3-public-dev.s3.amazonaws.com' :
    _ENV === 'prod' ? 'https://mr-s3-public-prod.s3.amazonaws.com' :
    '' // no fallback
)



export const SupplierRegistration5 = () => {
    const STEP = 5

    // State vars
    const [referrerMessage, setReferrerMessage] = useState('')
    const [hideAllErrors, setHideAllErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // Modal to show loading status.
    const [showProductsLoadingSpinner, setShowProductsLoadingSpinner] = useState(true)
    // Show spinner in the card index specified. 
    // - Use `-1` to not show any spinners.
    // - Use `null` to show spinner for the "Add Product" card.
    const [showAddingProductSpinner, setShowAddingProductSpinner] = useState<number|null>(-1) 
    const [showNextStepSpinner, setShowNextStepSpinner] = useState(false)
    const [spinnerText, setSpinnerText] = useState('Validating')
    // Session object which will be needed every time an image is added.
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    // Error messages for fields
    const [imageErrorMessage, setImageErrorMessage] = useState('')
    // Modal for adding products
    const [showAddProductModal, setShowAddProductModal] = useState(false)
    // Product ID that will be used for displaying proper content in AddProductModal
    const [productId, setProductId] = useState(0)
    // For triggering toaster when product has been successfully added from AddProductModal.tsx.
    const [productAdded, setProductAdded] = useState(false)
    // Product IDs that are already saved in DB, to display images for.
    const [productsList, setProductsList] = useState<ProductData[]>([])
    // Data to prepopulate inputs in AddProductModal
    const [productData, setProductData] = useState<ProductData>(DEFAULT_PRODUCT_DATA)
    // Trigger loading a new cache-breaked image for all cards when a product's primary image is changed.
    const [primaryImgChanged, setPrimaryImgChanged] = useState(false)
    // Trigger for refreshing the products list after a product has been deleted (and possibly for added too?).
    const [updateProductList, setUpdateProductList] = useState(false)

    // Auth/session functions
    const { checkLogin, getUsernameAndIdToken } = useContext(AccountContext)

    // Hook for getting user's business Id.
    const {userBizId, setUserBizId} = useUserBusinessId(sessionObj, setSessionObj, setErrorMessage)

    // To inddicate is this is an already-registered user who is just editing account info.
    const [isEdit, setIsEdit] = useState(false)

    // Preview mode where you can see and navigate btw steps, but not submit data.
    const { isPreviewMode } = useQueryParams()
    // Mock products list for preview mode
    const MOCK_PRODUCTS = [
        {
            id: 1,
            product_id: '100',
            name: 'Macbook Pro 16"',
            name_display: 'Macbook Pro',
            brand: 'Apple',
            headline_1: 'New MBP M1 chip',
            description_1: 'Desc: Guaranteed to heal your boredom',
            description_2: 'Uses: Guaranteed to heal your boredom',
            description_3: 'Research: Guaranteed to heal your boredom',
            discounts: {}, 
            business_name: 'Some Company Inc.',
            business_name_display: 'Some Company Inc.',
        }, {
            id: 2,
            product_id: '200',
            name: 'Basketball"',
            name_display: 'Basketball',
            brand: 'Wilson',
            headline_1: 'Therapy in the shape of a ball',
            description_1: 'Desc: Cures out-of-shape syndrome',
            description_2: 'Uses: Cures out-of-shape syndrome',
            description_3: 'Research: Cures out-of-shape syndrome',
            discounts: {}, 
            business_name: 'Some Company Inc.',
            business_name_display: 'Some Company Inc.',
        }, {
            id: 3,
            product_id: '300',
            name: 'Porsche 911"',
            name_display: 'Porsche 911',
            brand: 'Porsche',
            headline_1: 'Get your daily dose of car',
            description_1: 'Desc: Cures all ailments!',
            description_2: 'Uses: Cures all ailments!',
            description_3: 'Research: Cures all ailments!',
            discounts: {}, 
            business_name: 'Some Company Inc.',
            business_name_display: 'Some Company Inc.',
        }
    ]


    // On mount
    useEffect(() => {
        // Get query parameters
        const qp = qsToDict(window.location.search)

        // Show toaster if prev step was updated.
        if (qp.reason === 'updated') {
            toast('Updates have been saved', { autoClose: 3000 })
        }

        // For already-registered users who are just editing the page.
        if (qp.isEdit === 'true') {
            setIsEdit(true)
        }

        // Message based on referrer
        setReferrerMessage(qp.reason === 'reg-incomplete' ? 'Please complete your registration' : '')
    }, [])

    // Preview mode handling 
    useEffect(() => {
        if (isPreviewMode) {
            setProductsList(MOCK_PRODUCTS)
        }
    }, [isPreviewMode])

    // Just after mount, and after a new product was just added to the DB.
    //  - When we get the user's business Id from the backend, show the products that are saved 
    //    in the DB for that business. 
    //  - Remember that `userBizId` initializes to 0 (number).
    useEffect(() => {
        if (isPreviewMode) {
            setProductsList(MOCK_PRODUCTS)
        } else if (userBizId || updateProductList) { // don't add `productAdded` here.
            setShowProductsLoadingSpinner(true)
            // Get this business's products list from DB.
            axios.get(`${API_DB_URL}/get-business-products/${userBizId}`)
                .then((res) => {
                    const productsListResult: ProductData[] = res.data
                    if (productsListResult && Array.isArray(productsListResult)) {
                        setProductsList(productsListResult)
                    }
                }).finally(() => {
                    setShowProductsLoadingSpinner(false)
                    setUpdateProductList(false)
                })
        }
    }, [userBizId, productAdded, updateProductList, isPreviewMode])

    // Bump out users who aren't logged in.
    useEffect(() => {
        if (isPreviewMode !== false || !checkLogin) return
        checkLogin().then(isLoggedIn => {
            if (!isLoggedIn) {
                const qpOut = {reason: 'unverified', redirectTo: `/supplier-registration/${STEP}`}
                window.location.href = buildPath('/auth/login', qpOut)
            }
        })
    }, [checkLogin, isPreviewMode])

    // When Add Product button clicked, generate an empty product row in db that belongs to this 
    // user's business.
    const onClickAddProduct = useCallback(async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        index: number|null,
        _productId: number|null,
    ) => {
        if (isPreviewMode !== false) {
            setProductId(_productId || 0)
            setHideAllErrors(true)
            setShowAddingProductSpinner(-1)
            setShowAddProductModal(true)
            return
        }

        setShowAddingProductSpinner(index)
        setHideAllErrors(true)

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        if (session.error) { 
            setHideAllErrors(false)
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)
        
        // Config for API call.
        const config = {'headers': {'content-type': 'multipart/form-data'}}

        // Add username and idToken to form submission
        const formData = new FormData()
        formData.append('session', sessionJson)
        formData.append('businessId', String(userBizId || 0))

        // If product Id is provided, then GET the product details instead of creating a new product row.
        if (_productId) {
            if (isNaN(_productId) || !Number(_productId)) {
                throw new Error('System error #6847Fds: Please log out and login again.')
            }
            // Get the data for this _productId (this iterates to list to find the correct data
            // object in the `productData` list).
            for (let product of productsList) {
                if (product.id === _productId) {
                    setProductData(product)
                    break
                }
            }
            // Open the modal
            setProductId(_productId)
            setHideAllErrors(true)
            setShowAddingProductSpinner(-1)
            setShowAddProductModal(true)
        // For new products
        } else {
            setProductId(0)
            setProductData(DEFAULT_PRODUCT_DATA)
            // Create a new product row in db and return the row ID
            axios.post(`${API_DB_URL}/new-user-init-supplier/${STEP}a`, 
                Object.fromEntries(formData), 
                config
            ).then(async (res) => {
                // Success
                const productId = res.data?.productId
                const businessId = res.data?.businessId
                // Empty out product data because this is a new product.
                setProductData(DEFAULT_PRODUCT_DATA)
                // Check the returned data
                if (!productId || isNaN(productId) || !Number(productId)) {
                    throw new Error('System error #6847Fas: Please log out and login again.')
                }
                if (!businessId || isNaN(businessId) || !Number(businessId)) {
                    throw new Error('System error #6847Fbs: Please log out and login again.')
                }
                if (businessId !== userBizId) {
                    throw new Error('System error #6847Fcs: Please log out and login again.')
                }
                setProductId(productId)
                setHideAllErrors(true)
                setShowAddingProductSpinner(-1)
                setShowAddProductModal(true)
            }).catch(async (err) => {
                // Failure
                setShowNextStepSpinner(false)
                setShowAddProductModal(false)
                setErrorMessage('')
                setProductId(0)
                setTimeout(() => {
                    setHideAllErrors(false)
                    setErrorMessage(parseErrorObject(err, '#3563st5'))
                }, 350)
                setShowAddingProductSpinner(-1)
            })
        }
    }, [isPreviewMode, API_DB_URL, userBizId, productsList, sessionObj, getUsernameAndIdToken])

    // When the user clicks the "X" button on the product card to delete the product from db/S3/CF.
    // - Before this function actually runs, user will first have to confirm a popup, which is
    //   coded into ProductUploadGreyback.tsx.
    const onClickDeleteProduct = async (productId: number) => {
        if (isPreviewMode !== false) return

        if (!productId) {
            throw new Error('Error #4969as: Unknown error - please contact support with this error code.')
        }

        setHideAllErrors(true)

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        if (session.error) { 
            setHideAllErrors(false)
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)

        const formData = new FormData()
        formData.append('session', sessionJson)

        // Toast that product is deleting instead of showing spinner.
        toast('Deleting...')

        // Delete the product from DB first, then on success 
        // Create a new product row in db and return the row ID
        axios.post(`${API_DB_URL}/delete-product-all/${productId}`, 
            Object.fromEntries(formData), 
        ).then(async (res) => {
            // Edge case error
            if (res.data !== true) {
                throw new Error('Error #4969bs: Unknown error - please contact support with this error code.')
            }
        }).then(async () => {
            // Then delete the images from S3 and clear CF cache. Fail silently.
            await axios.delete(`${API_IMG_URL}/delete-all-images-for-product/${productId}`)
        }).then(() => {
            // Hide old toast and show the new one.
            toast.dismiss()
            toast('Product successfully deleted.')
        }).catch(async (err) => {
            // Failure
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#3569st5'))
            }, 350)
        }).finally(() => {
            setUpdateProductList(true)
        })


    }

    // When user closes modal, save or cancel, reset the productId so that the AddProductModal
    // will revert to default state.
    useEffect(() => {
        if (!showAddProductModal)  {
            setProductId(0)
        }
    }, [showAddProductModal])

    // After a product is successfully added via AddProductModal, show a toaster message.
    useEffect(() => {
        if (productAdded === true) {
            toast('Product changes have been saved')
            setProductAdded(false)
        }
    }, [productAdded])


    // When a product image is changed, a chain of events will trigger...
    //      (event triggered in [AddProductModal -> InputImageField] and then sent to [ProductUploadGreyback])
    // which will cause ProductUploadGreyback to run a cachebreaker on its <img> src for the primary
    // product card image.
    // When `primaryImgChanged` gets set to true, set it back to false after one render cycle 
    // so that it can be  triggered again subsequent updates to any card's primary image.
    useEffect(() => {
        if (primaryImgChanged) setPrimaryImgChanged(false)
    }, [primaryImgChanged])


    // Don't let user continue to next step unless they have at least one product.
    const continueToNextStep = useCallback(async (goBackToAccount: boolean) => {
        if (isPreviewMode !== false) return

        setHideAllErrors(true)

        if (!productsList.length) {
            setHideAllErrors(false)
            setErrorMessage('Please add at least one product before continuing')
            return
        }

        /** Return Cognito user info after validating */
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        if (session.error) { 
            setHideAllErrors(false)
            setErrorMessage(session.error)
            return
        }
        const sessionJson = JSON.stringify(session)

        const formData = new FormData()
        formData.append('session', sessionJson)

        // Finalize registration in db after confirming that at least one product + sku is in db for this user/business.
        axios.post(`${API_DB_URL}/new-user-init-supplier/${STEP}c`, 
            Object.fromEntries(formData), 
        ).then(async (res) => {
            // Edge case error
            if (res.data !== true) {
                throw new Error('Error #4969cs: Please logout then login again to continue.')
            }
        }).then(async () => {
            // Registration is complete - redirect to confirmation page.
            const qpOut = {
                reason: 'updated',
            }
            if (goBackToAccount) {
                window.location.href = buildPath(`/account`, qpOut)
            } else {
                window.location.href = `/supplier-registration/${STEP+1}`
            }
        }).catch(async (err) => {
            // Failure
            setErrorMessage('')
            setTimeout(() => {
                setHideAllErrors(false)
                setErrorMessage(parseErrorObject(err, '#3570st5'))
            }, 350)
        })
    }, [productsList])


    return (
        <>
        <HeaderBanner
            imageName="doctorpen"
            imageText={isEdit ? 'EDIT PRODUCTS' : 'SUPPLIER APPLICATION'}
            imageSpacerColor='BLUE'
        />

        {/* Modal to show loading spinner on registration pages. */}
        <RegistrationLoadingModal
            show={showNextStepSpinner}
            setShow={setShowNextStepSpinner}
            titleText={spinnerText}
        />

        {/* Modal for adding new products  */}
        <AddProductModal 
            show={showAddProductModal}
            setShow={setShowAddProductModal}
            sessionObj={sessionObj}
            setSessionObj={setSessionObj}
            getUsernameAndIdToken={getUsernameAndIdToken}
            userBizId={userBizId}
            productId={productId}
            setProductAdded={setProductAdded}
            productData={productData}
            setProductData={setProductData}
            setPrimaryImgChanged={setPrimaryImgChanged}
            businessType='supplier'
            isPreviewMode={isPreviewMode}
        />

        <PreviewModeMessage show={!!isPreviewMode} link={'/supplier-registration/1'} />

        <div className="supplier-register-page-layout p4">
            {/* Show different messages based on how the user got here */}
            {referrerMessage ? (
                <div className='registration-message'>
                    {referrerMessage}
                </div>
            ) : null}


            {/* Image error messages */}
            {(imageErrorMessage) ? (
                <div className='error-message'>
                    {imageErrorMessage}
                </div>
            ): null }

            {!isEdit ? (
                <div className="progress-bar-placement p3">
                    <ProgressBar whichStep={5} numberOfSteps={5} />
                </div>
            ) : null}
            
            <div className='body-container'>
                <div className="form-wrapper-uppermid p4">
                    <div className="header2 p4">
                        PRODUCTS
                    </div>

                    <div className='supplier-product-uploads-container'>
                        {/* Show loading circle before existing products are loaded from db/S3 */}
                        {(!isPreviewMode && showProductsLoadingSpinner) ? (
                            <div className='upload-product-greyback-container'> 
                                <ProductUploadGreyback
                                    containerStyle={{}}
                                    spinnerStyle={{display: showProductsLoadingSpinner ? 'block' : 'none'}}
                                    enableAddProductButton={false}
                                    onClickAddProductButton={() => {}}
                                    productIndex={null}
                                    productId={null}
                                    showAddProductIcon={false}
                                    addProductIconStyle={{}}
                                    productsList={[]}
                                />
                            </div>
                        ): null}
                        {/* Show all products for this business in their own card, each with a product image */}
                        {productsList.map((product, index) => (
                            <div className='upload-product-greyback-container' key={product.id}> 
                                <ProductUploadGreyback
                                    containerStyle={{}}
                                    spinnerStyle={{display: showAddingProductSpinner === index ? 'block' : 'none'}}
                                    productImgSrc={!isPreviewMode ? 
                                        `${S3_BASE_URL}/uploads/product/${product.id}/PRODUCT_1` // Always show first img of the product.
                                        : `${S3_BASE_URL}/uploads/product/0/PRODUCT_${index+1}` // Just cycle images for product 0 since it's just a demo.
                                    }
                                    productImgStyle={{opacity: showAddingProductSpinner === index ? 0.7 : 1}}
                                    enableAddProductButton={true}
                                    onClickAddProductButton={onClickAddProduct}
                                    productIndex={index}
                                    productId={product.id}
                                    showAddProductIcon={false}
                                    addProductIconStyle={{}}
                                    productsList={productsList}
                                    primaryImgChanged={primaryImgChanged}
                                    // deleteProduct={() => { alert('(run delete func)') }}
                                    deleteProduct={onClickDeleteProduct}
                                    isPreviewMode={isPreviewMode}
                                />
                                <div className='product-name'>
                                    {product.name}
                                </div>
                            </div>
                        ))}
                        {/* Card for adding new products */}
                        <div className='upload-product-greyback-container'> 
                            <ProductUploadGreyback
                                containerStyle={{}}
                                spinnerStyle={{display: showAddingProductSpinner === null ? 'block' : 'none'}}
                                productImgSrc=''
                                productImgStyle={{}}
                                enableAddProductButton={true}
                                onClickAddProductButton={onClickAddProduct}
                                productIndex={null}
                                productId={null}
                                showAddProductIcon={true}
                                addProductIconStyle={{opacity: showAddingProductSpinner === null ? 0.7 : 1}}
                                productsList={[]}
                                isPreviewMode={isPreviewMode}
                            />
                            <div className="add-product-call-to-action">
                                ADD PRODUCT
                            </div>
                        </div>
                    </div>
                </div>

                {/* Form (non-image) error messages */}
                {(!hideAllErrors && errorMessage) ? (
                    <div className='error-message'>
                        {errorMessage}
                    </div>
                ): null }

                {/* Back/Continue buttons */}
                <div className='buttons-container'>
                    {isEdit ? (
                        <BlockButton
                            type='button'
                            style={{ marginTop: '120px', marginBottom: '60px', width: '250px' }}
                            buttonStyle="ghost-purple"
                            onClick={() => window.location.href = '/account'}
                        >
                            CANCEL
                        </BlockButton>
                    ) : (
                        <Link to={`/supplier-registration/${STEP - 1}${isPreviewMode ? '?mode=preview' : ''}`}>
                            <BlockButton 
                                style={{ marginTop: '120px', marginBottom: '60px', width: '250px' }} 
                                buttonStyle="ghost-purple"
                            >
                                BACK
                            </BlockButton>
                        </Link>
                    )}

                    {(!isPreviewMode && !isEdit) ? (
                        <BlockButton 
                            type='button'
                            style={{ marginTop: '120px', marginBottom: '60px', width: '250px' }} 
                            buttonStyle="purple"
                            onClick={() => continueToNextStep(false)}
                        >
                            CONTINUE
                        </BlockButton>
                    ) : (isEdit ? (
                        <BlockButton
                            type='button'
                            style={{ marginTop: '120px', width: '250px' }}
                            buttonStyle="purple"
                            onClick={() => continueToNextStep(true)}
                        >
                            SAVE
                        </BlockButton>
                    ) : (
                        <BlockButton 
                            type='button' // IMPORTANT
                            style={{ marginTop: '120px', marginBottom: '60px', width: '250px' }} 
                            buttonStyle="purple"
                            disabled={true}
                        >
                            FINALIZE
                        </BlockButton>
                    ))}
                </div>

                {/* Toaster messages for when product is saved or deleted */}
                <ToastContainer 
                    hideProgressBar={true}
                    position='bottom-center'
                    style={{textAlign: 'center', cursor: 'default'}}
                />
            </div>
        </div>
        </>
    )
}