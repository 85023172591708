import './table.scss';



interface Props {
  col1: string,
  col2: string,
  col3: string,
  col4: string,
  col5?: string,
}

export const CheckoutGrid = ({ col1, col2, col3, col4, col5 }: Props) => {
  return (
    <div className="grid-container checkout-container">

      <div className="checkout-header-col1">{col1}</div>
      <div className="checkout-header-cols">{col2}</div>
      <div className="checkout-header-cols">{col3}</div>
      <div className="checkout-header-cols">{col4}</div>
      <div className="checkout-header-cols">{col5}</div>
      <div className="checkout-items-fill">Product 1</div>
      <div className="checkout-items-fill">Sku 1</div>
      <div className="checkout-items-fill">Qty 1</div>
      <div className="checkout-items-fill">Amount 1</div>
      <div className="checkout-items-fill">Delivery Date 1</div>
      <div className="checkout-items-fill">Product 2</div>
      <div className="checkout-items-fill">Sku 2</div>
      <div className="checkout-items-fill">Qty 2</div>
      <div className="checkout-items-fill">Amount 2</div>
      <div className="checkout-items-fill">Delivery Date 2</div>

    </div>
  );
}



export const ProceduresGrid = ({ col1, col2, col3, col4, col5 }: Props) => {
  return (
    <div className="grid-container procedures-container">

      <div className="procedures-header-cols">{col1}</div>
      <div className="procedures-header-cols">{col2}</div>
      <div className="procedures-header-cols">{col3}</div>
      <div className="procedures-header-cols">{col4}</div>
      <div className="procedures-header-cols">{col5}</div>
      <div className="procedures-items-fill">Order Number 1</div>
      <div className="procedures-items-fill">Date 1</div>
      <div className="procedures-items-fill">Payment Type 1</div>
      <div className="procedures-items-fill">Amount 1</div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill">Order Number 2</div>
      <div className="procedures-items-fill">Date 2</div>
      <div className="procedures-items-fill">Payment Type 2</div>
      <div className="procedures-items-fill">Amount 2</div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>

    </div>
  );
}


export const TagsGrid = ({ col1, col2, col3, col4, col5 }: Props) => {
  return (
    <div className="grid-container tags-container">

      <div className="tags-header-cols">{col1}</div>
      <div className="tags-header-cols">{col2}</div>
      <div className="tags-header-cols">{col3}</div>
      <div className="tags-header-cols">{col4}</div>
      <div className="tags-header-cols">{col5}</div>
      <div className="tags-items-fill">1</div>
      <div className="tags-items-fill">2022-03-22</div>
      <div className="tags-items-fill">2022-04-21</div>
      <div className="tags-items-fill">Clinic Name 1</div>
      <div className="tags-items-fill">Doctor Name 1</div>
      <div className="tags-items-fill">2</div>
      <div className="tags-items-fill">2022-03-22</div>
      <div className="tags-items-fill">2022-04-21</div>
      <div className="tags-items-fill">Clinic Name 2</div>
      <div className="tags-items-fill">Doctor Name 2</div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>
      <div className="tags-items-fill"></div>

    </div>
  );
}


export const LeaderboardGrid = ({ col1, col2, col3, col4 }: Props) => {
  return (
    <div className="grid-container leaderboard-container">

      <div className="leaderboard-header-cols">{col1}</div>
      <div className="leaderboard-header-cols">{col2}</div>
      <div className="leaderboard-header-cols">{col3}</div>
      <div className="leaderboard-header-cols">{col4}</div>
      <div className="leaderboard-items-fill">1</div>
      <div className="leaderboard-items-fill">Doctor Name 1</div>
      <div className="leaderboard-items-fill">Volume 1</div>
      <div className="leaderboard-items-fill">Percentage Change</div>
      <div className="leaderboard-items-fill">2</div>
      <div className="leaderboard-items-fill">Doctor Name 2</div>
      <div className="leaderboard-items-fill">Volume 2</div>
      <div className="leaderboard-items-fill">Percentage Change</div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>
      <div className="leaderboard-items-fill"></div>

    </div>
  );
}

export const VendorDashGrid = ({ col1, col2, col3, col4, col5 }: Props) => {
  return (
    <div className="grid-container procedures-container">

      <div className="procedures-header-cols">{col1}</div>
      <div className="procedures-header-cols">{col2}</div>
      <div className="procedures-header-cols">{col3}</div>
      <div className="procedures-header-cols">{col4}</div>
      <div className="procedures-header-cols">{col5}</div>
      <div className="procedures-items-fill">Order Number 1</div>
      <div className="procedures-items-fill">Date 1</div>
      <div className="procedures-items-fill">Payment Type 1</div>
      <div className="procedures-items-fill">Amount 1</div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill">Order Number 2</div>
      <div className="procedures-items-fill">Date 2</div>
      <div className="procedures-items-fill">Payment Type 2</div>
      <div className="procedures-items-fill">Amount 2</div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>
      <div className="procedures-items-fill"></div>

    </div>
  );
}