import React, { useContext, useState } from 'react'

import { AccountContext } from "../../../auth/Account"
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton'
import { HeaderBanner } from '../../../layout/header-banner/HeaderBanner'
import { InputField } from '../../../shared/form/input-field/InputField'
import { ProgressBar } from '../../../shared/progress-bar/ProgressBar'

import { formatAWsCognitoErrorMessage, convertAwsEmailToUsername } from '../../../../helpers/messageParserAws'

import './forgot-password.scss'



/**
 * Component page for users who forgo ttheir logins.
 */
export const ForgotPassword1 = () => {
    const { forgotPassword, confirmPassword } = useContext(AccountContext)

    const [step, setStep] = useState(1)

    const [email, setEmail] = useState('')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [newPassword, setNewPassword] = useState('')

    // UI success/error messages.
    const [forgotPassSuccessMessage, setForgotPassSuccessMessage] = useState('')
    const [forgotPassErrorMessage, setForgotPassErrorMessage] = useState('')
    const [confirmPassSuccessMessage, setConfirmPassSuccessMessage] = useState('')
    const [confirmPassErrorMessage, setConfirmPassErrorMessage] = useState('')


    // Tell AWS Cognito to send this user a forgot-password email.
    // The email will contain a code that the user can enter onto this page.
    // TODO: consider formatting the email so that user can click a link that will fill in 
    //       their verification code for them.
    const runForgotPassword = (
        emailAddr: string
    ) => {
        // Convert email to username. See why this is needed in this function's definition.
        const username = convertAwsEmailToUsername(emailAddr)

        forgotPassword(username).then((res: any) => {
            // See: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_CodeDeliveryDetailsType.html
            // `res` format: 
            //      {CodeDeliveryDetails: {
            //          'AttributeName: "email", DeliveryMedium: "EMAIL", Destination: "T***@m***"
            //      }}
            // The only two possible options for attribute (/delivery-medium) are "email" and "SMS".
            let messageType = res.CodeDeliveryDetails.AttributeName
            messageType = messageType.toUpperCase() === 'SMS' ? 'text message' : messageType
            const destination = res.CodeDeliveryDetails.Destination
            setForgotPassSuccessMessage(
                `Account recovery ${messageType} sent to ${destination}.${'\n'}`
                + `Copy the code form your ${messageType} into the box below.${'\n\n'}`
                + `If you don't see this email in your inbox within 5 minutes, look for it in `
                + `your junk mail folder.${'\n'}If you find it there, please mark it as “Not Junk”.`
            )
            setForgotPassErrorMessage('')
            setStep(2)
        }).catch((err) => {
            // See possible error objects here: 
            // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
            console.log('forgot pw input failure:', err)
            setForgotPassErrorMessage(formatAWsCognitoErrorMessage(err))
            setForgotPassSuccessMessage('')
        })
    }

    // Submit to AWS Cognito the user's verfication code from `runForgotPassword()` so 
    // that they can reset their password.
    const runConfirmPassword = (
        emailAddr: string, verificationCode: string, newPassword: string
    ) => {
        // Convert email to username. See why this is needed in this function's definition.
        const username = convertAwsEmailToUsername(emailAddr)
        confirmPassword(username, verificationCode, newPassword).then((res) => {
            setConfirmPassSuccessMessage(`Success! You can now login with your new password.`)
        }).catch((err) => {
            console.log('confirm pw input error:', err)
            setConfirmPassErrorMessage(formatAWsCognitoErrorMessage(err))
        })
    }


    return (
        <div>
            <HeaderBanner
                imageName="doctorpen"
                imageText="SUPPORT"
                imageSpacerColor='BLUE'
            />
            <div className="forgot-password-page-layout">
                <div className="body-container main-container">
                    <div className="progress-bar-placement">
                        <ProgressBar whichStep={step} numberOfSteps={2} />
                    </div>
                    <div className="header">
                        RESET PASSWORD
                    </div>

                    {(step === 2) ? (
                        <div className="text-spacing">
                            Enter the confirmation code sent to your e-mail below, then enter your new password.
                        </div>
                    ) : null}

                    <div className="form-wrapper">
                        <InputField
                            name='test'
                            type="email"
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(e.target.value)
                            }}
                            successMessage={forgotPassSuccessMessage}
                            errorMessage={forgotPassErrorMessage}
                            placeholder="E-mail"
                        />

                        {(step === 1) ? (
                            <>
                            <BlockButton
                                style={{ width: '400px' }}
                                buttonStyle="purple"
                                onClick={() => runForgotPassword(email)}
                            >
                                Send Confirmation
                            </BlockButton>
                            </>
                        ) : ( // step === 2
                            <>
                            <InputField
                                name='confirm'
                                placeholder="Confirmation Code"
                                value={confirmationCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationCode(e.target.value)}
                            />

                            <InputField
                                name='confirm'
                                placeholder="New Password"
                                type="password"
                                value={newPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                                successMessage={confirmPassSuccessMessage}
                                errorMessage={confirmPassErrorMessage}
                            />

                            <BlockButton
                                onClick={() => {
                                    runConfirmPassword(email, confirmationCode, newPassword)
                                }}
                                style={{ width: '400px' }} 
                                buttonStyle="purple"
                            >
                                RESET PASSWORD
                            </BlockButton>
                            </>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}
