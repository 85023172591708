import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AccountContext, DecodedJwt } from '../../auth/Account'

import { SessionPacket } from '../../../types/session';
import { useCurrentSession } from '../../../hooks/useCurrentSession';

import { BlockButton } from '../../../components/shared/buttons/block-button/BlockButton';
import { HeaderSearchDropdown } from './header-search-dropdown/HeaderSearchDropdown'
import { HeaderSearchInputField } from './header-search-input-field/HeaderSearchInputField';
import { Icon } from '../../shared/icon/Icon';
import { Navbar } from '../navbar/Navbar';

import { MyAccountInfo } from '../../../../config';

import './desktop-header.scss';
import './inactivity-modal-styles.scss';



/**
 * A little component to show number on the cart icon
 */
const CartSize = ({count} : {count: number}) => {
    return (
        <div className='cart-size'>{count}</div>
    )
}


const API_DB_URL = process.env.REACT_APP_API_DB_URL



interface Props {
    orderNo?: string;
    userNotActive?: any;
    isAdminPage?: boolean;
    style?: React.CSSProperties;
}
/**
 * White header, blue navbar, and inactivity modal which shows on all pages.
 */
export const DesktopHeader = ({ 
    orderNo, userNotActive,
    isAdminPage, style,
 }: Props) => {

    const [isInactive, setIsInactive] = useState(false)
    const [showInactivityModal, setShowInactivityModal] = useState(false)

    const SEARCH_CATEGORIES = ['All', 'Joints', 'Skin', 'Tissue']
    const [searchCategory, setSearchCategory] = useState<string>('All')

    // User login/session info.
    const { isLoggedIn } = useCurrentSession()
    const { logout, decodeJwt, getUsernameAndIdToken, getShoppingCart, shoppingCartSize } = useContext(AccountContext)
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
    const [count, setCount] = useState(0)
    const [thisUserId, setThisUserId] = useState(0)

    // For admin page to treat header differently.
    const isAdminClass = isAdminPage ? 'is-admin' : ''


    const _notActive = () => {
        if (isInactive) {
            userNotActive(isInactive)
        }
        setShowInactivityModal(true)
    }




    /**
     * IMPORTANT: THIS WAS COPIED FROM MYACCOUNT.TSX ON 8/24. CONSOLIDATE.
     * - MAYBE MOVE INTO ACCOUNT CONTEXT.
     */
    const [entityUrlBit, setEntityUrlBit] = useState<'supplier'|'vendor'|'doctor'|'consumer'|''>('')
    const [accountInfo, setAccountInfo] = useState<MyAccountInfo|null>(null)

    // Get account data from db, along with data for any associated business/clinic.
	const getAccountData = async (sessionObj: SessionPacket|null) => {
		// Get session info if possible, else fail silently
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session)
        const decodedJwt: DecodedJwt|null = await decodeJwt(session.idToken) as DecodedJwt|null
        if (decodedJwt && decodedJwt['custom:u_id']) {
            setThisUserId(Number(decodedJwt['custom:u_id']))
        }
		if (session.error) { return }

		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		
		// Get data from backend.
		//  - First get general data then get tagged reps.
		axios.post(`${API_DB_URL}/get-account-info`, 
			Object.fromEntries(formData), 
			config
		).then(async (res) => {
			const accountInfoData = res.data
			setAccountInfo(accountInfoData)
			console.log('accountInfo', accountInfoData)
			
			const _entity_type = accountInfoData?.u_entity_type || ''

			// TODO: standardize and centrailize.
			if (_entity_type === 'manufacturer') {
				setEntityUrlBit('supplier')
				return ['supplier', accountInfoData]
			} else if (_entity_type === 'distributor') {
				setEntityUrlBit('vendor')
				return ['vendor', accountInfoData]
			} else if (_entity_type === 'provider') {
				setEntityUrlBit('doctor')
				return ['doctor', accountInfoData]
			} else if (_entity_type === 'general') {
				setEntityUrlBit('consumer')
				return ['consumer', accountInfoData]
			} else {
                // TODO: Supressor error messages for the demo.
				// alert('System Error #4613: could not get user entity type. Please contact support.')
				console.log('System Error #4613: could not get user entity type. Please contact support.')
				// throw new Error('System Error #4613: could not get user entity type. Please contact support.')
			}
		})
	}




    // The only reason to have the session is to try to get a logged-in user's shopping cart,
    // size, so fail silently.
    const getLoginInfoIfPossible = async () => {
        const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
        setSessionObj(session)
        if (session.error) return; // Fail silently.
        const sessionJson = JSON.stringify(session)
        getShoppingCart(sessionJson) // This triggers `getShoppingCartSize()` in Account.tsx.
    }

    useEffect(() => {
        getLoginInfoIfPossible()
    }, [])

    useEffect(() => {
        setCount(shoppingCartSize)
    }, [shoppingCartSize])

    useEffect(() => {
        if (!sessionObj) return;
        getAccountData(sessionObj)
    }, [sessionObj])

    return (
        <>
            <header className={`${isAdminClass}`} style={style}>
                {/* The top-most navigation bar */}
                <div className={`${!isAdminClass ? 'body-container-no-vert-pad' : ''} header-wrapper ${isAdminClass}`}>
                    <div className='center-items logo-container'>
                        <Link to={!isAdminPage ? `/public/home` : `/account`}>
                            <img src="/logo.png" alt="logo" className={`logo-full ${isAdminClass}`} />
                            <img src="/logo-graphic.png" alt="logo" className={`logo-graphic ${isAdminClass}`} />
                        </Link>
                    </div>

                    {!isAdminPage ? (
                        <div className='center-items search-container'>
                            <HeaderSearchDropdown
                                SEARCH_CATEGORIES={SEARCH_CATEGORIES}
                                searchCategory={searchCategory}
                                setSearchCategory={setSearchCategory}
                            />
                            <HeaderSearchInputField placeholder='Search' />
                        </div>
                    ) : null}


                    {(!isAdminPage && isLoggedIn === true) ? (
                        // Buttons for Logged-in users
                        <div className='center-items icon-buttons'>
                            <Link to={`/cart/check-out/`}>
                                <button className='clickable icon-button dynamic-icon' title="Cart">
                                    <Icon name='cart' />
                                    {(count && !isNaN(count)) ? (
                                        <CartSize count={count} />
                                    ) : (null)}
                                </button>
                            </Link>

                            {(entityUrlBit === 'vendor') ? (
                                <Link to={`/vendor-dashboard/`}>
                                    <button className='clickable icon-button dynamic-icon' title="User Profile">
                                        <Icon name='barchart-vert' />
                                    </button>
                                </Link>
                            ) : (entityUrlBit === 'consumer') ? (
                                <Link to={`/rep/dashboard/`}>
                                    <button className='clickable icon-button dynamic-icon' title="User Profile">
                                        <Icon name='barchart-vert' />
                                    </button>
                                </Link>
                            ) : (entityUrlBit === 'doctor') ? (
                                <Link to={`/physician/physician-dashboard/`}>
                                    <button className='clickable icon-button dynamic-icon' title="User Profile">
                                        <Icon name='barchart-vert' />
                                    </button>
                                </Link>
                            ) : null}

                            <Link to={`/account/`}>
                                <button className='clickable icon-button dynamic-icon' title="User Profile">
                                    <Icon name='profile' />
                                </button>
                            </Link>

                            <Link to={`#`}>
                                <button 
                                    className='clickable icon-button dynamic-icon' 
                                    title="Log out"
                                    onClick={() => logout()}
                                >
                                    <Icon name='logout' />
                                </button>
                            </Link>
                        </div>
                    ) : ((!isAdminPage && isLoggedIn === false) ? (
                        // Buttons for anonymous users
                        <div className='center-items icon-buttons-notloggedin'>
                            <Link to={`/vendor-registration/1`}>
                                <BlockButton
                                    buttonStyle='desktop-header-buttons purple'
                                >
                                    JOIN
                                </BlockButton>
                            </Link>

                            <Link to={`/auth/login`}>
                                <BlockButton
                                    buttonStyle='desktop-header-buttons dark'
                                >
                                    LOGIN
                                </BlockButton>

                            </Link>
                        </div>
                    ) : ((isAdminPage && isLoggedIn === true) ? (
                        <div className='center-items icon-buttons'>
                            <Link to={`#`}>
                                <button 
                                    className={`clickable icon-button dynamic-icon ${isAdminClass}`}
                                    title="Log out"
                                    onClick={() => logout()}
                                >
                                    <Icon name='logout' />
                                </button>
                            </Link>
                        </div>
                     ) : ((isAdminPage && isLoggedIn === false) ? (
                        <div className='center-items icon-buttons-notloggedin'>
                            <Link to={`/auth/login/?redirectTo=${encodeURIComponent('/admin')}`}>
                                <BlockButton
                                    buttonStyle='desktop-header-buttons dark'
                                >
                                    LOGIN
                                </BlockButton>

                            </Link>
                        </div>
                    ) : (
                        // Default state - what is shown before the app knows whether the user is logged in.
                        null
                    ))))}

                </div>


                {/* The blue navbar */}
                {!isAdminPage? (
                    <Navbar navType="default" />
                ) : null}

            </header>
        </>
    )
}
