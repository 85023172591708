import React from 'react';
import { connect } from 'react-redux';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { SelectField, SelectOption } from '../../../shared/form/select-field/SelectField';
import { ProductOrder } from '../../../../models/CartItem';
import { addOrderToCart } from '../../../../store/actions/cart-actions';
import { CartMap } from '../../../../store/reducers/cart-flow';
import { store, StoreItems } from '../../../../store/store';
import { Modal } from '../../../shared/modal/Modal';
import './product-details.scss';
import { Navigate } from 'react-router-dom';
import { ProductGroup } from '../../../../models/ProductGroup';
import { Product } from '../../../../models/Product';

//  TODO:  CONVERT/UPDATE TO MODERN REACT

type Props = {
    productId: number
    product: ProductGroup,
    // store injections
    addOrderToCart: Function,
    cart: CartMap,
};

type State = {
    order: ProductOrder,
    showContimueShoppingModal: boolean,
    checkOut: boolean
};




class ProductDetailsPage extends React.Component<Props, State, null> {
    private _qtyOptions: SelectOption[];

    constructor(props: Props) {
        super(props);

        this._qtyOptions = new Array(100).fill(0).map((_, index) => {
            const qtyCount = index + 1;
            return {
                value: qtyCount,
                option: qtyCount + '',
            }
        });

        this.state = {
            checkOut: false,
            showContimueShoppingModal: false,
            order: {
                qty: 1,
                sku: props.product?.displayProduct.sku,
                product: props.product?.displayProduct,
            }
        };
    }

    componentDidMount() {
        // was navigated to by a direct link,
        if (!this.props.product) {
            store.subscribe(() => {
                const { products } = store.getState();
                if (products.length > 0) {
                    this.setState({
                        order: {
                            qty: 1,
                            sku: this.props.product.products[0].sku,
                            product: this.props.product.products[0],
                        }
                    });
                }
            });
        }
    }


    /**
     * ============================================ form methods ======
     * =================================================================
     */

    private _updateDose = (sku: string) => {
        return () => {
            this.setState({
                order: {
                    sku: sku,
                    qty: this.state?.order.qty,
                    product: this.props?.product.products.find(product => product.sku === sku) as Product,
                }
            })
        }
    }


    private _updateQty = (e: any) => {
        const qty: number = parseInt(e.target.value);
        if (qty) {
            this.setState({
                order: {
                    sku: this.state?.order.sku,
                    qty: qty,
                    product: this.state?.order.product,
                }
            });
        }
    }


    private _formatOrderItems() {
        const cartDisplayItems: any = [];
        this.props.cart.forEach(cartItem => {
            console.log('item: ', cartItem);
            cartDisplayItems.push(
                <tr key={cartItem.uniqueKey} className="product-item">
                    <td><span>{cartItem.product.name}</span></td>
                    <td><span>{cartItem.product.sku}</span></td>
                    <td><span>{cartItem.qty}</span></td>
                    <td><span>$1000</span></td>
                </tr>
            );

        });
        return cartDisplayItems;
    }


    private _addToCart = () => {
        if (this.state?.order) {
            this.props.addOrderToCart(this.state.order);
        }

        this.setState({
            showContimueShoppingModal: true,
            order: this.state.order,
        });
    }




    /**
     * ============================================ formating methds ==
     * =================================================================
     */
    private _formatDoseOptions() {
        return this.props.product?.doses.map((doseOption, i) => {
            return (
                <li key={i}>
                    <BlockButton
                        onClick={this._updateDose(doseOption.sku)}
                        style={{
                            padding: '8px 0',
                        }}
                        buttonStyle={
                            ((this.state?.order.product?.sku === doseOption.sku) ? 'dark no-hover no-text-transform' : 'ghost-dark no-hover no-text-transform')
                            + ' no-pad'
                        }>
                        <span className="clickable">{doseOption.dose} ml</span>
                    </BlockButton>
                </li>
            );
        });
    }


    private _formatResourceFiles() {
        return this.props.product?.resources.map(resource => {
            return (
                <li key={resource.id}>
                    {resource.name}
                </li>
            );
        });
    }


    private _hideModal = () => {
        this.setState({ showContimueShoppingModal: false });
    }


    render() {
        return (
            <div className='body-container product-details-page'>

                <Modal
                    modalType={1}
                    show={this.state.showContimueShoppingModal}
                    closeModal={this._hideModal}
                >
                    <div className='modal-content'>
                        <div className={((this.props?.cart.size > 8) ? 'make-scollable' : '')}>
                            <div className='scollable-table'>
                                <table className="order-table">
                                    <thead>
                                        <tr>
                                            <th><span>Product</span></th>
                                            <th><span>SKU</span></th>
                                            <th><span>QTY</span></th>
                                            <th><span>Amount</span></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this._formatOrderItems()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='buttons-wrapper'>
                            <div className='button continue-shopping-button'>
                                <BlockButton
                                    buttonStyle='ghost-purple no-hover'
                                    onClick={this._hideModal}
                                >
                                    Continue Shopping
                                </BlockButton>
                            </div>

                            <div className='button check-out-button'>
                                <BlockButton
                                    buttonStyle='purple no-hover'
                                    onClick={() => {
                                        this.setState({ checkOut: true });
                                    }}>
                                    Check out
                                </BlockButton>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* cart check out redirect */}
                {this.state.checkOut ? <Navigate to='/cart/check-out' /> : null}




                <div className='section product-listing'>
                    <div className='img-wrapper' style={{
                        background: 'url(' + this.state.order?.product?.image + ') no-repeat center center',
                    }} />

                    <div className='product-summary'>
                        <h1>{this.state.order?.product?.name}</h1>
                        <p className='brand'>{this.state.order?.product?.brand}</p>
                        <p className='vendor'>{this.state.order?.product?.vendor}</p>
                        <p className='description'>
                            {this.state.order?.product?.description}
                        </p>
                    </div>
                </div>

                <div className='section order-info'>
                    <div className='order-options'>
                        <div className='dose-options'>
                            <p className='sub-header'>Dose</p>
                            <ul>
                                {this._formatDoseOptions()}
                            </ul>
                        </div>

                        <div className='qty'>
                            <p className='sub-header'>Quantity</p>

                            <div className='order-submit'>
                                <div className='select-wrapper'>
                                    <SelectField
                                        containerClassName='transparent-bg border-radius-2 light-border pad-10 full-height'
                                        name='qty'
                                        // 11/14/2022 - removed this because not compatible
                                        // with the new form of this component.
                                        // defaultValue={this._qtyOptions[0].value}
                                        options={this._qtyOptions}
                                        onChange={this._updateQty}
                                    />
                                </div>
                                <div className='submit-button-wrapper'>
                                    <BlockButton
                                        buttonStyle='purple'
                                        disabled={(
                                            (this.state.order?.product === undefined) ||
                                            (this.state.order?.sku === undefined) ||
                                            (this.state.order?.qty === undefined)
                                        )}
                                        onClick={this._addToCart}
                                    >ADD TO CART</BlockButton>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='pricing-feedback'>
                        <p className='sub-header'>Pricing</p>
                    </div>
                </div>


                <div className='section info use-description'>
                    <h2>Uses</h2>

                    <p>
                        {this.props.product?.uses}
                    </p>
                </div>

                <div className='section info research-files'>
                    <h2>Research</h2>

                    <div className='resources'>
                        <ul className='files'>
                            {this._formatResourceFiles()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}



const injectStoreToProps = (store: StoreItems) => {
    const { cart } = store;
    return { cart };
}

const conn = connect(injectStoreToProps, { addOrderToCart });
export const ProductDetails = conn(ProductDetailsPage);