import './modal.scss';
import { BlockButton } from "../buttons/block-button/BlockButton";


type Props = {
    show: boolean,
    children: JSX.Element,
    title?: string,
    subtitle?: string,
    closeModal: Function,
    modalStyle?: string;
    modalType?: number;
    noCloseOverlay?: boolean; // if this modal shouldn't be closable from the overaly.
    noCloseButton?: boolean; // if this modal shouldn't be closable from the "x" button.

};


export const Modal = ({
    modalType, show, title, subtitle, children, closeModal, modalStyle, noCloseOverlay, noCloseButton,
}: Props) => {
    const showHideClassName = show ? "display-block" : "display-none";

    return (
        <div className={`${showHideClassName}  generic-modal noprint ${modalType === 2 ? 'base-modal' : ""}`} >

            {/* Background overlay */}
            <div onClick={() => {
                if (closeModal && !noCloseOverlay) {
                    closeModal();
                }
            }} className='bg-shade'
            />

            <div className={modalStyle || 'modal-main'}>
                {(title) && (
                    <div className={'title'}>
                        {title}
                    </div>
                )}

                {(subtitle) && (
                    <div className='subtitle'>
                        {subtitle}
                    </div>
                )}

                {/* Close button */}
                {!noCloseButton ? (
                    <div className="close-button"
                        onClick={() => closeModal()}
                    >
                        &#10006;
                    </div>
                ) : null}
                {children}
            </div>
        </div>
    );
};