/**
 * List the types of accounts that can sign up for Medngine.
 */

export type AccountTypesNames = 'Manufacturer' | 'Provider' | 'General' | 'Distributor' | string
export type AccountTypesFlat = AccountTypesNames[]
export type AccountType = {
    "name": AccountTypesNames,
}
export type AccountTypeMap = {
    [key in AccountTypesNames]: string;
}
type DropdownListItem = {
    option: string,
    value: string | null,
}

/**
 * WARNING: DO NOT CHANGE THESE VALUES!!
 * 
 * Changing values in here will change how authentication works in AWS Cognito, and 
 * could prevent many or all users from being to use the website!
 * 
 * TODO: add tests to ensure that these values are uppercased first letter only,
 *       and to throw an error if these values are changed.
 *  
 */

export const ACCOUNT_TYPES_FLAT: AccountTypesFlat = [
    'Manufacturer',
    'Distributor',
    'Provider',
    'Rep',
]
// TODO: need accurate urls.
export const ACCOUNT_TYPES_TO_REG_URL: { [key: AccountTypesNames]: string } = {
    'Manufacturer': '/supplier-registration',
    'Distributor': '/vendor-registration',
    'Provider': '/doctor-registration',
    'Rep': '/consumer-registration',
}
// Display names for the above account types
export const ACCOUNT_TYPES_DISPLAY: AccountTypeMap = {
    'Manufacturer': 'Supplier',
    'Distributor': 'Business',
    'Provider': 'Doctor',
    'Rep': 'Rep',
}

export const ACCOUNTTYPES: AccountType[] =
    ACCOUNT_TYPES_FLAT.map((account_type) => ({ 'name': account_type }))

export const ACCOUNT_TYPES_DROPDOWN_OPTIONS: DropdownListItem[] = [
    { option: '-', value: null },
    ...ACCOUNT_TYPES_FLAT.map(accountType => {
        const displayName = ACCOUNT_TYPES_DISPLAY[accountType] || '-'
        return { option: accountType, value: displayName }
    })
]

