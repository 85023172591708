import { APP_VERSION, APP_VERSION_COOKIE_NAME } from "../../config";

import { CookieNames, getCookie, makeOrUpdateCookie } from "../cache/Cookie_General";


/**
 * Make necessary changes such as clearing cache when app version changes.
 */
const VersionManager = () => {
    const currentVersion = getCookie(APP_VERSION_COOKIE_NAME)

    // Config vars from dotenv.
    const _ENV = process.env.REACT_APP__ENV

    const deleteAllCookies = () => {
        var cookies = document.cookie.split(";");
    
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }
    
    const clearLocalStorage = () => {
        localStorage.clear()
    }

    // This will clear any legacy cookies that we have, including forcing user to login again.
    // - then force refresh.
    if (currentVersion !== APP_VERSION) {
        console.log(`old app version detected: ${currentVersion} -> ${APP_VERSION} | ${_ENV}`)
        deleteAllCookies()
        clearLocalStorage()
        makeOrUpdateCookie(CookieNames.medappversion, APP_VERSION)
        window.location.reload();
    } else {
        console.log(`app version up to date (v=${APP_VERSION}) | ${_ENV}`)
    }

    return <></>
}

export default VersionManager