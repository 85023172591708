import { CONVERT_AT_SIGN_TO } from "../../config"

/**
 * AWS Cognito has bug that won't allow username to be same as email - so set username to
 * the email address without the '@' (and add "--" for easy find/replace if needed
 * for, say, data analysis or joining tables or w/e). 
 * Users can still login with their full original email addresses.
 * 
 * Since username (with its different formatting) may show up in error messageing from AWS,
 * run those error messages through one of these before presenting it in the UI.
 */
export const convertAwsEmailToUsername = (email: string) => {
    return email.replace('@', CONVERT_AT_SIGN_TO)
}

export const convertAwsUsernameToEmail = (username: string) => {
    return username.replace(CONVERT_AT_SIGN_TO, '@')
}

/**
 * For properly formatting and displaying error and status messages from AWS Cognito. 
 * 
 * 
 * To view all possible error/status message titles for AWS Cognito:
 *  - Registration/signup:
 *    https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
 *  - Login:
 *    https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
 * 
 * RECOMMMENDED:
 * Use the wrapper function and pass it the error object to run everything together.
 */
export const formatAWsCognitoErrorMessage = (err: Error): string => {
    // See why all of these conversions are needed in their respective function definitions.
    const readableErrorName = getReadableNameFromAwsError(err.name)
    let formattedErrorDescription = replaceUsernameWithEmail(err.message)
    formattedErrorDescription = convertAwsUsernameToEmail(formattedErrorDescription)

    let messageAppend = ''

    // Message names that might require tech support.
    const EXCEPTION_NAMES_TECH_RELATED = [
        // For registration
        'CodeDeliveryFailureException',
        'ForbiddenException',
        'InternalErrorException',
        'InvalidEmailRoleAccessPolicyException',
        'InvalidLambdaResponseException',
        'InvalidSmsRoleAccessPolicyException',
        'InvalidSmsRoleTrustRelationshipException',
        'ResourceNotFoundException',
        'UnexpectedLambdaException',
        'UserLambdaValidationException',
        // For login
        // (all login errors that are tech related have redundant names with the above)

        // TODO: fill in the possible errors for forgot-password/confirm-password page.
    ]

    // TODO: consider adding a medngine support contact email address here.
    const APPEND_MSG_CONTACT_SUPPORT = ' Please try again in a few minutes.' +
                                       ' If you continue to have problems, contact Medngine support.'

    // Special handling for specific types of messages.
    if (err.name === 'UsernameExistsException') {
        return `An account with this email address already exists.`
    } else if (err.name === 'NotAuthorizedException') {
        // Normal username/password error.
        if (err.message.includes('email') || err.message.includes('password')) {
            return formattedErrorDescription 
                    + `${'\n'}If this is your first time logging in, make `
                    + 'sure that you have verified your email address.'
        }
        // When user was disabled (but not deleted) through AWS Cognito GUI/CLI.
        else if (err.message.includes('disabled')) {
            return formattedErrorDescription 
                    + `${'\n'}If you think this is an error, please contact support.`
        }
    } else if (err.name === 'NotAuthorizedException'
               && err.message.includes('disabled')) {
        
    } else if (err.name === 'UserNotConfirmedException') {
        return 'Please click the verification link sent to your email.'
    } else if (EXCEPTION_NAMES_TECH_RELATED.includes(err.name)) {
        messageAppend = APPEND_MSG_CONTACT_SUPPORT
    }

    return readableErrorName + ': ' + formattedErrorDescription + ' ' + messageAppend
}

/**
 * Error messages always have the form of "NameAndDetailsException" - so split into words starting
 * with capital letters separated by a space, so they become user-readable.
 * Also remove the last word "Exception".
 */
const getReadableNameFromAwsError = (errName: string) => {
    const errorName = errName.replace('Exception', '').replace('exception', '')

    let readableName = ''

    for (let x=0; x < errorName.length; x++) {
        const char = errorName[x]
        if (char === char.toUpperCase()) {
            if (readableName) {
                readableName += ' '
            }
        }
        readableName += char
    }

    return readableName
}

/** 
 * Since "username" is the name of auth field for AWS Cognitor (instead of "email"), error 
 * messages says things like "incorrect username or password", etc when login fails, for example.
 * This could be confusing since user is meant to login with email address.
 * So replace any instance of the word "username" with "email".
 */
const replaceUsernameWithEmail = (message: string) => {
    const regex1 = new RegExp('username', 'ig')
    const regex2 = new RegExp('user name', 'ig')
    return message.replaceAll(regex1, 'email').replaceAll(regex2, 'email')

}

