import React, { useEffect, useState } from 'react'

import axios from "axios";
import braintreeWeb, { Client, DataCollector, HostedFields } from 'braintree-web'
import { Icon } from '../../../shared/icon/Icon';

// Used this for the Braintree "Drop-in" form, which we can't use for ACH, so went with hosted fields instead.
// import dropin from "braintree-web-drop-in"



const API_DB_URL = process.env.REACT_APP_API_DB_URL


interface Props {
    loggedInUserId: number|null; // Only has a val if user is logged in.
    sessionJson: string;
    setHostedFieldsReady: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BrantreeForm_CC = ({
    loggedInUserId, sessionJson,
    setHostedFieldsReady,
}: Props) => {
    const [clientToken, setClientToken] = useState('')
    const [clientInstance, setClientInstance] = useState<Client|null>(null)
    const [deviceDataCorrelationId, setDeviceDataCorrelationId] = useState('')
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState<HostedFields|null>(null)
    const [transactionData, setTransactionData] = useState<any>(null)
    const [isPaymentMade, setIsPaymentMade] = useState<any>(null)
    // Statuses
    const [clientDataAndSDKReady, setClientDataAndSDKReady] = useState(false)


    // ON MOUNT
    // - Set up Braintree
    useEffect(() => {
        setUpBraintreeClient()
	}, [])

    /**
     * Initialize Braintree Client
     *  1. Get "Client Token" from backend which requests from Braintree's "Server SDK".
     *  2. Use the Client Token to get the frontend "Client SDK".
     *  3. Use the Client SDK to get info about user's device (Braintree needs it for security).
     */
    const setUpBraintreeClient = async () => {
        await axios.get(`${API_DB_URL}/braintree/get-client-token`).then((res) => {
            // Get braintree token from Braintree's "Server SDK" (called from our backend).
            const clientToken: string = res.data
            setClientToken(clientToken)
            return [clientToken]
        }).then(async ([clientToken]) => {
            //Initialize the "Client SDK".
            const clientInstance: Client = await braintreeWeb.client.create({authorization: clientToken})
            setClientInstance(clientInstance)
            return [clientToken, clientInstance] as [string, Client]
        }).then(async ([clientToken, clientInstance]) => {
            // Get data about user's device.
            const dataCollectorInstance: DataCollector = await braintreeWeb.dataCollector.create({ client: clientInstance })
            var deviceData = dataCollectorInstance.deviceData // Looks like: `{"correlation_id":"6387149a680b626eef201ee9a70a7638"}` (type=string)
            setDeviceDataCorrelationId(deviceData)
        
            // TODO: delete me:
            console.log('============ vars ==============')
            console.log('clientToken', clientToken)
            console.log('clientInstance', clientInstance)
            console.log('dataCollectorInstance', dataCollectorInstance)
            console.log('deviceData', deviceData)
            console.log('============ ^^^^ ==============')
        }).then(() => {
            // Mark this as done & ready for creating the Hosted Fields (payment form html).
            setClientDataAndSDKReady(true)
        }).catch(function (err) {
            // Handle error in creation of components
            console.error('Error creating client:', err);
        })
    }

    // Submit payment
    const paymentTransaction = async (e: any, userId: number|null, deviceDataCorrelationId: any, hostedFieldsInstance: any) => {
        e.preventDefault()
        const data = e
		console.log('running paymentTransaction', data)
		console.log('hostedFieldsInstance3', hostedFieldsInstance)
		if (!sessionJson) return null
        
        // Get payment details from Braintree, including the Payment Method Nonce.
        await hostedFieldsInstance.tokenize((async (tokenizeErr: any, paymentInfo: any) => {
            if (tokenizeErr) {
                console.error(tokenizeErr)
                return
            }
            const nonce = paymentInfo.nonce
            console.log('pmtMthodNonce Paylod', paymentInfo, nonce)
            
            /**
             * Submit payment to backend.
             */
            const config = {'headers': {'content-type': 'multipart/form-data'}}
            const formData = new FormData() 
            formData.append('session', sessionJson)
            formData.append('amount', '36.37')
            formData.append('device_data', deviceDataCorrelationId)
            formData.append('payment_info', JSON.stringify(paymentInfo))
            formData.append('user_id', String(userId))
            
            await axios.post(`${API_DB_URL}/braintree/submit-payment`, 
                Object.fromEntries(formData), 
                config
            ).then((result) => {
                alert('success!')
                console.log('RESULT', result)
                setTransactionData(result.data)
                setIsPaymentMade(true)
            }).catch((e) => {
                console.error(e)
            })
        }))
	}

    const onCancelPayment = () => {
        console.log('onCancelPayment')
    }

    const onSubmitPayment = (e: any) => {
        e.preventDefault()
        console.log('onSubmitPayment', e)

    }


    
    return (
        <>
        {/* 
          * =============================================
          * BRAINTREE HOSTED 
          * =============================================
          */}
          {((clientDataAndSDKReady) ) ? (
            <HostedFieldsComponent 
                clientToken={clientToken}
                userId={loggedInUserId}
                onSubmitFunc={paymentTransaction}
                deviceDataCorrelationId={deviceDataCorrelationId}
                onCancelPayment={onCancelPayment}
                hostedFieldsInstance={hostedFieldsInstance}
                setHostedFieldsInstance={setHostedFieldsInstance}
                setHostedFieldsReady={setHostedFieldsReady}
            />
        ) : 'no token'}

        
        {/* Braintree JS client SDK */}
        <script src="https://js.braintreegateway.com/web/3.96.1/js/client.min.js"></script>
        <script src="https://js.braintreegateway.com/web/3.96.1/js/hosted-fields.min.js"></script>
        {/* jQuery */}
        {/* <script src="http://code.jquery.com/jquery-3.2.1.min.js" crossOrigin="anonymous"></script> */}
        </>
    )
}







interface PropsHostedFields {
    clientToken: string;
    onSubmitFunc: Function;
    userId: number|null;
    deviceDataCorrelationId: any;
    onCancelPayment: Function;
    hostedFieldsInstance: HostedFields|null;
    setHostedFieldsInstance: React.Dispatch<React.SetStateAction<HostedFields|null>>;
    setHostedFieldsReady: React.Dispatch<React.SetStateAction<boolean>>;
}

const HostedFieldsComponent = ({
    clientToken, userId, 
    deviceDataCorrelationId, 
    onSubmitFunc, onCancelPayment,
    hostedFieldsInstance, setHostedFieldsInstance, 
    setHostedFieldsReady,
}: PropsHostedFields) => {

    // Call the function to create hosted fields when the component mounts.
    useEffect(() => {
        createHostedFields().then((hostedFieldsInstance) => {
            setHostedFieldsInstance(hostedFieldsInstance || null)
            setHostedFieldsReady(true)
        })
    }, []);


    const createHostedFields = async () => {
        try {
            // Create a client instance with the client token.
            const clientInstance = await braintreeWeb.client.create({ authorization: clientToken })
    
            // Use the client instance to generate a payment nonce
            const hostedFieldsInstance = await braintreeWeb.hostedFields.create({
                client: clientInstance,
                styles: {
                    /**
                     * These styles are for objects inside of a braintree <iframe>, which area all
                     * wrapped inside of our '.field-input' divs.
                     * 
                     * Styles reference: 
                     * - https://developer.paypal.com/braintree/docs/guides/hosted-fields/styling/javascript/v3/
                     */
    
                    // For all braintree input fields (CC #, CVV, Expiration Date)
                    'input[data-braintree-name]': {
                        'font-family': 'sans-serif',
                        'font-size': '16px',
                        'color': '#444',
                    },
                    'input[data-braintree-name].valid': {
                        'color': '#004',
                        'font-weight': 'bold',
                    },
                    'input[data-braintree-name].invalid': {
                        'color': '#a00',
                    },
                },
                // (JB: these work)
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: 'Card Number'
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: 'MM/YY'
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: 'CVV'
                    }
                }
            }).then((hostedFieldsInstance) => {
                // Add event listeners or handle the hosted fields instance as needed
                // For example, you can add event listeners to handle validation and other interactions
                hostedFieldsInstance.on('validityChange', function (event) {
                    if (event.emittedBy === 'number') {
                        if (event.fields.number.isValid) {
                            // Handle valid card number
                            console.log('(valid card #)')
                        } else {
                            // Handle invalid card number
                            console.log('(invalid card #)')
                        }
                    } else if (event.emittedBy === 'expirationDate') {
                        if (event.fields.expirationDate.isValid) {
                            // Handle valid expiration date
                        } else {
                            // Handle invalid expiration date
                        }
                    } else if (event.emittedBy === 'cvv') {
                        if (event.fields.cvv.isValid) {
                            // Handle valid CVV
                        } else {
                            // Handle invalid CVV
                        }
                    }
                })
                return hostedFieldsInstance
            })
    
            // You can also store the hostedFieldsInstance in a state or prop if needed
            // For example, if you want to use the instance in other parts of your component

            return hostedFieldsInstance
        } catch (err) {
            console.error('Error:', err)
        }
    }
        
        

  
    return (
        <div className='credit-card-fields'>
            {/* 
              * The fields in this payment form is mostly** managed by Braintree's JS/API/server.
              * 
              * FYI: 
              * - It's okay to add other fields/buttons to this as needed.
              *
              * Notes by element:
              * - <label.field-label>
              *     - This one is NOT managed by braintree 
              *     - Styles for this are in our SCSS file.
              * - <div.field-input>
              *     - Managed mostly by braintree.
              *     - Styles for the container wrapper are in our SCSS file...
              *     - But Braintree adds an <iframe> inside of this, for which the styles
              *       need to be defined in the JS below.
              *     ----------------------
              *     - Braintree will also add these three classes to this container obj (div.field-input),
              *       based on the inner <input>'s value/state. (Note that these CANNOT be used as
              *       parent CSS selectors for the contents of the <iframe>):
              *         - .braintree-hosted-fields-valid 
              *         - .braintree-hosted-fields-invalid 
              *             - This class is only added when when correct # of chars are supplied.
              *         - .braintree-hosted-fields-focused
              *             - Only when the input is actually focused.
              *             - Independed of field's valid/invalid state.
              */}
            <form onSubmit={(e) => onSubmitFunc(e, userId, deviceDataCorrelationId, hostedFieldsInstance)}>
                <div className='field-container'>
                    <label htmlFor="card-number" className='field-label'>Card Number!!</label>
                    <div id="card-number" className='field-input'>
                        <div className='bt-status valid'><Icon name='circle-checkmark'></Icon></div>
                        <div className='bt-status invalid'><Icon name='circle-x'></Icon></div>
                    </div>
                </div>
                <div className='field-container'>
                    <label htmlFor="expiration-date" className='field-label'>Expiration Date</label>
                    <div id="expiration-date" className='field-input'>
                        <div className='bt-status valid'><Icon name='circle-checkmark'></Icon></div>
                        <div className='bt-status invalid'><Icon name='circle-x'></Icon></div>
                    </div>
                </div>
                <div className='field-container'>
                    <label htmlFor="cvv" className='field-label'>CVV</label>
                    <div id="cvv" className='field-input'>
                        <div className='bt-status valid'><Icon name='circle-checkmark'></Icon></div>
                        <div className='bt-status invalid'><Icon name='circle-x'></Icon></div>
                    </div>
                </div>
                <div className="button-container">
                    <input type='submit'className="btn btn-primary" value="Purchase" id="submit" />
                    <br/>
                    <a className="btn btn-warning" onClick={() => onCancelPayment()}>Cancel</a> 
                </div>
            </form>
        </div>
    )
}