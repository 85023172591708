import { useEffect, useRef } from 'react'

import { useNavigate } from "react-router-dom"

import { Icon } from "../icon/Icon";

import LoadingCircle from '../../../static/gif/loading-circle.gif'

import './change-log-modal.scss';



type Props = {
    show: boolean;
    closeModal: Function;
    overTitle?: string;
    title?: string;
    children: JSX.Element;
}




export const ChangeLogModal = ({ 
    show, closeModal,
    overTitle, title,
    children,
}: Props) => {
    const showHideClassName = show ? "display-block" : "display-none";


    return (
        <div className={`change-log-modal generic-modal noprint base-modal ${showHideClassName}`} >

            {/* Background overlay */}
            <div 
                className='bg-shade'
                onClick={() => {
                    if (closeModal) {
                        closeModal()
                    }
                }} 
            />

            <div className="modal-main">
                {(overTitle) ? (
                    <div className='over-title'>
                        {overTitle}
                    </div>
                ) : null}
                {(title) ? (
                    <div className='title'>
                        {title}
                    </div>
                ) : null}

                
                {/* Close button */}
                <div className="close-button"
                    onClick={() => closeModal()}
                >
                    &#10006;
                </div>

                {children}
            </div>
        </div>
    )
}