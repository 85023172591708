import './admin.scss'



export const AdminHome = () => {
    return (
        <div className='body-container-admin'>
            Admins Home page
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            For dev purposes only <a href='/public/dbtest' style={{display: 'inline', color: 'blue'}}>don't click this</a>
        </div>
    )
}