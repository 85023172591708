import React from 'react'

import axios from 'axios'

import parseErrorObject from '../../../helpers/parseErrorObject'

import { RedisCart } from '../../../../config'
import { ProductsById } from '../patient/product-detail/PatientProductPage'



const API_DB_URL = process.env.REACT_APP_API_DB_URL



/**
 * Function to get product details from the API.
 */
const _fetchProductDetails = async (
    sessionJson: string, // SessionObj as a json-string.
    cart: RedisCart|null,
    setProductDetails: React.Dispatch<React.SetStateAction<ProductsById>>,
) => {
    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)
    formData.append('redisCart', JSON.stringify(cart))

    axios.post(`${API_DB_URL}/get-cart-product-details`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        const data = res.data
        setProductDetails(data)
    }).catch(async (err) => {
        alert('Failed to get product details for cart!\n\n' + parseErrorObject(err))
    }) 
}

export default _fetchProductDetails