import './block-button.scss';
import { Icon } from '../../icon/Icon';



type ButtonStyles = 'desktop-header-buttons' | 'white' | 'purple' | 'dark' | 'ghost-purple' | 
                    'ghost-dark' | 'ghost-mid' | 'no-text-transform' | 'no-pad' | 'less-pad' | 
                    'little-pad' | 'little' | 'no-hover';

interface Props {
    children: any;
    type?: 'button' | 'reset' | 'submit';
    onClick?: Function;
    buttonStyle?: string; // TODO: convert this type to `ButtonStyles[]` (array)
    disabled?: boolean;
    style?: any;
    className?: string;
    iconType?: string;
}

export const BlockButton = ({
    type, children, onClick, buttonStyle, disabled, style, className, iconType
}: Props) => {

    const selectIcon = () => {
        if (iconType === 'study') {
            return (
                <div className='icon-then-text'>
                    <Icon name='study' />
                </div>
            )
        } else {
            return null
        }
    }


    return (
        <button
            type={type || 'button'}
            className={'block-button clickable ' + (buttonStyle || '') + ' ' + (className || '')}
            style={style}
            disabled={disabled}
            onClick={(e) => (onClick && !disabled) ? onClick(e) : null}
        >
            {selectIcon()}
            {children}
        </button>
    );
}
