import { HeaderAction } from "../actions/header-title-actions"
import { ReducerAction } from "./_types"

export const sectionHeader = (newSectionHeader = null, action: ReducerAction) => {
    switch (action.type) {
        case HeaderAction.CHANGE_SECTION_HEADER:
        case HeaderAction.NO_SECTION_HEADER:
            return action.payload
        default:
            return newSectionHeader
    }
}