import { SelectOption } from "../../../shared/form/select-field/SelectField";

import { MOCK_API_get_saved_payment_accounts } from '../../../../api/mockApi'
import { PaymentAccountDetails, PaymentMethod } from '../CartShared';

/**
 * Function to get all of a user's saved/stored payment accounts for ONE specific payment method
 *      type at at a time (such as all saved credit #s, OR all saved Bank account #s,  but not
 *      both credit cards and banks at the same time), and formats them for display in a dropdown list.
 */
type ReturnType = [PaymentAccountDetails[], SelectOption[]]

const _fetchUserPaymentAccounts = async (
    paymentMethod: PaymentMethod,
): Promise<ReturnType> => {
    return await MOCK_API_get_saved_payment_accounts(paymentMethod.type).then((paymentAccounts) => {
        const dropdownOptions = paymentAccounts.map((paymentAccount, x) => 
            ({ 
                option: JSON.stringify(paymentAccount), 
                value: `${paymentAccount.name} - ${paymentAccount.accountNumber.split(' ').slice(-1)[0] || ''}`,
            })
        )
        return [paymentAccounts, dropdownOptions]
    })
}

export default _fetchUserPaymentAccounts