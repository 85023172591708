/**
 * Hook for dynamically monitoring changes in URL's query params, since using <Link>
 * to modify query params doesn't trigger re-render by itself.
 * 
 * Query params that aren't dynamic shouldn't be handled here; they should be done 
 * in and "on-mount" `useEffect()`.
 */

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryParams = () => {
    const location = useLocation()

    // 1) For registration steps' "preview" mode.
    //    - Start with null value in order to determine when state has been applied.
    const [isPreviewMode, setIsPreviewMode] = useState<boolean|null>(null)

    useEffect(() => {
        // 1) For registration steps' "preview" mode.
        if (location.search.includes('mode=preview')) {
            setIsPreviewMode(true)
        } else {
            setIsPreviewMode(false)
        }
    }, [location.search])
    
    return { isPreviewMode }
}


