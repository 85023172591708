import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { ProductData } from '../../../shared/modal/AddProductModal';

import LoadingCircle from '../../../../static/gif/loading-circle.gif'
import VendorAddProductImg from "../../../../static/img/add-product-icon.png";

import './product-upload-greyback.scss'


interface Props {
    /* Styles - required */
    containerStyle: React.CSSProperties; 
    spinnerStyle: React.CSSProperties; 
    addProductIconStyle: React.CSSProperties;
    /* Add product button - required */
    enableAddProductButton: boolean;
    onClickAddProductButton: Function;
    showAddProductIcon: boolean;
    /* Product information - required */
    productsList: ProductData[];
    productIndex: number|null;
    productId: number|null;
    /* Function to run after user confirms that they want to delete the product - optional */
    deleteProduct?: Function;
    /* Image of the product - optional */
    productImgSrc?: string;
    productImgStyle?: React.CSSProperties;
    /* When notified that primary image of a product has changed, run cache-breaker on the img src */
    primaryImgChanged?: boolean;
    /* Registration steps "preview mode". */
    isPreviewMode?: boolean|null;
    /* Child components - optional */
    children?: any;
}


export const ProductUploadGreyback = ({ 
    containerStyle = {}, spinnerStyle = {}, addProductIconStyle = {},
    enableAddProductButton, onClickAddProductButton, showAddProductIcon,
    productsList, productIndex, productId, 
    productImgSrc, productImgStyle,
    deleteProduct,
    primaryImgChanged,
    children,
    isPreviewMode,
}: Props) => {

    // Cache-break'd img src
    // - (don't cache-break the original else every single load will be slow)
    const [productImgSrc_cacheBreak, setProductImgSrc_cacheBreak] = useState(productImgSrc || '')

    // When the 'X' button at top right corner is clicked, confrim w/user then trigger deletion
    // of all product data skus in db, and related images from S3, and clear CF cache.
    const onClickDeleteProductButton = useCallback(() => {
        if (isPreviewMode !== false) return

        const product = typeof productIndex === 'number' ? productsList[productIndex] : null
        if (!product) { throw new Error('Error #4459: Please logout and login, then try again.')}
        const productNameDesc = product.name ? `(${product.name})` : ''
        const confirmed = window.confirm(`Completely delete this product ${productNameDesc} and all of its SKUs?`)
        if (confirmed) {
            if (deleteProduct) {
                deleteProduct(product.id)
            }
        }
    }, [productsList, productIndex, productId, isPreviewMode])

    // Run cache-breaker on img src
    useEffect(() => {
        if (primaryImgChanged) {
            setProductImgSrc_cacheBreak(prev => prev.split('?')[0] + '?time=' + String(Date.now()))
        }
    }, [primaryImgChanged])



    return (
        <div className='product-upload-greyback' style={containerStyle}>
            {/* Button to delete the product from DB and its images from S3/CloudFront */}
            {productImgSrc ? (
                <button 
                    className='delete-product-button'
                    onClick={() => {
                        if (isPreviewMode === false) {
                            onClickDeleteProductButton()
                        }
                    }}
                >
                    &#10006;
                </button>
            ) : null}

            {/* The product's image */}
            {productImgSrc ? (
                <img
                    src={productImgSrc_cacheBreak}
                    className='vendor-product-primary-image'
                    style={productImgStyle}
                />

            ) : null}

            {/* Background icon with a circle-plus icon in it to show user that they can upload here */}
            {showAddProductIcon ? (
                <img
                    src={VendorAddProductImg}
                    className='vendor-add-product-icon'
                    style={addProductIconStyle}
                />
            ) : null}

            {/* Optional misc children should be before spinner code below. */}
            {children}

            {/* Invisible button overlay to add a product */}
            {enableAddProductButton ? (
                <button
                    className='invisible-add-product-button'
                    onClick={(e) => onClickAddProductButton(e, productIndex, productId)}
                />
            ) : null}

            {/* Spinner when loading */}
            <img 
                className='vendor-adding-product-spinner'
                src={LoadingCircle}
                style={spinnerStyle}
            />
        </div>
    );
}