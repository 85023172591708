import { CartDict } from "../../../../cache/Cart_Types"




/**
 * Function to go through the the shopping cart and return a sorted list of all distinct
 * delivery dates.
 * 
 * Delivery dates in shopping cart (input arg) are formmatted like:
 *      'Wed, 11/20/2022'
 * And the returned results array has the same format.
 */
const _getAllDeliveryDates = (
    shoppingCart: CartDict
): string[] => {
    let dates = new Set()
  
    for (let productId of Object.keys(shoppingCart)) {
        for (let skuId of Object.keys(shoppingCart[productId])) {
            // const dateStr = shoppingCart[productId][skuId][1].split(', ')[1]
            
            // TODO: this is fake.
            const dateStr = '7/5/2023'
            
            dates.add(dateStr)
        }
    }

    // Convert set to array.
    let datesArr = Array.from(dates)

    // Convert to YYYYMMDD format to sort, because sorting Date objects sorts by string.
    datesArr = datesArr.map((dt) => {
        const ds = String(dt).split('/')
        const year = ds[2]
        let month = ds[0]
        let date = ds[1]
        month = (month.length === 1) ? '0'+month : month
        date = (date.length === 1) ? '0'+date : date
        return year + month + date
    })

    // Sort by date ascending.
    datesArr = datesArr.sort()

    // Convert back to the original date string (without the weekday abbreviation in front).
    const datesArr2 = datesArr.map(dt => {
        const dtt = String(dt)
        const year = Number(dtt.slice(0,4))
        const month = Number(dtt.slice(4,6)) - 1 // Remember that months start at 0.
        const date = Number(dtt.slice(6,8)) // Date of the month - (dates start at 1)
        const dateObj = new Date(year, month, date)
        const dayName = new Intl.DateTimeFormat('en-US', {weekday: 'short'}).format(dateObj)
        // Add the 1 back in for month
        return String(`${dayName}, ${month + 1}/${date}/${year}`)
    })

    return datesArr2
}

export default _getAllDeliveryDates