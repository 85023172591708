import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getUser } from '../../../store/actions/user-actions';
import { StoreItems } from '../../../store/store';
//     import { PhysicianProfile } from './physician-profile/PhysicianProfile';
import { ConsumerDashboard } from './dashboard/ConsumerDashboard';
//     import { NewPhysicianDashboard } from './dashboard-new/NewPhysicianDashboard';
//     import { RegisteredProcedures } from './procedures/RegisteredProcedures';
//     import { TissueInventory } from './tissue-inventory/TissueInventory';
//     import { ProductInventory } from './product-inventory/ProductInventory';
//     import { StudyDashboard } from './study-dashboard/StudyDashboard';
//  import { ProductDetails } from '../product/product-details/ProductDetails';   PRODUCT DETAIL PAGE.
import './consumer.scss';


// TODO:  FIX PRODUCT DETAILS PAGE EXPORT FOR COMPATIBILITY WITH MODERN REACT


type Props = {
    user?: any,
    getUser: any,
};



const ConsumerPage = (props: Props) => {

    props.getUser();

    console.log(props.user);

    if (!props.user) <Navigate to="/auth/login" />

    return (
        <Routes>
            <Route path='consumer-dashboard' element={<ConsumerDashboard />} />
        </Routes>
    );
}


const injectStoreToProps = (store: StoreItems) => {
    const { user } = store;
    return { user };
}

const conn = connect(injectStoreToProps, { getUser });
export const Consumer = conn(ConsumerPage);