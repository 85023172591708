/**
 * For any page that is added to the admin, define the path here
 */

export interface Page {
  path: string;
  children?: {[key: string]: Page};
}

export interface Hierarchy {
  [key: string]: Page;
}


// The base path for all admin pages.
export const BASE_ADMIN_PATH = '/admin'
export const BASE_PAGE_NAME = 'admin'

export const ADMIN_NAV_HIERARCY: Hierarchy = {
    admin: {
        path: `${BASE_ADMIN_PATH}/`,
        children: {
            sys: {
                path: `${BASE_ADMIN_PATH}/sys`,
            },
            lists: {
                path: `${BASE_ADMIN_PATH}/lists`,
                children: {
                    user: { path: `${BASE_ADMIN_PATH}/lists/user` },
                    // user_cognito: { path: `${BASE_ADMIN_PATH}/lists/user_cognito` },
                    // role: { path: `${BASE_ADMIN_PATH}/lists/role` },
                    // entity_type: { path: `${BASE_ADMIN_PATH}/lists/entity_type` },
                    business: { path: `${BASE_ADMIN_PATH}/lists/business` },
                    clinic: { path: `${BASE_ADMIN_PATH}/lists/clinic` },
                    // business_copy: { path: `${BASE_ADMIN_PATH}/lists/business_copy` },
                    // clinic_copy: { path: `${BASE_ADMIN_PATH}/lists/clinic_copy` },
                    // email: { path: `${BASE_ADMIN_PATH}/lists/email` },
                    // address: { path: `${BASE_ADMIN_PATH}/lists/address` },
                    // phone: { path: `${BASE_ADMIN_PATH}/lists/phone` },
                    // website: { path: `${BASE_ADMIN_PATH}/lists/website` },
                    product: { path: `${BASE_ADMIN_PATH}/lists/product` },
                    // sku: { path: `${BASE_ADMIN_PATH}/lists/sku` },
                    // tag: { path: `${BASE_ADMIN_PATH}/lists/tag` },
                    rep_org: { path: `${BASE_ADMIN_PATH}/lists/rep_org` },
                },
            },
        }
    }
}