import React, { useContext, useEffect, useState } from "react";

import { AccountContext } from "../../auth/Account";
import { Modal } from "./Modal"
import { BlockButton } from "../buttons/block-button/BlockButton";
import { InputField } from "../form/input-field/InputField";

import { buildPath } from "../../../helpers/urls";

import RedirectGif from '../../../static/img/redirect-spinner.gif'
import './temp-modal.scss';




type Props = {
    awsUsername: string, // User email with "@" replaced by "--"
    referrer: string,
    redirectTo: string,
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    showSpinner: boolean;
    setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    spinnerText: string;
    setSpinnerText: React.Dispatch<React.SetStateAction<string>>;
    justVerifyEmail?: boolean; // If this is opened outside of traditional flow, e.g. user finished  step 1
                               // of registration, then closed window, then came back to enter verification code.
};


export const VerificationCodeModal = ({ 
    awsUsername, 
    referrer, redirectTo, 
    show, setShow, showSpinner, setShowSpinner, spinnerText, setSpinnerText,
    justVerifyEmail,
}: Props) => {
    const { confirmVerificationCode } = useContext(AccountContext)
    const [code, setCode] = useState('')
    const [codeVerificationError, setCodeVerificationError] = useState('')
    const [codeVerificationSuccess, setCodeVerificationSuccess] = useState('')

    const [showOpacity, setShowOpacity] = useState(false)
    const [codeConfirmedInternal, setCodeConfirmedInternal] = useState(false)
    const [focusModalInput, setFocusModalInput] = useState(false)


    useEffect(() => {
        if (showSpinner === true) {
            setShowOpacity(true)
        }
    }, [showSpinner])


    /** 
     * For step 1 of registration.
     * Add user to db and send verification code to AWS Cognito and confirm.
     * On success: redirect to login page with `referrer` query string.
     */
    const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        confirmVerificationCode(awsUsername, code)
            .then((data: any) => {
                console.log('verification code is good!... data: ', data)
                setCodeVerificationError('')
                setCodeVerificationSuccess('')
                new Promise(async (resolve, reject) => {
                    setTimeout(() => {
                        setCodeVerificationSuccess('Account verified!')
                        setCodeConfirmedInternal(true)
                        resolve(null)
                    }, 550)
                }).then(async () => {
                    await new Promise((resolve, reject) => {
                        setTimeout(() => {
                            setSpinnerText('Redirecting')
                            setShowSpinner(true)
                            resolve(null)
                        }, 500)
                    })
                }).then(async () => {
                    // Successful - redirect to login page, followed by redirect to step 2.
                    await new Promise((resolve, reject) => {
                        const qpOut = {
                            redirectTo: redirectTo,
                            referrer: referrer,
                        }
                        setTimeout(() => {
                            window.location.href = buildPath('/auth/login/', qpOut)
                            resolve(null)
                        }, 500)
                    })
                })
            }).catch((err: Error) => {
                setCodeVerificationError('')
                setCodeVerificationSuccess('')
                setTimeout(() => {
                    setCodeVerificationError(`${err.name}: ${err.message}`)
                }, 350)
            })
    }




    return (
        <div className='temp-redirect-modal'>

            {/* Fake modal container for animation purposes */}
            <div 
                className={`redirecting-message-container ${showOpacity ? 'full-opacity' : ''}`}
                style={{ display: showSpinner ? 'block' : 'none' }}
            >
                <div className={`redirecting-message ${showOpacity ? 'full-opacity' : ''}`}>
                    {spinnerText}<br/>
                    <img src={RedirectGif} />
                </div>
            </div>

            {/* The real modal */}
            <Modal
                modalType={2}
                show={show}
                closeModal={() =>  setShow(false)} // this modal shouldn't ever be closed
                noCloseOverlay={true}
                title='Account Confirmation'
                // This is a lie but encourage them to confirm account right away to not deal with 
                // headache later. Actual expiry time is 24 hours.
                subtitle={'Enter the verification code sent to your e-mail below.\r\n\r\nCode expires in 15 minutes.'}
            >
                <div className="modal-contents" style={{ marginTop: '40px' }}>
                    <form className="form-wrapper" onSubmit={onSubmitForm}>
                        <InputField
                            required
                            id="cognito-verification-code"
                            name='vendorfirst'
                            placeholder='Verification code'
                            altDisplay={true}
                            style={{ textAlign: 'center' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setCode(e.target.value)
                            }}
                            focus={show}
                        />

                        <BlockButton type='submit' buttonStyle='purple' style={{ width: '100%' }}>
                            SUBMIT
                        </BlockButton>
                    </form>

                    {codeVerificationError ? (
                        <div className='error-message'>
                            {codeVerificationError}<br/><br/>
                            {justVerifyEmail ? ( // Add this to every error message for users who are only verifying code.
                                "Make sure you used the same email and account type as when you registered, "
                                + "and that you haven't confirmed your email already.\r\n\r\n"
                                + "If it has been over 24 hours since you received your verification code "
                                + "then please register again."
                            ) : ''}
                        </div>
                    ): ( null )}
                    
                    {codeVerificationSuccess ? (
                        <div className='success-message'>
                            {codeVerificationSuccess}
                        </div>
                    ): ( null )}
                </div>
            </Modal>
        </div>
    )
}