
import { Product, ProductInfo } from'./Product';
import { ProductResource, ProductResourceInfo } from './ProductResource';


/**
 * {
        "id": 1,
        "uses": "A test product detail",
        "products": [
            {
                "id": 8,
                "created_date": "2022-04-21",
                "name": "Super Med",
                "brand": "Med Extra",
                "vendor": "ME",
                "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore est iure molestiae, sint laboriosam tenetur quam non temporibus praesentium earum et esse perferendis illo omnis. Dignissimos corporis aliquam iusto, at ipsum eaque incidunt earum doloremque, adipisci fugiat doloribus vel placeat sunt voluptatibus vero praesentium? Blanditiis possimus temporibus voluptate voluptas nostrum dignissimos voluptates saepe placeat cumque praesentium. Mollitia corrupti quae incidunt expedita quis omnis, quos corporis deleniti dignissimos aliquid. Nostrum rerum culpa consequatur repellat fugiat magni debitis optio accusantium impedit, laudantium nulla, ad sit ipsam. Ad quibusdam assumenda perspiciatis quos? Vel eos perferendis nostrum, necessitatibus expedita odit adipisci incidunt veniam dolores?",
                "sku": "0x134KJH24089",
                "dose": 2.0,
                "weight": 30.0,
                "deactivation_date": "2022-04-21",
                "image": "https://medngine-media.s3.amazonaws.com/media/55KM14_AS01.jpg",
                "product_type": {
                    "id": 1,
                    "type": "Pro"
                },
                "categories": [
                    {
                        "id": 1,
                        "category": "test"
                    },
                    {
                        "id": 2,
                        "category": "Med pack"
                    }
                ],
                "Prices": [
                    {
                        "id": 1,
                        "price": "100.00",
                        "price_type": null,
                        "trigger": null
                    },
                    {
                        "id": 5,
                        "price": "2.00",
                        "price_type": "tier_2",
                        "trigger": "24"
                    },
                    {
                        "id": 4,
                        "price": "1.00",
                        "price_type": "tier_3",
                        "trigger": null
                    }
                ]
            },
            {
                "id": 4,
                "created_date": "2022-04-15",
                "name": "apitest",
                "brand": "test brand",
                "vendor": "test vendor",
                "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore est iure molestiae, sint laboriosam tenetur quam non temporibus praesentium earum et esse perferendis illo omnis. Dignissimos corporis aliquam iusto, at ipsum eaque incidunt earum doloremque, adipisci fugiat doloribus vel placeat sunt voluptatibus vero praesentium? Blanditiis possimus temporibus voluptate voluptas nostrum dignissimos voluptates saepe placeat cumque praesentium. Mollitia corrupti quae incidunt expedita quis omnis, quos corporis deleniti dignissimos aliquid. Nostrum rerum culpa consequatur repellat fugiat magni debitis optio accusantium impedit, laudantium nulla, ad sit ipsam. Ad quibusdam assumenda perspiciatis quos? Vel eos perferendis nostrum, necessitatibus expedita odit adipisci incidunt veniam dolores?",
                "sku": "asdf",
                "dose": 1.0,
                "weight": 1.0,
                "deactivation_date": null,
                "image": "https://medngine-media.s3.amazonaws.com/media/6.Check_out_-_Payment_NtrLQBf_hvh8R02.png",
                "product_type": {
                    "id": 1,
                    "type": "Pro"
                },
                "categories": [
                    {
                        "id": 1,
                        "category": "test"
                    }
                ],
                "Prices": [
                    {
                        "id": 5,
                        "price": "2.00",
                        "price_type": "tier_2",
                        "trigger": "24"
                    },
                    {
                        "id": 3,
                        "price": "3.00",
                        "price_type": "tier_1",
                        "trigger": "10"
                    },
                    {
                        "id": 4,
                        "price": "1.00",
                        "price_type": "tier_3",
                        "trigger": null
                    }
                ]
            },
            {
                "id": 6,
                "created_date": "2022-04-21",
                "name": "Med Pack",
                "brand": "Acme",
                "vendor": "Chief Med",
                "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore est iure molestiae, sint laboriosam tenetur quam non temporibus praesentium earum et esse perferendis illo omnis. Dignissimos corporis aliquam iusto, at ipsum eaque incidunt earum doloremque, adipisci fugiat doloribus vel placeat sunt voluptatibus vero praesentium? Blanditiis possimus temporibus voluptate voluptas nostrum dignissimos voluptates saepe placeat cumque praesentium. Mollitia corrupti quae incidunt expedita quis omnis, quos corporis deleniti dignissimos aliquid. Nostrum rerum culpa consequatur repellat fugiat magni debitis optio accusantium impedit, laudantium nulla, ad sit ipsam. Ad quibusdam assumenda perspiciatis quos? Vel eos perferendis nostrum, necessitatibus expedita odit adipisci incidunt veniam dolores?",
                "sku": "0x134KJH24356",
                "dose": 1.0,
                "weight": 1.0,
                "deactivation_date": "2035-04-21",
                "image": "https://medngine-media.s3.amazonaws.com/media/max-medical-pack_DHQ_33ttJzc.jpg",
                "product_type": {
                    "id": 1,
                    "type": "Pro"
                },
                "categories": [
                    {
                        "id": 2,
                        "category": "Med pack"
                    }
                ],
                "Prices": [
                    {
                        "id": 1,
                        "price": "100.00",
                        "price_type": null,
                        "trigger": null
                    },
                    {
                        "id": 2,
                        "price": "999.00",
                        "price_type": null,
                        "trigger": null
                    },
                    {
                        "id": 5,
                        "price": "2.00",
                        "price_type": "tier_2",
                        "trigger": "24"
                    }
                ]
            },
            {
                "id": 7,
                "created_date": "2022-04-21",
                "name": "Double Med",
                "brand": "Med x2",
                "vendor": "Med",
                "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore est iure molestiae, sint laboriosam tenetur quam non temporibus praesentium earum et esse perferendis illo omnis. Dignissimos corporis aliquam iusto, at ipsum eaque incidunt earum doloremque, adipisci fugiat doloribus vel placeat sunt voluptatibus vero praesentium? Blanditiis possimus temporibus voluptate voluptas nostrum dignissimos voluptates saepe placeat cumque praesentium. Mollitia corrupti quae incidunt expedita quis omnis, quos corporis deleniti dignissimos aliquid. Nostrum rerum culpa consequatur repellat fugiat magni debitis optio accusantium impedit, laudantium nulla, ad sit ipsam. Ad quibusdam assumenda perspiciatis quos? Vel eos perferendis nostrum, necessitatibus expedita odit adipisci incidunt veniam dolores?",
                "sku": "0x134KJH24567",
                "dose": 2.0,
                "weight": 10.0,
                "deactivation_date": "2035-04-21",
                "image": "https://medngine-media.s3.amazonaws.com/media/LXMB50_SKD_NB-scaled-1200x1005.jpg",
                "product_type": {
                    "id": 1,
                    "type": "Pro"
                },
                "categories": [
                    {
                        "id": 2,
                        "category": "Med pack"
                    }
                ],
                "Prices": [
                    {
                        "id": 1,
                        "price": "100.00",
                        "price_type": null,
                        "trigger": null
                    },
                    {
                        "id": 2,
                        "price": "999.00",
                        "price_type": null,
                        "trigger": null
                    }
                ]
            }
        ],
        "resources": [
            {
                "id": 1,
                "name": "test",
                "description": "test",
                "file_object": "https://medngine-media.s3.amazonaws.com/media/BillingGuideCoreText_ProText.pdf"
            },
            {
                "id": 2,
                "name": "Keenan Finkelstein",
                "description": "Front",
                "file_object": "https://medngine-media.s3.amazonaws.com/media/20210204_073713.jpg"
            },
            {
                "id": 3,
                "name": "Keenan Finkelstein",
                "description": "Back",
                "file_object": "https://medngine-media.s3.amazonaws.com/media/20210204_073719.jpg"
            }
        ]
    }
 */

type DoseObj = {dose: number, sku: string, productId: number};
interface IProductGroup {
    get id(): number;
    get uses(): string;
    get products(): Product[];
    get resources(): ProductResource[];
    get doses(): DoseObj[];
    get displayProduct(): Product;
};


type ProductGroupInfo = {
    id: number,
    uses: string,
    products: ProductInfo[],
    resources: ProductResourceInfo[]
};

export class ProductGroup implements IProductGroup {
    private _id: number;
    private _uses: string;
    private _products: Product[];
    private _resources: ProductResource[];

    constructor(productDetails: ProductGroupInfo) {
        this._id = productDetails.id;
        this._uses = productDetails.uses;
        this._products = productDetails.products.map(product => new Product(product));
        this._resources = productDetails.resources.map(resource => new ProductResource(resource));
    }


    get doses(): DoseObj[] {
        return this._products
            .map(product => {
                return {
                    dose: product.dose,
                    sku: product.sku,
                    productId: product.id,
                }
            })
            .sort((a, b) => a.dose - b.dose);
    }

    get displayProduct(): Product {
        return this._products[0];
    }

    public truncateText(text: string, maxChar = 100) {
        if (text.length <= maxChar) return text.trim();
        return text.substring(0, maxChar).trim() + '...';
    }


    get id(): number {
        return this._id;
    }
    get uses(): string {
        return this._uses;
    }
    get products(): Product[] {
        return this._products;
    }
    get resources(): ProductResource[] {
        return this._resources;
    }
};