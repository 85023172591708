

export type ProductCategoryInfo = {
    id: number,
    category: string,
};

interface IProductCategory {
    get id(): number;
    get name(): string;
}

export class ProductCategory implements IProductCategory {
    private _id: number;
    private _name: string;
    constructor(category: ProductCategoryInfo) {
        this._id = category.id;
        this._name = category.category;
    }

    get id(): number {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
}

