import { useEffect, useRef, useState } from 'react';
import './input-field.scss'

interface Props {
    name: string,
    small?: boolean,
    type?: 'text' | 'number' | 'tel' | 'email' | 'password' | 'url',
    placeholder?: string,
    id?: string,
    required?: boolean,
    pattern?: string,
    altStyle?: string,
    maxLength?: number,
    style?: any,
    inputStyle?: string,
    wrapperStyle?: React.CSSProperties,
    // State variables for managing entered value.
    value?: any,
    setValue?: React.Dispatch<React.SetStateAction<number|string>>|Function,
    onChange?: any, // TODO: come up with a better type.
    onChangeRedBackground?: boolean;
    unmarkChanged?: boolean;
    setUnmarkChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    // Other event bindings
    onClick?: Function;
    onKeyUp?: Function;
    onBlur?: Function;
    // Just default value - should be a string (not a state var).
    defaultValue?: string;
    // Error/Success messages to be displayed for this input field.
    successMessage?: string,
    errorMessage?: string,
    // Modify display for error/success message display.
    altDisplay?: boolean;
    // Automatically focus the input.
    focus?: boolean;
    // Special handling for input fields that represent currency values (USD).
    // - If this is set to true, then `setValue` must also be provided in args.
    isCurrency?: boolean;
    // Chrome sometimes mis-guesses that an input field should be completed with an email/pw,
    // and auto-fills the user's email address, when it's really a different type of field.
    // Use this flag for those types of fields.
    preventAutofill?: boolean;
    // If the field should be disabled
    disabled?: boolean
}



// Styles to display after value has been changed by user (if `onChangeRedBackground` is true):
const CHANGED_STYLE = {
    backgroundColor: 'rgba(255, 0, 0, .14)',
    color: 'rgba(0, 4, 12, .75)',
}


export const InputField = ({
    name, small = false, type, placeholder, id, required, pattern, altStyle,
    maxLength, style, inputStyle, wrapperStyle, 
    value, setValue, onChange, onChangeRedBackground, unmarkChanged, setUnmarkChanged,
    onClick, onKeyUp, onBlur,
    defaultValue,
    successMessage, errorMessage,
    altDisplay, focus,
    isCurrency,
    preventAutofill,
    disabled,
}: Props) => {
    const COMPONENT_ID = name + '-'

    const [mounted, setMounted] = useState(false)
    const [changeColor, setChangeColor] = useState(false)

    const inputField = useRef() as React.MutableRefObject<HTMLInputElement>

    // On value change
    useEffect(() => { 
        if (isCurrency && setValue && !mounted) {
            formatCurrency(value, true)
            setMounted(true)
        } else if (isCurrency && setValue) {
            formatCurrency(value, false)
        }
    }, [value])

    // If `onChangeRedBrackground=true`, then this will unset the red background)
    // - (this runs whenever component is updated w/new vars/vals)
    useEffect(() => {
        if (unmarkChanged === true && setUnmarkChanged) {
            setUnmarkChanged(false)
            setChangeColor(false)
        }
    }, [unmarkChanged])

    // On focus change
    useEffect(() => {
        if (focus) {
            inputField.current?.focus()
        } else {
            inputField.current?.blur()
        }
    }, [focus])

    // This will run only when `isCurrency` is true: format the value correctly on load and on change.
    const formatCurrency = (_val: string|number, fixDecimals: boolean) => {
        if (!isCurrency || !setValue) return _val
        let newValue = String(_val).replaceAll('$', '').replaceAll(' ', '')
        if (!newValue || isNaN(Number(newValue))) return _val
        if (fixDecimals) {
            newValue = '$' + String(Number(newValue).toFixed(2))
        } else {
            newValue = '$' + newValue
        }
        setValue(newValue)
    }

    // If `isCurrency` is true, format the display (needed because it doesn't always format correctly on mount)
    const formatCurrencyInPlace = (_val: string) => {
        if (!isCurrency || !setValue || !_val || typeof _val !== 'string') return _val
        let newValue = _val.replaceAll('$', '').replaceAll(' ', '')
        if (!newValue || isNaN(Number(newValue)) || newValue[0] === '$') return _val
        return '$' + _val.replaceAll('$', '').replaceAll(' ', '')
    }

    return (
        <div
            className={
                'input-wrapper '
                + (small ? 'small' : '') + ' '
                + (altStyle ?? '') + ' '
                + (altDisplay ? 'alt' : '') + ' '
            }
            style={wrapperStyle}
        >
            <input
                ref={inputField}
                className={`input ${inputStyle ?? ''}`}
                style={{...(style || {}), ...(changeColor ? CHANGED_STYLE : {})}}
                id={id ?? COMPONENT_ID}
                name={name}
                type={type ?? 'text'}
                placeholder={placeholder}
                required={required ?? false}
                pattern={pattern}
                maxLength={maxLength}
                // Enforce maxLength for inputs of [type=number].
                onInput={(e) => {
                    if (type !== 'number' || !maxLength) return
                    return e.currentTarget.value = e.currentTarget.value.slice(0, maxLength)
                }}
                // Manage state variables for entered value
                value={value !== undefined ? formatCurrencyInPlace(value) : undefined}
                onChange={(e) => {
                    if (onChange) { onChange(e) }
                    if (onChangeRedBackground) {
                        setChangeColor(true)
                    }
                }}
                defaultValue={defaultValue}
                // This is the only way to prevent auto-fill, at least most of the time.
                // https://stackoverflow.com/questions/50347574/how-to-disable-chrome-autocomplete-feature
                autoComplete={preventAutofill ? 'new-password' : undefined}
                disabled={!!disabled}

                // Mouse/keyboard event handlers.
                onClick={(e) => {
                    if (onClick) { onClick(e) }
                }}
                onKeyUp={(e) => {
                    if (onKeyUp) { onKeyUp(e) }
                }}
                onBlur={(e) => {
                    formatCurrency(e.target.value, true)
                    if (onBlur) { onBlur(e) }
                }}
            />

            {(successMessage ? (
                <div className='success-message'>
                    {successMessage}
                </div>
            ) : (
                null
            ))}
            {(errorMessage ? (
                <div className='error-message'>
                    {errorMessage}
                </div>
            ) : (
                null
            ))}

        </div>
    )
}