import { Product } from "./Product";


interface ICartItem {
    get product(): Product;
    get qty(): number;
    set qty(qty: number);
    get price(): number;
    get uniqueKey(): string
    // get productType(): string;
};


export type ProductOrder = {
    product: Product,
    qty: number,
    sku: string,
    // productType: string
};

export class CartItem implements ICartItem {
    private _uniqueKey: string;
    private _product: Product; 
    private _qty: number;
    private _price: number;
    // private _productType: string;

    constructor(productInCart: ProductOrder) {
        this._uniqueKey = `${Date.now()}`;
        this._product = productInCart.product;
        this._qty = productInCart.qty;
        this._price = 0;
        // this._productType = productInCart.productType;
    }

    get uniqueKey(): string {
        return this._uniqueKey;
    }
    get product() {
        return this._product;
    }
    get price(): number {
        return this._price;
    }
    get qty(): number {
        return this._qty;
    }
    set qty(qty: number) {
        this._qty = qty;
    }
    // get productType(): string {
    //     return this._productType
    // }


    /**
	 * 
	 * TODO:
     * assign the cart item price according to the tier rating for the ammount ordered
	 */

    private _setPriceBasedOnTier() {
        /**
         * let tierPrice = this.product.prices[0];
         * if: in tier range
         *   - tierPrice = this.product.prices[?];
         * 
         * this._price = tierPrice;
         */
    }
}