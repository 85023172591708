import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'

import { SessionPacket } from "../../../types/session";

import { BlockButton } from "../buttons/block-button/BlockButton";
import { Modal } from "./Modal"

import parseErrorObject from '../../../helpers/parseErrorObject';

import './tag-invite-modal.scss'



type Props = {
  show: boolean;
  closeModal: Function;
  sessionObj: SessionPacket|null;
  userType: 'supplier'|'vendor'|'doctor'|'consumer'|'',
  setSuccess: Function;
  tagInviteType: 'clinic'|'rep'|''; // Only used for Reps, who can tag either clinics (providers) or other Reps.
};



const API_DB_URL = process.env.REACT_APP_API_DB_URL



export const TagInviteModal = ({ 
    show, closeModal,
    sessionObj,
    userType,
    setSuccess,
    tagInviteType,
}: Props) => {
    const [emailAddress, setEmailAddress] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    // Whenever the visibility status changes, clear all data.
    useEffect(() => {
        setEmailAddress('')
        setErrorMessage('')
        setSuccessMessage('')
        setIsLoading(false)
    }, [show])

    const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        setErrorMessage('')

        if (!sessionObj) {
            setErrorMessage('Please refresh the page or log out and login again.')
            return
        }
        // Assume sessionObj is valid if it exists.
        const sessionJson = JSON.stringify(sessionObj)
        const config = {'headers': {'content-type': 'multipart/form-data'}}
        const formData = new FormData(e.target as HTMLFormElement)
        formData.append('session', sessionJson)

        const postUrl = (userType === 'vendor' || userType === 'supplier') ? 
                            `${API_DB_URL}/vendor-tag-rep` :
                        (userType === 'consumer' && tagInviteType === 'clinic') ?
                            `${API_DB_URL}/rep-tag-clinic` :
                        (userType === 'consumer' && tagInviteType === 'rep') ?
                            `${API_DB_URL}/rep-tag-rep` :
                            'an-invalid-endpoint' // intentional - doctors can't send invites to anyone.
        axios.post(postUrl, 
            Object.fromEntries(formData), 
            config
        ).then(async (res) => {
            const data = res.data
            setIsLoading(false)
            if (data === true) {
                setSuccessMessage('Success!')
                setTimeout(() => {
                    // Clear form and message data.
                    setSuccessMessage('')
                    setErrorMessage('')
                    setEmailAddress('')
                    // Notify parent and close modal.
                    setSuccess()
                    closeModal()
                }, 600)
            } else if (data === false) {
                setErrorMessage('Error #3778: Something went wrong. Please contact an admin.')
            }
        }).catch(err => {
            setIsLoading(false)
            setErrorMessage('')
            setTimeout(() => {
                setErrorMessage(parseErrorObject(err))
            }, 350)
        })
    }, [userType, tagInviteType])

    return (
        <div className='tag-invite-modal'>
            <Modal
                modalType={2}
                show={show}
                closeModal={closeModal}
            >
                <div className="modal-contents">
                    <div className="text">
                        <h2 className="title">
                            {(userType === 'vendor' || userType === 'supplier') ? (
                                'Tag a Rep for your business'
                            ) : (userType === 'consumer' && tagInviteType === 'clinic') ? (
                                'Tag a Doctor or Clinic to represent'
                            ) : (userType === 'consumer' && tagInviteType === 'rep') ? (
                                'Tag a Rep who reports to you'
                            ) : (
                                'Send a tag invitation' // This should never happen
                            )}
                        </h2>
                        <div className='sub-title'>
                            {(userType === 'vendor' || userType === 'supplier') ? (
                                'User must be registered on Medngine as a "Rep" account '
                                + 'in order to be invited or tagged.'
                            ) : (userType === 'consumer' && tagInviteType === 'clinic') ? (
                                'User\'s medical practice must be registered on Medngine as a '
                                + '"Doctor" account order to be invited or tagged.'
                            ) : (userType === 'consumer' && tagInviteType === 'rep') ? (
                                'User must be registered on Medngine as a '
                                + '"Rep" account order to be invited or tagged.'
                            ) : (
                                '' // This should never happen
                            )}
                        </div>
                        <form onSubmit={(e) => onSubmit(e)}>
                            <input 
                                name='email'
                                type='text'
                                placeholder='Email address'
                                className='email-input'
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                            />
                            {errorMessage ? (
                                <div className='error-message'>
                                    {errorMessage}
                                </div>
                            ) : null}
                            <BlockButton
                                type='submit'
                                style={{ marginTop: '40px' }} 
                                buttonStyle="purple"
                                disabled={isLoading}
                            >
                                Invite
                            </BlockButton>
                            {successMessage ? (
                                <div className='success-message'>
                                    {successMessage}
                                </div>
                            ) : null}
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};