import { Route, Routes } from 'react-router-dom'
import { vendorRegistrationPathBase, doctorRegistrationPathBase, consumerRegistrationPathBase } from './app/constants/paths';
import { VendorRegistration } from './app/components/pages/vendor/register/VendorRegistration'
import { DoctorRegistration } from './app/components/pages/physician/register/DoctorRegistration';
import { ConsumerRegistration } from './app/components/pages/consumer/register/ConsumerRegistration';

export const Registration = () => {

    return (
        <>
            <Routes>
                <Route path={`/${vendorRegistrationPathBase}/*`} element={<VendorRegistration />} />
                <Route path={`/${doctorRegistrationPathBase}/*`} element={<DoctorRegistration />} />
                <Route path={`/${consumerRegistrationPathBase}/*`} element={<ConsumerRegistration />} />
            </Routes>
        </>
    )
}