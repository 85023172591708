/*
 * These below paths need specific behavior for the banner images and (dyanmically translucent) navbar.
 * 
 * Url structure (regex): 
 * wwww.medngine.com/vendor-registration/[1-3](\/?)
 */

// Supplier registration paths
export const supplierRegistrationPathBase: string = 'supplier-registration'
const supplierRegistrationPaths_Pre = [
    '/' + supplierRegistrationPathBase + '/1',
    '/' + supplierRegistrationPathBase + '/2',
    '/' + supplierRegistrationPathBase + '/3',
    '/' + supplierRegistrationPathBase + '/4',
    '/' + supplierRegistrationPathBase + '/5',
    '/' + supplierRegistrationPathBase + '/6',
]
export const supplierRegistrationPaths = supplierRegistrationPaths_Pre.concat(
    supplierRegistrationPaths_Pre.map(path => path + '/')
)

// Vendor registration paths
export const vendorRegistrationPathBase: string = 'vendor-registration'
const vendorRegistrationPaths_Pre = [
    '/' + vendorRegistrationPathBase + '/1',
    '/' + vendorRegistrationPathBase + '/2',
    '/' + vendorRegistrationPathBase + '/3',
    '/' + vendorRegistrationPathBase + '/4',
    '/' + vendorRegistrationPathBase + '/5',
    '/' + vendorRegistrationPathBase + '/6',
]
export const vendorRegistrationPaths = vendorRegistrationPaths_Pre.concat(
    vendorRegistrationPaths_Pre.map(path => path + '/')
)

// Doctor registration paths
export const doctorRegistrationPathBase: string = 'doctor-registration'
const doctorRegistrationPaths_Pre = [
    '/' + doctorRegistrationPathBase + '/1',
    '/' + doctorRegistrationPathBase + '/2',
    '/' + doctorRegistrationPathBase + '/3',
]
export const doctorRegistrationPaths = doctorRegistrationPaths_Pre.concat(
    doctorRegistrationPaths_Pre.map(path => path + '/')
)

// Consumer registration paths
export const consumerRegistrationPathBase: string = 'consumer-registration'
const consumerRegistrationPaths_Pre = [
    '/' + consumerRegistrationPathBase + '/1',
    '/' + consumerRegistrationPathBase + '/2',
]
export const consumerRegistrationPaths = consumerRegistrationPaths_Pre.concat(
    consumerRegistrationPaths_Pre.map(path => path + '/')
)

// "Public" pages
const publicFacingPaths_Pre = [
    '/public/home',
    '/public/category',
    '/public/about',
    '/public/contact',
    // '/public/vendor-profile', // Don't use translucent navbar here because background of the banner 
    // image is almost white. Also the title text fades out at wrong position.
    '/public/product-detail',
    '/public/doctor-profile',
]
export const publicFacingPaths = publicFacingPaths_Pre.concat(
    publicFacingPaths_Pre.map(path => path + '/')
)

