import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { useEffect } from 'react';

import { Account } from './app/components/auth/Account';
import { Auth } from './app/components/pages/auth/Auth';
import { BusinessAssociateAgreement } from './app/components/pages/business-associate-agreement/BusinessAssociateAgreement';
import { MyAccount } from './app/components/pages/my-account/MyAccount';
import { Cart } from './app/components/pages/cart/Cart';
import { CreditApplication } from './app/components/pages/credit-application/CreditApplication';
import { DesktopFooter } from './app/components/layout/desktop-footer/DesktopFooter';
import { DesktopHeader } from './app/components/layout/desktop-header/DesktopHeader';
//import { DoctorRegistration } from './app/components/pages/doctor-registration/DoctorRegistration'; --  OLD DOCTOR REGISTRATION
import { HomePage } from './app/components/pages/public/home/Home';
import { Physician } from './app/components/pages/physician/Physician';
import { Product } from './app/components/pages/product/Product';
import { Public } from './app/components/pages/public/Public';
import { Patient } from './app/components/pages/patient/Patient';
import { Rep } from './app/components/pages/rep/Rep';
import { Consumer } from './app/components/pages/consumer/Consumer';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './app/helpers/scrollToTop';
import { store } from './app/store/store';
import { VendorDashboard } from './app/components/pages/vendor/dashboard/VendorDashboard';
import { SupplierRegistration } from './app/components/pages/supplier/register/SupplierRegistration';
import { VendorRegistration } from './app/components/pages/vendor/register/VendorRegistration';
import { DoctorRegistration } from './app/components/pages/physician/register/DoctorRegistration';
import { ConsumerRegistration } from './app/components/pages/consumer/register/ConsumerRegistration';
import { Registration } from './registration';
import VersionManager from './app/components/VersionManager';

import { TestingPage } from './app/components/pages/testing/TestingPage';
import { JesseTestingPage } from './app/components/pages/testing/JesseTestingPage';

import { Admin } from './app/components/admin/Admin'

import { supplierRegistrationPathBase, vendorRegistrationPathBase, 
	doctorRegistrationPathBase, consumerRegistrationPathBase } from './app/constants/paths';

import './index.scss'

//	<Route path="/register-doctor" element={<DoctorRegistration />} />   -   OLD DOCTOR REGISTRATION




/**
 * GLOBAL-FUNCTION DECLARATIONS
 * ===========================================================================================
 * - Type definitions are in @types/app/global.d.ts
 * - These functions can be used anywhere in the react app (in .tsx files).
 */

// Call `clog(...)` anywhere in React (.tsx) code in place of `console.log(...)`.
window.clog = console.log



  


/**
 * MAIN REACT APP
 * ===========================================================================================
 */
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Account>
				<ScrollToTop />

				<VersionManager />

				<DesktopHeader />

				<Routes>
					{/* TODO: eventually replace all of these paths strings with
						constants from paths.tsx */}
					<Route path="/" element={<HomePage />} />
					<Route path="/auth/*" element={<Auth />} />

					<Route path="/admin/*" element={<Admin />} />

					<Route path="/account" element={<MyAccount />} />
					<Route path="/cart/*" element={<Cart />} />
					<Route path="/credit-application/*" element={<CreditApplication />} />
					<Route path='/physician/*' element={<Physician />} />
					<Route path='/rep/*' element={<Rep />} />
					<Route path='/product/*' element={<Product />} />
					<Route path={`/${supplierRegistrationPathBase}/*`} element={<SupplierRegistration />} />
					<Route path={`/${vendorRegistrationPathBase}/*`} element={<VendorRegistration />} />
					<Route path={`/${doctorRegistrationPathBase}/*`} element={<DoctorRegistration />} />
					<Route path={`/${consumerRegistrationPathBase}/*`} element={<ConsumerRegistration />} />
					<Route path="/register/*" element={<Registration />} />
					<Route path="/vendor-dashboard" element={<VendorDashboard />} />
					<Route path="/baa-page" element={<BusinessAssociateAgreement />} />
					<Route path='/public/*' element={<Public />} />
					<Route path='/patient/*' element={<Patient />} />
					<Route path='/consumer/*' element={<Consumer />} />

					{/* temp dev pages */}
					<Route path="/api-testing" element={<TestingPage />} />
					<Route path="/jesse-testing" element={<JesseTestingPage />} />

					{/* Don't delete this - this is for AWS monitoring on port 80 (port 443 is at "/") */}
					{/* TODO: move this to nginx */}
					<Route path="/healthcheck" element={null} />
				</Routes>

				<DesktopFooter />
			</Account>
		</BrowserRouter>
	</Provider>
	,
	document.getElementById('root')
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

