import getProductDetails from "./_fetchProductDetails";

import { ProductsById } from "../patient/product-detail/PatientProductPage";





/**
 * Types
 * ------------------------------------------------------------------------------------------------
 */
export interface Address {
    id: number,
    label?: string,
    description?: string,
    name?: string,
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
}

export enum PaymentType {
    bank = 'bank',
    credit = 'credit',
    contract = 'contract',
}

export interface PaymentMethod {
    id: number;
	type: PaymentType;
	display: string;
}

export interface PaymentAccountDetails {
    id: number;
    type: PaymentType;
    name: string;
    company: string;
    routingNumber: string;
    accountNumber: string;
}

export type PaymentAccounts = {
    [key in PaymentType]: PaymentAccountDetails[];
}




/**
 * Array functions
 * ------------------------------------------------------------------------------------------------
 */

// Get an address index position from the addresses list, by using the addressId.
export const GetAddressIndexFromId = (addressList: Address[], addressId: number): number => {
    for (let x=0; x < addressList.length; x++) {
        const address = addressList[x]
        if (address.id === addressId) {
            return x
        }
    }
    return -1
}
// Ex: Find in an array of objects the object that matches the provided "id" value.
// Returns both the item and the index, of nulls.
// Note that this does NOT behave like the other MOCK_API calls on this page.
export const getObjByIdFromList = (list: any[], property: string|number, value: string|number) => {
    for (let x=0; x < list.length; x++) {
        const item = list[x]
        if (item[property] == value) { // "==" for type-insensitive matching
            return [item, x]
        }
    }
    return [null, -1]
}
// Function to check if two arrays are the same.
const idsListsAreTheSame = (list1: number[], list2: number[]) => {
    if (list1.length !== list2.length) return false;
    const L1 = list1.sort()
    const L2 = list2.sort()
    for (let x=0; x < L1.length; x++) {
        if (L1[x] !== L2[x]) return false;
    }
    return true
}





/**
 * Shopping Cart funcs.
 * ------------------------------------------------------------------------------------------------
 */

// Get the shopping cart through AccountContext, then also get Product Details.
// - Passes in the AccountContext function (that was preloaded already in the calling component)
export const getCartAndProductDetails = async (
    sessionJson: string, 
    skuIds: React.MutableRefObject<number[]>,
    getShoppingCart: Function,
    setProductDetails: React.Dispatch<React.SetStateAction<ProductsById>>,
) => {
    // Running this func also stores the result in the AccountContext var, `shoppingCart`,
    // and also updates `shoppingCartSize` etc.
    const cartObj = await getShoppingCart(sessionJson)
    // The set the skuIds list, so we know if we have to go get new product details from the 
    // backend.
    const _sids: number[] = []
    for (let productId of Object.keys(cartObj || {})) {
        for (let skuId of Object.keys(cartObj![productId])) {
            _sids.push(Number(skuId))
        }
    }
    // If the skus list has changed, trigger a new backend call for product details.
    const hasChanged = !idsListsAreTheSame(skuIds.current, _sids)
    skuIds.current = _sids
    if (hasChanged) {
        // This will set the result to `productDetails` state var.
        await getProductDetails(sessionJson, cartObj, setProductDetails)
    }
}


