import axios from 'axios';
import { SelectOption } from "../../shared/form/select-field/SelectField";
import { Address } from './CartShared';
import parseErrorObject from '../../../helpers/parseErrorObject';



// API endpoint
const API_DB_URL = process.env.REACT_APP_API_DB_URL



// Number of characters after which to use ellipses.
const ELLIP_LEN = 15


/**
 * Function to get a list of user's address from the API.
 * Updates state variables which will then fetch updated shipping cost and 
 * sales tax rates (in _updateAddress.tsx).
 * 
 * // Get list of shipping addresses for a user.
// - If user not logged in, fail silently and return empty array, because something else will
//   alert the user.
// - For other errors, show popup alert.
 */

const _fetchAddress = async (
    sessionJson: string,
    setUserAddresses: React.Dispatch<React.SetStateAction<Address[]>>,
    setUserAddressesDropdownOptions?: React.Dispatch<React.SetStateAction<SelectOption[]>>,
): Promise<void> => {
    if (!sessionJson) return; // fail silently.

    const config = {'headers': {'content-type': 'multipart/form-data'}}
    const formData = new FormData() 
    formData.append('session', sessionJson)

    const res = await axios.post(`${API_DB_URL}/cart/get-shipping-address-list`, 
        Object.fromEntries(formData), 
        config
    ).then(async (res) => {
        const addresses = res.data || [] // array of Address objs - (assume correct shapes)
        setUserAddresses(addresses)
        const addressList: SelectOption[] = addresses.map((address: Address, x: number) => {
            let optionDisplayText = address.label || address.description || address.street1
            // Prevent the text from getting too long.
            if (optionDisplayText.length > ELLIP_LEN) {
                optionDisplayText = optionDisplayText.slice(0, ELLIP_LEN) + '...'
            }
            return { option: optionDisplayText, value: optionDisplayText }
        })
        if (setUserAddressesDropdownOptions) {
            setUserAddressesDropdownOptions(addressList)
        }
    }).catch(err => {
        const eMsg = parseErrorObject(err, '#14450')
        alert(eMsg)
    })
}

export default _fetchAddress