/**
 * For Reps users. Shows reps they have been tagged by.
 * - At most there should be one row in this table.
 * - If the rep signed up as a "rep-member" (or an existing rep-org), then the rep-lead 
 *   accepts their membership, then the rep lead will be this tag.
 * - If another rep tags them and they accept the tag, then it will deactivate the 
 *   tag with the rep-lead and tag this user to the rep who sent the tag instead.
 * - If the rep-lead never approves their membership, and a rep within a (any) rep-org
 *   tags them and they accept, then they will become a member of that rep or and the 
 *   rep-lead doesn't have to confirm the invite.
 */

import { useEffect, useState } from 'react';

import { Icon } from '../../shared/icon/Icon';
import { TagAcceptModal } from '../../shared/modal/TagAcceptModal';

import { MyAccountInfo, TaggedReps } from '../../../../config'



interface Props {
    getTagsListError: string;
    tagsData_RtoR_sent: TaggedReps[];
    tagsData_RtoR_received: TaggedReps[];
    untagRep: Function;
    userId: number;
    userType: 'consumer';
    accountInfo: MyAccountInfo;
}



export const TaggedByReps_Rep = ({
    getTagsListError,
    tagsData_RtoR_sent,
    tagsData_RtoR_received,
    untagRep,
    userId, userType, accountInfo,
}: Props) => {
    const [showAcceptTagModal, setShowAcceptTagModal] = useState(false)
    const [fromName, setFromName] = useState('')
    const [fromEmail, setFromEmail] = useState('')
    const [fromOrgName, setFromOrgName] = useState('') // rep-org name
    const [uuid, setUuid] = useState('')
    // If this isn't needed, it will be an empty array
    const [sentRepMemRow, setSentRepMemRow] = useState<any[]>([])
    const [shouldUseSentRepRow, setShouldUseSentRepRow] = useState(false)

    // When accept-tag modal is closed, clear the vars.
    useEffect(() => {
        if (!showAcceptTagModal) {
            setFromName('')
            setFromEmail('')
            setFromOrgName('')
            setUuid('')
        }
    }, [showAcceptTagModal])

    // For rep-members who, during reg, said they were member of a particular rep-org, and they
    // haven't subsequently been tagged by another rep in the rep org, they will only have a "sent"
    // registration invite to the org.
    // - Find that db row and store the data in `sentRepMemRow`.
    // - If one is found, it will be displayed before the other rows (see template logic).
    useEffect(() => {
        if (tagsData_RtoR_sent.length && accountInfo) {
            const repMemRow = tagsData_RtoR_sent.find(row => {
                return (
                    row.active !== false // will be null for open invitations
                    && row.on_reg
                    && row.from_uid === accountInfo.u_id
                    && !row.from_roid && !row.from_bid && !row.to_cid
                    && row.to_roid
                )
            })
            if (repMemRow) {
                setSentRepMemRow([repMemRow])
            }
        }
    }, [
        tagsData_RtoR_sent, tagsData_RtoR_sent.length, accountInfo, 
        tagsData_RtoR_received, tagsData_RtoR_received.length,
    ])


    // Condition to prevent display of this modal.
    if ((!tagsData_RtoR_received.length && !sentRepMemRow.length) || accountInfo.u_types.is_rep_lead) {
        return null
    }

    
    return (
        <div className='section'>
            {/* Modal to accept tag invite (but not decline, which is handled by MyAccount.tsx) */}
            {(showAcceptTagModal && fromEmail && uuid) ? (
                <TagAcceptModal
                    show={showAcceptTagModal}
                    closeModal={() => setShowAcceptTagModal(false)}
                    title='Accept Invitation from:'
                    subtitle={[fromOrgName, fromName, fromEmail].filter(v => !!v).join('\r\n')}
                    message={"Note that this will replace any pre-existing tag to your rep "
                        + "organization, which may be through another user."}
                    uuid={uuid}
                />
            ) : null}
            
            <div className='title-row'>
                <div className='section-title'>
                    My Organization
                </div>
            </div>
            
            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            <table className='info-table'>
                <thead>
                    <tr>
                        <td>Rep Organization</td>
                        <td>Tagged by</td>
                        <td>Status</td>
                        <td>Invited</td>
                        <td>Accepted</td>
                    </tr>
                </thead>
                <tbody>
                    {(sentRepMemRow.concat(tagsData_RtoR_received)).map((row, index) => (
                        <tr 
                            key={index + '--' + row.to_uid}
                            className='tag-row'
                            style={{ cursor: 'default' }}
                            title={
                                `Invitation from:\r\n`
                                + `    Email: ${row.from_email}\r\n`
                                + `    ${row.from_name ? `Rep: ${row.from_name}\r\n` : ''}`
                                + `    ${row.from_reporg_name ? `Rep Org: ${row.from_reporg_name}` : ''}`
                            }
                        >
                            <td className='title medium product-cell'>
                                {/* It will usually be in `from_reporg_name`, unless user was a new 
                                  * sign up who requested membership in this org
                                  */}
                                {row.from_reporg_name || row.to_reporg_name}
                            </td>
                            <td className='medium product-cell'>
                                {row.from_name}
                            </td>
                            <td className='smaller product-cell'>
                                {(row.active) ? (
                                    'Active'
                                ) : (row.active === false) ? (
                                    'Deactivated' // This should never show bc it's filtered out on backend.
                                ) : (
                                    <span style={{color: '#f34'}}>
                                        Pending
                                    </span>
                                )}
                            </td>
                            <td className='small product-cell'>
                                {row.invited_at}
                            </td>
                            <td className='small product-cell'>
                                {(row.active) ? (
                                    row.accepted_at
                                ) : (row.active === false) ? (
                                    'Declined' // (this should never show bc these rows are filtered out.)
                                ) : ( // row.active === null
                                    // Button to show the accept-invite modal.
                                    <button 
                                        type='button' 
                                        className='accept-invite-button'
                                        onClick={() => {
                                            setShowAcceptTagModal(true)
                                            setFromOrgName(row.from_reporg_name)
                                            setFromName(row.from_name)
                                            setFromEmail(row.from_email)
                                            setUuid(row.uuid)
                                        }}
                                    >
                                        Accept
                                    </button>
                                )}
                            </td>
                            <td className='smallest untag-cell'>
                                {/* untag */}
                                <button 
                                    type='button'
                                    className='untag-button'
                                    onClick={() => {
                                        untagRep(
                                            'deactivate', null, false,
                                            userId, userType, accountInfo, false,
                                            row.from_roid, row.to_roid,
                                            row.from_reporg_name, row.to_reporg_name,
                                            row.from_bid, row.from_biz_name, 
                                            row.from_uid, row.to_uid, row.from_name, row.to_name, 
                                            row.to_cid, row.to_clinic_name,
                                            row.active)
                                    }}
                                >
                                        <Icon name='circle-x' />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}