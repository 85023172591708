/**
 * For 'rep-lead' users to see a table/list if ALL of the reps in their company.
 * 
 * Note: 
 *  Deleting a tag from this table goes through a slightly different mechanism than
 *  all of the other `untagRep()` function calls (on both the frontend and the backend).
 *    
 */

import { useEffect, useState } from 'react';

import { BlockButton } from '../../shared/buttons/block-button/BlockButton';
import { Icon } from '../../shared/icon/Icon';
import { MyAccountInfo, RepOrgAllReps, TaggedReps } from '../../../../config';
import { TagReassignModal } from '../../shared/modal/TagReassignModal';
import { TagAcceptModal } from '../../shared/modal/TagAcceptModal';
import { TagTreeModal } from '../../shared/modal/TagTreeModal';

import LoadingCircle from '../../../static/gif/loading-circle.gif'



interface Props {
    getTagsListError: string;
    tagsData_RtoR_sent: TaggedReps[];
    tagsData_RtoR_received: TaggedReps[];
    untagRep: Function;
    allRepOrgReps: RepOrgAllReps[];
    accountInfo: MyAccountInfo;
    // For showing the reassign modal.
    showTagReassignModal: boolean|null;
    setShowTagReassignModal: React.Dispatch<React.SetStateAction<boolean|null>>;
    // For updating reps list (and their corresponding red coloring for checking errors)
    getAllRepOrgReps: Function;
    // For tag tree
    getAllRepOrgRepsTree: Function;
    allRepOrgRepsTree: any;
    setAllRepOrgRepsTree: React.Dispatch<React.SetStateAction<any>>;
    allRepOrgRepsTreeUserCount: number|null;
    setAllRepOrgRepsTreeUserCount: React.Dispatch<React.SetStateAction<number|null>>;
    tagTreeIsLoading: boolean;
    tagTreeErrorMessage: string;
    // For checking for tag cycles and the corresponding output message.
    checkForRepTagCycles: Function;
    showTagCyclesSpinner: boolean;
    setShowTagCyclesSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    tagCyclesMessage: string;
}




export const TaggedReps_RepLead = ({
    getTagsListError,
    tagsData_RtoR_sent,
    tagsData_RtoR_received,
    untagRep,
    allRepOrgReps,
    accountInfo,
    showTagReassignModal, setShowTagReassignModal,
    getAllRepOrgReps,
    getAllRepOrgRepsTree, allRepOrgRepsTree, setAllRepOrgRepsTree, tagTreeIsLoading, tagTreeErrorMessage,
    allRepOrgRepsTreeUserCount, setAllRepOrgRepsTreeUserCount,
    checkForRepTagCycles, showTagCyclesSpinner, setShowTagCyclesSpinner, tagCyclesMessage,
}: Props) => {
    const [showAcceptTagModal, setShowAcceptTagModal] = useState(false)
    const [fromName, setFromName] = useState('')
    const [fromEmail, setFromEmail] = useState('')
    const [fromOrgName, setFromOrgName] = useState('') // rep-org name
    const [altPath, setAltPath] = useState('')
    const [action, setAction] = useState<'deactivate'|'reassign'>('deactivate')
    const [reassignTo, setReassignTo] = useState('')
    const [toUserName, setToUserName] = useState('')
    const [toUserEmail, setToUserEmail] = useState('')
    const [confirmed, setConfirmed] = useState(false)
    const [activeRow, setActiveRow] = useState<any>(null)
    const [forceReassign, setForceReassign] = useState(false)
    // For tag tree
    const [showTagTreeModal, setShowTagTreeModal] = useState(false)


    // When accept-tag modal is closed, clear the vars.
    useEffect(() => {
        if (!showAcceptTagModal) {
            setFromName('')
            setFromEmail('')
            setFromOrgName('')
            setAltPath('')
        }
    }, [showAcceptTagModal])
    
    // When reassign modal is closed, clear the vars. 
    // - AND ALSO re-run funcs to get tags list and check for circular reference errors, bc that
    //   may be the reason that the user is reassigning.
    useEffect(() => {
        if (showTagReassignModal === false) { // (this inits to `null`)
            setAction('deactivate')
            setReassignTo('')
            setConfirmed(false)
            setActiveRow(null)
            setForceReassign(false)
            // Refresh the "All Reps" table and also check for circular references again.
            checkForRepTagCycles(accountInfo.u_id) // no await
            getAllRepOrgReps(accountInfo.u_id) // no await
        }
    }, [showTagReassignModal])

    // When TagReassignModal is confirmed.
    useEffect(() => {
        if (confirmed && activeRow) {
            setConfirmed(false)

            // This `untagRep` func has args that looks different from
            // the others. 
            // - Put the org_id in the "from" args, but use the "to_uid" 
            //   arg instead of "from_uid"
            //   because the button to call this function for will only
            //   show for tags that aren't the on-reg tags (that tag "up").
            
            untagRep(
                action, reassignTo, forceReassign,
                accountInfo.u_id, 'consumer', accountInfo, false,
                activeRow.rep_org_id, 0, 
                activeRow.rep_org_name, '',
                0, '', 
                0, activeRow.user_id, '', activeRow.user_name,
                0, '',
                activeRow.active,
                true
            )
        }
    }, [confirmed, action, accountInfo, activeRow, reassignTo, forceReassign])

    /**
     * Functions to check problems with tag rows (ie lack of "from" tag or tag cycles).
     * - IMPORTANT: any function that is added here (prob) needs to be added to `HAS_PROBLEMS()`.
     */
    const _hasMultipleParents = (row: RepOrgAllReps) => {
        return (!row.on_reg && (row.tagged_by_ids?.length || 0) > 1)
               || (row.on_reg && (row.tagged_to_ids?.length || 0) > 1)
    }
    const _hasNoParentsButIsActive = (row: RepOrgAllReps) => {
        return (!row.on_reg && row.active && !row.tagged_by_ids?.length)
               || (row.on_reg && row.active && !row.tagged_to_ids?.length)
    }
    const HAS_PROBLEMS = (row: RepOrgAllReps) => {
        return (
            _hasMultipleParents(row)
            || _hasNoParentsButIsActive(row)
        )
    }
    
    // Condition to prevent display of this modal.
    if (
        !tagsData_RtoR_sent.find(t => t.active)
        && !tagsData_RtoR_received.find(t => t.active)
        && !allRepOrgReps.find(t => t.active)
    ) {
        return null
    }

    return (
        <div className='section'>
            {/* Modal for deactivating or reassigning a tag */}
            {/* activeRow conditional REQUIRED for wrapper, so that the contents reset when row is changed */}
            {(showTagReassignModal && activeRow) ? (
                <TagReassignModal
                    show={showTagReassignModal}
                    closeModal={() => setShowTagReassignModal(false)}
                    title={`${action.slice(0,1).toUpperCase() + action.slice(1)} ${toUserName}`}
                    subtitle={toUserEmail}
                    message={``}
                    action={action}
                    reassignTo={reassignTo}
                    setReassignTo={setReassignTo}
                    setAction={setAction}
                    onConfirm={() => setConfirmed(true)}
                />
            ) : null}

            {/* Modal to accept tag invite (but not decline, which is handled by MyAccount.tsx) */}
            {(showAcceptTagModal && fromEmail && altPath) ? (
                <TagAcceptModal
                    show={showAcceptTagModal}
                    closeModal={() => setShowAcceptTagModal(false)}
                    title='Accept Membership Request from:'
                    subtitle={[fromOrgName, fromName, fromEmail].filter(v => !!v).join('\r\n')}
                    message="Confirm that this user is part of your organization."
                    uuid={''}
                    altPath={altPath}
                />
            ) : null}

            {(showTagTreeModal) ? (
                <TagTreeModal
                    show={showTagTreeModal}
                    tagTreeData={allRepOrgRepsTree}
                    tagTreeUserCount={allRepOrgRepsTreeUserCount}
                    flatData={allRepOrgReps}
                    accountInfo={accountInfo}
                    tagTreeIsLoading={tagTreeIsLoading}
                    tagTreeErrorMessage={tagTreeErrorMessage}
                    closeModal={() => {
                        setShowTagTreeModal(false)
                        setAllRepOrgRepsTree(null)
                        setAllRepOrgRepsTreeUserCount(null)
                    }}
				/>
            ) : null}

            <div className='title-row'>
                <div className='section-title'>
                    {/* All Reps in <span style={{fontStyle: 'italic'}}>{allRepOrgReps[0].rep_org_name}</span> */}
                    All Reps
                </div>
            </div>
            {getTagsListError ? (
                <div className='error-message'>
                    {getTagsListError}
                </div>
            ) : null}

            <table className='info-table'>
                <thead>
                    <tr>
                        <td>Name [ID]</td>
                        <td>Status</td>
                        <td>Created</td>
                        <td>Updated</td>
                    </tr>
                </thead>
                <tbody>
                    {/* Don't show the rep-lead in this table. But show every other user, even deactivated. */}
                    {allRepOrgReps.filter(row => row.user_id !== accountInfo.u_id).map((row, index) => (
                        <tr 
                            key={index + '--' + row.user_id + 3}
                            className='tag-row'
                            style={{ cursor: 'default' }}
                            title={
                                `${
                                    (_hasMultipleParents(row)) ?
                                        'WARNING:\r\nThis user is tagged by multiple users, which indicates '
                                            + 'that the tag structure is broken. Please '
                                            + 'fix ASAP to prevent problems.\r\n\r\n' 
                                            + 'To fix: Reassign this user to yourself.\r\n\r\n'
                                        : ''
                                }`
                                + `${
                                    (_hasNoParentsButIsActive(row)) ?
                                        `WARNING:\r\nThis user is marked active but doesn't have a tag from `
                                            + 'anyone in this organization.\r\n\r\n'
                                            + 'To fix: Assign this user to yourself or another rep in '
                                            + 'your organization.\r\n\r\n'
                                        : ''
                                }`
                                + `Status: ${
                                    (row.active === null && row.on_reg) ? 'Requested'
                                    : (row.active === null) ? 'Invited' 
                                    : (row.active === false) ? 'Inactive' 
                                    : 'Active'
                                }\r\n`
                                + `Tagged by: ${
                                    (row.on_reg ? row.tagged_to_strs : row.tagged_by_strs)?.join(', ') || '(none)'
                                }\r\n`
                                + `Tags: ${
                                    (row.on_reg ? row.tagged_by_strs : row.tagged_to_strs)?.join(', ') || '(none)'
                                }`
                            }
                            onClick={() => {
                                setToUserName(row.user_name)
                                setToUserEmail(row.user_email)
                                if (HAS_PROBLEMS(row)) {
                                    setForceReassign(true)
                                } else (
                                    setForceReassign(false)
                                )
                            }}
                        >
                            <td className='title medium product-cell'>
                                {/* <span className='info-icon'>&#9432;</span> */}
                                <div className='shrinkable-name'>

                                    {/* Show the user's name in red if there are problems */}
                                    <span style={{ color: `${((_hasMultipleParents(row)) || (_hasNoParentsButIsActive(row))) ? '#f34' : ''}`}}>
                                        {row.user_name} [{row.user_id}]
                                    </span>

                                    {/* If this user is tagged by multiple users (indicates cycle). */}
                                    {(_hasMultipleParents(row)) ? (
                                        <div className='warning-icon'>
                                            <Icon name='cycle'/>
                                        </div>
                                    ) : null}

                                    {/* If this user is `active=true` but isn't tagged by anyone. */}
                                    {(_hasNoParentsButIsActive(row)) ? (
                                        <div className='warning-icon'>
                                            <Icon name='exclamation-triangle'/>
                                        </div>
                                    ) : null}
                                </div>
                                {/* The name and email of the user who tagged this user */}
                                {/* <div className='popout-tagger-info'>
                                    {[row.from_name]}
                                </div> */}
                            </td>
                            <td className='smaller product-cell'>
                                {(row.active) ? (
                                    'Active'
                                ) : (row.active === false) ? (
                                    'Deactivated' // This should never show bc it's filtered out on backend.
                                ) : (
                                    <>
                                    <span style={{color: '#f34'}}>
                                        Pending
                                    </span>
                                    {/* Button to show the accept-invite modal. */}
                                    <button 
                                        type='button' 
                                        className='accept-invite-button'
                                        onClick={() => {
                                            setShowAcceptTagModal(true)
                                            setFromOrgName('')
                                            setFromName(row.user_name)
                                            setFromEmail(row.user_email)
                                            setAltPath(`accept-rep-member/${row.rep_org_id}/${row.user_id}`)
                                        }}
                                    >
                                        Accept
                                    </button>
                                    </>
                                )}
                            </td>
                            <td className='small product-cell'>
                                {row.created_at}
                            </td>
                            <td className='small product-cell'>
                                {row.updated_at}
                            </td>
                            <td className='smallest untag-cell'>
                                {/* untag */}
                                <button 
                                    type='button'
                                    className='edit-tag-button'
                                    onClick={() => {
                                        setActiveRow(JSON.parse(JSON.stringify(row)))
                                        setShowTagReassignModal(true)
                                    }}
                                >
                                    {/* <Icon name='circle-x' /> */}
                                    <Icon name='edit' />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {(showTagCyclesSpinner) ? (
                <div className='tag-cycles-message'>
                    <img src={LoadingCircle} className='loading-spinner for-tag-cycles' />
                </div>
            ) : (tagCyclesMessage) ? (
                //  The red-colored rows with title text description is good, but this is a little extra,
                // just in case a problem user isn't identified by above template logic.
                <div className={'tag-cycles-message' + (tagCyclesMessage.includes('WARNING') ? ' red' : '')}>
                    {tagCyclesMessage}
                </div>
            ) : null}

            <BlockButton
                type='button'
                buttonStyle='ghost-mid less-pad'
                onClick={() => {
                    setShowTagTreeModal(true)
                    getAllRepOrgRepsTree(accountInfo.u_id)
                }}
                style={{
                    marginTop: '20px',
                    padding: '0 16px',
                    height: '36px',
                    width: 'fit-content',
                    fontSize: '1.4rem',

                }}
                disabled={showTagTreeModal}
            >
                View Hierarchy
            </BlockButton>
        </div>
    )
}