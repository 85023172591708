import { createStore, applyMiddleware, Action, combineReducers } from "redux";
import thunk from "redux-thunk";
import { HippaTimeout } from "../models/HippaTimeout";
import { Product } from "../models/Product";
import { GlobalActions } from "./actions/_global-actions";
import { SectionHeaderPayload } from "./actions/header-title-actions";
import { cart, currentProduct, products, selectedPaymentMethod, stateTax, TaxRateMap, taxRates } from "./reducers/cart-flow";
import { sectionHeader } from "./reducers/page";
import { user } from "./reducers/user";
import { ProductGroup } from "../models/ProductGroup";
import { TaxRate } from "../models/TaxRate";


export const reducers = combineReducers({
    user,
    cart,
    taxRates,
    stateTax,
    products,
    sectionHeader,
    currentProduct,
    selectedPaymentMethod,
});

export type StoreItems = {
    sectionHeader: SectionHeaderPayload | null,
    selectedPaymentMethod: any,
    user: any,
    cart: any,
    taxRates: TaxRateMap,
    stateTax: TaxRate,
    products: ProductGroup[],
    currentProduct: ProductGroup
}


export const store = createStore(reducers, applyMiddleware(thunk));


/**
 * HIPPA: remove info from store per time requirement
 */
setInterval(() => {
    store.dispatch({ type: GlobalActions.HIPPA_RESET, payload: null });
}, HippaTimeout.getMinutesInMilliseconds(15));

