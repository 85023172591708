import axios from "axios";
import { config } from "../../../config";
import { TaxRate } from "../../models/TaxRate";
import { TaxRateMap } from "../reducers/cart-flow";

export enum CheckOutActionsEnum {
    UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
    GET_TAX_RATES = 'GET_TAX_RATES',
    GET_TAX_BY_STATE = 'GET_TAX_BY_STATE',
};


export const updatePaymentMethod = (paymentMethod: any) => {
    return {
        type: CheckOutActionsEnum.UPDATE_PAYMENT_METHOD,
        payload: paymentMethod
    };
};


export const getAllTaxRates = () => {
    return async (dispatch: any, getState: any) => {
        const { taxRates } = getState();
        let taxes: TaxRateMap = new Map();

        if(taxRates && (taxRates.size > 0)) {
            dispatch({
                type: CheckOutActionsEnum.GET_TAX_RATES,
                payload: taxRates,
            });
            return;
        }

        try {
            const response = await axios.get(config.apiBase + '/taxrate/', {
                headers: {
                    'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
                    'content-type': 'application/json',
                }
            });

            if (response.data) {
                response.data.forEach((rate: any) => taxes.set(rate.location_code, new TaxRate(rate)));
            }            

            dispatch({
                type: CheckOutActionsEnum.GET_TAX_RATES,
                payload: taxes
            });

        } catch (err) {
            console.log(err);
            dispatch({
                type: CheckOutActionsEnum.GET_TAX_RATES,
                payload: taxRates
            });
        }
    }
};



export type StateAbbr = 'AL'|'AK'|'AZ'|'AR'|'CA'|'CO'|'CT'|'DE'|'FL'|'GA'|'HI'|'ID'|'IL'|'IN'|'IA'|'KS'|'KY'|'LA'|'ME'|'MD'|'MA'|'MI'|'MN'|'MS'|'MO'|'MT'|'NE'|'NV'|'NH'|'NJ'|'NM'|'NY'|'NC'|'ND'|'OH'|'OK'|'OR'|'PA'|'RI'|'SC'|'SD'|'TN'|'TX'|'UT'|'VT'|'VA'|'WA'|'WV'|'WI'|'WY';
export const getTaxRateByState = (state: StateAbbr) => {
    return async (dispatch: any, getState: any) => {
        const { taxRates } = getState();
        let taxes: TaxRateMap = new Map();

        if(taxRates && (taxRates.size > 0)) {
            dispatch({
                type: CheckOutActionsEnum.GET_TAX_BY_STATE,
                payload: taxRates.get(state),
            });
            return;
        }

        try {
            const response = await axios.get(config.apiBase + '/taxrate/', {
                headers: {
                    'Authorization': 'Token 25ebb5f16ba77cc4bf845ff4e2d0e5aa48e79def',
                    'content-type': 'application/json',
                }
            });

            if (response.data) {
                response.data.forEach((rate: any) => taxes.set(rate.location_code, new TaxRate(rate)));                
                dispatch({
                    type: CheckOutActionsEnum.GET_TAX_BY_STATE,
                    payload: taxes.get(state),
                });

                dispatch({
                    type: CheckOutActionsEnum.GET_TAX_RATES,
                    payload: taxes
                });            
                return;
            }
            
            dispatch({
                type: CheckOutActionsEnum.GET_TAX_BY_STATE,
                payload: taxRates.get(state),
            });

        } catch (err) {
            console.log(err);
            dispatch({
                type: CheckOutActionsEnum.GET_TAX_BY_STATE,
                payload: taxRates.get(state),
            });
        }
    }
};