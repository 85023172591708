import { ProductOrder } from "../../models/CartItem";
import { Product } from "../../models/Product";

export enum CartActions {
    ADD_PRODUCT     = 'ADD_PRODUCT',
    REMOVE_PORDUCT  = 'REMOVE_PORDUCT',
    UPDATE_PRODUCT  = 'UPDATE_PRODUCT',
};



export const addOrderToCart = (order: ProductOrder) => {
    return {
        type: CartActions.ADD_PRODUCT,
        payload: order
    };
};


export const removeProductFromCart = (product: Product) => {
    return {
        type: CartActions.REMOVE_PORDUCT,
        payload: { product }
    };
};


export const editOrderInCart = (product: Product) => {
    return {
        type: CartActions.UPDATE_PRODUCT,
        payload: { product }
    };
};