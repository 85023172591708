
export enum UserAction {
    GET_USER = 'GET_USER',
};




export const getUser = () => {
    /**
     * TODO:
     * - see if user exists else make API call to get user else redirect to login
     */

    return {
        type: UserAction.GET_USER,
        payload: {
            id: 123,
            firstName: 'John',
            lastName: 'Smith',
        }
    };
};