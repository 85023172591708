import './image-carousel.scss'
import React from 'react';
import ImageOne from "./images/c-image1.png";
import ImageTwo from "./images/c-image2.jpg";
import ImageThree from "./images/c-image3.jpg";






export const ImageCarousel = (props: any) => {

  return (
    <div className="display-images">
      <img className="img1" src={ImageOne} />
      <img className="img2" src={ImageTwo} />
      <img className="img3" src={ImageThree} />
      <div className="carousel-container1">

        <div className="carousel1">
          <div className="item main">
            <img src={ImageOne} />
          </div>
          <div className="item">
            <img src={ImageTwo} />
          </div>
          <div className="item">
            <img src={ImageThree} />
          </div>
        </div>
      </div>
      <div className="dot-position">
        <ul className="ul-test">
          <li className="li-test" />
          <div className="dot-padding" />
          <li className="li-test test2" />
          <div className="dot-padding" />
          <li className="li-test test3" />
        </ul>
      </div>
    </div>

  );
};

