import './test-button2.scss';
import ArrowIcon from './circlearrow.svg';
import { ReactComponent as ArrowIcon2 } from './circlearrow.svg';

interface Props {
  children?: any;
}

export const TestButton2 = ({ children }: Props) => {
  return <button className="mainbutton"><img src={ArrowIcon} className="icon" />{children}</button>;
}

export const TestButton3 = ({ children }: Props) => {
  return <button className="mainbutton">{children}<ArrowIcon2 className="icon-padding" fill="currentColor" stroke="null" /></button>;
}

