import React from 'react';
import { Link } from "react-router-dom";

import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";
import { ProgressBar } from "../../../shared/progress-bar/ProgressBar";

import './doctor-registration.scss';



export const DoctorRegistration3 = () => {
    const STEP: number = 3


    return (
        <div>
            <HeaderBanner
                imageName="doctorpen"
                imageText="MEDICAL PRACTICE"
                imageSpacerColor='BLUE'
            />
            <div className="doctor-register-page-layout">
                <div className="progress-bar-placement">
                    <ProgressBar whichStep={3} numberOfSteps={3} />
                </div>
                <div className="body-container form-layout">
                    <div className="header" style={{ textAlign: 'center' }}>
                        REGISTRATION COMPLETE!
                    </div>
                    <br/><br/><br/>
                    <div style={{ lineHeight: 2, fontSize: '2rem', textAlign: 'center' }}>
                        You and your medical practice are now searchable in the nav bar. You can modify 
                        these details at any time by clicking over to your account page. 
                    </div>
                </div>
                <div 
                    className="body-container-no-vert-pad buttons-container" 
                    style={{ justifyContent: 'center' }}
                >
                    <Link to={`/account`}>
                        <BlockButton buttonStyle="purple">
                            MY ACCOUNT
                        </BlockButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}