import { Link } from 'react-router-dom';

import hash from '../../../../helpers/hashCode';

import './nav-account-dropdown.scss';
import { useEffect } from 'react';



interface Props {
    navName: string;
    /* Choose 1 of 2 below */
    // Option 1: use all three of these if you want to have a nav dropdown menu
    navLinks?: string[];
    navLinksPaths?: string[];
    // Whether the parent of the dropwdown should act as a link, too.
    navClickableParent?: boolean,
    // Option 2: use this if there's no dropdown menu (so clicking the link should go somewhere)
    navLinkSingle?: 'HOME' | 'ABOUT' | 'CONTACT' | 'PRODUCTS';
    // always use this v
    openLeft?: boolean;
}

export const NavDropdown = ({ 
    navName, navLinks=[], navLinksPaths=[], openLeft, navLinkSingle, navClickableParent=true,
}: Props) => {

    // Paths for single links.
    const LINKS = {
        ABOUT: '/public/about',
        HOME: '/public/home',
        CONTACT: '/public/contact',
        PRODUCTS: '/public/vendor-profile'
    }

    return (
        <div className="acc-dropdown">
            <div className="nav-bar">
                {/* For non-clickable menu item */}
                {(!navClickableParent) ? (
                    <div className='dropbtn' style={{cursor: 'pointer'}}>
                        {navName}
                    </div>
                ) : (!navLinkSingle) ? (
                    <a href="javascript:void(0)" className="dropbtn">
                        {navName}
                    </a>

                ) : (
                    <Link to={LINKS[navLinkSingle]} className="dropbtn">{navLinkSingle}</Link>
                )}

                {/* For dropdown menu */}
                {(navLinks.length && navLinks.length === navLinksPaths.length) ? (
                    <div className={`dropdown-content ${openLeft ? 'open-left' : ''}`}>
                        <div className="filler"></div>
                        <div className="small-arrow"></div>

                        {/* Dropwdown links */}
                        {(navName.toUpperCase() === 'CONTACT') ? (
                            // If type is contact, then give usable links for phone & email.
                            <>
                                <a href='tel:1-844-422-8607' className='contact-link'>
                                    1-844-422-8607
                                </a>
                                <a href='mailto:info@regenativelabs.com' className='contact-link'>
                                    info@regenativelabs.com
                                </a>
                            </>
                        ) : (
                            // ...otherwise show normal links 
                            navLinks.map((navItemName, index: number) => (
                                <Link 
                                    key={hash(navItemName)} 
                                    to={navLinksPaths[index] || '#'}
                                >
                                    {navItemName}
                                </Link>
                            ))
                        )}
                    </div>
                ) : (
                    null
                )}
            </div>
        </div>
    )
}