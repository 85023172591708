import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "../../../auth/Account";

import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { HeaderBanner } from "../../../layout/header-banner/HeaderBanner";

import { Address, GetAddressIndexFromId, PaymentAccountDetails, PaymentType, getCartAndProductDetails } from "../CartShared";
import { CookieNames, getCookie } from "../../../../cache/Cookie_General";
import { CartDict } from "../../../../cache/Cart_Types";
import { PAYMENT_METHODS } from "../../../../../config";
import { SessionPacket } from "../../../../types/session";
import getAllDeliveryDates from "../check-out/_getAllDeliveryDates";
import getUserAddresses from "../_fetchAddress";
import getUserPaymentAccounts from "../check-out/_fetchUserPaymentAccounts";
import OrdersTable from "../check-out/_OrdersTable";
import numberFormatter from '../../../../helpers/numberFormatter'

import { ProductsById } from "../../patient/product-detail/PatientProductPage";

import PrintOnlyContent from "../../../shared/print/PrintOnlyContent";
import "./check-out-confirmation.scss";




const API_DB_URL = process.env.REACT_APP_API_DB_URL

const EMPTY_NOTES_PLACEHOLDER = '(empty)'




/**
 * Component for user to verify their shopping cart items, address, payment methods, etc
 * before finalizing a purchase order.
 */
export const CheckOutConfirmation = () => {
	// Account context for getting an Order ID
	const { getUsernameAndIdToken, getAddressId, getCartNote, getShoppingCart,
			shoppingCart, getPaymentMethodIds } = useContext(AccountContext)

	/** State variables */
	// From the API - dictionary of products and their details:
	const [productDetails, setProductDetails] = useState({} as ProductsById)
	// User's shipping address that was selected on the previous screen.
	const [userAddressesList, setUserAddressesList] = useState([] as Address[])
	const [address, setAddress] = useState({} as Address)
	// User's notes from previous screen.
	const [notes, setNotes] = useState('')
	// All distinct delivery dates, in order.
	const [deliveryDates, setDeliveryDates] = useState([] as string[])
	// User's selected payment method/account IDs from previous screen.
	const [paymentMethodType, setPaymentMethodType] = useState<PaymentType|null>(null)
	const [paymentMethodDescriptor, setPaymentMethodDescriptor] = useState('')
	// The payment accounts API returns in the format of dropdown options.
	const [paymentMethodId, setPaymentMethodId] = useState<number|null>(null)
	const [paymentAccountId, setPaymentAccountId] = useState<number|null>(null)
	const [paymentAccounts, setPaymentAccounts] = useState([] as PaymentAccountDetails[])
	// Total cart price
	const [finalPrice, setFinalPrice] = useState(0)
	// Session
	const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
	const [sessionJson, setSessionJson] = useState('')
	// Cart
	const [redisCart, setRedisCart] = useState<CartDict|null>(null)
	const skuIds = useRef<number[]>([])


	// Run on mount, right after the AccountContext function loads: Get sessionObj,
	const getSessionObj = async () => {
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session || null)
		setSessionJson(session ? JSON.stringify(session) : '')
	}
	// ^^
	useEffect(() => {
		getSessionObj()
	}, [getUsernameAndIdToken])




	// After getting the sessionObj:
	//  - Get the address the user selected on previous screen.
	//  - Get the note the user entered on previous screen, if any.
	//  - Get the payment method ID and payment account ID that the user selected.
	//  - Get cookie containing the cart total price. TODO: NOT SECURE!
	// ///
	//  - Get list of user's addresses (which subsequently triggers whichever address the user
	//    chose the last time they were at this CheckOut page).)
	//  - Get dictionary of product details.
	//  - Get note that was prevously added to cart
	//  - Get the user's cart from memorydb/redis & get all of that cart's product/sku details.
	// IMPORTANT: If this block is changed, you'll prob also need to change the matching block
	// 	          in CheckOutConfirmation.tsx.
	useEffect(() => {
		if (sessionJson && getShoppingCart) {
			// Get cart from Redis and product details from psql.
			getCartAndProductDetails(sessionJson, skuIds, getShoppingCart, setProductDetails)
			// Get the list of addresses (psql) for this user's business or clinic that we can ship to.
			getUserAddresses(sessionJson, setUserAddressesList)
			// Get any note text the user might have added to their cart (redis).
			getCartNote(sessionJson).then((text: string|null) => {
				if (text === null) {
					setNotes(EMPTY_NOTES_PLACEHOLDER)
				} else {
					setNotes(text)
				}
			})
			// Get the user's payment options.
			getPaymentMethodIds().then(accountInfo => {
				if (Array.isArray(accountInfo) && accountInfo.length === 2) {
					const [pmtMethodId, pmtAccountId] = accountInfo
					setPaymentMethodId(pmtMethodId)
					setPaymentAccountId(pmtAccountId)
					let paymentMethod = PAYMENT_METHODS[0] // just needs a default.
					// Get/save the index of the selected payment method.
					for (let x=0; x < PAYMENT_METHODS.length; x++) {
						if (PAYMENT_METHODS[x].id === pmtMethodId) {
							paymentMethod = PAYMENT_METHODS[x]
							setPaymentMethodType(PAYMENT_METHODS[x].type)
							break
						}
					}
					// Get user's saved payment accounts
					getUserPaymentAccounts(paymentMethod)
					// Get user's saved accounts for this payment method (and populate dropdown menu)
					getUserPaymentAccounts(paymentMethod).then(([pmtAccts, drpdwnOpts]) => {
						setPaymentAccounts(pmtAccts)
					})
				}
			})
			// TODO: not sure why we have this.
			setFinalPrice(
				Number(getCookie(CookieNames.carttotalprice))
			)
		} else if (!getShoppingCart) {
			// I think this can can happen, but haven't seen it in local testing...
			alert("The function to get ShooppingCart didn't load, please contact an admin.")
		}
	}, [sessionJson])

	// After loading the user's address list on mount...
	// Show the shipping address the user selected on the previous page.
	useEffect(() => {
		if (!userAddressesList.length) return;

		getAddressId(sessionJson).then((addressId: number|null) => {
			// (unlike CheckOut.tsx, if `addressId` is null, DO NOT fall back on anything; let it fail)
			let addressIndex = 0
			if (addressId === 0 || Number(addressId)) {
				const index: number = GetAddressIndexFromId(userAddressesList, Number(addressId))
				if (!isNaN(index) && index !== -1) {
					addressIndex = index
				}
			}
			setAddress(userAddressesList[addressIndex])
		})
	}, [userAddressesList])


	// Look through user's payment accounts (already filtered for the right payment method), 
	// 		and match it with the payment account the user previously selected.
	// This runs when:
	//  - A value is set for both payment method type and we have list of user's accounts.
	useEffect(() => {
		if (paymentMethodType && paymentAccountId && paymentAccounts.length) {
			for (let x=0; x < paymentAccounts.length; x++) {
				if (paymentAccounts[x].id === paymentAccountId) {
					setPaymentMethodDescriptor(
						paymentAccounts[x].company + ' ' + paymentAccounts[x].accountNumber
					)
				}
			}
		}

	}, [paymentMethodType, paymentAccountId, paymentAccounts])


	// Get ordered list of distinct shipping dates in shopping cart.
	// This runs when:
	//  - shopping cart is loaded.
	useEffect(() => {
		if (shoppingCart && Object.keys(shoppingCart).length) {
			setDeliveryDates(getAllDeliveryDates(shoppingCart))
		}
	}, [shoppingCart])
	
	// Redirect with "Back" button or "Confirm" button.
    let navigate = useNavigate(); 
    const routeChange = (whichPage: 'back'|'confirm') =>{ 
		const path = (whichPage === 'back' ? (
			'/cart/check-out'
		) : (
			'/cart/check-out-success'
		))
        navigate(path);
    }


	return (
		<>
		<PrintOnlyContent title={true}>Medngine order confirmation page</PrintOnlyContent>
		<HeaderBanner
			imageName="doctorpen"
			imageText="REVIEW YOUR ORDER"
			imageSpacerColor='BLUE'
			dontChangeTitleOpacity={true}
      	/>
		<div className='body-container cart-check-out-confirmation'>
			<div className='title-order-id noprint'>
				Review your shipment details
			</div>

			<div className='delivery-info-container'>
				<div className='left'>
					<div className='delivery-address-title'>
						SHIPMENT ADDRESS
					</div>
					<div className='delivery-address'>
						{/* Show the user's address, & conditionally exlude steet addr line 2. */}
						{(address && Object.keys(address).length) ? (
							<>
							{address.name}<br/>
							{address.street1}<br/>
							{(address.street2) ? (
								<>
								{address.street2}<br/>
								</>
							) : ( null )}
							{address.city}, {address.state.toUpperCase()}, {address.zip}
							</>
						) : ( null )}
					</div>
				</div>
				<div className='right'>
					<PrintOnlyContent path={false}><div /></PrintOnlyContent>
					<div className='delivery-date-title'>
						{/* Say "Delivery Date Range" if there are multiple dates. */}
						{(deliveryDates.length > 1 
											&& deliveryDates[0] !== deliveryDates.slice(-1)[0]) ? (
							'DELIVERY DATE RANGE'
						) : ( 
							'DELIVERY DATE'
						)}
					</div>
					<div className='delivery-date'>
						{/* Show a single date if there's only one or show date range */}
						{(deliveryDates.length && deliveryDates[0] === deliveryDates.slice(-1)[0]) ? (
							<>
							{deliveryDates[0]}
							</>
						) : ((deliveryDates.length) ? (
							<>
							{deliveryDates[0]} - {deliveryDates.slice(-1)[0]}
							</>
						) : (
							'n/a'
						))}
					</div>
				</div>
				<PrintOnlyContent path={false}><div /></PrintOnlyContent>
			</div>

			{/* 
			  * Show a different table for each delivery by looping each date and creating
			  * 	a separate table for each that exludes all dates but one.
			  *  - Inefficient method due to deadline. Won't be a problem unless extreme edge cases.
			  *  - TODO: make this better whent there's time.
			  */}
			{deliveryDates.map((dateStr: string, idx: number) => (
				// `dateStr` has format '11/20/2022', and in the shopping cart (`CartDict`)
				// it looks like 'Wed, 11/20/2022' - both are strings.
				// Adding index to key string just in case date formatter has bugs.
				<React.Fragment key={dateStr+'x'+idx}>
					<div className='shipments-by-date'>
						<div className="shipment-counter-title">
							<div className='left'>
								Shipment {idx + 1} of {deliveryDates.length}
							</div>
							<div className='right'>
								<span style={{fontWeight: 600}}>
									{dateStr}
								</span>
							</div>
						</div>
						<div className="orders-table-wrapper">
							<OrdersTable 
								modifiable={false}
								containerStyle={{marginTop: '20px'}}
								sessionObj={sessionObj}
								productDetails={productDetails}
							/>
						</div>
					</div>
				</React.Fragment>
			))}

			<div className="notes">
				<div className='notes-title'>
					Notes
				</div>
				<div className='notes-text'>
					{(notes) ? (
						<>
						{notes}
						</>
					) : (
						<span className='notes-none-text'>
							(empty)
						</span>
					)}
				</div>
			</div>

			<div className='totals-container'>
				<div className="total-price">
					{numberFormatter(finalPrice)}
				</div>
				<div className="total-price-subtext">
					Total Amount Due
				</div>
				<div className="payment-method">
					{paymentMethodDescriptor}
				</div>
			</div>

			<div className="buttons-container-finaize">
				<div className='left'>
					<BlockButton
						type='button'
						buttonStyle='ghost-purple mid-size'
						style={{
							padding: 0, width: '100px', fontSize: '1.5rem', fontWeight: 700,
						}}
						onClick={() => routeChange('back')}
					>
						&lArr; CART
					</BlockButton>
				</div>
				<div className="right">
					<BlockButton
						type='button'
						buttonStyle='ghost-purple mid-size'
						style={{padding: 0, width: '170px'}}
						onClick={() => window.print()}
					>
						PRINT
					</BlockButton>
					<BlockButton
						type='button'
						buttonStyle='purple mid-size'
						style={{padding: 0, width: '170px'}}
						onClick={() => routeChange('confirm')}
					>
						CONFIRM
					</BlockButton>
				</div>
			</div>
		</div>
		</>
	)
}
