import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Flip } from 'react-toastify'

import { AccountContext, DecodedJwt } from "../../auth/Account";
import { SessionPacket } from "../../../types/session";

import { BlockButton } from "../../shared/buttons/block-button/BlockButton";
import { HeaderBanner } from "../../layout/header-banner/HeaderBanner"
import PrintOnlyContent from "../../shared/print/PrintOnlyContent"
import { TagInviteModal } from "../../shared/modal/TagInviteModal";
import { TaggedDocs_Rep } from "./TaggedDocs_Rep";
import { TaggedByBizs_Rep } from "./TaggedByBizs_Rep";
import { TaggedByReps_Doc } from "./TaggedByReps_Doc";
import { TaggedByReps_Rep } from "./TaggedByReps_Rep ";
import { TaggedReps_Biz } from "./TaggedReps_Biz";
import { TaggedReps_Rep } from "./TaggedReps_Rep";
import { TaggedReps_RepLead } from "./TaggedReps_RepLead";

import parseErrorObject from '../../../helpers/parseErrorObject'
import { buildPath, qsToDict } from "../../../helpers/urls";

import { TaggedReps, MyAccountInfo, RepOrgAllReps } from "../../../../config";

import LoadingCircle from '../../../static/gif/loading-circle.gif'
import { Icon } from "../../shared/icon/Icon";

import './my-account.scss'
import 'react-toastify/dist/ReactToastify.css'




const API_DB_URL = process.env.REACT_APP_API_DB_URL



// Query params so registration pages display differently.
const REG_PAGE_QUERY_PARAMS = '?isEdit=true'

export const MyAccount = () => {
	// Loading spinner
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(true)
	// Session data
    const [sessionObj, setSessionObj] = useState<SessionPacket|null>(null)
	// Account info from backend
	const [accountInfo, setAccountInfo] = useState<MyAccountInfo|null>(null)
	const [entityUrlBit, setEntityUrlBit] = useState<'supplier'|'vendor'|'doctor'|'consumer'|''>('')
	// Different kinds of tag invites
	// - Only used for reps, who can tag both docs/clinics (providers), and other reps too.
	const [tagInviteType, setTagInviteType] = useState<'clinic'|'rep'|''>('')
	// Error messages
	const [errorMessage, setErrorMessage] = useState('')

	// Modal for tagging / send-invitation-email
	const [showTagInviteModal, setShowTagInviteModal] = useState(false)
	const [emailSendSuccessful, setEmailSendSuccessful] = useState<boolean|null>(null)
	// Tags list - for Vendor->Rep tags, shown on both vendor's and rep's account pages.
	const [tagsData_VtoR, setTagsData_VtoR] = useState<TaggedReps[]>([])
	// Tags list - for Rep->Clinic/Doctor tags, shown on both rep's and clinic/doctor's account pages.
	const [tagsData_RtoC, setTagsData_RtoC] = useState<TaggedReps[]>([])
	// Tags list - for Rep->Rep tags, shown on both both reps' account pages.
	const [tagsData_RtoR_sent, setTagsData_RtoR_sent] = useState<TaggedReps[]>([])
	const [tagsData_RtoR_received, setTagsData_RtoR_received] = useState<TaggedReps[]>([])

	// For REP LEADS ONLY - list of all reps in their Rep Org.
	const [allRepOrgReps, setAllRepOrgReps] = useState<RepOrgAllReps[]>([])
	// Modal for reassigning tags.
	const [showTagReassignModal, setShowTagReassignModal] = useState<boolean|null>(null)
	// For REP LEADS ONLY - get data & show modal for tag tree.
	const [allRepOrgRepsTree, setAllRepOrgRepsTree] = useState<any>(null)
	const [allRepOrgRepsTreeUserCount, setAllRepOrgRepsTreeUserCount] = useState<number|null>(null)
	const [tagTreeIsLoading, setTagTreeIsLoading] = useState(false)
	const [tagTreeErrorMessage, setTagTreeErrorMessage] = useState('')
	// For REP LEADS ONLY - message that will indicate if/where there are tag cycles.
	const [tagCyclesMessage, setTagCyclesMessage] = useState('')
	const [showTagCyclesSpinner, setShowTagCyclesSpinner] = useState(false)


	// For REP MEMBERS ONLY - their initial row from rep_member table.
	// - This is always an arrow of length 0 or length 1.
	const [initialRepMemberRow, setInitialRepMemberRow] = useState<TaggedReps[]>([]) 

	const [getTagsListError, setGetTagsListError] = useState('')
	const [runGetTagsList, setRunGetTagsList] = useState(false)

	// Auth/session functions
	const [thisUserId, setThisUserId] = useState(0)
    const { checkLogin, decodeJwt, getUsernameAndIdToken } = useContext(AccountContext)

	// Navigte
	const navigate = useNavigate()
	

	// On mount
	// - but only after `thisUserId` is set, bc `getAccountData()` triggers some other things
	//   that need `thisUserId`.
	useEffect(() => {
		if (!thisUserId) return;

		// Get query parameters from url.
		const qp = qsToDict(window.location.search)

		// Show toaster if user just came back from editing a page
		if (qp.reason === 'updated') {
			toast('Updates have been saved', { autoClose: 3000 })
		}

		// Show toaster if user just accepted a tag invite.
		if (qp.tagAccepted === 'true') {
			toast.success('Invitation accepted!', { autoClose: 5000 })
		} else if (qp.tagAccepted === 'false') {
			toast.error('This invitation is either invalid or has expired', { autoClose: 8000 })
		}

		// Show toaster if user just accepted (or failed to accept) RepOrg membership request.
		if (qp.repOrgMemberAccepted === 'dne') {
			toast.error(`This user either doesn't exist or is already a member of your organization.`)
		} else if (qp.repOrgMemberAccepted === 'true-inactive') {
			toast.success('Membership accepted, but this user has not verified their email yet.\r\n\r\n'
				+ 'You will be able to see this user in your reps lists once they verify their account.'
			, { autoClose: false }) // don't auto-close.
		} else if (qp.repOrgMemberAccepted === 'true') {
			toast.success('Membership accepted!', { autoClose: 5000 })
		} else if (qp.repOrgMemberAccepted === 'error') {
			toast.error('Error: failed to accept membership - please try again.', { autoClose: 5000 })
		} 

		if (Object.entries(qp || {}).length) {
			navigate(window.location.pathname)
		}
		// Get data about this user's account, products/business/clinic, etc.
		getAccountData(thisUserId)
	}, [thisUserId])

	// Bump out users who aren't logged in.
	useEffect(() => {
		if (!checkLogin) return
		
		checkLogin().then(async (isLoggedIn) => {
			if (!isLoggedIn) {
				// Get query parameters from url to forward to login page if neccessary.
				const qp = qsToDict(window.location.search)

				let qpOut: any = {reason: 'unverified', redirectTo: `/account`}

				// If user arrived at account page by clicking link in email to accept invitation 
				// for Vendor-tag-Rep, and that user hasn't logged in, send user to login page,
				// then back here where they will continue to have the queryParms to show the
				// success toaster for accepting the invite.
				// - Possble vals for `tagAccepted` are the string "true" or "false"
				if (qp.tagAccepted) {
					qpOut = {reason: 'unverified', redirectTo: buildPath(`/account`, {tagAccepted: qp.tagAccepted})}
				}
				window.location.href = buildPath('/auth/login', qpOut)
			} else {
				// Get the user's ID from the session token.
				const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
				const decodedJwt: DecodedJwt|null = await decodeJwt(session.idToken) as DecodedJwt|null
				if (decodedJwt && decodedJwt['custom:u_id']) {
					setThisUserId(Number(decodedJwt['custom:u_id']))
				}
			}
		})
	}, [checkLogin, sessionObj])


	/** 
	 * Show a toaster when email was successfully sent (for tagging invites).
	 */
	useEffect(() => {
		if (emailSendSuccessful && thisUserId && entityUrlBit && accountInfo) {
			toast('Invitation email sent!', { autoClose: 3000 })
			getTagsList(thisUserId, entityUrlBit, accountInfo) // Refresh tags list
			setEmailSendSuccessful(null)
		}
	}, [emailSendSuccessful, thisUserId, entityUrlBit, accountInfo])






	// Get account data from db, along with data for any associated business/clinic.
	const getAccountData = async (_thisUserId: number) => {
		// Get session info if possible, else fail silently
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session)
		if (session.error) { return }

		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		
		// Get data from backend.
		//  - First get general data then get tagged reps.
		axios.post(`${API_DB_URL}/get-account-info`, 
			Object.fromEntries(formData), 
			config
		).then(async (res) => {
			const accountInfoData = res.data
			setAccountInfo(accountInfoData)
			console.log('accountInfo', accountInfoData)

			// If user is a REP LEAD, trigger another function to get all reps in his rep-org.
			// - And another function to check for tag cycles.
			if (accountInfoData.u_types.is_rep_lead) {
				getAllRepOrgReps(_thisUserId) // no await
				checkForRepTagCycles(_thisUserId) // no await
			}
			
			const _entity_type = accountInfoData?.u_entity_type || ''

			// TODO: standardize and centrailize.
			if (_entity_type === 'manufacturer') {
				setEntityUrlBit('supplier')
				return ['supplier', accountInfoData]
			} else if (_entity_type === 'distributor') {
				setEntityUrlBit('vendor')
				return ['vendor', accountInfoData]
			} else if (_entity_type === 'provider') {
				setEntityUrlBit('doctor')
				return ['doctor', accountInfoData]
			} else if (_entity_type === 'general') {
				setEntityUrlBit('consumer')
				return ['consumer', accountInfoData]
			} else {
				alert('System Error #4613: could not get user entity type. Please contact support.')
				throw new Error('System Error #4613: could not get user entity type. Please contact support.')
			}
		}).then(async ([_entityUrlBit, accountInfoData]) => {
			// Get Reps that have been tagged by this business,
			// showing invited and active tags, but not deactivated ones.
			// - Can just send the same POST object as before.
			await getTagsList(_thisUserId, _entityUrlBit, accountInfoData)
		}).catch(async (err) => {
			setErrorMessage('')
			setTimeout(() => {
				setErrorMessage(parseErrorObject(err))
			}, 350)
		}).finally(() => {
			setShowLoadingSpinner(false)
		})
	}

	// Get ALL REPS IN REP ORG (if user is Rep-Lead)
	const getAllRepOrgReps = async (_thisUserId: number) => {
		// (removing this ``setShowLoadingSpinner(true)` bc this "All Reps" table gets updated 
		//  every time the reassign-rep modal is closed  and that spinner is annoying.)
		// setShowLoadingSpinner(true)

		// Get session info if possible, else fail silently
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session)
		if (session.error) { return }

		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		
		// Get data from backend.
		//  - First get general data then get tagged reps.
		axios.post(`${API_DB_URL}/get-all-reps-in-org`, 
			Object.fromEntries(formData), 
			config
		).then(async (res) => {
			const data = res.data
			setAllRepOrgReps(data)
			console.log('AllRepOrgReps', data)
		}).catch(async (err) => {
			setErrorMessage('')
			setTimeout(() => {
				setErrorMessage(parseErrorObject(err))
			}, 350)
		}).finally(() => {
			setShowLoadingSpinner(false)
		})
	}

	// CHECK FOR CYCLES IN TAG TREE (if user is Rep-Lead)
	const checkForRepTagCycles = async (_thisUserId: number) => {
		setShowTagCyclesSpinner(true)

		// Get session info if possible, else fail silently
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session)
		if (session.error) { return }

		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		
		// Get data from backend.
		//  - First get general data then get tagged reps.
		axios.post(`${API_DB_URL}/check-for-tag-cycles`, 
			Object.fromEntries(formData), 
			config
		).then(async (res) => {
			const data = res.data
			setTagCyclesMessage('')
			window.setTimeout(() => {
				if (data === false) {
					// setTagCyclesMessage('Check for circular tag references: passed!')
					// Set to empty for now...
					setTagCyclesMessage('')
				} else {
					setTagCyclesMessage(data) // This will start with the substring "WARNING: ".
				}
			}, 350)
		}).catch(async (err) => {
			setTagCyclesMessage('')
			window.setTimeout(() => {
				setTagCyclesMessage(parseErrorObject(err))
			}, 350)
		}).finally(() => {
			setShowTagCyclesSpinner(false)
		})
	}
	
	// Similar to above, Get ALL REPS IN REP ORG as a TREE (if user is Rep-Lead)
	const getAllRepOrgRepsTree = async (_thisUserId: number) => {
		setTagTreeIsLoading(true)

		// Get session info if possible, else fail silently
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		setSessionObj(session)
		if (session.error) { return }

		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		
		// Get data from backend.
		//  - First get general data then get tagged reps.
		axios.post(`${API_DB_URL}/get-all-reps-in-org-tree`, 
			Object.fromEntries(formData), 
			config
		).then(async (res) => {
			// Structure of `res.data`:
			//		{results: <dict_for_hierarchy_tree>, userCount: <count_of_users_in_the_tree>
			const data = res.data
			setAllRepOrgRepsTree(data.results)
			setAllRepOrgRepsTreeUserCount(data.userCount)
		}).catch(async (err) => {
			setTagTreeErrorMessage('')
			setTimeout(() => {
				setTagTreeErrorMessage(parseErrorObject(err))
			}, 350)
			setAllRepOrgRepsTree(null)
			setAllRepOrgRepsTreeUserCount(null)
		}).finally(() => {
			setTagTreeIsLoading(false)
		})
	}

	const getTagListDebounce = useRef<number>(0)
	const getTagListDebounce_time = 300 // ms

	// Get Reps that are tagged by Vendor.
	// - arg will be used if provided, otherwise will use state variable.
	const getTagsList = async (
		_thisUserId: number, _entityUrlBit: 'supplier'|'vendor'|'doctor'|'consumer'|'',
		accountInfo: MyAccountInfo,
	) => {
		clearTimeout(getTagListDebounce.current)
		getTagListDebounce.current = window.setTimeout(async () => {
			// This should never happen:
			if (!_entityUrlBit) {
				throw new Error('Missing entity type. Please refresh or login again.')
			}

			const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
			const sessionJson = JSON.stringify(session)
			const config = {'headers': {'content-type': 'multipart/form-data'}}
			const formData = new FormData() 
			formData.append('session', sessionJson)

			const userType = _entityUrlBit

			if (userType === 'vendor' || userType === 'supplier') {
				await axios.post(`${API_DB_URL}/tags-list-vendor`, 
					Object.fromEntries(formData), 
					config
				).then(async (res) => {
					const data_VtoR = res.data
					setTagsData_VtoR(data_VtoR)
				}).catch(err => {
					setGetTagsListError(parseErrorObject(err))
				})
			} else if (userType === 'consumer') { // AKA REPS AND REP LEADS
				await axios.post(`${API_DB_URL}/tags-list-rep`, 
					Object.fromEntries(formData), 
					config
				).then(async (res) => {
					const data = res.data
					// Need to split this into two lists. One for tags from Vendors and
					// the other for tags to doctors.
					// - Some `Number()` type casts seem to be required - otherwise occasionally won't
					//   populate or both `RtoR` lists.
					let data_VtoR: TaggedReps[] = []
					let data_RtoC: TaggedReps[] = []
					let data_RtoR_sent: TaggedReps[] = []
					let data_RtoR_received: TaggedReps[] = []
					for (let d of data) {
						if (!!d.from_bid) { 
							data_VtoR.push(d) 
						} else if (!!d.to_cid) { 
							data_RtoC.push(d) 
						} else if (!d.from_bid && !d.to_cid && Number(d.from_uid) === Number(_thisUserId)) { 
							data_RtoR_sent.push(d) 
						} else if (!d.from_bid && !d.to_cid && Number(d.to_uid) === Number(_thisUserId)) {
							data_RtoR_received.push(d)
						}

					}
					// Keeping these commented here bc they will likely needed for testing soon.
					// console.log('_thisUserId', _thisUserId, typeof _thisUserId)
					// console.log('data_VtoR', data_VtoR)
					// console.log('data_RtoC', data_RtoC)
					// console.log('data_RtoR_sent', data_RtoR_sent)
					// console.log('data_RtoR_received', data_RtoR_received)
					setTagsData_VtoR([...data_VtoR])
					setTagsData_RtoC([...data_RtoC])
					setTagsData_RtoR_sent([...data_RtoR_sent])
					setTagsData_RtoR_received([...data_RtoR_received])
					// IFF this user originally registered as a rep-member, get that original rep-member row.
					findOnRegRepMemberRow(data_RtoR_sent, accountInfo)
				}).catch(err => {
					setGetTagsListError(parseErrorObject(err, '#0213e'))
				})
			} else if (userType === 'doctor') {
				await axios.post(`${API_DB_URL}/tags-list-clinic`, 
					Object.fromEntries(formData), 
					config
				).then(async (res) => {
					const data_RtoC = res.data
					setTagsData_RtoC(data_RtoC)
				}).catch(err => {
					setGetTagsListError(parseErrorObject(err, '#0213f'))
				})
			}
		}, getTagListDebounce_time)
	}


	// Find the special row that was tagged "up". This only applies to users who initially registered
	// as a rep member of a particular rep org.
	// - Note that this won't set a valid to `initialRepMemberRow` if the user didn't register
	//   as a rep-member (even if they became a rep-member later), or if their initial tag
	//   to the rep-lead got replaced with the tag of another rep-member, or if all of the
	//   aforementioned tags are `active=false`.
	const findOnRegRepMemberRow = (
		_data_RtoR_sent: TaggedReps[],
		accountInfo: MyAccountInfo,
	) => {
		const _initialRepMemberRow = _data_RtoR_sent.find(row => {
			return (
				row.active !== false 
				&& row.on_reg && row.from_uid === accountInfo.u_id
				&& !row.from_roid && !row.from_bid && !row.to_cid && row.to_roid
			)
		})
		if (_initialRepMemberRow) {
			setInitialRepMemberRow([_initialRepMemberRow])
		} else {
			setInitialRepMemberRow([])
		}
	}

	// TODO: why is this here??
	const productRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
		const destination = event.currentTarget.getAttribute('data-dest')
		if (destination) {
			// window.location.href = destination
			window.open(destination)
		}
	}

		// Delete/deactivate a tag relationship.
	const untagRep = async (
		action: 'deactivate'|'reassign', reassignTo: string|number|null, forceReassign: boolean,
		_thisUserId: number, _entityUrlBit: 'supplier'|'vendor'|'doctor'|'consumer'|'', accountInfo: MyAccountInfo,
		deactivate_self: boolean, // Special: for rep-to-rep tags; which mr_rep_member row to deactive, "self" or the other guy?
		from_roid: number, to_roid: number, from_reporg_name: string, to_reporg_name: string,
		from_bid: number, from_biz_name: string,
		from_uid: number, to_uid: number, from_uname: string, to_uname: string,
		to_cid: number, to_clinic_name: string, 
		is_active: boolean,
		// For alternate handling when this func is called from TaggedReps_RepLead.tsx (by only a REP-LEAD user type):
		altForRepLead?: boolean, 
	) => {
		const userType = _entityUrlBit
		let confirmed = false

		// If this is a rep-to-rep tag modification, then show a message about how the rep's child tags will be reassigned.
		// - Note: if this user's ID matches the "to" user ID, then they are deactivating their own tag,
		//   which means their own child tags should NOT be reassigned (they might be doing that
		//   so they can get another parent-rep tag invite, for example - and worst case the rep-lead
		//   can always do the reassignemnt for their child-reps).
		// - The value of `action` is irrelevant, bc the deactivated rep's rep-tags will 
		//   still need to be reassigned to someone else.
		// - Below conditional is for rep-leads who are modifying any rep-to-rep tag, and 
		//   rep-members who are modifying their own taggged reps (as opposed to reps they are tagged to).
		let reassignText = ''
		if (
			reassignTo && from_roid && !from_bid && !to_cid && 
		    (_thisUserId === from_uid || (
				altForRepLead && _thisUserId !== from_uid && _thisUserId !== to_uid
			))
		) {
			if (action === 'deactivate') {
				reassignText = `\r\n\r\n${to_uname || 'This rep'} will be deactivated and their tagged `
						   + `reps will be reassigned to the user with `
						   + `${Number(reassignTo) ? 'ID' : 'email'} = ${reassignTo}.` 
			} else { // action === 'reassign'
				reassignText = `\r\n\r\n${to_uname || 'This rep'} will be reassigned to the user with `
						   + `${Number(reassignTo) ? 'ID' : 'email'} = ${reassignTo}.` 
			}
		}

		// The `untagRep()` call from a REP-LEAD (from component `TaggedReps_RepLead.tsx`) calls this
		// function a little differently, so deal with that first.

		if (altForRepLead && accountInfo.u_types.is_rep_lead) {
			// This part is for rep lead special untag handling.
			// - These request only use the "from" fields, not "to" fields.
			if (action === 'reassign') {
				if (Number(reassignTo)) { // `reassignTo` is a user ID.
					confirmed = window.confirm(
						// `Are you sure you want to reassign ${from_uname || 'this rep'} to user with ID "${reassignTo}"?`
						'' + reassignText.replace(/[\n\r]/g, '')
					)
				} else { // `reassignTo` is an email string.
					confirmed = window.confirm(
						// `Are you sure you want to reassign ${from_uname || 'this rep'} to user with email "${reassignTo}"?`
						'' + reassignText.replace(/[\n\r]/g, '')
					)
				}
			} else {
				confirmed = window.confirm(
					`Are you sure you want to permanently remove ${from_uname || 'this rep'} from your organization?`
					+ reassignText
				)
			}
		// And everything else below is just for normal users.
		} else if (userType === 'vendor' || userType === 'supplier') {
			if (is_active) {
				confirmed = window.confirm(`Are you sure you want to ${action} ${to_uname || 'this user'}'s Sales Rep relationship?`)
			} else if (is_active === null) {
				confirmed = window.confirm(`Are you sure you want to rescind invitation to ${to_uname || 'this user'}?`)
			} else  { // is_active === false (this should never happen)
				confirmed = window.confirm(`Permanently remove tag to ${to_uname || 'this user'}?`)
			}
		} else if (userType === 'consumer') {
			if (is_active) {
				if (from_bid) { // Vendor->Rep tag/invitation
					confirmed = window.confirm(`Are you sure you want to permanently remove yourself as a Rep for ${from_biz_name || 'this business'}?`)
				} else if (to_cid) { // Rep->Clinic/Doctor tag/invitation
					confirmed = window.confirm(`Are you sure you want to permanently remove yourself as a Rep for ${to_clinic_name || 'this medical provider'}?`)
				} else if ((from_roid || to_roid)) { // Rep->Rep tag/invitation
					const name = (from_uid === _thisUserId) ? to_uname : from_uname
					const mainMsg = accountInfo.u_types.is_rep_lead ? 
						`Are you sure you want to untag ${name || 'this user'} from your organization?` :
						`Are you sure you want to permanently remove your tag with ${name || 'this user'} at ${from_reporg_name || to_reporg_name}?\r\n\r\n`
					let warningMsg = (from_uid === _thisUserId) ?
						`If you do, this user and all of their tagged reps will be re-assigned to the head of your organization.` :
						`If you do, you and all of your tagged reps will be re-assigned to the head of your organization.`
					warningMsg = accountInfo.u_types.is_rep_lead ?  '' : warningMsg
					confirmed = window.confirm(mainMsg + warningMsg + reassignText)
				} else { // (this should never happen)
					const name = (from_uid === _thisUserId) ? to_uname : from_uname
					confirmed = window.confirm(`Permanently remove tag to ${name || 'this user'}?` + reassignText)
				}
			} else if (is_active === null) {
				if (from_bid) { // Vendor->Rep tag/invitation
					confirmed = window.confirm(`Are you sure you want to reject this invitation to be a Rep for ${from_biz_name || 'this business'}?`)
				} else if (to_cid) { // Rep->Clinic/Doctor tag/invitation
					confirmed = window.confirm(`Are you sure you want to rescind request to be a Rep for ${to_clinic_name || 'this doctor/medical practice'}?`)
				} else if ((from_roid || to_roid)) { // Rep->Rep tag/invitation
					const rej = (from_uid === _thisUserId) ? 'rescind' : 'reject'
					const tof = (from_uid === _thisUserId) ? 'to' : 'from'
					const name = (from_uid === _thisUserId) ? to_uname : from_uname
					confirmed = window.confirm(`Are you sure you want to ${rej} this tag invitation ${tof} ${name} at ${from_reporg_name || to_reporg_name}?` + reassignText)
				} else { // (this should never happen)
					const name = (from_uid === _thisUserId) ? to_uname : from_uname
					confirmed = window.confirm(`Permanently remove tag with ${name || 'this user'}?` + reassignText)
				}
			} else  { // is_active === false (this should never happen)
				confirmed = window.confirm(`Permanently remove this tag?` + reassignText)
			}
		} else if (userType === 'doctor') {
			if (is_active) {
				confirmed = window.confirm(`Are you sure you want to permanently remove ${from_uname || 'this user'} as your Sales Rep?`)
			} else if (is_active === null) { // (this should never happen bc doctors can't send invites to anyone)
				confirmed = window.confirm(`Are you sure you want to decline your invitation from ${from_uname || 'this user'}?`)
			} else  { // is_active === false (this should never happen)
				confirmed = window.confirm(`Permanently untag ${from_uname || 'this user'}?`)
			}
		} else {
			alert(`Unknown error: ${_entityUrlBit}, ${userType}`)
		}

		if (!confirmed) return

		// Assume we have the session obj from earlier.
		const session: SessionPacket = sessionObj || await getUsernameAndIdToken()
		// Prepare post request.
		const sessionJson = JSON.stringify(session)
		const config = {'headers': {'content-type': 'multipart/form-data'}}
		const formData = new FormData() 
		formData.append('session', sessionJson)
		formData.append('action', String(action ? action : ''))
		formData.append('reassign_to', String(reassignTo ? reassignTo : '')) // Use empty for false/null/undefined
		formData.append('this_uid', String(_thisUserId))
		formData.append('from_uid', String(from_uid))
		formData.append('from_bid', String(from_bid))
		formData.append('from_roid', String(from_roid))
		formData.append('to_uid', String(to_uid))
		formData.append('to_roid', String(to_roid))
		formData.append('to_cid', String(to_cid))
		formData.append('deactivate_self', String(deactivate_self))
		formData.append('alt_for_rep_lead', String(altForRepLead ? altForRepLead : '')) // Use empty for false/null/undefined
		formData.append('force_reassign', String(forceReassign ? forceReassign : '')) // Use empty for false/null/undefined

		let requestUrl = ``
		if (userType === 'vendor' || userType === 'supplier') {
			requestUrl = `${API_DB_URL}/delete-tag/vendor/rep`
		} else if (userType === 'consumer') { // This is for a Rep
			if (from_bid) { // Vendor->Rep tag/invitation
				requestUrl = `${API_DB_URL}/delete-tag/rep/vendor`
			} else if (to_cid) { // Rep->Clinic/Doctor tag/invitation
				requestUrl = `${API_DB_URL}/delete-tag/rep/clinic`
			} else { // Rep->Rep tag/invitation
				requestUrl = `${API_DB_URL}/delete-tag/rep/rep` // (this one also does tag reassignments)
			}
		} else if (userType === 'doctor') {
			requestUrl = `${API_DB_URL}/delete-tag/clinic/rep`
		}

		if (requestUrl) {
			await axios.post(requestUrl, 
				Object.fromEntries(formData), 
				config
			).then(async (res) => {
				// On success, refresh the tags list.}
				let message = ''
				if (action === 'deactivate') {
					message = message || 'Tag deactivated.\r\n\r\nPlease review Tag Hierarchy to confirm correct structure.'
					toast.success(`${message}`, { autoClose: 3000 })
				} else { // action === 'reassign'
					message = message || 'Tag reassigned.\r\n\r\nPlease review Tag Hierarchy to confirm correct structure.'
					toast.success(`${message}`, { autoClose: 3000 })
				}
				await getTagsList(_thisUserId, _entityUrlBit, accountInfo)
			}).catch(err => {
				// Error
				let message = ''
				if (action === 'deactivate') {
					message = 'Failed to deactivate tag.'
				} else { // action === 'reassign'
					message = 'Failed to reassign tag.'
				}
				toast.error(`${message}\r\n-\r\n${parseErrorObject(err)}`, { autoClose: 8000 })
			}).finally(() => {
				setShowTagReassignModal(false)
			})
		} else {
			setErrorMessage('Error: could not get user type. Please login again (#4516).')	
		}
	}
		


	return (
		<div className='my-account-page'>
			<PrintOnlyContent title={true}>Medngine My Account page</PrintOnlyContent>

			<HeaderBanner
				imageName="doctorpen"
				imageText="MY ACCOUNT"
				imageSpacerColor='BLUE'
				dontChangeTitleOpacity={true}
			/>

			{(entityUrlBit === 'vendor' || entityUrlBit === 'supplier' || entityUrlBit === 'consumer') ? (
				<TagInviteModal
					show={showTagInviteModal}
					closeModal={() => {
						setShowTagInviteModal(false)
						setTagInviteType('')
					}}
					sessionObj={sessionObj}
					userType={entityUrlBit}
					tagInviteType={tagInviteType}
					setSuccess={() => setEmailSendSuccessful(true)}
				/>
			 ) : null}

			{/* Error message at top of the page */}
			{errorMessage ? (
				<div className='error-message'>
					{errorMessage}
				</div>
			) : null}

			{showLoadingSpinner ? (
				<img src={LoadingCircle} className='loading-spinner' />
			) : null}
			
			{accountInfo ? (
				<div className='body-container section-container'>
					<div className='section'>
						<div className='title-row'>
							<div className='section-title'>
								Account
							</div>
							<button className='edit-info'>
								<Link to={`/${entityUrlBit}-registration/1/${REG_PAGE_QUERY_PARAMS}`}>
									Edit
								</Link>
							</button>
						</div>
						<table className='info-table'>
							<tbody>
								<tr>
									<td className='title'>
										Name:
									</td>
									<td className='info'>
										{accountInfo.u_name}
									</td>
								</tr>
								<tr>
									<td className='title'>
										User ID:
									</td>
									<td className='info'>
										{accountInfo.u_id}
									</td>
								</tr>
								<tr>
									<td className='title'>
										Email:
									</td>
									<td className='info'>
										{accountInfo.u_email}
									</td>
								</tr>
								<tr>
									<td className='title'>
										Registration date:
									</td>
									<td className='info'>
										{accountInfo.u_reg_date}
									</td>
								</tr>
								<tr>
									<td className='title'>
										Account type:
									</td>
									<td className='info'>
										{accountInfo?.u_account_type?.join(', ')}
										{
											((accountInfo.u_types.is_sysadmin
											 || accountInfo.u_types.is_admin
											 || accountInfo.u_types.is_conductor
											 || accountInfo.u_types.is_manager
											) ? (
												<button 
													type='button'
													className='admin-button'
													onClick={() => navigate('/admin')}
												>
													<Icon name='admin' />
												</button>
											) : (
												null
											))
																						
										}
									</td>
								</tr>
								
								{/* An NPI row for doctors only */}
								{entityUrlBit === 'doctor' ? (
									<tr>
										<td className='title'>
											Individual NPI:
										</td>
										<td className='info'>
											{accountInfo.u_npi}
										</td>
									</tr>
								) : null}

								{/* 
								  * An extra row for users who claimed to be part of a RepOrg,
								  *	but haven't been approved yet.
								  */}
								{(
									accountInfo.u_types.is_rep_member
									&& tagsData_RtoR_sent.length >= 1
									&& !!tagsData_RtoR_sent.find(t => t.active === null) // different (from block below)
									&& !tagsData_RtoR_sent.find(t => t.active === true) // only 1 "!"
									&& !!tagsData_RtoR_sent.find(t => t.active === null)?.to_roid // different (from block below)
									&& !tagsData_RtoR_received.find(t => t.active === true) // only 1 "!"
								) ? (
									<>
									<tr>
										<td className='title'>
											Company:
										</td>
										<td className='info'>
											<div style={{color: '#f34', fontWeight: 'bold', marginBottom: '8px'}}>
												PENDING
											</div>
											<div style={{marginBottom: '6px'}}>
												Awaiting membership approval from&nbsp;
												<span style={{fontStyle: 'italic'}}>
													{tagsData_RtoR_sent[0].to_reporg_name}
												</span>
											</div>
										</td>
									</tr>
									</>
								) : null}

								{/* 
								  * An extra row for ACTIVE sales reps who are active with a compnay.
								  */}
								{((
										accountInfo.u_types.is_rep_member
										&& (initialRepMemberRow || tagsData_RtoR_received.length)
									) || (
										accountInfo.u_types.is_rep_lead
										&& allRepOrgReps.length
									)
								) ? (
									<>
									<tr>
										<td className='title'>
											Company:
										</td>
										<td className='info' style={{fontStyle: 'italic'}}>
											{
												initialRepMemberRow[0]?.to_reporg_name // There should only be one (if any)
												|| tagsData_RtoR_received.find(t => t.active)?.from_reporg_name // There should only be one (if any)
												|| allRepOrgReps[0]?.rep_org_name // IFF this is rep-lead and he has tagged reps (if no tagged reps, then this block won't show for rep-lead)
												|| '--'
											}
										</td>
									</tr>
									</>
								) : null}

								{/* 
								  * An extra row for independent sales reps who are indy BECAUSE
								  * they were rejected.
								  */}
								{(
									accountInfo.u_types.is_rep_member
									&& tagsData_RtoR_sent.length >= 1
									&& !!tagsData_RtoR_sent.find(t => t.active === false) // different (from block above)
									&& !tagsData_RtoR_sent.find(t => t.active === true) // only 1 "!"
									&& !!tagsData_RtoR_sent.find(t => t.active === false)?.to_roid // different (from block above)
									&& !tagsData_RtoR_received.find(t => t.active === true) // only 1 "!"
								) ? (
									<>
									<tr>
										<td className='title'>
											Company:
										</td>
										<td className='info' style={{fontStyle: 'italic'}}>
											(independent)
										</td>
									</tr>
									</>
								) : null}
							</tbody>
						</table>
					</div>

					{entityUrlBit === 'vendor' || entityUrlBit === 'supplier' ? (
						<>
						<div className='section'>
							<div className='title-row'>
								<div className='section-title'>
									Business
								</div>
								<button className='edit-info'>
									<Link to={`/${entityUrlBit}-registration/2/${REG_PAGE_QUERY_PARAMS}`}>
										Edit 1
									</Link>
								</button>
								<button className='edit-info'>
									<Link to={`/${entityUrlBit}-registration/4/${REG_PAGE_QUERY_PARAMS}`}>
										Edit 2
									</Link>
								</button>
							</div>
							<table className='info-table'>
								<tbody>
									<tr>
										<td className='title'>
											Name:
										</td>
										<td className='info'>
											{accountInfo.b_name}
										</td>
									</tr>
									<tr>
										<td className='title'>
											Business page:
										</td>
										<td className='info'>
											<Link to={`/public/vendor-profile/${accountInfo.b_id}`}>
												{window.location.origin}/public/vendor-profile/{accountInfo.b_id}
											</Link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className='section'>
							<div className='title-row'>
								<div className='section-title'>
									Products
								</div>
								<button className='edit-info'>
									<Link to={`/${entityUrlBit}-registration/5/${REG_PAGE_QUERY_PARAMS}`}>
										Edit
									</Link>
								</button>
							</div>
							<table className='info-table'>
							<thead>
								<tr>
									<td style={{textAlign: 'left', paddingLeft: '16px'}}>Product</td>
									<td style={{textAlign: 'left', paddingLeft: '16px'}}>Skus</td>
								</tr>
							</thead>
								<tbody>
									{accountInfo.products.map((product, index) => (
										<tr 
											key={index + '--' + product.id}
											className='product-row'
											data-dest={`/public/products/${product.id}`}
											onClick={(e: React.MouseEvent<HTMLTableRowElement>) => productRowClick(e)}
										>
											<td className='title product-cell'>
												{product.name}
											</td>
											<td className='info wide product-cell'>
												{product.skus.map((sku, index) => (
													<React.Fragment key={sku.id+sku.name+index}>
														{sku.name}
														<br/>
													</React.Fragment>
												))}
											</td>
											{/* This helps keep proper sizing. */}
											<td></td> 
										</tr>
									))}
								</tbody>
							</table>
						</div>
						</>
					) : null}

					{entityUrlBit === 'doctor' ? (
						<div className='section'>
							<div className='title-row'>
								<div className='section-title'>
									Clinic
								</div>
								<button className='edit-info'>
									<Link to={`/${entityUrlBit}-registration/2/${REG_PAGE_QUERY_PARAMS}`}>
										Edit
									</Link>
								</button>
							</div>
							<table className='info-table'>
								<tbody>
									<tr>
										<td className='title'>
											Name:
										</td>
										<td className='info'>
											{accountInfo.c_name}
										</td>
									</tr>
									<tr>
										<td className='title'>
											Practice NPI:
										</td>
										<td className='info'>
											{accountInfo.c_npi}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : null}


					{/* TAG TABLES FOR REPS */}
					{(entityUrlBit === 'consumer') ? (
						<>
						{/* The person/org that this Rep is tagged to.
						    - By default it's the CEO of the Rep-Org, unless someone else in the
							  org tagged this Rep.
						    - Don't show this section if the user is REP LEAD - because all they
							  will see in this section is the users who work for the company, and
							  that info is in another block.
						*/}
						<TaggedByReps_Rep
							getTagsListError={getTagsListError}
							tagsData_RtoR_sent={tagsData_RtoR_sent}
							tagsData_RtoR_received={tagsData_RtoR_received}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
						/>
						{/* Other Reps whom this Rep tagged (same rep-org).
						    - Always shows this section for reps so they can see the Invite a Rep button.
							  UNLESS they haven't been tagged by anyone yet (& Indy reps can't tag anyone).
							- Use `tagsData_RtoR_sent` as gate bc a new Rep-Member will have "sent" 
					          a request to the Rep Org (registation is the only time they can send 
				              invites "up" to someone in the hierarchy).)
						*/}
						<TaggedReps_Rep
							getTagsListError={getTagsListError}
							tagsData_RtoR_sent={tagsData_RtoR_sent}
							tagsData_RtoR_received={tagsData_RtoR_received}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
							setShowTagInviteModal={setShowTagInviteModal}
							setTagInviteType={setTagInviteType}
						/>
						{/* The Business(es) that tagged this Rep. */}
						<TaggedByBizs_Rep
							getTagsListError={getTagsListError}
							tagsData_VtoR={tagsData_VtoR}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
						/>
						{/* Doctors tagged by this Rep. 
							- This section is always visible.
						*/}
						<TaggedDocs_Rep
							getTagsListError={getTagsListError}
							tagsData_RtoC={tagsData_RtoC}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
							setShowTagInviteModal={setShowTagInviteModal}
							setTagInviteType={setTagInviteType}
						/>
						{/* FOR REP LEADS ONLY - All sales reps in the rep-org. */}
						{(allRepOrgReps.length) ? (
							<>
							<TaggedReps_RepLead
								getTagsListError={getTagsListError}
								tagsData_RtoR_sent={tagsData_RtoR_sent}
								tagsData_RtoR_received={tagsData_RtoR_received}
								untagRep={untagRep}
								allRepOrgReps={allRepOrgReps}
								accountInfo={accountInfo}
								showTagReassignModal={showTagReassignModal}
								setShowTagReassignModal={setShowTagReassignModal}
								getAllRepOrgReps={getAllRepOrgReps}
								getAllRepOrgRepsTree={getAllRepOrgRepsTree}
								allRepOrgRepsTree={allRepOrgRepsTree}
								setAllRepOrgRepsTree={setAllRepOrgRepsTree}
								allRepOrgRepsTreeUserCount={allRepOrgRepsTreeUserCount}
								setAllRepOrgRepsTreeUserCount={setAllRepOrgRepsTreeUserCount}
								checkForRepTagCycles={checkForRepTagCycles}
								showTagCyclesSpinner={showTagCyclesSpinner}
								setShowTagCyclesSpinner={setShowTagCyclesSpinner}
								tagCyclesMessage={tagCyclesMessage}
								tagTreeIsLoading={tagTreeIsLoading}
								tagTreeErrorMessage={tagTreeErrorMessage}
							/>
							</>
						) : null}
						</>
					) : null}

					{/* TAG TABLES FOR BUSINESSES (vendor AND supplier) 
						- This section will always show for businesses, bc even if they don't
					      have existing tags they still need to be able to tag Reps.
					*/}
					{(entityUrlBit === 'vendor' || entityUrlBit === 'supplier') ? (
						<TaggedReps_Biz
							getTagsListError={getTagsListError}
							tagsData_VtoR={tagsData_VtoR}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
							setShowTagInviteModal={setShowTagInviteModal}
							setTagInviteType={setTagInviteType}
						/>
					) : null }

					{/* TAG TABLES FOR DOCTORS/CLINICS
					    - Doctors can't invite anyone, so only show this section/table if they
					      have at least one invite/tag from a Rep.
					*/}
					{(entityUrlBit === 'doctor') ? (
						<TaggedByReps_Doc
							getTagsListError={getTagsListError}
							tagsData_RtoC={tagsData_RtoC}
							untagRep={untagRep}
							userId={thisUserId}
							userType={entityUrlBit}
							accountInfo={accountInfo}
						/>
					) : null}




					<div className='section'>
						<div className='title-row'>
							<div className='section-title'>
								Active Orders
							</div>
						</div>
						<table className='info-table'>
							<tbody>
								<tr>
									<td className='title'>
										2/19/2023
									</td>
									<td className='info'>
										(order info link)
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='section'>
						<div className='title-row'>
							<div className='section-title'>
								Past Orders
							</div>
						</div>
						<table className='info-table'>
							<tbody>
								<tr>
									<td className='title'>
										1/7/2023
									</td>
									<td className='info'>
										(order info link)
									</td>
								</tr>
								<tr>
									<td className='title'>
										9/25/2022
									</td>
									<td className='info'>
										(order info link)
									</td>
								</tr>
								<tr>
									<td className='title'>
										6/14/2022
									</td>
									<td className='info'>
										(order info link)
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
		) : (
			null
		)}

		<ToastContainer 
			hideProgressBar={true}
			closeOnClick={true}
			position='bottom-center'
			style={{textAlign: 'center', cursor: 'default', width: '500px', maxWidth: '80vw', whiteSpace: 'pre-wrap'}}
			transition={Flip}
		/>
		</div>
	)
}