import React from 'react';
import { BlockButton } from '../../../shared/buttons/block-button/BlockButton';
import { PillButton } from '../../../shared/buttons/pill-button/PillButton';
import { InputField } from '../../../shared/form/input-field/InputField';
import { Table } from "../../../shared/tables/Table";
import { Link } from "react-router-dom";
import VendorDashHeatMap from "../../../../static/img/vendordash-heatmap.png";
import './vendor-dashboard.scss';
import PrintOnlyContent from '../../../shared/print/PrintOnlyContent';


//    TODO:   add Search Bar on top of Recent Orders Grid, fix pagination
//    TODO:   make working heatmap


type Props = {};

type State = {};


export class VendorDashboard extends React.Component<Props, State> {
    private STEP: number;


    constructor(props: Props) {
        super(props);
        this.STEP = 9999999999999999
    }

    mockDataTable = [
        { orderNumber: "151435411", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435412", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435413", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435414", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435415", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435416", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435417", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435418", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435419", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435421", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435422", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435423", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435424", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435425", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435426", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435427", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435428", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435429", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435430", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
        { orderNumber: "151435431", date: "01-01-1995", amount: "5", status: "PENDING", orderLink: 'VIEW ORDER' },
    ]

    render() {
        return (
            <>
                <div className="header-spacer" />
                <div className="vendor-dashboard-layout">
                    <PrintOnlyContent title={true}>Medngine vendor dashboard page</PrintOnlyContent>
                    <div className="body-container-no-vert-pad header-section">
                        <div className="page-title">WELCOME</div>
                        <div className="name-and-datefilter-container">
                            <div className="page-subtitle">REGENATIVE LABS</div>
                            <div className="datepicker-wrapper">
                                <div className="datepicker">03/15/2022 - Today</div>
                            </div>
                        </div>
                    </div>
                    <div className="salesnumbers-container">
                        <div className='body-container-no-vert-pad sections'>
                            <div className='section'>
                                <div className='data'>$89,021.00</div>
                                <div className='title'>RECEIVED</div>
                            </div>
                            <div className='section'>
                                <div className='data'>$11,000.00</div>
                                <div className='title'>CHANGE</div>
                            </div>
                            <div className='section chart'>
                                {/*    <div className='data'>_ _ _ _</div>  */}
                                <div className='color-row'>
                                    <div className="vertical-color one" />
                                    <div className="vertical-color two" />
                                    <div className="vertical-color three" />
                                    <div className="vertical-color four" />
                                </div>
                                <div className='title chart'>IPSUM</div>
                            </div>
                        </div>
                    </div>

                    <div className='body-container-no-vert-pad'>
                        <div className="recent-orders-wrapper">
                            <div className="header">RECENT ORDERS</div>
                            <InputField required style={{ width: '700px', marginTop: '70px' }} name='vendorfirst' placeholder='Search by Order Number, Product, etc.' />
                            <Table
                                headerStyle="vendordash-header-cols"
                                gridType="vendordash-container"
                                tableStyle="vendordash-items-fill"
                                style={{ marginTop: '27px' }}
                                visibleRows={4}
                                data={this.mockDataTable}
                                columnNames={["Order Number", "Date", "Amount", "Status", "Order Link"]}
                                pagingOption="paging-arrow"
                            />
                        </div>
                        <div className="heatmap-wrapper">
                            <div className="header">ORDERS</div>
                            <img src={VendorDashHeatMap} className="image1" />
                            {/* <PageNumbers currentPage={7} totalPages={80} />  */}
                        </div>
                        <div className="products-wrapper">
                            <div className="header">PRODUCTS</div>
                            <div className="products-grid">
                                <div className="header products">HIGHEST VOLUME</div>
                                <div className="header products">MOST VIEWED</div>
                                <div className="header products">PENDING APPROVAL</div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <div className="grey-boxes"></div>
                                <Link to={`/vendor-registration/5`}>
                                    <PillButton
                                        style={{ marginTop: '52px', }}
                                        //  buttonType='pill-button reversed clickable'
                                        buttonStyle="white gradient"
                                        iconType='add'
                                        reverse={true}
                                    >
                                        ADD PRODUCTS
                                    </PillButton>
                                </Link>
                            </div>
                        </div>
                        <div className="header">EVENTS</div>
                        <div className='events-wrapper'>
                            <div className='events-gallery'>
                                <div className='event clickable main-event'>
                                    <div className='bg'></div>
                                    webinar / Event
                                </div>
                                <div className='sub-events'>
                                    <div className='event clickable'>
                                        <div className='bg'></div>
                                        webinar / Event
                                    </div>
                                    <div className='event clickable'>
                                        <div className='bg'></div>
                                        webinar / Event
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}