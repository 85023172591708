import { InputField } from "../../shared/form/input-field/InputField"
import { RadioSelector } from "../../shared/buttons/radio-selector/RadioSelector"

import { SelectField, SelectOption } from "../../shared/form/select-field/SelectField";
import { STATES } from "../../../constants/states";

import "./credit-application.scss";



interface Props {
	activeStep: number;
}
/**
 * Credit Application page - step 1.
 * 
 * This is where user enters general info about the their business.
 */
export const CreditApplicationStep1 = ({ activeStep }: Props) => {
	const STEP: number = 1

	const statesAbbreviationsList: SelectOption[] = [
		{ option: '-', value: null }, 
		...STATES.map(state => ({ option: state.abbreviation, value: state.abbreviation }))
	]

	return (
		<div style={{display: `${(activeStep === STEP) ? 'block' : 'none'}`}}>
			<div className="form-border">
				<div className='section-title'>
					Company Information
				</div>
				<div className="form-row">
					<div className='form-field' style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-business-name">
							Business Legal Name
						</label>
						<InputField
							required
							id="credit-application-form-business-name"
							name="business-name"
							altStyle='credit-app'
						/>
					</div>
					<div className='form-field' style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-dba">
							DBA (if applicable)
						</label>
						<InputField 
							id="credit-application-form-dba" 
							name="dba" 
							altStyle='credit-app'
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-field" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-street-address-line-1">
							Street Address
						</label>
						<InputField
							required
							id="credit-application-form-street-address-line-1"
							name="street-address-line-1"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-address-line-2">
							Street Address Line 2
						</label>
						<InputField 
							id="credit-application-form-address-line-2"
							name="street-address-line-2"
							altStyle='credit-app'
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-field" style={{width: '50%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-city">
							City / State
						</label>
						<InputField 
							required
							id="credit-application-form-city"
							name="city"
							altStyle='credit-app city'
						/>
						<SelectField
							required
							id="credit-application-form-state"
							name='state'
							options={statesAbbreviationsList}
							containerClassName='credit-app-state'
						/>
					</div>
					<div className="form-field" style={{width: '25%'}}>
						<label htmlFor="credit-application-form-zip-code">
							Zip Code
						</label>
						<InputField 
							required
							type='number'
							maxLength={5}
							id="credit-application-form-zip-code"
							name="zip-code"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '25%'}}>
						<label htmlFor="credit-application-form-phone">
							Phone
						</label>
						<InputField
							required
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							type="tel"
							id="credit-application-form-phone"
							name="phone"
							altStyle='credit-app'
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-field" style={{width: '40%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-fed-tax-id">
							Federal Tax ID
						</label>
						<InputField
							required
							id="credit-application-form-fed-tax-id"
							name="fed-tax-id"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '30%'}}>
						<label htmlFor="credit-application-form-years-in-business">
							Years in Business <br/><span className='subtext'>(under current ownership)</span>
						</label>
						<InputField
							required
							id="credit-application-form-years-in-business"
							type='number'
							maxLength={3}
							name="years-in-business"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '30%'}}>
						<label htmlFor="credit-application-form-number-of-employees">
							# of Employees
						</label>
						<InputField
							required
							id="credit-application-form-number-of-employees"
							type='number'
							maxLength={7}
							name="bunumber-of-employees"
							altStyle='credit-app'
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-field" style={{width: '40%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-annual-revenue">
							Annual Revenue
						</label>
						{/* TODO: add commas to dollar figures */}
						<InputField
							required
							id="credit-application-form-annual-revenue"
							type='number'
							maxLength={13}
							name="annual-revenue"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '40%'}}>
						<label htmlFor="credit-application-form-website">
							Website
						</label>
						<InputField
							required
							id="credit-application-form-website"
							type='url'
							name="website"
							altStyle='credit-app'
						/>
					</div>
					<div className="form-field" style={{width: '20%'}}>
						{/* this is a spacer for alignment purposes */}
					</div>
				</div>
				<div className="form-row" style={{marginTop: '16px'}}>
					<div className="form-field" style={{width: '100%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-website">
							Legal Structure
						</label>
						<RadioSelector name='legal-structure' useLeftMargin={false}>
							Sole Proprietor
						</RadioSelector>
						<RadioSelector name='legal-structure'>Partnership</RadioSelector>
						<RadioSelector name='legal-structure'>LLC</RadioSelector>
						<RadioSelector name='legal-structure'>Corporation</RadioSelector>
						<RadioSelector name='legal-structure'>Municipal</RadioSelector>
						<RadioSelector name='legal-structure'>Not for Profit</RadioSelector>
					</div>
				</div>
			</div>


			<div className="form-border contact">
				<div className='section-title'>
					Company Contact Information
				</div>
				<div className="form-row">
					<div className="form-field no-right-padding" style={{width: '35%'}}>
						<label className='width-fixed' htmlFor="credit-application-form-contact-name">
							Contact Name
						</label>
						<InputField
							required
							id="credit-application-form-contact-name"
							name="contact-name"
						/>
					</div>
					<div className="form-field no-right-padding" style={{width: '20%'}}>
						<label htmlFor="credit-application-form-contact-title">
							Title
						</label>
						<InputField
							required
							id="credit-application-form-contact-title"
							name="contact-title"
						/>
					</div>
					<div className="form-field no-right-padding" style={{width: '20%'}}>
						<label htmlFor="credit-application-form-contact-cell">
							Phone
						</label>
						<InputField
							required
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							type="tel"
							id="credit-application-form-contact-cell"
							name="contact-cell"
						/>
					</div>
					<div className="form-field" style={{width: '25%'}}>
						<label htmlFor="credit-application-form-contact-email">
							Email
						</label>
						<InputField
							required
							type="email"
							id="credit-application-form-contact-email"
							name="contact-email"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

