import { BlockButton } from "../../../shared/buttons/block-button/BlockButton";
import { InputField } from "../../../shared/form/input-field/InputField";
import { SelectField, SelectOption } from "../../../shared/form/select-field/SelectField";
import VendorAddProductImg from "../../../../static/img/add-product-icon.png";
import PenEditIcon from "./pen-edit-icon.png"
import "./doctors.scss";


//  TODO:  ADD SHADOWS TO CONTAINERS

export const Doctors = () => {

  const ShowEntries: SelectOption[] = [
    { option: ' Most Recent ', value: 99 },
    { option: ' 5 ', value: 5 },
    { option: ' 10 ', value: 10 },
    { option: ' 20 ', value: 20 },
    { option: ' 50 ', value: 50 },
  ]

  return (
    <div className="doctors-layout">
      <div className="body-container ">
        <div className="multicolor-headers">
          <div className="header small blue">BUILD</div>
          <div className="header small">/ DOCTORS</div>
        </div>

        <div className="main-container">
          <div className="top-row">
            <div className="column">
              <div className="header">DOCTORS</div>
              <div className="header blue">3</div>
            </div>
            <div className="column">
              <div className="header">TAGGED</div>
              <div className="header blue">3</div>
            </div>
            <div className="column">
              <div className="header">EXPIRING SOON</div>
              <div className="header blue">0</div>
            </div>
            <div className="column">
              <div className="header">ACTIVE SINCE (DATE HERE)</div>
              <div className="header blue">9</div>
            </div>
          </div>
          <div className="search-container">
            <div className="columns-mid text-box">
              <div>Showing 3 of 3 records</div>
            </div>
            <div className="columns-mid dropdown-box">
              <SelectField
                name='entries-number'
                options={ShowEntries}
              />
            </div>
            <div className="columns-mid search-box">
              <InputField
                name="search"
                placeholder="SEARCH"
              />
            </div>
          </div>

          <div className="text-span">

            <div className="text-one">A card with<div className="circle-icon">!</div>
              means doctor either not invited or tagged.</div>

            <div className="text-one">Doctors requiring action (invitation/tagging) show first.
              After that doctors ordered by most recent order date.</div>
          </div>
          <div className="bottom-container">
            <div className="columns-bottom add-doctor">
              <img src={VendorAddProductImg} />
              <div className="icon-text">ADD DOCTOR</div>
            </div>

            <div className="columns-bottom doctor-info-card">
              <div className="info-span">
                <div className="header blue">DrLicka Dentist</div>
                <div className="circle-icon">!</div>
              </div>
              <div className="info-span">
                <div className="header">Doctor: Phillip DrLicka</div>
                <img src={PenEditIcon} />
              </div>
              <div className="header">Doctor NPI: 1942277793</div>
              <div className="header">Clinic Phone: 850-455-1323</div>
              <div className="header red">NO ORDER</div>
              <BlockButton
                buttonStyle='desktop-header-buttons purple'
              >
                INVITE DOCTOR
              </BlockButton>
              <div className="teal-container">
                <div className="tealtext">TAG: Regenerative Reps/Rep</div>
                <div className="tealtext">Demo <div className="days-box">12</div>days</div>
              </div>
            </div>

            <div className="columns-bottom doctor-info-card">
              <div className="info-span">
                <div className="header blue">My Specialty Clinic LLC</div>
                <div className="circle-icon">!</div>
              </div>
              <div className="info-span">
                <div className="header">Doctor: David Neumann</div>
                <img src={PenEditIcon} />
              </div>
              <div className="header">Doctor NPI: 1043339575</div>
              <div className="header">Clinic Phone: 555-555-5555</div>
              <div className="header red">NO ORDER</div>
              <BlockButton
                buttonStyle='desktop-header-buttons purple'
              >
                INVITE DOCTOR
              </BlockButton>
              <div className="teal-container">
                <div className="tealtext">TAG: Regenerative Reps/Rep</div>
                <div className="tealtext">Demo <div className="days-box">143</div>days</div>
              </div>
            </div>

            <div className="columns-bottom doctor-info-card">
              <div className="info-span">
                <div className="header blue">Metropolitan Podiatric</div>
                <div className="circle-icon">!</div>
              </div>
              <div className="info-span">
                <div className="header">Doctor: Michael Galoyan</div>
                <img src={PenEditIcon} />
              </div>
              <div className="header">Doctor NPI: 1023428976</div>
              <div className="header">Clinic Phone: No Phone</div>
              <div className="header red">NO ORDER</div>
              <BlockButton
                buttonStyle='desktop-header-buttons purple'
              >
                INVITE DOCTOR
              </BlockButton>
              <div className="teal-container">
                <div className="tealtext">TAG: Regenerative Reps/Rep</div>
                <div className="tealtext">Demo <div className="days-box">30</div>days</div>
              </div>
            </div>

          </div>

          <div className="end-results">- End of Results -</div>




        </div>
      </div>
    </div >
  )
}